import NakedPromise from '../../../lib/pattern/NakedPromise';
import { textLocaleFormat } from '../../../lib/util/textTools';
import { GameId } from '../../../replicant/defs/gameDay';
import { sleep } from '../../../replicant/util/jsTools';
import app from '../../getApp';
import { PlayerClipId } from '../../concept/BaseballPlayer';
import { trackGameDayStep } from '../../lib/analytics/gameDay';
import { GameDayPlayer, GameDayScreen } from '../../main/home/GameDayScreen';
import { GameDayFlow, GameDayFlowOpts, ScoreData } from './GameDayFlow';

const scoreboardData: ScoreData[] = [
    {
        balls: 0,
        strikes: 0,
        outs: 2,
        visitorData: ['1', '0', '0', '2', '0'],
        homeData: ['0', '1', '0', '1'],
        visitorR: 3,
        homeR: 2,
    },
    {
        balls: 0,
        strikes: 2,
        outs: 2,
        visitorData: ['1', '0', '0', '2', '0', '1', '2', '2'],
        homeData: ['0', '1', '0', '1', '1', '2', '2', '1'],
        visitorR: 8,
        homeR: 8,
    },
    {
        balls: 0,
        strikes: 0,
        outs: 0,
        visitorData: ['1', '0', '0', '2', '0', '1', '2', '2', '1'],
        homeData: ['0', '1', '0', '1', '1', '2', '2', '1', '1'],
        visitorR: 8,
        homeR: 9,
    },
];

/*
    Fully scripted "game day" for the first game in MVP,
    make generic~ system if game days going to be similar. For example like cafe-match customers
*/
export class GameDay0Flow extends GameDayFlow {
    // fields
    //-------------------------------------------------------------------------

    // init
    //-------------------------------------------------------------------------
    constructor(opts: GameDayFlowOpts) {
        super(opts);
        this._home = app().server.state.name;
        this._visitor = textLocaleFormat('[gameDay0Opponent]');
        this._opponent = opts.opponentOverride ?? textLocaleFormat('[gameDay0Opponent]');
        this._scoreboard = 'board0';
        this._gameDaySteps = [
            { handler: this._intro, substeps: this._introSubSteps },
            { handler: this._step1, substeps: 1 },
            { handler: this._step2, substeps: 1 },
            { handler: this._step3, substeps: 2 },
            { handler: this._step4, substeps: 1 },
            { handler: this._step5, substeps: 1 },
            { handler: this._stepEnding, substeps: this._endingSubSteps },
        ];
    }

    // impl
    //-------------------------------------------------------------------------
    private async _intro() {
        await this._baseIntro({
            scoreData: scoreboardData[0],
            tapDialog: `[gameDay0Step0|${this._home}|${this._visitor}]`,
            nextBgId: 'gameDay0',
        });
    }

    private async _step1() {
        let stepIndex = this._stepOffset[this._step];
        if (!this._gameDayScreen) {
            this._gameDayScreen = (await app().nav.open('gameDayScreen', {
                bgId: 'gameDay0',
                players: [
                    {
                        clip: 'onbase1_flipped',
                        loop: true,
                        skin: 'character5_boy',
                        offsetX: 20,
                    } as GameDayPlayer,
                ],
            })) as GameDayScreen;
        }

        void app().nav.preload('gameDayScreen', { bgId: 'gameDay1' });
        await sleep(1.25);

        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay1',
            players: [
                {
                    clip: 'onbase3',
                    loop: true,
                    skin: 'character4_girl',
                },
            ] as GameDayPlayer[],
        })) as GameDayScreen;

        void app().nav.preload('gameDayScreen', { bgId: 'gameDay2' });
        await sleep(1.25);

        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay2',
            players: [
                {
                    clip: 'batter_idle',
                    loop: true,
                    skin: 'character1_boy',
                } as GameDayPlayer,
            ],
        })) as GameDayScreen;

        const tapPromise1 = new NakedPromise();
        await this._gameDayScreen.spawnBubbleTap({
            dialogText: `[gameDay0Step1]`,
            promise: tapPromise1,
            bubbleOffset: { x: 0, y: this._gameDayScreen.base.height * 0.5 - this._gameDayScreen.base.height * 0.22 },
            parentOverride: this._gameDayScreen.base,
        });

        void this._gameDayScreen.preloadChoiceView();
        await tapPromise1;

        trackGameDayStep({ stepIndex: stepIndex++, stepName: 'players', stepType: 'tap' });
    }

    private async _step2() {
        let stepIndex = this._stepOffset[this._step];
        if (!this._gameDayScreen) {
            this._gameDayScreen = (await app().nav.open('gameDayScreen', {
                bgId: 'gameDay2',
                players: [{ clip: 'batter_idle', loop: true, skin: 'character1_boy' } as GameDayPlayer],
            })) as GameDayScreen;
        }

        const choicePromise = new NakedPromise<number>();
        void this._gameDayScreen.spawnBubbleChoice({
            dialogText: '[gameDay0Step2]',
            choices: ['[gameDay0Choice0]', '[gameDay0Choice1]'],
            promise: choicePromise,
            bubbleOffset: { x: 0, y: this._gameDayScreen.base.height * 0.5 - this._gameDayScreen.base.height * 0.22 },
            parentOverride: this._gameDayScreen.base,
        });

        void app().nav.preload('gameDayScreen', { bgId: 'gameDay3' });

        // preload sfx
        void app().resource.loadAsset(this._batSFX);

        const choiceIndex = await choicePromise;

        trackGameDayStep({ stepIndex: stepIndex++, stepName: 'first_choice', stepType: 'choice' });

        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay3',
            players: [{ skin: 'character3_boy_red', clip: 'pitcher_idle_flip', loop: true } as GameDayPlayer],
        })) as GameDayScreen;

        await sleep(0.5);

        await this._gameDayScreen.playPlayerAnimation({ id: 'pitcher_throw_flipped' });

        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay2',
            players: [{ skin: 'character1_boy', clip: 'batter_idle' } as GameDayPlayer],
        })) as GameDayScreen;

        await sleep(0.2);

        const choiceAction = [
            // choice 1
            async () => this._gameDayScreen.playPlayerAnimation({ id: 'batter_homerun' }),
            // choice 2
            async () => this._gameDayScreen.playPlayerAnimation({ id: 'batter_bunt' }),
        ];

        const delay = choiceIndex === 0 ? 0.25 : 0.11;
        void sleep(delay).then(() => app().sound.play(this._batSFX));

        await choiceAction[choiceIndex]();

        const choiceClipId: PlayerClipId[] = [
            // choice 1
            'pitcher_watchhomerun',
            // choice 2
            'pitcher_watchbunt',
        ];

        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay3',
            players: [{ skin: 'character3_boy_red', clip: choiceClipId[choiceIndex], loop: false } as GameDayPlayer],
        })) as GameDayScreen;

        await sleep(choiceIndex === 0 ? 1.4 : 1.8);
    }

    private async _step3() {
        let stepIndex = this._stepOffset[this._step];
        // girl run
        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay2',
            players: [
                {
                    skin: 'character4_girl',
                    clip: 'run',
                    offsetX: 200,
                    offsetY: 40,
                    loop: true,
                    scaleX: -1,
                    animateRun: true,
                } as GameDayPlayer,
            ],
        })) as GameDayScreen;

        const runPromise = new NakedPromise();
        await this._gameDayScreen.spawnBubbleTap({
            dialogText: `[gameDay0Step3]`,
            promise: runPromise,
            narrator: 'arrow',
            bubbleOffset: { x: 0, y: this._gameDayScreen.base.height - 150 },
            parentOverride: this._gameDayScreen.base,
        });

        void this._gameDayScreen.preloadScoreboard(this._scoreboard);
        await runPromise;

        trackGameDayStep({ stepIndex: stepIndex++, stepName: 'run', stepType: 'tap' });

        // do a transition for score panel as well (part of feedback)
        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay2',
        })) as GameDayScreen;

        void this._gameDayScreen.spawnScoreDynamic({
            ...scoreboardData[1],
            visitor: this._visitor,
            home: this._home,
            scoreboard: this._scoreboard,
        });

        const scorePromise = new NakedPromise();
        await this._gameDayScreen.spawnBubbleTap({
            dialogText: `[gameDay0Step4]`,
            promise: scorePromise,
            narrator: 'arrow',
            bubbleOffset: { x: 0, y: this._gameDayScreen.base.height - 150 },
            parentOverride: this._gameDayScreen.base,
        });

        await scorePromise;

        trackGameDayStep({ stepIndex: stepIndex++, stepName: 'run_score', stepType: 'tap' });
    }

    private async _step4() {
        let stepIndex = this._stepOffset[this._step];
        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay0',
            players: [
                {
                    skin: 'character2_girl',
                    offsetX: 60,
                    offsetY: -4,
                    scale: 0.81,
                    clip: 'baseman_stressed_idle',
                    loop: true,
                } as GameDayPlayer,
                {
                    skin: 'character3_boy_red',
                    offsetX: -115,
                    offsetY: 60,
                    scale: 0.81,
                    clip: 'onbase2_flipped',
                    loop: true,
                } as GameDayPlayer,
            ],
        })) as GameDayScreen;

        const dualPromise = new NakedPromise();
        await this._gameDayScreen.spawnBubbleTap({
            dialogText: `[gameDay0Step5]`,
            promise: dualPromise,
            narrator: 'arrow',
            bubbleOffset: { x: 0, y: this._gameDayScreen.base.height - 150 },
            parentOverride: this._gameDayScreen.base,
        });

        void this._gameDayScreen.preloadChoiceView();

        await dualPromise;
        trackGameDayStep({ stepIndex: stepIndex++, stepName: 'two_players', stepType: 'tap' });
    }

    private async _step5() {
        let stepIndex = this._stepOffset[this._step];
        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay3',
            players: [{ skin: 'character1_boy', clip: 'pitcher_idle_flip', loop: true } as GameDayPlayer],
        })) as GameDayScreen;

        const choicePromise = new NakedPromise<number>();
        void this._gameDayScreen.spawnBubbleChoice({
            dialogText: '[gameDay0Step6]',
            choices: ['[gameDay0Choice2]', '[gameDay0Choice3]'],
            promise: choicePromise,
            bubbleOffset: { x: 0, y: this._gameDayScreen.base.height * 0.5 - this._gameDayScreen.base.height * 0.22 },
            parentOverride: this._gameDayScreen.base,
        });

        const choiceIndex = await choicePromise;

        trackGameDayStep({ stepIndex: stepIndex++, stepName: 'second_choice', stepType: 'choice' });

        const choiceAction = [
            // choice 1
            async () => this._gameDayScreen.playPlayerAnimation({ id: 'pitcher_throw_flipped' }),
            // choice 2
            async () => this._gameDayScreen.playPlayerAnimation({ id: 'pitcher_throw_tofirst' }),
        ];

        void app().resource.loadAsset(choiceIndex === 0 ? this._whiffSFX : this._catchSFX);

        await choiceAction[choiceIndex]();

        const choiceAction2 = [
            async () => {
                this._gameDayScreen = (await app().nav.open('gameDayScreen', {
                    bgId: 'gameDay2',
                    players: [{ skin: 'character6_girl_red2', clip: 'batter_strikeout', loop: false }],
                })) as GameDayScreen;
            },
            async () => {
                this._gameDayScreen = (await app().nav.open('gameDayScreen', {
                    bgId: 'gameDay0',
                    players: [
                        {
                            skin: 'character3_boy_red',
                            offsetX: -115,
                            offsetY: 60,
                            scale: 0.81,
                            clip: 'onbase1_flipped',
                            loop: true,
                        } as GameDayPlayer,
                        {
                            skin: 'character2_girl',
                            offsetX: 60,
                            offsetY: -4,
                            scale: 0.81,
                            clip: 'baseman_catch',
                            loop: false,
                        } as GameDayPlayer,
                    ],
                })) as GameDayScreen;
            },
        ];

        void choiceAction2[choiceIndex]();

        const delay = choiceIndex === 0 ? 0.85 : 1;
        void sleep(delay).then(() => app().sound.play(choiceIndex === 0 ? this._whiffSFX : this._catchSFX));

        await sleep(1.9);
    }

    private async _stepEnding() {
        await this._baseVictory({ scoreData: scoreboardData[2], tapDialog: '[gameDay0Step7]' });
    }
}
