//TODO: cleanup and simplify
import { Filter, utils } from '@pixi/core';

import fragment from './gradient.frag';
import vertex from './gradient.vert';

// types
//-----------------------------------------------------------------------------
export type ColorStop = {
    offset: number;
    color: number;
    alpha: number;
};

export type DefaultOptions = {
    type: number;
    stops: ColorStop[];
    angle?: number;
    alpha?: number;
};

export type CssOptions = {
    css: string;
    alpha?: number;
    maxColors?: number;
};

const ANGLE_OFFSET = 90; // align degrees with CSS

function sortColorStops(stops: ColorStop[]): ColorStop[] {
    return [...stops].sort((a, b) => a.offset - b.offset);
}

/*
    ColorGradientFilter from pixi but with absolute alpha
*/
export class GradientFilter extends Filter {
    // constants
    //-------------------------------------------------------------------------
    static readonly LINEAR = 0;
    static readonly RADIAL = 1;
    static readonly CONIC = 2;

    public static readonly defaults: DefaultOptions = {
        type: GradientFilter.LINEAR,
        stops: [
            { offset: 0.0, color: 0xff0000, alpha: 1.0 },
            { offset: 1.0, color: 0x0000ff, alpha: 1.0 },
        ],
        alpha: 1.0,
        angle: 90.0,
    };

    // fields
    //-------------------------------------------------------------------------
    private _stops: ColorStop[] = [];

    // properties
    //-------------------------------------------------------------------------
    set type(value: number) {
        this.uniforms.uType = value;
    }

    get type(): number {
        return this.uniforms.uType;
    }

    set angle(value: number) {
        this.uniforms.uAngle = value - ANGLE_OFFSET;
    }

    get angle(): number {
        return this.uniforms.uAngle + ANGLE_OFFSET;
    }

    set alpha(value: number) {
        this.uniforms.uAlpha = value;
    }

    get alpha(): number {
        return this.uniforms.uAlpha;
    }

    set maxColors(value: number) {
        this.uniforms.uMaxColors = value;
    }

    get maxColors(): number {
        return this.uniforms.uMaxColors;
    }

    get stops(): ColorStop[] {
        return this._stops;
    }

    set stops(stops: ColorStop[]) {
        const sortedStops = sortColorStops(stops);

        const colors = new Float32Array(sortedStops.length * 3);
        const R = 0;
        const G = 1;
        const B = 2;

        for (let i = 0; i < sortedStops.length; i++) {
            const color = utils.hex2rgb(sortedStops[i].color);
            const indexStart = i * 3;

            colors[indexStart + R] = color[R];
            colors[indexStart + G] = color[G];
            colors[indexStart + B] = color[B];
        }

        this.uniforms.uColors = colors;
        this.uniforms.uOffsets = sortedStops.map((s) => s.offset);
        this.uniforms.uAlphas = sortedStops.map((s) => s.alpha);
        this.uniforms.uNumStops = sortedStops.length;
        this._stops = sortedStops;
    }

    // init
    //-------------------------------------------------------------------------
    constructor(options?: DefaultOptions | CssOptions) {
        const options_ = { ...GradientFilter.defaults, ...options };
        super(vertex, fragment);
        this.autoFit = false;

        Object.assign(this, options_);
    }
}
