import { Sprite, Texture } from 'pixi.js';

import { uiAlignBottom, uiAlignCenterX } from '../../../lib/pixi/uiTools';
import { BasicText } from '../../lib/ui/text/BasicText';

// constants
//-----------------------------------------------------------------------------
const manifest = {
    panel: 'puzzle.score.panel.png',
    lvlBg: 'puzzle.goal.level.png',
};

/*
    puzzle moves panel
*/
export class PuzzleMovesPanel extends Sprite {
    // fields
    //-------------------------------------------------------------------------
    // scene
    private _moves: BasicText;
    private _level: BasicText;
    private _levelBg: Sprite;

    // properties
    //-------------------------------------------------------------------------
    public set moves(value: number) {
        this._moves.text = value.toString();
        uiAlignCenterX(this, this._moves, 11);
    }

    // init
    //-------------------------------------------------------------------------
    constructor() {
        // init base
        super(Texture.from(manifest.panel));

        // spawn title text
        this._spawnTitle();

        // spawn moves text
        this._spawnMoves();

        // spawn level
        this._spawnLevel();
    }

    static assets(): string[] {
        return Object.values(manifest);
    }

    // api
    //-------------------------------------------------------------------------
    public setLevel(level: number) {
        this._level.text = `[puzzleLevel|${level}]`;
        uiAlignCenterX(this._levelBg, this._level, 9);
    }

    // private: scene
    //-------------------------------------------------------------------------
    private _spawnTitle() {
        // spawn
        const widget = this.addChild(
            new BasicText({
                text: '[puzzleMoves]',
                style: {
                    fill: '#FCFFD5',
                    fontSize: 34,
                    lineJoin: 'round',
                    stroke: '#092933',
                    strokeThickness: 4,
                    fontWeight: 'bold',
                    padding: 6,
                    dropShadow: true,
                    dropShadowBlur: 3,
                    dropShadowAlpha: 0.35,
                    dropShadowDistance: 3,
                    dropShadowAngle: Math.PI / 2,
                },
            }),
        );
        uiAlignCenterX(this, widget, 8);
        widget.y = -12;
    }

    private _spawnMoves() {
        // create
        const widget = (this._moves = this.addChild(
            new BasicText({
                style: {
                    fill: '#FEFFF3',
                    fontSize: 70,
                    lineJoin: 'round',
                    stroke: '#C17127',
                    strokeThickness: 6,
                    fontWeight: 'bold',
                    padding: 10,
                },
                text: '',
            }),
        ));
        widget.y = 54;
    }

    private _spawnLevel() {
        const bg = (this._levelBg = this.addChild(Sprite.from(manifest.lvlBg)));

        const text = (this._level = bg.addChild(
            new BasicText({
                style: {
                    fill: '#FCFFD5',
                    fontSize: 22,
                    lineJoin: 'round',
                    stroke: '#092933',
                    strokeThickness: 4,
                    fontWeight: 'bold',
                    padding: 6,
                    dropShadow: true,
                    dropShadowBlur: 3,
                    dropShadowAlpha: 0.25,
                    dropShadowDistance: 3,
                    dropShadowAngle: Math.PI / 2,
                },
            }),
        ));
        text.y = 0;
        text.x = 20;
        uiAlignCenterX(bg, text);
        uiAlignCenterX(this, bg);
        uiAlignBottom(this, bg, -28);
    }
}
