import { Layout } from '@pixi/layout';
import { FlexContainer } from '@play-co/flex';
import { NineSlicePlane, Sprite, Texture } from 'pixi.js';

import { BasicAsyncHandler } from '../../../../lib/defs/types';
import { uiAlignCenterX } from '../../../../lib/pixi/uiTools';
import { ProductDef } from '../../../../replicant/defs/product';
import { TextImageButton } from '../../../lib/ui/buttons/TextImageButton';
import { manifest as shopManifest } from '../../shop/manifest';
import { BuyCompleteItems } from './BuyCompleteItems';
import app from '../../../getApp';
import { PopupOptions, PopupScreen } from '../../../lib/screens/PopupScreen';

// types
//-----------------------------------------------------------------------------
export type BuyCompletePopupOptions = {
    product: ProductDef;
    onContinue: BasicAsyncHandler;
} & PopupOptions;

// constants
//-----------------------------------------------------------------------------
const manifest = {
    bg: 'frame.popup.yellow.png',
    button: 'button.green.png',
    panel: 'panel.purple.glow.png',
};
const lazyManifest = {
    bonusEN: 'label.complete.en.png',
    bonusJA: 'label.complete.jp.png',
};

const WIDTH = 692;
const HEIGHT = 689;

export class BuyCompletePopup extends PopupScreen {
    // fields
    //-------------------------------------------------------------------------
    // events
    public onContinue: BasicAsyncHandler;
    // scene
    protected main: Layout;

    private _claimButton: TextImageButton;

    public get claimButton(): TextImageButton {
        return this._claimButton;
    }

    // impl
    //-------------------------------------------------------------------------
    public preload() {
        const lang = app().settings.language;
        const assets = [...Object.values(manifest), ...Object.values(shopManifest)];
        lang === 'en' ? assets.push(lazyManifest.bonusEN) : assets.push(lazyManifest.bonusJA);
        return app().resource.loadAssets(assets);
    }

    public override async spawning(options: BuyCompletePopupOptions) {
        void super.spawning({
            ...options,
            width: WIDTH,
            height: HEIGHT,
            underlay: 0.6,
        });

        this.onContinue = options.onContinue;

        const panel = Sprite.from(manifest.panel);
        const panelContainer = panel.addChild(
            new FlexContainer({
                width: panel.width,
                height: panel.height,
                direction: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }),
        );
        panelContainer.addChild(new BuyCompleteItems(options.product));
        panelContainer.doLayout();

        const titleAsset = app().settings.language === 'en' ? lazyManifest.bonusEN : lazyManifest.bonusJA;
        const title = Sprite.from(titleAsset);
        this.baseLayout.addContent({
            bg: {
                content: new NineSlicePlane(Texture.from(manifest.bg), 95, 128, 32, 40).props({
                    width: WIDTH,
                    height: HEIGHT,
                }),
                styles: {
                    width: WIDTH,
                    height: HEIGHT,
                },
            },
            header: {
                content: title,
                styles: {
                    position: 'topCenter',
                    marginTop: -36,
                },
            },
            main: {
                content: panel,
                styles: {
                    position: 'topCenter',
                    marginTop: 88,
                },
            },
        });

        if (this.onContinue) {
            const okButton = (this._claimButton = new TextImageButton({
                text: '[buttonContinue]',
                image: manifest.button,
                y: -5,
                slice: {
                    width: 300,
                    height: 122,
                    left: 30,
                    top: 16,
                    right: 30,
                    bottom: 32,
                },
                style: {
                    fill: 'FFF',
                    fontSize: 40,
                    fontWeight: 'bold',
                    lineJoin: 'round',
                    fontStyle: 'normal',
                    dropShadow: true,
                    dropShadowDistance: 1,
                },
            }));
            okButton.props({
                y: this.baseLayout.height - 100,
                pivot: { x: okButton.width * 0.5, y: okButton.height * 0.5 },
                onPress: async () => this.onContinue?.(),
            });
            uiAlignCenterX(this.baseLayout, okButton); // Align with pivot to enable scale animation from center
            this.baseLayout.addChild(okButton);
        }
    }
}
