export const manifest = {
    listItemBg: 'frame.popup.yellow.png',
    statsPanel: 'panel.stats.png',
    buttonClose: 'button.close.png',
    buyButton: 'button.main.green.png',
    header: 'frame.shop.header.png',
    bg: 'bg.shop.png',
    purpleBg: 'badge.bundle.purple.png',
    redBg: 'badge.bundle.red.png',
    yellowBg: 'badge.bundle.yellow.png',
    labelEn: 'label.shop.en.png',
    labelJa: 'label.shop.ja.png',
    badgeOneTimeOnlyEn: 'badge.one.time.only.en.png',
    badgeOneTimeOnlyJa: 'badge.one.time.only.ja.png',
    coin: 'coin.icon.png',
    coins1: 'coins.1.png',
    coins2: 'coins.2.png',
    coins3: 'coins.3.png',
    coins4: 'coins.4.png',
    coins5: 'coins.5.png',
    coins6: 'coins.6.png',
    panelItemCorner: 'panel.shop.item.corner.png',
    panelItem: 'panel.shop.item.png',
    panelLabelCyan: 'panel.shop.label.cyan.png',
    panelLabelPurple: 'panel.shop.label.purple.png',
    panelLabelRed: 'panel.shop.label.red.png',
    panelLabelYellow: 'panel.shop.label.yellow.png',
    unlimitedLivesIcon: 'icon.unlimited.lives.png',
    iconFan: 'icon.shop.fan.png',
    iconBomb: 'icon.shop.bomb.png',
    iconBaseball: 'icon.shop.baseball.png',
    iconFirecracker: 'icon.shop.firecracker.png',
    iconFireball: 'icon.shop.fireball.png',
    iconRunner: 'icon.shop.runner.png',
    iconBat: 'icon.shop.bat.png',
};
