import { gsap } from 'gsap';
import { NineSlicePlane, Sprite, Texture } from 'pixi.js';
import { BasicHandler, BoundsType, PositionType, SizeType } from '../../../lib/defs/types';
import { uiAlignCenterX, uiAlignCenterY, uiAlignTop } from '../../../lib/pixi/uiTools';
import { tween } from '../../../lib/util/tweens';
import { BoosterId, boosterMap } from '../../../replicant/defs/booster';
import { LayoutScreen2 } from '../../lib/screens/LayoutScreen2';
import { TargetSpotLight } from '../../lib/ui/lights/TargetSpotLight';
import { Pointer } from '../../lib/ui/Pointer';
import { BasicText } from '../../lib/ui/text/BasicText';
import { blockIterateAll } from '../match2-odie/util/blockTools';
import { mapFromGlobalPosition } from '../match2-odie/util/mapTools';
import { PuzzleController } from './controller/PuzzleController';
import { PuzzleScreen } from './PuzzleScreen';
import app from '../../getApp';

// types
//-----------------------------------------------------------------------------
export type BoosterTipPopupOptions = {
    id: BoosterId;
    showPointer: boolean;
    onActivate: (position: PositionType) => void;
    onClose: BasicHandler;
};

// constants
//-----------------------------------------------------------------------------
const manifest = {
    bg: 'frame.puzzletip.png',
};

const animation = {
    duration: 0.5,
    message: {
        from: -240,
        to: 80,
    },
};

/*
    booster activator popup
*/
export class BoosterActivatorPopup extends LayoutScreen2 {
    // fields
    //-------------------------------------------------------------------------
    // input
    private _options: BoosterTipPopupOptions;
    // scene
    private _spotlight: TargetSpotLight;
    private _message: NineSlicePlane;
    private _pointer: Pointer;
    // state
    private _controller: PuzzleController;

    // impl
    //-------------------------------------------------------------------------
    public preload(options: Partial<BoosterTipPopupOptions>) {
        return app().resource.loadAssets([...Object.values(manifest), boosterMap[options.id].asset]);
    }

    public async spawning(options?: BoosterTipPopupOptions) {
        this._options = options;
        this._controller = (app().nav.screens.puzzleScreen?.instance as PuzzleScreen)?.controller;
    }

    public async spawned() {
        // spawn
        this._spawnScreen();
        this._spawnMessage();

        // FTUE
        if (this._options.showPointer) {
            this._spawnPointer();
        }

        // animate
        await this._animateIn();
    }

    public override async despawning() {
        void super.despawning();
        // animate
        await this._animateOut();
    }

    public despawned() {
        // root is not layout, use call directly to remove all children
        this.root.removeChildren();
    }

    public override resized(size: SizeType): void {
        super.resized(size);
    }

    // private: events
    //-------------------------------------------------------------------------
    private _onTap(globalPosition: PositionType) {
        // get map position of tap
        const position = mapFromGlobalPosition(globalPosition);

        // get map cell at this position
        const cell = this._controller.screen.scene.sessionEntity.c.map.getCellAt(position);

        // if position on map
        if (cell) {
            // if cell enabled, activate
            if (cell.enabled) this._options.onActivate?.(position);
        }
        // else close
        else this._options.onClose?.();
    }

    // private: scene
    //-------------------------------------------------------------------------
    private _spawnScreen() {
        const screen = this._controller.screen;
        const targets: BoundsType[] = [];

        // collect block targets
        blockIterateAll(this._controller.screen.scene, (base) => {
            const bounds = base.view.getBounds();
            bounds.width /= this.scale;
            bounds.height /= this.scale;
            targets.push(bounds);
        });

        // get button target
        const bounds = screen.footer.boosterButtons[this._options.id].getBounds();
        bounds.width /= this.scale;
        bounds.height /= this.scale;
        targets.push(bounds);

        // spawn spotlight on targets
        const spotlight = (this._spotlight = this.root.addChild(
            new TargetSpotLight({
                width: this.size.width,
                height: this.size.height,
                targets,
                pad: 2,
                shape: 'square',
            }),
        ));

        // enable input on the spotlight screen
        spotlight.interactive = true;

        // tapping the screen triggers close request
        spotlight.on('pointertap', (event) => this._onTap(event.data.global));

        // start spotlight
        spotlight.start();
    }

    private _spawnMessage() {
        const booster = boosterMap[this._options.id];

        // spawn background
        const bg = (this._message = new NineSlicePlane(Texture.from(manifest.bg), 90, 60, 34, 42));
        bg.width = 600;
        bg.height = 204;
        this.root.addChild(bg);
        uiAlignCenterX(this.size, bg);

        // spawn icon
        const icon = Sprite.from(boosterMap[this._options.id].asset);
        icon.scale.set(1.3);
        icon.x = 42;
        bg.addChild(icon);
        uiAlignCenterY(bg, icon, -6);
        const x = icon.x + icon.width + 20;

        // spawn title
        const title = bg.addChild(
            new BasicText({
                text: booster.text,
                style: {
                    fill: '#3E4047',
                    fontWeight: 'bold',
                    fontSize: 42,
                    lineJoin: 'round',
                },
            }),
        );
        title.x = x;
        title.y = 40;

        // spawn description
        const desc = bg.addChild(
            new BasicText({
                text: booster.tip,
                style: {
                    fill: '#3E4047',
                    fontWeight: 'bold',
                    fontSize: 30,
                    lineJoin: 'round',
                },
            }),
        );
        desc.x = x;
        desc.y = 92;
    }

    private _spawnPointer() {
        const { id } = this._options;
        const yOffset = {
            dart: 0,
            bullet: 0,
            drill: 70,
            roulette: 0,
        };

        this._pointer = new Pointer({ type: 'hand' });
        this._pointer.anchor.set(0.5);

        this.root.addChild(this._pointer);

        // middle of the screen X
        uiAlignCenterX(this.size, this._pointer, 140);
        // middle of the board Y, the board itself is aligned at the top
        uiAlignTop(this.size, this._pointer, 810 + yOffset[id]);

        const timeline = gsap.timeline();
        timeline.to(this._pointer, { rotation: 0.5, duration: 0.45, ease: 'power1.out' });
        timeline.play().repeat(-1).repeatDelay(0.2).yoyo(true);
    }

    // private: animation
    //-------------------------------------------------------------------------
    private async _animateIn() {
        // set initial values
        this._spotlight.alpha = 0;

        if (this._pointer) {
            this._pointer.alpha = 0;
        }

        this._message.y = animation.message.from;

        // fade in spotlight screen
        this._spotlight.animate().add(this._spotlight, { alpha: 1 }, animation.duration, tween.pow2Out);

        if (this._pointer) {
            this._pointer.animate().add(this._pointer, { alpha: 1 }, animation.duration, tween.pow2Out);
        }

        // pop in message
        await this._message
            .animate()
            .add(this._message, { y: animation.message.to }, animation.duration, tween.backOut());
    }

    public async _animateOut() {
        // fade out spotlight screen
        this._spotlight.animate().add(this._spotlight, { alpha: 0 }, animation.duration, tween.pow2In);

        if (this._pointer) {
            this._pointer.animate().add(this._pointer, { alpha: 0 }, animation.duration, tween.pow2In);
        }

        // pop out message
        await this._message
            .animate()
            .add(this._message, { y: animation.message.from }, animation.duration, tween.backIn());
    }
}
