import { waitFor } from '@play-co/astro';

import NakedPromise from '../../../../lib/pattern/NakedPromise';
import TaskQueue from '../../../../lib/pattern/TaskQueue';
import UpdateObserver from '../../../../lib/pattern/UpdateObserver';
import { IScreenController } from '../../../../plugins/nav/IScreenController';
import {
    BoosterId,
    boosterIds,
    boosterMap,
    boosterToProductId,
    PowerBoosterId,
} from '../../../../replicant/defs/booster';
import gameConfig from '../../../../replicant/defs/gameConfig';
import { coinsProductDefs } from '../../../../replicant/defs/product';
import { sleep } from '../../../../replicant/util/jsTools';
import { BoosterFlow } from '../../../flows/BoosterFlow';
import { BoosterIntroFlow } from '../../../flows/BoosterIntroFlow';
//import { PuzzleTipHelper } from './PuzzleTipHelper';
import { PuzzleCompleteFlow } from '../../../flows/PuzzleCompleteFlow';
import { PuzzleTutorialFlow } from '../../../flows/PuzzleTutorialFlow';
import {
    PinchEventTrigger,
    ShopPinchRestore,
    trackNoMovesPinchConversion,
    trackPinchConversion,
    trackPinchEvent,
} from '../../../lib/analytics/pinch';
import { PuzzleMapExporter } from '../../../lib/porters/mapPorter/PuzzleMapExporter';
import { PuzzleMapImporter } from '../../../lib/porters/mapPorter/PuzzleMapImporter';
import { PowerBlockType } from '../../match2-odie/defs/block';
import { EventId, GameEvent, GoalEvent, MoveEvent, RewardEvent, RoundEvent } from '../../match2-odie/defs/event';
import { GoalId } from '../../match2-odie/defs/goal';
import { GoalsDef, MapDef } from '../../match2-odie/defs/map';
import { MysteryRewardId } from '../../match2-odie/defs/reward';
import { BlockEntity } from '../../match2-odie/entities/BlockEntity';
import { mapGetAssets } from '../../match2-odie/util/mapTools';
import { PuzzleGoalsPanel } from '../PuzzleGoalsPanel';
import { PuzzleScreen } from '../PuzzleScreen';
import { PuzzleReactionController } from './PuzzleReactionController';
import { PuzzleTipHelper } from './PuzzleTipHelper';
import app from '../../../getApp';

// types
//-----------------------------------------------------------------------------
export type PuzzleCompleteHandler = (results: PuzzleResults) => void;

export type PuzzleControllerOptions = {
    editorMode?: boolean;
    tutorialMode?: boolean;
    onComplete?: PuzzleCompleteHandler;
    powerBoosters?: PowerBlockType[];
    restoreState?: boolean;
    shopPinch?: ShopPinchRestore;
};

export type RewardState = { [key in MysteryRewardId]?: boolean };

// DO NOT change result types from 'success', 'fail' and 'quit' since these are used for analytics as well
// Additional result types can be added if needed
export type PuzzleResults =
    | {
          result: 'success';
          rewards: RewardState;
      }
    | { result: 'fail' }
    | { result: 'quit' };

type BoosterCounts = { [key in BoosterId]?: number };

// constants
//-----------------------------------------------------------------------------
const musicMap = ['happy-summer.ogg'];

/*
    puzzle screen controller. this is a high level control of the game that ties
    in the surrounding UI, and external command flows.
*/
export class PuzzleController implements IScreenController {
    // fields
    //-------------------------------------------------------------------------
    // input
    private readonly _screen: PuzzleScreen;
    private _editorMode: boolean;
    private _tutorialMode: boolean;
    private _onComplete?: PuzzleCompleteHandler;
    // state
    private _complete: boolean;
    private _boosterViewOpen: boolean;
    //private _goalCounts: { [key in GoalId]?: number };
    private _rewardState: RewardState;
    private _mapDef: MapDef;
    private _mapLevel: number;
    private _taskQueue: TaskQueue;
    private _time: number;
    private _idlePromise: NakedPromise;
    private _exiting: boolean;
    // components
    private _updates = new UpdateObserver();
    private _tips: PuzzleTipHelper;
    private _reactions: PuzzleReactionController;
    // stats
    private _attackCount: number;
    private _giftCount: number;
    private _continueCount: number;
    private _usedBoosterCounts: BoosterCounts;
    private _usedPowerBoosterCounts: Record<PowerBoosterId, number>;
    // handlers
    private _gameEventHandler = (event: GameEvent) => this._gameEventHandlers[event.id]?.call(this, event);
    // maps
    private readonly _gameEventHandlers: { [key in EventId]?: (event: GameEvent) => void } = {
        goal: this._onGameGoalEvent,
        move: this._onGameMoveEvent,
        reward: this._onGameRewardEvent,
        round: this._onGameRoundEvent,
        ungoal: this._onGameUngoalEvent,
    };

    // properties
    //-------------------------------------------------------------------------
    public get active(): boolean {
        return !!this.screen.scene?.active;
    }

    public get editorMode(): boolean {
        return this._editorMode;
    }

    public get tutorialMode(): boolean {
        return this._tutorialMode;
    }

    public get moves(): number {
        return this._screen.scene.sessionEntity.c.phase.moves;
    }

    private set moves(moves: number) {
        this._screen.scene.sessionEntity.c.phase.moves = moves;
    }

    public get moveCounter(): number {
        return this._screen.scene.sessionEntity.c.phase.moveCounter;
    }

    public get usedBoosters(): BoosterCounts {
        return this._usedBoosterCounts;
    }

    public get usedPowerBoosterCounts(): Record<PowerBoosterId, number> {
        return this._usedPowerBoosterCounts;
    }

    public get map(): MapDef {
        return this._mapDef;
    }

    public get mapLevel(): number {
        return this._mapLevel;
    }

    public get screen(): PuzzleScreen {
        return this._screen;
    }

    public get time(): number {
        return this._time;
    }

    public get attackCount(): number {
        return this._attackCount;
    }

    public get giftCount(): number {
        return this._giftCount;
    }

    public get continueCount(): number {
        return this._continueCount;
    }

    // init
    //-------------------------------------------------------------------------
    constructor(screen: PuzzleScreen) {
        this._screen = screen;
        this._tips = new PuzzleTipHelper(screen);
        this._reactions = new PuzzleReactionController(screen);
    }

    // impl
    //-------------------------------------------------------------------------
    public async assets(options?: PuzzleControllerOptions): Promise<string[]> {
        const { map } = await this._selectMap(options);
        return map ? [...PuzzleGoalsPanel.goalAssets(map.goals), ...mapGetAssets(map, false)] : [];
    }

    public async restart() {
        // set exiting state
        this._exiting = true;

        // queue quit
        await this._taskQueue.add(async () => {
            // wait for idle
            await this._idlePromise;

            // reopen
            await app().nav.close('puzzle');
            //TODO: shouldnt need this. but some actions (like dog effect) happening in puzzle still, may need new 'exiting' phase.
            await waitFor(2000);
            await app().nav.open('puzzle', {
                editorMode: true,
            });
        });
    }

    public async prepare(options?: PuzzleControllerOptions) {
        const screen = this._screen;
        const scene = screen.scene;

        // set fields
        this._editorMode = !!options?.editorMode;
        this._tutorialMode = !!options?.tutorialMode;
        this._onComplete = options?.onComplete;

        // select map
        const { map, level } = await this._selectMap(options);

        // start preloading effects
        void app().resource.loadAssets(mapGetAssets(map, true));

        // init views
        screen.header.init();

        // init state
        this._mapDef = map;
        this._mapLevel = level;
        this._complete = false;
        this._rewardState = {};
        void this._initGoalState(map.goals, level);
        this._taskQueue = new TaskQueue();
        this._time = app().server.now();
        this._attackCount = 0;
        this._giftCount = 0;
        this._continueCount = 0;
        this._usedBoosterCounts = this._getEmptyBoosterCounts();

        this._usedPowerBoosterCounts = {
            rocket: 0,
            bomb: 0,
            cube: 0,
        };

        options.powerBoosters?.forEach((id) => {
            this._usedPowerBoosterCounts[id] = 1; // can only be 0 or 1 for analytics
        });

        this._idlePromise = new NakedPromise();
        this._exiting = false;

        // clear save state
        if (options.restoreState) {
            await app().server.invoke.puzzleSaveClear();
        }

        // start scene
        await scene.startGame(map);

        // post scene init state
        this._initMovesState(map.moves);

        // we're coming from a closed shop into puzzle restore
        // verify if we should resume into puzzle directly
        // if we pinched in liff and got enough coins now we completed a purchase, auto buy moves and resume puzzle
        if (options.restoreState && options?.shopPinch === 'moves') {
            if (app().game.player.coins >= gameConfig.lives.cost) {
                await app().server.invoke.coinsPurchase({ id: 'moves' });
                trackNoMovesPinchConversion(true);
                this.grantContinue(); // add moves so the puzzle can resume without no moves flow
            }
        }

        // register updates
        this._updates.listen(
            () => app().server.state.boosters,
            () => this._updateBoosters(),
        );

        // register events
        scene.events.subscribe(this._gameEventHandler);
        Object.entries(screen.footer.boosterButtons).forEach(
            ([id, button]) => (button.onPress = async () => this._onBooster(id as BoosterId)),
        );

        // play puzzle music
        void app().music.play(musicMap[(level - 1) % musicMap.length]);

        // start components
        this._updates.start();
        void this._reactions.start();

        // notify
        //app().core.messages.publish({ id: 'puzzleStarted' });

        // game start actions
        void sleep(0).then(async () => {
            // puzzle tutorial
            if (level === 1 && !app().server.state.tutorial.puzzle) {
                await new PuzzleTutorialFlow(this).execute();
            } else {
                await this._tips.tipBlock();
            }

            // check if booster intro should trigger
            const boosterIdFtue = this._getBoosterIdFtue();
            if (boosterIdFtue) {
                await new BoosterIntroFlow({ id: boosterIdFtue }).execute();
            }

            // spawn power boosters
            if (options?.powerBoosters)
                void this._screen.scene.powerBlockSystem.spawnPowerBoosters(options.powerBoosters);

            // initial update complete
            this._idlePromise.resolve();
            this._updateComplete();
        });
    }

    public hidden() {
        const scene = this._screen.scene;

        // stop components
        void this._reactions.stop();
        this._updates.stop();

        // pop stop puzzle music
        void app().music.stop();

        // unregister events
        scene.events.unsubscribe(this._gameEventHandler);

        // stop game
        scene.stopGame();
    }

    // api
    //-------------------------------------------------------------------------
    public grantContinue() {
        // reset complete state
        this._complete = false;

        // add moves
        this.moves += gameConfig.puzzle.continue.moves;

        // update stats
        ++this._continueCount;

        // update ui
        this._updateMoves();

        this._screen.scene.phaseSystem.phase = 'active';
    }

    public forceComplete() {
        // complete goals
        this._screen.scene.sessionEntity.c.map.completeGoals();

        // update
        this._idlePromise.resolve();
        this._updateComplete();
    }

    public forceFail() {
        // complete goals
        this.moves = 0;

        // update
        this._idlePromise.resolve();
        this._updateComplete();
    }

    // persistant state
    public loadState(expectedLevel: number): MapDef | undefined {
        const save = app().server.state.puzzle.save;
        if (save) {
            // require expected level
            if (save.level !== expectedLevel) return undefined;
            // import json string to def
            return new PuzzleMapImporter(save.state).import();
        }
        return undefined;
    }

    public saveState() {
        // export current map state to def
        const mapDef = this._screen.scene.sessionEntity.c.map.exportState();
        // export def to json string
        const state = new PuzzleMapExporter(mapDef).export();
        // write to server
        void app().server.invoke.puzzleSave({ level: this._mapLevel, state });
    }

    // private: init
    //-------------------------------------------------------------------------
    private async _initGoalState(goalDef: GoalsDef, level: number) {
        // init ui
        const header = this._screen.header;
        header.goals.setGoals(goalDef);
        header.moves.setLevel(level);
    }

    private _initMovesState(moves: number) {
        // init fields
        this.moves = moves;

        // init ui
        this._screen.header.moves.moves = moves;
    }

    private async _selectMap(options?: PuzzleControllerOptions): Promise<{ map: MapDef; level: number }> {
        const mapService = app().puzzleMap;

        // if editor mode, return editor map
        if (options?.editorMode) {
            return {
                map: mapService.editorMap,
                level: 0,
            };
            // else return current player map
        }

        // get current level
        const level = app().server.state.puzzle.level;

        // attempt to load existing state
        if (options.restoreState) {
            const map = this.loadState(level);
            if (map) return { map, level };
        }

        // load as new map
        return {
            map: await mapService.getMap(level),
            level,
        };
    }

    private _getEmptyBoosterCounts(): BoosterCounts {
        return boosterIds.reduce((acc: BoosterCounts, id: BoosterId) => {
            acc[id] = 0;
            return acc;
        }, {});
    }

    private _getBoosterIdFtue(): BoosterId | undefined {
        const boosterIds = Object.keys(boosterMap) as BoosterId[];
        let boosterFTUE;
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < boosterIds.length; i++) {
            const boosterId = boosterIds[i];
            const unlockLevel = boosterMap[boosterId].level;
            if (unlockLevel !== app().server.state.puzzle.level) {
                continue;
            }

            if (!app().server.state.boosters[boosterId].ftue) {
                boosterFTUE = boosterId;
                break;
            }
        }
        return boosterFTUE;
    }

    // private: events
    //-------------------------------------------------------------------------
    private _onGameGoalEvent(event: GoalEvent) {
        // update goal
        this._updateGoal(event.goalId, event.source);
    }

    private _onGameMoveEvent(event: MoveEvent) {
        // if not auto event, reset idle promise
        if (event.source !== 'auto') {
            this._idlePromise = new NakedPromise();
        }

        // if have enough moves and tap or auto source
        if (this.moves >= 0 && (event.source === 'tap' || event.source === 'auto')) {
            // update ui
            this._updateMoves();
        }
    }

    private _onGameRewardEvent(event: RewardEvent) {
        /*
        // handle reward
        switch (event.rewardId) {
            case 'attack':
                this._actionAttack(event.block);
                break;
            case 'gift':
                this._actionGift(event.block);
                break;
            default:
                this._setReward(event.rewardId, event.block);
        }
        */
    }

    private _onGameRoundEvent(event: RoundEvent) {
        // update complete state
        this._updateComplete();

        // resolve idle promise
        this._idlePromise.resolve();

        // show block tips if nothing else happening
        if (this._taskQueue.pending === 0) void this._tips.tipBlock();
    }

    private _onGameUngoalEvent(event: GoalEvent) {
        // update goal
        this._updateGoal(event.goalId, event.source);
    }

    private async _onBooster(id: BoosterId) {
        // do not allow spam click of different booster buttons which will break the button(s)
        if (this._boosterViewOpen) return;

        const count = app().server.state.boosters[id].count;
        if (count > 0) {
            this._boosterViewOpen = true;
            // run booster flow
            const didActivateBooster = await new BoosterFlow({ id }).execute();

            if (didActivateBooster) {
                this._usedBoosterCounts[id] += 1;
            }
            this._boosterViewOpen = false;
        } else {
            await this._onBuyBooster(id);
        }
    }

    private async _onBuyBooster(id: BoosterId): Promise<void> {
        const popup = 'purchasePopup';
        let reOpenPopup = true;
        let shopPinch = false;
        let coinsUsed = false;
        while (reOpenPopup) {
            const reOpenPromise = new NakedPromise<{ reOpen: boolean; pinched?: boolean; coinsConsumed?: boolean }>();
            await app().nav.open(popup, {
                id,
                onOk: async () => {
                    await app().nav.close(popup);
                    // purchase flow
                    const productId = boosterToProductId(id);
                    const def = coinsProductDefs[productId];
                    const state = app().server.state;
                    if (app().game.player.coins >= def.getCost(state)) {
                        await app().server.invoke.coinsPurchase({ id: productId });
                        this._updateBoosters();
                        reOpenPromise.resolve({ reOpen: false, coinsConsumed: true });
                    } else {
                        trackPinchEvent({ resource: 'coins', trigger: `purchase_${productId}` as PinchEventTrigger });
                        await this._openShop();
                        reOpenPromise.resolve({ reOpen: true, pinched: true });
                    }
                },
                onClose: async () => {
                    await app().nav.close(popup);
                    reOpenPromise.resolve({ reOpen: false });
                },
                onCoins: async () => {
                    await app().nav.close(popup);
                    await this._openShop();
                    reOpenPromise.resolve({ reOpen: true });
                },
            });

            void app().nav.preload('shop');
            const { reOpen, pinched, coinsConsumed } = await reOpenPromise;

            reOpenPopup = reOpen;
            shopPinch = !!pinched || shopPinch;
            coinsUsed = !!coinsConsumed || coinsUsed;
        }

        // pinched first and then bought coins and consumed them -> conversion
        if (shopPinch && coinsUsed) {
            const productId = boosterToProductId(id);
            trackPinchConversion({
                trigger: `purchase_${productId}`,
                action: 'purchase',
                resource: 'coins',
                feature: 'puzzle_booster',
                subFeature: `puzzle_booster_${productId}`,
                wasSurfacedByPinch: true,
            });
        }
    }

    // private: updates
    //-------------------------------------------------------------------------
    // get remaining goals
    private _updateComplete() {
        const remainingGoals = Object.values(this._screen.scene.sessionEntity.c.map.goals).reduce(
            (total, remaining) => total + remaining,
            0,
        );

        // if not already complete and if remaining goals is 0 or moves is 0
        if (!this._complete && (remainingGoals === 0 || this.moves === 0)) {
            // set complete
            this._complete = true;

            // enter none state
            this._screen.scene.phaseSystem.phase = 'none';

            // run complete action
            this._actionComplete(
                remainingGoals === 0
                    ? {
                          result: 'success',
                          rewards: this._rewardState,
                      }
                    : { result: 'fail' },
            );
        }
    }

    private _updateGoal(goalId: GoalId, source?: BlockEntity) {
        // get icon view for goal
        const icon = this._screen.header.goals.goalIcons[goalId];

        // queue update view with current count
        void icon.queueUpdate(() => this._screen.scene.sessionEntity.c.map.goals[goalId], source);
    }

    private _updateMoves() {
        // update ui
        this._screen.header.moves.moves = this.moves;
    }

    private _updateBoosters() {
        // update booster counts
        Object.entries(this._screen.footer.boosterButtons).forEach(([id, button]) => {
            const def = boosterMap[id as BoosterId];
            button.locked = this._mapLevel < def.level;
            button.count = app().server.state.boosters[id].count;
        });
    }

    // private: actions
    //-------------------------------------------------------------------------
    private _actionComplete(results: PuzzleResults) {
        // notify complete
        this._onComplete?.(results);
        // app().core.messages.publish({ id: 'puzzleComplete', results });

        // if not tutorial or editor mode
        if (!this._editorMode && !this._tutorialMode) {
            // queue complete action
            void this._taskQueue.add(async () => {
                // wait for idle
                await this._idlePromise;

                // fade music to low volume. so it doesnt interfere with complete sounds.
                void app().music.fade(0.6, 0.05);

                // if not exiting, execute complete command
                if (!this._exiting) await new PuzzleCompleteFlow(results, this).execute();
            });
        }
    }

    private async _openShop() {
        const closePromise = new NakedPromise();
        void app().nav.open('shop', { onClose: closePromise.resolve });
        await closePromise;
    }
}
