import { IFlow } from '../../lib/pattern/IFlow';
import NakedPromise from '../../lib/pattern/NakedPromise';
import { isPlatformNative, logoutNativeApp } from '../../lib/util/native';
import { openOA } from '../../lib/util/officialAccount';
import { LanguageId } from '../../replicant/defs/settings';
import app from '../getApp';
import { openURL } from '../lib/tools/appTools';
import { showSupportWidget } from '../main/popups/CSWidget';
import { SettingsPopup } from '../main/popups/SettingsPopup';

export class SettingsFlow implements IFlow {
    private readonly _complete = new NakedPromise<boolean>();

    // init
    //-------------------------------------------------------------------------
    constructor() {}

    // impl
    //-------------------------------------------------------------------------
    public async execute() {
        await this._actionSettings();

        return this._complete;
    }

    private async _actionComplete(success: boolean) {
        this._complete.resolve(success);
    }

    private async _actionSettings() {
        let reOpenSettings = true;
        while (reOpenSettings) {
            const reOpenPromise = new NakedPromise<boolean>();
            const settings = app().settings;
            const screen = (await app().nav.open('settingsPopup', {
                bgm: settings.music,
                sfx: settings.sound,
                language: settings.language,
                isGuest: app().platform.guest,
                onClose: () => reOpenPromise.resolve(false),
                underlay: 0.6,
            })) as SettingsPopup;

            this._setupSettingHandlers(screen, reOpenPromise);
            reOpenSettings = await reOpenPromise;
        }

        void app().nav.close('settingsPopup');
        void this._actionComplete(true);
    }

    private _setupSettingHandlers(screen: SettingsPopup, reOpenPromise: NakedPromise<boolean>) {
        screen.buttonEnableBGM.onPress = () => this._onEnableBGM(screen);
        screen.buttonDisableBGM.onPress = () => this._onDisableBGM(screen);
        screen.buttonEnableSFX.onPress = () => this._onEnableSFX(screen);
        screen.buttonDisableSFX.onPress = () => this._onDisableSFX(screen);
        screen.buttonEnglish.onPress = async () => this._onEnglish(screen);
        screen.buttonJapanese.onPress = async () => this._onJapanese(screen);
        if (!app().platform.guest) {
            screen.buttonSupport.onPress = async () => {
                await this._onFaqSupport();
                reOpenPromise.resolve(true);
            };
        }
        screen.buttonTos.onPress = async () => {
            await this._onTos();
            reOpenPromise.resolve(true);
        };
        screen.buttonPrivacy.onPress = async () => {
            await this._onPrivacy();
            reOpenPromise.resolve(true);
        };

        if (screen.buttonDelete) {
            screen.buttonDelete.onPress = async () => {
                await this._accountDelete();
                reOpenPromise.resolve(true);
            };
        }

        if (screen.buttonLogout) {
            screen.buttonLogout.onPress = async () => {
                logoutNativeApp();
            };
        }
    }

    private _onEnglish(screen: SettingsPopup) {
        screen.buttonEnglish.selected = true;
        screen.buttonJapanese.selected = false;
        void this._updateLanguage('en');
    }

    private _onJapanese(screen: SettingsPopup) {
        screen.buttonEnglish.selected = false;
        screen.buttonJapanese.selected = true;
        void this._updateLanguage('ja');
    }

    private async _onEnableBGM(screen: SettingsPopup) {
        screen.buttonDisableBGM.selected = false;
        screen.buttonEnableBGM.selected = true;
        await app().settings.setMusic(true);
    }

    private async _onDisableBGM(screen: SettingsPopup) {
        screen.buttonDisableBGM.selected = true;
        screen.buttonEnableBGM.selected = false;
        await app().settings.setMusic(false);
    }

    private async _onEnableSFX(screen: SettingsPopup) {
        screen.buttonDisableSFX.selected = false;
        screen.buttonEnableSFX.selected = true;
        await app().settings.setSound(true);
    }

    private async _onDisableSFX(screen: SettingsPopup) {
        screen.buttonDisableSFX.selected = true;
        screen.buttonEnableSFX.selected = false;
        await app().settings.setSound(false);
    }

    private async _updateLanguage(lang: LanguageId) {
        await app().settings.setLanguage(lang);
    }

    private async _onFaqSupport() {
        let openFaq = true;
        while (openFaq) {
            const reOpenPromise = new NakedPromise<boolean>();
            void app().nav.open('faqPopup', {
                onClose: () => {
                    reOpenPromise.resolve(false);
                    void app().nav.close('faqPopup');
                },
                onLineSettings: () => this._openLineSettings(),
                onSupport: () => this._contactSupport(),
                onDelete: async () => {
                    await this._accountDelete();
                    reOpenPromise.resolve(true);
                },
                onLogout: isPlatformNative()
                    ? async () => {
                          await this._onNativeLogout();
                          reOpenPromise.resolve(true);
                      }
                    : null,
            });
            openFaq = await reOpenPromise;
        }
    }

    private async _contactSupport() {
        // const subject = textLocaleFormat(`[faqContactSubject|${encodePacket(app().game.player.id)}]`);
        //openURL(`mailto:${gameConfig.support.email}?subject=${encodeURIComponent(subject)}`);
        ++app().busy.count;
        await showSupportWidget();
        --app().busy.count;
    }

    private _openLineSettings() {
        openURL('https://line.me/R/nv/connectedApps');
    }

    private async _onPrivacy() {
        const closePromise = new NakedPromise();
        void app().nav.open('legalPopup', {
            title: '[privacyTitle]',
            message: '[privacyMessage]',
            onOk: () => {
                closePromise.resolve();
                void app().nav.close('legalPopup');
            },
            underlay: 0.6,
        });
        await closePromise;
    }

    private async _onTos() {
        const closePromise = new NakedPromise();
        void app().nav.open('legalPopup', {
            title: '[tosTitle]',
            message: '[tosMessage]',
            onOk: () => {
                closePromise.resolve();
                void app().nav.close('legalPopup');
            },
            underlay: 0.6,
        });
        await closePromise;
    }

    private async _accountDelete() {
        await app().server.invoke.setDeleteAccountIntent(true);

        const confirmPromise = new NakedPromise<boolean>();
        await app().nav.open('confirmPopup', {
            onYes: () => confirmPromise.resolve(true),
            onNo: () => confirmPromise.resolve(false),
            title: '[deleteTitle]',
            message: '[deleteMessage]',
            buttonOk: '[deleteOk]',
            buttonCancel: '[deleteCancel]',
            height: 580,
            underlay: 0.6,
        });
        const isConfirmed = await confirmPromise;
        if (!isConfirmed) {
            await app().server.invoke.setDeleteAccountIntent(false);
            void app().nav.close('confirmPopup');
            return;
        }

        try {
            await app().server.invoke.deleteAccount();
            await app().server.flush();

            if (isPlatformNative()) {
                logoutNativeApp();
            } else {
                openOA();
            }
        } catch (e: any) {
            // this is just to protect player, should not really trigger
            // but we want to give some kind of feedback if we dont delete the account
            await app().showAlert(e.message ?? e);
        }
    }

    private async _onNativeLogout() {
        const logoutPromise = new NakedPromise<boolean>();
        void app().nav.open('infoPopup', {
            title: '[nativeLogoutTitle]',
            infoText: '[nativeLogoutMessage]',
            height: 520,
            underlay: 0.6,
            onOk: () => logoutPromise.resolve(true),
            onClose: () => logoutPromise.resolve(false),
        });

        const logout = await logoutPromise;
        if (logout) {
            logoutNativeApp();
        }
    }
}
