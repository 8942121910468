import { IFlow } from '../../lib/pattern/IFlow';
import NakedPromise from '../../lib/pattern/NakedPromise';
import app from '../getApp';
import { TaskScreen } from '../main/tasks/TaskScreen';

export class TaskMainFlow implements IFlow {
    private readonly _complete = new NakedPromise<boolean>();

    // fields
    private readonly _screen: TaskScreen;

    // init
    //-------------------------------------------------------------------------
    constructor(opts: { screen: TaskScreen }) {
        this._screen = opts.screen;
    }

    // impl
    //-------------------------------------------------------------------------
    public async execute() {
        await this._actionMainUpdate();

        return this._complete;
    }

    private async _actionComplete(success: boolean) {
        this._complete.resolve(success);
    }

    private async _actionMainUpdate() {
        await app().server.invoke.updateTaskSet();
        const updatedTask = app().server.state.task;

        this._screen.updateProgress(updatedTask, true);
        void this._actionComplete(true);
    }
}
