import { Layout } from '@pixi/layout';
import { FlexContainer } from '@play-co/flex';
import { Container, NineSlicePlane, Sprite, Texture } from 'pixi.js';

import { BasicAsyncHandler } from '../../../lib/defs/types';
import { uiAlignCenterX } from '../../../lib/pixi/uiTools';
import { BoosterId, PowerBoosterId } from '../../../replicant/defs/booster';
import { TextImageButton } from '../../lib/ui/buttons/TextImageButton';
import { BasicText } from '../../lib/ui/text/BasicText';
import app from '../../getApp';
import { PopupScreen, PopupOptions } from '../../lib/screens/PopupScreen';

// types
//-----------------------------------------------------------------------------
export type DailyBonusPopupOptions = {
    rewards: { id: string; amount: number }[];
    onClaim: BasicAsyncHandler;
} & PopupOptions;

// constants
//-----------------------------------------------------------------------------
const manifest = {
    bg: 'frame.popup.yellow.png',
    button: 'button.green.png',
    panel: 'panel.purple.glow.png',
    // --- rewards
    coins: 'icon.reward.coins.png',
    bomb: 'icon.reward.bomb.png',
    cube: 'icon.reward.baseball.png',
    rocket: 'icon.reward.rocket.png',
    dart: 'icon.reward.bat.png',
    bullet: 'icon.reward.runner.png',
    drill: 'icon.reward.fireball.png',
    roulette: 'icon.reward.fan.png',
};
const lazyManifest = {
    bonusEN: 'label.daily.bonus.en.png',
    bonusJA: 'label.daily.bonus.ja.png',
};

const WIDTH = 692;
const HEIGHT = 710;

export class DailyBonusPopup extends PopupScreen {
    // fields
    //-------------------------------------------------------------------------
    // events
    public onClaim: BasicAsyncHandler;
    // scene
    protected main: Layout;

    private _claimButton: TextImageButton;

    public get claimButton(): TextImageButton {
        return this._claimButton;
    }

    // impl
    //-------------------------------------------------------------------------
    public preload() {
        const lang = app().settings.language;
        const assets = Object.values(manifest);
        lang === 'en' ? assets.push(lazyManifest.bonusEN) : assets.push(lazyManifest.bonusJA);
        return app().resource.loadAssets(assets);
    }

    public override async spawning(options: DailyBonusPopupOptions) {
        void super.spawning({
            ...options,
            width: WIDTH,
            height: HEIGHT,
            underlay: 0.6,
        });

        this.onClaim = options.onClaim;

        const panel = Sprite.from(manifest.panel);
        const bgContainer = new Container();
        bgContainer.addChild(panel);

        const flexContainer = new FlexContainer({
            alignItems: 'center',
            justifyContent: 'center',
            height: 290,
            direction: 'row',
        });

        options.rewards.forEach((item) => {
            const { id, amount } = item;

            const itemFlex = new FlexContainer({
                alignItems: 'center',
                justifyContent: 'center',
                width: 256,
                height: 300,
                direction: 'column',
            });

            const sprite = Sprite.from(manifest[id as PowerBoosterId | BoosterId | 'coins']);

            const amountText = new BasicText({
                text: `x${amount}`,
                style: {
                    align: 'center',
                    fontSize: 56,
                    fontWeight: 'bold',
                    fill: '#FFF976',
                    lineJoin: 'round',
                    stroke: '#000000',
                    strokeThickness: 6,
                },
            });

            itemFlex.addChild(
                sprite.setFlex({
                    position: 'absolute',
                    top: 0,
                }),
                amountText.setFlex({
                    position: 'absolute',
                    top: 240,
                }),
            );

            flexContainer.addChild(itemFlex);
        });
        flexContainer.doLayout();

        const titleAsset = app().settings.language === 'en' ? lazyManifest.bonusEN : lazyManifest.bonusJA;
        const title = Sprite.from(titleAsset);
        this.baseLayout.addContent({
            bg: {
                content: new NineSlicePlane(Texture.from(manifest.bg), 95, 128, 32, 40).props({
                    width: WIDTH,
                    height: HEIGHT,
                }),
                styles: {
                    width: WIDTH,
                    height: HEIGHT,
                },
            },
            header: {
                content: title,
                styles: {
                    position: 'topCenter',
                    marginTop: -36,
                },
            },
            main: {
                content: bgContainer,
                styles: {
                    position: 'topCenter',
                    marginTop: 100,
                },
            },
            flex: {
                content: flexContainer,
                styles: {
                    position: 'topCenter',
                    marginTop: 165,
                },
            },
        });

        if (this.onClaim) {
            const okButton = (this._claimButton = new TextImageButton({
                text: '[buttonClaim]',
                image: manifest.button,
                y: -5,
                slice: {
                    width: 300,
                    height: 122,
                    left: 30,
                    top: 16,
                    right: 30,
                    bottom: 32,
                },
                style: {
                    fill: 'FFF',
                    fontSize: 40,
                    fontWeight: 'bold',
                    lineJoin: 'round',
                    fontStyle: 'normal',
                    dropShadow: true,
                    dropShadowDistance: 1,
                },
            }));
            okButton.props({
                y: this.baseLayout.height - 105,
                pivot: { x: okButton.width * 0.5, y: okButton.height * 0.5 },
                onPress: async () => this.onClaim?.(),
            });
            uiAlignCenterX(this.baseLayout, okButton); // Align with pivot to enable scale animation from center
            this.baseLayout.addChild(okButton);
        }
    }
}
