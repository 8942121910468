import { SB } from '@play-co/replicant';

import { boosterAdd, powerBoosterAdd } from '../components/boosters';
import { livesSet } from '../components/lives';
import gameConfig from './gameConfig';
import { MutableState, State, SyncActionAPI } from './replicant';

// types
//-----------------------------------------------------------------------------
// iap product ids
export const productIds = [
    'baseball_beginners_bundle',
    'baseball_champions_bundle',
    'baseball_victory_bundle',
    'baseball_grand_slam_bundle',
    'baseball_home_run_bundle',
    'baseball_shut_out_bundle',
    'baseball_mini_bundle',
    'baseball_200_coins',
    'baseball_1000_coins',
    'baseball_2000_coins',
    'baseball_5000_coins',
    'baseball_10000_coins',
    'baseball_20000_coins',
] as const;
export type ProductId = (typeof productIds)[number];

export type CoinsProductId =
    | 'baseball'
    | 'bomb'
    | 'lives'
    | 'moves'
    | 'rocket'
    | 'dart'
    | 'bullet'
    | 'drill'
    | 'roulette';

export type ShopItemColorTheme = 'cyan' | 'yellow' | 'purple' | 'red';

// schema
//-----------------------------------------------------------------------------
export const productState = {
    // IAP product state map. this needs to be flexible since products can often change.
    product: SB.map(
        // type: ProductState
        SB.object({
            purchaseCount: SB.int(),
        }),
    ).default({}),
};

export type ProductState = {
    purchaseCount: number;
};

// defs
//-----------------------------------------------------------------------------
export type ProductDef = {
    id: ProductId;
    color?: ShopItemColorTheme;
    priceYen: number;
    priceUsd: number; // divide by 100 for dollar amount. Raw data from google play divided by 10000
    itemName?: string;
    banner?: string;
    oneTimeOnly?: boolean;
    infiniteLives?: number;
    coins: number;
    metalBat?: number;
    runner?: number;
    fireBall?: number;
    tornadoFan?: number;
    rocket?: number;
    bomb?: number;
    baseball?: number;
};
export type ProductDefs = Record<ProductId, ProductDef>;

export type ProductItem = ProductDef & {
    getRenderPrice: () => string;
};

export type CoinsProductDef = {
    getCost: (state?: State) => number; // in coins
    action: (state: MutableState, api: SyncActionAPI) => void;
};

// tables
//-----------------------------------------------------------------------------
export const productDefs: ProductDefs = {
    baseball_beginners_bundle: {
        id: 'baseball_beginners_bundle',
        color: 'purple',
        priceYen: 100, // real yen number
        priceUsd: 69,
        itemName: 'bundleBaseballBeginnersItemName',
        banner: 'bundleBaseballBeginnersBanner',
        oneTimeOnly: true,
        coins: 400,
        metalBat: 1,
        runner: 1,
        fireBall: 1,
        tornadoFan: 1,
        rocket: 1,
        bomb: 1,
        baseball: 1,
    },
    baseball_champions_bundle: {
        id: 'baseball_champions_bundle',
        color: 'red',
        priceYen: 15000,
        priceUsd: 9999,
        itemName: 'bundleBaseballChampionsItemName',
        banner: 'bundleBaseballChampionsBanner',
        infiniteLives: 18,
        coins: 13000,
        metalBat: 25,
        runner: 25,
        fireBall: 25,
        tornadoFan: 25,
        rocket: 25,
        bomb: 25,
        baseball: 25,
    },
    baseball_victory_bundle: {
        id: 'baseball_victory_bundle',
        color: 'cyan',
        priceYen: 12000,
        priceUsd: 7999,
        itemName: 'bundleBaseballVictoryItemName',
        infiniteLives: 12,
        coins: 10000,
        metalBat: 18,
        runner: 18,
        fireBall: 18,
        tornadoFan: 18,
        rocket: 18,
        bomb: 18,
        baseball: 18,
    },
    baseball_grand_slam_bundle: {
        id: 'baseball_grand_slam_bundle',
        color: 'cyan',
        priceYen: 6000,
        priceUsd: 3999,
        itemName: 'bundleBaseballGrandSlamItemName',
        infiniteLives: 6,
        coins: 5000,
        metalBat: 8,
        runner: 8,
        fireBall: 8,
        tornadoFan: 8,
        rocket: 8,
        bomb: 8,
        baseball: 8,
    },
    baseball_home_run_bundle: {
        id: 'baseball_home_run_bundle',
        color: 'yellow',
        priceYen: 3000,
        priceUsd: 1999,
        itemName: 'bundleBaseballHomeRunItemName',
        banner: 'bundleBaseballHomeRunBanner',
        infiniteLives: 2,
        coins: 2000,
        metalBat: 4,
        runner: 4,
        fireBall: 4,
        tornadoFan: 4,
        rocket: 4,
        bomb: 4,
        baseball: 4,
    },
    baseball_shut_out_bundle: {
        id: 'baseball_shut_out_bundle',
        color: 'cyan',
        priceYen: 1500,
        priceUsd: 999,
        itemName: 'bundleBaseballShutOutItemName',
        coins: 1000,
        metalBat: 2,
        runner: 2,
        fireBall: 2,
        tornadoFan: 2,
        rocket: 2,
        bomb: 2,
        baseball: 2,
    },
    baseball_mini_bundle: {
        id: 'baseball_mini_bundle',
        color: 'cyan',
        priceYen: 1000,
        priceUsd: 699,
        itemName: 'bundleBaseballMiniItemName',
        coins: 600,
        metalBat: 1,
        runner: 1,
        fireBall: 1,
        tornadoFan: 1,
        rocket: 1,
        bomb: 1,
        baseball: 1,
    },
    baseball_200_coins: {
        id: 'baseball_200_coins',
        priceYen: 300,
        priceUsd: 199,
        oneTimeOnly: false,
        coins: 200,
    },
    baseball_1000_coins: {
        id: 'baseball_1000_coins',
        priceYen: 1200,
        priceUsd: 799,
        oneTimeOnly: false,
        coins: 1000,
    },
    baseball_2000_coins: {
        id: 'baseball_2000_coins',
        priceYen: 2200,
        priceUsd: 1499,
        oneTimeOnly: false,
        coins: 2000,
    },
    baseball_5000_coins: {
        id: 'baseball_5000_coins',
        priceYen: 4500,
        priceUsd: 2999,
        oneTimeOnly: false,
        coins: 5000,
    },
    baseball_10000_coins: {
        id: 'baseball_10000_coins',
        priceYen: 8800,
        priceUsd: 5999,
        oneTimeOnly: false,
        coins: 10000,
    },
    baseball_20000_coins: {
        id: 'baseball_20000_coins',
        priceYen: 15000,
        priceUsd: 9999,
        oneTimeOnly: false,
        coins: 20000,
    },
};

export const coinsProductDefs: Record<CoinsProductId, CoinsProductDef> = {
    baseball: {
        getCost: () => 400,
        action: (state: MutableState) => {
            powerBoosterAdd(state, 'cube', 3);
        },
    },
    bomb: {
        getCost: () => 300,
        action: (state: MutableState) => {
            powerBoosterAdd(state, 'bomb', 3);
        },
    },
    lives: {
        getCost: () => gameConfig.lives.cost,
        action: (state: MutableState, api: SyncActionAPI) => {
            livesSet(state, gameConfig.lives.max, api.date.now());
        },
    },
    moves: {
        getCost: (state: State) => getContinueCost(state),
        action: (state: MutableState) => {
            ++state.puzzle.continues;
        },
    },
    rocket: {
        getCost: () => 200,
        action: (state: MutableState) => {
            powerBoosterAdd(state, 'rocket', 3);
        },
    },
    dart: {
        getCost: () => 400,
        action: (state: MutableState) => {
            boosterAdd(state, 'dart', 3);
        },
    },
    bullet: {
        getCost: () => 800,
        action: (state: MutableState) => {
            boosterAdd(state, 'bullet', 3);
        },
    },
    drill: {
        getCost: () => 800,
        action: (state: MutableState) => {
            boosterAdd(state, 'drill', 3);
        },
    },
    roulette: {
        getCost: () => 200,
        action: (state: MutableState) => {
            boosterAdd(state, 'roulette', 3);
        },
    },
};

// helpers
//-----------------------------------------------------------------------------
export function isBundleShopItem(item: ProductDef): boolean {
    return item.itemName !== undefined;
}

export function isCoinPackShopItem(item: ProductDef): boolean {
    return !isBundleShopItem(item);
}

// 6 coin images using 5 thresholds
const coinThresholds: [number, number, number, number, number] = [300, 1000, 3000, 5000, 6500];

export function getCoinImage(amount: number): string {
    // return a string like `coin.1.png` based on the amount compared to the thresholds
    for (let i = 0; i < coinThresholds.length; i++) {
        if (amount < coinThresholds[i]) {
            return `coins.${i + 1}.png`;
        }
    }
    return `coins.${coinThresholds.length + 1}.png`;
}

export function getContinueCost(state: State): number {
    const config = gameConfig.puzzle.continue;

    const continues = state.puzzle.continues;

    // get cost based on continue count limited by cost levels
    return config.cost[Math.min(continues, config.cost.length - 1)];
}
