import { Layout } from '@pixi/layout';
import { Container } from 'pixi.js';

import { SizeType } from '../../../lib/defs/types';
import { tween } from '../../../lib/util/tweens';
import { IScreen } from '../../../plugins/nav/IScreen';
import { sleep } from '../../../replicant/util/jsTools';
import { BaseballPlayer } from '../../concept/BaseballPlayer';
import { pixiConfig } from '../../defs/config';
import app from '../../getApp';

const ORIENTATION_CHANGE = 'orientationchange';

/*
    base layout screen. normalizes screen size.
*/
export class LayoutScreen2 implements IScreen {
    // fields
    //-------------------------------------------------------------------------
    // scene
    private _root: Container;
    private _base: Layout;
    private _size: SizeType;
    private _scale = 1;

    // collect all spines here and call nukeSpines before leaving the scene for less spine visible skeleton during scene transition
    protected _spines: Container[] = [];

    // properties
    //-------------------------------------------------------------------------
    public get root(): Container {
        return this._root;
    }

    public get base(): Layout {
        return this._base;
    }

    public get size(): SizeType {
        return this._size;
    }

    public get scale(): number {
        return this._scale;
    }

    public async despawning(): Promise<void> {
        // await this.nukeSpines();
    }

    // init
    //-------------------------------------------------------------------------
    constructor() {
        // root
        this._root = new Container();

        // base
        this._base = this._root.addChild(
            new Layout({
                id: 'root',
                styles: {
                    width: '100%',
                    height: '100%',
                },
            }),
        );
    }

    // api
    //-------------------------------------------------------------------------
    protected empty() {
        // remove all children
        this.base.removeChildren();

        // FIXME workaround to remove all children
        for (const id of this.base.content.children.keys()) {
            this.base.removeChildByID(id);
        }
    }

    protected async nukeSpines() {
        const fadeNuke = async (spine: Container) => {
            // make sure skeleton is not too bright during fading
            (spine as BaseballPlayer).toSprite();
            // fast animation, faster than regular transision to make sure the skeleton is fully faded out
            await spine.animate().add(spine, { alpha: 0 }, 0.18, tween.linear);
            spine.destroy();
        };

        const promises = this._spines.map((spine: Container) => fadeNuke(spine));
        await Promise.all(promises);

        this._spines = [];
    }

    protected async addOrientationListener(): Promise<void> {
        // Listen for orientation changes
        window.addEventListener(
            ORIENTATION_CHANGE,
            async () => {
                await sleep(0.4); // let the device have time to adjust to the new orientation
                this.forcedResize();
            },
            false,
        );
    }

    // impl
    //-------------------------------------------------------------------------
    public resized(size: SizeType): void {
        const appSize = pixiConfig.size;

        // scale to fit minimum app height
        if (size.height < appSize.height) {
            this._scale = size.height / appSize.height;
            this._root.scale.set(this._scale);
            size.width = Math.round((size.width * appSize.height) / size.height);
            size.height = appSize.height;
        } else {
            this._scale = 1;
            this._root.scale.set(1);
        }

        this._size = size;

        // layout resize
        this._base.resize(size.width, size.height);
    }

    public forcedResize(): void {
        const size = {
            width: app().stage.canvas.width,
            height: app().stage.canvas.height,
        };
        this.resized(size);
    }
}
