import { Application, Plugin, PluginOptions } from '@play-co/astro';

import { InstantGame } from './InstantGame';
import app from '../../app/getApp';

export type InstantGamePluginOptions = PluginOptions;

export class InstantGamePlugin extends Plugin<InstantGamePluginOptions> {
    constructor(app: Application, options?: InstantGamePluginOptions) {
        super(app, options);
    }

    public async prepare(): Promise<void> {
        await InstantGame.init();
        //TODO: this is lib referencing app. refactor
        app().server = InstantGame.replicant;
        await InstantGame.start();
    }

    public get platform() {
        return InstantGame.platform;
    }

    public get replicant() {
        return InstantGame.replicant;
    }
}
