import { i18n } from '@play-co/astro';
import { SizeType } from '../../../lib/defs/types';
import { IScreen } from '../../../plugins/nav/IScreen';
import { FlexContainer } from '@play-co/flex';

/*
    base Flex layout screen. Use for screens which want to extend from a root container
    which resizes to the current app size
*/
export class FlexLayoutScreen implements IScreen {
    // fields
    //-------------------------------------------------------------------------
    // scene
    protected layout: FlexContainer;

    // properties
    //-------------------------------------------------------------------------
    public get root(): FlexContainer {
        return this.layout;
    }

    // init
    //-------------------------------------------------------------------------
    constructor() {
        // root
        this.layout = new FlexContainer({
            id: 'mainLayout',
            direction: 'column',
            alignItems: 'center',
            width: '100%',
            height: '100%',
        });

        // Redo layout on language change - give it a high order to ensure doLayout is called after other changes
        i18n.onUpdate.connect(() => this.layout.doLayout(), 100);
    }

    // api
    //-------------------------------------------------------------------------
    protected empty() {
        // remove all children
        this.layout.removeChildren();
    }

    // impl
    //-------------------------------------------------------------------------
    public resized(size: SizeType): void {
        this.layout.setFlex({ width: size.width, height: size.height });
        this.layout.doLayout();
    }
}
