import { Container, Sprite, TEXT_GRADIENT } from 'pixi.js';

import { uiAlignCenterX, uiAlignTop, uiSizeToWidth } from '../../../lib/pixi/uiTools';
import { BasicText } from '../../lib/ui/text/BasicText';
import app from '../../getApp';

type GameDayOA = 'gameDay0';
// type GameDayOA = 'gameDay0' | 'gameDay1' | 'gameDay2';

// constants
//-----------------------------------------------------------------------------
const manifest = {
    gameDay0: 'game_day_dynamic_0.png',
};

// api
//-----------------------------------------------------------------------------
export async function gameDayOA(opts: {
    dayId: GameDayOA;
    day: string;
    versus: string;
    score: string;
}): Promise<Container> {
    const { dayId, day, versus, score } = opts;
    const container = new Container();
    await app().resource.loadAsset(manifest[dayId]);
    const creativeBg = Sprite.from(manifest[dayId]);
    // add to container now so it gets the correct size
    container.addChild(creativeBg);

    const dayTitle = new BasicText({
        text: day,
        style: {
            align: 'center',
            fontSize: 28,
            fontWeight: 'bold',
            fillGradientType: TEXT_GRADIENT.LINEAR_VERTICAL,
            fill: [0xc7d0e4, 0xd9e1f2],
            lineJoin: 'round',
            dropShadow: true,
            dropShadowDistance: 5,
            dropShadowAngle: Math.PI / 2,
            dropShadowAlpha: 0.3,
        },
    });

    const versusTitle = new BasicText({
        // multiple space on purpose to keep the title within the same string and handling alignment
        text: `vs   ${versus}`,
        style: {
            align: 'center',
            fontSize: 28,
            fontWeight: 'bold',
            fill: 0x2081b8,
            lineJoin: 'round',
            dropShadow: true,
            dropShadowDistance: 5,
            dropShadowAngle: Math.PI / 2,
            dropShadowAlpha: 0.3,
        },
    });

    const scoretext = new BasicText({
        text: score,
        style: {
            align: 'center',
            fontSize: 42,
            fontWeight: 'bold',
            fill: 0xffffff,
            lineJoin: 'round',
            dropShadow: true,
            dropShadowDistance: 5,
            dropShadowAngle: Math.PI / 2,
            dropShadowAlpha: 0.3,
        },
    });

    uiSizeToWidth(dayTitle, 90);
    uiSizeToWidth(scoretext, 170);
    container.addChild(dayTitle, versusTitle, scoretext);
    uiAlignTop(container, dayTitle, 10);
    uiAlignTop(container, versusTitle, 5);
    uiAlignCenterX(container, versusTitle, 60);
    dayTitle.position.x = 15;
    scoretext.position.set(30, container.height - 78);

    return container;
}
