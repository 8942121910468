import { Sprite, Texture } from 'pixi.js';

import { Animation } from '../../../lib/animator/Animation';
import { BasicAsyncHandler } from '../../../lib/defs/types';
import UpdateObserver from '../../../lib/pattern/UpdateObserver';
import { TouchInputComponent } from '../../../lib/pixi/components/TouchInputComponent';
import { pixiSetInterval } from '../../../lib/pixi/pixiTools';
import { uiAlignCenter, uiAlignRight, uiSizeToFit } from '../../../lib/pixi/uiTools';
import { getInifniteLivesTimeLeft, isInfiniteLivesActive, livesTimeToNext } from '../../../replicant/components/lives';
import gameConfig from '../../../replicant/defs/gameConfig';
import { timeFormatCountdown, timeToComponents } from '../../../replicant/util/timeTools';
import { BasicText } from '../../lib/ui/text/BasicText';
import { onDownAnimation, onTapAnimation, onUpAnimation } from '../../lib/util/clickAnimation';
import app from '../../getApp';

const manifest = {
    life: 'icon.life.png',
    frame: 'panel.stats.2.png',
    circleGlow: 'icon.node.enabled.png',
    circleDefault: 'icon.node.disabled.png',
    plus: 'button.plus.png',
    infinite: 'icon.life.infinite.png',
};

// smarter component handling its own state and rendering
export default class LifeComponent extends Sprite {
    // events
    public onPress?: BasicAsyncHandler;

    private _state: 'default' | 'infinite';
    private _livesIcon: Sprite; // 2 types, normal or infinite
    private _plus: Sprite;
    private _lives: number;
    private _timer: BasicText;
    private _observer = new UpdateObserver();
    private _animation: Animation;

    private _circles: Sprite[] = [];

    constructor() {
        super(Texture.from(manifest.frame));

        Object.assign(new TouchInputComponent(this), {
            onTap: this._onTap.bind(this),
            onDown: this._onDown.bind(this),
            onUp: this._onUp.bind(this),
        });

        this._timer = new BasicText({
            text: ``,
            style: {
                fill: '#FF8EA9',
                fontSize: 20,
                lineJoin: 'round',
                align: 'center',
            },
        });

        this._timer.pivot.y = this._timer.height / 2;

        // this.addChild(this._timer, plus);
        this.addChild(this._timer);

        this._observer.listen(
            () => app().game.player.lives,
            (lives) => {
                this._update(); // extra render call to sync timer when  life amount changes
                this._updateAmount(lives);
            },
        );

        // start
        this._update();
        pixiSetInterval(this, () => this._update(), 1);

        this._observer.start();

        this.pivot.set(this.width / 2, this.height / 2);
    }

    public static assets(): string[] {
        return Object.values(manifest);
    }

    // re-render amount lights
    private _updateAmount(amount: number, forceUpdate = false) {
        if (!forceUpdate && (this._lives === amount || this._state === 'infinite')) return;
        this._lives = amount;

        let x = 60;
        for (let i = 0; i < gameConfig.lives.max; i++) {
            if (this._circles[i]) {
                this._circles[i].removeFromParent();
                this._circles[i] = null;
            }

            const circle = new Sprite(Texture.from(i <= amount - 1 ? manifest.circleGlow : manifest.circleDefault));
            circle.scale.set(0.62);
            circle.x = x;
            circle.y = 10;
            x += 15;
            this.addChild(circle);

            this._circles[i] = circle;
        }
    }

    private _update() {
        const executionMap = {
            default: () => {
                if (this._state !== 'default') {
                    this._state = 'default';
                    // force update lights
                    this._updateAmount(app().game.player.lives, true);

                    if (this._livesIcon) {
                        this._livesIcon.destroy();
                    }
                    this._livesIcon = new Sprite(Texture.from(manifest.life));
                    this._livesIcon.x = 9;
                    this._livesIcon.y = 13;
                    this.addChild(this._livesIcon);

                    this._plus = Sprite.from(manifest.plus);
                    this._plus.y = -10;
                    uiAlignRight(this, this._plus, 22);
                    this.addChild(this._plus);
                }

                const timeLeft = livesTimeToNext(app().server.state, app().server.now());
                this._timer.text = timeLeft > 0 ? timeFormatCountdown(timeToComponents(timeLeft)) : '[livesFull]';
                uiSizeToFit(this._timer, 86, 70);
                this._timer.y = 52;
                uiAlignRight(this, this._timer, -14);
            },
            infinite: () => {
                if (this._state !== 'infinite') {
                    this._state = 'infinite';

                    if (this._plus) this._plus.destroy();

                    this._circles.forEach((circle, i) => {
                        circle?.destroy();
                        this._circles[i] = null;
                    });

                    if (this._livesIcon) {
                        this._livesIcon.destroy();
                    }
                    this._livesIcon = new Sprite(Texture.from(manifest.infinite));
                    this._livesIcon.x = 9;
                    this._livesIcon.y = 13;
                    this.addChild(this._livesIcon);
                }

                const timeLeft = getInifniteLivesTimeLeft(app().server.state, app().server.now());
                this._timer.text = timeLeft > 0 ? timeFormatCountdown(timeToComponents(timeLeft)) : `0`;
                uiSizeToFit(this._timer, 90, 70);
                uiAlignCenter(this, this._timer, 28);
            },
        };

        const updatedState = isInfiniteLivesActive(app().server.state, app().server.now()) ? 'infinite' : 'default';

        executionMap[updatedState]();
    }

    // private: handlers
    //-------------------------------------------------------------------------
    private async _onTap() {
        void app().sound.play('button.ogg', { dupes: 5, volume: 0.6 });
        this._animation?.cancel();
        this._animation = onTapAnimation(this);
        await this.onPress?.();
    }

    private _onDown() {
        this._animation?.cancel();
        this._animation = onDownAnimation(this);
    }

    private _onUp() {
        this._animation?.cancel();
        this._animation = onUpAnimation(this);
    }
}
