#version 300 es
#define SHADER_NAME uber

in vec2 aVertexPosition;
in vec2 aTextureCoord;

uniform mat3 projectionMatrix;
uniform mat3 translationMatrix;
uniform mat3 uTextureMatrix;
uniform vec2 size;

out vec2 vUv;
out vec2 vTextureCoord;

void main(void)
{
    gl_Position = vec4((projectionMatrix * translationMatrix * vec3(aVertexPosition * size, 1.0)).xy, 0.0, 1.0);
    vUv = aTextureCoord;
    vTextureCoord = (uTextureMatrix * vec3(aTextureCoord, 1.0)).xy;
}
