import { Sprite } from 'pixi.js';

import { uiAlignCenter, uiAlignCenterX, uiSizeToFit, uiSizeToWidth } from '../../../lib/pixi/uiTools';
import { BasicText } from '../../lib/ui/text/BasicText';
import { BasicPopup, BasicPopupOptions } from './BasicPopup';
import app from '../../getApp';
import { MarkupText } from '../../lib/ui/text/MarkupText';

const manifest = {
    icon: 'icon.team.morale.intro.png',
};

export class TeamMoraleIntroPopup extends BasicPopup {
    // impl
    //-------------------------------------------------------------------------
    public override preload() {
        return [...super.preload(), ...app().resource.loadAssets([...Object.values(manifest)])];
    }

    // @ts-ignore
    public override async spawning(options: BasicPopupOptions) {
        void super.spawning({
            header: '[popupTeamMoraleIntroTitle]',
            width: 700,
            height: 660,
            okButton: '[buttonOk]',
            ...options,
        });

        const moraleIncreasedIcon = Sprite.from(manifest.icon);
        const moraleTitle = new BasicText({
            text: '[teamMoraleTitle]',
            style: {
                fill: 0xffffff,
                align: 'center',
                fontWeight: 'bold',
                lineJoin: 'round',
                fontSize: 18,
                fontStyle: 'italic',
            },
        });

        moraleIncreasedIcon.addChild(moraleTitle);
        uiSizeToWidth(moraleTitle, 114);
        uiAlignCenter(moraleIncreasedIcon, moraleTitle, -86, 30);

        const increased = new BasicText({
            text: '[popupTeamMoraleIntroIncreased]',
            style: {
                fill: 0x047d10,
                align: 'center',
                fontWeight: 'bold',
                lineJoin: 'round',
                fontSize: 36,
            },
        });
        const performance = new BasicText({
            text: '[popupTeamMoraleIntroPerf]',
            style: {
                fill: 0x3e4047,
                align: 'center',
                fontWeight: 'bold',
                lineJoin: 'round',
                fontSize: 36,
                wordWrap: true,
                wordWrapWidth: 490,
                // this removes weird random line spacing for japanese texts
                whiteSpace: 'normal',
            },
        });

        // spawn
        this.main.addContent({
            main: {
                content: moraleIncreasedIcon,
                styles: {
                    position: 'topCenter',
                    marginTop: 140,
                },
            },
            increased: {
                content: increased,
                styles: {
                    position: 'topCenter',
                    marginTop: 345,
                },
            },
            performance: {
                content: performance,
                styles: {
                    position: 'topCenter',
                    marginTop: 392,
                },
            },
        });
    }
}
