import { SB } from '@play-co/replicant';
import { Dialog } from './storyDialogs';
import { timeFromComponents } from '../util/timeTools';

export type TeamMoraleConfig = {
    id: string; // for analytics
    moraleGain: number[];
    dialogs: Dialog[];
};

export type NightDecayClockJst = keyof typeof decayClockTimes;

//-----------------------------------------------------------------------------
export const teamMoraleState = {
    teamMorale: SB.object({
        score: SB.int().min(0).default(60),
        timestamp: SB.int().default(-1),
        ftue: SB.boolean(),
        pinchFlow: SB.object({
            pinchId: SB.int().default(-1),
            scenarioId: SB.int().default(-1),
        }),
    }),
};

const score = {
    task: 50,
    gameDay: {
        win: 40,
        loss: 20,
    },
    puzzle: 10,
    // there's also more dynamic scoring defined for the pinch flows, see teamMoraleConfig
};

const decayClockTimes = {
    // 6am JST
    sixAM: timeFromComponents({ hours: 21 }), // 06:00 (6:00am) in Japan, JST is +21 hours from UTC
    // 10pm JST
    tenPM: timeFromComponents({ hours: 13 }), // 22:00 (10:00pm) in Japan, JST is +13 hours from UTC
};

const teamMoraleConfig: TeamMoraleConfig[][] = [
    [
        {
            id: 'batterInjured0',
            moraleGain: [20, 30],
            dialogs: [
                { speechSource: 'player', dialog: '[teamMoralePinch0BatterInjured0]', clip: 'batter_injured1' },
                {
                    speechSource: 'narrator',
                    dialog: '[teamMoralePinch0BatterInjured1]',
                    extraChoice: ['[teamMoralePinch0BatterInjuredOption0]', '[teamMoralePinch0BatterInjuredOption1]'],
                    clip: 'batter_injured1',
                },
                {
                    speechSource: 'player',
                    choiceDialogs: ['[teamMoralePinch0BatterInjured2]', '[teamMoralePinch0BatterInjured3]'],
                    clip: 'batter_injured1',
                },
            ],
        },
        {
            id: 'batterInjured1',
            moraleGain: [40, 5],
            dialogs: [
                { speechSource: 'player', dialog: '[teamMoralePinch1BatterInjured0]', clip: 'batter_injured2' },
                {
                    speechSource: 'narrator',
                    dialog: '[teamMoralePinch1BatterInjured1]',
                    extraChoice: ['[teamMoralePinch1BatterInjuredOption0]', '[teamMoralePinch1BatterInjuredOption1]'],
                    clip: 'batter_injured2',
                },
                {
                    speechSource: 'player',
                    choiceDialogs: ['[teamMoralePinch1BatterInjured2]', '[teamMoralePinch1BatterInjured3]'],
                    clip: 'batter_injured2',
                },
            ],
        },
    ],
    [
        {
            id: 'needAdvice0',
            moraleGain: [30, 30],
            dialogs: [
                { speechSource: 'player', dialog: '[teamMoralePinch0Advice0]', clip: 'technical_advice1' },
                {
                    speechSource: 'narrator',
                    dialog: '[teamMoralePinch0Advice1]',
                    extraChoice: ['[teamMoralePinch0AdviceOption0]', '[teamMoralePinch0AdviceOption1]'],
                    clip: 'technical_advice1',
                },
                {
                    speechSource: 'player',
                    choiceDialogs: ['[teamMoralePinch0Advice2]', '[teamMoralePinch0Advice3]'],
                    clip: 'technical_advice1',
                },
            ],
        },
        {
            id: 'needAdvice1',
            moraleGain: [30, 40],
            dialogs: [
                { speechSource: 'player', dialog: '[teamMoralePinch1Advice0]', clip: 'technical_advice2' },
                {
                    speechSource: 'narrator',
                    dialog: '[teamMoralePinch1Advice1]',
                    extraChoice: ['[teamMoralePinch1AdviceOption0]', '[teamMoralePinch1AdviceOption1]'],
                    clip: 'technical_advice2',
                },
                {
                    speechSource: 'player',
                    choiceDialogs: ['[teamMoralePinch1Advice2]', '[teamMoralePinch1Advice3]'],
                    clip: 'technical_advice2',
                },
            ],
        },
    ],

    [
        {
            id: 'pitcherInjured0',
            moraleGain: [30, 20],
            dialogs: [
                { speechSource: 'player', dialog: '[teamMoralePinch0PitcherInjured0]', clip: 'pitcher_injured1' },
                {
                    speechSource: 'narrator',
                    dialog: '[teamMoralePinch0PitcherInjured1]',
                    extraChoice: ['[teamMoralePinch0PitcherInjuredOption0]', '[teamMoralePinch0PitcherInjuredOption1]'],
                    clip: 'pitcher_injured1',
                },
                {
                    speechSource: 'player',
                    choiceDialogs: ['[teamMoralePinch0PitcherInjured2]', '[teamMoralePinch0PitcherInjured3]'],
                    clip: 'pitcher_injured1',
                },
            ],
        },
        {
            id: 'pitcherInjured1',
            moraleGain: [30, 40],
            dialogs: [
                { speechSource: 'player', dialog: '[teamMoralePinch1PitcherInjured0]', clip: 'pitcher_injured2' },
                {
                    speechSource: 'narrator',
                    dialog: '[teamMoralePinch1PitcherInjured1]',
                    extraChoice: ['[teamMoralePinch1PitcherInjuredOption0]', '[teamMoralePinch1PitcherInjuredOption1]'],
                    clip: 'pitcher_injured2',
                },
                {
                    speechSource: 'player',
                    choiceDialogs: ['[teamMoralePinch1PitcherInjured2]', '[teamMoralePinch1PitcherInjured3]'],
                    clip: 'pitcher_injured2',
                },
            ],
        },
    ],
    [
        {
            id: 'tiredTraining0',
            moraleGain: [30, 30],
            dialogs: [
                { speechSource: 'player', dialog: '[teamMoralePinch0TiredTraining0]', clip: 'training_tired1' },
                {
                    speechSource: 'narrator',
                    dialog: '[teamMoralePinch0TiredTraining1]',
                    extraChoice: ['[teamMoralePinch0TiredTrainingOption0]', '[teamMoralePinch0TiredTrainingOption1]'],
                    clip: 'training_tired1',
                },
                {
                    speechSource: 'player',
                    choiceDialogs: ['[teamMoralePinch0TiredTraining2]', '[teamMoralePinch0TiredTraining3]'],
                    clip: 'training_tired1',
                },
            ],
        },
        {
            id: 'tiredTraining1',
            moraleGain: [40, 40],
            dialogs: [
                { speechSource: 'player', dialog: '[teamMoralePinch1TiredTraining0]', clip: 'training_tired2' },
                {
                    speechSource: 'narrator',
                    dialog: '[teamMoralePinch1TiredTraining1]',
                    extraChoice: ['[teamMoralePinch1TiredTrainingOption0]', '[teamMoralePinch1TiredTrainingOption1]'],
                    clip: 'training_tired2',
                },
                {
                    speechSource: 'player',
                    choiceDialogs: ['[teamMoralePinch1TiredTraining2]', '[teamMoralePinch1TiredTraining3]'],
                    clip: 'training_tired2',
                },
            ],
        },
    ],
    [
        {
            id: 'tiredFromPractice0',
            moraleGain: [30, 5],
            dialogs: [
                { speechSource: 'player', dialog: '[teamMoralePinch0Tired0]', clip: 'practice_tired1' },
                {
                    speechSource: 'narrator',
                    dialog: '[teamMoralePinch0Tired1]',
                    extraChoice: ['[teamMoralePinch0TiredOption0]', '[teamMoralePinch0TiredOption1]'],
                    clip: 'practice_tired1',
                },
                {
                    speechSource: 'player',
                    choiceDialogs: ['[teamMoralePinch0Tired2]', '[teamMoralePinch0Tired3]'],
                    clip: 'practice_tired1',
                },
            ],
        },
        {
            id: 'tiredFromPractice1',
            moraleGain: [40, 10],
            dialogs: [
                { speechSource: 'player', dialog: '[teamMoralePinch1Tired0]', clip: 'practice_tired2' },
                {
                    speechSource: 'narrator',
                    dialog: '[teamMoralePinch1Tired1]',
                    extraChoice: ['[teamMoralePinch1TiredOption0]', '[teamMoralePinch1TiredOption1]'],
                    clip: 'practice_tired2',
                },
                {
                    speechSource: 'player',
                    choiceDialogs: ['[teamMoralePinch1Tired2]', '[teamMoralePinch1Tired3]'],
                    clip: 'practice_tired2',
                },
            ],
        },
    ],
];

export default {
    decayClockTimes,
    hourlyPointDecrease: 5,
    hourlyPointDecreaseAB: 12,
    decayPauseHourJST: 22,
    decayResumeHourJST: 6,
    score,
    teamMoraleConfig,
};
