import { IFlow } from '../../lib/pattern/IFlow';
import NakedPromise from '../../lib/pattern/NakedPromise';
import app from '../getApp';

export class SetNameFlow implements IFlow {
    private readonly _complete = new NakedPromise<string>();

    // init
    //-------------------------------------------------------------------------
    constructor() {}

    // impl
    //-------------------------------------------------------------------------
    public async execute() {
        await this._actionSetName();

        return this._complete;
    }

    private async _actionComplete(name: string) {
        this._complete.resolve(name);
    }

    private async _actionSetName() {
        const popupId = 'namePopup';
        const namePromise = new NakedPromise<string>();
        const currentName = app().game.player.name;
        void app().nav.open(popupId, {
            currentName,
            onConfirm: namePromise,
        });

        const updatedName = await namePromise;
        void app().nav.close(popupId);

        if (currentName !== updatedName) {
            await app().server.invoke.playerSetName({ name: updatedName });
        }

        void this._actionComplete(updatedName);
    }
}
