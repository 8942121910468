import { IFlow } from '../../lib/pattern/IFlow';
import NakedPromise from '../../lib/pattern/NakedPromise';
import {
    guessInstalledNativeAppPlatform,
    isNativePromoEnabled,
    shouldTriggerNativeReward,
} from '../../lib/util/native';
import { InstantGame } from '../../plugins/instantGames/InstantGame';
import native from '../../replicant/defs/native';
import app from '../getApp';
import { HomeScreen } from '../main/home/HomeScreen';
import { HomeSequenceFlow } from './HomeSequenceFlow';
import { LineLoginFlow } from './LineLoginFlow';
import { NativePromoFlow } from './NativePromoFlow';

// for start up
export class StartFlow implements IFlow {
    private readonly _complete = new NakedPromise<boolean>();
    private _homeScreen: HomeScreen;

    // init
    //-------------------------------------------------------------------------
    constructor(opts: { homeScreen: HomeScreen }) {
        this._homeScreen = opts.homeScreen;
    }

    // impl
    //-------------------------------------------------------------------------
    public async execute() {
        if (app().platform.guest) {
            await new LineLoginFlow().execute();
        }

        await new HomeSequenceFlow({ homeScreen: this._homeScreen }).execute();

        const launchPromoId = 'launchAppPromo';
        if (
            isNativePromoEnabled() &&
            !app().platform.native &&
            guessInstalledNativeAppPlatform() === null && // if installed crossplay promo is triggered in PreLaunchFlow
            app().game.player.isCooldownFinished(launchPromoId)
        ) {
            await app().server.invoke.triggerCooldown({ id: launchPromoId });
            await new NativePromoFlow({
                payload: {
                    triggerSource: 'launch_install',
                    feature: 'play_on_app_popup',
                    subFeature: 'play_on_app_popup_cta',
                },
            }).execute();
        }

        if (shouldTriggerNativeReward()) {
            const claimPromise = new NakedPromise();
            void app().nav.open('appRewardPopup', { ...native.rewards, onClaim: claimPromise.resolve });
            await claimPromise;
            void app().nav.close('appRewardPopup');

            const appOs = InstantGame.platform.osType as 'IOS_APP' | 'ANDROID_APP';
            const claimExecutionMap = {
                IOS_APP: app().server.invoke.claimIosNativeReward,
                ANDROID_APP: app().server.invoke.claimAndroidNativeReward,
            };
            await claimExecutionMap[appOs]();
        }

        void this._actionComplete(true);
        return this._complete;
    }

    private async _actionComplete(success: boolean) {
        this._complete.resolve(success);
    }
}
