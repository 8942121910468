import { Sprite } from 'pixi.js';

import { uiAlignCenter, uiAlignCenterY, uiAlignRight, uiSizeToWidth } from '../../../../lib/pixi/uiTools';
import { BasicText, BasicTextOptions } from '../text/BasicText';
import { ImageButton, ImageButtonOptions } from './ImageButton';

// types
//-----------------------------------------------------------------------------
export type ConsumeButtonOptions = {
    text?: string;
    consumeAmount: number;
} & ImageButtonOptions &
    BasicTextOptions;

const manifest = {
    icon: 'icon.coin.png',
};

/*
    ui: image button with text and consume icon + amount
*/
export class ConsumeButton extends ImageButton {
    // fields
    //-------------------------------------------------------------------------
    private _options0: ConsumeButtonOptions;
    private _text: BasicText;

    // properties
    //-------------------------------------------------------------------------
    public get textView(): BasicText {
        return this._text;
    }

    public get text() {
        return this._text.text;
    }

    public set text(text: string) {
        this._text.text = text;
    }

    static assets(): string[] {
        return Object.values(manifest);
    }

    // init
    //-------------------------------------------------------------------------
    constructor(options: ConsumeButtonOptions) {
        super(options);

        // set fields
        this._options0 = options;

        // add text
        this._text = this.button.addChild(new BasicText(options));
        this._text.pivot.y = this._text.height / 2;

        const coin = Sprite.from(manifest.icon);
        coin.scale.set(0.75);

        const amount = new BasicText({ text: `${options.consumeAmount}`, style: { ...options.style, fontSize: 36 } });

        uiSizeToWidth(this._text, this.button.width * 0.5 - 14);
        uiAlignCenter(this.button, this._text, -48, -8);
        uiSizeToWidth(amount, 60);

        this.button.addChild(coin, amount);
        uiAlignRight(this.button, coin, -102);
        uiAlignCenterY(this.button, coin, -6);
        uiAlignRight(this.button, amount, -34);
        uiAlignCenterY(this.button, amount, -6);
    }
}
