import { NakedPromise } from '@play-co/astro';
import { captureMessage } from '@sentry/browser';

import { IFlow } from '../../lib/pattern/IFlow';
import { guessInstalledNativeAppPlatform } from '../../lib/util/native';
import { InstantGame } from '../../plugins/instantGames/InstantGame';
import { PowerBoosterId } from '../../replicant/defs/booster';
import { productDefs, ProductId } from '../../replicant/defs/product';
import { ShopPinchRestore } from '../lib/analytics/pinch';
import { getPurchaseFlow, trackPurchaseFlowStart } from '../lib/analytics/product';
import { NativePromoFlow } from './NativePromoFlow';
import app from '../getApp';

export class IapFlow implements IFlow {
    private _productId: ProductId;
    private _shopPinch?: ShopPinchRestore;
    private _crossplayPowerBoosters?: PowerBoosterId[];

    // init
    //-------------------------------------------------------------------------
    constructor(options: { id: ProductId; shopPinch?: ShopPinchRestore; crossplayPowerBoosters?: PowerBoosterId[] }) {
        this._productId = options.id;
        this._shopPinch = options?.shopPinch;
        this._crossplayPowerBoosters = options?.crossplayPowerBoosters;
    }

    // impl
    //-------------------------------------------------------------------------
    public async execute() {
        const opts = {
            purchaseFlow: getPurchaseFlow(),
            productID: this._productId,
            feature: 'iap',
            subFeature: 'iap_coins_shop',
            revenueGross: productDefs[this._productId].priceUsd / 100,
        };
        trackPurchaseFlowStart(opts);
        if (app().product.supported) {
            // run purchase flow & start spinner, spinner resumed on _onProvisionProductAsync or cancel/fail
            ++app().busy.count;
            const status = await app().product.purchase(this._productId, opts);
            if (status !== 'ok') {
                --app().busy.count;

                const closePromise = new NakedPromise();
                const interruptId = 'purchaseInterruptPopup';
                await app().nav.open(interruptId, {
                    type: status,
                    onOk: closePromise.resolve,
                    underlay: 0.6,
                });
                await closePromise;
                void app().nav.close(interruptId);
            }
        } else {
            if (app().platform.guest || app().platform.native) {
                captureMessage('No IAP support on native/guest', {
                    level: 'warning',
                    extra: { platform: InstantGame.platform.osType },
                });
                return;
            }
            await new NativePromoFlow({
                skipPopup: true,
                shopPinch: this._shopPinch,
                crossplayPowerBoosters: this._crossplayPowerBoosters,
                payload: {
                    triggerSource:
                        guessInstalledNativeAppPlatform() !== null ? 'purchase_redirect' : 'purchase_install',
                    feature: 'shop',
                    productID: `${this._productId}`,
                },
            }).execute();
        }
    }
}
