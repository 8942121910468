import { Layout } from '@pixi/layout';
import { Container, NineSlicePlane, Sprite, Texture } from 'pixi.js';

import { BasicHandler, SizeType } from '../../../lib/defs/types';
import { TouchInputComponent } from '../../../lib/pixi/components/TouchInputComponent';
import { uiAlignCenterX, uiAlignCenterY } from '../../../lib/pixi/uiTools';
import { pixiConfig } from '../../defs/config';
import { LayoutScreen } from '../../lib/screens/LayoutScreen';
import { TextImageButton } from '../../lib/ui/buttons/TextImageButton';
import { BasicText } from '../../lib/ui/text/BasicText';
import app from '../../getApp';

// types
//-----------------------------------------------------------------------------
export type MessagePopup2Options = {
    title?: string;
    text: string;
    y?: number;
    width: number;
    opacity?: number;
    onOk?: BasicHandler;
    onTap?: BasicHandler;
};

// constants
//-----------------------------------------------------------------------------
const manifest = {
    bg: 'frame.puzzletip.png',
    close: 'button.green.png',
};

/*
    tooltip message popup
*/
export class MessagePopup2 extends LayoutScreen {
    // fields
    //-------------------------------------------------------------------------
    // scene
    protected _content: Container;
    protected _message: Layout;
    private _dialog: NineSlicePlane;
    private _text: BasicText;
    private _underlayInput: TouchInputComponent;

    // properties
    //-------------------------------------------------------------------------
    protected get content(): Container {
        return this._content;
    }

    protected get message(): Layout {
        return this._message;
    }

    public override resized(size: SizeType): void {
        super.resized(size);

        if (this.message) {
            const s = Math.min(size.height / pixiConfig.size.height, 1);
            this.message.width = this.root.width;
            this.message.height = this.root.height;
            this.message.scale.set(s);
            this.message.x = (this.root.width - this.message.width * s) / 2;
        }
    }

    // impl
    //-------------------------------------------------------------------------
    public preload() {
        return app().resource.loadAssets([...Object.values(manifest)]);
    }

    public async spawning(options?: MessagePopup2Options) {
        this._spawn(options);
    }

    public despawned() {
        // remove all children
        this.root.removeChildren();
        // FIXME workaround to remove all children
        for (const id of this.root.content.children.keys()) {
            this.root.removeChildByID(id);
        }

        // disable input
        if (this._underlayInput) this._underlayInput.enabled = false;
    }

    // private: scene
    //-------------------------------------------------------------------------
    private _spawn(options: MessagePopup2Options) {
        this._content = this.root.addChild(new Container());

        this._message = this.root.addChild(
            new Layout({
                styles: {
                    width: '100%',
                    height: '100%',
                },
            }),
        );

        this._spawnScreen(options);
        this._spawnMessage(options);

        if (options.onTap) {
            this._underlayInput = Object.assign(new TouchInputComponent(this.root), {
                onTap: async () => options.onTap?.(),
            });
        }
    }

    private _spawnScreen(options: MessagePopup2Options) {
        if (options.opacity !== 0) {
            const screen = Object.assign(Sprite.from(Texture.WHITE), {
                alpha: options.opacity,
                tint: 0,
                interactive: !!options.onOk,
            });
            this.message.addContent({
                screen: {
                    content: screen,
                    styles: {
                        position: 'center',
                        minWidth: '100%',
                        minHeight: '100%',
                    },
                },
            });
        }
    }

    private _spawnMessage(options: MessagePopup2Options) {
        if (!options.text) return;

        const dialog = (this._dialog = new NineSlicePlane(Texture.from(manifest.bg), 90, 60, 34, 42));

        const text = new BasicText({
            text: options.text,
            style: {
                fill: '#3E4047',
                align: 'center',
                fontWeight: 'bold',
                fontSize: 30,
                lineJoin: 'round',
                wordWrap: true,
                wordWrapWidth: options.width || 600,
            },
        });
        this._text = dialog.addChild(text);
        this._dialog.width = this._text.width + 92;
        this._dialog.height = this._text.height + 124;
        uiAlignCenterX(this._dialog, this._text);
        uiAlignCenterY(this._dialog, this._text, options.onOk ? -14 : -6);

        if (options.onOk) {
            const button = new TextImageButton({
                text: '[buttonOk]',
                image: manifest.close,
                y: -4,
                slice: {
                    width: 160,
                    height: 100,
                    left: 30,
                    top: 16,
                    right: 30,
                    bottom: 32,
                },
                style: {
                    dropShadow: true,
                    dropShadowAngle: Math.PI / 2,
                    dropShadowDistance: 3,
                    dropShadowAlpha: 0.7,
                    dropShadowBlur: 5,
                    fill: '#fff',
                    fontSize: 32,
                    fontWeight: 'bold',
                    lineJoin: 'round',
                    strokeThickness: 2,
                },
            }).props({
                y: dialog.height - 62,
                onPress: options.onOk,
            });
            dialog.addChild(button);
            uiAlignCenterX(dialog, button);
        }

        if (options.onTap) {
            this.message.props({
                onTap: options.onTap,
            });
        }

        this.message.addContent({
            dialog: {
                content: dialog,
                styles: {
                    marginTop: options.y || 0,
                    position: 'topCenter',
                },
            },
        });
    }
}
