import { Graphics, Sprite, Texture } from 'pixi.js';

import { Animation } from '../../../lib/animator/Animation';
import { BasicAsyncHandler } from '../../../lib/defs/types';
import UpdateObserver from '../../../lib/pattern/UpdateObserver';
import { TouchInputComponent } from '../../../lib/pixi/components/TouchInputComponent';
import { uiAlignCenter, uiSizeToFit } from '../../../lib/pixi/uiTools';
import { tween } from '../../../lib/util/tweens';
import { formatResourceCount } from '../../lib/tools/appTools';
import { BasicText } from '../../lib/ui/text/BasicText';
import { onDownAnimation } from '../../lib/util/clickAnimation';
import app from '../../getApp';

const DOWN_SCALE = 1.04;
const DURATION = 0.2;

const manifest = {
    frame: 'panel.stats.small.png',
};

export type StatsViewOptions = { startAmount: number };

export default abstract class StatsView extends Graphics {
    // events
    public onPress?: BasicAsyncHandler;

    protected _amount: BasicText;
    protected _icon: Sprite;
    protected _observer = new UpdateObserver();
    protected _bg: Sprite;
    // for animation
    protected _defaultScale: number;

    private _animation: Animation;

    constructor(opts: StatsViewOptions) {
        super();
        const { startAmount } = opts;
        // transparent main container
        this.beginFill(0x0, 0.001).drawRect(0, 0, 180, 80).endFill();

        // set default scale to handle tap animations
        this._defaultScale = 1;

        Object.assign(new TouchInputComponent(this), {
            onTap: this._onTap.bind(this),
            onDown: this._onDown.bind(this),
            onUp: this._onUp.bind(this),
        });

        this._bg = Sprite.from(Texture.from(manifest.frame));
        this.addChild(this._bg);
        uiAlignCenter(this, this._bg);

        this._amount = new BasicText({
            text: `${startAmount}`,
            style: {
                fill: '#FFF',
                fontSize: 26,
                lineJoin: 'round',
                align: 'center',
                stroke: '#1D030B',
                strokeThickness: 4,
                fontWeight: 'bold',
            },
        });
        this._amount.pivot.y = this._amount.height / 2;
        this.addChild(this._amount);
        this._updateAmount(startAmount);

        this.pivot.set(this.width / 2, this.height / 2);
    }

    public static assets(): string[] {
        return Object.values(manifest);
    }

    public forceUpdateAmount(amount: number) {
        this._observer.stop();
        this._updateAmount(amount);
    }

    public resumeUpdateAmount() {
        this._observer.start();
    }

    protected _updateAmount(amount: number) {
        this._amount.text = `${formatResourceCount(amount)}`;
        uiSizeToFit(this._amount, 66, 90);
        uiAlignCenter(this, this._amount, 15, -1);
    }

    // private: handlers
    //-------------------------------------------------------------------------
    private async _onTap() {
        void app().sound.play('button.ogg', { dupes: 5, volume: 0.6 });

        this._animation?.cancel();
        this.scale.set(this._defaultScale);

        // custom click animation to allow scaling of component
        this._animation = this.animate()
            .add(this.scale, { x: DOWN_SCALE, y: DOWN_SCALE }, DURATION, tween.backIn())
            .add(this.scale, { x: this._defaultScale, y: this._defaultScale }, DURATION, tween.backOut());

        await this.onPress?.();
    }

    private _onDown() {
        this._animation?.cancel();
        this._animation = onDownAnimation(this);

        this.scale.set(this._defaultScale);
        this._animation = this.animate().add(
            this.scale,
            { x: this._defaultScale - 0.06, y: this._defaultScale - 0.06 },
            DURATION,
            tween.backIn(),
        );
    }

    private _onUp() {
        this._animation?.cancel();

        this.scale.set(this._defaultScale);
        this._animation = this.animate().add(
            this.scale,
            { x: this._defaultScale, y: this._defaultScale },
            DURATION,
            tween.backIn(),
        );
    }
}
