import { NakedPromise } from '@play-co/astro';

import { textLocaleFormat } from '../../../lib/util/textTools';
import { tween } from '../../../lib/util/tweens';
import { GameId } from '../../../replicant/defs/gameDay';
import { sleep } from '../../../replicant/util/jsTools';
import { trackGameDayStep } from '../../lib/analytics/gameDay';
import { GameDayPlayer, GameDayScreen } from '../../main/home/GameDayScreen';
import { GameDayFlow, GameDayFlowOpts, ScoreData } from './GameDayFlow';
import app from '../../getApp';

const scoreboardData: ScoreData[] = [
    {
        balls: 3,
        strikes: 0,
        outs: 2,
        visitorData: ['0', '0', '0'],
        homeData: ['0', '0', '0'],
        visitorR: 0,
        homeR: 0,
    },
    {
        balls: 0,
        strikes: 2,
        outs: 2,
        visitorData: ['0', '0', '0', '2', '0', '1', '0', '0'],
        homeData: ['0', '0', '0', '0', '0', '0', '0', '0'],
        visitorR: 3,
        homeR: 0,
    },
    {
        balls: 0,
        strikes: 0,
        outs: 0,
        visitorData: ['0', '0', '0', '2', '0', '1', '0', '0'],
        homeData: ['0', '0', '0', '0', '0', '0', '0', '0', '4x'],
        visitorR: 3,
        homeR: 4,
    },
];

export class GameDay7Flow extends GameDayFlow {
    // fields
    //-------------------------------------------------------------------------

    // init
    //-------------------------------------------------------------------------
    constructor(opts: GameDayFlowOpts) {
        super(opts);
        this._visitor = app().server.state.name;
        this._home = textLocaleFormat('[gameDay7Opponent]');
        this._opponent = opts.opponentOverride ?? textLocaleFormat('[gameDay7Opponent]');
        this._scoreboard = 'board1';
        this._gameDaySteps = [
            { handler: this._intro, substeps: this._introSubSteps },
            { handler: this._step1, substeps: 1 },
            { handler: this._step2, substeps: 1 },
            { handler: this._step3, substeps: 2 },
            { handler: this._step4, substeps: 1 },
            { handler: this._step5, substeps: 2 },
            { handler: this._step6, substeps: 1 },
            { handler: this._stepEnding, substeps: this._endingSubSteps },
        ];
    }

    // impl
    //-------------------------------------------------------------------------
    private async _intro() {
        await this._baseIntro({ scoreData: scoreboardData[0], tapDialog: `[gameDay7Step0]`, nextBgId: 'gameDay3' });
    }

    private async _step1() {
        let stepIndex = this._stepOffset[this._step];
        if (!this._gameDayScreen) {
            this._gameDayScreen = (await app().nav.open('gameDayScreen', {
                bgId: 'gameDay3',
                players: [{ skin: 'character3_boy_red', clip: 'pitcher_idle_flip', loop: true } as GameDayPlayer],
            })) as GameDayScreen;
        }

        void app().nav.preload('gameDayScreen', { bgId: 'gameDay2' });
        await sleep(1.25);

        await this._gameDayScreen.playPlayerAnimation({ id: 'pitcher_throw_flipped' });

        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay2',
            players: [
                {
                    skin: 'character2_girl',
                    offsetX: 30,
                    offsetY: 40,
                    clip: 'batter_ball_surprised',
                    loop: false,
                } as GameDayPlayer,
            ],
        })) as GameDayScreen;

        await sleep(1.1);
        void this._gameDayScreen.playPlayerAnimation({ id: 'batter_idle', loop: true, mix: 0.25 });

        void this._gameDayScreen.preloadChoiceView(); // preload choice view for next step
        const tapPromise = new NakedPromise();
        await this._gameDayScreen.spawnBubbleTap({
            dialogText: `[gameDay7Step1]`,
            promise: tapPromise,
            bubbleOffset: { x: 0, y: this._gameDayScreen.base.height * 0.5 - this._gameDayScreen.base.height * 0.22 },
            parentOverride: this._gameDayScreen.base,
        });
        await tapPromise;
        trackGameDayStep({ stepIndex: stepIndex++, stepName: 'intro_swing', stepType: 'tap' });
    }

    private async _step2() {
        let stepIndex = this._stepOffset[this._step];
        // same as previous scene, avoid fade in its not needed
        if (!this._gameDayScreen) {
            this._gameDayScreen = (await app().nav.open('gameDayScreen', {
                bgId: 'gameDay2',
                players: [
                    {
                        skin: 'character2_girl',
                        offsetX: 30,
                        offsetY: 40,
                        clip: 'batter_idle',
                        loop: true,
                    } as GameDayPlayer,
                ],
            })) as GameDayScreen;
        }

        const choicePromise = new NakedPromise<number>();
        void this._gameDayScreen.spawnBubbleChoice({
            dialogText: '[gameDay7Step2]',
            choices: ['[gameDay7Choice0]', '[gameDay7Choice1]'],
            promise: choicePromise,
            bubbleOffset: { x: 0, y: this._gameDayScreen.base.height * 0.5 - this._gameDayScreen.base.height * 0.22 },
            parentOverride: this._gameDayScreen.base,
        });

        void app().nav.preload('gameDayScreen', { bgId: 'gameDay3' });
        // preload sfx
        void app().resource.loadAsset(this._batSFX);

        const choiceIndex = await choicePromise;

        trackGameDayStep({ stepIndex: stepIndex++, stepName: 'first_choice', stepType: 'choice' });

        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay3',
            players: [
                {
                    skin: 'character3_boy_red',
                    clip: 'pitcher_idle_flip',
                    loop: true,
                } as GameDayPlayer,
            ],
        })) as GameDayScreen;

        void app().nav.preload('gameDayScreen', { bgId: 'gameDay2' });
        await sleep(0.5);

        await this._gameDayScreen.playPlayerAnimation({ id: 'pitcher_throw_flipped' });

        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay2',
            players: [
                {
                    skin: 'character2_girl',
                    offsetX: 30,
                    offsetY: 40,
                    clip: choiceIndex === 0 ? 'batter_ball_onpurpose' : 'batter_swing',
                    loop: false,
                } as GameDayPlayer,
            ],
        })) as GameDayScreen;

        if (choiceIndex === 0) {
            // continue and allow the batter_ball_onpurpose to walk off screen
            await sleep(2.45);
        } else {
            void sleep(0.11).then(() => app().sound.play(this._batSFX));
            await sleep(0.5);
            // instead of long animation including walk switch scene to red boy
            this._gameDayScreen = (await app().nav.open('gameDayScreen', {
                bgId: 'gameDay3',
                players: [{ skin: 'character3_boy_red', clip: 'pitcher_watchhomerun', loop: false } as GameDayPlayer],
            })) as GameDayScreen;

            await sleep(1.4);
        }
    }

    private async _step3() {
        let stepIndex = this._stepOffset[this._step];
        // preload sfx
        void app().resource.loadAsset(this._catchSFX);
        // girl run
        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay2',
            players: [
                {
                    skin: 'character1_boy',
                    clip: 'run',
                    offsetX: 200,
                    offsetY: 40,
                    loop: true,
                    scaleX: -1,
                    animateRun: true,
                } as GameDayPlayer,
            ],
        })) as GameDayScreen;

        const runPromise = new NakedPromise();
        await this._gameDayScreen.spawnBubbleTap({
            dialogText: `[gameDay7Step3]`,
            promise: runPromise,
            narrator: 'arrow',
            bubbleOffset: { x: 0, y: this._gameDayScreen.base.height - 150 },
            parentOverride: this._gameDayScreen.base,
        });

        void this._gameDayScreen.preloadScoreboard(this._scoreboard);
        await runPromise;

        trackGameDayStep({ stepIndex: stepIndex++, stepName: 'run', stepType: 'tap' });

        // do a transition for score panel as well (part of feedback)
        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay2',
        })) as GameDayScreen;

        void this._gameDayScreen.spawnScoreDynamic({
            ...scoreboardData[1],
            visitor: this._visitor,
            home: this._home,
            scoreboard: this._scoreboard,
        });

        const scorePromise = new NakedPromise();
        await this._gameDayScreen.spawnBubbleTap({
            dialogText: `[gameDay7Step4]`,
            promise: scorePromise,
            narrator: 'arrow',
            bubbleOffset: { x: 0, y: this._gameDayScreen.base.height - 150 },
            parentOverride: this._gameDayScreen.base,
        });

        void app().nav.preload('gameDayScreen', { bgId: 'gameDay3' });
        await scorePromise;

        trackGameDayStep({ stepIndex: stepIndex++, stepName: 'run_score', stepType: 'tap' });
    }

    private async _step4() {
        let stepIndex = this._stepOffset[this._step];
        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay3',
            players: [{ skin: 'character1_boy', clip: 'pitcher_idle_flip', loop: true } as GameDayPlayer],
        })) as GameDayScreen;

        const tapPromise = new NakedPromise();

        await this._gameDayScreen.spawnBubbleTap({
            dialogText: `[gameDay7Step5]`,
            promise: tapPromise,
            bubbleOffset: { x: 0, y: this._gameDayScreen.base.height * 0.5 - this._gameDayScreen.base.height * 0.22 },
            parentOverride: this._gameDayScreen.base,
        });

        void app().nav.preload('gameDayScreen', { bgId: 'gameDay2' });
        await tapPromise;

        trackGameDayStep({ stepIndex: stepIndex++, stepName: 'pitcher_idle', stepType: 'tap' });

        // nervous red playuer
        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay2',
            players: [{ skin: 'character3_boy_red', clip: 'batter_idle_unconfident', loop: true } as GameDayPlayer],
        })) as GameDayScreen;

        void this._gameDayScreen.preloadChoiceView();
        void app().nav.preload('gameDayScreen', { bgId: 'gameDay4' });

        await sleep(1.25); // pause before going to next scene
    }

    private async _step5() {
        let stepIndex = this._stepOffset[this._step];
        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay4',
            players: [
                {
                    skin: 'character2_girl',
                    offsetX: 80,
                    clip: 'fielder_idle',
                    loop: true,
                    scaleX: -1,
                } as GameDayPlayer,
            ],
        })) as GameDayScreen;

        const tapPromise = new NakedPromise();

        await this._gameDayScreen.spawnBubbleTap({
            dialogText: `[gameDay7Step6]`,
            promise: tapPromise,
            bubbleOffset: { x: 0, y: this._gameDayScreen.base.height * 0.5 - this._gameDayScreen.base.height * 0.22 },
            parentOverride: this._gameDayScreen.base,
        });
        await tapPromise;

        const choicePromise = new NakedPromise<number>();
        void this._gameDayScreen.spawnBubbleChoice({
            dialogText: '[gameDay7Step7]',
            choices: ['[gameDay7Choice2]', '[gameDay7Choice3]'],
            promise: choicePromise,
            bubbleOffset: { x: 0, y: this._gameDayScreen.base.height * 0.5 - this._gameDayScreen.base.height * 0.22 },
            parentOverride: this._gameDayScreen.base,
        });
        trackGameDayStep({ stepIndex: stepIndex++, stepName: 'grounders', stepType: 'tap' });

        void app().nav.preload('gameDayScreen', { bgId: 'gameDay3' });
        const choiceIndex = await choicePromise;

        trackGameDayStep({ stepIndex: stepIndex++, stepName: 'second_choice', stepType: 'choice' });

        if (choiceIndex === 0) {
            void this._gameDayScreen.playPlayerAnimation({ id: 'fielder_walk', loop: true });
            // custom one time animation
            const player = this._gameDayScreen.mainPlayer;
            await player
                .animate()
                .add(player.position, { x: player.x - 230, y: player.y - 160 }, 1.7, tween.linear)
                .promise();
        }
    }

    private async _step6() {
        let stepIndex = this._stepOffset[this._step];
        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay3',
            players: [{ skin: 'character1_boy', clip: 'pitcher_throw_flipped', loop: false } as GameDayPlayer],
        })) as GameDayScreen;

        void app().resource.loadAsset(this._batSFX);
        void app().nav.preload('gameDayScreen', { bgId: 'gameDay2' });
        await sleep(0.8);

        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay2',
            players: [{ skin: 'character3_boy_red', clip: 'batter_homerun', loop: false } as GameDayPlayer],
        })) as GameDayScreen;
        void app().sound.play(this._batSFX);
        await sleep(1.1);

        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay3',
            players: [{ skin: 'character1_boy', clip: 'pitcher_watchhomerun', loop: false } as GameDayPlayer],
        })) as GameDayScreen;

        void app().nav.preload('gameDayScreen', { bgId: 'gameDay4' });
        await sleep(1.2);

        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay4',
            players: [
                {
                    skin: 'character2_girl',
                    offsetX: 30,
                    offsetY: 40,
                    clip: 'pitcher_watchhomerun',
                    loop: false,
                } as GameDayPlayer,
            ],
        })) as GameDayScreen;

        await sleep(1.1);
        void this._gameDayScreen.playPlayerAnimation({ id: 'pitcher_watchhomerun_idle', loop: true });

        const watchPromise = new NakedPromise();
        await this._gameDayScreen.spawnBubbleTap({
            dialogText: `[gameDay7Step8]`,
            promise: watchPromise,
            narrator: 'arrow',
            bubbleOffset: { x: 0, y: this._gameDayScreen.base.height - 150 },
            parentOverride: this._gameDayScreen.base,
        });

        void app().nav.preload('gameDayScreen', { bgId: 'gameDay0' });
        await watchPromise;
        trackGameDayStep({ stepIndex: stepIndex++, stepName: 'watch_swing', stepType: 'tap' });
    }

    private async _stepEnding() {
        await this._baseLoss({ scoreData: scoreboardData[2], tapDialog: `[gameDay7Step9]` });
    }
}
