import { analytics } from '@play-co/gcinstant';
import teamMorale from '../../../replicant/defs/teamMorale';

export function trackMoralePinchStart(opts: { pinchId: number; scenarioId: number }) {
    const { pinchId, scenarioId } = opts;
    const pinchState = teamMorale.teamMoraleConfig[scenarioId][pinchId].id;
    analytics.pushEvent('CoachingDialogStart', { pinchState });
}

export function trackMoralePinchFinish(opts: { pinchId: number; scenarioId: number; selectedOption: number }) {
    const { pinchId, scenarioId, selectedOption } = opts;
    const pinchState = teamMorale.teamMoraleConfig[scenarioId][pinchId].id;
    analytics.pushEvent('CoachingDialogFinish', { pinchState, selectedOption });
}
