import { Container, Graphics, NineSlicePlane, Sprite, Texture } from 'pixi.js';

import { Animation } from '../../../lib/animator/Animation';
import NakedPromise from '../../../lib/pattern/NakedPromise';
import { TouchInputComponent } from '../../../lib/pixi/components/TouchInputComponent';
import { uiAlignBottom, uiAlignCenter, uiAlignCenterX, uiCreateQuad, uiSizeToFit } from '../../../lib/pixi/uiTools';
import { textLocaleFormat } from '../../../lib/util/textTools';
import { tween } from '../../../lib/util/tweens';
import { Scoreboard } from '../../../replicant/defs/gameDay';
import { sleep } from '../../../replicant/util/jsTools';
import { SpeechGenerator } from '../../components/SpeechGenerator';
import { LayoutScreen2 } from '../../lib/screens/LayoutScreen2';
import { TextImageButton } from '../../lib/ui/buttons/TextImageButton';
import { Pointer } from '../../lib/ui/Pointer';
import { BasicText } from '../../lib/ui/text/BasicText';
import ChoiceView from './ChoiceView';
import { PlayerId } from './HomeScreen';
import app from '../../getApp';

const SPEECH_SPEED = 0.044;
const POINTER_SCALE = 0.45;
const MAIN_UI_Z = 10000;

const DEFAULT_DIALOGS = [
    '[tapPitcher0]',
    '[tapPitcher1]',
    '[tapPitcher2]',
    '[tapPitcher3]',
    '[tapPitcher4]',
    '[tapPitcher5]',
    '[tapPitcher6]',
    '[tapPitcher7]',
    '[tapPitcher8]',
    '[tapPitcher9]',
];

const PITCHER_DIALOGS = [
    '[tapCatcher0]',
    '[tapCatcher1]',
    '[tapCatcher2]',
    '[tapCatcher3]',
    '[tapCatcher4]',
    '[tapCatcher5]',
    '[tapCatcher6]',
    '[tapCatcher7]',
    '[tapCatcher8]',
    '[tapCatcher9]',
];

const BATTER_DIALOGS = [
    '[tapBatter0]',
    '[tapBatter1]',
    '[tapBatter2]',
    '[tapBatter3]',
    '[tapBatter4]',
    '[tapBatter5]',
    '[tapBatter6]',
    '[tapBatter7]',
    '[tapBatter8]',
    '[tapBatter9]',
];

export const TEAM_MATE_Y_OFF = 750;
const DEFAULT_Y_BOTTOM = 480;
const DEFAULT_Y_MIDDLE = 690;

const BUBBLE_TAP_MAP = {
    player: {
        bubbleY: (670 + (DEFAULT_Y_MIDDLE - DEFAULT_Y_BOTTOM)) * -1,
    },
    teamMateGirl: {
        bubbleY: (350 + TEAM_MATE_Y_OFF) * -1,
    },
    teamMateGuy: {
        bubbleY: (350 + TEAM_MATE_Y_OFF) * -1,
    },
};

export const PLAYER_BUBBLE_MAP = {
    player: {
        dialogs: DEFAULT_DIALOGS,
        offsetX: 0,
        offsetY: BUBBLE_TAP_MAP.player.bubbleY,
        slice: { left: 300, right: 80 },
        scaleX: 1,
    },
    teamMateGirl: {
        dialogs: PITCHER_DIALOGS,
        offsetX: -25,
        offsetY: BUBBLE_TAP_MAP.teamMateGirl.bubbleY,
        slice: { left: 80, right: 300 },
        scaleX: -1,
    },
    teamMateGuy: {
        dialogs: BATTER_DIALOGS,
        offsetX: 25,
        offsetY: BUBBLE_TAP_MAP.teamMateGuy.bubbleY,
        slice: { left: 80, right: 300 },
        scaleX: 1,
    },
};

// manifest
//-----------------------------------------------------------------------------
const manifest = {
    bubble: 'frame.bubble.png',
    narrator: 'frame.narrator.png',
    narratorNext: 'icon.next.arrow.png',
    // ---------
    // TODO LOAD ONLY WHEN NEEDED
    orangeButton: 'button.main.orange.png',
    titlePanel: 'panel.header.png',
};

export const boardAssets: Record<Scoreboard, { board: string; light: string }> = {
    board0: {
        board: 'panel.score.game.0.png',
        light: 'icon.score.light.0.png',
    },
    board1: {
        board: 'panel.score.game.1.png',
        light: 'icon.score.light.1.png',
    },
    board2: {
        board: 'panel.score.game.2.png',
        light: 'icon.score.light.2.png',
    },
};

// General screen for speech + tap interactions, expects a static bg implementation centered at the bottom
export abstract class SpeechScreen extends LayoutScreen2 {
    // scene
    protected _bg: Graphics | NineSlicePlane;
    protected _skipSpeechCount = 0; // larger than 0 means skip speech, larger than 1 speech and bubble delay
    protected _underlayInput: TouchInputComponent;
    protected _pointerIdleTime = 0;
    protected _isInteracting = false;
    protected _pointerAnimation?: Animation;
    protected speechDefaultY: number;
    // if pointer used, load in subclass ...Pointer.assets()
    protected _pointer?: Pointer;
    protected _pointerText?: BasicText;

    // impl
    //-------------------------------------------------------------------------
    public preload(options?: any) {
        return app().resource.loadAssets(Object.values(manifest));
    }

    public async preloadScoreboard(scoreboard: Scoreboard) {
        await Promise.all(app().resource.loadAssets([boardAssets[scoreboard].board, boardAssets[scoreboard].light]));
    }

    public async preloadChoiceView() {
        await Promise.all(app().resource.loadAssets(ChoiceView.assets()));
    }

    public spawnTiltedHeader(opts: { title: string; offsetY?: number }) {
        const { title, offsetY } = opts;
        const header = Sprite.from(manifest.titlePanel);
        const textView = new BasicText({
            text: title,
            style: {
                fill: '#01ADD9',
                fontSize: 50,
                fontWeight: 'bold',
                lineJoin: 'round',
                align: 'left',
            },
        });

        header.addChild(textView);
        uiAlignCenter(header, textView, 0, 8);

        textView.rotation = -0.048;

        header.alpha = 0;
        this._bg.addChild(header);
        uiAlignCenter(this._bg, header, 0, 80 + (offsetY ?? 0));
        header.animate().add(header, { alpha: 1 }, 0.3, tween.linear);
        return header;
    }

    public async spawnBubbleChoice(opts: {
        player?: 'player' | 'teamMateGirl' | 'teamMateGuy'; // if no player, default to main player in the middle
        dialogText?: string;
        choices: string[];
        promise: NakedPromise<number>;
        narrator?: 'simple' | 'arrow';
        bubbleOffset?: { x: number; y: number };
        parentOverride?: Container;
    }) {
        // this should be called in the flow that triggers this for instant load time here
        await this.preloadChoiceView();
        const { player, dialogText, choices, promise, narrator, bubbleOffset, parentOverride } = opts;
        const views: Container[] = [];
        this._underlayInput = this._underlayInput ?? new TouchInputComponent(this.root);
        this._underlayInput.enabled = true;
        let finishedSpeech = false;
        const onTap = async () => {
            if (!finishedSpeech) {
                this._skipSpeechCount++;
                return;
            }
        };

        // generic callback
        const onChoice = async (choiceIndex: number) => {
            const promises = [];
            await sleep(0.25); // small pause to allow click animation to play before despawning everything
            for (const view of views) {
                promises.push(
                    view
                        .animate()
                        .add(view, { alpha: 0 }, 0.3, tween.pow2Out)
                        .then(() => view.removeSelf()),
                );
            }
            await Promise.all(promises);
            this._skipSpeechCount = 0;
            this._underlayInput.enabled = false;
            this._isInteracting = false;
            promise.resolve(choiceIndex);
        };

        if (dialogText) {
            // bubble spawn and tap
            const bubble = await this.spawnSpeechBubble({
                dialogText,
                narrator,
                bubbleOffset: bubbleOffset ?? {
                    x: player ? PLAYER_BUBBLE_MAP[player].offsetX : 0,
                    y: player ? PLAYER_BUBBLE_MAP[player].offsetY : PLAYER_BUBBLE_MAP.player.offsetY,
                },
                slice: player ? PLAYER_BUBBLE_MAP[player].slice : null,
                scaleX: player ? PLAYER_BUBBLE_MAP[player].scaleX : null,
                onTapOverride: onTap,
                parentOverride,
                player,
            });

            views.push(bubble);
        }
        // this step requires tap to continue,
        // re-enable it even though it got disabled in the end of the shared bubble animation above
        this._underlayInput.enabled = true;
        finishedSpeech = true;

        const choice = new ChoiceView({ choices, onChoice });
        choice.alpha = 0;
        choice.animate().add(choice, { alpha: 1 }, this._skipSpeechCount > 0 ? 0 : 0.3, tween.pow2In);

        if (parentOverride) {
            parentOverride.addChild(choice);
            uiAlignBottom(parentOverride, choice, -18);
            uiAlignCenterX(parentOverride, choice);
        } else {
            this._bg.addChild(choice);
            uiAlignBottom(this._bg, choice, -18);
            uiAlignCenterX(this._bg, choice);
        }

        views.push(choice);
    }

    public async spawnBubbleTap(opts: {
        player?: 'player' | 'teamMateGirl' | 'teamMateGuy'; // if no player, default to main player in the middle
        dialogText?: string;
        narrator?: 'simple' | 'arrow';
        buttonOverride?: boolean;
        promise?: NakedPromise;
        bubbleOffset?: { x: number; y: number };
        parentOverride?: Container;
    }) {
        const { player, dialogText, narrator, buttonOverride, promise, bubbleOffset, parentOverride } = opts;
        this._isInteracting = true;

        const views: Container[] = [];
        this._underlayInput = this._underlayInput ?? new TouchInputComponent(this.root);
        this._underlayInput.enabled = true;
        let finishedSpeech = false;
        const onTap = async () => {
            if (!finishedSpeech) {
                this._skipSpeechCount++;
                return;
            }

            if (buttonOverride) {
                await sleep(0.25); // small pause to allow click animation to play before despawning everything
            }
            const promises = [];
            for (const view of views) {
                promises.push(
                    view
                        .animate()
                        .add(view, { alpha: 0 }, 0.3, tween.pow2Out)
                        .then(() => view.removeSelf()),
                );
            }
            await Promise.all(promises);
            // re-disable again after custom tap and reset everything
            this._underlayInput.enabled = false;
            this._skipSpeechCount = 0;
            this._isInteracting = false;
            promise.resolve();
        };

        // bubble spawn and tap
        const bubble = await this.spawnSpeechBubble({
            dialogText,
            narrator,
            bubbleOffset: bubbleOffset ?? {
                x: player ? PLAYER_BUBBLE_MAP[player].offsetX : 0,
                y: player ? PLAYER_BUBBLE_MAP[player].offsetY : PLAYER_BUBBLE_MAP.player.offsetY,
            },
            slice: player ? PLAYER_BUBBLE_MAP[player].slice : null,
            scaleX: player ? PLAYER_BUBBLE_MAP[player].scaleX : null,
            onTapOverride: onTap,
            parentOverride,
            player,
        });
        // this step requires tap to continue,
        // re-enable it even though it got disabled in the end of the shared bubble animation above
        this._underlayInput.enabled = !buttonOverride;
        finishedSpeech = true;

        if (bubble) views.push(bubble);

        // narrator has its own blinking arrow in the speech view
        if (!narrator) {
            this._spawnTapContainer({ views, buttonOverride, buttonCallback: onTap, parentOverride });
        }
    }

    public async spawnSpeechBubble(opts: {
        dialogText?: string;
        bubbleOffset: { x: number; y: number };
        slice?: { left: number; right: number };
        scaleX?: number;
        narrator?: 'simple' | 'arrow'; // 'narrator bubble' with/without blinking arrow or default 'bubble'
        onTapOverride?: () => Promise<any>;
        parentOverride?: Container;
        player: PlayerId;
    }) {
        // reset count on each new bubble to allow for normal speed + skip taps
        this._skipSpeechCount = 0;
        const { dialogText, bubbleOffset, slice, scaleX, narrator, onTapOverride, parentOverride } = opts;
        this._despawnPointer();

        this._underlayInput = this._underlayInput ?? new TouchInputComponent(this.root);

        if (onTapOverride) {
            this._underlayInput.onTap = () => onTapOverride();
        } else {
            this._underlayInput.onTap = async () => this._skipSpeechCount++;
        }

        let bubble;
        if (dialogText) {
            bubble = new NineSlicePlane(
                Texture.from(narrator ? manifest.narrator : manifest.bubble),
                slice ? slice.left : narrator ? 80 : 300,
                narrator ? 80 : 0,
                slice ? slice.right : 80,
                narrator ? 80 : 0,
            );
            bubble.width = 680;

            if (narrator === 'arrow') {
                const next = Sprite.from(manifest.narratorNext);
                bubble.addChild(next);
                next.position.set(bubble.width - 60, bubble.height - 60);

                next.animate()
                    .add(next, { alpha: 0 }, 0.8, tween.pow2Out)
                    .add(next, { alpha: 1 }, 0.8, tween.pow2In)
                    .loop();
            }

            const maxLabelWidth = bubble.width - 80;
            const label = new BasicText({
                style: {
                    fill: '#454299',
                    fontWeight: 'bold',
                    fontSize: 36,
                    lineJoin: 'round',
                    // this removes weird random line spacing for japanese texts
                    whiteSpace: 'normal',
                    wordWrap: true,
                    wordWrapWidth: maxLabelWidth,
                    align: 'center',
                },
            });

            bubble.addChild(label);
            label.scale.x = scaleX ?? 1;

            bubble.pivot.set(bubble.width * 0.5, bubble.height * 0.5);

            const fullText = textLocaleFormat(dialogText);
            if (parentOverride) {
                parentOverride.addChild(bubble);
                uiAlignCenterX(parentOverride, bubble, bubbleOffset.x);
            } else {
                this._bg.addChild(bubble);
                uiAlignCenterX(this._bg, bubble, bubbleOffset.x);
            }

            bubble.scale.x = scaleX ?? 1;

            bubble.y = parentOverride ? bubbleOffset.y : this._bg.height + bubbleOffset.y;
            bubble.zIndex = 5000;

            this._underlayInput.enabled = true;
            bubble.alpha = 0;
            bubble.animate().add(bubble, { alpha: 1 }, this._skipSpeechCount > 0 ? 0 : 0.3, tween.pow2In);
            await sleep(this._skipSpeechCount > 0 ? 0 : 0.5);

            const speechGenerator = new SpeechGenerator({
                profileId: opts.player === 'teamMateGirl' ? 'girl' : 'boy',
                rate: 0,
            });
            const volume = app().music.volume;
            if (this._skipSpeechCount === 0) {
                void speechGenerator.play();
                void app().music.fade(0.2, volume * 0.6);
            }

            const scaleSign = Math.sign(label.scale.x);
            for (let i = 0; i < fullText.length; i++) {
                label.text = fullText.substring(0, i + 1);
                if (scaleSign < 0) {
                    label.scale.x *= -1;
                    // set positive width & scale to allow width limit using a positive width
                    uiSizeToFit(label, maxLabelWidth, bubble.height - (narrator ? 40 : 110));
                    // revert temp scale before rendering to screen
                    label.scale.x *= -1;
                } else {
                    uiSizeToFit(label, maxLabelWidth, bubble.height - (narrator ? 40 : 110));
                }
                uiAlignCenter(bubble, label, -4, narrator ? -10 : -44);

                await sleep(this._skipSpeechCount > 0 ? 0 : SPEECH_SPEED);
            }

            void app().music.fade(0.2, volume);
            speechGenerator.stop();
        }

        return bubble;
    }

    private _spawnTapContainer(opts: {
        views: Container[];
        buttonOverride?: boolean;
        buttonCallback?: () => void;
        parentOverride?: Container;
    }) {
        const { views, buttonOverride, buttonCallback, parentOverride } = opts;
        // discuss faded alpha container?
        const tapFrame = uiCreateQuad(0x0, 0.001, this._bg.width, 126);

        if (buttonOverride) {
            const returnButton = new TextImageButton({
                text: '[buttonReturn]',
                image: manifest.orangeButton,
                y: -5,
                slice: {
                    width: 320,
                    height: 120,
                    left: 45,
                    top: 0,
                    right: 45,
                    bottom: 0,
                },
                style: {
                    fill: '#fff',
                    fontSize: 38,
                    fontWeight: 'bold',
                    lineJoin: 'round',
                },
            });

            returnButton.onPress = async () => buttonCallback();

            // const arrow = Sprite.from(manifest.arrowSmall);
            // returnButton.button.addChild(arrow);
            // arrow.scale.x = -1;
            // uiAlignCenterY(returnButton, arrow);
            // arrow.x = 65;
            // arrow.y -= 5;

            if (parentOverride) {
                parentOverride.addChild(returnButton);
                uiAlignBottom(parentOverride, returnButton, -70);
                uiAlignCenterX(parentOverride, returnButton);
            } else {
                this._bg.addChild(returnButton);
                uiAlignBottom(this._bg, returnButton, -70);
                uiAlignCenterX(this._bg, returnButton);
            }
            views.push(returnButton);
        } else {
            const tapLabel = new BasicText({
                text: `[tapToContinue]`,
                style: {
                    fill: '#FFF',
                    fontSize: 36,
                    lineJoin: 'round',
                    fontWeight: 'bold',
                    dropShadow: true,
                    dropShadowAngle: Math.PI / 2,
                    dropShadowAlpha: 0.6,
                    dropShadowDistance: 4,
                },
            });
            tapFrame.zIndex = MAIN_UI_Z;
            tapLabel.zIndex = MAIN_UI_Z;

            views.push(tapFrame, tapLabel);

            if (parentOverride) {
                parentOverride.addChild(tapFrame, tapLabel);
                uiAlignBottom(parentOverride, tapFrame);
                uiAlignBottom(parentOverride, tapLabel, -70);
                uiAlignCenterX(parentOverride, tapLabel);
            } else {
                this._bg.addChild(tapFrame, tapLabel);
                uiAlignBottom(this._bg, tapFrame);
                uiAlignBottom(this._bg, tapLabel, -70);
                uiAlignCenterX(this._bg, tapLabel);
            }
        }
    }

    private _despawnPointer() {
        if (this?._pointer) {
            this._pointerIdleTime = 0;
            this._pointerAnimation?.cancel();
            this._pointer.scale.x = Math.sign(this._pointer.parent.scale.x) * POINTER_SCALE;
            this._pointer
                .animate()
                .add(this._pointer.scale, { x: 0, y: 0 }, 0.2, tween.backIn(1.2))
                .then(() => {
                    this._pointer.destroy();
                    this._pointer = null;
                });
        }

        if (this._pointerText) {
            this._pointerText
                .animate()
                .add(this._pointerText, { alpha: 0 }, 0.2, tween.pow2Out)
                .then(() => {
                    this._pointerText.destroy();
                    this._pointerText = null;
                });
        }
    }
}
