import { Container, Sprite, Texture } from 'pixi.js';

import { DirectionId, ThrowerBlockProps } from '../../defs/block';
import { blockPositionView } from '../../util/blockTools';

// constants
//-----------------------------------------------------------------------------
const manifest = {
    enabled: 'block.thrower.png',
    disabled: 'block.thrower.disabled.png',
    cage: 'block.cage.front.png',
};

/*
    thrower block view
*/
export class ThrowerBlockView extends Container {
    // fields
    //-------------------------------------------------------------------------
    // input
    private _direction: DirectionId;
    // scene
    public _sprite: Sprite;
    // state
    private _enabled = true;

    // properties
    //-------------------------------------------------------------------------
    public get enabled(): boolean {
        return this._enabled;
    }

    public set enabled(value: boolean) {
        // update field
        this._enabled = value;

        // update asset according to state
        this._sprite.texture = Texture.from(manifest[value ? 'enabled' : 'disabled']);
    }

    // init
    //-------------------------------------------------------------------------
    constructor(props: ThrowerBlockProps) {
        // spawn
        super();

        // spawn sprite
        const sprite = (this._sprite = this.addChild(Sprite.from(manifest.enabled)));
        sprite.anchor.set(0.5);
        sprite.x = 43;
        sprite.y = 48;

        // position
        blockPositionView(this, props);
    }

    static assets(): string[] {
        return Object.values(manifest);
    }
}
