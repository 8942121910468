import { i18n } from '@play-co/astro';
import { FlexContainer } from '@play-co/flex';
import { Sprite, Texture } from 'pixi.js';

import { ProductItem } from '../../../replicant/defs/product';
import { TextLanguageId } from '../../defs/text';
import { TextImageButton } from '../../lib/ui/buttons/TextImageButton';
import { layoutConfig } from './layout';
import { manifest } from './manifest';

export class ShopBuyButton extends FlexContainer {
    public button: TextImageButton;

    constructor(item: ProductItem) {
        super({
            width: 214,
            height: 100,
            alignItems: 'center',
            justifyContent: 'center',
        });

        // create button
        const slice = 25;
        this.button = this.addChild(
            new TextImageButton({
                image: manifest.buyButton,
                slice: {
                    width: 214,
                    height: 100,
                    left: slice,
                    top: slice,
                    right: slice,
                    bottom: slice,
                },
                fit: false,
                text: `${item.getRenderPrice()}`,
                y: -5,
                style: {
                    fontSize: 40,
                    ...layoutConfig.fontWhite,
                },
            }).setFlex({
                position: 'absolute',
                width: '100%',
                height: '100%',
            }),
        );

        // create one time only button
        if (item.oneTimeOnly) {
            const language = i18n.language as TextLanguageId;
            const texture = Texture.from(language === 'en' ? manifest.badgeOneTimeOnlyEn : manifest.badgeOneTimeOnlyJa);
            this.addChild(new Sprite(texture))
                .setFlex({
                    position: 'absolute',
                    left: 0,
                    top: '40%',
                })
                .setFlexSizeAnchored(70, 70, 0.5);
        }
    }
}
