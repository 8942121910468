import gameConfig from '../defs/gameConfig';
import { LanguageId } from '../defs/settings';

export type ReplicantCreativeType =
    | 'first_session_0'
    | 'first_session_1'
    | 'first_session_2'
    | 'game_day_0_0'
    | 'game_day_0_1'
    | 'game_day_0_2'
    | 'game_day_1_0'
    | 'game_day_1_1'
    | 'game_day_1_2'
    | 'game_day_2_0'
    | 'game_day_2_1'
    | 'game_day_2_2'
    | 'game_day_3_0'
    | 'game_day_3_1'
    | 'game_day_3_2'
    | 'game_day_4_0'
    | 'game_day_4_1'
    | 'game_day_4_2'
    | 'game_day_5_0'
    | 'game_day_5_1'
    | 'game_day_5_2'
    | 'game_day_6_0'
    | 'game_day_6_1'
    | 'game_day_6_2'
    | 'game_day_7_0'
    | 'game_day_7_1'
    | 'game_day_7_2'

    // game day v2 (AB test)
    | 'd1d5g1_1'
    | 'd1d5g1_2'
    | 'd1d5g1_3'
    | 'd1d5g2_1'
    | 'd1d5g2_2'
    | 'd1d5g2_3'
    | 'd2d6g1_1'
    | 'd2d6g1_2'
    | 'd2d6g1_3'
    | 'd2d6g2_1'
    | 'd2d6g2_2'
    | 'd2d6g2_3'
    | 'd3d7g1_1'
    | 'd3d7g1_2'
    | 'd3d7g1_3'
    | 'd3d7g2_1'
    | 'd3d7g2_2'
    | 'd3d7g2_3'
    | 'd4d8g1_1'
    | 'd4d8g1_2'
    | 'd4d8g1_3'
    | 'd4d8g2_1'
    | 'd4d8g2_2'
    | 'd4d8g2_3'
    | 'practice_0_0'
    | 'practice_0_1'
    | 'practice_0_2'
    | 'practice_1_0'
    | 'practice_1_1'
    | 'practice_1_2'
    | 'practice_2_0'
    | 'practice_2_1'
    | 'practice_2_2'
    | 'practice_3_0'
    | 'practice_3_1'
    | 'practice_3_2'
    | 'practice_4_0'
    | 'practice_4_1'
    | 'practice_4_2'
    | 'lives_full'
    | 'pinch_0_0'
    | 'pinch_0_1'
    | 'pinch_1_0'
    | 'pinch_1_1'
    | 'pinch_2_0'
    | 'pinch_2_1'
    | 'pinch_3_0'
    | 'pinch_3_1'
    | 'pinch_4_0'
    | 'pinch_4_1';

export type CreativeText = {
    id: string;
    cta: string;
    text: string;
    title: string;
};

type ReplicantMessagesConfig = {
    [lang: string]: Record<
        ReplicantCreativeType,
        {
            [bucket: string]: { title?: string; text: string; cta: string };
        }
    >;
};

const config: ReplicantMessagesConfig = {
    // --------- game day V2 (2 games a day)
    en: {
        d1d5g1_1: {
            control: {
                text: 'Coach, the match is starting! Don’t miss it!',
                cta: 'It’s game time!',
            },
        },
        d1d5g1_2: {
            control: {
                text: 'We’re about to lose, Coach! Please, we need you!',
                cta: 'Hurry to the dugout',
            },
        },
        d1d5g1_3: {
            control: {
                text: 'Well… the game is over and we lost. What do we do now? 🙁',
                cta: 'Rally the team!',
            },
        },
        d1d5g2_1: {
            control: {
                text: 'Batter up, Coach! It’s time to hit this game out of the park.',
                cta: 'Swing into action!',
            },
        },
        d1d5g2_2: {
            control: {
                text: 'We’re in a pickle coach. We need you to help us bring the game home!',
                cta: 'Slide into the game.',
            },
        },
        d1d5g2_3: {
            control: {
                text: 'Strike three, we’re out… the game slipped through our gloves without you.💔',
                cta: 'Pitch a new game plan.',
            },
        },
        d2d6g1_1: {
            control: {
                text: 'A home run waiting to happen, coach. Let’s play ball!',
                cta: 'Hit the ground running',
            },
        },
        d2d6g1_2: {
            control: {
                text: 'Down by a few tunes, but it’s not over ‘til it’s over.',
                cta: 'Rally the troops!',
            },
        },
        d2d6g1_3: {
            control: {
                text: 'We really dropped the ball. Can we count on you to be there next time, coach? 🥺',
                cta: 'It’s time to regroup.',
            },
        },
        d2d6g2_1: {
            control: {
                text: 'Let’s warm up for a big win, Coach. Go team!',
                cta: 'Yeah! Go team!',
            },
        },
        d2d6g2_2: {
            control: {
                text: `The tide's against us, Coach. Can you turn it around?`,
                cta: 'Take the helm!',
            },
        },
        d2d6g2_3: {
            control: {
                text: 'It’s over, Coach… without you we just couldn’t pull through.💔',
                cta: 'Rebuild their spirit',
            },
        },
        d3d7g1_1: {
            control: {
                text: `It's time to shine, Coach! The team's ready! Let’s play ball!`,
                cta: 'Step up to bat!',
            },
        },
        d3d7g1_2: {
            control: {
                text: 'It’s crunch time and we’re on the brink of losing this. We need you boss!',
                cta: 'Dive back in!',
            },
        },
        d3d7g1_3: {
            control: {
                text: `The final bell's rung, and we're left in the dust... again. What do we do now? 😢`,
                cta: 'Restore their faith',
            },
        },
        d3d7g2_1: {
            control: {
                text: 'Game time. We’re eager for your strategy, Coach!',
                cta: 'Take Control',
            },
        },
        d3d7g2_2: {
            control: {
                text: `The game's getting away from us. We need your insight, Coach!`,
                cta: 'Jump into action!',
            },
        },
        d3d7g2_3: {
            control: {
                text: 'Another loss. 😢 We really needed you… 💔',
                cta: 'Answer their call',
            },
        },
        d4d8g1_1: {
            control: {
                text: 'Time to shine and lead us to victory. Go team!',
                cta: 'Let’s play ball!',
            },
        },
        d4d8g1_2: {
            control: {
                text: `We're facing defeat, Coach. It's time for a game-changing play`,
                cta: 'Seize the moment!',
            },
        },
        d4d8g1_3: {
            control: {
                text: 'As the stadium empties, so does our resolve... Is this the end of our journey, Coach?🥺',
                cta: 'Reignite the flame',
            },
        },
        d4d8g2_1: {
            control: {
                text: 'Coach, the match is starting! Don’t miss it!',
                cta: 'It’s game time!',
            },
        },
        d4d8g2_2: {
            control: {
                text: 'We’re about to lose, Coach! Please, we need you!',
                cta: 'Hurry to the dugout',
            },
        },
        d4d8g2_3: {
            control: {
                text: 'Well… the game is over and we lost. What do we do now? 🙁',
                cta: 'Rally the team!',
            },
        },
        // ---------
        first_session_0: {
            control: {
                text: 'Coach, the match is starting! Don’t miss it!',
                cta: 'It’s game time!',
            },
        },
        first_session_1: {
            control: {
                text: `We’re about to lose, Coach! Please, we need you!`,
                cta: 'Hurry to the dugout',
            },
        },
        first_session_2: {
            control: {
                text: `Well... the game is over and we lost. What do we do now? 🙁`,
                cta: 'Rally the team!',
            },
        },
        game_day_0_0: {
            control: {
                text: 'Coach, the match is starting! Don’t miss it!',
                cta: 'It’s game time!',
            },
        },
        game_day_0_1: {
            control: {
                text: `We’re about to lose, Coach! Please, we need you!`,
                cta: 'Hurry to the dugout',
            },
        },
        game_day_0_2: {
            control: {
                text: `Well... the game is over and we lost. What do we do now? 🙁`,
                cta: 'Rally the team!',
            },
        },
        game_day_1_0: {
            control: {
                text: 'Batter up, Coach! It’s time to hit this game out of the park.',
                cta: 'Swing into action!',
            },
        },
        game_day_1_1: {
            control: {
                text: `We’re in a pickle coach. We need you to help us bring the game home!`,
                cta: 'Slide into the game.',
            },
        },
        game_day_1_2: {
            control: {
                text: `Strike three, we’re out... the game slipped through our gloves without you.💔`,
                cta: 'Pitch a new game plan.',
            },
        },
        game_day_2_0: {
            control: {
                text: 'Today’s a home run waiting to happen, coach. Let’s play ball!',
                cta: 'Hit the ground running',
            },
        },
        game_day_2_1: {
            control: {
                text: `Down by a few tunes, but it’s not over ‘til it’s over.`,
                cta: 'Rally the troops!',
            },
        },
        game_day_2_2: {
            control: {
                text: `We really dropped the ball today. Can we count on you to be there next time, coach? 🥺`,
                cta: 'It’s time to regroup.',
            },
        },
        game_day_3_0: {
            control: {
                text: 'Let’s warm up for a big win today, Coach. Go team!',
                cta: 'Yeah! Go team!',
            },
        },
        game_day_3_1: {
            control: {
                text: `The tide's against us today, Coach. Can you turn it around?`,
                cta: 'Take the helm!',
            },
        },
        game_day_3_2: {
            control: {
                text: `It’s over, Coach... without you we just couldn’t pull through.💔`,
                cta: 'Rebuild their spirit',
            },
        },
        game_day_4_0: {
            control: {
                text: `It's time to shine, Coach! The team's ready! Let’s play ball!`,
                cta: 'Step up to bat!',
            },
        },
        game_day_4_1: {
            control: {
                text: `It’s crunch time and we’re on the brink of losing this. We need you boss!`,
                cta: 'Dive back in!',
            },
        },
        game_day_4_2: {
            control: {
                text: `The final bell's rung, and we're left in the dust... again. What do we do now? 😢`,
                cta: 'Restore their faith',
            },
        },
        game_day_5_0: {
            control: {
                text: `Game time. We’re eager for your strategy, Coach!`,
                cta: 'Take Control',
            },
        },
        game_day_5_1: {
            control: {
                text: `The game's getting away from us. We need your insight, Coach!`,
                cta: 'Jump into action!',
            },
        },
        game_day_5_2: {
            control: {
                text: `Another loss. 😢 We really needed you today... 💔`,
                cta: 'Answer their call',
            },
        },
        game_day_6_0: {
            control: {
                text: `Time to shine and lead us to victory. Go team!`,
                cta: 'Let’s play ball!',
            },
        },
        game_day_6_1: {
            control: {
                text: `We're facing defeat, Coach. It's time for a game-changing play`,
                cta: 'Seize the moment!',
            },
        },
        game_day_6_2: {
            control: {
                text: `As the stadium empties, so does our resolve... Is this the end of our journey, Coach?🥺`,
                cta: 'Reignite the flame',
            },
        },
        game_day_7_0: {
            control: {
                text: `Batter up, Coach! It’s time to hit this game out of the park.`,
                cta: 'Swing into action!',
            },
        },
        game_day_7_1: {
            control: {
                text: `We’re in a pickle coach. We need you to help us bring the game home!`,
                cta: 'Slide into the game',
            },
        },
        game_day_7_2: {
            control: {
                text: `Strike three, we’re out... the game slipped through our gloves without you.💔`,
                cta: 'Pitch a new game plan',
            },
        },
        practice_0_0: {
            control: {
                text: `Coach! Our morning practice is about to begin. Please guide us!`,
                cta: 'Let’s Start!',
            },
        },
        practice_0_1: {
            control: {
                text: `Coach! It’s already afternoon, but can you give us the practice menu?`,
                cta: 'Decide the menu!',
            },
        },
        practice_0_2: {
            control: {
                text: `Coach, it’s almost evening. I guess our practice is over today.`,
                cta: 'See you guys tomorrow',
            },
        },
        practice_1_0: {
            control: {
                text: `Coach! Please start the fielding practice!`,
                cta: `Let's start!`,
            },
        },
        practice_1_1: {
            control: {
                text: `Half way there!! Please come see us, Coach!`,
                cta: 'I’m coming!',
            },
        },
        practice_1_2: {
            control: {
                text: `Final one! Coach, please come see us for the last shot!`,
                cta: 'Almost there!',
            },
        },
        practice_2_0: {
            control: {
                text: `Coach, I will practice pitching at the bullpen today. Please come see me!`,
                cta: `I'm coming!`,
            },
        },
        practice_2_1: {
            control: {
                text: `[panting].. I’ll pitch more! Coach, please come see me!!`,
                cta: 'Are you okay...?',
            },
        },
        practice_2_2: {
            control: {
                text: `...seems like I got my shoulder injured… it hurts, Coach...`,
                cta: 'To the infirmary now!',
            },
        },
        practice_3_0: {
            control: {
                text: `Let’s do a build-up today! First is running! Coach, please come see us!`,
                cta: `I'm coming now!`,
            },
        },
        practice_3_1: {
            control: {
                text: `Next is Weight Training! Coach is coming to see us soon!`,
                cta: 'About to be there!',
            },
        },
        practice_3_2: {
            control: {
                text: `Last is the bunny-hop squat... Hang in there, everyone! Coach is coming!`,
                cta: `Don't give up!`,
            },
        },
        practice_4_0: {
            control: {
                text: `Today let’s practice swing! Come see us, Coach!`,
                cta: `Let’s start!`,
            },
        },
        practice_4_1: {
            control: {
                text: `Hands and arms are going a bit numb... but let’s keep going!`,
                cta: 'Take it easy, guys...',
            },
        },
        practice_4_2: {
            control: {
                text: `OMG! The bat slipped away from my hands and injured someone! Please call ambulance, Coach!`,
                cta: `Call ambulance!`,
            },
        },
        lives_full: {
            control: {
                text: `Lives are fully recharged now! Let’s practice again!`,
                cta: `Play Now`,
            },
        },
        // team morale
        pinch_0_0: {
            control: {
                text: `I’ve overdone batting practice…`,
                cta: `Play Now`,
            },
        },
        pinch_0_1: {
            control: {
                text: `Ouch! I got hit by my own batted ball…`,
                cta: `Play Now`,
            },
        },
        pinch_1_0: {
            control: {
                text: `I’m having trouble turning a double play.`,
                cta: `Play Now`,
            },
        },
        pinch_1_1: {
            control: {
                text: `Yips… I can’t throw a ball in the way I want…`,
                cta: `Play Now`,
            },
        },
        pinch_2_0: {
            control: {
                text: `I’ve been feeling stiffness in my shoulder…`,
                cta: `Play Now`,
            },
        },
        pinch_2_1: {
            control: {
                text: `Ouch… My shoulder hurts so much!`,
                cta: `Play Now`,
            },
        },
        pinch_3_0: {
            control: {
                text: `I can’t lift dumbbells anymore…`,
                cta: `Play Now`,
            },
        },
        pinch_3_1: {
            control: {
                text: `I’m exhausted, and I can’t move any more…`,
                cta: `Play Now`,
            },
        },
        pinch_4_0: {
            control: {
                text: `I’m so tired that I can’t concentrate on practice!`,
                cta: `Play Now`,
            },
        },
        pinch_4_1: {
            control: {
                text: `I’m exhausted, and I’m gonna collapse…`,
                cta: `Play Now`,
            },
        },
    },
    ja: {
        // --------- game day V2 (2 games a day)
        d1d5g1_1: {
            control: {
                text: '監督、試合がもうすぐ始まります！来て下さいね！',
                cta: '試合開始！',
            },
        },
        d1d5g1_2: {
            control: {
                text: '監督、負けそうです！ぜひ指示をお願いします！',
                cta: 'ベンチへ急げ！',
            },
        },
        d1d5g1_3: {
            control: {
                text: '監督…試合に負けてしまいました…僕たちはどうすれば？😢',
                cta: '集合をかける！',
            },
        },
        d1d5g2_1: {
            control: {
                text: '試合開始です、監督！この試合、必ず決めてやりましょう！',
                cta: '豪快に行け！',
            },
        },
        d1d5g2_2: {
            control: {
                text: 'これは難局です、監督。勝つために指示をお願いします！',
                cta: '急いで試合へ！',
            },
        },
        d1d5g2_3: {
            control: {
                text: '三振アウト…監督なしでは、僕らの勝利を確実にできません…💔',
                cta: '次のプランを練る',
            },
        },
        d2d6g1_1: {
            control: {
                text: '今度こそホームランを打ってやりますよ！始めましょう、監督！',
                cta: 'さぁ取り掛かろう！',
            },
        },
        d2d6g1_2: {
            control: {
                text: 'どうもテンポが合わない…でも試合終了までは、まだ終わりじゃないぞ。',
                cta: '集合をかける！',
            },
        },
        d2d6g1_3: {
            control: {
                text: '今回は白球に見放されました…次回こそは気てくれると、信じていいですか？監督…🥺',
                cta: '取り戻さねば',
            },
        },
        d2d6g2_1: {
            control: {
                text: '今回こそ勝利にむけてウォームアップを始めましょう、監督。みんな、いくぞ！',
                cta: 'みんな、頑張れ！',
            },
        },
        d2d6g2_2: {
            control: {
                text: '試合の流れが相手よりです、監督…この流れを変えて下さい！',
                cta: '舵をとれ！',
            },
        },
        d2d6g2_3: {
            control: {
                text: '終わりました、監督…僕たちだけでは勝ちを引き寄せられませんでした💔',
                cta: '心身ともに立て直そう',
            },
        },
        d3d7g1_1: {
            control: {
                text: '今度こそ僕らが輝く時！準備OKです、監督！始めましょう！',
                cta: '積極的に行け！',
            },
        },
        d3d7g1_2: {
            control: {
                text: '修羅場になってきました…この試合厳しいかも知れません。監督が必要です！',
                cta: '急いで戻れ！',
            },
        },
        d3d7g1_3: {
            control: {
                text: '試合終了のサイレンが…また負けた…僕らは一体どうすればいいんでしょうか😢',
                cta: '自信を取り戻そう',
            },
        },
        d3d7g2_1: {
            control: {
                text: 'いよいよ試合ですね。監督の戦略を是非お願いします！',
                cta: '戦略はこうだ！',
            },
        },
        d3d7g2_2: {
            control: {
                text: '勝ちが遠のきつつあります…監督、ご指示を！',
                cta: '今すぐ戻れ！',
            },
        },
        d3d7g2_3: {
            control: {
                text: 'また負け…😢この試合こそ一緒にいてほしかったのに…💔',
                cta: '期待に応えねば',
            },
        },
        d4d8g1_1: {
            control: {
                text: '今回こそは僕らを勝利へと導いてくれるだろう、行くぞみんな！',
                cta: '試合を始めよう！',
            },
        },
        d4d8g1_2: {
            control: {
                text: '敗北の予感です…監督、ここで流れを変えるようなプレイを！',
                cta: 'この瞬間を掴め！',
            },
        },
        d4d8g1_3: {
            control: {
                text: '負けるたび、僕らの自信も消えていく…僕らの旅はここで終わりなんでしょうか？監督…🥺',
                cta: '心の火を再燃させよう',
            },
        },
        d4d8g2_1: {
            control: {
                text: '監督、試合がもうすぐ始まります！来て下さいね！',
                cta: '試合開始！',
            },
        },
        d4d8g2_2: {
            control: {
                text: '監督、負けそうです！ぜひ指示をお願いします！',
                cta: 'ベンチへ急げ！',
            },
        },
        d4d8g2_3: {
            control: {
                text: '監督…試合に負けてしまいました…僕たちはどうすれば？😢',
                cta: '集合をかける！',
            },
        },
        // ---------
        first_session_0: {
            control: {
                text: '監督、試合がもうすぐ始まります！来て下さいね！',
                cta: '試合開始！',
            },
        },
        first_session_1: {
            control: {
                text: '監督、負けそうです！ぜひ指示をお願いします！',
                cta: 'ベンチへ急げ！',
            },
        },
        first_session_2: {
            control: {
                text: '監督…試合に負けてしまいました…僕たちはどうすれば？😢',
                cta: '集合をかける！',
            },
        },
        game_day_0_0: {
            control: {
                text: '監督、試合がもうすぐ始まります！来て下さいね！',
                cta: '試合開始！',
            },
        },
        game_day_0_1: {
            control: {
                text: '監督、負けそうです！ぜひ指示をお願いします！',
                cta: 'ベンチへ急げ！',
            },
        },
        game_day_0_2: {
            control: {
                text: '監督…試合に負けてしまいました…僕たちはどうすれば？😢',
                cta: '集合をかける！',
            },
        },
        game_day_1_0: {
            control: {
                text: '試合開始です、監督！この試合、必ず決めてやりましょう！',
                cta: '豪快に行け！',
            },
        },
        game_day_1_1: {
            control: {
                text: `これは難局です、監督。勝つために指示をお願いします！`,
                cta: '急いで試合へ！',
            },
        },
        game_day_1_2: {
            control: {
                text: `三振アウト…監督なしでは、僕らの勝利を確実にできません…💔`,
                cta: '次のプランを練る',
            },
        },
        game_day_2_0: {
            control: {
                text: '今日はホームランを打ってやりますよ！始めましょう、監督！',
                cta: 'さぁ取り掛かろう！',
            },
        },
        game_day_2_1: {
            control: {
                text: `どうもテンポが合わない…でも試合終了までは、まだ終わりじゃないぞ。`,
                cta: '集合をかける！',
            },
        },
        game_day_2_2: {
            control: {
                text: `今日は白球に見放されました…次回こそは気てくれると、信じていいですか？監督…🥺`,
                cta: '取り戻さねば',
            },
        },
        game_day_3_0: {
            control: {
                text: '今日こそ勝利にむけてウォームアップを始めましょう、監督。みんな、いくぞ！',
                cta: 'Yeah! Go team!',
            },
        },
        game_day_3_1: {
            control: {
                text: `試合の流れが相手よりです、監督…この流れを変えて下さい！`,
                cta: 'みんな、頑張れ！',
            },
        },
        game_day_3_2: {
            control: {
                text: `終わりました、監督…僕たちだけでは勝ちを引き寄せられませんでした💔`,
                cta: '心身ともに立て直そう',
            },
        },
        game_day_4_0: {
            control: {
                text: `今日こそ僕らが輝く時！準備OKです、監督！始めましょう！`,
                cta: '積極的に行け！',
            },
        },
        game_day_4_1: {
            control: {
                text: `修羅場になってきました…この試合厳しいかも知れません。監督が必要です！`,
                cta: '急いで戻れ！',
            },
        },
        game_day_4_2: {
            control: {
                text: `試合終了のサイレンが…また負けた…僕らは一体どうすればいいんでしょうか😢`,
                cta: '自信を取り戻そう',
            },
        },
        game_day_5_0: {
            control: {
                text: `いよいよ試合ですね。監督の戦略を是非お願いします！`,
                cta: '戦略はこうだ！',
            },
        },
        game_day_5_1: {
            control: {
                text: `勝ちが遠のきつつあります…監督、ご指示を！`,
                cta: '今すぐ戻れ！',
            },
        },
        game_day_5_2: {
            control: {
                text: `また負け…😢今日こそ一緒にいてほしかったのに…💔`,
                cta: '期待に応えねば',
            },
        },
        game_day_6_0: {
            control: {
                text: `今日こそは僕らを勝利へと導いてくれるだろう、行くぞみんな！`,
                cta: 'L試合を始めよう！',
            },
        },
        game_day_6_1: {
            control: {
                text: `敗北の予感です…監督、ここで流れを変えるようなプレイを！`,
                cta: 'この瞬間を掴め！',
            },
        },
        game_day_6_2: {
            control: {
                text: `負けるたび、僕らの自信も消えていく…僕らの旅はここで終わりなんでしょうか？監督…🥺`,
                cta: '心の火を再燃させよう',
            },
        },
        game_day_7_0: {
            control: {
                text: `試合開始です、監督！この試合、必ず決めてやりましょう！`,
                cta: '豪快に行け！',
            },
        },
        game_day_7_1: {
            control: {
                text: `これは難局です、監督。勝つために指示をお願いします！`,
                cta: '急いで試合へ！',
            },
        },
        game_day_7_2: {
            control: {
                text: `三振アウト…監督なしでは、僕らの勝利を確実にできません…💔`,
                cta: '次のプランを練る',
            },
        },
        practice_0_0: {
            control: {
                text: `監督、朝練が始まります！指導お願いします！`,
                cta: '始めよう！',
            },
        },
        practice_0_1: {
            control: {
                text: `監督、もう午後ですが、今日の練習メニューを決めてください！`,
                cta: 'メニューを決める！',
            },
        },
        practice_0_2: {
            control: {
                text: `監督…日が暮れました…今日の練習は終わっちゃいました。`,
                cta: '明日頑張ろう！',
            },
        },
        practice_1_0: {
            control: {
                text: `監督、1000本ノックお願いします！来て下さい！`,
                cta: `ノック開始だ！`,
            },
        },
        practice_1_1: {
            control: {
                text: `残り500本！！まだまだ！見て下さいね、監督！`,
                cta: 'すぐ行くぞ！',
            },
        },
        practice_1_2: {
            control: {
                text: `いよいよラスト！監督、最後の１本お願いします！`,
                cta: 'もうすぐ着く！',
            },
        },
        practice_2_0: {
            control: {
                text: `監督、今日はとことん投げ込みます！見てて下さいね！`,
                cta: `見に行く！`,
            },
        },
        practice_2_1: {
            control: {
                text: `まだまだ…もっと投げます！監督、見に来て下さい！`,
                cta: 'おい、大丈夫か…？',
            },
        },
        practice_2_2: {
            control: {
                text: `くっ…肩を痛めてしまったみたいです…、監督…。`,
                cta: '保健室へ急げ！',
            },
        },
        practice_3_0: {
            control: {
                text: `今日は体力作りだ！走り込み開始！見てて下さい、監督！`,
                cta: `よし、今行くぞ！`,
            },
        },
        practice_3_1: {
            control: {
                text: `次はウェイトトレーニング！監督が見に来てくれるぞ！`,
                cta: 'もうすぐ行くぞ！',
            },
        },
        practice_3_2: {
            control: {
                text: `最後はうさぎ跳び…監督が見てくれるぞ、みんな踏ん張れ！`,
                cta: `最後まで頑張れ！`,
            },
        },
        practice_4_0: {
            control: {
                text: `今日は全員で素振りだ！監督、見てて下さい！`,
                cta: `始めよう！`,
            },
        },
        practice_4_1: {
            control: {
                text: `腕がしびれてきたけど…まだまだ！監督に見てもらうんだ！`,
                cta: 'む、無理するなよ…',
            },
        },
        practice_4_2: {
            control: {
                text: `あぁ！バットがすっぽ抜けて、ケガ人が出た！！監督、救急車を！`,
                cta: `救急車を呼べ！`,
            },
        },
        lives_full: {
            control: {
                text: `ライフが全回復したよ！さぁ、練習を再開しよう！`,
                cta: `今すぐプレイ`,
            },
        },
        // team morale
        pinch_0_0: {
            control: {
                text: `バッティング練習のやりすぎで体が張る…`,
                cta: `今すぐプレイ`,
            },
        },
        pinch_0_1: {
            control: {
                text: `アイタタ…自打球が当たってしまった…`,
                cta: `今すぐプレイ`,
            },
        },
        pinch_1_0: {
            control: {
                text: `ダブルプレイがなかなか成功しません。`,
                cta: `今すぐプレイ`,
            },
        },
        pinch_1_1: {
            control: {
                text: `イップスで、ボールが変な方向へ行ってしまう…`,
                cta: `今すぐプレイ`,
            },
        },
        pinch_2_0: {
            control: {
                text: `さっきから肩に張りを感じるなぁ…`,
                cta: `今すぐプレイ`,
            },
        },
        pinch_2_1: {
            control: {
                text: `肩が痛くてボールが投げられない…`,
                cta: `今すぐプレイ`,
            },
        },
        pinch_3_0: {
            control: {
                text: `筋トレやりすぎて、もうダンベルが上がらない…`,
                cta: `今すぐプレイ`,
            },
        },
        pinch_3_1: {
            control: {
                text: `しんどくて、もう体が動かないよ…`,
                cta: `今すぐプレイ`,
            },
        },
        pinch_4_0: {
            control: {
                text: `疲れがたまって練習に集中できません…`,
                cta: `今すぐプレイ`,
            },
        },
        pinch_4_1: {
            control: {
                text: `疲れがたまって、もう倒れそう…`,
                cta: `今すぐプレイ`,
            },
        },
    },
};

// Random among text
export function getCreativeText(
    locale: LanguageId,
    type: ReplicantCreativeType,
    random: () => number,
    substitutions?: { [key: string]: any },
    titleSubstitutions?: { [key: string]: any },
): CreativeText {
    // Fallback locale if we don't have the current locale or its not translated
    const lang = config[locale] ? locale : gameConfig.settings.language;
    const messagesObj = config[lang][type];
    const messagesArr = Object.keys(messagesObj);
    const messageIdx = Math.floor(random() * messagesArr.length);
    const messageId = messagesArr[messageIdx];
    const message = messagesObj[messageId];

    let text = message.text;
    if (substitutions) {
        for (const token in substitutions) {
            text = text.replace(`{${token}}`, substitutions[token]);
        }
    }

    let title = message.title;
    if (title && titleSubstitutions) {
        for (const token in titleSubstitutions) {
            title = title.replace(`{${token}}`, titleSubstitutions[token]);
        }
    }

    return {
        id: messageId,
        cta: message.cta,
        text,
        title,
    };
}

export function getCreativeTextSpecific(
    locale: LanguageId,
    type: ReplicantCreativeType,
    subType: string,
    substitutions?: { [key: string]: any },
    titleSubstitutions?: { [key: string]: any },
): CreativeText {
    // Fallback locale if we don't have the current locale or its not translated
    const lang = config[locale] ? locale : gameConfig.settings.language;
    const messagesObj = config[lang][type];
    const message = messagesObj[subType];

    let text = message.text;
    if (substitutions) {
        for (const token in substitutions) {
            text = text.replace(`{${token}}`, substitutions[token]);
        }
    }

    let title = message.title;
    if (title && titleSubstitutions) {
        for (const token in titleSubstitutions) {
            title = title.replace(`{${token}}`, titleSubstitutions[token]);
        }
    }

    return {
        id: subType,
        cta: message.cta,
        text,
        title,
    };
}
