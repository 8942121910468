import app from '../../../../getApp';
import { BasicBlockProps, blockColorMap, BlockId, ColorId, colorToBlockId } from '../../defs/block';
import { CollideEvent, CollideId } from '../../defs/collide';
import { BasicBlockEntity, despawnBlockEntity, spawnBlockEntity } from '../../entities/BlockEntity';
import { GameScene } from '../../GameScene';
import { CollisionTracker } from '../../util/CollisionTracker';
import { mapGetPan } from '../../util/mapTools';
import { matchFindGroup } from '../../util/matchTools';
import { BreakEffect } from '../effects/BreakEffect';
import { InvalidEffect } from '../effects/InvalidEffect';
import { CollideHandler, ICollision } from './ICollision';

/*
    basic block collision resolver
*/
export class BasicCollision implements ICollision {
    // fields
    //-------------------------------------------------------------------------
    // input
    private readonly _scene: GameScene;
    private readonly _entity: BasicBlockEntity;
    // map
    private readonly _resolvers: { [key in CollideId]?: CollideHandler } = {
        tap: this._resolveTap,
        attack: this._resolveAttack,
    };

    // init
    //-------------------------------------------------------------------------
    constructor(scene: GameScene, entity: BasicBlockEntity) {
        this._scene = scene;
        this._entity = entity;
    }

    // impl
    //-------------------------------------------------------------------------
    public async resolve(event: CollideEvent): Promise<boolean> {
        return this._resolvers[event.id]?.call(this, event);
    }

    // private: resolvers
    //-------------------------------------------------------------------------
    private async _resolveTap(event: CollideEvent) {
        // find match group
        const group = matchFindGroup(this._scene, this._entity);

        //TODO: put following into an effect
        // if found enough
        if (group) {
            const collisionTracker = new CollisionTracker();

            // attack all found
            for (const match of group.matches) {
                match.base.c.block.collide('attack');
            }

            // spawn power block?
            const spawnBlockId = this._getSpawnBlockFromSymboledEntity();
            //TODO: should be effect
            if (spawnBlockId) {
                // play sound
                void app().sound.play(`puzzle-${this._entity.c.blockBasic.symbolId}-form.mp3`, {
                    volume: 0.9,
                    pan: mapGetPan(this._entity.c.position.mapPosition),
                });

                // spawn new block entity
                spawnBlockEntity(this._scene, { id: spawnBlockId }, this._entity.c.position.mapPosition);
            }

            // block collide neighbor positions
            for (const neighbor of group.neighbors) {
                collisionTracker.collideAt(this._scene.sessionEntity.c.map, neighbor.c.position.mapPosition, 'block', {
                    type: 'block',
                    entity: this._entity,
                });
            }

            // notify group break event
            this._scene.events.publish({ id: 'group', cause: 'basic', count: group.matches.length });

            return true;
            // else invalid effect
        }
        await new InvalidEffect(this._entity).execute();
        return false;
    }

    private async _resolveAttack(event: CollideEvent) {
        // attack basic block
        await this._attack(event);
        return true;
    }

    // private: actions
    //-------------------------------------------------------------------------
    private async _attack(event: CollideEvent) {
        const entity = this._entity;
        const position = entity.c.position.mapPosition;

        // play break sound
        void app().sound.play('puzzle-basic-destroy.ogg', { dupes: 2, pan: mapGetPan(position) });

        // despawn block entity
        despawnBlockEntity(this._scene, entity);

        // execute break effect
        return new BreakEffect(this._scene, {
            position,
            size: entity.c.block.props,
            color: blockColorMap[this._getColor()].base,
        }).execute();
    }

    // private: support
    //-------------------------------------------------------------------------
    private _getSpawnBlockFromSymboledEntity(): BlockId | undefined {
        switch (this._entity.c.blockBasic.symbolId) {
            case 'bomb':
                return 'bomb';
            case 'cube':
                return this._getSpawnCubeBlockFromSymboledEntity();
            case 'rocket':
                return this._getSpawnRocketBlockFromSymboledEntity();
            default:
                return undefined;
        }
    }

    private _getSpawnCubeBlockFromSymboledEntity(): BlockId | undefined {
        // match color to cube
        return colorToBlockId[this._getColor()].cube;
    }

    private _getSpawnRocketBlockFromSymboledEntity(): BlockId | undefined {
        return Math.random() < 0.5 ? 'rocketHorizontal' : 'rocketVertical';
    }

    private _getColor(): ColorId {
        return (this._entity.c.block.props as BasicBlockProps).color;
    }
}
