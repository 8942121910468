import { analytics } from '@play-co/gcinstant';

export function trackPerformance(opts: {
    // Is this first session event
    isFirst: boolean;

    // Whole session avr fps
    fps: number;
    // Whole session avr variance
    choppiness: number;
    // Whole session avr sample variance
    choppinessSample: number;

    // Average FPS between current performance events and previous performance events
    realtimeFPS: number;
    // Average DT variance between current performance events and previous performance events
    realtimeChoppiness: number;
    // Average DT sample variance between current performance events and previous performance events
    realtimeChoppinessSample: number;

    jsHeapSizeLimit: number | null;
    jsHeapSizeTotal: number | null;
    jsHeapSizeUsed: number | null;

    // Listed in estimated order of occurrence
    timeToAppConstructor?: number;
    timeToInitializeAsync?: number;
    timeToReplicantLogin?: number;
    timeToEarlyLoadTournament?: number;
    timeToBlockingAssets?: number;
    timeToStartGameAsync?: number;
    timeToEntryPreChecks?: number;
    timeToEntryFinalEvent?: number;
    timeToGameVisible?: number;
    timeToPaymentsReady?: number;
    timeToFriendsStates?: number;
    timeToInferTournamentPayloadData?: number;
}) {
    analytics.pushEvent('Performance', opts);
}
