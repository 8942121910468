import { Plugin, PluginOptions } from '@play-co/astro';

import { firstTapPromise } from '../../lib/util/events';
import type { App } from '../App';

const CONSOLE_KEY = 'v_console';

// types
//-----------------------------------------------------------------------------
// public
export type CoreServiceOptions = PluginOptions;

/*
    core features service
*/
export class CoreService extends Plugin {
    // fields
    //-------------------------------------------------------------------------
    // init
    //-------------------------------------------------------------------------
    constructor(app: App, options: Partial<CoreServiceOptions>) {
        super(app, options);
    }

    public async init() {}

    public start(): void {
        // register events
        void firstTapPromise.then(this._onFirstTap.bind(this));
    }

    // private: handlers
    //-------------------------------------------------------------------------
    private async _onFirstTap() {
        //TODO: make it work on iphone as well and test on a lot of devices. Currently breaks tutorial
        // request fullscreen and screen lock. ignore on fail.
        //     try {
        //         await app.stage.view.requestFullscreen();
        //         await window.screen.orientation.lock('portrait');
        //     } catch {}
    }
}
