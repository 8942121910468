import app from '../../../../getApp';
import { CollideEvent, CollideId } from '../../defs/collide';
import type { LockBlockEntity } from '../../entities/BlockEntity';
import { despawnBlockEntity } from '../../entities/BlockEntity';
import { GameScene } from '../../GameScene';
import { mapGetPan } from '../../util/mapTools';
import { BreakEffect } from '../effects/BreakEffect';
import { InvalidEffect } from '../effects/InvalidEffect';
import { CollideHandler, ICollision } from './ICollision';

// fields
//-----------------------------------------------------------------------------
const sounds = ['puzzle-lock-destroy.mp3', 'puzzle-lock-poke.mp3'];

/*
    lock block collision resolver
*/
export class LockCollision implements ICollision {
    // fields
    //-------------------------------------------------------------------------
    // input
    private readonly _scene: GameScene;
    private readonly _entity: LockBlockEntity;
    // map
    private readonly _resolvers: { [key in CollideId]?: CollideHandler } = {
        tap: this._resolveTap,
        block: this._resolveBlock,
        attack: this._resolveAttack,
    };

    // init
    //-------------------------------------------------------------------------
    constructor(scene: GameScene, entity: LockBlockEntity) {
        this._scene = scene;
        this._entity = entity;
    }

    // impl
    //-------------------------------------------------------------------------
    public async resolve(event: CollideEvent): Promise<boolean> {
        return this._resolvers[event.id]?.call(this, event);
    }

    // private: resolvers
    //-------------------------------------------------------------------------
    private async _resolveTap(event: CollideEvent) {
        // invalid effect
        await new InvalidEffect(this._entity).execute();
        return false;
    }

    private async _resolveBlock(event: CollideEvent) {
        const collider = event.collider;

        // if basic block collider
        if (collider?.type === 'block' && collider.entity.c.block.props.type === 'basic') {
            // attack lock
            void this._attack();
            return true;
        }
        return false;
    }

    private async _resolveAttack(event: CollideEvent) {
        // attack lock
        void this._attack();
        return true;
    }

    // private: actions
    //-------------------------------------------------------------------------
    private async _attack() {
        const component = this._entity.c.blockLock;
        const position = this._entity.c.position.mapPosition;

        // play sound based on component count
        void app().sound.play(sounds[component.count], { pan: mapGetPan(position) });

        // if count is 0
        if (component.count <= 0) {
            // despawn entity
            despawnBlockEntity(this._scene, this._entity);

            // execute break effect
            await new BreakEffect(this._scene, {
                position,
                size: this._entity.c.block.props,
                color: 0xffcc44,
            }).execute();
            // else decrement count
        } else {
            --component.count;
        }
    }
}
