import { Container } from 'pixi.js';

import { WobbleAnimation } from '../../animations/WobbleAnimation';
import { BlockEntity } from '../../entities/BlockEntity';
import { mapGetPan } from '../../util/mapTools';
import { IEffect } from './IEffect';
import app from '../../../../getApp';

/*
    invalid effect. for tapped blocks that cant produce any other effect.
*/
export class InvalidEffect implements IEffect {
    // fields
    //-------------------------------------------------------------------------
    // input
    private readonly _entity: BlockEntity;
    private readonly _view: Container;

    // init
    //-------------------------------------------------------------------------
    constructor(entity: BlockEntity) {
        this._entity = entity;
        this._view = entity.c.view2d.view.getChildAt(0) as Container;
    }

    // impl
    //-------------------------------------------------------------------------
    public async execute() {
        // play sound
        void app().sound.play('puzzle-invalid.mp3', { pan: mapGetPan(this._entity.c.position.mapPosition) });

        // wobble the entity view. dont block on animation
        void new WobbleAnimation({
            target: this._view,
        }).start();
    }
}
