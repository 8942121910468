import { Container, Sprite } from 'pixi.js';

import { uiAlignCenterY, uiAlignLeft, uiAlignRight } from '../../../lib/pixi/uiTools';
import { textLocaleFormat } from '../../../lib/util/textTools';
import { ImageButton } from '../../lib/ui/buttons/ImageButton';
import { BasicText } from '../../lib/ui/text/BasicText';

const manifest = {
    button: 'button.choice.png',
    arrowSmall: 'icon.arrow.small.png',
};

export default class ChoiceView extends Container {
    constructor(options: { choices: string[]; onChoice: (choiceIndex: number) => Promise<void> }) {
        super();
        const { choices, onChoice } = options;
        let y = choices.length > 1 ? 0 : -20;

        for (let i = 0; i < choices.length; i++) {
            const width = 650;
            const parsed = textLocaleFormat(choices[i]);
            const frame = new ImageButton({
                image: manifest.button,
                slice: {
                    width: 650,
                    height: 144,
                    left: 60,
                    right: 60,
                    top: 40,
                    bottom: 40,
                },
            });

            const text = new BasicText({
                text: parsed,
                style: {
                    fill: '#fff',
                    fontSize: 30,
                    fontWeight: 'bold',
                    lineJoin: 'round',
                    wordWrap: true,
                    wordWrapWidth: width - 114,
                    align: 'left',
                },
            });

            frame.button.addChild(text);
            uiAlignLeft(frame, text, 50);
            uiAlignCenterY(frame, text, -5);
            frame.onPress = async () => onChoice(i);

            const arrow = Sprite.from(manifest.arrowSmall);

            frame.button.addChild(arrow);
            uiAlignCenterY(frame, arrow, -3);
            uiAlignRight(frame, arrow, -30);

            this.addChild(frame);
            frame.y = y;
            y += frame.height + 12;
        }
    }

    static assets() {
        return Object.values(manifest);
    }
}
