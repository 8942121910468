import { Plugin, PluginOptions } from '@play-co/astro';
import { Container, DisplayObject } from 'pixi.js';

import { BasicHandler } from '../../lib/defs/types';
import Observer from '../../lib/pattern/Observer';
import app from '../getApp';
import type { App } from '../App';

// types
//-----------------------------------------------------------------------------
export type InputServiceOptions = PluginOptions;

/*
    plugin: augments pixi interaction manager with additional features
*/
export class InputService extends Plugin {
    // fields
    //-------------------------------------------------------------------------
    // events
    public onTap = new Observer();
    // scene
    private _tapZone: Container;

    // properties
    //-------------------------------------------------------------------------
    // control input
    public set enabled(enabled: boolean) {
        app().stage.stage.interactiveChildren = enabled;
    }

    // init
    //-------------------------------------------------------------------------
    constructor(app: App, options: Partial<InputServiceOptions>) {
        super(app, options);
    }

    public async init() {
        // init
        this._initTapZone();
    }

    // api
    //-------------------------------------------------------------------------
    // waits for any screen input
    public async waitAny() {
        // restrict inputs
        const unrestrict = this.restrict([]);

        // wait for tapzone input
        await app().input.onTap.waitFor();

        // unrestrict
        unrestrict();
    }

    // restrict input to just the given whitelist. returns function to undo this operation.
    public restrict(whitelist: DisplayObject[]): BasicHandler {
        const blocked: DisplayObject[] = [];

        // recurse starting at stage
        this._restrictR(app().stage.stage, blocked, whitelist);

        // return function that restores block list
        return () => {
            for (const object of blocked) object.interactive = true;
        };
    }

    private _restrictR(node: DisplayObject, blocked: DisplayObject[], whitelist: DisplayObject[]) {
        // skip if tapzone or in whitelist
        if (node === this._tapZone || whitelist.includes(node)) return;

        // if has interactive, disable and add to blocked list
        if (node.interactive) {
            node.interactive = false;
            blocked.push(node);
        }

        // recurse
        if (node instanceof Container) {
            for (const child of node.children) {
                this._restrictR(child, blocked, whitelist);
            }
        }
    }

    // private: init
    //-------------------------------------------------------------------------
    private _initTapZone() {
        // insert under all else
        this._tapZone = app()
            .stage.stage.addChildAt(new Container(), 0)
            .props({
                interactive: true,
                hitArea: { contains: () => true },
            })
            .on('pointertap', () => this.onTap.publish());
    }
}
