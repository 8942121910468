import { IFlow } from '../../lib/pattern/IFlow';
import NakedPromise from '../../lib/pattern/NakedPromise';
import { guessInstalledNativeAppPlatform, isNativePromoEnabled } from '../../lib/util/native';
import app from '../getApp';
import { NativePromoFlow } from './NativePromoFlow';

export class PreLaunchFlow implements IFlow {
    private readonly _complete = new NakedPromise<boolean>();

    // init
    //-------------------------------------------------------------------------
    constructor() {}

    // impl
    //-------------------------------------------------------------------------
    public async execute() {
        if (isNativePromoEnabled() && !app().platform.native && guessInstalledNativeAppPlatform() !== null) {
            await new NativePromoFlow({
                payload: {
                    triggerSource: 'crossplay_popup',
                    feature: 'launch_redirect',
                    subFeature: 'crossplay_popup_cta',
                },
            }).execute();
        }

        void this._actionComplete(true);
        return this._complete;
    }

    private async _actionComplete(success: boolean) {
        this._complete.resolve(success);
    }
}
