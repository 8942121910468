import { Container, Sprite } from 'pixi.js';

import { pixiSetInterval } from '../../../lib/pixi/pixiTools';
import { uiAlignCenter, uiSizeToWidth } from '../../../lib/pixi/uiTools';
import { BasicText } from '../../lib/ui/text/BasicText';
import { getTeamMoraleScore } from '../../../replicant/components/teamMorale';
import getApp from '../../getApp';
import { gsap } from 'gsap';
import { sleep } from '../../../replicant/util/jsTools';
import app from '../../getApp';

type ColorId = keyof typeof progressMap;

const ANIM_DURATION = 0.9;

const manifest = {
    bg: 'frame.header.top.png',
    panel: 'panel.team.morale.png',
    progressGreen: 'icon.morale.green.png',
    progressOrange: 'icon.morale.orange.png',
    progressRed: 'icon.morale.red.png',
    sparkle: 'fx.basic.sparkle.png',
};

const progressMap = {
    green: {
        hex: 0x85ff4b,
        icon: manifest.progressGreen,
    },
    orange: {
        hex: 0xff9c28,
        icon: manifest.progressOrange,
    },
    red: {
        hex: 0xf8441d,
        icon: manifest.progressRed,
    },
};

export default class TeamMoraleView extends Container {
    private _progressFill: number;
    private _score: number;
    private _progressText: BasicText;
    private _percent: BasicText;
    private _stopUpdate = false;
    private _filledBarList: Sprite[] = [];

    public static assets(): string[] {
        return [...Object.values(manifest)];
    }

    constructor(opts: { scoreOverride?: number; simple?: boolean }) {
        super();
        const bg = Sprite.from(manifest.bg);

        // simple spawn, for example in scripted sequences without main container visible
        if (opts.simple) {
            bg.alpha = 0;
        }
        const progress = Sprite.from(manifest.panel);
        this.addChild(bg);
        this.addChild(progress);
        uiAlignCenter(this, progress, 0, 18);

        const moraleTitle = new BasicText({
            text: '[teamMoraleTitle]',
            style: {
                fill: 0xffffff,
                align: 'center',
                fontWeight: 'bold',
                lineJoin: 'round',
                fontSize: 20,
                fontStyle: 'italic',
            },
        });
        uiSizeToWidth(moraleTitle, 150);
        uiAlignCenter(this, moraleTitle, -53, -18);

        this._score =
            opts.scoreOverride !== undefined
                ? opts.scoreOverride
                : getTeamMoraleScore(getApp().server.state, getApp().server.now());

        this._progressFill = progressMap[this._getColorId()].hex;
        this._spawnProgress();

        if (opts.scoreOverride !== undefined) {
            void this.forceScore({ animated: false, score: this._score });
        } else {
            // normal, let the component handle its own score
            void void this.updateScore({ animated: false, score: this._score });
        }

        // start the ticker
        this._update();
        this.addChild(moraleTitle);
    }

    public async forceScore(opts: { animated: boolean; score: number }) {
        await this.updateScore({ ...opts, forced: true });
    }

    public async updateScore(opts: { animated: boolean; score: number; forced?: boolean }) {
        const { animated, score, forced } = opts;
        const previousScore = this._score;
        this._score = score;

        const newColor = progressMap[this._getColorId()];
        this._progressFill = newColor.hex;
        this._percent.destroy();
        this._progressText.destroy();
        this._spawnProgress();
        this._stopUpdate = true;
        await Promise.all([
            this._updateProgressText({ animated, previousScore }),
            this._updateProgessBar({ animated, barIcon: newColor.icon, previousScore }),
        ]);

        // do not resume if forced, wait until manuall resume
        if (!forced) {
            this._stopUpdate = false;
        }
    }

    public pauseMoraleUpdate() {
        this._stopUpdate = true;
    }

    public resumeMoraleUpdate() {
        this._stopUpdate = false;
    }

    private async _updateProgressText(opts: { animated: boolean; previousScore: number }) {
        const { animated, previousScore } = opts;
        const tweenNumber = { value: previousScore };

        if (this._score === previousScore || !animated) {
            this._progressText.text = `${this._score}`;
            this._progressText.pivot.set(this._progressText.width * 0.5, this._progressText.height * 0.5);
            return;
        }

        // dont play if 1 difference
        if (Math.abs(this._score - previousScore) > 1) void app().sound.play('wave_fade.ogg', { volume: 0.9 });

        return new Promise<void>((resolve) => {
            const textDuration = ANIM_DURATION + 0.1;

            gsap.to(tweenNumber, {
                value: this._score,
                duration: textDuration,
                ease: 'power1.inOut',
                onUpdate: () => {
                    this._progressText.text = `${Math.round(tweenNumber.value)}`;
                    this._progressText.pivot.set(this._progressText.width * 0.5, this._progressText.height * 0.5);
                },
                onComplete: resolve,
            });
        });
    }

    private _update() {
        const tick = async () => {
            if (this._stopUpdate) return;
            const newScore = getTeamMoraleScore(getApp().server.state, getApp().server.now());
            await this.updateScore({ animated: true, score: newScore });
        };

        void tick();
        pixiSetInterval(
            this,
            async () => {
                await tick();
            },
            0.9,
        );
    }

    private async _spawnExplosion(targetBar: Container) {
        const particles = app().particles.create({
            textures: [manifest.sparkle],
            rate: 0.03,
            limit: 60,
            emitterDuration: 0.2,
            duration: 0.6,
            tint: this._progressFill,
            behaviors: [{ type: 'explode', magnitude: [70, 140] }, { type: 'scale', to: 0 }, { type: 'kinematic' }],
        });

        targetBar.addChild(particles.view);
        uiAlignCenter(targetBar, particles.view, 10, 1);
        await particles.start();
        particles.view.removeFromParent();
    }

    private _spawnProgress() {
        this._progressText = new BasicText({
            style: {
                fill: this._progressFill,
                align: 'center',
                fontWeight: 'bold',
                lineJoin: 'round',
                fontSize: this._score === 100 ? 23 : 28,
            },
        });

        this._percent = new BasicText({
            text: '%',
            style: {
                fill: this._progressFill,
                align: 'center',
                fontWeight: 'bold',
                lineJoin: 'round',
                fontSize: 23,
            },
        });
        this._percent.alpha = 0.5;
        this._progressText.pivot.set(this._progressText.width * 0.5, this._progressText.height * 0.5);
        this._progressText.position.set(369, 54);
        this._percent.position.set(358, 64);
        this.addChild(this._percent, this._progressText);
    }

    private async _updateProgessBar(opts: { animated: boolean; barIcon: string; previousScore: number }) {
        const { animated, barIcon, previousScore } = opts;
        const newBars = Math.floor(this._score / 10);
        const previousBars = this._filledBarList.length;
        if (previousBars !== newBars) {
            let x = 90;
            if (previousBars < newBars) {
                this._filledBarList.forEach((bar) => {
                    bar.removeFromParent();
                });
                this._filledBarList = [];

                for (let i = 0; i < newBars; i++) {
                    const bar = Sprite.from(barIcon);
                    this._filledBarList.push(bar);
                    this.addChild(bar);
                    bar.y = 51;
                    bar.x = x;
                    x += bar.width - 3;
                    if (animated && i > previousBars - 1) {
                        void app().sound.play('power_up.ogg', { volume: 0.9, dupes: 10 });
                        void this._spawnExplosion(bar);
                        await sleep(ANIM_DURATION / (newBars - previousBars));
                    }
                }
            } else {
                const reduceSleep = ANIM_DURATION / (previousBars - newBars);
                for (let i = previousBars - 1; i >= newBars; i--) {
                    const bar = this._filledBarList.pop();
                    if (animated) {
                        await sleep(reduceSleep);
                    }
                    bar.removeFromParent();
                }
                // sync color
                for (let i = 0; i < this._filledBarList.length; i++) {
                    this._filledBarList[i].removeFromParent();
                    const newBar = Sprite.from(barIcon);
                    this._filledBarList[i] = newBar;
                    this.addChild(newBar);
                    newBar.y = 51;
                    newBar.x = x;
                    x += newBar.width - 3;
                }
            }
        }
    }

    private _getColorId(): ColorId {
        if (this._score > 55) {
            return 'green';
        } else if (this._score > 25) {
            return 'orange';
        } else {
            return 'red';
        }
    }
}
