import { IFlow } from '../../../lib/pattern/IFlow';
import NakedPromise from '../../../lib/pattern/NakedPromise';
import app from '../../getApp';
import { TeamMoraleIntroPopup } from '../../main/popups/TeamMoraleIntroPopup';

export class TutorialMoraleIntroFlow implements IFlow {
    private readonly _complete = new NakedPromise<boolean>();

    // impl
    //-------------------------------------------------------------------------
    public async execute() {
        await this._actionMoraleIntro();

        return this._complete;
    }

    private async _actionComplete(success: boolean) {
        this._complete.resolve(success);
    }

    private async _actionMoraleIntro() {
        const moralePromise = new NakedPromise();
        // quick team morale intro before task intro
        (await app().nav.open('teamMoraleIntroPopup', {
            onOk: moralePromise.resolve,
            underlay: 0.6,
        })) as TeamMoraleIntroPopup;

        await moralePromise;

        const closePromise = app().nav.close('teamMoraleIntroPopup');
        const savePromise = app().server.invoke.completeTeamMoraleFTUE();
        await Promise.all([closePromise, savePromise]);

        void this._actionComplete(true);
    }
}
