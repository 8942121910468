import { Container } from 'pixi.js';

import { SpineAnimation } from '../../lib/pixi/animation/SpineAnimation';

// constants
//-----------------------------------------------------------------------------
export const speedlinesClipId = 'speedline_anim';

const manifest = {
    spine: 'speedline_BG.json',
};

export class Speedlines extends Container {
    // fields
    //-------------------------------------------------------------------------
    // scene
    private _spine: SpineAnimation;

    // init
    //-------------------------------------------------------------------------
    static assets() {
        return Object.values(manifest);
    }

    public set speed(value: number) {
        this._spine.speed(value);
    }

    constructor() {
        super();

        // spawn
        this._spawn();
    }

    // api
    //-------------------------------------------------------------------------
    public async start() {
        // stop previous
        this._spine.stop();
        // no mix for the action clips
        const mix = 0.1;
        await this._spine.start({ id: speedlinesClipId, loop: true, mix });
    }

    // private: scene
    //-------------------------------------------------------------------------
    private _spawn() {
        const spine = (this._spine = new SpineAnimation({ json: manifest.spine }));
        spine.scale.set(1.21);
        this.pivot.set(this.width / 2, this.height / 2);

        this.addChild(spine);
    }
}
