import { IFlow } from '../../lib/pattern/IFlow';
import NakedPromise from '../../lib/pattern/NakedPromise';
import { isEnoughStarsToStep } from '../../replicant/components/task';
import app from '../getApp';
import { trackTaskStep } from '../lib/analytics/task';
import { TaskScreen } from '../main/tasks/TaskScreen';

export class TaskStepFlow implements IFlow {
    private readonly _complete = new NakedPromise<{ goToPuzzle?: boolean }>();

    // fields
    private readonly _screen: TaskScreen;
    private readonly _stepIndex: number;

    // init
    //-------------------------------------------------------------------------
    constructor(opts: { screen: TaskScreen; stepIndex: number }) {
        this._screen = opts.screen;
        this._stepIndex = opts.stepIndex;
    }

    // impl
    //-------------------------------------------------------------------------
    public async execute() {
        if (!isEnoughStarsToStep(app().server.state.task, this._stepIndex, app().server.state.stars)) {
            const goToPuzzle = await this._notEnoughStars();
            void this._actionComplete({ goToPuzzle });
        } else {
            await this._actionTaskUpdate();
            void this._actionComplete({});
        }
        return this._complete;
    }

    private async _actionComplete(opts: { goToPuzzle?: boolean }) {
        this._complete.resolve(opts);
    }

    private async _actionTaskUpdate() {
        await app().server.invoke.updateTask({ stepIndex: this._stepIndex });

        trackTaskStep({ stepIndex: this._stepIndex });

        const updatedTask = app().server.state.task;
        const targetStar = this._screen.tasks[this._stepIndex].starIcon;
        const global = targetStar.toGlobal({ x: targetStar.width * 0.5, y: targetStar.height * 0.5 });
        const animationDuration = 0.7;
        this._screen.isPausedProgress = true;
        await this._screen.starDecreaseAnimation({ animationDuration, target: global });
        this._screen.updateProgress(updatedTask, true);
        this._screen.isPausedProgress = false;

        const timestamp = updatedTask.steps[this._stepIndex].timestamp;
        this._screen.tasks[this._stepIndex].update({ timestamp });
    }

    private async _notEnoughStars() {
        const noStarsPromise = new NakedPromise<boolean>();
        void app().nav.open('notEnoughStarsPopup', {
            underlay: 0.6,
            onOk: () => noStarsPromise.resolve(true),
            onClose: () => noStarsPromise.resolve(false),
        });
        const goToPuzzle = await noStarsPromise;
        void app().nav.close('notEnoughStarsPopup');
        return goToPuzzle;
    }
}
