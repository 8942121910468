import NakedPromise from '../../../lib/pattern/NakedPromise';
import { textLocaleFormat } from '../../../lib/util/textTools';
import { tween } from '../../../lib/util/tweens';
import { GameId } from '../../../replicant/defs/gameDay';
import { sleep } from '../../../replicant/util/jsTools';
import app from '../../getApp';
import { BaseballPlayer, PlayerClipId } from '../../concept/BaseballPlayer';
import { trackGameDayStep } from '../../lib/analytics/gameDay';
import { GameDayPlayer, GameDayScreen } from '../../main/home/GameDayScreen';
import { GameDayFlow, GameDayFlowOpts, ScoreData } from './GameDayFlow';

const scoreboardData: ScoreData[] = [
    {
        balls: 0,
        strikes: 2,
        outs: 2,
        visitorData: ['0', '0', '2', '0'],
        homeData: ['1', '2', '1'],
        visitorR: 2,
        homeR: 4,
    },
    {
        balls: 0,
        strikes: 2,
        outs: 1,
        visitorData: ['0', '0', '2', '2', '0', '1', '2', '0'],
        homeData: ['1', '2', '1', '0', '0', '1', '0', '1'],
        visitorR: 5,
        homeR: 6,
    },
    {
        balls: 0,
        strikes: 0,
        outs: 0,
        visitorData: ['0', '0', '2', '2', '0', '1', '2', '0', '0'],
        homeData: ['1', '2', '1', '0', '0', '1', '0', '1', '-'], // null -> '-'
        visitorR: 5,
        homeR: 6,
    },
];

export class GameDay3Flow extends GameDayFlow {
    // fields
    //-------------------------------------------------------------------------

    // init
    //-------------------------------------------------------------------------
    constructor(opts: GameDayFlowOpts) {
        super(opts);
        this._visitor = app().server.state.name;
        this._home = textLocaleFormat('[gameDay3Opponent]');
        this._opponent = opts.opponentOverride ?? textLocaleFormat('[gameDay3Opponent]');
        this._scoreboard = 'board2';
        this._gameDaySteps = [
            { handler: this._intro, substeps: this._introSubSteps },
            { handler: this._step1, substeps: 3 },
            { handler: this._step2, substeps: 2 },
            { handler: this._step3, substeps: 4 },
            { handler: this._stepEnding, substeps: this._endingSubSteps },
        ];
    }

    // impl
    //-------------------------------------------------------------------------
    private async _intro() {
        await this._baseIntro({
            scoreData: scoreboardData[0],
            tapDialog: `[gameDay3Step0|${this._visitor}]`,
            nextBgId: 'gameDay0',
        });
    }

    private async _step1() {
        let stepIndex = this._stepOffset[this._step];

        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay0',
            players: [
                {
                    skin: 'character5_boy',
                    offsetX: 60,
                    offsetY: -4,
                    scale: 0.81,

                    clip: 'fielder_idle',
                    loop: true,
                } as GameDayPlayer,
                {
                    skin: 'character3_boy_red',
                    offsetX: -115,
                    offsetY: 60,
                    scale: 0.81,
                    clip: 'onbase1_flipped',
                    loop: true,
                } as GameDayPlayer,
            ],
        })) as GameDayScreen;

        void app().nav.preload('gameDayScreen', { bgId: 'gameDay2' });
        void app().resource.loadAsset(this._batSFX);
        await sleep(1.25);

        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay2',
            players: [
                {
                    skin: 'character6_girl_red2',
                    offsetX: 30,
                    offsetY: 40,
                    clip: 'batter_grounder',
                    loop: false,
                } as GameDayPlayer,
            ],
        })) as GameDayScreen;

        void app().sound.play(this._batSFX);
        void app().resource.loadAsset(this._catchSFX);
        void app().nav.preload('gameDayScreen', { bgId: 'gameDay5' });
        await sleep(0.75);

        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay5',
            players: [
                {
                    skin: 'character2_girl',
                    clip: 'fielder_catch',
                    scaleX: -1,
                    loop: false,
                } as GameDayPlayer,
            ],
        })) as GameDayScreen;
        void app().sound.play(this._catchSFX);
        await sleep(0.4);
        void this._gameDayScreen.playPlayerAnimation({ id: 'fielder_idle', loop: true, mix: 3 });

        const tapPromise = new NakedPromise();
        await this._gameDayScreen.spawnBubbleTap({
            dialogText: `[gameDay3Step1]`,
            promise: tapPromise,
            bubbleOffset: { x: 0, y: this._gameDayScreen.base.height * 0.5 - this._gameDayScreen.base.height * 0.22 },
            parentOverride: this._gameDayScreen.base,
        });

        void this._gameDayScreen.preloadChoiceView();
        await tapPromise;

        trackGameDayStep({ stepIndex: stepIndex++, stepName: 'catch', stepType: 'tap' });

        const choicePromise = new NakedPromise<number>();
        void this._gameDayScreen.spawnBubbleChoice({
            dialogText: '[gameDay3Step2]',
            choices: ['[gameDay3Choice0]', '[gameDay3Choice1]'],
            promise: choicePromise,
            bubbleOffset: { x: 0, y: this._gameDayScreen.base.height * 0.5 - this._gameDayScreen.base.height * 0.22 },
            parentOverride: this._gameDayScreen.base,
        });

        // preload sfx
        void app().resource.loadAsset(this._batSFX);
        const choiceIndex = await choicePromise;

        trackGameDayStep({ stepIndex: stepIndex++, stepName: 'first_choice', stepType: 'choice' });

        if (choiceIndex === 1) {
            // index 0 already used earlier in this step
            void app().nav.preload('gameDayScreen', { bgId: 'gameDay1' });
        }

        await this._gameDayScreen.playPlayerAnimation({ id: 'fielder_throw', loop: false });

        const choiceScenes = [
            async () =>
                (await app().nav.open('gameDayScreen', {
                    bgId: 'gameDay0',
                    players: [
                        {
                            skin: 'character5_boy',
                            offsetX: -60,
                            offsetY: -4,
                            scale: 0.78,
                            clip: 'baseman_catch',
                            loop: false,
                        } as GameDayPlayer,
                        {
                            skin: 'character6_girl_red2',
                            offsetX: 280,
                            offsetY: 200,
                            scale: 0.81,
                            scaleX: -1,
                            clip: 'run',
                            loop: true,
                            animateRun: true,
                            overrideRunCallback: async (player: BaseballPlayer) => {
                                await player
                                    .animate()
                                    .add(player, { x: player.x - 130, y: player.y - 80 }, 0.4, tween.linear)
                                    .promise();
                            },
                        } as GameDayPlayer,
                    ],
                })) as GameDayScreen,
            async () =>
                (await app().nav.open('gameDayScreen', {
                    bgId: 'gameDay1',
                    players: [
                        {
                            skin: 'character1_boy',
                            offsetX: -60,
                            offsetY: 5,
                            scale: 0.8,
                            clip: 'baseman_catch',
                            loop: false,
                        } as GameDayPlayer,
                        {
                            skin: 'character3_boy_red',
                            offsetX: 240,
                            offsetY: -5,
                            scale: 0.8,
                            clip: 'onbase2_out_flipped',
                            loop: true,
                        } as GameDayPlayer,
                    ],
                })) as GameDayScreen,
        ];

        void sleep(0.4).then(() => app().sound.play(this._catchSFX));
        this._gameDayScreen = await choiceScenes[choiceIndex]();

        const postSceneChoice = [
            // choice 1
            async () => {
                await sleep(0.12);
                await this._gameDayScreen.playPlayerAnimation({
                    playerIndex: 1,
                    id: 'onbase1_out_flipped_idle',
                    loop: false,
                    scaleX: -1,
                });
            },
            // choice 2
            async () => sleep(1.8),
        ];
        await postSceneChoice[choiceIndex]();

        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay5',
            players: [
                {
                    skin: 'character2_girl',
                    clip: 'fielder_idle',
                    scaleX: -1,
                    loop: false,
                } as GameDayPlayer,
            ],
        })) as GameDayScreen;

        const idlePromise = new NakedPromise();
        await this._gameDayScreen.spawnBubbleTap({
            dialogText: `[gameDay3Step3]`,
            promise: idlePromise,
            bubbleOffset: { x: 0, y: this._gameDayScreen.base.height * 0.5 - this._gameDayScreen.base.height * 0.22 },
            parentOverride: this._gameDayScreen.base,
        });

        void app().resource.loadAsset(this._batSFX);
        await idlePromise;

        trackGameDayStep({ stepIndex: stepIndex++, stepName: 'amazing', stepType: 'tap' });
    }

    private async _step2() {
        let stepIndex = this._stepOffset[this._step];
        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay2',
            players: [{ skin: 'character1_boy', clip: 'batter_homerun', loop: false } as GameDayPlayer],
        })) as GameDayScreen;
        void app().sound.play(this._batSFX);

        void sleep(1.1).then(() =>
            this._gameDayScreen.playPlayerAnimation({ id: 'batter_idle', loop: true, mix: 0.15 }),
        );

        void app().nav.preload('gameDayScreen', { bgId: 'gameDay3' });
        const homerunPromise = new NakedPromise();
        await this._gameDayScreen.spawnBubbleTap({
            dialogText: `[gameDay3Step4]`,
            promise: homerunPromise,
            narrator: 'arrow',
            bubbleOffset: { x: 0, y: this._gameDayScreen.base.height - 150 },
            parentOverride: this._gameDayScreen.base,
        });
        await homerunPromise;
        trackGameDayStep({ stepIndex: stepIndex++, stepName: 'homerun', stepType: 'tap' });

        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay3',
            players: [{ skin: 'character3_boy_red', clip: 'pitcher_watchhomerun', loop: false } as GameDayPlayer],
        })) as GameDayScreen;

        void app().nav.preload('gameDayScreen', { bgId: 'thirdBase' });
        await sleep(1.2);

        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay2',
            players: [
                {
                    skin: 'character4_girl',
                    clip: 'run',
                    loop: true,
                    offsetX: 630,
                    offsetY: 40,
                    scaleX: -1,
                } as GameDayPlayer,
                {
                    skin: 'character1_boy',
                    clip: 'run',
                    loop: true,
                    offsetX: 630,
                    offsetY: 40,
                    scaleX: -1,
                } as GameDayPlayer,
            ],
        })) as GameDayScreen;

        void this._gameDayScreen.horizontalAnimation({ playerIndex: 0 });
        void sleep(1).then(() => this._gameDayScreen.horizontalAnimation({ playerIndex: 1 }));

        const runPromise = new NakedPromise();
        await this._gameDayScreen.spawnBubbleTap({
            dialogText: `[gameDay3Step5]`,
            promise: runPromise,
            narrator: 'arrow',
            bubbleOffset: { x: 0, y: this._gameDayScreen.base.height - 150 },
            parentOverride: this._gameDayScreen.base,
        });

        void this._gameDayScreen.preloadScoreboard(this._scoreboard);
        await runPromise;

        trackGameDayStep({ stepIndex: stepIndex++, stepName: 'runners', stepType: 'tap' });
    }

    private async _step3() {
        let stepIndex = this._stepOffset[this._step];
        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'thirdBase',
        })) as GameDayScreen;

        await this._gameDayScreen.preloadScoreboard(this._scoreboard);
        void this._gameDayScreen.spawnScoreDynamic({
            ...scoreboardData[1],
            scoreboard: this._scoreboard,
            home: this._home,
            visitor: this._visitor,
        });

        const scorePromise = new NakedPromise();
        await this._gameDayScreen.spawnBubbleTap({
            dialogText: `[gameDay3Step6|${this._home}]`,
            promise: scorePromise,
            narrator: 'arrow',
            bubbleOffset: { x: 0, y: this._gameDayScreen.base.height - 150 },
            parentOverride: this._gameDayScreen.base,
        });

        void app().nav.preload('gameDayScreen', { bgId: 'thirdBase' });
        await scorePromise;

        trackGameDayStep({ stepIndex: stepIndex++, stepName: 'runner_score', stepType: 'tap' });

        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'thirdBase',
            players: [
                {
                    skin: 'character6_girl_red2',
                    offsetX: 30,
                    offsetY: -10,
                    scale: 0.75,
                    clip: 'fielder_idle',
                    loop: true,
                } as GameDayPlayer,
                {
                    skin: 'character5_boy',
                    offsetX: -110,
                    offsetY: 10,
                    scale: 0.8,
                    clip: 'onbase1_flipped',
                    loop: true,
                } as GameDayPlayer,
            ],
        })) as GameDayScreen;

        await sleep(1.25);

        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay2',
            players: [{ skin: 'character1_boy', clip: 'batter_idle', loop: true } as GameDayPlayer],
        })) as GameDayScreen;

        const tapPromise = new NakedPromise();
        await this._gameDayScreen.spawnBubbleTap({
            dialogText: `[gameDay3Step7]`,
            promise: tapPromise,
            bubbleOffset: { x: 0, y: this._gameDayScreen.base.height * 0.5 - this._gameDayScreen.base.height * 0.22 },
            parentOverride: this._gameDayScreen.base,
        });

        void this._gameDayScreen.preloadChoiceView();
        await tapPromise;

        trackGameDayStep({ stepIndex: stepIndex++, stepName: 'batter_question', stepType: 'tap' });

        const choicePromise = new NakedPromise<number>();
        void this._gameDayScreen.spawnBubbleChoice({
            dialogText: '[gameDay3Step8]',
            choices: ['[gameDay3Choice2]', '[gameDay3Choice3]'],
            promise: choicePromise,
            bubbleOffset: { x: 0, y: this._gameDayScreen.base.height * 0.5 - this._gameDayScreen.base.height * 0.22 },
            parentOverride: this._gameDayScreen.base,
        });

        // preload sfx
        void app().resource.loadAsset(this._batSFX);
        const choiceIndex = await choicePromise;
        trackGameDayStep({ stepIndex: stepIndex++, stepName: 'second_choice', stepType: 'choice' });

        const choiceAnimationClips: PlayerClipId[] = ['batter_grounder', 'batter_linedrive'];

        void sleep(0.3).then(() => app().sound.play(this._batSFX));
        void app().nav.preload('gameDayScreen', { bgId: choiceIndex === 0 ? 'gameDay3' : 'gameDay5' });
        await this._gameDayScreen.playPlayerAnimation({ id: choiceAnimationClips[choiceIndex], loop: false });
        await sleep(0.2);

        const choiceScenes = [
            async () =>
                (await app().nav.open('gameDayScreen', {
                    bgId: 'gameDay3',
                    players: [
                        {
                            skin: 'character3_boy_red',
                            clip: 'pitcher_catchthrowtofirst',
                            offsetX: 100,
                            loop: false,
                        } as GameDayPlayer,
                    ],
                })) as GameDayScreen,
            async () =>
                (await app().nav.open('gameDayScreen', {
                    bgId: 'gameDay5',
                    players: [
                        {
                            skin: 'character6_girl_red2',
                            clip: 'pitcher_catchthrowtofirst',
                            offsetX: 100,
                            loop: false,
                        } as GameDayPlayer,
                    ],
                })) as GameDayScreen,
        ];

        await choiceScenes[choiceIndex]();

        await sleep(1);
        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'thirdBase',
            players: [
                {
                    skin: 'character5_boy',
                    offsetX: -70,
                    offsetY: 100,
                    scale: 0.8,
                    clip: 'onbase1_out_flipped',
                    loop: false,
                } as GameDayPlayer,
                {
                    skin: 'character6_girl_red2',
                    offsetX: 50,
                    offsetY: 120,
                    scale: 0.8,
                    clip: 'fielder_catchthrow',
                    loop: false,
                } as GameDayPlayer,
            ],
        })) as GameDayScreen;

        await sleep(1.5);

        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay0',
            players: [
                {
                    skin: 'character3_boy_red',
                    offsetX: -10,
                    offsetY: 10,
                    scale: 0.8,
                    clip: 'baseman_catch',
                    loop: false,
                } as GameDayPlayer,
                {
                    skin: 'character1_boy',
                    offsetX: 280,
                    offsetY: 200,
                    scale: 0.8,
                    scaleX: -1,
                    clip: 'run',
                    loop: true,
                    animateRun: true,
                    overrideRunCallback: async (player: BaseballPlayer) => {
                        await player
                            .animate()
                            .add(player, { x: player.x - 130, y: player.y - 80 }, 0.4, tween.linear)
                            .promise();
                    },
                } as GameDayPlayer,
            ],
        })) as GameDayScreen;

        void sleep(0.1).then(() => app().sound.play(this._catchSFX));

        void sleep(0.12).then(() => {
            void this._gameDayScreen.playPlayerAnimation({
                playerIndex: 1,
                id: 'onbase1_out_flipped_idle',
                loop: true,
                scaleX: -1,
            });
        });
        void sleep(1.5).then(() => {
            void this._gameDayScreen.playPlayerAnimation({ playerIndex: 0, id: 'baseman_catch_idle', loop: true });
        });

        await sleep(1.5);

        const catchPromise = new NakedPromise();
        await this._gameDayScreen.spawnBubbleTap({
            dialogText: `[gameDay3Step9]`,
            promise: catchPromise,
            narrator: 'arrow',
            bubbleOffset: { x: 0, y: this._gameDayScreen.base.height - 150 },
            parentOverride: this._gameDayScreen.base,
        });

        void app().nav.preload('gameDayScreen', { bgId: 'gameDay5', fullTeam: { type: 'loss' } });
        await catchPromise;

        trackGameDayStep({ stepIndex: stepIndex++, stepName: 'opponent_catch', stepType: 'tap' });
    }

    private async _stepEnding() {
        await this._baseLoss({ scoreData: scoreboardData[2], tapDialog: `[gameDay3Step10]` });
    }
}
