import { waitAFrame } from '@play-co/astro';
import { Assets } from 'pixi.js';

import { PositionType, SizeType } from '../../../../../lib/defs/types';
import { BlockBreakAnimation } from '../../animations/BlockBreakAnimation';
import { despawnOverlayEntity, spawnOverlayEntity } from '../../entities/OverlayEntity';
import { GameScene } from '../../GameScene';
import { IEffect } from './IEffect';

// types
//-----------------------------------------------------------------------------
export type BreakEffectOptions = {
    position: PositionType;
    size: SizeType;
    color: number;
    alpha?: number;
};

/*
    block breaking effect
*/
export class BreakEffect implements IEffect {
    // fields
    //-------------------------------------------------------------------------
    // input
    private readonly _scene: GameScene;
    private readonly _position: PositionType;
    private readonly _size: SizeType;
    private readonly _color: number;
    private readonly _alpha: number;

    // init
    //-------------------------------------------------------------------------
    constructor(scene: GameScene, options: BreakEffectOptions) {
        this._scene = scene;
        this._position = options.position;
        this._size = options.size;
        this._color = options.color;
        this._alpha = options.alpha ?? 1;
    }

    static assets(): string[] {
        return BlockBreakAnimation.assets();
    }

    // impl
    //-------------------------------------------------------------------------
    public async execute() {
        // wait a frame since we async the animation
        await waitAFrame();

        // load assets
        await Assets.load(BreakEffect.assets());

        // animate
        void this._animate();
    }

    // private: animation
    //-------------------------------------------------------------------------
    private async _animate() {
        // create  animation
        const animation = new BlockBreakAnimation({
            color: this._color,
            alpha: this._alpha,
        });

        // spawn animation as overlay
        const entity = spawnOverlayEntity(this._scene, this._position, this._size, () => animation);

        // run animation
        await animation.start();

        // despawn entity
        despawnOverlayEntity(this._scene, entity);
    }
}
