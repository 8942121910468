import { SB } from '@play-co/replicant';

import { timeFromComponents } from '../util/timeTools';

export type Scoreboard = 'board0' | 'board1' | 'board2';
export type ClockIdJST = keyof typeof nextDayTimers;
export type GameId = 'first' | 'second'; // 2 games a day

export const gameDayState = {
    gameDay: SB.object({
        // main game of the day
        day: SB.int(), // last played first game of the day
        startMorale: SB.int().min(0), // morale points at the start of the game to determine result
        lastCompleted: SB.int(), // last completed day, used in combination with timestamp to calculate game schedule
        timestamp: SB.int(), // last time a game day was completed
        step: SB.int(), // in case a player drops out mid flow, re-store the step
        completed: SB.boolean(), // needed for game day calculations and next available game day
        practiceId: SB.int().default(-1), // practice game id, used to send practice messaged and sending the same practice set in a row
        rewards: SB.array(
            SB.object({
                // gameId (day) and game (0 or 1, first or second) to lookup rewards from config
                gameId: SB.int(),
                game: SB.int().optional(),
                // previousTimestamp and teamMorale value hashed to lookup rewards from config
                previousTimestamp: SB.int().optional(),
                teamMorale: SB.int().optional(),
            }),
        ),

        // secondary game in the same day
        secondGame: SB.object({
            day: SB.int(), // last played second game of the day
            lastCompleted: SB.int(),
            timestamp: SB.int(),
            step: SB.int(),
            completed: SB.boolean(),
        }),
    }),
};

const nextDayTimers = {
    // 7am JST
    sevenAM: timeFromComponents({ hours: 22 }), // 07:00 (7:00am) in Japan, JST is +22 hours from UTC
    // 9am JST next day utc
    nineAM: timeFromComponents({ hours: 24 }), // 09:00 (9:00am) in Japan, JST is +0 hours from UTC
    // 10am JST next day utc
    tenAM: timeFromComponents({ hours: 24 + 1 }), // 10:00 (10:00am) in Japan, JST is +1 hours from UTC
    // 12pm JST next day utc
    twelvePM: timeFromComponents({ hours: 24 + 3 }), // 12:00 (12:00pm) in Japan, JST is +3 hours from UTC
    // 3pm JST next day utc
    threePM: timeFromComponents({ hours: 24 + 6 }), // 15:00 (3:00pm) in Japan, JST is +6 hours from UTC
    // 5pm JST next day utc
    fivePM: timeFromComponents({ hours: 24 + 8 }), // 17:00 (5:00pm) in Japan, JST is +8 hours from UTC
    // 8pm JST next day utc
    eightPM: timeFromComponents({ hours: 24 + 11 }), // 20:00 (8:00pm) in Japan, JST is +11 hours from UTC
};

// index is gameId, sequential 0-7 or 0-15 for AB test
const gameDayRewards = [
    { result: 'win', items: [{ id: 'coins', amount: 100 }] },
    { result: 'lose', items: [{ id: 'coins', amount: 10 }] },
    {
        result: 'win',
        items: [
            { id: 'rocket', amount: 1 },
            { id: 'roulette', amount: 1 },
        ],
    },
    { result: 'lose', items: [{ id: 'dart', amount: 1 }] },
    { result: 'win', items: [{ id: 'bullet', amount: 1 }] },
    { result: 'win', items: [{ id: 'drill', amount: 1 }] },
    { result: 'lose', items: [{ id: 'coins', amount: 10 }] },
    { result: 'lose', items: [{ id: 'rocket', amount: 1 }] },
];

// win gameday0, gameday2, gameday4, gameday5
// loss gameday1, gameday3, gameday6, gameday7
// index is gameId, sequential 0-7 and sub index is the dynamic day depending on teamMorale
const gameDayRewardsTeamMorale = [
    // day 1
    [
        { result: 'win', items: [{ id: 'coins', amount: 100 }] },
        { result: 'lose', items: [{ id: 'coins', amount: 10 }] },
    ],
    // day 2
    [
        { result: 'win', items: [{ id: 'rocket', amount: 1 }] },
        { result: 'lose', items: [{ id: 'roulette', amount: 1 }] },
    ],
    // day 3
    [
        { result: 'win', items: [{ id: 'bullet', amount: 1 }] },
        { result: 'lose', items: [{ id: 'coins', amount: 10 }] },
    ],
    // day 4
    [
        { result: 'win', items: [{ id: 'drill', amount: 1 }] },
        { result: 'lose', items: [{ id: 'rocket', amount: 1 }] },
    ],

    // day 5
    [
        { result: 'win', items: [{ id: 'rocket', amount: 1 }] },
        { result: 'lose', items: [{ id: 'roulette', amount: 1 }] },
    ],
    // day 6
    [
        { result: 'win', items: [{ id: 'coins', amount: 100 }] },
        { result: 'lose', items: [{ id: 'coins', amount: 10 }] },
    ],
    // day 7
    [
        { result: 'win', items: [{ id: 'rocket', amount: 1 }] },
        { result: 'lose', items: [{ id: 'rocket', amount: 1 }] },
    ],
    // day 8
    [
        { result: 'win', items: [{ id: 'drill', amount: 1 }] },
        { result: 'lose', items: [{ id: 'coins', amount: 10 }] },
    ],
];

const gameDayRewardsMultiple = [
    // day 1, game 1, 2
    [
        { result: 'win', items: [{ id: 'coins', amount: 100 }] },
        { result: 'lose', items: [{ id: 'coins', amount: 10 }] },
    ],
    // day 2, game 1, 2
    [
        {
            result: 'win',
            items: [
                { id: 'rocket', amount: 1 },
                { id: 'roulette', amount: 1 },
            ],
        },
        { result: 'lose', items: [{ id: 'dart', amount: 1 }] },
    ],
    // day 3, game 1, 2
    [
        { result: 'win', items: [{ id: 'bullet', amount: 1 }] },
        { result: 'win', items: [{ id: 'drill', amount: 1 }] },
    ],
    // day 4, game 1, 2
    [
        { result: 'lose', items: [{ id: 'coins', amount: 10 }] },
        { result: 'lose', items: [{ id: 'rocket', amount: 1 }] },
    ],
    // day 5, game 1, 2
    [
        { result: 'win', items: [{ id: 'coins', amount: 100 }] },
        { result: 'lose', items: [{ id: 'coins', amount: 10 }] },
    ],
    // day 6, game 1, 2
    [
        {
            result: 'win',
            items: [
                { id: 'rocket', amount: 1 },
                { id: 'roulette', amount: 1 },
            ],
        },
        { result: 'lose', items: [{ id: 'dart', amount: 1 }] },
    ],
    // day 7, game 1, 2
    [
        { result: 'win', items: [{ id: 'bullet', amount: 1 }] },
        { result: 'win', items: [{ id: 'drill', amount: 1 }] },
    ],
    // day 8, game 1, 2
    [
        { result: 'lose', items: [{ id: 'coins', amount: 10 }] },
        { result: 'lose', items: [{ id: 'rocket', amount: 1 }] },
    ],
];

export default {
    firstGameAmount: 8,
    secondGameAmount: 8,
    nextDayTimers,
    practiceIds: [0, 1, 2, 3, 4],
    gameDayRewards,
    gameDayRewardsMultiple,
    gameDayRewardsTeamMorale,
};
