import { FlexContainer } from '@play-co/flex';

import { isCoinPackShopItem, ProductId, ProductItem } from '../../../replicant/defs/product';
import { ShopBundleItem } from './ShopBundleItem';
import { ShopCoinPackItem } from './ShopCoinPackItem';
import { ShopListItem } from './ShopListItem';

export class ShopList extends FlexContainer {
    private _onItemPress: (id: ProductId) => Promise<void>;

    constructor(opts: { onItemPress: (id: ProductId) => Promise<void> }) {
        super({
            width: '100%',
            direction: 'column',
            alignItems: 'center',
            paddingTop: 10,
        });

        this._onItemPress = opts.onItemPress;
    }

    public loadItems(items: ProductItem[]) {
        for (const item of items) {
            let shopItem: ShopListItem;
            if (isCoinPackShopItem(item)) {
                shopItem = new ShopCoinPackItem(item);
            } else {
                shopItem = new ShopBundleItem(item);
            }
            shopItem.onPress = this._onItemPress;
            this.addChild(shopItem);
        }
    }
}
