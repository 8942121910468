import { Layout } from '@pixi/layout';
import { FlexContainer } from '@play-co/flex';
import { Container, NineSlicePlane, Sprite, Texture } from 'pixi.js';

import app from '../../getApp';
import { BasicAsyncHandler } from '../../../lib/defs/types';
import { uiAlignCenterX } from '../../../lib/pixi/uiTools';
import { textLocaleFormat } from '../../../lib/util/textTools';
import { AdminId } from '../../../replicant/defs/admin';
import { BoosterId, boosterMap, PowerBoosterId } from '../../../replicant/defs/booster';
import { TextImageButton } from '../../lib/ui/buttons/TextImageButton';
import { BasicText } from '../../lib/ui/text/BasicText';
import { PopupScreen, PopupOptions } from '../../lib/screens/PopupScreen';

// types
//-----------------------------------------------------------------------------
export type AdminRewardPopupOptions = {
    rewards: Record<AdminId, number>;
    onClaim: BasicAsyncHandler;
} & PopupOptions;

// constants
//-----------------------------------------------------------------------------
const manifest = {
    bg: 'frame.popup.yellow.png',
    button: 'button.green.png',
    panel: 'panel.purple.glow.png',
    coins: 'icon.coins.daily.bonus.png',
    stars: 'icon.star.main.large.png',
    infiniteLives: 'icon.life.infinite.large.png',
    // power boosters
    bomb: 'block.bomb.png',
    cube: 'block.cube.all.png',
    rocket: 'block.rocket.horizontal.png',
};
const lazyManifest = {
    rewardEN: 'label.reward.en.png',
    rewardJA: 'label.reward.ja.png',
};

const WIDTH = 692;
const HEIGHT = 776;

export class AdminRewardPopup extends PopupScreen {
    // fields
    //-------------------------------------------------------------------------
    // events
    public onClaim: BasicAsyncHandler;
    // scene
    protected main: Layout;

    private _claimButton: TextImageButton;

    public get claimButton(): TextImageButton {
        return this._claimButton;
    }

    // impl
    //-------------------------------------------------------------------------
    public preload() {
        const lang = app().settings.language;
        const assets = Object.values(manifest);
        lang === 'en' ? assets.push(lazyManifest.rewardEN) : assets.push(lazyManifest.rewardJA);
        Object.keys(boosterMap).forEach((id: BoosterId) => assets.push(boosterMap[id].asset));
        return app().resource.loadAssets(assets);
    }

    public override async spawning(options: AdminRewardPopupOptions) {
        void super.spawning({
            ...options,
            width: WIDTH,
            height: HEIGHT,
            underlay: 0.6,
        });

        this.onClaim = options.onClaim;

        const adminMessage = new BasicText({
            text: `[adminMessage]`,
            style: {
                align: 'center',
                fontSize: 39,
                fontWeight: 'bold',
                fill: '#3E4047',
                lineJoin: 'round',
            },
        });

        const panel = Sprite.from(manifest.panel);
        const bgContainer = new Container();
        bgContainer.addChild(panel);

        const rewardList = Object.keys(options.rewards).map((id: AdminId) => ({
            id,
            amount: options.rewards[id],
        }));

        const totalItems = rewardList.filter((item) => item.amount > 0) as {
            id: AdminId;
            amount: number;
        }[];

        function addItems(flexRow: FlexContainer, items: { id: AdminId; amount: number }[]) {
            // if one item then allow it to be slightly larger
            const upScale = items.length === 1;
            items.forEach((item: { id: AdminId; amount: number }) => {
                const { id, amount } = item;

                const itemFlex = new FlexContainer({
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 126,
                    height: 150,
                    direction: 'column',
                });

                // is regular booster?
                let sprite: Sprite;
                if (boosterMap[id as BoosterId]) {
                    sprite = Sprite.from(boosterMap[id as BoosterId].asset);
                } else {
                    sprite = Sprite.from(manifest[id as PowerBoosterId | 'coins' | 'stars' | 'infiniteLives']);
                }

                let spriteTop = 10;
                if (id === 'infiniteLives' || id === 'coins') {
                    spriteTop = 20;
                    sprite.setProportionalFlexWidth(upScale ? 114 : 86);
                    sprite.setProportionalFlexHeight(upScale ? 114 : 86);
                } else {
                    sprite.setProportionalFlexWidth(upScale ? 130 : 100);
                    sprite.setProportionalFlexHeight(upScale ? 130 : 100);
                }

                let text;
                if (id === 'infiniteLives') {
                    const hours = textLocaleFormat('[hours]');
                    // amount defined in hours
                    text = `${item.amount}${hours}`;
                } else {
                    text = `x${amount}`;
                }

                const amountText = new BasicText({
                    text,
                    style: {
                        align: 'center',
                        fontSize: 36,
                        fontWeight: 'bold',
                        fill: '#FFF976',
                        lineJoin: 'round',
                        stroke: '#000000',
                        strokeThickness: 10,
                    },
                });

                itemFlex.addChild(
                    sprite.setFlex({
                        position: 'absolute',
                        top: spriteTop,
                    }),
                    amountText.setFlex({
                        position: 'absolute',
                        top: upScale ? 138 : 110,
                    }),
                );

                flexRow.addChild(itemFlex);
            });

            flexRow.doLayout();

            return flexRow;
        }

        const topFlex = new FlexContainer({
            alignItems: 'center',
            justifyContent: 'center',
            height: 200,
        });

        let bottomFlex;
        if (totalItems.length > 5) {
            bottomFlex = new FlexContainer({
                alignItems: 'center',
                alignContent: 'space-around',
                justifyContent: 'center',
                height: 200,
            });

            const mod = totalItems.length % 2;

            addItems(topFlex, totalItems.splice(0, totalItems.length / 2 + mod));
            // add the rest
            addItems(bottomFlex, totalItems);
        } else {
            addItems(topFlex, totalItems);
        }

        const titleAsset = app().settings.language === 'en' ? lazyManifest.rewardEN : lazyManifest.rewardJA;
        const title = Sprite.from(titleAsset);
        this.baseLayout.addContent({
            bg: {
                content: new NineSlicePlane(Texture.from(manifest.bg), 95, 128, 32, 40).props({
                    width: WIDTH,
                    height: HEIGHT,
                }),
                styles: {
                    width: WIDTH,
                    height: HEIGHT,
                },
            },
            header: {
                content: title,
                styles: {
                    position: 'topCenter',
                    marginTop: -42,
                },
            },
            message: {
                content: adminMessage,
                styles: {
                    position: 'topCenter',
                    marginTop: 70,
                },
            },
            main: {
                content: bgContainer,
                styles: {
                    position: 'topCenter',
                    marginTop: 175,
                },
            },
            topFlex: {
                content: topFlex,
                styles: {
                    position: 'topCenter',
                    marginTop: bottomFlex ? 185 : 280, // 1 or 2 rows
                    marginLeft: -14, // offset to make it center
                },
            },
            bottomFlex: bottomFlex
                ? {
                      content: bottomFlex,
                      styles: {
                          position: 'topCenter',
                          marginTop: 365,
                          marginLeft: -14, // offset to make it center
                      },
                  }
                : null,
        });

        if (this.onClaim) {
            const okButton = (this._claimButton = new TextImageButton({
                text: '[buttonClaim]',
                image: manifest.button,
                y: -5,
                slice: {
                    width: 300,
                    height: 122,
                    left: 30,
                    top: 16,
                    right: 30,
                    bottom: 32,
                },
                style: {
                    fill: 'FFF',
                    fontSize: 40,
                    fontWeight: 'bold',
                    lineJoin: 'round',
                    fontStyle: 'normal',
                    dropShadow: true,
                    dropShadowDistance: 1,
                },
            }));
            okButton.props({
                y: this.baseLayout.height - 105,
                pivot: { x: okButton.width * 0.5, y: okButton.height * 0.5 },
                onPress: async () => this.onClaim?.(),
            });
            uiAlignCenterX(this.baseLayout, okButton); // Align with pivot to enable scale animation from center
            this.baseLayout.addChild(okButton);
        }
    }
}
