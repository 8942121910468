import { renderTemplate, renderTemplatesWithAssets, SB } from '@play-co/replicant';

import { getGameUrl } from '../util/envTools';
import { parseSenderName } from '../util';

/* eslint-disable camelcase */
const LINE_REDIRECT_URL = getGameUrl();

export const firstSessionOA = {
    first_session_0_ja: 'private/chatbot/init/first_session_1_ja.png',
    first_session_1_ja: 'private/chatbot/init/first_session_2_ja.png',
    first_session_2_ja: 'private/chatbot/init/first_session_3_ja.png',

    first_session_0_en: 'private/chatbot/init/first_session_1_en.png',
    first_session_1_en: 'private/chatbot/init/first_session_2_en.png',
    first_session_2_en: 'private/chatbot/init/first_session_3_en.png',
};

export const firstSessionV2OA = {
    first_session_v2_0_ja: 'private/chatbot/gameday/abv2/ja/game_day_0_0_ja.png',
    first_session_v2_1_ja: 'private/chatbot/gameday/abv2/ja/game_day_0_1_ja.png',
    first_session_v2_2_ja: 'private/chatbot/gameday/abv2/ja/game_day_0_2_ja.png',

    first_session_v2_0_en: 'private/chatbot/gameday/abv2/en/game_day_0_0_en.png',
    first_session_v2_1_en: 'private/chatbot/gameday/abv2/en/game_day_0_1_en.png',
    first_session_v2_2_en: 'private/chatbot/gameday/abv2/en/game_day_0_2_en.png',
};

export const gameDayAsssetsAbOA = {
    // 1 indexed, d1d5g1_1_en -> game day 1 & 5, game 1 message 1
    // -- english
    d1d5g1_1_en: 'private/chatbot/gameday/ab/en/d1d5g1_1_en.png',
    d1d5g1_2_en: 'private/chatbot/gameday/ab/en/d1d5g1_2_en.png',
    d1d5g1_3_en: 'private/chatbot/gameday/ab/en/d1d5g1_3_en.png',
    d1d5g2_1_en: 'private/chatbot/gameday/ab/en/d1d5g2_1_en.png',
    d1d5g2_2_en: 'private/chatbot/gameday/ab/en/d1d5g2_2_en.png',
    d1d5g2_3_en: 'private/chatbot/gameday/ab/en/d1d5g2_3_en.png',
    d2d6g1_1_en: 'private/chatbot/gameday/ab/en/d2d6g1_1_en.png',
    d2d6g1_2_en: 'private/chatbot/gameday/ab/en/d2d6g1_2_en.png',
    d2d6g1_3_en: 'private/chatbot/gameday/ab/en/d2d6g1_3_en.png',
    d2d6g2_1_en: 'private/chatbot/gameday/ab/en/d2d6g2_1_en.png',
    d2d6g2_2_en: 'private/chatbot/gameday/ab/en/d2d6g2_2_en.png',
    d2d6g2_3_en: 'private/chatbot/gameday/ab/en/d2d6g2_3_en.png',
    d3d7g1_1_en: 'private/chatbot/gameday/ab/en/d3d7g1_1_en.png',
    d3d7g1_2_en: 'private/chatbot/gameday/ab/en/d3d7g1_2_en.png',
    d3d7g1_3_en: 'private/chatbot/gameday/ab/en/d3d7g1_3_en.png',
    d3d7g2_1_en: 'private/chatbot/gameday/ab/en/d3d7g2_1_en.png',
    d3d7g2_2_en: 'private/chatbot/gameday/ab/en/d3d7g2_2_en.png',
    d3d7g2_3_en: 'private/chatbot/gameday/ab/en/d3d7g2_3_en.png',
    d4d8g1_1_en: 'private/chatbot/gameday/ab/en/d4d8g1_1_en.png',
    d4d8g1_2_en: 'private/chatbot/gameday/ab/en/d4d8g1_2_en.png',
    d4d8g1_3_en: 'private/chatbot/gameday/ab/en/d4d8g1_3_en.png',
    d4d8g2_1_en: 'private/chatbot/gameday/ab/en/d4d8g2_1_en.png',
    d4d8g2_2_en: 'private/chatbot/gameday/ab/en/d4d8g2_2_en.png',
    d4d8g2_3_en: 'private/chatbot/gameday/ab/en/d4d8g2_3_en.png',

    // japanese
    d1d5g1_1_ja: 'private/chatbot/gameday/ab/ja/d1d5g1_1_jp.png',
    d1d5g1_2_ja: 'private/chatbot/gameday/ab/ja/d1d5g1_2_jp.png',
    d1d5g1_3_ja: 'private/chatbot/gameday/ab/ja/d1d5g1_3_jp.png',
    d1d5g2_1_ja: 'private/chatbot/gameday/ab/ja/d1d5g2_1_jp.png',
    d1d5g2_2_ja: 'private/chatbot/gameday/ab/ja/d1d5g2_2_jp.png',
    d1d5g2_3_ja: 'private/chatbot/gameday/ab/ja/d1d5g2_3_jp.png',
    d2d6g1_1_ja: 'private/chatbot/gameday/ab/ja/d2d6g1_1_jp.png',
    d2d6g1_2_ja: 'private/chatbot/gameday/ab/ja/d2d6g1_2_jp.png',
    d2d6g1_3_ja: 'private/chatbot/gameday/ab/ja/d2d6g1_3_jp.png',
    d2d6g2_1_ja: 'private/chatbot/gameday/ab/ja/d2d6g2_1_jp.png',
    d2d6g2_2_ja: 'private/chatbot/gameday/ab/ja/d2d6g2_2_jp.png',
    d2d6g2_3_ja: 'private/chatbot/gameday/ab/ja/d2d6g2_3_jp.png',
    d3d7g1_1_ja: 'private/chatbot/gameday/ab/ja/d3d7g1_1_jp.png',
    d3d7g1_2_ja: 'private/chatbot/gameday/ab/ja/d3d7g1_2_jp.png',
    d3d7g1_3_ja: 'private/chatbot/gameday/ab/ja/d3d7g1_3_jp.png',
    d3d7g2_1_ja: 'private/chatbot/gameday/ab/ja/d3d7g2_1_jp.png',
    d3d7g2_2_ja: 'private/chatbot/gameday/ab/ja/d3d7g2_2_jp.png',
    d3d7g2_3_ja: 'private/chatbot/gameday/ab/ja/d3d7g2_3_jp.png',
    d4d8g1_1_ja: 'private/chatbot/gameday/ab/ja/d4d8g1_1_jp.png',
    d4d8g1_2_ja: 'private/chatbot/gameday/ab/ja/d4d8g1_2_jp.png',
    d4d8g1_3_ja: 'private/chatbot/gameday/ab/ja/d4d8g1_3_jp.png',
    d4d8g2_1_ja: 'private/chatbot/gameday/ab/ja/d4d8g2_1_jp.png',
    d4d8g2_2_ja: 'private/chatbot/gameday/ab/ja/d4d8g2_2_jp.png',
    d4d8g2_3_ja: 'private/chatbot/gameday/ab/ja/d4d8g2_3_jp.png',
};

export const gameDayAssetOA = {
    // 0 indexed, game_day_1 -> game day 2, initial game day is first_session_0
    // --- english
    game_day_0_0_en: 'private/chatbot/gameday/en/game_day_0_0_en.png',
    game_day_0_1_en: 'private/chatbot/gameday/en/game_day_0_1_en.png',
    game_day_0_2_en: 'private/chatbot/gameday/en/game_day_0_2_en.png',

    game_day_1_0_en: 'private/chatbot/gameday/en/game_day_1_0_en.png',
    game_day_1_1_en: 'private/chatbot/gameday/en/game_day_1_1_en.png',
    game_day_1_2_en: 'private/chatbot/gameday/en/game_day_1_2_en.png',

    game_day_2_0_en: 'private/chatbot/gameday/en/game_day_2_0_en.png',
    game_day_2_1_en: 'private/chatbot/gameday/en/game_day_2_1_en.png',
    game_day_2_2_en: 'private/chatbot/gameday/en/game_day_2_2_en.png',

    game_day_3_0_en: 'private/chatbot/gameday/en/game_day_3_0_en.png',
    game_day_3_1_en: 'private/chatbot/gameday/en/game_day_3_1_en.png',
    game_day_3_2_en: 'private/chatbot/gameday/en/game_day_3_2_en.png',

    game_day_4_0_en: 'private/chatbot/gameday/en/game_day_4_0_en.png',
    game_day_4_1_en: 'private/chatbot/gameday/en/game_day_4_1_en.png',
    game_day_4_2_en: 'private/chatbot/gameday/en/game_day_4_2_en.png',

    game_day_5_0_en: 'private/chatbot/gameday/en/game_day_5_0_en.png',
    game_day_5_1_en: 'private/chatbot/gameday/en/game_day_5_1_en.png',
    game_day_5_2_en: 'private/chatbot/gameday/en/game_day_5_2_en.png',

    game_day_6_0_en: 'private/chatbot/gameday/en/game_day_6_0_en.png',
    game_day_6_1_en: 'private/chatbot/gameday/en/game_day_6_1_en.png',
    game_day_6_2_en: 'private/chatbot/gameday/en/game_day_6_2_en.png',

    game_day_7_0_en: 'private/chatbot/gameday/en/game_day_7_0_en.png',
    game_day_7_1_en: 'private/chatbot/gameday/en/game_day_7_1_en.png',
    game_day_7_2_en: 'private/chatbot/gameday/en/game_day_7_2_en.png',

    // --- japanese
    game_day_0_0_ja: 'private/chatbot/gameday/ja/game_day_0_0_ja.png',
    game_day_0_1_ja: 'private/chatbot/gameday/ja/game_day_0_1_ja.png',
    game_day_0_2_ja: 'private/chatbot/gameday/ja/game_day_0_2_ja.png',

    game_day_1_0_ja: 'private/chatbot/gameday/ja/game_day_1_0_ja.png',
    game_day_1_1_ja: 'private/chatbot/gameday/ja/game_day_1_1_ja.png',
    game_day_1_2_ja: 'private/chatbot/gameday/ja/game_day_1_2_ja.png',

    game_day_2_0_ja: 'private/chatbot/gameday/ja/game_day_2_0_ja.png',
    game_day_2_1_ja: 'private/chatbot/gameday/ja/game_day_2_1_ja.png',
    game_day_2_2_ja: 'private/chatbot/gameday/ja/game_day_2_2_ja.png',

    game_day_3_0_ja: 'private/chatbot/gameday/ja/game_day_3_0_ja.png',
    game_day_3_1_ja: 'private/chatbot/gameday/ja/game_day_3_1_ja.png',
    game_day_3_2_ja: 'private/chatbot/gameday/ja/game_day_3_2_ja.png',

    game_day_4_0_ja: 'private/chatbot/gameday/ja/game_day_4_0_ja.png',
    game_day_4_1_ja: 'private/chatbot/gameday/ja/game_day_4_1_ja.png',
    game_day_4_2_ja: 'private/chatbot/gameday/ja/game_day_4_2_ja.png',

    game_day_5_0_ja: 'private/chatbot/gameday/ja/game_day_5_0_ja.png',
    game_day_5_1_ja: 'private/chatbot/gameday/ja/game_day_5_1_ja.png',
    game_day_5_2_ja: 'private/chatbot/gameday/ja/game_day_5_2_ja.png',

    game_day_6_0_ja: 'private/chatbot/gameday/ja/game_day_6_0_ja.png',
    game_day_6_1_ja: 'private/chatbot/gameday/ja/game_day_6_1_ja.png',
    game_day_6_2_ja: 'private/chatbot/gameday/ja/game_day_6_2_ja.png',

    game_day_7_0_ja: 'private/chatbot/gameday/ja/game_day_7_0_ja.png',
    game_day_7_1_ja: 'private/chatbot/gameday/ja/game_day_7_1_ja.png',
    game_day_7_2_ja: 'private/chatbot/gameday/ja/game_day_7_2_ja.png',
};

export const gameDayAssetV2OA = {
    // --- english
    game_day_v2_0_0_en: 'private/chatbot/gameday/abv2/en/game_day_0_0_en.png',
    game_day_v2_0_1_en: 'private/chatbot/gameday/abv2/en/game_day_0_1_en.png',
    game_day_v2_0_2_en: 'private/chatbot/gameday/abv2/en/game_day_0_2_en.png',

    game_day_v2_1_0_en: 'private/chatbot/gameday/abv2/en/game_day_1_0_en.png',
    game_day_v2_1_1_en: 'private/chatbot/gameday/abv2/en/game_day_1_1_en.png',
    game_day_v2_1_2_en: 'private/chatbot/gameday/abv2/en/game_day_1_2_en.png',

    game_day_v2_2_0_en: 'private/chatbot/gameday/abv2/en/game_day_2_0_en.png',
    game_day_v2_2_1_en: 'private/chatbot/gameday/abv2/en/game_day_2_1_en.png',
    game_day_v2_2_2_en: 'private/chatbot/gameday/abv2/en/game_day_2_2_en.png',

    game_day_v2_3_0_en: 'private/chatbot/gameday/abv2/en/game_day_3_0_en.png',
    game_day_v2_3_1_en: 'private/chatbot/gameday/abv2/en/game_day_3_1_en.png',
    game_day_v2_3_2_en: 'private/chatbot/gameday/abv2/en/game_day_3_2_en.png',

    game_day_v2_4_0_en: 'private/chatbot/gameday/abv2/en/game_day_4_0_en.png',
    game_day_v2_4_1_en: 'private/chatbot/gameday/abv2/en/game_day_4_1_en.png',
    game_day_v2_4_2_en: 'private/chatbot/gameday/abv2/en/game_day_4_2_en.png',

    game_day_v2_5_0_en: 'private/chatbot/gameday/abv2/en/game_day_5_0_en.png',
    game_day_v2_5_1_en: 'private/chatbot/gameday/abv2/en/game_day_5_1_en.png',
    game_day_v2_5_2_en: 'private/chatbot/gameday/abv2/en/game_day_5_2_en.png',

    game_day_v2_6_0_en: 'private/chatbot/gameday/abv2/en/game_day_6_0_en.png',
    game_day_v2_6_1_en: 'private/chatbot/gameday/abv2/en/game_day_6_1_en.png',
    game_day_v2_6_2_en: 'private/chatbot/gameday/abv2/en/game_day_6_2_en.png',

    game_day_v2_7_0_en: 'private/chatbot/gameday/abv2/en/game_day_7_0_en.png',
    game_day_v2_7_1_en: 'private/chatbot/gameday/abv2/en/game_day_7_1_en.png',
    game_day_v2_7_2_en: 'private/chatbot/gameday/abv2/en/game_day_7_2_en.png',

    // --- japanese
    game_day_v2_0_0_ja: 'private/chatbot/gameday/abv2/ja/game_day_0_0_ja.png',
    game_day_v2_0_1_ja: 'private/chatbot/gameday/abv2/ja/game_day_0_1_ja.png',
    game_day_v2_0_2_ja: 'private/chatbot/gameday/abv2/ja/game_day_0_2_ja.png',

    game_day_v2_1_0_ja: 'private/chatbot/gameday/abv2/ja/game_day_1_0_ja.png',
    game_day_v2_1_1_ja: 'private/chatbot/gameday/abv2/ja/game_day_1_1_ja.png',
    game_day_v2_1_2_ja: 'private/chatbot/gameday/abv2/ja/game_day_1_2_ja.png',

    game_day_v2_2_0_ja: 'private/chatbot/gameday/abv2/ja/game_day_2_0_ja.png',
    game_day_v2_2_1_ja: 'private/chatbot/gameday/abv2/ja/game_day_2_1_ja.png',
    game_day_v2_2_2_ja: 'private/chatbot/gameday/abv2/ja/game_day_2_2_ja.png',

    game_day_v2_3_0_ja: 'private/chatbot/gameday/abv2/ja/game_day_3_0_ja.png',
    game_day_v2_3_1_ja: 'private/chatbot/gameday/abv2/ja/game_day_3_1_ja.png',
    game_day_v2_3_2_ja: 'private/chatbot/gameday/abv2/ja/game_day_3_2_ja.png',

    game_day_v2_4_0_ja: 'private/chatbot/gameday/abv2/ja/game_day_4_0_ja.png',
    game_day_v2_4_1_ja: 'private/chatbot/gameday/abv2/ja/game_day_4_1_ja.png',
    game_day_v2_4_2_ja: 'private/chatbot/gameday/abv2/ja/game_day_4_2_ja.png',

    game_day_v2_5_0_ja: 'private/chatbot/gameday/abv2/ja/game_day_5_0_ja.png',
    game_day_v2_5_1_ja: 'private/chatbot/gameday/abv2/ja/game_day_5_1_ja.png',
    game_day_v2_5_2_ja: 'private/chatbot/gameday/abv2/ja/game_day_5_2_ja.png',

    game_day_v2_6_0_ja: 'private/chatbot/gameday/abv2/ja/game_day_6_0_ja.png',
    game_day_v2_6_1_ja: 'private/chatbot/gameday/abv2/ja/game_day_6_1_ja.png',
    game_day_v2_6_2_ja: 'private/chatbot/gameday/abv2/ja/game_day_6_2_ja.png',

    game_day_v2_7_0_ja: 'private/chatbot/gameday/abv2/ja/game_day_7_0_ja.png',
    game_day_v2_7_1_ja: 'private/chatbot/gameday/abv2/ja/game_day_7_1_ja.png',
    game_day_v2_7_2_ja: 'private/chatbot/gameday/abv2/ja/game_day_7_2_ja.png',
};

export const practiceAssetOA = {
    // --- english
    practice_0_0_en: 'private/chatbot/practice/en/practice_0_0_en.png',
    practice_0_1_en: 'private/chatbot/practice/en/practice_0_1_en.png',
    practice_0_2_en: 'private/chatbot/practice/en/practice_0_2_en.png',

    practice_1_0_en: 'private/chatbot/practice/en/practice_1_0_en.png',
    practice_1_1_en: 'private/chatbot/practice/en/practice_1_1_en.png',
    practice_1_2_en: 'private/chatbot/practice/en/practice_1_2_en.png',

    practice_2_0_en: 'private/chatbot/practice/en/practice_2_0_en.png',
    practice_2_1_en: 'private/chatbot/practice/en/practice_2_1_en.png',
    practice_2_2_en: 'private/chatbot/practice/en/practice_2_2_en.png',

    practice_3_0_en: 'private/chatbot/practice/en/practice_3_0_en.png',
    practice_3_1_en: 'private/chatbot/practice/en/practice_3_1_en.png',
    practice_3_2_en: 'private/chatbot/practice/en/practice_3_2_en.png',

    practice_4_0_en: 'private/chatbot/practice/en/practice_4_0_en.png',
    practice_4_1_en: 'private/chatbot/practice/en/practice_4_1_en.png',
    practice_4_2_en: 'private/chatbot/practice/en/practice_4_2_en.png',

    // --- japanese
    practice_0_0_ja: 'private/chatbot/practice/ja/practice_0_0_ja.png',
    practice_0_1_ja: 'private/chatbot/practice/ja/practice_0_1_ja.png',
    practice_0_2_ja: 'private/chatbot/practice/ja/practice_0_2_ja.png',

    practice_1_0_ja: 'private/chatbot/practice/ja/practice_1_0_ja.png',
    practice_1_1_ja: 'private/chatbot/practice/ja/practice_1_1_ja.png',
    practice_1_2_ja: 'private/chatbot/practice/ja/practice_1_2_ja.png',

    practice_2_0_ja: 'private/chatbot/practice/ja/practice_2_0_ja.png',
    practice_2_1_ja: 'private/chatbot/practice/ja/practice_2_1_ja.png',
    practice_2_2_ja: 'private/chatbot/practice/ja/practice_2_2_ja.png',

    practice_3_0_ja: 'private/chatbot/practice/ja/practice_3_0_ja.png',
    practice_3_1_ja: 'private/chatbot/practice/ja/practice_3_1_ja.png',
    practice_3_2_ja: 'private/chatbot/practice/ja/practice_3_2_ja.png',

    practice_4_0_ja: 'private/chatbot/practice/ja/practice_4_0_ja.png',
    practice_4_1_ja: 'private/chatbot/practice/ja/practice_4_1_ja.png',
    practice_4_2_ja: 'private/chatbot/practice/ja/practice_4_2_ja.png',
};

const livesFullAssetOA = {
    lives_full: 'private/chatbot/lives/lives_full.png',
};

const teamMoralePinchAssetOA = {
    // --- english
    pinch_0_0_en: 'private/chatbot/pinch/en/pinch_0_0_en.png',
    pinch_0_1_en: 'private/chatbot/pinch/en/pinch_0_1_en.png',
    pinch_1_0_en: 'private/chatbot/pinch/en/pinch_1_0_en.png',
    pinch_1_1_en: 'private/chatbot/pinch/en/pinch_1_1_en.png',
    pinch_2_0_en: 'private/chatbot/pinch/en/pinch_2_0_en.png',
    pinch_2_1_en: 'private/chatbot/pinch/en/pinch_2_1_en.png',
    pinch_3_0_en: 'private/chatbot/pinch/en/pinch_3_0_en.png',
    pinch_3_1_en: 'private/chatbot/pinch/en/pinch_3_1_en.png',
    pinch_4_0_en: 'private/chatbot/pinch/en/pinch_4_0_en.png',
    pinch_4_1_en: 'private/chatbot/pinch/en/pinch_4_1_en.png',
    // --- japanese
    pinch_0_0_ja: 'private/chatbot/pinch/ja/pinch_0_0_ja.png',
    pinch_0_1_ja: 'private/chatbot/pinch/ja/pinch_0_1_ja.png',
    pinch_1_0_ja: 'private/chatbot/pinch/ja/pinch_1_0_ja.png',
    pinch_1_1_ja: 'private/chatbot/pinch/ja/pinch_1_1_ja.png',
    pinch_2_0_ja: 'private/chatbot/pinch/ja/pinch_2_0_ja.png',
    pinch_2_1_ja: 'private/chatbot/pinch/ja/pinch_2_1_ja.png',
    pinch_3_0_ja: 'private/chatbot/pinch/ja/pinch_3_0_ja.png',
    pinch_3_1_ja: 'private/chatbot/pinch/ja/pinch_3_1_ja.png',
    pinch_4_0_ja: 'private/chatbot/pinch/ja/pinch_4_0_ja.png',
    pinch_4_1_ja: 'private/chatbot/pinch/ja/pinch_4_1_ja.png',
};

const staticOA = {
    ...firstSessionOA,
    ...firstSessionV2OA,
    ...gameDayAssetOA,
    ...gameDayAssetV2OA,
    ...gameDayAsssetsAbOA,
    ...practiceAssetOA,
    ...livesFullAssetOA,
    ...teamMoralePinchAssetOA,
};

export type ReplicantAssetKey = keyof typeof staticOA;
export type FirstSessionAssetKey = keyof typeof firstSessionOA;
export type FirstSessionAssetKeyV2 = keyof typeof firstSessionV2OA;
export type GameDayAssetKey = keyof typeof gameDayAssetOA;
export type GameDayAssetV2Key = keyof typeof gameDayAssetV2OA;
export type GameDayAssetABKey = keyof typeof gameDayAsssetsAbOA;
export type PracticeAssetKey = keyof typeof practiceAssetOA;
export type LivesFullAssetKey = keyof typeof livesFullAssetOA;
export type TeamMoralePinchAssetKey = keyof typeof teamMoralePinchAssetOA;

const chatbotMessageTemplates = renderTemplatesWithAssets(staticOA, {
    staticOA: renderTemplate({
        args: SB.object({
            imageKey: SB.string() as SB.Schema<ReplicantAssetKey>,
            title: SB.string(),
            cta: SB.string(),
        }),

        renderers: {
            line: ({ api, payload, args }) =>
                lineGenericTemplate({
                    payload,
                    title: args.title,
                    cta: args.cta,
                    imageUrl: api.getAssetPath(args.imageKey),
                }),
        },
    }),
    flexText: renderTemplate({
        args: SB.object({
            text: SB.string(),
            cta: SB.string(),
        }),

        renderers: {
            line: ({ api, payload, args }) =>
                lineTextTemplate({
                    payload,
                    text: args.text,
                    cta: args.cta,
                }),
        },
    }),
    default: renderTemplate({
        args: SB.object({
            imageKey: SB.string(),
            title: SB.string(),
            cta: SB.string(),
        }),

        renderers: {
            line: ({ api, payload, args }) =>
                lineGenericTemplate({
                    payload,
                    title: args.title,
                    cta: args.cta,
                    imageUrl: api.getUserAssetUrl(args.imageKey), // String, not typed
                }),
        },
    }),
    imagePlain: renderTemplate({
        args: SB.object({
            imageKey: SB.string() as SB.Schema<ReplicantAssetKey>,
            previewKey: SB.string() as SB.Schema<ReplicantAssetKey>,
        }),

        renderers: {
            line: ({ api, payload, args }) =>
                lineImageTemplate({
                    imageUrl: api.getAssetPath(args.imageKey),
                    previewUrl: api.getAssetPath(args.previewKey),
                }),
        },
    }),
    textPlain: renderTemplate({
        args: SB.object({
            text: SB.string(),
        }),

        renderers: {
            line: ({ api, payload, args }) =>
                plainTextTemplate({
                    text: args.text,
                }),
        },
    }),
    textLink: renderTemplate({
        args: SB.object({}),
        renderers: {
            line: ({ api, payload, args }) => plainLink({ payload }),
        },
    }),
    flexImage: renderTemplate({
        args: SB.object({
            imageKey: SB.string() as SB.Schema<ReplicantAssetKey>,
            text: SB.string(),
            senderName: SB.string(),
        }),

        renderers: {
            line: ({ api, payload, args }) =>
                lineFlexImageTemplate({
                    payload,
                    text: args.text,
                    imageUrl: api.getAssetPath(args.imageKey),
                    senderName: args.senderName,
                }),
        },
    }),
    flexBubbleMessage: renderTemplate({
        args: SB.object({
            imageKey: SB.string() as SB.Schema<ReplicantAssetKey>,
            aspectRatio: SB.string(),
            text: SB.string(),
            title: SB.string().optional(),
            cta: SB.string(),
            senderName: SB.string(),
        }),

        renderers: {
            line: ({ api, payload, args }) =>
                lineBubbleTemplate({
                    payload,
                    text: args.text,
                    title: args?.title,
                    cta: args.cta,
                    imageUrl: api.getAssetPath(args.imageKey),
                    aspectRatio: args.aspectRatio,
                    senderName: args.senderName,
                }),
        },
    }),
    // similar to flexBubbleMessage but fetches user generated asset
    dynamicFlexBubbleMessage: renderTemplate({
        args: SB.object({
            imageKey: SB.string(),
            aspectRatio: SB.string(),
            text: SB.string(),
            title: SB.string().optional(),
            cta: SB.string(),
            senderName: SB.string(),
        }),

        renderers: {
            line: ({ api, payload, args }) =>
                lineBubbleTemplate({
                    payload,
                    text: args.text,
                    title: args?.title,
                    cta: args.cta,
                    imageUrl: api.getUserAssetUrl(args.imageKey),
                    aspectRatio: args.aspectRatio,
                    senderName: args.senderName,
                }),
        },
    }),
});

// https://developers.line.biz/en/reference/messaging-api/#template-messages
function lineGenericTemplate(opts: {
    title: string;
    subtitle?: string;
    imageUrl: string;
    cta: string;
    payload: object;
    lineUserId?: string;
    url?: string;
}) {
    let url = opts.url || LINE_REDIRECT_URL;

    const payload = opts.payload;
    let payloadStr = '';

    if (payload) {
        payloadStr = JSON.stringify(payload);
    }

    if (payloadStr) {
        if (url.indexOf('?') < 0) {
            url += `?payload=${encodeURIComponent(payloadStr)}`;
        } else {
            url += `&payload=${encodeURIComponent(payloadStr)}`;
        }
    }

    return {
        type: 'template',
        altText: opts.title.slice(0, 40),
        template: {
            type: 'buttons',
            thumbnailImageUrl: opts.imageUrl,
            image_aspect_ratio: 'square',
            imageSize: 'cover',
            imageBackgroundColor: '#FFFFFF',
            // Line allows a maximum length of 40 of the title (not used here)
            // Text allows a maximum length of 60
            text: `${opts.title}`.slice(0, 60),
            defaultAction: {
                type: 'uri',
                label: opts.cta,
                uri: url,
            },
            actions: [
                {
                    type: 'uri',
                    label: opts.cta,
                    uri: url,
                },
            ],
        },
    };
}

// https://developers.line.biz/en/reference/messaging-api/#flex-message
function lineTextTemplate(opts: { text: string; cta: string; payload: object; url?: string }) {
    let url = opts.url || LINE_REDIRECT_URL;

    const payload = opts.payload;
    let payloadStr = '';

    if (payload) {
        payloadStr = JSON.stringify(payload);
    }

    if (payloadStr) {
        if (url.indexOf('?') < 0) {
            url += `?payload=${encodeURIComponent(payloadStr)}`;
        } else {
            url += `&payload=${encodeURIComponent(payloadStr)}`;
        }
    }
    return {
        type: 'flex',
        // alttext 400 limit
        altText: opts.text.slice(0, 400),
        contents: {
            type: 'bubble',
            body: {
                type: 'box',
                layout: 'vertical',
                contents: [
                    {
                        type: 'text',
                        text: opts.text,
                        wrap: true,
                        color: '#000000',
                        action: {
                            type: 'uri',
                            label: opts.cta, // Not used
                            uri: url,
                        },
                    },
                    {
                        type: 'button',
                        action: {
                            type: 'uri',
                            label: opts.cta,
                            uri: url,
                        },
                        margin: 'xxl',
                        style: 'secondary', // primary -> white button text
                        color: '#33FF94',
                    },
                ],
            },
        },
    };
}

// https://developers.line.biz/en/reference/messaging-api/#flex-message
function lineFlexImageTemplate(opts: { senderName: string; text: string; payload: object; imageUrl: string }) {
    let url = LINE_REDIRECT_URL;

    const payload = opts.payload;
    let payloadStr = '';

    if (payload) {
        payloadStr = JSON.stringify(payload);
    }

    if (payloadStr) {
        if (url.indexOf('?') < 0) {
            url += `?payload=${encodeURIComponent(payloadStr)}`;
        } else {
            url += `&payload=${encodeURIComponent(payloadStr)}`;
        }
    }
    return {
        sender: {
            name: parseSenderName(opts.senderName),
        },
        type: 'flex',
        // alttext 400 limit
        altText: opts.text.slice(0, 400), // seen in the notification
        contents: {
            type: 'bubble',
            action: {
                type: 'uri',
                uri: url,
            },
            body: {
                type: 'box',
                layout: 'vertical',
                contents: [
                    {
                        type: 'image',
                        url: opts.imageUrl,
                        size: 'full',
                        aspectMode: 'fit',
                        aspectRatio: '1:1',
                        gravity: 'center',
                    },
                ],
                paddingAll: '0px',
            },
        },
    };
}

// image, title, text, button
function lineBubbleTemplate(opts: {
    text: string;
    title?: string;
    cta: string;
    payload: object;
    imageUrl: string;
    aspectRatio: string;
    senderName: string;
}) {
    let url = LINE_REDIRECT_URL;

    const payload = opts.payload;
    let payloadStr = '';

    if (payload) {
        // login token added to url
        payloadStr = JSON.stringify(payload);
    }

    if (payloadStr) {
        if (url.indexOf('?') < 0) {
            url += `?payload=${encodeURIComponent(payloadStr)}`;
        } else {
            url += `&payload=${encodeURIComponent(payloadStr)}`;
        }
    }

    const defaultContent = [
        {
            type: 'box',
            layout: 'vertical',
            margin: 'lg',
            spacing: 'sm',
            contents: [
                {
                    type: 'box',
                    layout: 'baseline',
                    spacing: 'sm',
                    contents: [
                        {
                            type: 'text',
                            text: opts.text,
                            color: '#aaaaaa',
                            size: 'sm',
                            wrap: true,
                        },
                    ],
                },
            ],
        },
    ];

    let textContent;
    if (opts.title) {
        textContent = [
            {
                type: 'text',
                text: opts.title,
                weight: 'bold',
                size: 'xl',
            },
            ...defaultContent,
        ];
    } else {
        // No title
        textContent = defaultContent;
    }

    return {
        sender: {
            name: parseSenderName(opts.senderName),
        },
        type: 'flex',
        // alttext 400 limit
        altText: opts.text.slice(0, 400), // seen in the notification
        contents: {
            type: 'bubble',
            action: {
                type: 'uri',
                uri: url,
            },
            hero: {
                type: 'image',
                size: 'full',
                aspectRatio: opts.aspectRatio,
                aspectMode: 'cover',
                action: {
                    type: 'uri',
                    uri: url,
                },
                url: opts.imageUrl,
            },
            body: {
                type: 'box',
                layout: 'vertical',
                contents: textContent,
            },
            footer: {
                type: 'box',
                layout: 'vertical',
                spacing: 'sm',
                contents: [
                    {
                        type: 'button',
                        style: 'primary',
                        action: {
                            type: 'uri',
                            label: opts.cta,
                            uri: url,
                        },
                        height: 'sm',
                    },
                ],
                flex: 0,
            },
        },
    };
}

function lineImageTemplate(opts: { imageUrl: string; previewUrl: string }) {
    // Just image, no payload or link for wallpaper promos etc.
    return {
        type: 'image',
        originalContentUrl: opts.imageUrl,
        previewImageUrl: opts.previewUrl,
    };
}

// https://developers.line.biz/en/reference/messaging-api/#text-message
function plainTextTemplate(opts: { text: string }) {
    return {
        type: 'text',
        text: opts.text,
    };
}

function plainLink(opts: { payload: object }) {
    let url = LINE_REDIRECT_URL;

    const payload = opts.payload;
    let payloadStr = '';

    if (payload) {
        // login token added to url
        payloadStr = JSON.stringify(payload);
    }

    if (payloadStr) {
        if (url.indexOf('?') < 0) {
            url += `?payload=${encodeURIComponent(payloadStr)}`;
        } else {
            url += `&payload=${encodeURIComponent(payloadStr)}`;
        }
    }

    return {
        type: 'text',
        text: url,
    };
}

export function generateChatbotPayload(args: {
    feature: string;
    api: any;
    tokenOverride?: string;
    subFeature?: string;
}) {
    const { feature, api, tokenOverride, subFeature } = args;
    // const token = tokenOverride ?? (await createLoginTokenMultiUse(api));
    return {
        $channel: 'CHATBOT',
        feature,
        $subFeature: subFeature || null,
        // Uncomment if we want to auth directly in payload again, need to disable richmenu to work
        // login: {
        // userId: hexEncode(api.getUserID()),
        // token,
        // },
    };
}

export { chatbotMessageTemplates };
