import { SB } from '@play-co/replicant';

import { BoosterId, PowerBoosterId } from './booster';

export type AdminItem = SB.ExtractType<typeof adminItemSchema>;
export type AdminId = PowerBoosterId | BoosterId | 'coins' | 'stars' | 'infiniteLives';

const adminItemSchema = SB.object({
    type: SB.string(), // resource, energy, booster, powerBooster
    id: SB.string(),
    amount: SB.int(),
});

// state
//-----------------------------------------------------------------------------
export const adminState = {
    adminMessages: SB.array(adminItemSchema),
};
