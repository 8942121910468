import { IFlow } from '../../../lib/pattern/IFlow';
import NakedPromise from '../../../lib/pattern/NakedPromise';
import app from '../../getApp';
import { trackPuzzleStart } from '../../lib/analytics/puzzle';
import { HomeScreen } from '../../main/home/HomeScreen';
import { LevelGoalPopup } from '../../main/popups/LevelGoal/LevelGoalPopup';
import { GameRewardFlow } from '../GameRewardFlow';

// TODO DISCUSS IF ANALYTICS IS NEEDED
// TODO DISCUSS IF ANALYTICS IS NEEDED
export class TutorialPuzzleFlow implements IFlow {
    private readonly _complete = new NakedPromise<boolean>();

    // impl
    //-------------------------------------------------------------------------
    public async execute() {
        await this._actionHome();
        await this._actionGoal();
        await this._actionPuzzle();

        return this._complete;
    }

    private async _actionComplete(success: boolean) {
        this._complete.resolve(success);
    }

    private async _actionHome() {
        const home = (await app().nav.open('homeScreen', {
            disableButtons: true,
            skipPointer: true,
        })) as HomeScreen;

        // extra claim step for game reward
        await new GameRewardFlow({ homeScreen: home }).execute();

        const tapPromise = new NakedPromise();
        home.puzzleButton.onPress = async () => tapPromise.resolve();
        // point to puzzle button in HomeScreen
        void app().nav.open('tipScreen', {
            type: 'hand',
            motion: 'tap',
            pointerOffset: { x: -30, y: -20 },
            allowInput: true,
            targets: [home.puzzleButton.getBounds()],
        });

        // preload the goal popup once we've spawned the pointer
        void app().nav.preload('levelGoalPopup', {
            level: app().server.state.puzzle.lastLevel + 1,
            isMaxLevel: false,
        });

        await tapPromise;

        void app().nav.preload('puzzleScreen');

        void app().nav.close('tipScreen');
    }

    private async _actionGoal() {
        const playPromise = new NakedPromise();
        const goalPopupId = 'levelGoalPopup';
        const goalPopup = (await app().nav.open(goalPopupId, {
            level: app().server.state.puzzle.lastLevel + 1,
            isMaxLevel: false,
            onOk: () => playPromise.resolve(),
        })) as LevelGoalPopup;

        // point to play button in popup
        void app().nav.open('tipScreen', {
            type: 'hand',
            motion: 'tap',
            pointerOffset: { x: -30, y: -20 },
            allowInput: true,
            targets: [goalPopup.mainButton.getBounds()],
        });
        await playPromise;
        void app().nav.close('tipScreen');
        void app().nav.close(goalPopupId);
    }

    private async _actionPuzzle() {
        // force puzzle start, consumes lives but allows for 0 lives for edge case players
        await app().server.invoke.puzzleBeginForce();
        trackPuzzleStart();
        // navigate to puzzle
        await app().nav.open('puzzleScreen', {});
        void this._actionComplete(true);
    }
}
