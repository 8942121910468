import { ComboGlowAnimation } from '../animations/ComboGlowAnimation';
import { BlockId, blockPropsMap } from '../defs/block';
import { AndroidBlockView } from '../views/blocks/AndroidBlockView';
import { AnimalBlockView } from '../views/blocks/AnimalBlockView';
import { BasicBlockView } from '../views/blocks/BasicBlockView';
import { BombBlockView } from '../views/blocks/BombBlockView';
import { CageBlockView } from '../views/blocks/CageBlockView';
import { CandleBlockView } from '../views/blocks/CandleBlockView';
import { ChainBlockView } from '../views/blocks/ChainBlockView';
import { ChameleonBlockView } from '../views/blocks/ChameleonBlockView';
import { CoffinBlockView } from '../views/blocks/CoffinBlockView';
import { CubeBlockView } from '../views/blocks/CubeBlockView';
import { DogBlockView } from '../views/blocks/DogBlockView';
import { DoorBlockView } from '../views/blocks/DoorBlockView';
import { FireboxBlockView } from '../views/blocks/FireboxBlockView';
import { FuguBlockView } from '../views/blocks/FuguBlockView';
import { FullIceBlockView } from '../views/blocks/FullIceBlockView';
import { HatBlockView } from '../views/blocks/HatBlockView';
import { HazardBlockView } from '../views/blocks/HazardBlockView';
import { KappaBlockView } from '../views/blocks/KappaBlockView';
import { KettleBlockView } from '../views/blocks/KettleBlockView';
import { LockBlockView } from '../views/blocks/LockBlockView';
import { MiniFuguBlockView } from '../views/blocks/MiniFuguBlockView';
import { MysteryBlockView } from '../views/blocks/MysteryBlockView';
import { PantherBlockView } from '../views/blocks/PantherBlockView';
import { PipeBlockView } from '../views/blocks/PipeBlockView';
import { PondBlockView } from '../views/blocks/PondBlockView';
import { PrisonBlockView } from '../views/blocks/PrisonBlockView';
import { RocketBlockView } from '../views/blocks/RocketBlockView';
import { ShellBlockView } from '../views/blocks/ShellBlockView';
import { SirenBlockView } from '../views/blocks/SirenBlockView';
import { SkullBlockView } from '../views/blocks/SkullBlockView';
import { SlimeBlockView } from '../views/blocks/SlimeBlockView';
import { SpiderBlockView } from '../views/blocks/SpiderBlockView';
import { StatueBlockView } from '../views/blocks/StatueBlockView';
import { ThrowerBlockView } from '../views/blocks/ThrowerBlockView';
import { VirusBlockView } from '../views/blocks/VirusBlockView';
import { WallBlockView } from '../views/blocks/WallBlockView';
import { ZombieBlockView } from '../views/blocks/ZombieBlockView';

// view
//-----------------------------------------------------------------------------
export function blockViewAssets(id: BlockId, effects?: boolean): string[] {
    const props = blockPropsMap[id];
    switch (props.type) {
        case 'android':
            return AndroidBlockView.assets();
        case 'animal':
            return AnimalBlockView.assets();
        case 'basic':
            return BasicBlockView.assets(props, effects);
        case 'bomb':
            return [...BombBlockView.assets(effects), ...(effects ? ComboGlowAnimation.assets() : [])];
        case 'cage':
            return CageBlockView.assets();
        case 'candle':
            return CandleBlockView.assets();
        case 'chain':
            return ChainBlockView.assets();
        case 'chameleon':
            return ChameleonBlockView.assets();
        case 'coffin':
            return CoffinBlockView.assets();
        case 'cube':
            return [...CubeBlockView.assets(props, effects), ...(effects ? ComboGlowAnimation.assets() : [])];
        case 'dog':
            return DogBlockView.assets();
        case 'door':
            return DoorBlockView.assets(props);
        case 'firebox':
            return FireboxBlockView.assets(effects);
        case 'fugu':
            return FuguBlockView.assets();
        case 'hat':
            return HatBlockView.assets(props);
        case 'ice':
            return FullIceBlockView.assets();
        case 'hazard':
            return HazardBlockView.assets(effects);
        case 'kappa':
            return KappaBlockView.assets();
        case 'kettle':
            return KettleBlockView.assets();
        case 'lock':
            return LockBlockView.assets();
        case 'miniFugu':
            return MiniFuguBlockView.assets();
        case 'mystery':
            return MysteryBlockView.assets(props);
        case 'panther':
            return PantherBlockView.assets(props);
        case 'pipe':
            return PipeBlockView.assets();
        case 'pond':
            return PondBlockView.assets();
        case 'prison':
            return PrisonBlockView.assets();
        case 'rocket':
            return [...RocketBlockView.assets(effects), ...(effects ? ComboGlowAnimation.assets() : [])];
        case 'siren':
            return SirenBlockView.assets();
        case 'shell':
            return ShellBlockView.assets();
        case 'skull':
            return SkullBlockView.assets();
        case 'slime':
            return SlimeBlockView.assets();
        case 'spider':
            return SpiderBlockView.assets();
        case 'statue':
            return StatueBlockView.assets();
        case 'thrower':
            return ThrowerBlockView.assets();
        case 'virus':
            return VirusBlockView.assets();
        case 'wall':
            return WallBlockView.assets(props);
        case 'zombie':
            return ZombieBlockView.assets();
        case 'randomSpawn':
            return [];
        default:
            throw Error();
    }
}
