import { Sprite, TEXT_GRADIENT } from 'pixi.js';

import { BasicText } from '../../lib/ui/text/BasicText';
import { LevelPopup, LevelPopupOptions } from './LevelPopup';
import app from '../../getApp';

// types
//-----------------------------------------------------------------------------
export type LevelSuccessMaxPopupOptions = Omit<LevelPopupOptions, 'ribbon'>;

const manifest = {
    bg: 'panel.puzzle.win.png',
    wellDoneEN: 'label.well.done.en.png',
    wellDoneJA: 'label.well.done.ja.png',
    character: 'icon.character.pitcher.idle.char2.girl.png',
};

/*
    Success popup
*/
export class LevelSuccessMaxPopup extends LevelPopup {
    public override preload() {
        return [...super.preload(), ...app().resource.loadAssets([...Object.values(manifest)])];
    }

    // @ts-ignore
    public override async spawning(options: LevelSuccessMaxPopupOptions) {
        await super.spawning({
            // onClose: options.onOk,
            ...options,
            ribbon: 'orange',
            okButton: '[buttonContinue]',
        });

        const maxLabel = new BasicText({
            text: '[popupLevelMax]',
            style: {
                fillGradientType: TEXT_GRADIENT.LINEAR_VERTICAL,
                fill: [0xffffff, 0xfeffea],
                fontSize: 24,
                fontWeight: 'bold',
                stroke: 'black',
                strokeThickness: 5,
                lineJoin: 'round',
                align: 'center',
                dropShadow: true,
                dropShadowAngle: Math.PI / 2,
                dropShadowDistance: 2,
            },
        });
        this.baseLayout.addContent({
            maxLabel: {
                content: maxLabel,
                styles: {
                    position: 'centerTop',
                    marginTop: 72,
                },
            },
        });

        const asset = app().settings.language === 'ja' ? manifest.wellDoneJA : manifest.wellDoneEN;
        const wellDone = Sprite.from(asset);
        const bg = Sprite.from(manifest.bg);
        const character = Sprite.from(manifest.character);

        character.scale.set(0.5);

        // spawn
        this.main.addContent({
            bg: {
                content: bg,
                styles: {
                    position: 'center',
                },
            },
            wellDone: {
                content: wellDone,
                styles: {
                    position: 'center',
                    marginTop: -120,
                },
            },
            character: {
                content: character,
                styles: {
                    position: 'center',
                    marginTop: 57,
                    marginLeft: 23,
                },
            },
        });
    }
}
