import { action } from '@play-co/replicant';

import { MutableState, State, SyncActionAPI } from '../defs/replicant';

// actions
//-----------------------------------------------------------------------------
export const tutorialActions = {
    tutorialSetStep: action((state: MutableState, options: { step: number }) => {
        state.tutorial.step = options.step;
    }),

    tutorialComplete: action((state: MutableState, _, api: SyncActionAPI) => {
        state.tutorial.complete = true;
    }),

    tutorialReset: action((state: MutableState, _) => {
        state.tutorial.complete = false;
        state.tutorial.step = 0;
    }),

    // mark a tip as complete
    tutorialTipComplete: action((state: MutableState, options: { id: string }) => {
        state.tips[options.id] = true;
    }),

    // mark puzzle tutorial complete
    tutorialPuzzleComplete: action((state: MutableState, _, api: SyncActionAPI) => {
        state.tutorial.puzzle = true;
    }),
};

// api
//-----------------------------------------------------------------------------
export function isFullTutorialComplete(state: State | MutableState): boolean {
    return state.tutorial.complete;
}
