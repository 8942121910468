import { Sprite } from 'pixi.js';

import { BasicAsyncHandler } from '../../../lib/defs/types';
import { pixiSetInterval } from '../../../lib/pixi/pixiTools';
import { uiSizeToFit } from '../../../lib/pixi/uiTools';
import gameConfig from '../../../replicant/defs/gameConfig';
import { timeFormatCountdown, timeToComponents } from '../../../replicant/util/timeTools';
import { ConsumeButton } from '../../lib/ui/buttons/ConsumeButton';
import { BasicText } from '../../lib/ui/text/BasicText';
import { MarkupText } from '../../lib/ui/text/MarkupText';
import PopupCoinView from '../home/PopupCoinView';
import { BasicPopup, BasicPopupOptions } from './BasicPopup';
import app from '../../getApp';

const HEIGHT = 670;

// types
//-----------------------------------------------------------------------------
export type RefillLivesPopupOptions = {
    onConfirm: BasicAsyncHandler;
    onCoins: BasicAsyncHandler;
    lifeTimeLeft: () => number;
} & BasicPopupOptions;

const manifest = {
    panel: 'panel.refill.lives.png',
    button: 'button.green.png',
};

export class RefillLivesPopup extends BasicPopup {
    private _timer: MarkupText;
    private _lifeTimeLeft: () => number;

    public override preload() {
        return [
            ...super.preload(),
            ...app().resource.loadAssets([...Object.values(manifest), ...ConsumeButton.assets()]),
        ];
    }

    // @ts-ignore
    public override async spawning(options: RefillLivesPopupOptions) {
        void super.spawning({
            onClose: options.onOk,
            ...options,
            header: '[popupRefillLivesTitle]',
            width: 690,
            height: HEIGHT,
        });

        const coinView = new PopupCoinView();
        coinView.onPress = options.onCoins;

        this._lifeTimeLeft = options.lifeTimeLeft;

        const panel = Sprite.from(manifest.panel);

        const maxLives = gameConfig.lives.max;
        const amount = new BasicText({
            text: `${maxLives}`,
            style: {
                fill: 0xffffff,
                stroke: 0x5b001c,
                strokeThickness: 9,
                fontSize: 74,
                fontWeight: 'bold',
            },
        });

        const button = new ConsumeButton({
            image: manifest.button,
            text: '[popupRefillLivesButton]',
            consumeAmount: gameConfig.lives.cost,
            slice: {
                width: 360,
                height: 122,
                left: 30,
                top: 16,
                right: 30,
                bottom: 32,
            },
            style: {
                fill: 'FFF',
                fontSize: 50,
                fontWeight: 'bold',
                lineJoin: 'round',
                fontStyle: 'normal',
                dropShadow: true,
                dropShadowDistance: 1,
            },
        });

        button.onPress = options.onConfirm;

        const timer = this._createTimer();

        // spawn
        this.main.addContent({
            coins: {
                content: coinView,
                styles: {
                    position: 'topCenter',
                    marginTop: -30,
                    marginLeft: -130,
                },
            },
            panel: {
                content: panel,
                styles: {
                    position: 'topCenter',
                    marginTop: 180,
                },
            },
            amount: {
                content: amount,
                styles: {
                    position: 'topCenter',
                    marginTop: 238,
                },
            },
            description: {
                content: timer,
                styles: {
                    position: 'topCenter',
                    marginTop: 404,
                },
            },
            button: {
                content: button,
                styles: {
                    position: 'bottomCenter',
                    marginBottom: 30,
                },
            },
        });
    }

    private _createTimer() {
        this._timer = new MarkupText({
            text: ``,
            style: {
                fill: '#3E4047',
                lineJoin: 'round',
                fontWeight: 'bold',
                fontSize: 22,
            },
        });

        const updateTimer = () => {
            const remaining = this._lifeTimeLeft();
            const parsed = timeFormatCountdown(timeToComponents(remaining > 0 ? remaining : 0));
            this._timer.text = `[popupRefillLivesText|<style fill="#AF1342">${parsed}</>]`;
            uiSizeToFit(this._timer, 300, 60);
        };

        updateTimer();
        pixiSetInterval(this._timer, updateTimer, 1);

        return this._timer;
    }
}
