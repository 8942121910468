import { SB } from '@play-co/replicant';

import { getCreativeText } from '../chatbot/chatbotTexts';
import { chatbotMessageTemplates, generateChatbotPayload, LivesFullAssetKey } from '../chatbot/messageTemplates';
import gameConfig from '../defs/gameConfig';
import { MutableState, ScheduledActionAPI, State, SyncActionAPI } from '../defs/replicant';
import settings, { LanguageId } from '../defs/settings';
import { numberClamp } from '../util/mathTools';
import { getAbTest } from '../util/replicantTools';

// constants
//-----------------------------------------------------------------------------
const livesFullEvent = 'livesFull';

// state
//-----------------------------------------------------------------------------
export const livesState = {
    // lives
    lives: SB.object({
        count: SB.int().default(gameConfig.lives.max),
        time: SB.int().default(0),
        infiniteExpiresIn: SB.int(),
    }),
};

// actions
//-----------------------------------------------------------------------------

// scheduled actions
//-----------------------------------------------------------------------------
export const livesScheduledActionsSchema = {
    livesFull: SB.object({}),
};
export const livesScheduledActions = {
    // when lives are full
    livesFull: (state: State, args: {}, api: ScheduledActionAPI) => {
        sendFullLives(state, api);
    },
};

// api
//-----------------------------------------------------------------------------
export function livesGet(state: State, now: number): number {
    // get state lives
    const lives = state.lives.count;

    // get interval lives
    const intervalLives = Math.max(Math.floor((now - state.lives.time) / gameConfig.lives.interval), 0);
    // return total livee
    return numberClamp(lives + intervalLives, 0, gameConfig.lives.max);
}

export function livesSet(state: MutableState, lives: number, now: number) {
    // update lives count
    state.lives.count = numberClamp(lives, 0, gameConfig.lives.max);

    // reset regen
    if (state.lives.count === gameConfig.lives.max || state.lives.count === 0) {
        state.lives.time = state.lives.count === 0 ? now : now - gameConfig.lives.interval;
        return;
    }

    // reset refill time to include only most recent interval
    state.lives.time = now - ((now - state.lives.time) % gameConfig.lives.interval);
}

export function livesAdd(state: MutableState, lives: number, now: number) {
    livesSet(state, livesGet(state, now) + lives, now);
}

export function livesTimeToFull(state: State, now: number): number {
    const fullTime = (gameConfig.lives.max - state.lives.count) * gameConfig.lives.interval + (state.lives.time || now);
    return fullTime - now;
}

export function livesTimeToNext(state: State, now: number): number {
    const totalLives = livesGet(state, now);
    if (totalLives >= gameConfig.lives.max) return 0;

    // get regen lives not added to state yet and subtract state count to calculate next regen
    const regenerated = totalLives - state.lives.count;
    const interval = gameConfig.lives.interval;
    const next = interval * regenerated + (state.lives.time || now);
    return interval - (now - next);
}

export function isInfiniteLivesActive(state: State, now: number): boolean {
    return now < state.lives.infiniteExpiresIn;
}

export function getInifniteLivesTimeLeft(state: State, now: number): number {
    return state.lives.infiniteExpiresIn - now;
}

export function addInfiniteLives(state: MutableState, durationInMS: number, now: number) {
    if (isInfiniteLivesActive(state, now)) {
        // just add to current time
        state.lives.infiniteExpiresIn += durationInMS;
    } else {
        // reset
        state.lives.infiniteExpiresIn = now + durationInMS;
    }
}

export function sendFullLives(state: State, api: ScheduledActionAPI | SyncActionAPI) {
    const lang = state.language as LanguageId;
    const creativeText = getCreativeText(lang, 'lives_full', api.math.random);
    const isEn = lang === 'en';
    const assetId = 'lives_full' as LivesFullAssetKey;
    const preFilledName = isEn ? settings.defaultNameEN : settings.defaultNameJA;

    // Default aspectRatio 1:1
    const aspectRatio = '3:2';
    api.chatbot.sendMessage(
        state.id,
        chatbotMessageTemplates.flexBubbleMessage({
            args: {
                imageKey: assetId,
                aspectRatio,
                text: creativeText.text,
                cta: creativeText.cta,
                senderName: state.name ? state.name : preFilledName,
            },
            payload: {
                ...generateChatbotPayload({ feature: 'lives_full', api }),
                $creativeAssetID: assetId,
            },
        }),
    );
}

// events
//-----------------------------------------------------------------------------
export function onLivesInit(api: SyncActionAPI, state: MutableState) {
    // reset lives full event
    api.scheduledActions.unschedule(livesFullEvent);
}

export function onLivesExit(api: ScheduledActionAPI, state: State) {
    // disabled for now
    return;
    // get time remaining to full lives
    const remaining = livesTimeToFull(state, api.date.now());

    // if positive, schedule lives full event
    if (remaining > 0) {
        api.scheduledActions.schedule.livesFull({
            args: {},
            notificationId: livesFullEvent,
            delayInMS: remaining,
        });
    }
}
