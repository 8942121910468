import type { Entity, QueriesObject, QueryResults, System } from '@play-co/odie';

import { BasicHandler } from '../../../../lib/defs/types';
import { SlimeEffect } from '../actions/effects/SlimeEffect';
import { BlockComponent } from '../components/BlockComponent';
import { PhaseComponent } from '../components/PhaseComponent';
import { SlimeBlockComponent } from '../components/SlimeBlockComponent';
import { config } from '../defs/config';
import { PhaseId } from '../defs/types';
import { SlimeBlockEntity } from '../entities/BlockEntity';
import type { GameScene } from '../GameScene';
import { matchFindGroups } from '../util/matchTools';

/*
    handles slime block specifics
*/
export class SlimeBlockSystem implements System {
    // constants
    //-------------------------------------------------------------------------
    public static readonly NAME = 'blockSlime';
    public static Queries: QueriesObject = {
        block: {
            components: [BlockComponent, SlimeBlockComponent],
            removed: true,
        },
        phase: {
            components: [PhaseComponent],
            modified: true,
        },
    };

    // fields
    //-------------------------------------------------------------------------
    // injected
    public scene!: GameScene;
    public queries!: QueryResults;
    //NOTE: state in system bad cuz ecs or something
    // state
    private _slimeDestroyed = false;
    // maps
    private _phaseHandlers: { [key in PhaseId]?: BasicHandler } = {
        input: this._onInputPhase,
    };

    // impl
    //-------------------------------------------------------------------------
    public modifiedQuery(entity: Entity, component: PhaseComponent, properties: any) {
        // handle phase change
        if (properties.phase) this._phaseHandlers[component.phase]?.call(this);
    }

    public removedFromQuery() {
        // set slime destroyed state
        this._slimeDestroyed = true;
    }

    // private: events
    //-------------------------------------------------------------------------
    private _onInputPhase() {
        const phase = this.scene.sessionEntity.c.phase;

        // if move input and after first round
        if (phase.moved && !this._slimeDestroyed && phase.round > 0) {
            this._activateSlime();
        }

        // reset destroyed state
        this._slimeDestroyed = false;
    }

    // private: actions
    //-------------------------------------------------------------------------
    private _activateSlime() {
        const slimes: SlimeBlockEntity[] = [];

        // collect slimes
        this.queries.block.forEach((entity: SlimeBlockEntity) => slimes.push(entity));

        // if slimes found
        if (slimes.length > 0) {
            // get match groups
            const matchGroups = matchFindGroups(this.scene);

            // require multiple match groups exist or the last one has matchable count excluding overlayed blocks
            if (
                matchGroups.length > 1 ||
                (matchGroups.length === 1 &&
                    matchGroups[0].matches.reduce((count, match) => count + (match.overlay ? 0 : 1), 0) >
                        config.blocks.basic.match)
            ) {
                // execute slime effect
                void new SlimeEffect(this.scene, { slimes }).execute();

                return true;
            }
        }

        return false;
    }
}
