import type { Component, Entity, QueriesObject, QueryResults, System } from '@play-co/odie';

import { Graph } from '../../../../lib/pattern/Graph';
import { PondBlockComponent } from '../components/PondBlockComponent';
import { config } from '../defs/config';
import { despawnBlockEntity, PondBlockEntity } from '../entities/BlockEntity';
import type { GameScene } from '../GameScene';
import { blockIterateNeighborsToGraph, blockNearestNeighbors } from '../util/blockTools';
import { mapGetPan } from '../util/mapTools';
import { PondBlockLayout, PondBlockLayoutType } from '../views/blocks/PondBlockView';
import app from '../../../getApp';

/*
    handles pond block specifics
*/
export class PondBlockSystem implements System {
    public static readonly NAME = 'blockPond';
    public static Queries: QueriesObject = {
        blockPond: {
            components: [PondBlockComponent],
            modified: true,
        },
    };

    // fields
    //-------------------------------------------------------------------------
    // injected
    public scene!: GameScene;
    public queries!: QueryResults;

    public start() {
        this._layoutPond();
    }

    public modifiedQuery(entity: Entity, component: Component, properties: any) {
        if (properties.damagePondLink) {
            const pondEntity = entity as PondBlockEntity;
            const pondGraph = this._getPondGraph(pondEntity);

            // Damage pond block
            void this._entityBreakEffect(pondEntity);

            // Check if there are any active ponds
            const isActive = !!pondGraph.toArray().find((e) => e.c.blockPond.damage < config.blocks.pond.damage);

            if (!isActive) {
                // Mark all pond entities as destruction in progress
                pondGraph.toArray().forEach((e) => (e.c.blockPond.destructionInProgress = true));

                // Then do iterative async staggered destruction
                void this._destroyPondFromEntity(pondEntity, pondGraph);
            }
        }
    }

    private _layoutPond() {
        this.queries.blockPond.forEach((entity: PondBlockEntity) => {
            const pondNeighbors = this._getPondNearestNeighbours(entity);
            const numNeighbors = pondNeighbors.reduce((acc, entity) => acc + (entity ? 1 : 0), 0);
            const [top, right, bottom, left] = pondNeighbors;

            let layout!: PondBlockLayoutType;

            if (numNeighbors === 0) {
                // Single block
                layout = PondBlockLayout.Single;
            } else if (numNeighbors === 1) {
                // End block
                if (top) {
                    layout = PondBlockLayout.EndBottom;
                } else if (bottom) {
                    layout = PondBlockLayout.EndTop;
                } else if (left) {
                    layout = PondBlockLayout.EndRight;
                } else if (right) {
                    layout = PondBlockLayout.EndLeft;
                }
            } else if (numNeighbors === 2) {
                // Horizontal, Vertical or Corner block
                if (top && bottom) {
                    layout = PondBlockLayout.Vertical;
                } else if (left && right) {
                    layout = PondBlockLayout.Horizontal;
                } else if (left && top) {
                    layout = PondBlockLayout.CornerBottomRight;
                } else if (top && right) {
                    layout = PondBlockLayout.CornerBottomLeft;
                } else if (right && bottom) {
                    layout = PondBlockLayout.CornerTopLeft;
                } else if (bottom && left) {
                    layout = PondBlockLayout.CornerTopRight;
                }
            } else if (numNeighbors === 3) {
                // T Section block
                if (left && top && right) {
                    layout = PondBlockLayout.TSectionBottom;
                } else if (top && right && bottom) {
                    layout = PondBlockLayout.TSectionLeft;
                } else if (right && bottom && left) {
                    layout = PondBlockLayout.TSectionTop;
                } else if (bottom && left && top) {
                    layout = PondBlockLayout.TSectionRight;
                }
            } else if (numNeighbors === 4) {
                // Cross block
                layout = PondBlockLayout.Cross;
            }

            entity.c.blockPond.layout = layout;
        });
    }

    private _getPondNearestNeighbours(entity: PondBlockEntity): PondBlockEntity[] {
        return blockNearestNeighbors(
            this.scene.sessionEntity.c.map,
            entity,
            (neighbor) => entity.c.block.blockId === neighbor.c.block.blockId,
        ) as PondBlockEntity[];
    }

    private _getPondGraph(entity: PondBlockEntity): Graph<PondBlockEntity> {
        return blockIterateNeighborsToGraph(
            this.scene.sessionEntity.c.map,
            entity,
            (neighbor) => entity.c.block.blockId === neighbor.c.block.blockId,
        );
    }

    private async _destroyPondFromEntity(entity: PondBlockEntity, pondGraph: Graph<PondBlockEntity>) {
        const phase = this.scene.sessionEntity.c.phase;
        const fadePromises: Promise<void>[] = [];
        const pondEntities = pondGraph.toArray();

        phase.activePush();

        pondEntities.forEach((pond) =>
            fadePromises.push(pond.c.blockPond.view.fadeOut().then(() => this._entityBreakEffect(pond))),
        );

        await Promise.all(fadePromises);
        await phase.activePop();
    }

    private async _entityBreakEffect(entity: PondBlockEntity) {
        const position = entity.c.position.mapPosition;

        if (entity.c.blockPond.destructionInProgress) {
            entity.c.blockPond.view.onDestroy();
            despawnBlockEntity(this.scene, entity);
        } else {
            await entity.c.blockPond.view.onDamage();
            void app().sound.play('puzzle-bubble-destroy.mp3', { pan: mapGetPan(position) });
        }
    }
}
