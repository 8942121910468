import * as lodash from 'lodash';
import { Ticker } from 'pixi.js';

import { sleep } from '../../replicant/util/jsTools';

// types
//-----------------------------------------------------------------------------
interface IListener {
    step: () => void;
    reset: () => void;
}

// support classes
//-----------------------------------------------------------------------------
class Listener<T> implements IListener {
    // fields
    //-------------------------------------------------------------------------
    // input
    private _getter: () => T;
    private _listener: (getter: T) => void;
    // state
    private _last: T;

    // init
    //-------------------------------------------------------------------------
    constructor(getter: () => T, listener: (getter: T) => void) {
        this._getter = getter;
        this._listener = listener;
    }

    // impl
    //-------------------------------------------------------------------------
    public step() {
        // get value
        const value = this._getter();

        // if changed since last step
        if (!lodash.isEqual(value, this._last)) {
            // update last value
            this._last = value;

            // notify
            this._listener(value);
        }
    }

    public reset() {
        this._last = undefined;
    }
}

/*
    observes updates
*/
export default class UpdateObserver {
    // fields
    //-------------------------------------------------------------------------
    // state
    private _listeners: IListener[] = [];
    private _paused = false;
    // listeners
    private _stepListener = this._step.bind(this);

    // properties
    //-------------------------------------------------------------------------
    public set pause(value: boolean) {
        this._paused = value;
    }

    // api
    //-------------------------------------------------------------------------
    public listen<T>(getter: () => T, listener: (getter: T) => void): UpdateObserver {
        // create and add new listener
        this._listeners.push(new Listener<T>(getter, listener));
        return this;
    }

    public unlisten() {
        this._listeners = [];
    }

    public start(): UpdateObserver {
        // reset
        this._reset();

        // initial step
        this._step();

        // register frame step event
        Ticker.system.add(this._stepListener);

        return this;
    }

    public stop() {
        // unregister frame step event
        Ticker.system.remove(this._stepListener);
    }

    // auto stop when no longer alive
    public attach(alive: () => boolean): UpdateObserver {
        void sleep(0).then(() => this.listen(alive, (alive) => !alive && this.stop()));
        return this;
    }

    // private
    //-------------------------------------------------------------------------
    private _step() {
        // if not paused step listeners
        if (!this._paused) {
            for (const listener of this._listeners) {
                listener.step();
            }
        }
    }

    private _reset() {
        // reset  listeners
        for (const listener of this._listeners) {
            listener.reset();
        }
    }
}
