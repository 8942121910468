import type { Component, Entity2D } from '@play-co/odie';

import { AndroidBlockProps, CountBlockOptionDef } from '../defs/block';
import { AndroidBlockView } from '../views/blocks/AndroidBlockView';

// types
//-----------------------------------------------------------------------------
export type AndroidBlockComponentOptions = {
    props: AndroidBlockProps;
    option?: number;
};

/*
    android block component
*/
export class AndroidBlockComponent implements Component {
    // constants
    //-------------------------------------------------------------------------
    static readonly NAME = 'blockAndroid';

    // fields
    //-------------------------------------------------------------------------
    // injected
    public entity!: Entity2D;
    // scene
    public view!: AndroidBlockView;
    public props!: AndroidBlockProps;

    // properties
    //-------------------------------------------------------------------------
    // count
    public get count(): number {
        return this.view.count;
    }

    public set count(value: number) {
        // update view
        this.view.count = value;
    }

    // current option
    public get option(): number {
        const index = (this.props.options as CountBlockOptionDef[]).findIndex((option) => this.count === option.count);
        return index >= 0 ? index : 0;
    }

    // impl
    //-------------------------------------------------------------------------
    public init(options: AndroidBlockComponentOptions): void {
        this.props = options.props;

        // get option def
        const optionDef = options.props.options[options.option || 0] as CountBlockOptionDef;

        // create view
        this.view = new AndroidBlockView(options.props);

        // set initial count
        this.count = optionDef.count;
    }
}
