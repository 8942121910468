import { Container, Sprite } from 'pixi.js';

import { BasicHandler, PositionType } from '../../../../lib/defs/types';
import { IAnimation } from '../../../../lib/pattern/IAnimation';
import { tween } from '../../../../lib/util/tweens';
import app from '../../../getApp';

// types
//-----------------------------------------------------------------------------
export type DartAnimationOptions = {
    position: PositionType;
};

// constants
//-----------------------------------------------------------------------------
const manifest = {
    bat: 'fx.dart.bat.png',
    smack: 'fx.dart.smack.png',
};

/*
    dart booster animation
*/
export class DartAnimation extends Container implements IAnimation {
    // fields
    //-------------------------------------------------------------------------
    // events
    public onImpact: BasicHandler;
    // input
    private _position: PositionType;
    // scene
    private _bat!: Sprite;
    private _smack!: Sprite;

    // init
    //-------------------------------------------------------------------------
    constructor(options: DartAnimationOptions) {
        super();
        this._position = options.position;
    }

    // init
    //-------------------------------------------------------------------------
    static assets(): string[] {
        return Object.values(manifest);
    }

    // impl
    //-------------------------------------------------------------------------
    public async start(): Promise<void> {
        this.x = this._position.x + 120;
        this.y = this._position.y + 50;

        this._bat = Sprite.from(manifest.bat);
        this._bat.anchor.x = 0.5;
        this._bat.anchor.y = 0.8;
        this._bat.scale.x = 0;
        this._bat.scale.y = 0;
        this._bat.rotation = 0.2;
        this.addChild(this._bat);

        await this._bat
            .animate()
            .add(
                this._bat,
                {
                    y: -60,
                    rotation: 1.3,
                },
                0.7,
                tween.pow2Out,
            )
            .and(
                this._bat.scale,
                {
                    x: 1,
                    y: 1,
                },
                tween.backOut(),
            );

        void app().sound.play('wooden_bat.ogg');

        await this._bat.animate().add(
            this._bat,
            {
                rotation: -1.5,
                y: 0,
            },
            0.3,
            tween.pow2In,
        );

        this.onImpact?.();

        this._smack = Sprite.from(manifest.smack);
        this._smack.anchor.x = 0.5;
        this._smack.anchor.y = 0.5;
        this._smack.x = -88;
        this._smack.y = -10;
        this.addChild(this._smack);

        this._smack.animate().wait(0.4).add(
            this._smack.scale,
            {
                x: 0,
                y: 0,
            },
            0.1,
            tween.pow2Out,
        );

        await this._bat
            .animate()
            .add(
                this._bat,
                {
                    y: -40,
                    rotation: -0.8,
                },
                0.4,
                tween.pow2Out,
            )
            .add(
                this._bat.scale,
                {
                    x: 0,
                    y: 0,
                },
                0.3,
                tween.pow2In,
            );
    }

    public stop() {
        this.removeChild(this._smack);
        this.removeChild(this._bat);
    }
}
