import { IFlow } from '../../lib/pattern/IFlow';
import NakedPromise from '../../lib/pattern/NakedPromise';
import { isInfiniteLivesActive, livesGet } from '../../replicant/components/lives';
import { PowerBoosterId, powerBoosterToProductId } from '../../replicant/defs/booster';
import gameConfig from '../../replicant/defs/gameConfig';
import { coinsProductDefs } from '../../replicant/defs/product';
import { integerRandom } from '../../replicant/util/mathTools';
import app from '../getApp';
import { PinchEventTrigger, trackPinchConversion, trackPinchEvent } from '../lib/analytics/pinch';
import { trackPuzzleStart } from '../lib/analytics/puzzle';
import { LevelGoalPopup } from '../main/popups/LevelGoal/LevelGoalPopup';
import { RefillLivesFlow } from './RefillLivesFlow';

/*
    puzzle play command
*/
export class PuzzlePlayFlow implements IFlow {
    private readonly _complete = new NakedPromise<{ forceScene?: 'homeScreen' }>();

    private _levelOverride: number;

    // used to skip popup and launch directly into puzzle, for example on crossplay redirect with pinch finishing in native launch sequence
    private _forceStart: boolean;
    private _forcePowerBoosters: PowerBoosterId[];
    // impl
    //-------------------------------------------------------------------------
    constructor(opts: { forceStart?: boolean; forcePowerBoosters?: PowerBoosterId[] }) {
        this._forceStart = !!opts.forceStart;
        this._forcePowerBoosters = opts?.forcePowerBoosters;
    }

    async execute() {
        const lastLevel = app().server.state.puzzle.lastLevel;
        // if at last completed level is max, select random level
        // TODO discuss and adjust the flow and generate everything on backend if different puzzles beecomes valuable
        if (lastLevel >= gameConfig.puzzle.levels.max) {
            this._levelOverride = integerRandom(10, gameConfig.puzzle.levels.max, Math.random);
        }

        if (this._forceStart) {
            void this._actionPuzzle(this._levelOverride, this._forcePowerBoosters ?? []);
        } else {
            void this._actionPlay();
        }

        return this._complete;
    }

    private async _actionComplete(opts: { forceScene?: 'homeScreen' }) {
        this._complete.resolve(opts);
    }

    // private: actions
    //-------------------------------------------------------------------------
    private async _actionPlay() {
        const now = app().server.now();
        const livesAvailable = livesGet(app().server.state, now);

        const goalPopupId = 'levelGoalPopup';
        const lastLevel = app().server.state.puzzle.lastLevel;
        const level = this._levelOverride ? this._levelOverride : lastLevel + 1;
        const popup = (await app().nav.open(goalPopupId, {
            level,
            isMaxLevel: !!this._levelOverride,
            underlay: 0.6,
            onOk: async () => {
                await app().nav.close(goalPopupId);
                if (livesAvailable > 0 || isInfiniteLivesActive(app().server.state, now)) {
                    void this._actionPuzzle(this._levelOverride, popup.powerBoosters);
                } else {
                    const boughtLives = await new RefillLivesFlow({
                        autoBuy: true,
                        crossplayPowerBoosters: popup.powerBoosters,
                    }).execute();
                    if (boughtLives) {
                        void this._actionPuzzle(this._levelOverride, popup.powerBoosters);
                    } else {
                        void this._actionPlay();
                    }
                }
            },
            onClose: async () => {
                await app().nav.close(goalPopupId);
                void this._actionComplete({ forceScene: 'homeScreen' });
            },
            onBuyBooster: this._onBuyBooster.bind(this),
        })) as LevelGoalPopup;

        void app().nav.preload('puzzleScreen');
    }

    private async _actionPuzzle(levelOverride: number | undefined, powerBoosters: PowerBoosterId[]) {
        // server begin puzzle
        await app().server.invoke.puzzleBegin({ levelOverride, powerBoosters });

        trackPuzzleStart();

        // navigate to puzzle
        await app().nav.open('puzzleScreen', {
            powerBoosters,
        });
        void this._actionComplete({});
    }

    // private: events
    //-------------------------------------------------------------------------
    private async _onBuyBooster(id: PowerBoosterId): Promise<void> {
        let reOpenPopup = true;
        let shopPinch = false;
        let coinsUsed = false;
        while (reOpenPopup) {
            const reOpenPromise = new NakedPromise<{ reOpen: boolean; pinched?: boolean; coinsConsumed?: boolean }>();
            const popup = 'purchasePopup';
            await app().nav.open(popup, {
                id,
                onOk: async () => {
                    await app().nav.close(popup);
                    // purchase flow
                    const productId = powerBoosterToProductId(id);
                    const def = coinsProductDefs[productId];
                    const state = app().server.state;
                    if (app().game.player.coins >= def.getCost(state)) {
                        await app().server.invoke.coinsPurchase({ id: productId });
                        reOpenPromise.resolve({ reOpen: false, coinsConsumed: true });
                    } else {
                        trackPinchEvent({ resource: 'coins', trigger: `purchase_${productId}` as PinchEventTrigger });
                        await this._openShop();
                        reOpenPromise.resolve({ reOpen: true, pinched: true });
                    }
                },
                onClose: async () => {
                    await app().nav.close(popup);
                    reOpenPromise.resolve({ reOpen: false });
                },
                onCoins: async () => {
                    await app().nav.close(popup);
                    await this._openShop();
                    reOpenPromise.resolve({ reOpen: true });
                },
            });

            void app().nav.preload('shop');
            const { reOpen, pinched, coinsConsumed } = await reOpenPromise;

            reOpenPopup = reOpen;
            shopPinch = !!pinched || shopPinch;
            coinsUsed = !!coinsConsumed || coinsUsed;
        }

        // pinched first and then bought coins and consumed them -> conversion
        if (shopPinch && coinsUsed) {
            const productId = powerBoosterToProductId(id);
            // previosly did not have enough coins, so this is a conversion
            trackPinchConversion({
                trigger: `purchase_${productId}`,
                action: 'purchase',
                resource: 'coins',
                feature: 'puzzle_booster',
                subFeature: `puzzle_booster_${productId}`,
                wasSurfacedByPinch: true,
            });
        }

        // return to play flow
        void this._actionPlay();
    }

    private async _openShop() {
        const closePromise = new NakedPromise();
        void app().nav.open('shop', { onClose: closePromise.resolve });
        await closePromise;
    }
}
