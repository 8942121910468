import { Container, Sprite } from 'pixi.js';

import { AxisId, PondBlockProps } from '../../defs/block';
import { config } from '../../defs/config';
import { blockPositionView } from '../../util/blockTools';

export const PondBlockLayout = {
    Single: 'SINGLE',
    Horizontal: 'HORIZONTAL',
    Vertical: 'VERTICAL',
    EndTop: 'END_TOP',
    EndRight: 'END_RIGHT',
    EndBottom: 'END_BOTTOM',
    EndLeft: 'END_LEFT',
    CornerTopLeft: 'CORNER_TOP_LEFT',
    CornerTopRight: 'CORNER_TOP_RIGHT',
    CornerBottomLeft: 'CORNER_BOTTOM_LEFT',
    CornerBottomRight: 'CORNER_BOTTOM_RIGHT',
    TSectionTop: 'T_SECTION_TOP',
    TSectionRight: 'T_SECTION_RIGHT',
    TSectionBottom: 'T_SECTION_BOTTOM',
    TSectionLeft: 'T_SECTION_LEFT',
    Cross: 'CROSS',
};
export type PondBlockLayoutType = (typeof PondBlockLayout)[keyof typeof PondBlockLayout];

type SectionType = AxisId | EndId | CornerId | JunctionId | 'single';

type EndId = 'endTop' | 'endBottom' | 'endLeft' | 'endRight';

type CornerId = 'cornerTopLeft' | 'cornerTopRight' | 'cornerBottomLeft' | 'cornerBottomRight';

type JunctionId = 'tsectionTop' | 'tsectionBottom' | 'tsectionLeft' | 'tsectionRight' | 'cross';

const manifest: Record<SectionType, string> = {
    single: 'block.pond.single.png',
    vertical: 'block.pond.vertical.png',
    horizontal: 'block.pond.horizontal.png',
    endTop: 'block.pond.end.top.png',
    endBottom: 'block.pond.end.bottom.png',
    endLeft: 'block.pond.end.left.png',
    endRight: 'block.pond.end.right.png',
    cornerTopLeft: 'block.pond.top.left.png',
    cornerTopRight: 'block.pond.top.right.png',
    cornerBottomLeft: 'block.pond.bottom.left.png',
    cornerBottomRight: 'block.pond.bottom.right.png',
    tsectionTop: 'block.pond.t.top.png',
    tsectionBottom: 'block.pond.t.bottom.png',
    tsectionLeft: 'block.pond.t.left.png',
    tsectionRight: 'block.pond.t.right.png',
    cross: 'block.pond.cross.png',
};
const manifestEtc = {
    lotus: 'fx.pond.lotus.leaf.png',
};

const TILE_SIZE = config.tile.size;
const HALF_TILE_SIZE = TILE_SIZE / 2;
const Y_OFFSET = 0;

export const isLineLayout = (layout: PondBlockLayoutType): boolean =>
    [PondBlockLayout.Horizontal, PondBlockLayout.Vertical].includes(layout);

const isVerticalLineLayout = (layout: PondBlockLayoutType): boolean => layout === PondBlockLayout.Vertical;

const isEndLayout = (layout: PondBlockLayoutType): boolean =>
    [PondBlockLayout.EndTop, PondBlockLayout.EndBottom, PondBlockLayout.EndLeft, PondBlockLayout.EndRight].includes(
        layout,
    );

const isCornerLayout = (layout: PondBlockLayoutType): boolean =>
    [
        PondBlockLayout.CornerTopLeft,
        PondBlockLayout.CornerTopRight,
        PondBlockLayout.CornerBottomLeft,
        PondBlockLayout.CornerBottomRight,
    ].includes(layout);

const isJunctionLayout = (layout: PondBlockLayoutType): boolean =>
    [
        PondBlockLayout.TSectionTop,
        PondBlockLayout.TSectionRight,
        PondBlockLayout.TSectionBottom,
        PondBlockLayout.TSectionLeft,
        PondBlockLayout.Cross,
    ].includes(layout);

const getEndId = (layout: PondBlockLayoutType): EndId | null => {
    switch (layout) {
        case PondBlockLayout.EndTop:
            return 'endTop';
        case PondBlockLayout.EndRight:
            return 'endRight';
        case PondBlockLayout.EndBottom:
            return 'endBottom';
        case PondBlockLayout.EndLeft:
            return 'endLeft';
        default:
            return null;
    }
};

const getCornerId = (layout: PondBlockLayoutType): CornerId | null => {
    switch (layout) {
        case PondBlockLayout.CornerTopLeft:
            return 'cornerTopLeft';
        case PondBlockLayout.CornerTopRight:
            return 'cornerTopRight';
        case PondBlockLayout.CornerBottomLeft:
            return 'cornerBottomLeft';
        case PondBlockLayout.CornerBottomRight:
            return 'cornerBottomRight';
        default:
            return null;
    }
};

const getJunctionId = (layout: PondBlockLayoutType): JunctionId | null => {
    switch (layout) {
        case PondBlockLayout.TSectionTop:
            return 'tsectionTop';
        case PondBlockLayout.TSectionRight:
            return 'tsectionRight';
        case PondBlockLayout.TSectionBottom:
            return 'tsectionBottom';
        case PondBlockLayout.TSectionLeft:
            return 'tsectionLeft';
        case PondBlockLayout.Cross:
            return 'cross';
        default:
            return null;
    }
};

/*
    pond block view
*/
export class PondBlockView extends Container {
    private _scaleView = new Container();
    private _damage = 0;
    private _layout0: PondBlockLayoutType = PondBlockLayout.Horizontal;
    private _block?: Sprite;
    //private _ripple!: WaterRipples;
    private _lotus!: Sprite;

    public static assets(): string[] {
        return [...Object.values(manifest), ...Object.values(manifestEtc)];
    }

    constructor(props: PondBlockProps) {
        super();
        this.addChild(this._scaleView);
        //this._scaleView.scale.x = this._scaleView.scale.y = 1.01;
        this._updateLayout();
        blockPositionView(this, props);
        this._scaleView.x += 8;
        this._scaleView.y += 7;
    }

    public onTap() {
        //this._ripple.triggerLargeRipple();
    }

    public async onDamage() {
        //this._ripple.triggerLargeRipple();
        this._lotus.visible = false;
    }

    public onDestroy() {
        //this._ripple.removeRipples();
        this._lotus.removeSelf();
    }

    public async fadeOut() {
        // await gsap.to(this, {
        //     duration: 0.4,
        //     alpha: 0,
        //     ease: 'quad.inOut',
        // });
    }

    public get damage(): number {
        return this._damage;
    }

    public set damage(value: number) {
        if (this._damage === value) {
            return;
        }

        this._damage = value;
        this._updateLotus(false);
    }

    private _updateLotus(animated = false) {
        if (this._damage === 0) {
            this._lotus.visible = true;
        } else {
            this._lotus.visible = false;
        }
    }

    public get layout0(): PondBlockLayoutType {
        return this._layout0;
    }

    public set layout0(pondLayout: PondBlockLayoutType) {
        if (this._layout0 === pondLayout) {
            return;
        }

        this._layout0 = pondLayout;

        this._updateLayout();
    }

    //   private _createRipples() {
    //     const size = config.tile.size * 0.75;
    //     this._ripple = new WaterRipples(this._scaleView, size, size);
    //   }

    private _createLotus() {
        this._lotus = this._scaleView.addChild(Sprite.from(manifestEtc.lotus));
        this._lotus.x -= 4;
        this._lotus.y -= 4;
        this._updateLotus();
    }

    private _updateLayout() {
        this._despawnBlock();
        this._spawnBlock();
    }

    private _getBlockSprite(sectionType: SectionType): Sprite {
        const layer = Sprite.from(manifest[sectionType]);
        layer.pivot.x = layer.width / 2;
        layer.pivot.y = layer.height / 2;

        return layer;
    }

    private _getSingle(): Sprite {
        const singleBlock = this._getBlockSprite('single');
        singleBlock.x = HALF_TILE_SIZE;
        singleBlock.y = HALF_TILE_SIZE + Y_OFFSET;

        return singleBlock;
    }

    private _getLineBlock(axisId: AxisId): Sprite {
        const lineBlock = this._getBlockSprite(axisId);
        lineBlock.x = HALF_TILE_SIZE;
        lineBlock.y = HALF_TILE_SIZE + Y_OFFSET;

        return lineBlock;
    }

    private _getEnd(cornerId: EndId): Sprite {
        const endBlock = this._getBlockSprite(cornerId);
        endBlock.x = HALF_TILE_SIZE;
        endBlock.y = HALF_TILE_SIZE + Y_OFFSET;

        return endBlock;
    }

    private _getCorner(cornerId: CornerId): Sprite {
        const cornerBlock = this._getBlockSprite(cornerId);
        cornerBlock.x = HALF_TILE_SIZE;
        cornerBlock.y = HALF_TILE_SIZE + Y_OFFSET;

        return cornerBlock;
    }

    private _getJunction(junctionId: JunctionId): Sprite {
        const junctionBlock = this._getBlockSprite(junctionId);
        junctionBlock.x = HALF_TILE_SIZE;
        junctionBlock.y = HALF_TILE_SIZE + Y_OFFSET;

        return junctionBlock;
    }

    private _spawnBlock() {
        const axisId = isVerticalLineLayout(this._layout0) ? 'vertical' : 'horizontal';
        const endId = getEndId(this._layout0);
        const cornerId = getCornerId(this._layout0);
        const junctionId = getJunctionId(this._layout0);

        if (isLineLayout(this._layout0)) {
            this._block = this._getLineBlock(axisId);
        } else if (isEndLayout(this._layout0)) {
            this._block = this._getEnd(endId);
        } else if (isCornerLayout(this._layout0)) {
            this._block = this._getCorner(cornerId);
        } else if (isJunctionLayout(this._layout0)) {
            this._block = this._getJunction(junctionId);
        } else this._block = this._getSingle();

        this._scaleView.addChild(this._block);
        //this._createRipples();
        this._createLotus();
    }

    private _despawnBlock() {
        if (!this._block) return;
        this.onDestroy();
        this._scaleView.removeChild(this._block);
        this._block = undefined;
    }

    public get lotus(): Container {
        return this._lotus;
    }
}
