import { IFlow } from '../../../lib/pattern/IFlow';
import NakedPromise from '../../../lib/pattern/NakedPromise';
import { getAvailableGameDayAB } from '../../../replicant/components/gameDay';
import { GameId, Scoreboard } from '../../../replicant/defs/gameDay';
import { sleep } from '../../../replicant/util/jsTools';
import app from '../../getApp';
import { trackGameDayStep } from '../../lib/analytics/gameDay';
import { GameDayScreen } from '../../main/home/GameDayScreen';
import { IntroScreen } from '../../main/intro/IntroScreen';

export type ScoreData = {
    visitorR: number;
    homeR: number;
    balls: number;
    strikes: number;
    outs: number;
    homeData: string[];
    visitorData: string[];
};

export type GameDayFlowOpts = { gameId: GameId; opponentOverride?: string };

export abstract class GameDayFlow implements IFlow {
    // fields
    //-------------------------------------------------------------------------
    protected readonly _introSubSteps = 1; // update if _baseIntro gets more tap steps
    protected readonly _endingSubSteps = 2; // update if _baseVictory or _baseLoss gets more tap steps

    protected _gameId: GameId;
    protected _gameDayScreen: GameDayScreen;
    protected _introScreen: IntroScreen;
    // text and scoreboard data
    protected _scoreboard: Scoreboard;
    protected _visitor: string;
    protected _home: string;

    protected _cheerSFX = 'crowd_cheer.ogg';
    protected _batSFX = 'wooden_bat.ogg';
    protected _whiffSFX = 'whiff.ogg';
    protected _catchSFX = 'glove_catch.ogg';
    protected _victorySFX = 'victory.ogg';
    protected _lossSFX = 'disappoint.ogg';

    // opponent for versus screen
    protected _opponent: string;

    // offset for analytics
    protected _stepOffset: number[];
    // state step
    protected _step = 0;
    protected _gameDaySteps: { handler: () => Promise<void>; substeps: number }[];

    // init
    //-------------------------------------------------------------------------
    constructor(opts: GameDayFlowOpts) {
        // caching started game id makes sure we always will run the correct complete action in the end of the game.
        // for example a game could be available at the start time but not available at the end of the flow.
        this._gameId = opts.gameId;
        this._step = this._getStep();
    }

    // impl
    //-------------------------------------------------------------------------
    async execute(): Promise<void> {
        this._stepOffset = this._gameDaySteps.reduce(
            (acc, cur) => {
                acc.push(acc[acc.length - 1] + cur.substeps);
                return acc;
            },
            [0],
        );

        await this.stepHandler();
    }

    private _getStep() {
        const state = app().server.state;
        const steps = {
            first: state.gameDay.step,
            second: state.gameDay.secondGame.step,
        };

        const { game } = getAvailableGameDayAB(state, app().server.now());

        return steps[game];
    }

    private async stepHandler() {
        const startStep = this._step;
        for (let i = startStep; i < this._gameDaySteps.length; i++) {
            await this._gameDaySteps[i].handler.bind(this).call();
            await app().server.invoke.gameDayStep({ game: this._gameId });
            this._step = this._getStep();
        }
    }

    protected async _baseIntro(opts: { scoreData: ScoreData; nextBgId: string; tapDialog: string }) {
        const { scoreData, nextBgId, tapDialog } = opts;

        void app().nav.open('versusScreen', {
            opponent: this._opponent,
            playerTeam: app().server.state.name,
        });
        await sleep(2.5);

        let stepIndex = this._stepOffset[this._step];
        this._introScreen = (await app().nav.open('introScreen', { isRegularGameDay: true })) as IntroScreen;

        this._introScreen.panDown();
        // load for the rest of the tutorial in intro scene
        void this._introScreen.preloadLazyAsset(this._scoreboard);

        // preload next scene
        void app().nav.preload('gameDayScreen', { bgId: nextBgId });
        await sleep(4);

        const introPromise = new NakedPromise();
        void this._introScreen.spawnBubbleTap({
            dialogText: tapDialog,
            promise: introPromise,
            narrator: 'arrow',
            // bubbleOffset: { x: -655, y: -290 }, // use this offset if score panel is spawned before bubble
            bubbleOffset: { x: -170, y: -290 },
        });

        void this._introScreen.spawnScoreDynamic({
            ...scoreData,
            visitor: this._visitor,
            home: this._home,
            scoreboard: this._scoreboard,
        });

        await introPromise;
        trackGameDayStep({ stepIndex: stepIndex++, stepName: 'intro', stepType: 'tap' });
    }

    protected async _baseVictory(opts: { scoreData: ScoreData; tapDialog: string }) {
        const { scoreData, tapDialog } = opts;
        let stepIndex = this._stepOffset[this._step];
        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay5',
        })) as GameDayScreen;

        await this._gameDayScreen.preloadScoreboard(this._scoreboard);
        void this._gameDayScreen.spawnScoreDynamic({
            ...scoreData,
            visitor: this._visitor,
            home: this._home,
            scoreboard: this._scoreboard,
        });

        void app().nav.preload('gameDayScreen', { bgId: 'gameDay5', fullTeam: { type: 'victory' } });

        const scorePromise = new NakedPromise();
        await this._gameDayScreen.spawnBubbleTap({
            dialogText: tapDialog,
            promise: scorePromise,
            narrator: 'arrow',
            bubbleOffset: { x: 0, y: this._gameDayScreen.base.height - 150 },
            parentOverride: this._gameDayScreen.base,
        });

        await scorePromise;

        trackGameDayStep({ stepIndex: stepIndex++, stepName: 'victory_score', stepType: 'tap' });

        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay5',
            fullTeam: { type: 'victory' },
        })) as GameDayScreen;

        void app().sound.play(this._victorySFX);

        const victoryPromise = new NakedPromise();
        await this._gameDayScreen.spawnBubbleTap({
            promise: victoryPromise,
            parentOverride: this._gameDayScreen.base,
        });

        await victoryPromise;

        // track right before we update the game day so its part of the same day
        trackGameDayStep({ stepIndex: stepIndex++, stepName: 'victory', stepType: 'tap' });
    }

    protected async _baseLoss(opts: { scoreData: ScoreData; tapDialog: string }) {
        const { scoreData, tapDialog } = opts;
        let stepIndex = this._stepOffset[this._step];
        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay5',
        })) as GameDayScreen;

        void (await this._gameDayScreen.preloadScoreboard(this._scoreboard));
        void this._gameDayScreen.spawnScoreDynamic({
            ...scoreData,
            visitor: this._visitor,
            home: this._home,
            scoreboard: this._scoreboard,
        });

        void app().nav.preload('gameDayScreen', { bgId: 'gameDay5', fullTeam: { type: 'loss' } });

        const scorePromise = new NakedPromise();
        await this._gameDayScreen.spawnBubbleTap({
            dialogText: tapDialog,
            promise: scorePromise,
            narrator: 'arrow',
            bubbleOffset: { x: 0, y: this._gameDayScreen.base.height - 150 },
            parentOverride: this._gameDayScreen.base,
        });

        await scorePromise;
        trackGameDayStep({ stepIndex: stepIndex++, stepName: 'lose_score', stepType: 'tap' });

        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay5',
            fullTeam: { type: 'loss' },
        })) as GameDayScreen;

        void app().sound.play(this._lossSFX);

        const losePromise = new NakedPromise();
        await this._gameDayScreen.spawnBubbleTap({
            promise: losePromise,
            parentOverride: this._gameDayScreen.base,
        });

        await losePromise;

        // track right before we update the game day so its part of the same day
        trackGameDayStep({ stepIndex: stepIndex++, stepName: 'loss', stepType: 'tap' });
    }
}
