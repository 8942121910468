import { Vector2 } from '@play-co/odie';
import { gsap } from 'gsap';

import { arrayRandom } from '../../../../../replicant/util/jsTools';
import { config } from '../../defs/config';
import { BlockEntity, spawnBlockEntity, ThrowerBlockEntity } from '../../entities/BlockEntity';
import { GameScene } from '../../GameScene';
import { blockIterateAll } from '../../util/blockTools';
import { mapIterateCells } from '../../util/mapTools';
import { IEffect } from './IEffect';

// types
//-----------------------------------------------------------------------------
// public
export type ThrowerEffectOptions = {
    subject: ThrowerBlockEntity;
};

/*
    spawns one new cage from the position of the thrower at a random (non thrower) block
*/
export class ThrowerEffect implements IEffect {
    // fields
    //-------------------------------------------------------------------------
    // input
    private readonly _scene: GameScene;
    private readonly _options: ThrowerEffectOptions;

    // init
    //-------------------------------------------------------------------------
    constructor(scene: GameScene, options: ThrowerEffectOptions) {
        this._scene = scene;
        this._options = options;
    }

    static assets(): string[] {
        return [];
    }

    // impl
    //-------------------------------------------------------------------------
    public async execute() {
        const state = this._scene.sessionEntity.c.state;

        // require throws remaining
        if (state.cageThrowsRemaining === 0) return false;

        // pick a target
        const target = this._selectTarget();
        if (!target) return false;

        // reduce throw count
        --state.cageThrowsRemaining;

        // spawn cage at target position
        const cage = spawnBlockEntity(this._scene, { id: 'cage' }, target);

        // animate cage throw
        await this._animateCageThrow(cage);

        // if none left, disable all throwers
        if (state.cageThrowsRemaining === 0) {
            blockIterateAll(this._scene, (base) => {
                if (base.c.block.blockId === 'thrower') base.c.blockThrower.view.enabled = false;
            });
        }

        return true;
    }

    // private: animations
    //-------------------------------------------------------------------------
    private async _animateCageThrow(cage: BlockEntity) {
        const duration = 0.8;
        const from = this._options.subject;
        const fromPosition = from.c.view2d.view.position;
        const view = cage.c.view2d.view;
        const toPosition = view.position.clone();
        const peak = Math.min(toPosition.y, fromPosition.y) - config.tile.size;
        view.position.set(fromPosition.x, fromPosition.y);

        // budget trajectory animation
        await gsap
            .timeline()
            .to(view, { y: peak, duration: duration / 2, ease: 'power1.out' })
            .to(view, { y: toPosition.y, duration: duration / 2, ease: 'power1.in' })
            .to(view, { x: toPosition.x, duration, ease: 'none' }, 0);
    }

    // private: support
    //-------------------------------------------------------------------------
    public _selectTarget(): Vector2 {
        const candidates: Vector2[] = [];

        // get target candidate positions
        mapIterateCells(this._scene, (cell, position) => {
            // must be a base entity, that isnt immortal, and not have an overlay
            if (cell.base && !cell.base.entity.c.block.props.immortal && !cell.overlay) {
                candidates.push(position);
            }
        });

        // pick a random one
        return arrayRandom(candidates);
    }
}
