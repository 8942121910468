import type { Component, Entity2D } from '@play-co/odie';

import { ShellBlockProps } from '../defs/block';
import { ShellBlockView } from '../views/blocks/ShellBlockView';

export type ShellComponentOptions = {
    props: ShellBlockProps;
    option?: number;
};

export class ShellBlockComponent implements Component {
    public static readonly NAME = 'blockShell';

    // fields
    //-------------------------------------------------------------------------
    public entity!: Entity2D;
    public view!: ShellBlockView;

    // properties
    //-------------------------------------------------------------------------
    // current option
    public get option(): number {
        return this.getGameplayState();
    }

    // impl
    //-------------------------------------------------------------------------
    public init(options: ShellComponentOptions): void {
        this.view = new ShellBlockView({ ...options.props });
        this.entity.c.block.getGameplayState = () => this.getGameplayState();
        const optionDef = options.props.options?.[options.option || 0] as boolean;
        this.view.setOpen(optionDef);
    }

    public getGameplayState() {
        return this.view.open ? 1 : 0;
    }
}
