import { ABTestsConfig } from '@play-co/replicant';

export type Ruleset = { abTests: typeof abTests };

/*
    Example

    'XXXX_TestId': {
        ...buckets(['Control', 'Enabled']),
        newUsersOnly: true, // optional
        assignManually: true, // optional
    }
    */

// ab tests
//-----------------------------------------------------------------------------
export const abTests = {
    // '0001_MoreOA': buckets(['Control', 'Enabled']),
    // '0002_FirstSession': buckets(['Control', 'Enabled']),
    // '0003_GameReward': buckets(['Control', 'Enabled']),
    // '0004_MultipleGames': buckets(['Control', 'Enabled']),
    // '0005_GameDayOA': buckets(['Control', 'Enabled']),
    // '0006_FullLivesOA': buckets(['Control', 'Enabled']),
    // '0007_TeamMorale': buckets(['Control', 'Enabled']),
    // '0008_MoraleDecay': buckets(['Control', 'Enabled']),
    '0009_OriginalConcept': buckets(['Control', 'Enabled']),
} as const satisfies ABTestsConfig;

// private
//-----------------------------------------------------------------------------
function buckets<T extends string>(ids: T[]): { buckets: { id: T }[] } {
    return {
        buckets: ids.map((id) => ({ id })),
    };
}
