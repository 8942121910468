import type { AdminToolProfileConfig } from '@play-co/replicant';
import { action, createAdminMessage, createMessages, SB } from '@play-co/replicant';

import { computedProperties } from '../computedProperties';
import { BoosterId, PowerBoosterId } from '../defs/booster';
import { MutableState, SyncActionAPI } from '../defs/replicant';
import { stateSchema } from '../state';
import { timeFromComponents } from '../util/timeTools';
import { boosterAdd, powerBoosterAdd } from './boosters';
import { addInfiniteLives } from './lives';
import { consumeCoins, grantCoins } from './shop';

export type AdminType = 'resource' | 'energy' | 'powerBooster' | 'booster';
const adminResource: AdminType = 'resource';
const adminEnergy: AdminType = 'energy';
const adminPowerBooster: AdminType = 'powerBooster';
const adminBooster: AdminType = 'booster';

type AdminMessage = {
    type: AdminType;
    id: string;
    amount: number;
};

export const adminActions = {
    // grants messageCount items from the state
    grantAdminItem: action((state: MutableState, opts: { messageCount: number }, api: SyncActionAPI) => {
        const { messageCount } = opts;

        // grab items and mutate state
        const toConsume: AdminMessage[] = [];
        // grab same amount as client showed in the claim popup, if new messages arrived they will get
        // consumed in a separate popup to avoid granting items that have not been seen by the user
        for (let i = 0; i < messageCount; i++) {
            const item = state.adminMessages.shift();
            toConsume.push(item as AdminMessage);
        }

        const resourceMap = {
            coins: (amount: number) =>
                grantCoins(state, api, { type: 'coins', amount, feature: 'admin', subFeature: 'grantCoins' }),
            stars: (amount: number) => (state.stars += amount),
        };
        const energyMap = {
            infiniteLives: (amount: number) =>
                addInfiniteLives(state, timeFromComponents({ hours: amount }), api.date.now()),
        };

        const grantMap: Record<AdminType, (item: AdminMessage) => void> = {
            resource: (item: AdminMessage) => {
                resourceMap[item.id as keyof typeof resourceMap](item.amount);
            },
            energy: (item: AdminMessage) => {
                energyMap[item.id as keyof typeof energyMap](item.amount);
            },
            powerBooster: (item: AdminMessage) => {
                powerBoosterAdd(state, item.id as PowerBoosterId, item.amount);
            },
            booster: (item: AdminMessage) => {
                boosterAdd(state, item.id as BoosterId, item.amount);
            },
        };

        for (const item of toConsume) {
            grantMap[item.type](item);
        }
    }),
};

export const adminToolMessages = createMessages(stateSchema)({
    // ---- Coins
    adminAddCoins: createAdminMessage(
        SB.object({ amount: SB.int().min(0), reason: SB.string() }),
        (state: MutableState, args: { amount: number }, info) => {
            // Add message to trigger claimable item in start flow
            state.adminMessages.push({
                type: adminResource,
                id: 'coins',
                amount: args.amount,
            });
        },
        'Add Coins',
    ),
    adminRemoveCoins: createAdminMessage(
        SB.object({ amount: SB.int().min(0), reason: SB.string() }),
        (state: MutableState, args: { amount: number }, info) => {
            // Trigger penalty immediately so the user cannot avoid it.
            consumeCoins({ amount: args.amount, feature: 'admin', subFeature: 'removeCoins' }, state);
        },
        'Remove Coins',
    ),
    adminSetCoins: createAdminMessage(
        SB.object({ amount: SB.int().min(0), reason: SB.string() }),
        // Should rarely be used
        (state: MutableState, args: { amount: number }, info) => {
            // Adjust asap since this is a setter without claim flow
            state.coins = Math.max(0, args.amount);
        },
        'Set Coins (use with caution)',
    ),
    // stars
    adminAddStars: createAdminMessage(
        SB.object({ amount: SB.int().min(0), reason: SB.string() }),
        (state: MutableState, args: { amount: number }, info) => {
            // Add message to trigger claimable item in start flow
            state.adminMessages.push({
                type: adminResource,
                id: 'stars',
                amount: args.amount,
            });
        },
        'Add Stars',
    ),
    adminRemoveStars: createAdminMessage(
        SB.object({ amount: SB.int().min(0), reason: SB.string() }),
        (state: MutableState, args: { amount: number }, info) => {
            state.stars -= args.amount;
            if (state.stars < 0) state.stars = 0;
        },
        'Remove Stars',
    ),
    adminSetStars: createAdminMessage(
        SB.object({ amount: SB.int().min(0), reason: SB.string() }),
        // Should rarely be used
        (state: MutableState, args: { amount: number }, info) => {
            // Adjust asap since this is a setter without claim flow
            state.stars = Math.max(0, args.amount);
        },
        'Set Stars (use with caution)',
    ),
    adminAddDart: createAdminMessage(
        SB.object({ amount: SB.int().min(0), reason: SB.string() }),
        (state: MutableState, args: { amount: number }, info) => {
            // Add message to trigger claimable item in start flow
            state.adminMessages.push({
                type: adminBooster,
                id: 'dart',
                amount: args.amount,
            });
        },
        'Add Dart',
    ),
    adminRemoveDart: createAdminMessage(
        SB.object({ amount: SB.int().min(0), reason: SB.string() }),
        (state: MutableState, args: { amount: number }, info) => {
            boosterAdd(state, 'dart', -args.amount);
        },
        'Remove Dart',
    ),
    adminSetDart: createAdminMessage(
        SB.object({ amount: SB.int().min(0), reason: SB.string() }),
        // Should rarely be used
        (state: MutableState, args: { amount: number }, info) => {
            // Adjust asap since this is a setter without claim flow
            if (args.amount > 0) {
                state.boosters['dart'].count = args.amount;
            }
        },
        'Set Dart (use with caution)',
    ),
    adminAddBullet: createAdminMessage(
        SB.object({ amount: SB.int().min(0), reason: SB.string() }),
        (state: MutableState, args: { amount: number }, info) => {
            // Add message to trigger claimable item in start flow
            state.adminMessages.push({
                type: adminBooster,
                id: 'bullet',
                amount: args.amount,
            });
        },
        'Add Bullet',
    ),
    adminRemoveBullet: createAdminMessage(
        SB.object({ amount: SB.int().min(0), reason: SB.string() }),
        (state: MutableState, args: { amount: number }, info) => {
            boosterAdd(state, 'bullet', -args.amount);
        },
        'Remove Bullet',
    ),
    adminSetBullet: createAdminMessage(
        SB.object({ amount: SB.int().min(0), reason: SB.string() }),
        // Should rarely be used
        (state: MutableState, args: { amount: number }, info) => {
            // Adjust asap since this is a setter without claim flow
            if (args.amount > 0) {
                state.boosters['bullet'].count = args.amount;
            }
        },
        'Set Bullet (use with caution)',
    ),
    adminAddDrill: createAdminMessage(
        SB.object({ amount: SB.int().min(0), reason: SB.string() }),
        (state: MutableState, args: { amount: number }, info) => {
            // Add message to trigger claimable item in start flow
            state.adminMessages.push({
                type: adminBooster,
                id: 'drill',
                amount: args.amount,
            });
        },
        'Add Drill',
    ),
    adminRemoveDrill: createAdminMessage(
        SB.object({ amount: SB.int().min(0), reason: SB.string() }),
        (state: MutableState, args: { amount: number }, info) => {
            boosterAdd(state, 'drill', -args.amount);
        },
        'Remove Drill',
    ),
    adminSetDrill: createAdminMessage(
        SB.object({ amount: SB.int().min(0), reason: SB.string() }),
        // Should rarely be used
        (state: MutableState, args: { amount: number }, info) => {
            // Adjust asap since this is a setter without claim flow
            if (args.amount > 0) {
                state.boosters['drill'].count = args.amount;
            }
        },
        'Set Drill (use with caution)',
    ),
    adminAddRoulette: createAdminMessage(
        SB.object({ amount: SB.int().min(0), reason: SB.string() }),
        (state: MutableState, args: { amount: number }, info) => {
            // Add message to trigger claimable item in start flow
            state.adminMessages.push({
                type: adminBooster,
                id: 'roulette',
                amount: args.amount,
            });
        },
        'Add Roulette',
    ),
    adminRemoveRoulette: createAdminMessage(
        SB.object({ amount: SB.int().min(0), reason: SB.string() }),
        (state: MutableState, args: { amount: number }, info) => {
            boosterAdd(state, 'roulette', -args.amount);
        },
        'Remove Roulette',
    ),
    adminSetRoulette: createAdminMessage(
        SB.object({ amount: SB.int().min(0), reason: SB.string() }),
        // Should rarely be used
        (state: MutableState, args: { amount: number }, info) => {
            // Adjust asap since this is a setter without claim flow
            if (args.amount > 0) {
                state.boosters['roulette'].count = args.amount;
            }
        },
        'Set Roulette (use with caution)',
    ),
    adminAddPowerBoosterSet: createAdminMessage(
        SB.object({ amount: SB.int().min(0), reason: SB.string() }),
        (state: MutableState, args: { amount: number }, info) => {
            // Add message to trigger claimable item in start flow
            state.adminMessages.push({
                type: adminPowerBooster,
                id: 'cube',
                amount: args.amount,
            });
            state.adminMessages.push({
                type: adminPowerBooster,
                id: 'bomb',
                amount: args.amount,
            });
            state.adminMessages.push({
                type: adminPowerBooster,
                id: 'rocket',
                amount: args.amount,
            });
        },
        'Add Cube, bomb, rocket',
    ),
    adminRemovePowerBoosterSet: createAdminMessage(
        SB.object({ amount: SB.int().min(0), reason: SB.string() }),
        (state: MutableState, args: { amount: number }, info) => {
            powerBoosterAdd(state, 'cube', -args.amount);
            powerBoosterAdd(state, 'bomb', -args.amount);
            powerBoosterAdd(state, 'rocket', -args.amount);
        },
        'Remove Cube, bomb, rocket',
    ),
    adminSetPowerBoosterSet: createAdminMessage(
        SB.object({ amount: SB.int().min(0), reason: SB.string() }),
        // Should rarely be used
        (state: MutableState, args: { amount: number }, info) => {
            // Adjust asap since this is a setter without claim flow
            if (args.amount > 0) {
                state.powerBoosters.map['cube'].count = args.amount;
                state.powerBoosters.map['bomb'].count = args.amount;
                state.powerBoosters.map['rocket'].count = args.amount;
            }
        },
        'Set Power Boosters (use with caution)',
    ),
    adminAddInfiniteLivesHours: createAdminMessage(
        SB.object({ amount: SB.int().min(0), reason: SB.string() }),
        (state: MutableState, args: { amount: number }, info) => {
            // Add message to trigger claimable item in start flow
            state.adminMessages.push({
                type: adminEnergy,
                id: 'infiniteLives',
                amount: args.amount,
            });
        },
        'Add Infinite Lives Hours',
    ),
    adminRemoveInfiniteLivesHours: createAdminMessage(
        SB.object({ amount: SB.int().min(0), reason: SB.string() }),
        (state: MutableState, args: { amount: number }, info) => {
            addInfiniteLives(state, -timeFromComponents({ hours: args.amount }), info.timestamp);
        },
        'Remove Infinite Lives Hours',
    ),
});

// admin tool
//-----------------------------------------------------------------------------
const profile: AdminToolProfileConfig<typeof computedProperties> = [
    { caption: 'Created At', property: 'createdAt' },
    { caption: 'Last State Update', property: 'updatedAt' },
    { caption: 'Name', property: 'name' },
    { caption: 'Stars', property: 'stars' },
    { caption: 'Coins', property: 'coins' },
    { caption: 'Premium Coins', property: 'coinsPremium' },
    { caption: 'Total Coins', property: 'totalCoins' },
    { caption: 'Boosters', property: 'boosters' },
    { caption: 'Power Boosters', property: 'powerBoosters' },
];

const adminMessageGroups: {
    [name: string]: (keyof typeof adminToolMessages)[];
} = {
    Coins: ['adminAddCoins', 'adminRemoveCoins', 'adminSetCoins'],
    Stars: ['adminAddStars', 'adminRemoveStars', 'adminSetStars'],
    Boosters: [
        'adminAddDart',
        'adminRemoveDart',
        'adminSetDart',
        'adminAddBullet',
        'adminRemoveBullet',
        'adminSetBullet',
        'adminAddDrill',
        'adminRemoveDrill',
        'adminSetDrill',
        'adminAddRoulette',
        'adminRemoveRoulette',
        'adminSetRoulette',
    ],
    PowerBoosters: ['adminAddPowerBoosterSet', 'adminRemovePowerBoosterSet', 'adminSetPowerBoosterSet'],
    InfiniteLives: ['adminAddInfiniteLivesHours', 'adminRemoveInfiniteLivesHours'],
};
export const adminTool = { profile, adminMessageGroups };
