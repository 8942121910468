import '@pixi/math-extras';
import './lib/pixi/mods/layoutMod';
import './lib/pixi/mods/qolMod';

import { Assets, extensions } from 'pixi.js';

import { App } from './app/App';
import { loadAtlas, resolveAtlasUrl } from './loaders/loadAtlas';
import { loadSpine } from './loaders/loadSpine';
import { resolveJsonUrl } from './loaders/resolveJson';

// remove the default spine loaders
Assets.loader['_parsers'].splice(7, 1);
Assets.loader['_parsers'].splice(7, 1);

extensions.add(resolveJsonUrl, resolveAtlasUrl, loadSpine, loadAtlas);

// create app
const application = new App();

// run it
if (!window.Native && !window.$Native?.in && window.liff) {
    void window.liff.ready.then(() => application.run());
} else {
    void application.run();
}
