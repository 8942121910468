import { action } from '@play-co/replicant';

import { MutableState } from '../defs/replicant';
import { abTests } from '../ruleset';
import { getDynamicReceiverBucket } from '../util/replicantTools';

// types
//-----------------------------------------------------------------------------
export type AbTestId = keyof typeof abTests;
export type ABTests = typeof abTests;
export type ABTestBucket<T extends AbTestId> = ABTests[T]['buckets'][number]['id'];

// actions
//-----------------------------------------------------------------------------
export const abtestActions = {
    // manually enable ab test
    abtestEnable: action((state: MutableState, options: { id: AbTestId; bucketId: string }, api) => {
        // assign test
        api.abTests.assign(options.id, options.bucketId);
    }),
    abtestAssign: action((state: MutableState, options: { id: AbTestId }, api) => {
        api.abTests.assign(options.id);
    }),
    // manually disable ab test
    abtestDisable: action((state: MutableState, options: { id: AbTestId }, api) => {
        // unassign test
        try {
            api.abTests.unassign(options.id);
        } catch {}
    }),
    abtestReset: action((state: MutableState, _, api) => {
        // reset all ab tests to default bucket assigned by the dynamic system
        Object.entries(abTests).forEach(([id, test]) => {
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            console.log(`Assigning ABTest: ${id} to: ${getDynamicReceiverBucket(state.id, id as AbTestId)}`);
            api.abTests.assign(id, getDynamicReceiverBucket(state.id, id as AbTestId));
        });
    }),
};
