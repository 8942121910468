import { Container } from 'pixi.js';

import { SpineAnimation } from '../../lib/pixi/animation/SpineAnimation';
import { objectToSprite } from '../lib/tools/appTools';

// types
//-----------------------------------------------------------------------------
// clip
export const playerClipIds = [
    'baseman_catch',
    'baseman_catch_idle',
    'baseman_stressed_idle',
    'batter_grounder',
    'batter_linedrive',
    'batter_ball_onpurpose',
    'batter_ball_surprised',
    'batter_bunt',
    'batter_confident',
    'batter_homerun',
    'batter_foul',
    'batter_idle',
    'batter_idle_unconfident',
    'batter_nervous',
    'batter_sigh',
    'batter_sad',
    'batter_strikeout',
    'batter_swing',
    'batter_swing_slow',
    'batter_practice',
    'celebrate',
    'celebrate_nojump',
    'embarassed',
    'fielder_catch',
    'fielder_catchfly',
    'fielder_idle',
    'fielder_sad',
    'fielder_surprised',
    'fielder_throw',
    'fielder_catchthrow', // new ID previous field_throw
    'fielder_walk',
    'grin',
    'idle1',
    'idle2',
    'laugh',
    'onbase1',
    'onbase1_flipped',
    'onbase1_flipped_safe',
    'onbase1_out_flipped',
    'onbase1_out_flipped_idle',
    'onbase2_flipped',
    'onbase2_out_flipped',
    'onbase3',
    'onbase3_out',
    'pitcher_catchthrowtofirst',
    'pitcher_idle',
    'pitcher_idle_flip',
    'pitcher_idle2',
    'pitcher_sad',
    'pitcher_run_flipped',
    'pitcher_tired_flipped',
    'pitcher_throw',
    'pitcher_throw_angry',
    'pitcher_throw_flipped',
    'pitcher_throw_tofirst',
    'pitcher_throw_tofirst_withfakeout',
    'pitcher_watchbunt',
    'pitcher_watchhomerun',
    'pitcher_watchhomerun_idle',
    'run',
    'run_flipped',
    'run_transition_onbase_flipped',
    'shrug_eyeroll',
    'slide',
    'walk',

    'baseman_nervous',
    'batter_nervous',
    'fielder_nervous',
    'pitcher_nervous',
    'pitcher_nervous_flip',
    'nervous',

    'baseman_tired',
    'batter_tired',
    'fielder_tired',
    'pitcher_tired',
    'pitcher_tired_flipped',

    // new animation ids
    'practice_tired1',
    'practice_tired2',

    'technical_advice1',
    'technical_advice2',

    'pitcher_injured1',
    'pitcher_injured2',

    'batter_injured1',
    'batter_injured2',

    'training_tired1',
    'training_tired2',
] as const;

export const baseballSkins = [
    'character1_boy',
    'character2_girl',
    'character3_boy',
    'character3_boy_red',
    // 'character3_girl_red',
    'character4_girl',
    'character5_boy',
    'character6_girl_red2',
    'character7_boy_red',
    'character8_girl_red',
] as const;

export type PlayerClipId = (typeof playerClipIds)[number];
export type PlayerSkin = (typeof baseballSkins)[number];

// constants
//-----------------------------------------------------------------------------
const manifest = {
    spine: 'spine.baseball.json',
};

/*
    baseball player
*/
export class BaseballPlayer extends Container {
    // fields
    //-------------------------------------------------------------------------
    // scene
    private _spine: SpineAnimation;

    // properties
    //-------------------------------------------------------------------------
    public get spine(): SpineAnimation {
        return this._spine;
    }

    // init
    //-------------------------------------------------------------------------
    static assets() {
        return Object.values(manifest);
    }

    public set speed(value: number) {
        this._spine.speed(value);
    }

    constructor(skin: PlayerSkin) {
        super();

        // spawn
        this._spawn(skin);
    }

    // api
    //-------------------------------------------------------------------------
    public async start(opts: { id: PlayerClipId; loop?: boolean; mix?: number }) {
        const { id, loop, mix } = opts;
        // stop previous
        this._spine.stop();
        await this._spine.start({ id, loop: !!loop, mix: mix ?? 0 });
    }

    public toSprite() {
        this.addChild(objectToSprite(this._spine));
        this._spine.visible = false;
    }

    // private: scene
    //-------------------------------------------------------------------------
    private _spawn(skin: PlayerSkin) {
        const spine = (this._spine = new SpineAnimation({ json: manifest.spine }));
        this.pivot.set(this.width / 2, this.height / 2);
        this.addChild(spine);
        spine.skin = skin;
    }
}
