import type { Component, Entity2D, Vector2 } from '@play-co/odie';

import { PositionType } from '../../../../lib/defs/types';
import { arrayCreate2 } from '../../../../replicant/util/jsTools';
import { config } from '../defs/config';
import { GoalId } from '../defs/goal';
import { BlockDef, CellDef, GridDef, MapDef } from '../defs/map';
import type { BlockEntity } from '../entities/BlockEntity';
import { TileEntity } from '../entities/TileEntity';
import { GameScene } from '../GameScene';

// types
//-----------------------------------------------------------------------------
export type CellLayerId = 'base' | 'overlay';
export class CellLayer {
    entity: BlockEntity;
    //TODO: this impl is wonky
    // points to the parent position of this block. appies only to larger than 1x1 blocks.
    parent?: Vector2;
}

export type Cell = {
    enabled: boolean;
    tile?: TileEntity;
} & Partial<Record<CellLayerId, CellLayer>>;

export type GoalCounts = { [key in GoalId]?: number };

/*
    tracks whats on the map and where
*/
export class MapComponent implements Component {
    // constants
    //-------------------------------------------------------------------------
    static readonly NAME = 'map';

    // fields
    //-------------------------------------------------------------------------
    // injected
    public entity!: Entity2D;
    // input
    private _mapDef: MapDef;
    // state
    private _columns: number;
    private _columnOffset: number;
    private _rows: number;
    private _grid: Cell[][]; // [columns][rows]
    private _goals: GoalCounts;

    // properties
    //-------------------------------------------------------------------------
    public get mapDef(): MapDef {
        return this._mapDef;
    }

    public get columns(): number {
        return this._columns;
    }

    public get columnOffset(): number {
        return this._columnOffset;
    }

    public get rows(): number {
        return this._rows;
    }

    public get grid(): Cell[][] {
        return this._grid;
    }

    public get goals(): GoalCounts {
        return this._goals;
    }

    // impl
    //-------------------------------------------------------------------------
    init(mapDef: MapDef): void {
        const gridDef = mapDef.grid;

        // set fields
        this._mapDef = mapDef;
        this._columns = gridDef.columns;
        this._rows = gridDef.rows;

        // init goal counts
        this._goals = {};
        for (const [id, count] of Object.entries(mapDef.goals)) {
            this._goals[id as GoalId] = count;
        }

        // import grid
        this._importGrid();
    }

    // api
    //-------------------------------------------------------------------------
    public isPositionOnMap(position: PositionType): boolean {
        return position.x >= 0 && position.x < this._columns && position.y >= 0 && position.y < this._rows;
    }

    public getCellAt(position: PositionType): Cell | undefined {
        if (!this.isPositionOnMap(position)) {
            return undefined;
        }

        return this._grid[position.x][position.y];
    }

    public setBlockEntity(entity: BlockEntity, position: Vector2) {
        // get cell at given position
        const cell = this.getCellAt(position);
        if (cell) {
            const block = entity.c.block;

            // add to all cells based on block dimensions
            for (let x = 0; x < block.width; ++x) {
                for (let y = 0; y < block.height; ++y) {
                    const subcell = this._grid[position.x + x][position.y + y];
                    const layer = {
                        entity,
                        parent: cell === subcell ? undefined : position,
                    };
                    if (block.props.overlay) subcell.overlay = layer;
                    else subcell.base = layer;
                }
            }
        }
    }

    public unsetBlockEntity(entity: BlockEntity) {
        // get cell at entity's position
        const position = entity.c.position.mapPosition;
        const cell = this.getCellAt(position);
        if (cell) {
            const block = entity.c.block;

            // get entity's layer id
            const layerId = this._getEntityLayer(cell, entity);
            if (layerId) {
                // get root position
                const rootPosition = cell[layerId].parent || position;

                // remove from all cells based on block dimensions
                for (let x = 0; x < block.width; ++x) {
                    for (let y = 0; y < block.height; ++y) {
                        delete this._grid[rootPosition.x + x][rootPosition.y + y][layerId];
                    }
                }
            }
        }
    }

    public setTileEntity(entity: TileEntity, position: Vector2) {
        // add tile entity to cell at given position
        const cell = this.getCellAt(position);
        if (cell) cell.tile = entity;
    }

    public completeGoals() {
        for (const id of Object.keys(this._goals)) {
            this._goals[id as GoalId] = 0;
        }
    }

    public exportState(): MapDef {
        const scene = this.entity.scene as GameScene;
        const { rewards, spawns } = this._mapDef;
        return {
            moves: scene.sessionEntity.c.phase.moves,
            goals: this._goals,
            rewards,
            spawns,
            grid: this._exportGrid(),
        };
    }

    // private: init
    //-------------------------------------------------------------------------
    private _importGrid(): void {
        const gridDef = this._mapDef.grid;

        // set column offset state
        this._columnOffset = 0;
        let columnMin = config.map.columns;
        let columnMax = 0;

        // initialize grid
        this._grid = arrayCreate2<Cell>(this._columns, this._rows, (column, row) => {
            const defCell = gridDef.cells[column][row];
            const cell: Cell = {
                enabled: defCell.enabled,
            };
            if (cell.enabled) {
                columnMin = Math.min(column, columnMin);
                columnMax = Math.max(column, columnMax);
            }
            return cell;
        });

        // solve column offset
        if (columnMax >= columnMin) this._columnOffset = columnMin - (config.map.columns - columnMax - 1);
    }

    // private: support
    //-------------------------------------------------------------------------
    private _getEntityLayer(cell: Cell, entity: BlockEntity): CellLayerId | undefined {
        if (cell.base?.entity === entity) return 'base';
        if (cell.overlay?.entity === entity) return 'overlay';
        return undefined;
    }

    private _exportGrid(): GridDef {
        const { columns, rows } = this._mapDef.grid;
        return {
            columns,
            rows,
            cells: arrayCreate2<CellDef>(this._columns, this._rows, (column, row) =>
                this._exportCell(this._grid[column][row]),
            ),
        };
    }

    private _exportCell(cell: Cell): CellDef {
        const defs: BlockDef[] = [this._exportCellLayer(cell.base), this._exportCellLayer(cell.overlay)].filter(
            (def) => !!def,
        );
        return {
            enabled: cell.enabled,
            blocks: defs.length > 0 ? defs : undefined,
        };
    }

    private _exportCellLayer(cellLayer?: CellLayer): BlockDef | undefined {
        if (cellLayer && !cellLayer.parent) {
            const c = cellLayer.entity.c;
            const { blockId, implId } = c.block;
            const option = c[implId].option;
            return { id: blockId, option };
        }
        return undefined;
    }
}
