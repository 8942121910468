import { Plugin, PluginOptions } from '@play-co/astro';
import { GCInstant } from '@play-co/gcinstant';

import { InstantGame } from '../../plugins/instantGames/InstantGame';
import { AbTestId } from '../../replicant/components/abtests';
import type { App } from '../App';

// types
//-----------------------------------------------------------------------------
// public
export type PlatformServiceOptions = PluginOptions;

/*
    platform abstraction service
*/
export class PlatformService extends Plugin {
    // fields
    //-------------------------------------------------------------------------
    private readonly _sessionStart: number;

    // properties
    //-------------------------------------------------------------------------
    public get sessionStart(): number {
        return this._sessionStart;
    }

    public get guest(): boolean {
        return GCInstant.platformID === 'line-guest';
    }

    public get native(): boolean {
        return InstantGame.platform.osType === 'ANDROID_APP' || InstantGame.platform.osType === 'IOS_APP';
    }

    public get ios(): boolean {
        return InstantGame.platform.osType === 'IOS' || InstantGame.platform.osType === 'IOS_APP';
    }

    public get android(): boolean {
        return InstantGame.platform.osType === 'ANDROID' || InstantGame.platform.osType === 'ANDROID_APP';
    }

    // init
    //-------------------------------------------------------------------------
    constructor(app: App, options: Partial<PlatformServiceOptions>) {
        super(app, options);
        this._sessionStart = Date.now();
    }

    public async start() {
        /*
        // log active ab tests
        Object.entries(abTests).forEach(([id, test]) =>
            console.log(`Default ABTest: ${id}`, getDynamicReceiverBucket(GCInstant.playerID, id as AbTestId)),
        );
        Object.entries(abTests).forEach(([id, test]) => console.log(`ABTest: ${id}`, this.getAbTest(id)));
        */

        // if native
        if (this.native) {
            // notify native that we're loaded (hides native loading screen)
            // window.Native.target.notifyLoaded();
            InstantGame.platform.legacyNative?.game.show();
        }
    }

    // api
    //-------------------------------------------------------------------------
    public getAbTest(id: string): string | undefined {
        return InstantGame.replicant.abTests.getBucketID(id);
    }

    public isAbTest(id: string, ...bucketIds: (string | undefined)[]): boolean {
        return bucketIds.includes(InstantGame.replicant.abTests.getBucketID(id));
    }

    public async enableAbTest(id: AbTestId, bucketId: string) {
        return InstantGame.replicant.invoke.abtestEnable({ id, bucketId });
    }

    public async disableAbTest(id: AbTestId) {
        return InstantGame.replicant.invoke.abtestDisable({ id });
    }

    public async resetAbTests() {
        return InstantGame.replicant.invoke.abtestReset();
    }

    // Assign manually according to the hashing function
    // Good to split up a test under certain conditions without cs tools
    public async assignAbTest(id: AbTestId) {
        return InstantGame.replicant.invoke.abtestAssign({ id });
    }
}
