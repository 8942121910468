import { gsap } from 'gsap';
import { Assets, Container, Sprite } from 'pixi.js';

import { PositionType } from '../../../../lib/defs/types';
import { IAnimation } from '../../../../lib/pattern/IAnimation';
import { SpritesheetAnimation } from '../../../../lib/pixi/animation/SpritesheetAnimation';
import { mapGetPan } from '../util/mapTools';
import app from '../../../getApp';

// types
//-----------------------------------------------------------------------------
export type BigBombAnimationOptions = {
    position: PositionType;
};

// constants
//-----------------------------------------------------------------------------
const manifest = {
    explode: 'clip.bigbomb',
    sheet: 'fx.bomb',
};

/*
    big bomb explosion animation
*/
export class BigBombAnimation extends Container implements IAnimation {
    // fields
    //-------------------------------------------------------------------------
    private _options: BigBombAnimationOptions;

    // init
    //-------------------------------------------------------------------------
    constructor(options: BigBombAnimationOptions) {
        super();
        this._options = options;
    }

    // init
    //-------------------------------------------------------------------------
    static assets(): string[] {
        return Object.values(manifest);
    }

    // impl
    //-------------------------------------------------------------------------
    public async start(): Promise<void> {
        // load assets
        await Assets.load(BigBombAnimation.assets());

        // create shockwave
        const wave = this._createWave();

        // create blast
        const blast = this._createBlast();

        // play sound
        void app().sound.play('puzzle-combo-bomb-bomb.mp3', { pan: mapGetPan(this._options.position) });

        // animate blast
        await this._animateBlast(blast);

        // run wave animation
        await this._animateWave(wave);
    }

    public stop() {}

    // private: scene
    //-------------------------------------------------------------------------
    private _createWave(): Sprite {
        const wave = Sprite.from('fx.bomb.wave.png');
        wave.anchor.set(0.5);
        wave.visible = false;
        return wave;
    }

    private _createBlast(): SpritesheetAnimation {
        const blast = new SpritesheetAnimation(manifest.explode);
        blast.anchor.set(0.5);
        return blast;
    }

    // private: animation
    //-------------------------------------------------------------------------
    private async _animateBlast(blast: SpritesheetAnimation) {
        // spawn
        this.addChild(blast);

        // animate
        await blast.start();

        // despawn
        this.removeChild(blast);
    }

    private async _animateWave(wave: Sprite) {
        const duration = 0.4;
        const scale = 3.2;

        // set initial values
        wave.visible = true;
        wave.scale.set(0.2);
        wave.alpha = 1;

        // spawn
        this.addChild(wave);

        // animate
        await gsap
            .timeline()
            .to(wave.scale, { x: scale, y: scale, duration, ease: 'none' })
            .to(wave, { alpha: 0, duration, ease: 'power2.in' }, 0);

        // despawn
        this.removeChild(wave);
    }
}
