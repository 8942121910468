import { Sprite } from 'pixi.js';

import { BasicText } from '../../lib/ui/text/BasicText';
import { AppPopupOptions } from './AppPopup';
import { BaseRibbonPopup } from './BaseRibbonPopup';
import app from '../../getApp';

// types
//-----------------------------------------------------------------------------
export type LineLoginPopupOptions = Record<string, any> & AppPopupOptions;

const manifest = {
    panel: 'panel.line.login.png',
};

export class LineLoginPopup extends BaseRibbonPopup {
    public override preload() {
        return [...super.preload(), ...app().resource.loadAssets([...Object.values(manifest)])];
    }

    // @ts-ignore
    public override async spawning(options: LineLoginPopupOptions) {
        await super.spawning({
            ...options,
            ribbon: 'orange',
            header: `[lineLoginTitle]`,
            okButton: '[lineLoginButton]',
            skipCommandPanel: true,
        });

        const panel = Sprite.from(manifest.panel);

        const loginText = new BasicText({
            text: '[lineLoginText]',
            system: true,
            style: {
                dropShadow: true,
                dropShadowAngle: Math.PI / 2,
                dropShadowAlpha: 0.6,
                dropShadowDistance: 2,
                fill: '#fff',
                strokeThickness: 4,
                align: 'center',
                fontWeight: 'bold',
                lineJoin: 'round',
                fontSize: 28,
                wordWrap: true,
                wordWrapWidth: 330,
                // this removes weird random line spacing for japanese texts
                whiteSpace: 'normal',
            },
        });

        // spawn
        this.main.addContent({
            bg: {
                content: panel,
                styles: {
                    position: 'center',
                },
            },
            install: {
                content: loginText,
                styles: {
                    position: 'right',
                    marginTop: 100,
                    marginRight: 10,
                },
            },
        });
    }
}
