import { PositionType } from '../../lib/defs/types';
import { IFlow } from '../../lib/pattern/IFlow';
import NakedPromise from '../../lib/pattern/NakedPromise';
import { BoosterId } from '../../replicant/defs/booster';
import app from '../getApp';
import { NavLayer } from '../defs/nav';
import { PuzzleController } from '../main/puzzle/controller/PuzzleController';
import { PuzzleScreen } from '../main/puzzle/PuzzleScreen';

// types
//-----------------------------------------------------------------------------
export type BoosterFlowOptions = {
    id: BoosterId;
};

/*
    booster FTUE flow, similar to BoosterFlow but its forced with pointer
*/
export class BoosterIntroFlow implements IFlow {
    // fields
    //-------------------------------------------------------------------------
    // input
    private _id: BoosterId;
    // state
    private _controller: PuzzleController;
    private readonly _completePromise = new NakedPromise<boolean>();

    // init
    //-------------------------------------------------------------------------
    constructor(options: BoosterFlowOptions) {
        this._id = options.id;
        this._controller = (app().nav.screens.puzzleScreen?.instance as PuzzleScreen)?.controller;
    }

    // impl
    //-------------------------------------------------------------------------
    public async execute() {
        // run start action
        await this._start();
        return this._completePromise;
    }

    // private: actions
    //-------------------------------------------------------------------------
    public async _start() {
        await this._activate();
    }

    private async _activate() {
        // preload booster
        void this._controller.screen.scene.preloadBooster(this._id);

        // show booster activator view
        await app().nav.open('boosterActivatorPopup', {
            id: this._id,
            showPointer: true,
            onActivate: (mapPosition: PositionType) => this._onActivate(mapPosition),
        });
    }

    private async _complete(success: boolean) {
        // close current dialog
        await app().nav.closeLayer(NavLayer.popup);

        // resolve complete
        this._completePromise.resolve(success);
    }

    // private: events
    //-------------------------------------------------------------------------
    // activate
    private async _onActivate(mapPosition: PositionType) {
        // disable input
        app().input.enabled = false;

        // close current dialog
        await app().nav.closeLayer(NavLayer.popup);

        // consume booster and toggle flag
        await app().server.invoke.boosterFtue({ id: this._id });

        // execute booster
        await this._controller.screen.scene.runBooster(this._id, mapPosition);

        // reenable input
        app().input.enabled = true;

        // complete success
        await this._complete(true);
    }
}
