import { IFlow } from '../../lib/pattern/IFlow';
import NakedPromise from '../../lib/pattern/NakedPromise';
import { livesTimeToNext } from '../../replicant/components/lives';
import { PowerBoosterId } from '../../replicant/defs/booster';
import gameConfig from '../../replicant/defs/gameConfig';
import app from '../getApp';
import { ShopPinchRestore, trackPinchEvent, trackRefillLivesPinchConversion } from '../lib/analytics/pinch';

type RefillLivesFlowOptions = {
    autoBuy?: boolean;
    crossplayPowerBoosters?: PowerBoosterId[];
};

export class RefillLivesFlow implements IFlow {
    // returns bought lives status
    private readonly _complete = new NakedPromise<boolean>();
    private _autoBuy: boolean;
    private _crossplayPowerBoosters: PowerBoosterId[];

    // init
    //-------------------------------------------------------------------------
    constructor(options: RefillLivesFlowOptions) {
        this._autoBuy = !!options?.autoBuy;
        this._crossplayPowerBoosters = options?.crossplayPowerBoosters;
    }

    // impl
    //-------------------------------------------------------------------------
    public async execute() {
        await this._noLivesAction();

        return this._complete;
    }

    private async _actionComplete(success: boolean) {
        this._complete.resolve(success);
    }

    private async _noLivesAction() {
        trackPinchEvent({ resource: 'lives', trigger: 'puzzle_start' });

        let reOpenPopup = true;
        while (reOpenPopup) {
            const refillPromise = new NakedPromise<{ refill: boolean; reOpen: boolean }>();
            await app().nav.open('refillLivesPopup', {
                lifeTimeLeft: () => livesTimeToNext(app().server.state, app().server.now()),
                underlay: 0.6,
                onConfirm: () => refillPromise.resolve({ refill: true, reOpen: false }),
                onClose: () => refillPromise.resolve({ refill: false, reOpen: false }),
                onCoins: async () => {
                    void app().nav.close('refillLivesPopup');
                    await this._openShop({});
                    refillPromise.resolve({ refill: false, reOpen: true });
                },
            });

            void app().nav.preload('shop');
            const { refill, reOpen } = await refillPromise;

            reOpenPopup = reOpen;
            void app().nav.close('refillLivesPopup');

            if (refill) {
                if (app().game.player.coins >= gameConfig.lives.cost) {
                    await this._consumeLives();
                    // consume directly and continue caller flow
                    void this._actionComplete(true);
                    return;
                }

                trackPinchEvent({ resource: 'coins', trigger: 'purchase_lives' });
                await this._openShop({ shopPinch: 'lives', crossplayPowerBoosters: this._crossplayPowerBoosters });
                if (this._autoBuy && app().game.player.coins >= gameConfig.lives.cost) {
                    // consume directly and continue caller flow
                    await this._consumeLives(true);
                    void this._actionComplete(true);
                    return;
                }
                reOpenPopup = true;
            }
        }

        void this._actionComplete(false);
    }

    private async _openShop(opts: { shopPinch?: ShopPinchRestore; crossplayPowerBoosters?: PowerBoosterId[] }) {
        const { shopPinch, crossplayPowerBoosters } = opts;
        const closePromise = new NakedPromise();
        void app().nav.open('shop', { shopPinch, crossplayPowerBoosters, onClose: closePromise.resolve });
        await closePromise;
    }

    private async _consumeLives(shopPinch = false) {
        await app().server.invoke.coinsPurchase({ id: 'lives' });
        trackRefillLivesPinchConversion(shopPinch);
    }
}
