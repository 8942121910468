import { CollideEvent, CollideId } from '../../defs/collide';
import type { HazardBlockEntity } from '../../entities/BlockEntity';
import { despawnBlockEntity } from '../../entities/BlockEntity';
import { GameScene } from '../../GameScene';
import { BreakEffect } from '../effects/BreakEffect';
import { HazardEffect } from '../effects/HazardEffect';
import { InvalidEffect } from '../effects/InvalidEffect';
import { CollideHandler, ICollision } from './ICollision';

// fields
//-----------------------------------------------------------------------------
//const sounds = ['puzzle-lock-destroy.mp3', 'puzzle-lock-poke.mp3'];

/*
    hazard block collision resolver
*/
export class HazardCollision implements ICollision {
    // fields
    //-------------------------------------------------------------------------
    // input
    private readonly _scene: GameScene;
    private readonly _entity: HazardBlockEntity;
    // map
    private readonly _resolvers: { [key in CollideId]?: CollideHandler } = {
        tap: this._resolveTap,
        block: this._resolveBlock,
        attack: this._resolveAttack,
    };

    // init
    //-------------------------------------------------------------------------
    constructor(scene: GameScene, entity: HazardBlockEntity) {
        this._scene = scene;
        this._entity = entity;
    }

    // impl
    //-------------------------------------------------------------------------
    public async resolve(event: CollideEvent): Promise<boolean> {
        return this._resolvers[event.id]?.call(this, event);
    }

    // private: resolvers
    //-------------------------------------------------------------------------
    private async _resolveTap(event: CollideEvent) {
        // invalid effect
        await new InvalidEffect(this._entity).execute();
        return false;
    }

    private async _resolveBlock(event: CollideEvent) {
        const collider = event.collider;

        // attack if basic block collider
        if (collider?.type === 'block' && collider.entity.c.block.props.type === 'basic') {
            await this._attack();
            return true;
        }
        return false;
    }

    private async _resolveAttack(event: CollideEvent) {
        // attack
        await this._attack();
        return true;
    }

    // private: actions
    //-------------------------------------------------------------------------
    private async _attack() {
        const entity = this._entity;
        const position = entity.c.position.mapPosition;
        // const component = entity.c.blockHazard;

        // play sound based on component count
        //app.sound.play(sounds[component.count], { pan: mapGetPan(this._entity.c.position.mapPosition) });

        // despawn block
        despawnBlockEntity(this._scene, this._entity);

        // execute break effect
        void new BreakEffect(this._scene, {
            position,
            size: entity.c.block.props,
            color: 0xffff00,
        }).execute();

        // execute hazard effect
        await new HazardEffect(this._scene, {
            subject: this._entity,
        }).execute();
    }
}
