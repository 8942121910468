import { action, SB, teaHash } from '@play-co/replicant';

import { getCreativeText, ReplicantCreativeType } from '../chatbot/chatbotTexts';
import {
    chatbotMessageTemplates,
    GameDayAssetABKey,
    GameDayAssetKey,
    GameDayAssetV2Key,
    generateChatbotPayload,
    PracticeAssetKey,
} from '../chatbot/messageTemplates';
import features from '../defs/features';
import gameDay, { ClockIdJST, GameId } from '../defs/gameDay';
import { MutableState, ScheduledActionAPI, State, SyncActionAPI } from '../defs/replicant';
import settings, { LanguageId } from '../defs/settings';
import { arrayRandom } from '../util/jsTools';
import { timeFromComponents, timeGetTimeOfDay, timeToComponents } from '../util/timeTools';
import { boosterAdd, powerBoosterAdd } from './boosters';
import { grantCoins } from './shop';
import { getAbTest } from '../util/replicantTools';
import { getTeamMoraleScore, updateTeamMorale } from './teamMorale';
import teamMorale from '../defs/teamMorale';

// constants
//-----------------------------------------------------------------------------
export const gameDay0 = 'game_day_0';
export const gameDay1 = 'game_day_1';
export const gameDay2 = 'game_day_2';

export const secondGame0 = 'second_game_0';
export const secondGame1 = 'second_game_1';
export const secondGame2 = 'second_game_2';

// regular practice ids, these ids are swapped out for "gameday practice" once all game days have passed
export const practice0 = 'practice_0';
export const practice1 = 'practice_1';
export const practice2 = 'practice_2';

// actions
//-----------------------------------------------------------------------------
export const gameDayActions = {
    gameDayStart: action((state: MutableState, _, api: SyncActionAPI) => {
        // update state to current day to be played next
        const current = getAvailableGameDayAB(state, api.date.now());
        const { game, index } = current;

        const gameAction = {
            first: () => {
                // allow dev to continue playing because of cheats etc
                if (!process.env.IS_DEVELOPMENT) {
                    if (index < state.gameDay.day) {
                        throw new Error(`available game '${index}' cant be less than previous game`);
                    }
                    if (index >= gameDay.firstGameAmount) {
                        throw new Error(
                            `available game day '${index}' cant be more than or equal to firstGameAmount '${gameDay.firstGameAmount}'`,
                        );
                    }
                }

                if (state.gameDay.day !== index) {
                    state.gameDay.step = 0;
                    // register morale points at the start of the game to determine result
                    state.gameDay.startMorale = getTeamMoraleScore(state, api.date.now());
                }
                state.gameDay.day = index;
                state.gameDay.completed = false;
            },
            second: () => {
                // allow dev to continue playing because of cheats etc
                if (!process.env.IS_DEVELOPMENT) {
                    if (index < state.gameDay.secondGame.day) {
                        throw new Error(`available second game '${index}' cant be less than previous game`);
                    }
                    if (index >= gameDay.secondGameAmount) {
                        throw new Error(
                            `available game day '${index}' cant be more than or equal to secondGameAmount '${gameDay.firstGameAmount}'`,
                        );
                    }
                }
                if (state.gameDay.secondGame.day !== index) {
                    state.gameDay.secondGame.step = 0;
                }
                state.gameDay.secondGame.day = index;
                state.gameDay.secondGame.completed = false;
            },
        };

        gameAction[game]();

        const isWin = isWinningResult({
            previousTimestamp: state.gameDay.timestamp,
            teamMorale: state.gameDay.startMorale,
        });
        return { ...current, isWin };
    }),
    gameDayComplete: action((state: MutableState, opts: { game: GameId }, api: SyncActionAPI) => {
        const { game } = opts;
        const updateAction = {
            first: () => gameDayComplete(state, api.date.now()),
            second: () => gameSecondComplete(state, api.date.now()),
        };
        updateAction[game]();
    }),
    gameDayStep: action((state: MutableState, opts: { game: GameId }, api: SyncActionAPI) => {
        const { game } = opts;
        const stepAction = {
            first: () => state.gameDay.step++,
            second: () => state.gameDay.secondGame.step++,
        };
        stepAction[game]();
    }),
    claimGameDayReward: action((state: MutableState, _, api: SyncActionAPI) => {
        // mutate array and pick first item
        const rewardIndexes = state.gameDay.rewards.shift();
        const reward = getGameDayReward(state, rewardIndexes);

        for (const item of reward.items) {
            switch (item.id) {
                case 'coins':
                    grantCoins(state, api, { type: 'coins', amount: item.amount, feature: 'game_day' });
                    break;
                case 'bomb':
                // fall through
                case 'cube':
                // fall through
                case 'rocket':
                    powerBoosterAdd(state, item.id, item.amount);
                    break;
                case 'dart':
                // fall through
                case 'bullet':
                // fall through
                case 'drill':
                // fall through
                case 'roulette':
                    boosterAdd(state, item.id, item.amount);
                    break;
                default:
                    throw new Error(`Invalid id ${item.id} for game day grant`);
            }
        }

        const isWin = isWinningResult({
            previousTimestamp: rewardIndexes.previousTimestamp,
            teamMorale: rewardIndexes.teamMorale,
        });
        const result = isWin ? 'win' : 'loss';
        const moraleGain = teamMorale.score.gameDay[result];
        const now = api.date.now();
        const before = getTeamMoraleScore(state, now);
        updateTeamMorale(state, moraleGain, api.date.now());
        const after = getTeamMoraleScore(state, now);
        const moraleScore = { before, after };
        return { moraleScore };
    }),
};

// scheduledActions
//-----------------------------------------------------------------------------
export const gameDayScheduledActionsSchema = {
    gameDay: SB.object({
        day: SB.int(), // 0-indexed
        messageId: SB.int(), // 0-indexed
    }),
    game: SB.object({
        day: SB.int(), // 0-indexed
        game: SB.int(), // 0 or 1 (first or second game)
        messageId: SB.int(), // 0-indexed
    }),
    // practice day at the same time as game day, swapped out fully to gameDay (practice) once all game days have been completed
    practice: SB.object({
        messageId: SB.int(), // 0-indexed
    }),
};

export const gameDayScheduledActions = {
    gameDay: async (state: MutableState, options: { day: number; messageId: number }, api: ScheduledActionAPI) => {
        const now = api.date.now();
        if (now > state.updatedAt + timeFromComponents({ days: 30 })) {
            // player inactive, disable any messages
            api.sendAnalyticsEvents([
                {
                    eventType: 'ChatbotInactive',
                    eventProperties: {},
                },
            ]);
            return;
        }

        const { day, messageId } = options;
        const messageData = [
            {
                notificationId: gameDay0,
                getDelay: () => getTimeToNextDay('sevenAM', now),
            },
            {
                notificationId: gameDay1,
                getDelay: () => getTimeToNextDay('twelvePM', now),
            },
            {
                notificationId: gameDay2,
                getDelay: () => getTimeToNextDay('fivePM', now),
            },
        ];

        let extraDelay = 0;
        if (day === -1) {
            extraDelay = getDelayPracticeOA(state, now);
            if (state.gameDay.practiceId === -1 || messageId === 0) {
                // mutate practice id to new set
                updatePracticeId(state, api);
            }

            sendPracticeOA(state, api, {
                practiceId: state.gameDay.practiceId,
                messageId,
                subFeature: `practice${state.gameDay.practiceId}`,
            });
        } else {
            void sendGameDayOA(state, api, { day, messageId, subFeature: `day${options.day}` });
        }

        const message = messageData[messageId];
        const nextDay = getNextGameDayOA(state, now);
        // -1 means more practice messages not tied to a specific game day
        api.scheduledActions.schedule.gameDay({
            args: { day: nextDay, messageId },
            notificationId: message.notificationId,
            delayInMS: message.getDelay() + extraDelay,
        });
    },
    game: async (
        state: MutableState,
        options: { day: number; game: number; messageId: number },
        api: ScheduledActionAPI,
    ) => {
        // abort any pending "multiple games" messages if disabled
        if (!features.multipleGames) return;

        const { day, game, messageId } = options;
        const messageData = [
            // first game of the day
            [
                {
                    notificationId: gameDay0,
                    getDelay: () => getTimeToNextDay('sevenAM', api.date.now()),
                },
                {
                    notificationId: gameDay1,
                    getDelay: () => getTimeToNextDay('nineAM', api.date.now()),
                },
                {
                    notificationId: gameDay2,
                    getDelay: () => getTimeToNextDay('twelvePM', api.date.now()),
                },
            ],
            // second game of the day
            [
                {
                    notificationId: secondGame0,
                    getDelay: () => getTimeToNextDay('threePM', api.date.now()),
                },
                {
                    notificationId: secondGame1,
                    getDelay: () => getTimeToNextDay('fivePM', api.date.now()),
                },
                {
                    notificationId: secondGame2,
                    getDelay: () => getTimeToNextDay('eightPM', api.date.now()),
                },
            ],
        ];

        // use this to call gameDay scheduled action and let it take over once game days are finished
        // the timings works for game 0 only (first game of the day), if game 0 only schedule changes then verify the offsets of these getDelay calls
        const practiceDelays = [
            {
                notificationId: gameDay0,
                getDelay: () => getTimeToNextDay('sevenAM', api.date.now()),
            },
            {
                notificationId: gameDay1,
                getDelay: () => getTimeToNextDay('twelvePM', api.date.now()),
            },
            {
                notificationId: gameDay2,
                getDelay: () => getTimeToNextDay('fivePM', api.date.now()),
            },
        ];

        void sendGameDayV2OA(state, api, { day, game, messageId, subFeature: `day${options.day}` });

        const message = messageData[game][messageId];
        const nextSecondDay = getNextSecondGameOA(state, api.date.now());
        // -1 means more practice messages not tied to a specific game day
        if (nextSecondDay === -1) {
            if (game === 0) {
                const practiceMessage = practiceDelays[messageId];
                // final game messgae of game 1, replace with practice messages
                // let regular gameDay take over which handles practice messages with default clock times
                api.scheduledActions.schedule.gameDay({
                    args: { day: nextSecondDay, messageId },
                    notificationId: practiceMessage.notificationId,
                    delayInMS: practiceMessage.getDelay(),
                });
            }
        } else {
            api.scheduledActions.schedule.game({
                args: { day: nextSecondDay, game, messageId },
                notificationId: message.notificationId,
                delayInMS: message.getDelay(),
            });
        }
    },
    // not to confuse with practice messages within gameDay scheduled action
    // this one uses different time slots without extra delay conditions, it just shares the OA messages
    practice: async (state: MutableState, options: { messageId: number }, api: ScheduledActionAPI) => {
        // disabled for now
        // const { messageId } = options;
        // const messageData = [
        //     {
        //         notificationId: practice0,
        //         getDelay: () => getTimeToNextDay('tenAM', api.date.now()),
        //     },
        //     {
        //         notificationId: practice1,
        //         getDelay: () => getTimeToNextDay('threePM', api.date.now()),
        //     },
        //     {
        //         notificationId: practice2,
        //         getDelay: () => getTimeToNextDay('eightPM', api.date.now()),
        //     },
        // ];
        // // first time or firs set of messages, generate new id
        // if (state.gameDay.practiceId === -1 || messageId === 0) {
        //     // mutate practice id to new set
        //     updatePracticeId(state, api);
        // }
        // // TODO discuss feature subFeature for this new kind of practice message
        // sendPracticeOA(state, api, {
        //     practiceId: state.gameDay.practiceId,
        //     messageId,
        //     subFeature: `practice${state.gameDay.practiceId}`,
        // });
        // const message = messageData[messageId];
        // const nextDay = getNextGameDayOA(state, api.date.now());
        // // -1 means more practice messages being used in the main gameDay scheduled action
        // // just return and let other system take over
        // if (nextDay === -1) return;
        // api.scheduledActions.schedule.practice({
        //     args: { messageId },
        //     notificationId: message.notificationId,
        //     delayInMS: message.getDelay(),
        // });
    },
};

// events
//-----------------------------------------------------------------------------
export function onGameDayInit(api: SyncActionAPI, state: MutableState) {
    // ---------------------------------------------------------------
    // if old player comes back using the new game day system,
    // set last/previous completed day to 1 day ago
    if (state.gameDay.day === 1 && state.gameDay.timestamp === 0) {
        state.gameDay.timestamp = api.date.now() - timeFromComponents({ days: 1 });
    }
    // ---------------------------------------------------------------

    api.scheduledActions.unschedule(gameDay0);
    api.scheduledActions.unschedule(gameDay1);
    api.scheduledActions.unschedule(gameDay2);

    api.scheduledActions.unschedule(secondGame0);
    api.scheduledActions.unschedule(secondGame1);
    api.scheduledActions.unschedule(secondGame2);

    api.scheduledActions.unschedule(practice0);
    api.scheduledActions.unschedule(practice1);
    api.scheduledActions.unschedule(practice2);
}

// game day and game day practice
export function onGameDayExit(api: ScheduledActionAPI, state: MutableState) {
    // 1st forced game day (cant be missed) needs to be completed before the regular game days start
    if (state.gameDay.day === 0 && !state.gameDay.completed) return;

    const next7am = getTimeToClockTime('sevenAM', api.date.now());
    // if 7am is passed, schedule for next day for all 3
    const clockOffset = next7am < 0 ? timeFromComponents({ days: 1 }) : 0;

    const now = api.date.now();
    const nextDay = getNextGameDayOA(state, api.date.now());

    if (features.multipleGames) {
        api.scheduledActions.schedule.game({
            args: { day: nextDay, game: 0, messageId: 0 },
            notificationId: gameDay0,
            delayInMS: next7am + clockOffset,
        });
        api.scheduledActions.schedule.game({
            args: { day: nextDay, game: 0, messageId: 1 },
            notificationId: gameDay1,
            delayInMS: getTimeToClockTime('nineAM', now) + clockOffset,
        });
        api.scheduledActions.schedule.game({
            args: { day: nextDay, game: 0, messageId: 2 },
            notificationId: gameDay2,
            delayInMS: getTimeToClockTime('twelvePM', now) + clockOffset,
        });
        const secondGame = getNextSecondGameOA(state, api.date.now());

        // check if its before or after 3pm JST using its own offset for the second game
        const threePM = getTimeToClockTime('threePM', now);
        const days = timeToComponents(threePM).days;
        const secondOffset = days > 0 ? -timeFromComponents({ days }) : 0;
        // game 2
        api.scheduledActions.schedule.game({
            args: { day: secondGame, game: 1, messageId: 0 },
            notificationId: secondGame0,
            delayInMS: threePM + secondOffset,
        });
        api.scheduledActions.schedule.game({
            args: { day: secondGame, game: 1, messageId: 1 },
            notificationId: secondGame1,
            delayInMS: getTimeToClockTime('fivePM', now) + secondOffset,
        });
        api.scheduledActions.schedule.game({
            args: { day: secondGame, game: 1, messageId: 2 },
            notificationId: secondGame2,
            delayInMS: getTimeToClockTime('eightPM', now) + secondOffset,
        });
    } else {
        // regular game day or practice day if nextDay is -1 (no need to handle practice delay here since the player is 'active')
        api.scheduledActions.schedule.gameDay({
            args: { day: nextDay, messageId: 0 },
            notificationId: gameDay0,
            delayInMS: next7am + clockOffset,
        });
        api.scheduledActions.schedule.gameDay({
            args: { day: nextDay, messageId: 1 },
            notificationId: gameDay1,
            delayInMS: getTimeToClockTime('twelvePM', now) + clockOffset,
        });
        api.scheduledActions.schedule.gameDay({
            args: { day: nextDay, messageId: 2 },
            notificationId: gameDay2,
            delayInMS: getTimeToClockTime('fivePM', now) + clockOffset,
        });
    }

    // if (nextDay !== -1) {
    //     const next10am = getTimeToClockTime('tenAM', api.date.now());

    //     // dont schedule practice message if the player completed first game day before 10am jst, just schedule them all the next day JST
    //     // or (regular logic) schedule all 3 messages the next day if its after 10am JST
    //     const practiceOffset = nextDay === 1 || next10am < 0 ? timeFromComponents({ days: 1 }) : 0;

    //     // schedule practice messages at the same day as game day messages
    //     // once no more game days availalbe, see -1 from 'getNextGameDayOA'then regular gameDay will send practice messages with new conditions
    //     api.scheduledActions.schedule.practice({
    //         args: { messageId: 0 },
    //         notificationId: practice0,
    //         delayInMS: next10am + practiceOffset,
    //     });
    //     api.scheduledActions.schedule.practice({
    //         args: { messageId: 1 },
    //         notificationId: practice1,
    //         delayInMS: getTimeToClockTime('threePM', now) + practiceOffset,
    //     });
    //     api.scheduledActions.schedule.practice({
    //         args: { messageId: 2 },
    //         notificationId: practice2,
    //         delayInMS: getTimeToClockTime('eightPM', now) + practiceOffset,
    //     });
    // }
}

export function gameDayComplete(state: MutableState, now: number) {
    state.gameDay.rewards.push({
        gameId: state.gameDay.day,
        game: 0,
        // timestamp and morale value hashed into number to determine result
        previousTimestamp: state.gameDay.timestamp,
        teamMorale: state.gameDay.startMorale,
    });

    state.gameDay.startMorale = 0;
    state.gameDay.completed = true; // mark completed to block entry and wait for next available game
    state.gameDay.step = 0;
    state.gameDay.lastCompleted = state.gameDay.day; // need to know where the timestamp is from
    state.gameDay.timestamp = now;

    if (state.gameDay.day === 0) {
        // set timestamp to trigger a rolling schedule for the second game now that first game has been completed
        state.gameDay.secondGame.timestamp = now + getTimeToNextDay('threePM', now);
    }
}

export function gameSecondComplete(state: MutableState, now: number) {
    state.gameDay.secondGame.completed = true; // mark completed to block entry and wait for next available game
    state.gameDay.secondGame.step = 0;
    state.gameDay.secondGame.lastCompleted = state.gameDay.secondGame.day; // need to know where the timestamp is from
    state.gameDay.secondGame.timestamp = now;

    state.gameDay.rewards.push({ gameId: state.gameDay.secondGame.day, game: 1 });
}

// Note: For OA scheduling only
export function getNextGameDayOA(state: State, now: number) {
    // impossible to miss the first day
    if (state.gameDay.day === 0 && !state.gameDay.completed) return 0;

    // old players from previous version with no complete timestamp for first day
    // they will be allowed entry for day 2 in getAvailableGameDay (index 1), schedule for day 3 here
    if (state.gameDay.timestamp === 0) {
        return 2;
    }

    let nextDay = state.gameDay.lastCompleted;
    let time = now - state.gameDay.timestamp;

    time += getTimeToNextDay('sevenAM', now); // offset accordingly to game start

    while (time >= 0) {
        time -= timeFromComponents({ days: 1 });
        nextDay++;
    }

    return nextDay < gameDay.firstGameAmount ? nextDay : -1; // -1 if no more days available
}

export function getNextSecondGameOA(state: State, now: number) {
    const secondGame = state.gameDay.secondGame;
    // no second OA message until first game is completed (cant be missed)
    // which will also trigger secondGame.timestamp for the second game to start its rolling schedule
    if (secondGame.timestamp === 0) return -1;

    let nextDay = secondGame.lastCompleted;

    // Last completed timestamp, or use first game complete timestamp with additional time
    // within second game window to start the rolling schedule for the second game
    let time = now - secondGame.timestamp;

    let offset = getTimeToNextDay('threePM', now); // offset accordingly to game start;
    const days = timeToComponents(offset).days;
    offset -= days > 0 ? timeFromComponents({ days }) : 0;
    time += offset;

    while (time >= 0) {
        time -= timeFromComponents({ days: 1 });
        nextDay++;
    }

    return nextDay < gameDay.secondGameAmount ? nextDay : -1; // -1 if no more days available
}

// For ingame use to allow entry
function getAvailableGameDay(state: State, now: number) {
    if (!state.sentInitMessage) return -1;

    // impossible to miss the first day
    if (state.gameDay.day === 0 && !state.gameDay.completed) return 0;

    // old players from previous version with no complete timestamp for first day, send them to the 2nd day (index 1)
    if (state.gameDay.timestamp === 0) {
        return 1;
    }

    let passedDaysCompleted = state.gameDay.lastCompleted;
    let time = now - state.gameDay.timestamp;

    time += getTimeToNextDay('sevenAM', now); // offset accordingly to game start
    passedDaysCompleted -= 1; // subtract one since we set the offset to next day
    while (time > 0) {
        time -= timeFromComponents({ days: 1 });
        passedDaysCompleted++;
    }

    if (passedDaysCompleted === state.gameDay.day) {
        // check if within the same day
        // do not allow entering the same day again if completed.
        return state.gameDay.completed ? -1 : state.gameDay.day;
    }

    return passedDaysCompleted < gameDay.firstGameAmount ? passedDaysCompleted : -1; // -1 if no more days available
}

export function getAvailableSecondGame(state: State, now: number) {
    if (!state.sentInitMessage) return -1;
    // Not allowed until FIRST game of the day overall is finished
    if (state.gameDay.day === 0 && !state.gameDay.completed) return -1;
    const secondGame = state.gameDay.secondGame;
    if (secondGame.timestamp === 0) return -1;

    let passedDaysCompleted = secondGame.lastCompleted;
    const completeTimestamp = secondGame.timestamp || now; // just use 1 day before as 'completed' if its not been completed yet to start calculation
    let time = now - completeTimestamp;

    const threePM = getTimeToNextDay('threePM', now); // offset accordingly to game start;

    time += threePM; // offset accordingly to game start
    passedDaysCompleted -= 1; // subtract one since we set the offset to next day
    while (time >= 0) {
        time -= timeFromComponents({ days: 1 });
        passedDaysCompleted++;
    }

    if (passedDaysCompleted === secondGame.day) {
        // check if within the same day
        // do not allow entering the same day again if completed.
        return secondGame.completed ? -1 : secondGame.day;
    }

    return passedDaysCompleted < gameDay.secondGameAmount ? passedDaysCompleted : -1; // -1 if no more days available
}

export function getAvailableGameDayAB(state: State, now: number): { game: GameId; index: number } {
    const firstGame = getAvailableGameDay(state, now);
    if (
        (state.gameDay.day === 0 && !state.gameDay.completed) ||
        state.gameDay.timestamp === 0 ||
        !features.multipleGames
    ) {
        return { game: 'first', index: firstGame };
    }

    const next7am = getTimeToNextDay('sevenAM', now);
    const firstGameStart = next7am;

    // new system depends in the time of the day
    const secondGame = getAvailableSecondGame(state, now);
    let secondGameStart = getTimeToNextDay('threePM', now);
    const days = timeToComponents(secondGameStart).days;
    // offset it accordingly within 24hour window
    secondGameStart -= days > 0 ? timeFromComponents({ days }) : 0;

    // do not allow first day's second game if the first game was completed after 3pm the same day
    if (state.gameDay.timestamp < now + secondGameStart - timeFromComponents({ days: 1 })) {
        // game index can be -1 which indicates time passed or completed already
        if (firstGameStart < secondGameStart) {
            // second game has started recently and has a larger window for next second game
            return { game: 'second', index: secondGame };
        }
    }
    // else first game has recently started and has a larger window for next first game
    return { game: 'first', index: firstGame };
}

export function sendGameDayOA(
    state: MutableState,
    api: ScheduledActionAPI | SyncActionAPI,
    message: {
        day: number;
        messageId: number;
        subFeature: string;
    },
) {
    const { day, messageId, subFeature } = message;
    const lang = state.language as LanguageId;

    const assetPostFix = lang === 'en' ? 'en' : 'ja';
    const textId = `game_day_${day}_${messageId}`;
    const baseId = `game_day_${day}_${messageId}`;
    const assetId = `${baseId}_${assetPostFix}` as GameDayAssetKey | GameDayAssetV2Key;
    const creativeText = getCreativeText(lang, textId as ReplicantCreativeType, api.math.random);

    // Default aspectRatio 1:1
    const aspectRatio = '3:2';

    const isEn = lang === 'en';
    const preFilledName = isEn ? settings.defaultNameEN : settings.defaultNameJA;

    api.chatbot.sendMessage(
        state.id,
        chatbotMessageTemplates.flexBubbleMessage({
            args: {
                imageKey: assetId,
                aspectRatio,
                text: creativeText.text,
                cta: creativeText.cta,
                senderName: state.name ? state.name : preFilledName,
            },
            payload: {
                ...generateChatbotPayload({ feature: 'gameday', subFeature, api }),
                $creativeAssetID: assetId,
                game: 0, // always first game for Control
            },
        }),
    );
}

export function sendGameDayV2OA(
    state: MutableState,
    api: ScheduledActionAPI | SyncActionAPI,
    message: {
        day: number;
        game: number; // 0 and 1
        messageId: number;
        subFeature: string;
    },
) {
    const { day, messageId, game, subFeature } = message;
    const lang = state.language as LanguageId;

    const prefixKey = getGameDayCreativePrefix({ day, game, messageId });
    const assetId = `${prefixKey}_${lang}` as GameDayAssetABKey;
    const creativeText = getCreativeText(lang, prefixKey as ReplicantCreativeType, api.math.random);

    // Default aspectRatio 1:1
    const aspectRatio = '3:2';

    const isEn = lang === 'en';
    const preFilledName = isEn ? settings.defaultNameEN : settings.defaultNameJA;

    api.chatbot.sendMessage(
        state.id,
        chatbotMessageTemplates.flexBubbleMessage({
            args: {
                imageKey: assetId,
                aspectRatio,
                text: creativeText.text,
                cta: creativeText.cta,
                senderName: state.name ? state.name : preFilledName,
            },
            payload: {
                ...generateChatbotPayload({ feature: 'gameday', subFeature, api }),
                $creativeAssetID: assetId,
                game,
            },
        }),
    );
}

export function sendPracticeOA(
    state: MutableState,
    api: ScheduledActionAPI | SyncActionAPI,
    message: {
        practiceId: number;
        messageId: number;
        subFeature: string;
    },
) {
    const { practiceId, messageId, subFeature } = message;
    const lang = state.language as LanguageId;
    const assetPostFix = lang === 'en' ? 'en' : 'ja';
    const baseId = `practice_${practiceId}_${messageId}`;
    const assetId = `${baseId}_${assetPostFix}` as PracticeAssetKey;
    const creativeText = getCreativeText(lang, baseId as ReplicantCreativeType, api.math.random);

    // Default aspectRatio 1:1
    const aspectRatio = '3:2';

    const isEn = lang === 'en';
    const preFilledName = isEn ? settings.defaultNameEN : settings.defaultNameJA;

    api.chatbot.sendMessage(
        state.id,
        chatbotMessageTemplates.flexBubbleMessage({
            args: {
                imageKey: assetId,
                aspectRatio,
                text: creativeText.text,
                cta: creativeText.cta,
                senderName: state.name ? state.name : preFilledName,
            },
            payload: {
                ...generateChatbotPayload({ feature: 'practice', subFeature, api }),
                $creativeAssetID: assetId,
            },
        }),
    );
}

export async function sendGameDynamicDayOA(
    state: State,
    api: ScheduledActionAPI | SyncActionAPI,
    opts: {
        textKey: ReplicantCreativeType;
        imageKey: string;
        feature: string;
        subFeature?: string;
        creativeAssetID: string;
    },
) {
    const { textKey, imageKey, feature, subFeature, creativeAssetID } = opts;

    const lang = state.language as LanguageId;
    const creativeText = getCreativeText(lang, textKey, api.math.random);
    // Default aspectRatio 1:1
    const aspectRatio = '3:2';
    const isEn = lang === 'en';
    const preFilledName = isEn ? settings.defaultNameEN : settings.defaultNameJA;

    api.chatbot.sendMessage(
        state.id,
        chatbotMessageTemplates.dynamicFlexBubbleMessage({
            args: {
                imageKey,
                aspectRatio,
                text: creativeText.text,
                cta: creativeText.cta,
                senderName: state.name ? state.name : preFilledName,
            },
            payload: {
                ...generateChatbotPayload({ feature, subFeature, api }),
                $creativeAssetID: creativeAssetID,
            },
        }),
    );
}

export function getTimeToNextDay(timeId: ClockIdJST, now: number): number {
    // get target time of day minus current time of day
    let remaining = gameDay.nextDayTimers[timeId] - timeFromComponents(timeGetTimeOfDay(now));
    if (remaining < 0) {
        remaining += timeFromComponents({ days: 1 });
    }

    return remaining;
}

// raw time, if negative add manually from caller
export function getTimeToClockTime(timeId: ClockIdJST, now: number): number {
    // get target time of day minus current time of day
    return gameDay.nextDayTimers[timeId] - timeFromComponents(timeGetTimeOfDay(now));
}

export function getDelayPracticeOA(state: State, now: number) {
    if (now < state.updatedAt + timeFromComponents({ days: 3 })) {
        return 0;
    }

    if (now < state.updatedAt + timeFromComponents({ days: 7 })) {
        return timeFromComponents({ days: 3 });
    }

    return timeFromComponents({ days: 7 });
}

export function updatePracticeId(state: MutableState, api: ScheduledActionAPI) {
    // generate new id, make sure its not the same as before
    const ids = gameDay.practiceIds.filter((x) => state.gameDay.practiceId !== x); // make copied array without last id
    const practiceId = arrayRandom(ids, api.math.random);
    state.gameDay.practiceId = practiceId;
}

// gameId is basically the day, game is the game of that gameId (day)
// gameId used to be unique but ab test allowed days to include multiple games
export function getGameDayReward(
    state: State,
    opts: { gameId: number; game?: number; previousTimestamp?: number; teamMorale?: number },
) {
    const { gameId, game, previousTimestamp, teamMorale } = opts;

    const isWin = isWinningResult({ previousTimestamp, teamMorale });
    if (features.multipleGames) {
        return gameDay.gameDayRewardsMultiple[gameId][game ?? 0];
    }

    const index = isWin ? 0 : 1;
    return gameDay.gameDayRewardsTeamMorale[gameId][index];
}

function isWinningResult(opts: { previousTimestamp: number; teamMorale: number }) {
    const { previousTimestamp, teamMorale } = opts;
    if (teamMorale >= 70) return true;
    if (teamMorale < 20) return false;

    // value in between, 50% chance for either result
    const roll = teaHash(previousTimestamp, teamMorale);
    return roll > 0.5;
}

function getGameDayCreativePrefix(opts: { day: number; game: number; messageId: number }): string {
    const { day, game, messageId } = opts;

    const assetMap = [
        // day 1
        [
            ['d1d5g1_1', 'd1d5g1_2', 'd1d5g1_3'],
            ['d1d5g2_1', 'd1d5g2_2', 'd1d5g2_3'],
        ],
        // day 2
        [
            ['d2d6g1_1', 'd2d6g1_2', 'd2d6g1_3'],
            ['d2d6g2_1', 'd2d6g2_2', 'd2d6g2_3'],
        ],
        // day 3
        [
            ['d3d7g1_1', 'd3d7g1_2', 'd3d7g1_3'],
            ['d3d7g2_1', 'd3d7g2_2', 'd3d7g2_3'],
        ],
        // day 4
        [
            ['d4d8g1_1', 'd4d8g1_2', 'd4d8g1_3'],
            ['d4d8g2_1', 'd4d8g2_2', 'd4d8g2_3'],
        ],
        // day 5
        [
            ['d1d5g1_1', 'd1d5g1_2', 'd1d5g1_3'],
            ['d1d5g2_1', 'd1d5g2_2', 'd1d5g2_3'],
        ],
        // day 6
        [
            ['d2d6g1_1', 'd2d6g1_2', 'd2d6g1_3'],
            ['d2d6g2_1', 'd2d6g2_2', 'd2d6g2_3'],
        ],
        // day 7
        [
            ['d3d7g1_1', 'd3d7g1_2', 'd3d7g1_3'],
            ['d3d7g2_1', 'd3d7g2_2', 'd3d7g2_3'],
        ],
        // day 8
        [
            ['d4d8g1_1', 'd4d8g1_2', 'd4d8g1_3'],
            ['d4d8g2_1', 'd4d8g2_2', 'd4d8g2_3'],
        ],
    ];

    return assetMap[day][game][messageId];
}
