import NakedPromise from '../../../lib/pattern/NakedPromise';
import { textLocaleFormat } from '../../../lib/util/textTools';
import { GameId } from '../../../replicant/defs/gameDay';
import { sleep } from '../../../replicant/util/jsTools';
import app from '../../getApp';
import { PlayerClipId } from '../../concept/BaseballPlayer';
import { trackGameDayStep } from '../../lib/analytics/gameDay';
import { GameDayPlayer, GameDayScreen } from '../../main/home/GameDayScreen';
import { GameDayFlow, GameDayFlowOpts, ScoreData } from './GameDayFlow';

const scoreboardData: ScoreData[] = [
    {
        balls: 3,
        strikes: 2,
        outs: 2,
        visitorData: ['1', '2', '1', '1'],
        homeData: ['0', '2', '2'],
        visitorR: 5,
        homeR: 4,
    },
    {
        balls: 0,
        strikes: 2,
        outs: 2,
        visitorData: ['1', '2', '1', '1', '1', '0', '2', '0'],
        homeData: ['0', '2', '2', '0', '1', '2', '0', '1'],
        visitorR: 8,
        homeR: 8,
    },
    {
        balls: 0,
        strikes: 0,
        outs: 0,
        visitorData: ['1', '2', '1', '1', '1', '0', '2', '0', '1'],
        homeData: ['0', '2', '2', '0', '1', '2', '0', '1', '0'],
        visitorR: 9,
        homeR: 8,
    },
];

export class GameDay5Flow extends GameDayFlow {
    // fields
    //-------------------------------------------------------------------------

    // init
    //-------------------------------------------------------------------------
    constructor(opts: GameDayFlowOpts) {
        super(opts);
        this._visitor = app().server.state.name;
        this._home = textLocaleFormat('[gameDay5Opponent]');
        this._opponent = opts.opponentOverride ?? textLocaleFormat('[gameDay5Opponent]');
        this._scoreboard = 'board1';
        this._gameDaySteps = [
            { handler: this._intro, substeps: this._introSubSteps },
            { handler: this._step1, substeps: 4 },
            { handler: this._step2, substeps: 1 },
            { handler: this._step3, substeps: 2 },
            { handler: this._step4, substeps: 1 },
            { handler: this._stepEnding, substeps: this._endingSubSteps },
        ];
    }

    // impl
    //-------------------------------------------------------------------------
    private async _intro() {
        await this._baseIntro({
            scoreData: scoreboardData[0],
            tapDialog: `[gameDay5Step0|${this._home}]`,
            nextBgId: 'gameDay2',
        });
    }

    private async _step1() {
        let stepIndex = this._stepOffset[this._step];

        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay2',
            players: [{ skin: 'character6_girl_red2', clip: 'batter_practice', loop: true } as GameDayPlayer],
        })) as GameDayScreen;
        void app().sound.play(this._whiffSFX);
        await sleep(0.75);
        void app().sound.play(this._whiffSFX);
        await sleep(0.25);

        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay3',
            players: [{ skin: 'character4_girl', clip: 'pitcher_idle', loop: true, scaleX: -1 } as GameDayPlayer],
        })) as GameDayScreen;

        const tapPromise = new NakedPromise();
        await this._gameDayScreen.spawnBubbleTap({
            dialogText: `[gameDay5Step1]`,
            promise: tapPromise,
            bubbleOffset: { x: 0, y: this._gameDayScreen.base.height * 0.5 - this._gameDayScreen.base.height * 0.22 },
            parentOverride: this._gameDayScreen.base,
        });

        void this._gameDayScreen.preloadChoiceView();
        await tapPromise;
        trackGameDayStep({ stepIndex: stepIndex++, stepName: 'pitcher_intro', stepType: 'tap' });

        const choicePromise = new NakedPromise<number>();
        void this._gameDayScreen.spawnBubbleChoice({
            dialogText: '[gameDay5Step2]',
            choices: ['[gameDay5Choice0]', '[gameDay5Choice1]'],
            promise: choicePromise,
            bubbleOffset: { x: 0, y: this._gameDayScreen.base.height * 0.5 - this._gameDayScreen.base.height * 0.22 },
            parentOverride: this._gameDayScreen.base,
        });

        const choiceIndex = await choicePromise;
        trackGameDayStep({ stepIndex: stepIndex++, stepName: 'first_choice', stepType: 'choice' });

        void this._gameDayScreen.playPlayerAnimation({
            playerIndex: 0,
            id: 'pitcher_throw_flipped',
            mix: 0.2,
            loop: false,
            scaleX: -1,
        });
        await sleep(1.25);

        const choices = ['batter_ball_onpurpose', 'batter_foul'];
        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay2',
            players: [{ skin: 'character6_girl_red2', clip: choices[choiceIndex], loop: false } as GameDayPlayer],
        })) as GameDayScreen;

        if (choiceIndex === 1) void app().sound.play(this._batSFX);

        await sleep(choiceIndex === 0 ? 2.5 : 1.25);
        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay3',
            players: [{ skin: 'character4_girl', clip: 'pitcher_idle', loop: true, scaleX: -1 } as GameDayPlayer],
        })) as GameDayScreen;

        const choiceDialogs = ['[gameDay5Step3sub0]', '[gameDay5Step3sub1]'];
        const coachPromise = new NakedPromise();
        await this._gameDayScreen.spawnBubbleTap({
            dialogText: choiceDialogs[choiceIndex],
            promise: coachPromise,
            bubbleOffset: { x: 0, y: this._gameDayScreen.base.height * 0.5 - this._gameDayScreen.base.height * 0.22 },
            parentOverride: this._gameDayScreen.base,
        });

        void this._gameDayScreen.preloadScoreboard(this._scoreboard);
        await coachPromise;
        trackGameDayStep({ stepIndex: stepIndex++, stepName: 'coach', stepType: 'tap' });

        void this._gameDayScreen.playPlayerAnimation({
            playerIndex: 0,
            id: 'pitcher_throw_flipped',
            mix: 0.2,
            loop: false,
            scaleX: -1,
        });

        void app().resource.loadAsset(this._whiffSFX);
        await sleep(1.25);

        const choiceSkins = ['character3_boy_red', 'character6_girl_red2'];
        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay2',
            players: [{ skin: choiceSkins[choiceIndex], clip: 'batter_strikeout', loop: false } as GameDayPlayer],
        })) as GameDayScreen;

        void app().sound.play(this._whiffSFX);

        await sleep(1.25);
        void this._gameDayScreen.playPlayerAnimation({ playerIndex: 0, id: 'batter_sad', mix: 0.3, loop: true });

        const greatPromise = new NakedPromise();
        const choiceStrikes = ['[gameDay5Step4sub0]', '[gameDay5Step4sub1]'];
        await this._gameDayScreen.spawnBubbleTap({
            dialogText: choiceStrikes[choiceIndex],
            promise: greatPromise,
            narrator: 'arrow',
            bubbleOffset: { x: 0, y: this._gameDayScreen.base.height - 150 },
            parentOverride: this._gameDayScreen.base,
        });

        await greatPromise;
        trackGameDayStep({ stepIndex: stepIndex++, stepName: 'strike', stepType: 'tap' });
    }

    private async _step2() {
        let stepIndex = this._stepOffset[this._step];

        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay3',
        })) as GameDayScreen;

        void this._gameDayScreen.spawnScoreDynamic({
            ...scoreboardData[1],
            scoreboard: this._scoreboard,
            home: this._home,
            visitor: this._visitor,
        });

        const scorePromise = new NakedPromise();
        await this._gameDayScreen.spawnBubbleTap({
            dialogText: `[gameDay5Step5]`,
            promise: scorePromise,
            narrator: 'arrow',
            bubbleOffset: { x: 0, y: this._gameDayScreen.base.height - 150 },
            parentOverride: this._gameDayScreen.base,
        });

        void app().nav.preload('gameDayScreen', { bgId: 'gameDay0' });
        await scorePromise;
        trackGameDayStep({ stepIndex: stepIndex++, stepName: 'tied', stepType: 'tap' });
    }

    private async _step3() {
        let stepIndex = this._stepOffset[this._step];
        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay0',
            players: [
                {
                    clip: 'onbase1_flipped',
                    loop: true,
                    skin: 'character1_boy',
                    offsetX: 20,
                } as GameDayPlayer,
            ],
        })) as GameDayScreen;

        void app().nav.preload('gameDayScreen', { bgId: 'gameDay1' });
        await sleep(1.25);

        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay1',
            players: [
                {
                    clip: 'onbase3',
                    skin: 'character2_girl',
                    loop: true,
                },
            ] as GameDayPlayer[],
        })) as GameDayScreen;

        void app().nav.preload('gameDayScreen', { bgId: 'gameDay2' });
        await sleep(1.25);

        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay2',
            players: [{ skin: 'character5_boy', clip: 'batter_idle', loop: true } as GameDayPlayer],
        })) as GameDayScreen;

        const tapPromise = new NakedPromise();
        await this._gameDayScreen.spawnBubbleTap({
            dialogText: `[gameDay5Step6]`,
            promise: tapPromise,
            bubbleOffset: { x: 0, y: this._gameDayScreen.base.height * 0.5 - this._gameDayScreen.base.height * 0.22 },
            parentOverride: this._gameDayScreen.base,
        });

        void this._gameDayScreen.preloadChoiceView();
        await tapPromise;
        trackGameDayStep({ stepIndex: stepIndex++, stepName: 'batter_idle', stepType: 'tap' });

        const choicePromise = new NakedPromise<number>();
        void this._gameDayScreen.spawnBubbleChoice({
            dialogText: '[gameDay5Step7]',
            choices: ['[gameDay5Choice2]', '[gameDay5Choice3]'],
            promise: choicePromise,
            bubbleOffset: { x: 0, y: this._gameDayScreen.base.height * 0.5 - this._gameDayScreen.base.height * 0.22 },
            parentOverride: this._gameDayScreen.base,
        });

        void app().nav.preload('gameDayScreen', { bgId: 'gameDay3' });

        const choiceIndex = await choicePromise;
        trackGameDayStep({ stepIndex: stepIndex++, stepName: 'second_choice', stepType: 'choice' });

        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay3',
            players: [{ skin: 'character6_girl_red2', clip: 'pitcher_throw_flipped', loop: false } as GameDayPlayer],
        })) as GameDayScreen;

        void app().resource.loadAsset(this._batSFX);
        void app().nav.preload('gameDayScreen', { bgId: 'gameDay2' });
        await sleep(0.8);

        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay2',
            players: [{ skin: 'character5_boy', clip: 'batter_idle' } as GameDayPlayer],
        })) as GameDayScreen;

        await sleep(0.2);

        const choiceAction = [
            // choice 1
            async () => this._gameDayScreen.playPlayerAnimation({ id: 'batter_homerun' }),
            // choice 2
            async () => this._gameDayScreen.playPlayerAnimation({ id: 'batter_bunt' }),
        ];

        void sleep(choiceIndex === 0 ? 0.45 : 0.25).then(() => app().sound.play(this._batSFX));
        await choiceAction[choiceIndex]();

        const choiceClipId: PlayerClipId[] = [
            // choice 1
            'pitcher_watchhomerun',
            // choice 2
            'pitcher_watchbunt',
        ];

        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay3',
            players: [{ skin: 'character6_girl_red2', clip: choiceClipId[choiceIndex], loop: false } as GameDayPlayer],
        })) as GameDayScreen;

        await sleep(choiceIndex === 0 ? 1.4 : 1.8);
    }

    private async _step4() {
        let stepIndex = this._stepOffset[this._step];
        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay2',
            players: [
                {
                    skin: 'character2_girl',
                    clip: 'run',
                    offsetX: 200,
                    offsetY: 40,
                    loop: true,
                    scaleX: -1,
                    animateRun: true,
                } as GameDayPlayer,
            ],
        })) as GameDayScreen;

        void app().sound.play(this._cheerSFX);

        const runPromise = new NakedPromise();
        await this._gameDayScreen.spawnBubbleTap({
            dialogText: `[gameDay5Step8]`,
            promise: runPromise,
            narrator: 'arrow',
            bubbleOffset: { x: 0, y: this._gameDayScreen.base.height - 150 },
            parentOverride: this._gameDayScreen.base,
        });
        await runPromise;
        trackGameDayStep({ stepIndex: stepIndex++, stepName: 'run', stepType: 'tap' });
    }

    private async _stepEnding() {
        await this._baseVictory({ scoreData: scoreboardData[2], tapDialog: `[gameDay5Step9|${this._home}]` });
    }
}
