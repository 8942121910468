import { Sprite } from 'pixi.js';

import { uiAlignCenterY, uiAlignRight } from '../../../lib/pixi/uiTools';
import StatsView from './StatsView';
import app from '../../getApp';

const manifest = {
    icon: 'icon.coin.png',
    plus: 'button.plus.png',
};

export default class MiniCoinView extends StatsView {
    public get icon() {
        return this._icon;
    }

    constructor() {
        super({ startAmount: app().game.player.coins });
        this._icon = Sprite.from(manifest.icon);
        const plus = Sprite.from(manifest.plus);
        this._icon.scale.set(0.9);
        this._icon.x = 8;
        uiAlignCenterY(this, this._icon, -3);
        this.addChild(this._icon, plus);

        uiAlignCenterY(this, plus, 3);
        uiAlignRight(this._bg, plus, 20);

        this._observer.listen(
            () => app().game.player.coins,
            (stats) => {
                this._updateAmount(stats);
            },
        );
        this._observer.start();
    }

    public static override assets(): string[] {
        return [...StatsView.assets(), ...Object.values(manifest)];
    }
}
