import { action } from '@play-co/replicant';

import { Coins, CurrencyFeature, getCurrencyEvent } from '../analytics/currency';
import { getCoinBalance } from '../defs/player';
import { coinsProductDefs, CoinsProductId } from '../defs/product';
import { MutableState, SyncActionAPI } from '../defs/replicant';

// actions
//-----------------------------------------------------------------------------
export const shopActions = {
    coinsPurchase: action((state: MutableState, options: { id: CoinsProductId }, api: SyncActionAPI) => {
        const { id } = options;
        const { getCost, action } = coinsProductDefs[id];

        const featureMap: Record<CoinsProductId, CurrencyFeature> = {
            // regular booster
            dart: 'boosters',
            bullet: 'boosters',
            drill: 'boosters',
            roulette: 'boosters',
            // power booster
            baseball: 'power_blocks',
            bomb: 'power_blocks',
            rocket: 'power_blocks',
            // general
            moves: 'puzzle_continue',
            lives: 'refill_lives',
        };

        const cost = getCost(state);

        if (getCoinBalance(state) < cost) throw new Error(`Not enough coins to complete purchase`);
        consumeCoins({ amount: cost, feature: featureMap[id], subFeature: id }, state, api);

        action(state, api);
    }),
};

export function grantCoins(
    state: MutableState,
    api: SyncActionAPI,
    opts: {
        type: 'coins' | 'coinsPremium';
        amount: number;
        feature: CurrencyFeature;
        subFeature?: string;
    },
) {
    const { type, amount, feature, subFeature } = opts;
    const oldCurrencies = { freeCoins: state.coins, paidCoins: state.coinsPremium, totalCoins: getCoinBalance(state) };

    const grantActionMap: Record<Coins, (state: MutableState, amount: number) => void> = {
        coins: (state: MutableState, amount: number) => (state.coins += amount),
        coinsPremium: (state: MutableState, amount: number) => (state.coinsPremium += amount),
    };

    // mutate state before creating and sending analytics event
    grantActionMap[type](state, amount);
    const event = getCurrencyEvent(state, {
        event: 'grant',
        oldCurrencies,
        feature,
        subFeature,
    });

    api.sendAnalyticsEvents([event]);
}

export function consumeCoins(
    opts: {
        amount: number;
        feature: CurrencyFeature;
        subFeature?: string;
    },
    state: MutableState,
    api?: SyncActionAPI,
) {
    const { amount, feature, subFeature } = opts;
    const oldCurrencies = { freeCoins: state.coins, paidCoins: state.coinsPremium, totalCoins: getCoinBalance(state) };

    // mutate state before creating and sending analytics event
    // premium coins are consumed first
    const paid = Math.min(state.coinsPremium, amount);
    const free = Math.max(amount - paid, 0);
    state.coinsPremium -= paid;
    state.coins -= free;

    const event = getCurrencyEvent(state, {
        event: 'consume',
        oldCurrencies,
        feature,
        subFeature,
    });

    // admin message has no api
    if (api) {
        api.sendAnalyticsEvents([event]);
    }
}
