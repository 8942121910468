import app from '../../../../getApp';
import { CubeBlockProps } from '../../defs/block';
import { CollideEvent, CollideId } from '../../defs/collide';
import type { BlockEntity } from '../../entities/BlockEntity';
import { despawnBlockEntity } from '../../entities/BlockEntity';
import { GameScene } from '../../GameScene';
import { mapGetPan } from '../../util/mapTools';
import { CubeEffect } from '../effects/CubeEffect';
import { PowerComboEffect } from '../effects/PowerComboEffect';
import { CollideHandler, ICollision } from './ICollision';

/*
    cube block collision resolver
*/
export class CubeCollision implements ICollision {
    // fields
    //-------------------------------------------------------------------------
    // input
    private readonly _scene: GameScene;
    private readonly _entity: BlockEntity;
    // map
    private readonly _resolvers: { [key in CollideId]?: CollideHandler } = {
        tap: this._resolveTap,
        attack: this._resolveAttack,
    };

    // init
    //-------------------------------------------------------------------------
    constructor(scene: GameScene, entity: BlockEntity) {
        this._scene = scene;
        this._entity = entity;
    }

    // impl
    //-------------------------------------------------------------------------
    public async resolve(event: CollideEvent): Promise<boolean> {
        return this._resolvers[event.id]?.call(this, event);
    }

    // private: resolvers
    //-------------------------------------------------------------------------
    private async _resolveTap(event: CollideEvent) {
        // attempt potential combo else explode cube
        const combo = new PowerComboEffect(this._scene, this._entity);
        if (!(await combo.execute())) await this._explode();

        return true;
    }

    private async _resolveAttack(event: CollideEvent) {
        // explode cube
        await this._explode();
        return true;
    }

    // private: actions
    //-------------------------------------------------------------------------
    private async _explode() {
        // get cube props
        const props = this._entity.c.block.props as CubeBlockProps;

        // play sound
        void app().sound.play('puzzle-cube-activate.mp3', {
            rate: 1.4,
            pan: mapGetPan(this._entity.c.position.mapPosition),
        });

        // despawn block
        despawnBlockEntity(this._scene, this._entity);

        // notify
        this._scene.events.publish({ id: 'power', type: 'cube' });

        // execute cube effect
        return new CubeEffect(this._scene, {
            position: this._entity.c.position.mapPosition,
            color: props.color,
        }).execute();
    }
}
