import type { Component, Entity2D } from '@play-co/odie';

import { PondBlockProps } from '../defs/block';
import { PondBlockLayoutType, PondBlockView } from '../views/blocks/PondBlockView';

// types
//-----------------------------------------------------------------------------
export type PondBlockComponentOptions = {
    props: PondBlockProps;
    option?: number;
};

/*
    pond block component
*/
export class PondBlockComponent implements Component {
    // constants
    //-------------------------------------------------------------------------
    public static readonly NAME = 'blockPond';

    // fields
    //-------------------------------------------------------------------------
    // injected
    public entity!: Entity2D;
    // scene
    public view!: PondBlockView;

    private _destructionInProgress!: boolean;

    // properties
    //-------------------------------------------------------------------------
    public get damage(): number {
        return this.view.damage;
    }

    public set damage(value: number) {
        this.view.damage = value;
    }

    public set layout(layout: PondBlockLayoutType) {
        this.view.layout0 = layout;
    }

    public get layout(): PondBlockLayoutType {
        return this.view.layout0;
    }

    public set destructionInProgress(value: boolean) {
        this._destructionInProgress = value;
    }

    public get destructionInProgress(): boolean {
        return this._destructionInProgress;
    }

    // current option
    public get option(): number {
        return this.getGameplayState();
    }

    // impl
    //-------------------------------------------------------------------------
    public init(options: PondBlockComponentOptions): void {
        this.view = new PondBlockView(options.props);
        this.view.damage = options.option || 0;
        this._destructionInProgress = false;
        this.entity.c.block.getGameplayState = () => this.getGameplayState();
    }

    public getGameplayState(): number {
        return this.damage;
    }
}
