import {
    Application,
    aspectResize,
    I18nPlugin,
    NakedPromise,
    ResizePlugin,
    ResourcePlugin,
    SoundPlugin,
    StagePlugin,
    VisibilityPlugin,
} from '@play-co/astro';
import { configureDebugPanel } from '@play-co/debug-panel';

import { PerformanceAnalytics } from '../lib/performance/PerformanceAnalytics';
import { ParticlesPlugin } from '../lib/pixi/particles/ParticlesPlugin';
import { pixiSetScene } from '../lib/pixi/pixiTools';
// @ts-ignore manifest.json does not exist before client build
import manifest from '../manifest.json';
import { InstantGame } from '../plugins/instantGames/InstantGame';
import { InstantGamePlugin } from '../plugins/instantGames/InstantGamesPlugin';
import { FlexLayoutPlugin } from '../plugins/layout/FlexLayoutPlugin';
import { NavPlugin } from '../plugins/nav/NavPlugin';
import { ResourceExPlugin } from '../plugins/resourceEx/ResourceExPlugin';
import { getAvailableGameDayAB } from '../replicant/components/gameDay';
import { shouldStartTaskIntroTutorial } from '../replicant/components/task';
import { PowerBoosterId } from '../replicant/defs/booster';
import gameConfig from '../replicant/defs/gameConfig';
import { isValidLineId } from '../replicant/util';
import { BusyComponent } from './components/BusyComponent';
import { cheatUi } from './defs/cheats';
import { pixiConfig } from './defs/config';
import { NavLayer, navLoaders, navScreens } from './defs/nav';
import { languageFontMap } from './defs/text';
import { tipMap } from './defs/tips';
import { GameDayMainFlow } from './flows/gameday/GameDayMainFlow';
import { PreLaunchFlow } from './flows/PreLaunchFlow';
import { PuzzlePlayFlow } from './flows/PuzzlePlayFlow';
import { RestoreStateFlow } from './flows/RestoreStateFlow';
import { StartFlow } from './flows/StartFlow';
import { TaskIntroFlow } from './flows/TaskIntroFlow';
import { TutorialFlow } from './flows/tutorials/TutorialFlow';
import { TutorialPuzzleFlow } from './flows/tutorials/TutorialPuzzleFlow';
import { TutorialTaskIntroFlow } from './flows/tutorials/TutorialTaskIntroFlow';
import { ShopPinchRestore, trackRefillLivesPinchConversion } from './lib/analytics/pinch';
import { getForcedLanguage, getUserPreferredLanguage } from './lib/util/device';
import { HomeScreen } from './main/home/HomeScreen';
import { AnalyticsService } from './services/AnalyticsService';
import { CoreService } from './services/CoreService';
import { GameService } from './services/GameService';
import { InputService } from './services/InputService';
import { MusicService } from './services/MusicService';
import { PlatformService } from './services/PlatformService';
import { ProductService } from './services/ProductService';
import { PuzzleMapService } from './services/PuzzleMapService';
import { SettingsService } from './services/SettingsService';
import { SoundService } from './services/SoundService';
import { TableStoreService } from './services/TableStoreService';
import { TipService } from './services/TipService';
import app, { setApp } from './getApp';
import { shouldTriggerMoralePinchFlow } from '../replicant/components/teamMorale';
import { TeamMoralePinchFlow } from './flows/TeamMoralePinchFlow';
import { getAbTest } from '../replicant/util/replicantTools';
import { TutorialOriginalConceptFlow } from './flows/tutorials/TutorialOriginalConceptFlow';

/*
    application core
*/
export class App extends Application {
    // fields
    //-------------------------------------------------------------------------
    // plugins
    public i18nPlugin!: I18nPlugin;
    public instantGamePlugin!: InstantGamePlugin;
    public nav!: NavPlugin;
    public resizePlugin!: ResizePlugin;
    public resourcePlugin!: ResourcePlugin;
    public resource!: ResourceExPlugin;
    public soundPlugin!: SoundPlugin;
    public stage!: StagePlugin;
    public particles!: ParticlesPlugin;
    public visibilityPlugin!: VisibilityPlugin;
    public flexLayoutPlugin!: FlexLayoutPlugin;
    // services (app specific plugins)
    public core!: CoreService;
    public input!: InputService;
    public platform!: PlatformService;
    public game!: GameService;
    public analytics!: AnalyticsService;
    public music!: MusicService;
    public product!: ProductService;
    public settings!: SettingsService;
    public sound!: SoundService;
    public tableStore!: TableStoreService;
    public tip!: TipService;
    public puzzleMap!: PuzzleMapService;
    // components
    public busy: BusyComponent;
    // state
    public server: typeof InstantGame.replicant;

    // init
    //-------------------------------------------------------------------------
    constructor() {
        super(pixiConfig);
        setApp(this);
    }

    // impl
    //-------------------------------------------------------------------------
    public async run(): Promise<void> {
        // init plugins
        this._initPlugins();

        // init services
        this._initServices();

        // init application (await inits all plugins then services in order)
        await this.init();

        // create busy component
        this.busy = new BusyComponent();

        if (!process.env.REPLICANT_OFFLINE && !isValidLineId(app().server.state.id)) {
            void this.showAlert('Unknown error.');
            return;
        }

        // post init start
        void this._start();
    }

    // api
    //-------------------------------------------------------------------------
    //TODO: move next 3 into UiService
    // show an alert popup with an optional title
    public async showAlert(message: string, title = '[popupErrorGeneralTitle]') {
        // open error popup and wait on close
        await new Promise((resolve) =>
            this.nav.open('infoPopup', {
                title,
                infoText: message,
                underlay: 0.6,
                onOk: resolve,
                onClose: resolve,
            }),
        );

        // close
        await this.nav.close('infoPopup');
    }

    /*
        an error specific alert
    */
    public async showError(error: Error) {
        return this.showAlert(error.message);
    }

    // private: start
    //-------------------------------------------------------------------------
    private async _start() {
        // open debug menu
        configureDebugPanel({
            replicant: this.server,
            ui: cheatUi,
        });

        // Initialize performance analytics
        PerformanceAnalytics.init();

        // prelaunch flow
        await new PreLaunchFlow().execute();

        // Must trigger EntryFinal after PreLaunchFlow due to crossplay EntryFinal analytics
        await InstantGame.sendEntryFinal();

        const entryData = InstantGame.platform.entryData as { [key: string]: unknown };
        // restore state flow
        if (
            await new RestoreStateFlow({
                openShop: !!entryData?.openIAP,
                shopPinch: entryData?.shopPinch as ShopPinchRestore,
            }).execute()
        ) {
            return;
        }
        // override everything, open home with shop overlay
        if (entryData?.openIAP) {
            const home = (await app().nav.open('homeScreen', {})) as HomeScreen;
            await home.openShop();

            if (entryData?.shopPinch === 'lives') {
                if (app().game.player.coins >= gameConfig.lives.cost) {
                    // consume lives and launch into puzzle
                    await app().server.invoke.coinsPurchase({ id: 'lives' });
                    trackRefillLivesPinchConversion(true);
                    // now launch into puzzle directly
                    const crossplayPowerBoosters = entryData?.crossplayPowerBoosters as PowerBoosterId[];
                    await new PuzzlePlayFlow({
                        forceStart: true,
                        forcePowerBoosters: crossplayPowerBoosters,
                    }).execute();
                    return;
                }
            }
        }
        // --------------------------

        const state = app().server.state;
        // main tutorial completed
        if (state.tutorial.complete) {
            await this._defaultStartFlow();
        } else {
            // else run tutorial
            await new TutorialFlow().execute();
            await this._defaultStartFlow(true);
        }
    }

    private async _defaultStartFlow(justFinishedTutorial = false) {
        const state = app().server.state;

        const { index } = getAvailableGameDayAB(state, app().server.now());

        if (shouldTriggerMoralePinchFlow(state)) {
            await new TeamMoralePinchFlow({
                pinchId: state.teamMorale.pinchFlow.pinchId,
                scenarioId: state.teamMorale.pinchFlow.scenarioId,
            }).execute();
        }
        if (index !== -1 && !justFinishedTutorial) {
            await new GameDayMainFlow().execute();
        }

        if (getAbTest(state, '0009_OriginalConcept') === 'Enabled') {
            // 0009_OriginalConcept Enabled behavior
            await new TutorialOriginalConceptFlow().execute();
        } else {
            // 0009_OriginalConcept Control behavior
            if (state.puzzle.lastLevel === 0) {
                // homescreen with scripted puzzle navigation
                await new TutorialPuzzleFlow().execute();
            } else {
                if (shouldStartTaskIntroTutorial(state, app().server.now())) {
                    await new TutorialTaskIntroFlow().execute();
                } else {
                    // default flow after tutorial(s), finish any task sequence and then run start flow
                    if (!state.task.introSeen) {
                        // trigger intro flow and then go to task screen or home screen
                        await new TaskIntroFlow({ taskLevel: state.task.level }).execute();
                    } else {
                        const homeScreen = (await app().nav.open('homeScreen', {})) as HomeScreen;
                        // trigger start flow only if its homescreen opening
                        await new StartFlow({ homeScreen }).execute();
                    }
                }
            }
        }
    }

    // private: init
    //-------------------------------------------------------------------------
    private _initPlugins(): void {
        // install stage plugin and register the root scene
        this.stage = this.add(StagePlugin, { name: 'stage' });
        pixiSetScene(this.stage.stage);

        // install resize plugin
        this.resizePlugin = this.add(ResizePlugin, {
            resizeFunction: aspectResize(pixiConfig.size.width, pixiConfig.size.height),
        });

        // install resource plugins
        this.resourcePlugin = this.add(ResourcePlugin, {
            name: 'resource',
            manifest,
            basePath: 'assets',
        });
        this.resource = this.add(ResourceExPlugin, {
            name: 'resourceEx',
        });

        // install particle plugin
        this.particles = this.add(ParticlesPlugin);

        // install instant games plugin
        this.instantGamePlugin = this.add(InstantGamePlugin);

        // add navigation plugin
        this.nav = this.add(NavPlugin, {
            layers: NavLayer.count,
            screens: navScreens,
            loaders: navLoaders,
            preload: this._getLaunchScene.bind(this),
        });

        // install sound plugin
        this.soundPlugin = this.add(SoundPlugin, {});

        // install visibility plugin
        this.visibilityPlugin = this.add(VisibilityPlugin);

        // install i18n (localization) plugin. needs to be down here or errors
        this.i18nPlugin = this.add(I18nPlugin, {
            //generatedFontLanguages: ['en','ja'], // this should match the `fontLanguages` in `fido-config.json`
            entryDefaults: languageFontMap,
            //language: 'ja',
            defaultManifestID: 'i18n',
        });

        // install flex layout plugin
        this.flexLayoutPlugin = this.add(FlexLayoutPlugin, {
            root: this.stage.stage,
            renderer: this.stage.renderer,
        });
    }

    private _initServices(): void {
        // add core service
        this.core = this.add(CoreService, {});

        // add input service
        this.input = this.add(InputService, {});

        // add analytics service
        this.analytics = this.add(AnalyticsService, {});

        // add tip service
        this.tip = this.add(TipService, {
            tips: tipMap,
        });

        // add settings service
        const forcedLanguage = getForcedLanguage();
        const preferredLanguage = getUserPreferredLanguage();
        this.settings = this.add(SettingsService, { forcedLanguage, preferredLanguage });

        // add table store service
        this.tableStore = this.add(TableStoreService, {});

        // add music service
        this.music = this.add(MusicService, {});

        // add sound service
        this.sound = this.add(SoundService, {
            channels: 16,
        });

        // add platform service
        this.platform = this.add(PlatformService, {});

        // add product service
        this.product = this.add(ProductService, {});

        // add puzzle map service
        this.puzzleMap = this.add(PuzzleMapService, {});

        // add game service
        this.game = this.add(GameService, {});
    }

    // private: support
    //-------------------------------------------------------------------------
    private _getLaunchScene(): string {
        return 'homeScreen';
    }
}
