import { PondBlockComponent } from '../../components/PondBlockComponent';
import { CollideEvent, CollideId } from '../../defs/collide';
import { config } from '../../defs/config';
import type { PondBlockEntity } from '../../entities/BlockEntity';
import { GameScene } from '../../GameScene';
import { InvalidEffect } from '../effects/InvalidEffect';
import { CollideHandler, ICollision } from './ICollision';

/*
    pond block collision resolver
*/
export class PondCollision implements ICollision {
    // fields
    //-------------------------------------------------------------------------
    // input
    private readonly _scene: GameScene;
    private readonly _entity: PondBlockEntity;
    // map
    private readonly _resolvers: { [key in CollideId]?: CollideHandler } = {
        tap: this._resolveTap,
        block: this._resolveBlock,
        attack: this._resolveAttack,
    };

    // init
    //-------------------------------------------------------------------------
    constructor(scene: GameScene, entity: PondBlockEntity) {
        this._scene = scene;
        this._entity = entity;
    }

    // impl
    //-------------------------------------------------------------------------
    public async resolve(event: CollideEvent) {
        return this._resolvers[event.id]?.call(this, event) ?? false;
    }

    // private: resolvers
    //-------------------------------------------------------------------------
    private async _resolveTap(event: CollideEvent) {
        const position = this._entity.c.position.mapPosition;
        const view = this._entity.c.blockPond.view;
        const damage = this._entity.c.blockPond.damage;

        if (damage >= config.blocks.pond.damage) return false;

        view.onTap();
        // new SplashEffect(this._scene, {
        //   assets: [
        //     getMatch2AssetPath('fx.pond/fx.pond.water.drop.png'),
        //     getMatch2AssetPath('fx.pond/fx.pond.water.droplet.png'),
        //   ],
        //   position,
        //   scale: [
        //     { x: 0.3, y: 0.3 },
        //     { x: 0.8, y: 0.8 },
        //   ],
        //   amount: 4,
        // }).execute();
        await new InvalidEffect(this._entity).execute();
        return false;
    }

    private async _resolveBlock(event: CollideEvent) {
        const collider = event.collider;
        const colliderType = collider?.type;

        if (colliderType === 'block') {
            const colliderPropsType = collider?.entity.c.block.props.type;

            if (colliderPropsType === 'basic') {
                await this._attack();
                return true;
            }
        }
        return false;
    }

    private async _resolveAttack() {
        await this._attack();
        return true;
    }

    private async _attack() {
        const damage = ++this._entity.c.blockPond.damage;
        const modifiedData = {
            damagePondLink: damage <= config.blocks.pond.damage,
        };

        // let PondBlockSystem handle block damage / pond destruction
        this._entity.setModified(PondBlockComponent, modifiedData);
    }
}
