import { StagePlugin } from '@play-co/astro';
import { UISchema } from '@play-co/debug-panel';
import { Container } from 'pixi.js';

import {
    FirstSessionAssetKey,
    FirstSessionAssetKeyV2,
    firstSessionOA,
    firstSessionV2OA,
} from '../../replicant/chatbot/messageTemplates';
import { getAvailableGameDayAB, getNextGameDayOA, getNextSecondGameOA } from '../../replicant/components/gameDay';
import { boosterIds } from '../../replicant/defs/booster';
import gameConfig from '../../replicant/defs/gameConfig';
import settings, { LanguageId } from '../../replicant/defs/settings';
import { sleep } from '../../replicant/util/jsTools';
import { timeFromComponents } from '../../replicant/util/timeTools';
import { gameDayOA } from '../creatives/chatbot/gameDay';
import { GameDay0Flow } from '../flows/gameday/GameDay0Flow';
import { GameDay1Flow } from '../flows/gameday/GameDay1Flow';
import { GameDay2Flow } from '../flows/gameday/GameDay2Flow';
import { GameDay3Flow } from '../flows/gameday/GameDay3Flow';
import { GameDay4Flow } from '../flows/gameday/GameDay4Flow';
import { GameDay5Flow } from '../flows/gameday/GameDay5Flow';
import { GameDay6Flow } from '../flows/gameday/GameDay6Flow';
import { GameDay7Flow } from '../flows/gameday/GameDay7Flow';
import { HomeSequenceFlow } from '../flows/HomeSequenceFlow';
import { SetNameFlow } from '../flows/SetNameFlow';
import { openURL } from '../lib/tools/appTools';
import { getActivePuzzleController } from '../lib/tools/puzzleTools';
import { windowReload } from '../lib/util/device';
import { HomeScreen } from '../main/home/HomeScreen';
import app from '../getApp';
import { getTeamMoraleScore, getTimeToMoralePinch } from '../../replicant/components/teamMorale';

// constants
//-----------------------------------------------------------------------------
const debugUrlKey = 'debugUrl';

// schema
//-----------------------------------------------------------------------------
export const cheatUi: UISchema = {
    Navigate: {
        Home: () => {
            void void app().nav.open('homeScreen', {});
        },
        Reset: async () => {
            await app().server.invoke.coreReset();
            await windowReload();
        },
        'Reset(skip tutorial)': async () => {
            await app().server.invoke.coreReset();
            await app().server.invoke.tutorialComplete();
            await app().server.invoke.settingsSetName({ name: settings.defaultNameEN });
            await windowReload();
        },
        'Reset(skip tutorial + sentInit)': async () => {
            await app().server.invoke.coreReset();
            await app().server.invoke.tutorialComplete();
            await app().server.invoke.settingsSetName({ name: settings.defaultNameEN });
            await app().server.invoke.cheatSentInitTrue();
            await windowReload();
        },
        'Reset(skip full tutorial + sentInit)': async () => {
            await app().server.invoke.coreReset();
            await app().server.invoke.tutorialComplete();
            await app().server.invoke.settingsSetName({ name: settings.defaultNameEN });
            await app().server.invoke.cheatSentInitTrue();
            await app().server.invoke.puzzleSetLevel({ level: 2 });
            await app().server.invoke.cheatGameDayComplete();
            await app().server.invoke.cheatInitTasks();
            await app().server.invoke.updateTaskTutorial({ stepIndex: 0 });
            await app().server.invoke.triggerDailyBonusTimestamp();
            await app().server.invoke.cheatGameSetMorale({ value: 70 });
            await windowReload();
        },
    },
    Cheats: {
        LivesReset: async () => {
            await app().server.invoke.cheatLivesSet({ amount: 0 });
        },
        LivesPlus: async () => {
            await app().server.invoke.cheatLivesAdd({ amount: 1 });
        },
        LivesMax: async () => {
            await app().server.invoke.cheatLivesSet({ amount: gameConfig.lives.max });
        },
        InfiniteLives5Min: async () => {
            await app().server.invoke.cheatLivesInfinite({ duration: timeFromComponents({ minutes: 5 }) });
            console.log(`-- infinite lives for 5 minutes added`);
        },
        StarsAdd50: async () => {
            await app().server.invoke.cheatAddStars({ amount: 50 });
        },
        StarsMinus50: async () => {
            await app().server.invoke.cheatAddStars({ amount: -50 });
        },
        CoinsAdd50: async () => {
            await app().server.invoke.cheatAddCoins({ amount: 50 });
        },
        CoinsMinus50: async () => {
            await app().server.invoke.cheatAddCoins({ amount: -50 });
        },
        CoinsPremiumAdd50: async () => {
            await app().server.invoke.cheatAddPremiumCoins({ amount: 50 });
        },
        CoinsPremiumMinus50: async () => {
            await app().server.invoke.cheatAddPremiumCoins({ amount: -50 });
        },
        SetInitMessageTrue: async () => {
            await app().server.invoke.cheatSentInitTrue();
        },
        SetGameDayComplete: async () => {
            await app().server.invoke.cheatGameDayComplete();
            await windowReload();
        },
        SetSecondGameComplete: async () => {
            await app().server.invoke.cheatSecondGameComplete();
            await windowReload();
        },
        CompleteTaskLevel: async () => {
            await app().server.invoke.cheatCompleteTaskSetLevel({});
            await windowReload();
        },
        CompleteTaskLevelSkipIntro: async () => {
            await app().server.invoke.cheatCompleteTaskSetLevel({ skipIntro: true });
            await windowReload();
        },
        SetFinalTaskLevel: async () => {
            await app().server.invoke.cheatSetFinalTaskLevel();
            await windowReload();
        },
    },
    TeamMorale: {
        TeamMorale: {
            min: 0,
            max: 100,
            step: 1,
            get: () => getTeamMoraleScore(app().server.state, app().server.now()),
            set: async (value: number) => {
                await app().server.invoke.cheatGameSetMorale({ value });
            },
        },
        forceTeamMoraleExit: async () => {
            await app().server.invoke.forceMoraleExitEvent();
            console.log(`-- force team morale exit`);
        },
        TimeToPinch50: () =>
            console.log(
                `-- time to pinch 50: ${getTimeToMoralePinch(app().server.state, 'first', app().server.now()) / 1000 / 60 / 60} hours`,
            ),
        TimeToPinch10: () =>
            console.log(
                `-- time to pinch 10: ${getTimeToMoralePinch(app().server.state, 'second', app().server.now()) / 1000 / 60 / 60} hours`,
            ),
        PinchFlow50_1: async () => {
            await app().server.invoke.cheatGenerateMoralePinch({ scenarioId: 0, pinchId: 0 });
            await windowReload();
        },
        PinchFlow50_2: async () => {
            await app().server.invoke.cheatGenerateMoralePinch({ scenarioId: 1, pinchId: 0 });
            await windowReload();
        },
        PinchFlow50_3: async () => {
            await app().server.invoke.cheatGenerateMoralePinch({ scenarioId: 2, pinchId: 0 });
            await windowReload();
        },
        PinchFlow50_4: async () => {
            await app().server.invoke.cheatGenerateMoralePinch({ scenarioId: 3, pinchId: 0 });
            await windowReload();
        },
        PinchFlow50_5: async () => {
            await app().server.invoke.cheatGenerateMoralePinch({ scenarioId: 4, pinchId: 0 });
            await windowReload();
        },
        PinchFlow10_1: async () => {
            await app().server.invoke.cheatGenerateMoralePinch({ scenarioId: 0, pinchId: 1 });
            await windowReload();
        },
        PinchFlow10_2: async () => {
            await app().server.invoke.cheatGenerateMoralePinch({ scenarioId: 1, pinchId: 1 });
            await windowReload();
        },
        PinchFlow10_3: async () => {
            await app().server.invoke.cheatGenerateMoralePinch({ scenarioId: 2, pinchId: 1 });
            await windowReload();
        },
        PinchFlow10_4: async () => {
            await app().server.invoke.cheatGenerateMoralePinch({ scenarioId: 3, pinchId: 1 });
            await windowReload();
        },
        PinchFlow10_5: async () => {
            await app().server.invoke.cheatGenerateMoralePinch({ scenarioId: 4, pinchId: 1 });
            await windowReload();
        },
    },
    GameDays: {
        '1': async () => {
            await app().server.invoke.cheatForceGameDay({ day: 0 });
            await new GameDay0Flow({ gameId: 'first' }).execute();
            await app().server.invoke.cheatGameDayComplete();
            void app().nav.open('homeScreen', {});
        },
        '2': async () => {
            await app().server.invoke.cheatForceGameDay({ day: 1 });
            await new GameDay1Flow({ gameId: 'first' }).execute();
            await app().server.invoke.cheatGameDayComplete();
            void app().nav.open('homeScreen', {});
        },
        '3': async () => {
            await app().server.invoke.cheatForceGameDay({ day: 2 });
            await new GameDay2Flow({ gameId: 'first' }).execute();
            await app().server.invoke.cheatGameDayComplete();
            void app().nav.open('homeScreen', {});
        },
        '4': async () => {
            await app().server.invoke.cheatForceGameDay({ day: 3 });
            await new GameDay3Flow({ gameId: 'first' }).execute();
            await app().server.invoke.cheatGameDayComplete();
            void app().nav.open('homeScreen', {});
        },
        '5': async () => {
            await app().server.invoke.cheatForceGameDay({ day: 4 });
            await new GameDay4Flow({ gameId: 'first' }).execute();
            await app().server.invoke.cheatGameDayComplete();
            void app().nav.open('homeScreen', {});
        },
        '6': async () => {
            await app().server.invoke.cheatForceGameDay({ day: 5 });
            await new GameDay5Flow({ gameId: 'first' }).execute();
            await app().server.invoke.cheatGameDayComplete();
            void app().nav.open('homeScreen', {});
        },
        '7': async () => {
            await app().server.invoke.cheatForceGameDay({ day: 6 });
            await new GameDay6Flow({ gameId: 'first' }).execute();
            await app().server.invoke.cheatGameDayComplete();
            void app().nav.open('homeScreen', {});
        },
        '8': async () => {
            await app().server.invoke.cheatForceGameDay({ day: 7 });
            await new GameDay7Flow({ gameId: 'first' }).execute();
            await app().server.invoke.cheatGameDayComplete();
            void app().nav.open('homeScreen', {});
        },
        'Random game reward': async () => {
            await app().server.invoke.cheatGameRewardGrant();
            const homeScreen = (await app().nav.open('homeScreen', {})) as HomeScreen;
            await new HomeSequenceFlow({ homeScreen }).execute();
        },
        'Random game reward V2': async () => {
            await app().server.invoke.cheatGameRewardGrantV2();
            const homeScreen = (await app().nav.open('homeScreen', {})) as HomeScreen;
            await new HomeSequenceFlow({ homeScreen }).execute();
        },
        'Next Logging': async () => {
            const now = app().server.now();
            const nextDayOA = getNextGameDayOA(app().server.state, now);
            const secondGameOa = getNextSecondGameOA(app().server.state, now);
            const { game, index } = getAvailableGameDayAB(app().server.state, now);
            console.log(`-- next game first: ${nextDayOA}`);
            console.log(`-- next game second: ${secondGameOa}`);
            console.log(`-- available day: ${game} ${index}`);
            console.log(`-- gameDay state: ${JSON.stringify(app().server.state.gameDay)}`);
        },
    },
    Settings: {
        'Change Name': async () => {
            const name = await new SetNameFlow().execute();
            console.log(`-- name set to ${name}`);
            void app().nav.open('homeScreen', {});
        },
        Language: {
            choices: ['en', 'ja'],
            get: () => app().settings.language,
            set: async (language: LanguageId) => {
                await app().settings.setLanguage(language);
                await windowReload();
            },
        },
    },
    Puzzle: {
        Open: async () => {
            await app().nav.close('puzzleScreen');
            await app().nav.open('puzzleScreen', {});
        },
        Complete: async () => {
            getActivePuzzleController()?.forceComplete();
        },
        Fail: async () => {
            getActivePuzzleController()?.forceFail();
        },
        Save: async () => {
            getActivePuzzleController()?.saveState();
        },
        Boosters: async () => {
            for (const id of boosterIds) void app().server.invoke.boosterSet({ id, count: 99 });
        },
        Level: {
            min: 1,
            max: gameConfig.puzzle.levels.max,
            step: 1,
            get: () => app().server.state.puzzle.level,
            set: async (value: number) => {
                await app().server.invoke.puzzleSetLevel({ level: value });
            },
        },
    },
    Native: {
        setInstalled: async () => {
            await app().server.invoke.updateAndroidNative();
            await app().server.invoke.updateIosNative();
            console.log(`-- native installed android & ios timestamp updated`);
        },
        resetTimestamps: async () => {
            await app().server.invoke.cheatResetNativeState();
            console.log(`-- installed + reward timestamps reset`);
        },
        URL: {
            get: () => window.localStorage.getItem(debugUrlKey) || '',
            set: async (language: string) => {
                window.localStorage.setItem(debugUrlKey, language);
            },
        },
        URLOpen: async () => {
            openURL(window.localStorage.getItem(debugUrlKey));
        },
    },
    Chatbot: {
        'Send Full Lives OA': async () => {
            await app().server.invoke.sendFullLivesOA();
        },
        'Send First session OA': async () => {
            const keys = Object.keys(firstSessionOA) as FirstSessionAssetKey[];
            for (const id of keys) {
                await app().server.invoke.sendFirstSessionOA({ id });
                console.log(`-- sent ${id}`);
                await sleep(0.2);
            }
        },
        'Send Next Day OA': async () => {
            for (let i = 0; i < 3; i++) {
                await app().server.invoke.sendNextDayOA({ messageId: i });
                console.log(`-- sent next day ${i}`);
                await sleep(0.2);
            }
        },
        'Send Game Day 1': async () => {
            const day = 0;
            for (let i = 0; i < 3; i++) {
                await app().server.invoke.sendGameDayOA({ day, messageId: i });
                console.log(`-- sent game day ${day + 1}, message ${i + 1}`);
                await sleep(0.2);
            }
        },
        'Send Game Day 2': async () => {
            const day = 1;
            for (let i = 0; i < 3; i++) {
                await app().server.invoke.sendGameDayOA({ day, messageId: i });
                console.log(`-- sent game day ${day + 1}, message ${i + 1}`);
                await sleep(0.2);
            }
        },
        'Send Game Day 3': async () => {
            const day = 2;
            for (let i = 0; i < 3; i++) {
                await app().server.invoke.sendGameDayOA({ day, messageId: i });
                console.log(`-- sent game day ${day + 1}, message ${i + 1}`);
                await sleep(0.2);
            }
        },
        'Send Game Day 4': async () => {
            const day = 3;
            for (let i = 0; i < 3; i++) {
                await app().server.invoke.sendGameDayOA({ day, messageId: i });
                console.log(`-- sent game day ${day + 1}, message ${i + 1}`);
                await sleep(0.2);
            }
        },
        'Send Game Day 5': async () => {
            const day = 4;
            for (let i = 0; i < 3; i++) {
                await app().server.invoke.sendGameDayOA({ day, messageId: i });
                console.log(`-- sent game day ${day + 1}, message ${i + 1}`);
                await sleep(0.2);
            }
        },
        'Send Game Day 6': async () => {
            const day = 5;
            for (let i = 0; i < 3; i++) {
                await app().server.invoke.sendGameDayOA({ day, messageId: i });
                console.log(`-- sent game day ${day + 1}, message ${i + 1}`);
                await sleep(0.2);
            }
        },
        'Send Game Day 7': async () => {
            const day = 6;
            for (let i = 0; i < 3; i++) {
                await app().server.invoke.sendGameDayOA({ day, messageId: i });
                console.log(`-- sent game day ${day + 1}, message ${i + 1}`);
                await sleep(0.2);
            }
        },
        'Send Game Day 8': async () => {
            const day = 7;
            for (let i = 0; i < 3; i++) {
                await app().server.invoke.sendGameDayOA({ day, messageId: i });
                console.log(`-- sent game day ${day + 1}, message ${i + 1}`);
                await sleep(0.2);
            }
        },
        'Send Practice Day 1': async () => {
            const practiceId = 0;
            for (let i = 0; i < 3; i++) {
                await app().server.invoke.sendPracticeOA({ practiceId, messageId: i });
                console.log(`-- sent practice ${practiceId + 1}, message ${i + 1}`);
                await sleep(0.2);
            }
        },
        'Send Practice Day 2': async () => {
            const practiceId = 1;
            for (let i = 0; i < 3; i++) {
                await app().server.invoke.sendPracticeOA({ practiceId, messageId: i });
                console.log(`-- sent practice ${practiceId + 1}, message ${i + 1}`);
                await sleep(0.2);
            }
        },
        'Send Practice Day 3': async () => {
            const practiceId = 2;
            for (let i = 0; i < 3; i++) {
                await app().server.invoke.sendPracticeOA({ practiceId, messageId: i });
                console.log(`-- sent practice ${practiceId + 1}, message ${i + 1}`);
                await sleep(0.2);
            }
        },
        'Send Practice Day 4': async () => {
            const practiceId = 3;
            for (let i = 0; i < 3; i++) {
                await app().server.invoke.sendPracticeOA({ practiceId, messageId: i });
                console.log(`-- sent practice ${practiceId + 1}, message ${i + 1}`);
                await sleep(0.2);
            }
        },
        'Send Practice Day 5': async () => {
            const practiceId = 4;
            for (let i = 0; i < 3; i++) {
                await app().server.invoke.sendPracticeOA({ practiceId, messageId: i });
                console.log(`-- sent practice ${practiceId + 1}, message ${i + 1}`);
                await sleep(0.2);
            }
        },
        'Send Game Day 1/5 game 1': async () => {
            const day = 0;
            const game = 0;
            for (let i = 0; i < 3; i++) {
                await app().server.invoke.sendGameDayV2OA({ day, game: 0, messageId: i });
                console.log(`-- sent game day ${day + 1}, game ${game + 1} message ${i + 1}`);
                await sleep(0.2);
            }
        },
        'Send Game Day 1/5 game 2': async () => {
            const day = 7;
            const game = 0;
            for (let i = 0; i < 3; i++) {
                await app().server.invoke.sendGameDayV2OA({ day, game: 1, messageId: i });
                console.log(`-- sent game day ${day + 1}, game: ${game + 1} message ${i + 1}`);
                await sleep(0.2);
            }
        },
        'Send Game Day 2/6 game 1': async () => {
            const day = 1;
            const game = 0;
            for (let i = 0; i < 3; i++) {
                await app().server.invoke.sendGameDayV2OA({ day, game: 0, messageId: i });
                console.log(`-- sent game day ${day + 1}, game ${game + 1} message ${i + 1}`);
                await sleep(0.2);
            }
        },
        'Send Game Day 2/6 game 2': async () => {
            const day = 1;
            const game = 1;
            for (let i = 0; i < 3; i++) {
                await app().server.invoke.sendGameDayV2OA({ day, game: 1, messageId: i });
                console.log(`-- sent game day ${day + 1}, game: ${game + 1} message ${i + 1}`);
                await sleep(0.2);
            }
        },
        'Send Game Day 3/7 game 1': async () => {
            const day = 2;
            const game = 0;
            for (let i = 0; i < 3; i++) {
                await app().server.invoke.sendGameDayV2OA({ day, game: 0, messageId: i });
                console.log(`-- sent game day ${day + 1}, game ${game + 1} message ${i + 1}`);
                await sleep(0.2);
            }
        },
        'Send Game Day 3/7 game 2': async () => {
            const day = 2;
            const game = 1;
            for (let i = 0; i < 3; i++) {
                await app().server.invoke.sendGameDayV2OA({ day, game: 1, messageId: i });
                console.log(`-- sent game day ${day + 1}, game: ${game + 1} message ${i + 1}`);
                await sleep(0.2);
            }
        },
        'Send Game Day 4/8 game 1': async () => {
            const day = 3;
            const game = 0;
            for (let i = 0; i < 3; i++) {
                await app().server.invoke.sendGameDayV2OA({ day, game: 0, messageId: i });
                console.log(`-- sent game day ${day + 1}, game ${game + 1} message ${i + 1}`);
                await sleep(0.2);
            }
        },
        'Send Game Day 4/8 game 2': async () => {
            const day = 3;
            const game = 1;
            for (let i = 0; i < 3; i++) {
                await app().server.invoke.sendGameDayV2OA({ day, game: 1, messageId: i });
                console.log(`-- sent game day ${day + 1}, game: ${game + 1} message ${i + 1}`);
                await sleep(0.2);
            }
        },
        'Send Team Morale scenario 1 pinches': async () => {
            for (let i = 0; i < 2; i++) {
                console.log(`-- sent team morale scenario 1, pinchId: ${i + 1}`);
                await app().server.invoke.sendMoralePinchOA({ messageId: 0, pinchId: i });
            }
        },
        'Send Team Morale scenario 2 pinches': async () => {
            for (let i = 0; i < 2; i++) {
                console.log(`-- sent team morale scenario 2, pinchId: ${i + 1}`);
                await app().server.invoke.sendMoralePinchOA({ messageId: 1, pinchId: i });
            }
        },
        'Send Team Morale scenario 3 pinches': async () => {
            for (let i = 0; i < 2; i++) {
                console.log(`-- sent team morale scenario 3, pinchId: ${i + 1}`);
                await app().server.invoke.sendMoralePinchOA({ messageId: 2, pinchId: i });
            }
        },
        'Send Team Morale scenario 4 pinches': async () => {
            for (let i = 0; i < 2; i++) {
                console.log(`-- sent team morale scenario 4, pinchId: ${i + 1}`);
                await app().server.invoke.sendMoralePinchOA({ messageId: 3, pinchId: i });
            }
        },
        'Send Team Morale scenario 5 pinches': async () => {
            for (let i = 0; i < 2; i++) {
                console.log(`-- sent team morale scenario 5, pinchId: ${i + 1}`);
                await app().server.invoke.sendMoralePinchOA({ messageId: 4, pinchId: i });
            }
        },
        'Execute game day practice s.actions': async () => {
            // all 3 gameDay practice messages with few seconds scheduled actions delay
            await app().server.invoke.scheduleGameDayPracticeOA();
            console.log(`-- scheduled current gameDay practice set with few seconds delay`);
        },
        'Execute team morale s.actions': async () => {
            await app().server.invoke.scheduleTeamMoralePinch();
            console.log(`-- scheduled current team morale set with few seconds delay`);
        },
        'Execute game day V2 s.actions DAY 0': async () => {
            // all 3 gameDay practice messages with few seconds scheduled actions delay
            await app().server.invoke.scheduleGameDayV2OA({ day: 0 });
            console.log(`-- scheduled gameDay 0 with few seconds delay`);
        },
        'Dynamic test': async () => {
            const creative = await gameDayOA({ dayId: 'gameDay0', day: 'DAY 1', versus: 'Test Team', score: '0-2' });
            void consoleRender(creative);
            const renderer = app().get(StagePlugin).renderer;
            const base64 = await renderer.plugins.extract.base64(creative, 'image/jpeg');
            const imageKey = await app().server.uploadUserAsset(base64);
            await app().server.invoke.sendGameDynamicDayOA({
                textKey: 'game_day_0_0',
                imageKey,
            });
        },
    },
};

async function consoleRender(view: Container) {
    const renderer = app().get(StagePlugin).renderer;
    const imageUrl = await renderer.plugins.extract.base64(view, 'image/jpeg');

    const scale = 0.5;
    const w = Math.round(view.width * scale);
    const h = Math.round(view.height * scale);
    const dim = `font-size: 1px; padding: ${Math.round(h / 2)}px ${Math.round(w / 2)}px;`;

    console.log('%c+', `${dim}background: url(${imageUrl}); background-size: ${w}px ${h}px; color: transparent;`);
}
