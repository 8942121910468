import { action, asyncAction, SB } from '@play-co/replicant';

import core from '../defs/core';
import { AsyncActionAPI, MutableState, ScheduledActionAPI, State, SyncActionAPI } from '../defs/replicant';
import { stateSchema } from '../state';
import { onFirstSessionInit } from './firstSession';
import { onGameDayInit } from './gameDay';
import { onLivesInit } from './lives';
import { onTaskInit } from './task';
import { onTeamMoraleInit } from './teamMorale';

// TODO: Better naming, the state had to be split out in a separate file to allow game to launch

// hooks
//-----------------------------------------------------------------------------
async function hookInit(api: AsyncActionAPI, state: MutableState, entryData: any) {
    // notify listeners
    onTaskInit(api, state);
    onFirstSessionInit(api, state);
    onGameDayInit(api, state);
    onLivesInit(api, state);
    onTeamMoraleInit(api, state);
}

// actions
//-----------------------------------------------------------------------------
export const coreActions = {
    // to be called once at client init to do init things
    coreInit: asyncAction(async (state: MutableState, options: { entryData: any }, api: AsyncActionAPI) => {
        // Validate and set chatbot metadata
        if (!api.chatbot.appleDeviceTokenIsValid() || !api.chatbot.androidDeviceTokenIsValid()) {
            api.chatbot.validateSubscription();
        }

        delete state.deleteAccountIntent;

        /*
        // dev only
        if (process.env.IS_DEVELOPMENT) {
            // override with mock state
            Object.assign(state, debugStates[api.getUserID()]);
        }

        // update state
        state.enterTime = api.date.now();

        */
        // update stats
        ++state.enterCount;

        // move over old players that passed the intro flows
        // and start the teamMorale decay
        if (state.task.introSeen && !state.teamMorale.ftue) {
            state.teamMorale.ftue = true;
            state.teamMorale.timestamp = api.date.now();
        }

        // hook init
        await hookInit(api, state, options.entryData);
    }),

    // reset all state
    coreReset: asyncAction(async (state: MutableState, _, api: AsyncActionAPI) => {
        if (process.env.IS_DEVELOPMENT || process.env.LINE_GUEST) {
            // set default state
            Object.assign(state, stateSchema.getDefault());
        }
    }),

    triggerCooldown: action((state: MutableState, args: { id: string }, api: SyncActionAPI) => {
        state.cooldowns[args.id] = {
            startTimestamp: api.date.now(),
        };
    }),
};

// api
//-----------------------------------------------------------------------------
export function isUserInactive(api: SyncActionAPI | ScheduledActionAPI, state: State | MutableState) {
    const now = api.date.now();
    const lastUpdateTime = state.updatedAt;

    if (now - lastUpdateTime > core.inactiveTime) return true;

    return false;
}

export function coreIsNewPlayer(state: State) {
    return state.enterCount <= 1;
}
