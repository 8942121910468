import { i18n } from '@play-co/astro';
import { FlexContainer } from '@play-co/flex';
import { ITextStyle, Sprite, Texture } from 'pixi.js';

import { getCoinImage, ProductDef } from '../../../../replicant/defs/product';
import { formatResourceCount } from '../../../lib/tools/appTools';
import { BasicText } from '../../../lib/ui/text/BasicText';
import { manifest } from '../../shop/manifest';

const fontStyle = {
    fontSize: 36,
    fontWeight: 'bold',
    fill: '#FFF976',
    stroke: '#000',
    strokeThickness: 6,
    lineJoin: 'round',
} as Partial<ITextStyle>;

const itemWidth = 152;
const itemHeight = 170;
const textY = 142;

export class BuyCompleteItems extends FlexContainer {
    protected content: FlexContainer;

    constructor(item: ProductDef) {
        super({
            alignItems: 'center',
            justifyContent: 'center',
            height: 320,
        });

        // add coins icon
        if (item.coins) {
            const resourceId = getCoinImage(item.coins);
            const coinTexture = Texture.from(resourceId);

            const container = this.addChild(
                new FlexContainer({
                    justifyContent: 'center',
                    width: itemWidth,
                    height: itemHeight,
                }),
            );

            container.addChild(
                new Sprite(coinTexture).setFlex({
                    position: 'absolute',
                    width: 126,
                    height: 'auto',
                    aspectRatio: coinTexture.width / coinTexture.height,
                    top: 8,
                }),
            );

            container.addChild(
                new BasicText({
                    text: `x${formatResourceCount(item.coins)}`,
                    style: fontStyle,
                }).setFlex({
                    position: 'absolute',
                    top: textY,
                }),
            );
        }

        // add infinite lives icon (if applicable)
        if (item.infiniteLives) {
            const hours = i18n.getEntry('hours').text;
            const text = `${item.infiniteLives}${hours}`;
            const icon = Sprite.from(manifest.unlimitedLivesIcon);
            icon.scale.set(1.4);

            const container = this.addChild(
                new FlexContainer({
                    justifyContent: 'center',
                    width: itemWidth,
                    height: itemHeight,
                }),
            );

            container.addChild(
                icon.setFlex({
                    position: 'absolute',
                    top: 20,
                }),
            );

            container.addChild(
                new BasicText({
                    text,
                    style: fontStyle,
                }).setFlex({
                    position: 'absolute',
                    top: textY,
                }),
            );
        }

        // add rocket, bomb, baseball icons
        if (item.rocket > 0) {
            const iconRocket = Sprite.from(manifest.iconFirecracker);
            const iconBomb = Sprite.from(manifest.iconBomb);
            const iconBaseball = Sprite.from(manifest.iconBaseball);
            iconRocket.scale.set(1.3);
            iconBomb.scale.set(1.3);
            iconBaseball.scale.set(1.3);

            const container = this.addChild(
                new FlexContainer({
                    justifyContent: 'center',
                    width: itemWidth,
                    height: itemHeight,
                }),
            );
            container.addChild(
                iconRocket.setFlex({
                    position: 'absolute',
                    left: 5,
                    top: -15,
                }),
            );

            container.addChild(
                iconBomb.setFlex({
                    position: 'absolute',
                    left: 74,
                    top: 14,
                }),
            );

            container.addChild(
                iconBaseball.setFlex({
                    position: 'absolute',
                    left: 40,
                    top: 45,
                }),
            );

            container.addChild(
                new BasicText({
                    text: `x${item.rocket}`,
                    style: fontStyle,
                }).setFlex({
                    position: 'absolute',
                    top: textY,
                }),
            );
        }

        // add metal bat, runner, fireball, tornado fan icons
        if (item.metalBat > 0) {
            const iconFan = Sprite.from(manifest.iconFan);
            const iconBat = Sprite.from(manifest.iconBat);
            const iconFireball = Sprite.from(manifest.iconFireball);
            const iconRunner = Sprite.from(manifest.iconRunner);
            iconFan.scale.set(1.2);
            iconBat.scale.set(1.2);
            iconFireball.scale.set(1.2);
            iconRunner.scale.set(1.2);

            const container = this.addChild(
                new FlexContainer({
                    justifyContent: 'center',
                    width: itemWidth,
                    height: itemHeight,
                }),
            );
            container.addChild(
                iconFan.setFlex({
                    position: 'absolute',
                    left: 20,
                    top: 50,
                }),
            );

            container.addChild(
                iconBat.setFlex({
                    position: 'absolute',
                    left: 20,
                    top: 0,
                }),
            );

            container.addChild(
                iconFireball.setFlex({
                    position: 'absolute',
                    left: 70,
                    top: 0,
                }),
            );

            container.addChild(
                iconRunner.setFlex({
                    position: 'absolute',
                    left: 70,
                    top: 60,
                }),
            );

            container.addChild(
                new BasicText({
                    text: `x${item.metalBat}`,
                    style: fontStyle,
                }).setFlex({
                    position: 'absolute',
                    top: textY,
                }),
            );
        }
    }
}
