import { Container, NineSlicePlane, Sprite, Texture } from 'pixi.js';

import { uiAlignCenter, uiAlignCenterX, uiSizeToFit } from '../../../../lib/pixi/uiTools';
import { powerBoosterConfig, PowerBoosterId, powerBoosterIds } from '../../../../replicant/defs/booster';
import { BasicText } from '../../../lib/ui/text/BasicText';
import { GoalId, goalMap } from '../../match2-odie/defs/goal';
import { GoalsDef, MapDef } from '../../match2-odie/defs/map';
import { LevelPopup, LevelPopupOptions } from '../LevelPopup';
import { PowerBoosterButton } from './PowerBoosterButton';
import app from '../../../getApp';

// types
//-----------------------------------------------------------------------------
export type LevelGoalPopupOptions = {
    isMaxLevel?: boolean;
    onBuyBooster?: BuyBoosterHandler;
} & Omit<LevelPopupOptions, 'ribbon'>;

type BuyBoosterHandler = (id: PowerBoosterId) => Promise<void>;

const manifest = {
    frame: 'frame.inset.light.png',
    nameplate: 'panel.nameplate.png',
    balloonBody: 'panel.balloon.body.png',
    balloonTip: 'panel.balloon.tip.png',
};

/*
    Puzzle goals popup
*/
export class LevelGoalPopup extends LevelPopup {
    // fields
    //-------------------------------------------------------------------------
    private _level: number;
    private _mapDef: MapDef;
    private _boosterButtons: PowerBoosterButton[] = [];

    // properties
    //-------------------------------------------------------------------------
    public get powerBoosters(): PowerBoosterId[] {
        return this._boosterButtons.filter((button) => button.selected).map((button) => button.id);
    }

    // impl
    //-------------------------------------------------------------------------
    public override preload({ level }: LevelGoalPopupOptions) {
        const mapGoalPromise = app()
            .puzzleMap.getMap(level)
            .then((mapDef: MapDef) => {
                this._mapDef = mapDef;
                return Promise.all(app().resource.loadAssets(this._goalAssets(mapDef.goals)));
            });
        return [
            ...super.preload(),
            ...app().resource.loadAssets([...Object.values(manifest), ...PowerBoosterButton.assets()]),
            mapGoalPromise,
        ];
    }

    // @ts-ignore
    public override async spawning(options: LevelGoalPopupOptions) {
        await super.spawning({
            ...options,
            ribbon: 'green',
            okButton: '[buttonPlay]',
        });

        this._level = options.level;
        const nameplate = new NineSlicePlane(Texture.from(manifest.nameplate), 28, 28, 28, 28);

        nameplate.width = 235;
        nameplate.height = 48;

        const goals = new BasicText({
            text: '[popupLevelObjectives]',
            style: {
                fill: 0x2c7381,
                fontSize: 28,
                fontWeight: 'bold',
                lineJoin: 'round',
                align: 'center',
            },
        });

        nameplate.addChild(goals);
        uiAlignCenter(nameplate, goals);

        const frame = new NineSlicePlane(Texture.from(manifest.frame), 28, 28, 28, 28);
        frame.width = 612;
        frame.height = 200;

        const boosters = new Container();
        this._spawnBoosters(boosters, options.level, options.onBuyBooster);

        // spawn
        this.main.addContent({
            frame: {
                content: frame,
                styles: {
                    position: 'center',
                    marginTop: -54,
                },
            },
            goals: {
                content: nameplate,
                styles: {
                    position: 'center',
                    marginTop: -138,
                },
            },
            boosters: {
                content: boosters,
                styles: {
                    position: 'center',
                    marginTop: 120,
                },
            },
        });

        if (options.isMaxLevel) {
            const balloon = new Container();
            const balloonBody = new NineSlicePlane(Texture.from(manifest.balloonBody), 28, 28, 28, 28);
            balloonBody.width = 675;
            balloon.addChild(balloonBody);
            const balloonTip = new Sprite(Texture.from(manifest.balloonTip));
            balloon.addChild(balloonTip);
            uiAlignCenter(balloonBody, balloonTip, 0, 78);
            const balloonLabel = new BasicText({
                text: '[popupLevelObjectivesMax]',
                style: {
                    fill: 0xffffff,
                    fontSize: 30,
                    fontWeight: 'bold',
                    align: 'center',
                    wordWrap: true,
                    wordWrapWidth: 590,
                },
            });
            balloon.addChild(balloonLabel);
            uiAlignCenter(balloonBody, balloonLabel, 0, -5);
            this.baseLayout.addContent({
                balloon: {
                    content: balloon,
                    styles: {
                        position: 'centerTop',
                        marginTop: -150,
                    },
                },
            });
        }

        let x = 0;
        const container = new Container();
        Object.keys(this._mapDef.goals).forEach((id: GoalId) => {
            const count = new BasicText({
                text: `${this._mapDef.goals[id]}`,
                style: {
                    fill: 0xffffff,
                    fontSize: 38,
                    fontWeight: 'bold',
                    lineJoin: 'round',
                    strokeThickness: 5,
                },
            });
            count.pivot.set(count.width, count.height);

            const goalIcon = Sprite.from(goalMap[id]);
            //goalIcon.scale.set(1); // make sure the small icons are big enough
            uiSizeToFit(goalIcon, 86, 86);
            container.addChild(goalIcon, count);
            goalIcon.x = x;
            count.x = x + goalIcon.width + 12;
            count.y = goalIcon.y + goalIcon.height + 18;

            x += 116;
        });

        frame.addChild(container);
        uiAlignCenter(frame, container, 0, 19);
    }

    public override async spawned() {
        await super.spawned();
        // show tutorial
        void this._showPowerBoosterTutorial();
    }

    // private: scene
    //-------------------------------------------------------------------------
    private _spawnBoosters(container: Container, level: number, handler: BuyBoosterHandler) {
        const title = container.addChild(
            new BasicText({
                text: '[popupLevelObjectivesBoosters]',
                style: {
                    fill: 0x666666,
                    fontSize: 26,
                    fontWeight: 'bold',
                },
            }),
        );

        let x = 0;
        const y = title.height + 6;
        this._boosterButtons = [];

        // @ts-ignore
        const powerBoosters = [...powerBoosterIds].reverse(); // copy and reverse
        for (const id of powerBoosters) {
            const count = app().server.state.powerBoosters.map[id].count;
            const locked = level < powerBoosterConfig.unlockLevel;
            const button = container.addChild(
                new PowerBoosterButton({
                    id,
                    count,
                    locked,
                }),
            );
            button.onPress = async () => {
                if (!locked) {
                    if (count > 0) button.selected = !button.selected;
                    else await handler(id);
                }
            };
            button.x = x;
            button.y = y;
            x += 116;
            this._boosterButtons.push(button);
        }

        uiAlignCenterX(container, title);
    }

    private _goalAssets(goalDef: GoalsDef): string[] {
        return Object.keys(goalDef).map((id: GoalId) => goalMap[id]);
    }

    private async _showPowerBoosterTutorial() {
        // if not yet shown and required level
        if (!app().server.state.powerBoosters.ftue && this._level >= powerBoosterConfig.unlockLevel) {
            const id = 'tipScreen2';

            // show tutorial message
            void app().nav.open(id, {
                text: '[popupLevelObjectivesTutorial]',
                onOk: async () => {
                    await app().nav.close(id);
                    await app().server.invoke.powerBoosterFtue();
                },
                square: true,
                targets: [this.main.getChildByID('boosters').getBounds()],
                y: 960,
            });
        }
    }
}
