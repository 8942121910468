import { BasicAsyncHandler } from '../../../lib/defs/types';
import { uiAlignBottom, uiAlignCenterX } from '../../../lib/pixi/uiTools';
import app from '../../getApp';
import { TextImageButton } from '../../lib/ui/buttons/TextImageButton';
import { BasicText } from '../../lib/ui/text/BasicText';
import { BasicPopup } from './BasicPopup';

// types
//-----------------------------------------------------------------------------
export type ConfirmPopupOptions = {
    onYes: BasicAsyncHandler;
    onNo: BasicAsyncHandler;
    title?: string;
    message?: string;
    buttonOk?: string;
    buttonCancel?: string;
    height?: number;
};

const manifest = {
    yesButton: 'button.green.png',
    noButton: 'button.blue.png',
};

/*
    Confirm popup with 2 main buttons (yes/no)
*/
export class ConfirmPopup extends BasicPopup {
    public override preload() {
        return [...super.preload(), ...app().resource.loadAssets(Object.values(manifest))];
    }

    // @ts-ignore
    public override async spawning(options: ConfirmPopupOptions) {
        const { onYes, onNo, title, message, buttonOk, buttonCancel, height } = options;
        void super.spawning({
            ...options,
            header: title ?? '[popupConfirmTitle]',
            width: 660,
            height: height ?? 390,
        });

        const yesButton = this._createButton('yes', buttonOk);
        const noButton = this._createButton('no', buttonCancel);
        yesButton.onPress = () => onYes();
        noButton.onPress = () => onNo();

        // spawn
        this.main.addContent({
            message: {
                content: new BasicText({
                    text: message ?? '[popupConfirmMessage]',
                    style: {
                        fill: '#3E4047',
                        align: 'center',
                        fontWeight: 'bold',
                        fontSize: 30,
                        lineJoin: 'round',
                        wordWrap: true,
                        wordWrapWidth: 580,
                    },
                }),
                styles: {
                    position: 'center',
                    marginTop: 0,
                },
            },
        });

        this.baseLayout.addChild(yesButton, noButton);
        uiAlignBottom(this.baseLayout, yesButton, -32);
        uiAlignBottom(this.baseLayout, noButton, -32);
        uiAlignCenterX(this.baseLayout, noButton, -110);
        uiAlignCenterX(this.baseLayout, yesButton, 110);
    }

    private _createButton(type: 'yes' | 'no', label?: string) {
        const image = type === 'yes' ? manifest.yesButton : manifest.noButton;
        const text = label ?? (type === 'yes' ? '[buttonYes]' : '[buttonNo]');
        return new TextImageButton({
            text,
            y: -5,
            image,
            slice: {
                width: 188,
                height: 106,
                left: 30,
                top: 16,
                right: 30,
                bottom: 32,
            },
            style: {
                fill: 'FFF',
                fontSize: 40,
                fontWeight: 'bold',
                lineJoin: 'round',
                fontStyle: 'normal',
                dropShadow: true,
                dropShadowDistance: 1,
            },
        });
    }
}
