import app from '../../../../getApp';
import { PositionType } from '../../../../../lib/defs/types';
import { CubeAnimation } from '../../animations/CubeAnimation';
import { BlockId, ColorId, CubeBlockProps } from '../../defs/block';
import { BlockEntity, despawnBlockEntity, spawnBlockEntity } from '../../entities/BlockEntity';
import { despawnOverlayEntity, spawnOverlayEntity } from '../../entities/OverlayEntity';
import { GameScene } from '../../GameScene';
import { blockIsFrozen, blockIterateAll } from '../../util/blockTools';
import { mapGetPan } from '../../util/mapTools';
import { IEffect } from './IEffect';

// types
//-----------------------------------------------------------------------------
type BlockReplacer = () => BlockId;

export type CubeReplaceComboEffectOptions = {
    subject: BlockEntity;
    pair: BlockEntity[];
    all: BlockEntity[];
    replacer: BlockReplacer;
};

/*
    bomb+cube combo replace all blocks of cube color with replacement blocks then tap those blocks effect
*/
export class CubeReplaceComboEffect implements IEffect {
    // fields
    //-------------------------------------------------------------------------
    // input
    private readonly _scene: GameScene;
    private readonly _options: CubeReplaceComboEffectOptions;

    // init
    //-------------------------------------------------------------------------
    constructor(scene: GameScene, options: CubeReplaceComboEffectOptions) {
        this._scene = scene;
        this._options = options;
    }

    // impl
    //-------------------------------------------------------------------------
    public async execute() {
        // get cube color
        const color = this._getCubeColor();

        // get basic blocks of given cube color
        const targets = this._getTargets(color);

        // despawn all subject blocks
        for (const block of this._options.all) {
            despawnBlockEntity(this._scene, block);
        }

        // animate
        await this._animate(this._options.subject.c.position.mapPosition, targets, color);

        // replace target blocks
        this._replaceTargets(targets);
    }

    // private: core
    //-------------------------------------------------------------------------
    private _getTargets(color: ColorId): BlockEntity[] {
        const targets: BlockEntity[] = [];

        // iterate blocks
        blockIterateAll(this._scene, (base) => {
            // not if frozen
            if (!blockIsFrozen(this._scene, base)) {
                const block = base?.c.block;

                // if basic block type and target color matches basic block color, choose block
                if (block?.props.type === 'basic' && color === block.props.color) {
                    targets.push(base);
                }
            }
        });

        return targets;
    }

    private _replaceTargets(targets: BlockEntity[]) {
        // for each target entity
        for (const entity of targets) {
            // despawn existing block
            despawnBlockEntity(this._scene, entity);

            // spawn replacement block
            const spawnedEntity = spawnBlockEntity(
                this._scene,
                { id: this._options.replacer() },
                entity.c.position.mapPosition,
            );

            // attack spawned block
            spawnedEntity.c.block.collide('attack');
        }
    }

    // private: animation
    //-------------------------------------------------------------------------
    private async _animate(position: PositionType, targets: BlockEntity[], color: ColorId) {
        // play sound
        void app().sound.play('puzzle-combo-cube-replace.mp3', { rate: 1.4, pan: mapGetPan(position) });

        // create animation
        const animation = new CubeAnimation({
            color,
            position,
            targets,
        });

        // spawn animation as overlay entity
        const entity = spawnOverlayEntity(this._scene, position, { width: 1, height: 1 }, () => animation);

        // run animation
        await animation.start();

        // despawn entity
        void animation.completed.then(() => despawnOverlayEntity(this._scene, entity));
    }

    // private: support
    //-------------------------------------------------------------------------
    private _getCubeColor(): ColorId {
        const pair = this._options.pair;
        const props1 = pair[0].c.block.props;
        const props2 = pair[1].c.block.props;

        // get cube color
        return props1.type === 'cube' ? props1.color : (props2 as CubeBlockProps).color;
    }
}
