import { Plugin, PluginOptions } from '@play-co/astro';

import { BoundsType } from '../../lib/defs/types';
import app from '../getApp';
import { NavLayer } from '../defs/nav';
import { MessagePopupOptions } from '../main/popups/MessagePopup';
import { TargetTipOptions2 } from '../main/tip/TipScreen2';
import type { App } from '../App';

// types
//-----------------------------------------------------------------------------
// public
export interface NewTipServiceOptions extends PluginOptions {
    tips: TipMap;
}

export type ShowContext = {
    target?: BoundsType;
};

// private
type MessageTipProps = {
    style: 'message';
} & Partial<MessagePopupOptions>;

type TargetTipProps = {
    style: 'target';
} & Partial<TargetTipOptions2>;

export type TipProps = MessageTipProps | TargetTipProps;

type TipMap = Record<string, TipProps>;

/*
    first time seen tooltips service
*/
export class TipService extends Plugin {
    // fields
    //-------------------------------------------------------------------------
    // input
    private readonly _tipMap: TipMap;
    // state
    private _showing = false;
    // maps
    private readonly _showMap = {
        message: this._showMessageTip,
        target: this._showTargetTip,
    };

    // init
    //-------------------------------------------------------------------------
    constructor(app: App, options: Partial<NewTipServiceOptions>) {
        super(app, options);
        this._tipMap = options.tips;
    }

    public init(): void {}

    // api
    //-------------------------------------------------------------------------
    public async show(id: string, options: ShowContext = {}): Promise<boolean> {
        // fail if already shown or showing
        if (this.isShown(id) || this._showing) {
            return false;
        }

        // set showing state
        this._showing = true;

        // get props
        const props = this._tipMap[id];

        // show tip view
        await this._showMap[props.style].call(this, props, options);

        // complete tip
        this._completeTip(id);

        // close tip view
        await app().nav.closeLayer(NavLayer.tip);

        // reset showing state
        this._showing = false;

        return true;
    }

    public isShown(id: string): boolean {
        //return false;
        return app().server.state.tips[id];
    }

    // private: tips
    //-------------------------------------------------------------------------
    private async _showMessageTip(props: MessageTipProps, options: ShowContext) {
        // // show message tip until ok button hit
        // await new Promise((resolve) =>
        //     app().nav.open('tipMessageScreen', {
        //         ...props,
        //         onOk: resolve,
        //     }),
        // );
    }

    private async _showTargetTip(props: TargetTipProps, options: ShowContext) {
        // show targeted tip until ok button hit
        await new Promise((resolve) =>
            app().nav.open('tipScreen2', {
                targets: [options.target],
                motion: 'point',
                ...props,
                y: 120,
                onOk: resolve,
            }),
        );
    }

    // private: backend
    //-------------------------------------------------------------------------
    private _completeTip(id: string) {
        void app().server.invoke.tutorialTipComplete({ id });
    }
}
