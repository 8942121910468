import app from '../../../../getApp';
import { CollideEvent, CollideId } from '../../defs/collide';
import type { PantherBlockEntity } from '../../entities/BlockEntity';
import { despawnBlockEntity } from '../../entities/BlockEntity';
import { GameScene } from '../../GameScene';
import { hasBlockBelow, mapGetPan } from '../../util/mapTools';
import { BreakEffect } from '../effects/BreakEffect';
import { InvalidEffect } from '../effects/InvalidEffect';
import { CollideHandler, ICollision } from './ICollision';

/*
    panther block collision resolver
*/
export class PantherCollision implements ICollision {
    // fields
    //-------------------------------------------------------------------------
    // input
    private readonly _scene: GameScene;
    private readonly _entity: PantherBlockEntity;
    // map
    private readonly _resolvers: { [key in CollideId]?: CollideHandler } = {
        tap: this._resolveTap,
        fall: this._resolveFall,
    };

    // init
    //-------------------------------------------------------------------------
    constructor(scene: GameScene, entity: PantherBlockEntity) {
        this._scene = scene;
        this._entity = entity;
    }

    // impl
    //-------------------------------------------------------------------------
    public async resolve(event: CollideEvent): Promise<boolean> {
        return this._resolvers[event.id]?.call(this, event);
    }

    // private: resolvers
    //-------------------------------------------------------------------------
    private async _resolveTap(event: CollideEvent) {
        // invalid effect
        await new InvalidEffect(this._entity).execute();
        return false;
    }

    private async _resolveFall(event: CollideEvent) {
        // if does not have block below
        if (!this._findBlockBelow()) {
            // destroy
            await this._actionDestroy();
            return true;
        }
        return false;
    }

    // private: actions
    //-------------------------------------------------------------------------
    private async _actionDestroy() {
        const position = this._entity.c.position.mapPosition;

        // play sound
        void app().sound.play('puzzle-panther-destroy.mp3', { dupes: 2, pan: mapGetPan(position) });

        // despawn block
        despawnBlockEntity(this._scene, this._entity);

        // execute break effect
        return new BreakEffect(this._scene, {
            position,
            size: this._entity.c.block.props,
            color: 0x5566ff,
        }).execute();
    }

    // private: support
    //-------------------------------------------------------------------------
    private _findBlockBelow(): boolean {
        return hasBlockBelow(this._scene.sessionEntity.c.map, this._entity);
    }
}
