import { i18n } from '@play-co/astro';
import { FlexContainer, Spacer } from '@play-co/flex';
import { Sprite, Texture } from 'pixi.js';

import { TextLanguageId } from '../../defs/text';
import { ImageButton } from '../../lib/ui/buttons/ImageButton';
import { layoutConfig } from './layout';
import { manifest } from './manifest';
import { ShopHeaderCoins } from './ShopHeaderCoins';

export class ShopHeader extends FlexContainer {
    public closeButton: ImageButton;

    constructor() {
        super({
            width: '100%',
            height: layoutConfig.headerHeight,
            alignItems: 'center',
        });

        // create background
        this.addChild(new Sprite(Texture.from(manifest.header))).setFlex({
            id: 'background',
            position: 'absolute',
            width: '100%',
            height: layoutConfig.headerBgHeight,
        });

        // create coins
        this.addChild(
            new ShopHeaderCoins().setFlex({
                marginLeft: layoutConfig.padding,
            }),
        );

        // add spacer
        this.addChild(new Spacer());

        // add banner
        const language = i18n.language as TextLanguageId;
        const bannerTexture = Texture.from(language === 'en' ? manifest.labelEn : manifest.labelJa);
        this.addChild(
            new Sprite(bannerTexture)
                .setFlex({
                    id: 'banner',
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                })
                .setFlexSizeAnchored(bannerTexture.width, bannerTexture.height, 0.5),
        );

        // add close button
        this.closeButton = this.addChild(
            new ImageButton({
                image: manifest.buttonClose,
            }),
        ).setFlex({
            id: 'close',
            width: 70,
            height: 74,
            marginRight: layoutConfig.padding,
        });
    }
}
