import { AnalyticsProperties, parseLineLinkPayload } from '@play-co/gcinstant';
import { AnalyticsPayload } from '@play-co/replicant';

import { forceGameUpdateAndReentry } from './native';

export const PN_STORAGE_KEY = 'pn_payload_cache';
export const LINK_STORAGE_KEY = 'link_payload_cache';

export function getSessionResumeEntryData() {
    const entryData: Partial<AnalyticsProperties.EntryData> = {};

    // merge the cached PN payload if it exists
    let payload = localStorage.getItem(PN_STORAGE_KEY);
    localStorage.removeItem(PN_STORAGE_KEY);

    if (payload) {
        Object.assign(entryData, JSON.parse(payload));
    }

    // merge the cached link payload if it exists
    payload = localStorage.getItem(LINK_STORAGE_KEY);
    localStorage.removeItem(LINK_STORAGE_KEY);

    if (payload) {
        Object.assign(entryData, JSON.parse(payload));
    }

    return entryData;
}

export function handleLink(url: string) {
    const entryData = parseLineLinkPayload(url) as any;

    // Should set the app was in background flag to true.
    if (entryData?.crossplayTracking) {
        entryData.crossplayTracking.wasInBackground = true;
    }

    restartWithPayload(LINK_STORAGE_KEY, entryData);
}

function restartWithPayload(cacheKey: string, payload: AnalyticsPayload | undefined) {
    if (payload) {
        localStorage.setItem(cacheKey, JSON.stringify(payload));
    }
    forceGameUpdateAndReentry();
}
