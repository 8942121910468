import { i18n } from '@play-co/astro';
import { FlexContainer } from '@play-co/flex';
import { NineSlicePlane, Sprite, Texture } from 'pixi.js';

import { textLocaleFormat } from '../../../lib/util/textTools';
import { getCoinImage, ProductItem } from '../../../replicant/defs/product';
import { formatResourceCount } from '../../lib/tools/appTools';
import { BasicText } from '../../lib/ui/text/BasicText';
import { layoutConfig } from './layout';
import { ShopBuyButton } from './ShopBuyButton';
import { ShopItemBombGroup, ShopItemInfiniteLives, ShopItemRunnerGroup } from './ShopItemGroup';
import { ShopListItem } from './ShopListItem';

export class ShopBundleItem extends ShopListItem {
    constructor(item: ProductItem) {
        super({
            width: '100%',
            height: 320,
        });

        // add coins
        const resourceId = getCoinImage(item.coins);
        const coinTexture = Texture.from(resourceId);

        // add coins container for coins icon and text
        const container = this.content.addChild(
            new FlexContainer({
                position: 'absolute',
                left: 70,
                bottom: 120,
                alignItems: 'flex-end',
                justifyContent: 'center',
            }),
        );
        // add coins icon
        container.addChild(new Sprite(coinTexture).setFlex({ position: 'absolute' }).setProportionalFlexHeight(150));

        // add coins text
        container.addChild(
            new BasicText({
                text: `x${formatResourceCount(item.coins)}`,
                style: {
                    fontSize: 30,
                    ...layoutConfig.fontBlack,
                },
            }).setFlex({
                top: 20,
            }),
        );

        // add banner (if applicable)
        if (item.banner) {
            // add banner icon
            const bannerResourceId = `badge.bundle.${item.color}.png`;
            const bannerTexture = Texture.from(bannerResourceId);
            this.content.addChild(new Sprite(bannerTexture)).setFlex({
                position: 'absolute',
                left: -layoutConfig.halfPadding,
                top: -layoutConfig.halfPadding,
                width: 128,
                height: 128,
            });
            const bannerTextContainer = this.content.addChild(
                new FlexContainer({
                    position: 'absolute',
                    left: 45,
                    top: 45,
                    alignItems: 'center',
                    justifyContent: 'center',
                }),
            );
            bannerTextContainer.angle = -30;

            // add banner text
            const text = textLocaleFormat(`[${item.banner}]`);
            const bannerText = bannerTextContainer.addChild(
                new BasicText({
                    text,
                    style: {
                        fontSize: 22,
                        lineHeight: 22,
                        align: 'center',
                        ...layoutConfig.fontWhite,
                        stroke: '#7E0101',
                    },
                }),
            );
            bannerText.setFlexSizeAnchored(bannerText.width, bannerText.height, 0.5);
        }

        // add bundle color bg
        const bgResourceId = `panel.shop.label.${item.color}.png`;
        const bgTexture = Texture.from(bgResourceId);
        const slice = 25;
        this.content.addChild(new NineSlicePlane(bgTexture, slice, slice, slice, slice)).setFlex({
            position: 'absolute',
            left: layoutConfig.halfPadding,
            right: layoutConfig.halfPadding,
            bottom: layoutConfig.padding,
            height: 76,
        });

        // add bundle name
        const itemName = textLocaleFormat(`[${item.itemName}]`);
        this.content.addChild(
            new BasicText({
                text: itemName,
                style: {
                    fontSize: 40,
                    ...layoutConfig.fontWhite,
                },
            }).setFlex({
                position: 'absolute',
                left: 30,
                bottom: layoutConfig.padding * 1.5,
            }),
        );

        // add buy button
        this.content.addChild(new ShopBuyButton(item)).setFlex({
            position: 'absolute',
            right: layoutConfig.halfPadding - 5,
            bottom: layoutConfig.halfPadding + 4,
        }).button.onPress = async () => this.onPress(item.id);

        // create items row
        const itemRow = this.content.addChild(
            new FlexContainer({
                position: 'absolute',
                top: layoutConfig.halfPadding,
                right: 20,
                alignItems: 'flex-start',
                justifyContent: 'flex-end',
                id: 'row',
            }),
        );

        // add infinite lives icon (if applicable)
        if (item.infiniteLives) {
            const hours = i18n.getEntry('hours').text;
            const text = `${item.infiniteLives}${hours}`;
            itemRow.addChild(
                new ShopItemInfiniteLives({
                    text,
                }),
            );
        }

        itemRow.addChild(
            new ShopItemBombGroup({
                text: `x${item.bomb}`, // note: rocket, bomb, baseball have the same value
            }),
        );

        itemRow.addChild(
            new ShopItemRunnerGroup({
                text: `x${item.metalBat}`, // note: metalBat, runner, fireBall, tornadoFan have the same value
            }),
        );
    }
}
