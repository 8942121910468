import { Assets, Container } from 'pixi.js';

import { PositionType } from '../../../../lib/defs/types';
import { IAnimation } from '../../../../lib/pattern/IAnimation';
import { SpritesheetAnimation } from '../../../../lib/pixi/animation/SpritesheetAnimation';
import { mapGetPan } from '../util/mapTools';
import app from '../../../getApp';

// types
//-----------------------------------------------------------------------------
export type BombAnimationOptions = {
    position: PositionType;
};

// constants
//-----------------------------------------------------------------------------
const manifest = {
    explode: 'clip.bomb',
};

/*
    bomb explosion animation
*/
export class BombAnimation extends Container implements IAnimation {
    // fields
    //-------------------------------------------------------------------------
    private _options: BombAnimationOptions;

    // init
    //-------------------------------------------------------------------------
    constructor(options: BombAnimationOptions) {
        super();
        this._options = options;
    }

    // init
    //-------------------------------------------------------------------------
    static assets(): string[] {
        return Object.values(manifest);
    }

    // impl
    //-------------------------------------------------------------------------
    public async start(): Promise<void> {
        // load assets
        await Assets.load(BombAnimation.assets());

        // play sound
        void app().sound.play('puzzle-bomb-activate.mp3', { pan: mapGetPan(this._options.position) });

        // create blast
        const blast = this._createBlast();

        // animate blast
        await this._animateBlast(blast);
    }

    public stop() {}

    // private: factory
    //-------------------------------------------------------------------------
    private _createBlast(): SpritesheetAnimation {
        const blast = new SpritesheetAnimation(manifest.explode);
        blast.anchor.set(0.5);
        return blast;
    }

    // private: animators
    //-------------------------------------------------------------------------
    private async _animateBlast(blast: SpritesheetAnimation) {
        // spawn
        this.addChild(blast);

        // animate
        await blast.start();

        // despawn
        this.removeChild(blast);
    }
}
