import app from '../../../app/getApp';
import { BasicAsyncHandler } from '../../../lib/defs/types';
import { TouchInputComponent } from '../../../lib/pixi/components/TouchInputComponent';
import { PowerBoosterId } from '../../../replicant/defs/booster';
import { ShopPinchRestore } from '../../lib/analytics/pinch';
import { manifest } from './manifest';
import { FlexLayoutScreen } from '../../lib/screens/FlexLayoutScreen';
import { ShopPopupComponent } from './ShopPopup.Component';

// types
//-----------------------------------------------------------------------------
export type ShopPopupOptions = {
    onClose: BasicAsyncHandler;
    shopPinch?: ShopPinchRestore;
    crossplayPowerBoosters?: PowerBoosterId[];
};

/*
    IAP popup, uses flex layout system
*/
export class ShopPopup extends FlexLayoutScreen {
    public static instance: ShopPopup;

    // catch taps and do nothing to avoid unexpected taps behind the shop screen
    private _underlayInput: TouchInputComponent;
    private _component: ShopPopupComponent;
    private _isOpen = false;

    public preload() {
        return [...app().resource.loadAssets(Object.values(manifest))];
    }

    public async init() {
        window.addEventListener('keydown', (e) => {
            if (!this._isOpen) return;

            if (e.key === 'Escape') {
                void this.close();
            }
        });
    }

    // @ts-ignore
    public async spawning(options: ShopPopupOptions) {
        // load the list items
        const items = (await app().product.getProducts()).filter((def) => {
            // filter out one time purchase items that have already been purchased
            if (def.oneTimeOnly && app().server.state.product[def.id]?.purchaseCount > 0) return false;
            return true;
        });

        // create component and add to layout
        this._component = new ShopPopupComponent({
            itemList: items,
            screen: this,
            ...options,
        });

        this.layout.addChild(this._component);

        // create input component to catch taps for the main container which will
        // block any input from going through the shop into the scene behind
        if (!this._underlayInput) {
            // create once
            this._underlayInput = new TouchInputComponent(this.root);
        }

        ShopPopup.instance = this;
        this._isOpen = true;

        app().analytics.purchaseShownTime = app().server.now();
    }

    public despawned() {
        // clean up
        this.layout.removeChild(this._component);
        this._component.destroy();
        this._component = null;

        this._isOpen = false;
        ShopPopup.instance = null;
    }

    public async close() {
        void app().nav.close('shop');
        void this._component.properties.onClose();
    }
}
