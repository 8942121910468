import { IFlow } from '../../lib/pattern/IFlow';
import storyDialogs from '../../replicant/defs/storyDialogs';
import { sleep } from '../../replicant/util/jsTools';
import app from '../getApp';
import { trackTaskFinish } from '../lib/analytics/task';
import { DialogFlow } from './DialogFlow';

export type TaskEndingFlowOpts = {
    taskLevel: number;
};

export class TaskEndingFlow extends DialogFlow implements IFlow {
    private readonly _taskLevel: number;
    // init
    //-------------------------------------------------------------------------
    constructor(opts: TaskEndingFlowOpts) {
        super();
        this._taskLevel = opts.taskLevel;
    }

    // impl
    //-------------------------------------------------------------------------
    public async execute() {
        await this._actionEnding();
        return this._complete;
    }

    private async _actionEnding() {
        const dialogs = storyDialogs.taskEndingDialogs[this._taskLevel];
        await this._dialogSequence(dialogs);

        void this._homeScreen.spawnMoraleView({});

        await app().server.invoke.completeTaskSet();
        trackTaskFinish();

        // allow for morale animation to play after state update
        await sleep(2.5);

        void this._actionComplete(true);
    }
}
