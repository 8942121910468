import { FlexComponent, FlexComponentLayoutRoot, view, children, ComputedLayout } from '@play-co/flex';
import { ShopPopup, ShopPopupOptions } from './ShopPopup';
import { Sprite, Texture } from 'pixi.js';
import { ShopHeader } from './ShopHeader';
import { LazyLoadingScrollBox, LoadableItem } from '../../lib/ui/containers/LazyLoadingScrollBox';
import { ShopList } from './ShopList';
import { BasicText } from '../../lib/ui/text/BasicText';
import { ProductId, ProductItem } from '../../../replicant/defs/product';
import { manifest } from './manifest';
import { pixiConfig } from '../../defs/config';
import { ShopPinchRestore } from '../../lib/analytics/pinch';
import { PowerBoosterId } from '../../../replicant/defs/booster';
import { IapFlow } from '../../flows/IapFlow';
import { layoutConfig } from './layout';

export type Elements = {
    bg: Sprite;
    header: ShopHeader;
    scrollBox: LazyLoadingScrollBox;
    list?: ShopList;
    errorLabel?: BasicText;
};

export type Props = ShopPopupOptions & {
    screen: ShopPopup;
    itemList: ProductItem[];
};

export class ShopPopupComponent extends FlexComponent<Elements, Props> {
    protected override create({ itemList, shopPinch, crossplayPowerBoosters }: Props): Elements {
        // create background
        const bg = new Sprite(Texture.from(manifest.bg));

        // create header
        const header = new ShopHeader();

        // create scroll box
        const scrollBox = new LazyLoadingScrollBox({
            ...pixiConfig.size,
            globalScroll: false,
            bottomPadding: 20,
        });

        // check for items
        const hasItems = itemList.length > 0;

        // create shop list if there are items, otherwise undefined to skip adding to layout
        const list: ShopList | undefined = hasItems
            ? new ShopList({
                  onItemPress: async (id: ProductId) => this._onItemPress(id, shopPinch, crossplayPowerBoosters),
              })
            : undefined;

        // create error label if there are no items, otherwise undefined to skip adding to layout
        const errorLabel: BasicText | undefined = !hasItems
            ? new BasicText({
                  text: '[shopEmptyError]',
                  style: {
                      ...layoutConfig.fontWhite,
                      align: 'center',
                      fontSize: 36,
                  },
              })
            : undefined;

        // return elements
        return {
            bg,
            header,
            scrollBox,
            list,
            errorLabel,
        };
    }

    protected override init({ header, list, errorLabel, scrollBox }: Elements, { itemList, screen }: Props): void {
        // listen for header close button press
        header.closeButton.onPress = async () => {
            void screen.close();
        };

        // setup and load list if it exists
        if (list) {
            list.loadItems(itemList);
            (list as LoadableItem).getLoadableItems = () => list.children as LoadableItem[];
        } else {
            errorLabel.setFlexSizeAnchored(errorLabel.width, errorLabel.height, 0.5);
        }

        // since the scrollbox uses non-flex children it breaks the layout update flow, so we catch it here
        // handle the content layout within the scrollbox manually
        scrollBox.on('layout', (computedLayout) => this.doScrollContentLayout(computedLayout));
    }

    protected override layout({ bg, header, scrollBox, errorLabel }: Elements): FlexComponentLayoutRoot {
        return {
            id: 'contentLayout',
            position: 'absolute',
            direction: 'column',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            maxWidth: 900,
            ...children(
                {
                    ...view(bg),
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                },
                {
                    ...view(header),
                },
                {
                    ...view(scrollBox),
                    width: '100%',
                    shrink: 1,
                    grow: 1,
                },
                {
                    ...view(errorLabel),
                },
            ),
        };
    }

    // component methods...
    protected doScrollContentLayout(computedLayout: ComputedLayout): void {
        const { list, scrollBox } = this.elements;
        if (!list) return;
        // reset the items in the scrollbox
        scrollBox.removeItems();
        // set the width of the list to the computed width
        list.setFlex({
            width: computedLayout.width,
        });
        // trigger the list layout
        list.doLayout({ local: false });
        // add the list to the scrollbox
        scrollBox.addItem(list);
    }

    private async _onItemPress(id: ProductId, shopPinch: ShopPinchRestore, crossplayPowerBoosters: PowerBoosterId[]) {
        await new IapFlow({ id, shopPinch, crossplayPowerBoosters }).execute();
    }
}
