import { stateSchema } from './state';
import { computedProperties } from './computedProperties';
import { messages } from './messages';
import { scheduledActions } from './scheduledActions';
import { asyncGetters } from './asyncGetters';
import { abTests } from './ruleset';
import { createEventHandlers } from '@play-co/replicant';
import { onFirstSessionExit } from './components/firstSession';
import { onGameDayExit } from './components/gameDay';
import { onLivesExit } from './components/lives';
import { onTeamMoraleExit } from './components/teamMorale';

export const eventHandlers = createEventHandlers(stateSchema, {
    computedProperties,
    messages,
    ruleset: { abTests },
    scheduledActions,
    // sharedStates: {},
    asyncGetters,
})({
    onSessionEnd: async (state, eventData, api) => {
        // notify listeners
        onFirstSessionExit(api, state);
        onGameDayExit(api, state);
        onLivesExit(api, state);
        onTeamMoraleExit(api, state);
    },
});
