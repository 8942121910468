import { action, asyncAction } from '@play-co/replicant';

import { ReplicantCreativeType } from '../chatbot/chatbotTexts';
import { FirstSessionAssetKey, FirstSessionAssetKeyV2 } from '../chatbot/messageTemplates';
import gameDay from '../defs/gameDay';
import { AsyncActionAPI, MutableState, SyncActionAPI } from '../defs/replicant';
import task from '../defs/task';
import { timeFromComponents } from '../util/timeTools';
import { sendFirstSessionOA, sendNextDayOA } from './firstSession';
import {
    gameDay0,
    gameDay1,
    gameDay2,
    gameDayComplete,
    gameSecondComplete,
    practice0,
    practice1,
    practice2,
    secondGame0,
    secondGame1,
    secondGame2,
    sendGameDayOA,
    sendGameDayV2OA,
    sendGameDynamicDayOA,
    sendPracticeOA,
} from './gameDay';
import { addInfiniteLives, livesAdd, livesSet, sendFullLives } from './lives';
import { onTeamMoraleExit, sendMoralePinchMessage } from './teamMorale';
import teamMorale from '../defs/teamMorale';

// Do not allow cheats in production
export const cheatActions = process.env.IS_DEVELOPMENT
    ? {
          sendFirstSessionOA: action(
              (
                  state: MutableState,
                  args: { id: FirstSessionAssetKey | FirstSessionAssetKeyV2 },
                  api: SyncActionAPI,
              ) => {
                  //   const token = await createLoginTokenMultiUse(api);
                  void sendFirstSessionOA(state, api, args.id, 'gameday1');
              },
          ),
          sendNextDayOA: action((state: MutableState, args: { messageId: number }, api: SyncActionAPI) => {
              const messageId = args.messageId;
              sendNextDayOA(state, api, { day: 0, messageId, subFeature: 'gameday2' });
          }),
          sendGameDayOA: action((state: MutableState, args: { day: number; messageId: number }, api: SyncActionAPI) => {
              const { day, messageId } = args;
              sendGameDayOA(state, api, { day, messageId, subFeature: `day${day}` });
          }),
          sendGameDayV2OA: action(
              (state: MutableState, args: { day: number; game: number; messageId: number }, api: SyncActionAPI) => {
                  const { day, game, messageId } = args;
                  void sendGameDayV2OA(state, api, { day, game, messageId, subFeature: `day${day}` });
              },
          ),
          sendPracticeOA: action(
              (state: MutableState, args: { practiceId: number; messageId: number }, api: SyncActionAPI) => {
                  const { practiceId, messageId } = args;
                  sendPracticeOA(state, api, { practiceId, messageId, subFeature: `practice${practiceId}` });
              },
          ),
          sendMoralePinchOA: action(
              (state: MutableState, args: { messageId: number; pinchId: number }, api: SyncActionAPI) => {
                  const { messageId, pinchId } = args;
                  const subFeature = teamMorale.teamMoraleConfig[messageId][pinchId].id;
                  sendMoralePinchMessage(state, api, { messageId, pinchId, subFeature });
              },
          ),
          forceMoraleExitEvent: action((state: MutableState, _, api: SyncActionAPI) => {
              onTeamMoraleExit(api as any, state);
          }),
          sendFullLivesOA: action((state: MutableState, _, api: SyncActionAPI) => {
              sendFullLives(state, api);
          }),
          scheduleGameDayV2OA: action((state: MutableState, opts: { day: number }, api: SyncActionAPI) => {
              const { day } = opts;
              api.scheduledActions.schedule.game({
                  args: { day, game: 0, messageId: 0 },
                  notificationId: gameDay0,
                  delayInMS: timeFromComponents({ seconds: 1 }),
              });
              api.scheduledActions.schedule.game({
                  args: { day, game: 0, messageId: 1 },
                  notificationId: gameDay1,
                  delayInMS: timeFromComponents({ seconds: 5 }),
              });
              api.scheduledActions.schedule.game({
                  args: { day, game: 0, messageId: 2 },
                  notificationId: gameDay2,
                  delayInMS: timeFromComponents({ seconds: 9 }),
              });
              api.scheduledActions.schedule.game({
                  args: { day, game: 1, messageId: 0 },
                  notificationId: secondGame0,
                  delayInMS: timeFromComponents({ seconds: 13 }),
              });
              api.scheduledActions.schedule.game({
                  args: { day, game: 1, messageId: 1 },
                  notificationId: secondGame1,
                  delayInMS: timeFromComponents({ seconds: 17 }),
              });
              api.scheduledActions.schedule.game({
                  args: { day, game: 1, messageId: 2 },
                  notificationId: secondGame2,
                  delayInMS: timeFromComponents({ seconds: 21 }),
              });
          }),
          scheduleGameDayPracticeOA: action((state: MutableState, _, api: SyncActionAPI) => {
              api.scheduledActions.schedule.gameDay({
                  args: { day: -1, messageId: 0 },
                  notificationId: gameDay0,
                  delayInMS: timeFromComponents({ seconds: 1 }),
              });
              api.scheduledActions.schedule.gameDay({
                  args: { day: -1, messageId: 1 },
                  notificationId: gameDay1,
                  delayInMS: timeFromComponents({ seconds: 5 }),
              });
              api.scheduledActions.schedule.gameDay({
                  args: { day: -1, messageId: 2 },
                  notificationId: gameDay2,
                  delayInMS: timeFromComponents({ seconds: 9 }),
              });
          }),
          scheduleTeamMoralePinch: action((state: MutableState, _, api: SyncActionAPI) => {
              api.scheduledActions.schedule.teamMorale({
                  args: { pinchId: 0 },
                  notificationId: 'test_pinch0',
                  delayInMS: timeFromComponents({ seconds: 1 }),
              });
              api.scheduledActions.schedule.teamMorale({
                  args: { pinchId: 1 },
                  notificationId: 'test_pinch1',
                  delayInMS: timeFromComponents({ seconds: 3 }),
              });
          }),
          schedulePracticeOA: asyncAction((state: MutableState, _, api: SyncActionAPI) => {
              api.scheduledActions.schedule.practice({
                  args: { messageId: 0 },
                  notificationId: practice0,
                  delayInMS: timeFromComponents({ seconds: 1 }),
              });
              api.scheduledActions.schedule.practice({
                  args: { messageId: 1 },
                  notificationId: practice1,
                  delayInMS: timeFromComponents({ seconds: 5 }),
              });
              api.scheduledActions.schedule.practice({
                  args: { messageId: 2 },
                  notificationId: practice2,
                  delayInMS: timeFromComponents({ seconds: 9 }),
              });
          }),
          sendGameDynamicDayOA: asyncAction(
              async (
                  state: MutableState,
                  args: { textKey: ReplicantCreativeType; imageKey: string },
                  api: AsyncActionAPI,
              ) => {
                  const { textKey, imageKey } = args;
                  await sendGameDynamicDayOA(state, api, {
                      textKey,
                      imageKey,
                      feature: 'gameday_test',
                      creativeAssetID: 'dynamic_test',
                  });
              },
          ),
          cheatSetClockOffset: action((state, args: { offset: number }, api: any) => {
              api.setClockOffset(args.offset);
          }),
          cheatGetClockOffset: action((state, _, api: any) => api.getClockOffset()),
          cheatLivesAdd: action((state: MutableState, args: { amount: number }, api: SyncActionAPI) => {
              livesAdd(state, args.amount, api.date.now());
          }),
          cheatLivesSet: action((state: MutableState, args: { amount: number }, api: SyncActionAPI) => {
              livesSet(state, args.amount, api.date.now());
          }),
          cheatLivesInfinite: action((state: MutableState, args: { duration: number }, api: SyncActionAPI) => {
              addInfiniteLives(state, args.duration, api.date.now());
          }),
          cheatAddStars: action((state: MutableState, args: { amount: number }, api: SyncActionAPI) => {
              state.stars += args.amount;
              if (state.stars < 0) state.stars = 0;
          }),
          cheatAddCoins: action((state: MutableState, args: { amount: number }, api: SyncActionAPI) => {
              state.coins += args.amount;
              if (state.coins < 0) state.coins = 0;
          }),
          cheatAddPremiumCoins: action((state: MutableState, args: { amount: number }, api: SyncActionAPI) => {
              state.coinsPremium += args.amount;
              if (state.coinsPremium < 0) state.coinsPremium = 0;
          }),
          cheatSentInitTrue: action((state: MutableState, _, api: SyncActionAPI) => {
              state.sentInitMessage = true;
          }),
          cheatGameDayComplete: action((state: MutableState, _, api: SyncActionAPI) => {
              gameDayComplete(state, api.date.now());
          }),
          cheatSecondGameComplete: action((state: MutableState, _, api: SyncActionAPI) => {
              gameSecondComplete(state, api.date.now());
          }),
          cheatCompleteTaskSetLevel: action(
              (state: MutableState, opts: { skipIntro?: boolean }, api: SyncActionAPI) => {
                  const { skipIntro } = opts;
                  const level = state.task.level;
                  if (level + 1 < task.taskList.length) {
                      // all good complete task set
                      state.task.introSeen = !!skipIntro || false;
                      state.task.level++;
                      state.task.timestamp = 0;
                      // set new tasks for new level
                      state.task.steps = task.taskList[state.task.level].steps.map((_) => ({ timestamp: 0 }));
                  } else {
                      // max level make sure to set -1 to indicate each task within the set is complete
                      state.task.steps = task.taskList[state.task.level].steps.map((_) => ({ timestamp: -1 }));
                      // Max level, set -1 to indicate task is complete and keep the rest of the data
                      state.task.timestamp = -1;
                  }
              },
          ),
          cheatSetFinalTaskLevel: action((state: MutableState, _, api: SyncActionAPI) => {
              // all good complete task set
              state.task.introSeen = false;
              state.task.level = task.taskList.length - 1;
              state.task.timestamp = 0;
              // set new tasks for new level
              state.task.steps = task.taskList[state.task.level].steps.map((_) => ({ timestamp: 0 }));
          }),
          cheatForceGameDay: action((state: MutableState, args: { day: number }, api: SyncActionAPI) => {
              state.gameDay.day = args.day;
              state.gameDay.step = 0;
              state.gameDay.completed = true; // set completed at start to avoid re-launching games at startup
          }),
          cheatResetNativeState: action((state: MutableState, _, api: SyncActionAPI) => {
              state.native.androidLastLaunched = 0;
              state.native.iosLastLaunched = 0;
              state.native.androidRewardTimestamp = 0;
              state.native.iosRewardTimestamp = 0;
          }),
          cheatInitTasks: action((state: MutableState, _, api: SyncActionAPI) => {
              state.task.steps = task.taskList[0].steps.map((_) => ({ timestamp: 0 }));
          }),
          cheatGameRewardGrant: action((state: MutableState, _, api: SyncActionAPI) => {
              const id = Math.floor(api.math.random() * (gameDay.gameDayRewards.length - 1));
              state.gameDay.rewards.push({ gameId: id });
          }),
          cheatGameRewardGrantV2: action((state: MutableState, _, api: SyncActionAPI) => {
              const id = Math.floor(api.math.random() * (gameDay.gameDayRewards.length - 1));
              state.gameDay.rewards.push({ gameId: id, game: api.math.random() < 0.5 ? 1 : 0 });
          }),
          cheatGameSetMorale: action((state: MutableState, args: { value: number }, api: SyncActionAPI) => {
              state.teamMorale.ftue = true;
              state.teamMorale.score = args.value;
              state.teamMorale.timestamp = api.date.now();
          }),
          cheatGenerateMoralePinch: action(
              (state: MutableState, opts: { pinchId: number; scenarioId: number }, api: SyncActionAPI) => {
                  const { pinchId, scenarioId } = opts;
                  state.teamMorale.pinchFlow.pinchId = pinchId;
                  state.teamMorale.pinchFlow.scenarioId = scenarioId;
              },
          ),
      }
    : null;
