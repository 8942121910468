import { NineSlicePlane, Sprite, Texture } from 'pixi.js';

import { uiAlignCenter } from '../../../lib/pixi/uiTools';
import { BasicText } from '../../lib/ui/text/BasicText';
import { BasicPopup, BasicPopupOptions } from './BasicPopup';
import app from '../../getApp';

const HEIGHT = 660;

// types
//-----------------------------------------------------------------------------
export type NotEnoughStarsPopupOptions = {
    onOk: VoidFunction;
} & BasicPopupOptions;

const manifest = {
    arrow: 'icon.arrow.png',
    frame: 'frame.inset.light.png',
    button: 'button.green.png',
    star: 'icon.star.main.large.png',
    starFrame: 'icon.star.frame.png',
    board: 'icon.game.board.png',
    glow: 'vfx.glow2.png',
};

/*
    Not enough stars popup
*/
export class NotEnoughStarsPopup extends BasicPopup {
    public override preload() {
        return [...super.preload(), ...app().resource.loadAssets([...Object.values(manifest)])];
    }

    // @ts-ignore
    public override async spawning(options: NotEnoughStarsPopupOptions) {
        void super.spawning({
            ...options,
            header: '[popupNoStarTitle]',
            width: 740,
            height: HEIGHT,
            onOk: options.onOk,
            onClose: options.onClose,
        });

        const description = new BasicText({
            text: '[popupNoStarMsg]',
            style: {
                fill: '#3E4047',
                align: 'center',
                fontWeight: 'bold',
                fontSize: 30,
                lineJoin: 'round',
            },
        });

        const frame = this._spawnMainFrame();

        // spawn
        this.main.addContent({
            main: {
                content: frame,
                styles: {
                    position: 'topCenter',
                    marginTop: 180,
                },
            },
            description: {
                content: description,
                styles: {
                    position: 'topCenter',
                    marginTop: 460,
                },
            },
        });
    }

    private _spawnMainFrame() {
        const frame = new NineSlicePlane(Texture.from(manifest.frame), 28, 28, 28, 28);
        frame.width = 612;
        frame.height = 261;
        const board = Sprite.from(manifest.board);
        const arrow = Sprite.from(manifest.arrow);
        const iconGlow = Sprite.from(manifest.glow);
        const star = Sprite.from(manifest.star);
        const starFrame = Sprite.from(manifest.starFrame);

        starFrame.addChild(star);
        uiAlignCenter(starFrame, star);

        iconGlow.pivot.set(iconGlow.width / 2, iconGlow.height / 2);
        iconGlow.scale.set(0.9);

        const offset = 170;
        uiAlignCenter(frame, board, -offset, 6);
        uiAlignCenter(frame, arrow, 0, 3);
        uiAlignCenter(frame, iconGlow, offset, 4);
        uiAlignCenter(frame, starFrame, offset);

        frame.addChild(board, arrow, iconGlow, starFrame);

        return frame;
    }
}
