import { Layout } from '@pixi/layout';
import { Container, NineSlicePlane, Sprite, TEXT_GRADIENT, Texture } from 'pixi.js';

import { uiAlignCenter, uiAlignCenterX, uiSizeToWidth } from '../../../lib/pixi/uiTools';
import { TextSliceButton } from '../../lib/ui/buttons/TextSliceButton';
import { UpDownButton } from '../../lib/ui/buttons/UpDownButton';
import { BasicText } from '../../lib/ui/text/BasicText';
import { BasicPopupOptions } from './BasicPopup';
import app from '../../getApp';
import { PopupScreen } from '../../lib/screens/PopupScreen';

// types
//-----------------------------------------------------------------------------
export type BaseRibbonPopupOptions = {
    ribbon: 'green' | 'red' | 'orange';
    okButtonWidth?: number;
    skipCommandPanel?: boolean;
} & BasicPopupOptions;

const manifest = {
    greenRibbon: 'frame.ribbon.green.png',
    redRibbon: 'frame.ribbon.red.png',
    orangeRibbon: 'frame.ribbon.orange.png',
    bgSilver: 'frame.popup.silver.png',
    bgYellow: 'frame.popup.yellow.png',
    commandPanel: 'panel.cmd.fade.white.png',
    up: 'button.green.png',
    close: 'button.close.png',
};

/*
    Base popup with ribbon header
*/
export abstract class BaseRibbonPopup extends PopupScreen {
    // fields
    //-------------------------------------------------------------------------
    // events
    public onOk?: () => Promise<any>;
    // scene
    protected main: Layout;

    private _mainButton: TextSliceButton;

    public get mainButton() {
        return this._mainButton;
    }

    // impl
    //-------------------------------------------------------------------------
    public preload(options?: unknown) {
        return app().resource.loadAssets(Object.values(manifest));
    }

    public override async spawning(options: BaseRibbonPopupOptions) {
        // set fields
        this.onOk = options.onOk;
        this.onClose = options.onClose;

        options.height ??= 520;
        const commandPanelHeight = Number(!!this.onOk) && 140;
        options.height += commandPanelHeight;

        await super.spawning({
            width: 700,
            ...options,
        });

        const bgAsset = options.ribbon === 'orange' ? manifest.bgYellow : manifest.bgSilver;
        const ribbonAsset = manifest[`${options.ribbon}Ribbon` as const];

        const headerText = new BasicText({
            text: options.header || '',
            style: {
                align: 'center',
                fontSize: 50,
                fontWeight: 'bold',
                fillGradientType: TEXT_GRADIENT.LINEAR_VERTICAL,
                fill: [0xfcffd5, 0xfeffea],
                stroke: 'black',
                strokeThickness: 4,
                lineJoin: 'round',
                dropShadow: true,
                dropShadowDistance: 5,
                dropShadowAngle: Math.PI / 2,
                dropShadowAlpha: 0.3,
            },
        });

        uiSizeToWidth(headerText, 430);

        const ribbonHeight = 104;

        const ribbon = new NineSlicePlane(Texture.from(ribbonAsset), 32, 48, 32, 56).props({
            width: 510,
            height: ribbonHeight,
        });

        const ribbonContainer = new Container();

        ribbonContainer.addChild(ribbon, headerText);
        uiAlignCenter(ribbonContainer, headerText, 4, -5);
        const { width, height } = this.baseLayout;

        this.baseLayout.addContent({
            // background
            bg: {
                content: new NineSlicePlane(Texture.from(bgAsset), 35, 75, 35, 48).props({ width, height }),
                styles: { width, height },
            },
            ribbon: {
                content: ribbonContainer,
                styles: {
                    position: 'topCenter',
                },
            },
            main: {
                content: (this.main = new Layout({
                    styles: {
                        width: '100%',
                        height: '100%',
                    },
                })),
                styles: {
                    position: 'center',
                    height: height - 160 - commandPanelHeight,
                    marginTop: -commandPanelHeight / 2 - 8,
                    padding: 40,
                },
            },
        });

        if (this.onClose) {
            const button = new UpDownButton({
                up: manifest.close,
                down: manifest.close,
                animate: true,
            });
            button.props({
                pivot: { x: button.width * 0.5, y: button.height * 0.5 },
                x: 68,
                y: 24,
                onPress: async () => this.onClose?.(),
            });
            this.baseLayout.addContent({
                // close button
                close: {
                    content: button,
                    styles: {
                        marginTop: 61,
                        marginRight: -15,
                        position: 'topRight',
                    },
                },
            });
        }

        if (commandPanelHeight) {
            const commandPanel = Sprite.from(options.skipCommandPanel ? Texture.EMPTY : manifest.commandPanel);
            commandPanel.props({
                x: 19,
                y: this.baseLayout.height - commandPanelHeight - 36,
                width: this.baseLayout.width - 38,
                height: commandPanelHeight,
            });
            this.baseLayout.addChild(commandPanel);
        }

        // ok button
        if (this.onOk) {
            const okButton = (this._mainButton = new TextSliceButton({
                text: options.okButton ?? '[buttonOk]',
                animate: true,
                offsetY: -5,
                slice: {
                    sliceUp: manifest.up,
                    sliceDown: manifest.up,
                    width: options.okButtonWidth ?? 300,
                    height: 122,
                    left: 30,
                    top: 16,
                    right: 30,
                    bottom: 32,
                },
                style: {
                    fill: 'FFF',
                    fontSize: 40,
                    fontWeight: 'bold',
                    lineJoin: 'round',
                    fontStyle: 'normal',
                    dropShadow: true,
                    dropShadowDistance: 1,
                },
            }));
            okButton.props({
                y: this.baseLayout.height - 100,
                pivot: { x: okButton.width * 0.5, y: okButton.height * 0.5 },
                onPress: async () => this.onOk?.(),
            });
            uiAlignCenterX(this.baseLayout, okButton); // Align with pivot to enable scale animation from center
            this.baseLayout.addChild(okButton);
        }
    }
}
