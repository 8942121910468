import { analytics } from '@play-co/gcinstant';

export type TriggerSource =
    | 'home_icon_install'
    | 'crossplay_popup'
    | 'purchase_install'
    | 'purchase_redirect'
    | 'launch_install';

export type CrossPlayFeature = 'play_on_app_popup' | 'shop' | 'crossplay_popup' | 'launch_redirect';

export type CrossplayTriggerParams = {
    triggerSource: TriggerSource;
    feature: string;
    productID?: string;
    subFeature?: string;
    isInstall: boolean;
};

export type CrossplayStartParams = CrossplayTriggerParams;

export type CrossplayFinishParams = CrossplayStartParams & {
    wasInBackground: boolean;
    elapsedSecondsCrossplayStart: number;
};

export function trackLiffLoginCrossplaySwitch(props: { deepLinkLength: number }) {
    analytics.pushEvent('LiffLoginCrossplaySwitch', { ...props });
}
