import app from '../../../../getApp';
import { BlockEntity, despawnBlockEntity } from '../../entities/BlockEntity';
import { GameScene } from '../../GameScene';
import { mapGetPan } from '../../util/mapTools';
import { IEffect } from './IEffect';
import { RocketEffect } from './RocketEffect';

// types
//-----------------------------------------------------------------------------
export type RocketRocketComboEffectOptions = {
    subject: BlockEntity;
    pair: BlockEntity[];
    all: BlockEntity[];
};

/*
    rocket+rocket combo launch effect
*/
export class RocketRocketComboEffect implements IEffect {
    // fields
    //-------------------------------------------------------------------------
    // input
    private readonly _scene: GameScene;
    private readonly _options: RocketRocketComboEffectOptions;

    // init
    //-------------------------------------------------------------------------
    constructor(scene: GameScene, options: RocketRocketComboEffectOptions) {
        this._scene = scene;
        this._options = options;
    }

    // impl
    //-------------------------------------------------------------------------
    public async execute() {
        const position = this._options.subject.c.position.mapPosition;

        // play sound
        void app().sound.play('puzzle-combo-rocket-rocket.mp3', { pan: mapGetPan(position) });

        // despawn all blocks
        for (const block of this._options.all) {
            despawnBlockEntity(this._scene, block);
        }

        // execute horizontal rocket effect
        const horizontalEffect = new RocketEffect(this._scene, {
            position,
            axis: 'horizontal',
        }).execute();

        // execute vertical rocket effect
        const verticalEffect = new RocketEffect(this._scene, {
            position,
            axis: 'vertical',
        }).execute();

        // wait on both effects
        await Promise.all([horizontalEffect, verticalEffect]);
    }
}
