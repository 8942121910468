import { action, SB } from '@play-co/replicant';

import { BoosterCollection, BoosterId, boosterIds, PowerBoosterId, powerBoosterIds } from '../defs/booster';
import gameConfig from '../defs/gameConfig';
import { MutableState, SyncActionAPI } from '../defs/replicant';
import { numberClamp } from '../util/mathTools';

// state
//-----------------------------------------------------------------------------
export const boosterState = {
    // booster map indexed by BoosterId
    boosters: SB.map(
        SB.object({
            // number of this booster available
            count: SB.int(),
            // flag to trigger pointer if first time seeing this booster
            ftue: SB.boolean(),
        }),
    ).default(
        boosterIds.reduce((collection, id) => {
            collection[id] = { count: 3, ftue: false };
            return collection;
        }, {} as BoosterCollection),
    ),

    // power boosters
    powerBoosters: SB.object({
        // flag to trigger pointer if first time seeing this booster
        ftue: SB.boolean().default(false),
        // power booster map indexed by PowerBoosterId
        map: SB.map(
            SB.object({
                // number of this booster available
                count: SB.int(),
            }),
        ).default(
            powerBoosterIds.reduce(
                (collection, id) => {
                    collection[id] = { count: 3 };
                    return collection;
                },
                {} as Record<PowerBoosterId, { count: number }>,
            ),
        ),
    }),
};

// actions
//-----------------------------------------------------------------------------
export const boosterActions = {
    // set booster count directly
    boosterSet: action((state: MutableState, options: { id: BoosterId; count: number }, api: SyncActionAPI) => {
        // dev only
        if (process.env.IS_DEVELOPMENT) {
            // update boosters
            boosterSet(state, options.id, options.count);
        }
    }),
    boosterFtue: action((state: MutableState, options: { id: BoosterId }, api: SyncActionAPI) => {
        state.boosters[options.id].ftue = true;
        boosterAdd(state, options.id, -1);
    }),

    //TODO: cheaters!
    // consumes a booster item
    boosterConsume: action((state: MutableState, options: { id: BoosterId }, api: SyncActionAPI) => {
        // consume booster
        boosterAdd(state, options.id, -1);
    }),

    // buys a booster item
    boosterBuy: action(
        (state: MutableState, options: { id: BoosterId }, api: SyncActionAPI) =>
            //TODO: pending spec
            /*
        // get booster def
        const booster = boosterMap[options.id];

        // require booster exists
        if (!booster) return new Error('none');

        // require can afford
        if (booster.cost > state.gold) return new Error('afford');

        // grant boosters to player
        boosterAdd(state, options.id, gameConfig.boosters.bundle);

        // subtract gold  by cost
        goldAdd(state, -booster.cost);
        */
            true,
    ),

    // set power booster count directly
    powerBoosterSet: action(
        (state: MutableState, options: { id: PowerBoosterId; count: number }, api: SyncActionAPI) => {
            // dev only
            if (process.env.IS_DEVELOPMENT) {
                // update boosters
                powerBoosterSet(state, options.id, options.count);
            }
        },
    ),
    powerBoosterFtue: action((state: MutableState, _, api: SyncActionAPI) => {
        state.powerBoosters.ftue = true;
    }),

    //TODO: cheaters!
    // consumes power booster items
    powerBoostersConsume: action((state: MutableState, options: { ids: PowerBoosterId[] }, api: SyncActionAPI) => {
        // consume boosters
        for (const type of options.ids) powerBoosterAdd(state, type, -1);
    }),

    // buys a booster item
    powerBoosterBuy: action(
        (state: MutableState, options: { id: BoosterId }, api: SyncActionAPI) =>
            //TODO: pending spec
            /*
        // get booster def
        const booster = boosterMap[options.id];

        // require booster exists
        if (!booster) return new Error('none');

        // require can afford
        if (booster.cost > state.gold) return new Error('afford');

        // grant boosters to player
        boosterAdd(state, options.id, gameConfig.boosters.bundle);

        // subtract gold  by cost
        goldAdd(state, -booster.cost);
        */
            true,
    ),
};

// api
//-----------------------------------------------------------------------------
// boosters
export function boosterSet(state: MutableState, id: BoosterId, count: number) {
    state.boosters[id].count = numberClamp(count, 0, gameConfig.boosters.max);
}

export function boosterAdd(state: MutableState, id: BoosterId, delta = 1) {
    boosterSet(state, id, state.boosters[id].count + delta);
}

// booster setters for the resourceAdders
export function dartAdd(state: MutableState, count: number) {
    boosterAdd(state, 'dart', count);
}

export function bulletAdd(state: MutableState, count: number) {
    boosterAdd(state, 'bullet', count);
}

export function drillAdd(state: MutableState, count: number) {
    boosterAdd(state, 'drill', count);
}

export function rouletteAdd(state: MutableState, count: number) {
    boosterAdd(state, 'roulette', count);
}

// power boosters
export function powerBoosterSet(state: MutableState, id: PowerBoosterId, count: number) {
    state.powerBoosters.map[id].count = numberClamp(count, 0, gameConfig.boosters.max);
}

export function powerBoosterAdd(state: MutableState, id: PowerBoosterId, delta = 1) {
    powerBoosterSet(state, id, state.powerBoosters.map[id].count + delta);
}
