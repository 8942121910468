import { getCoinBalance } from '../defs/player';
import { State } from '../defs/replicant';

type CurrencyGrantFeature =
    | 'shop'
    | 'daily_bonus'
    | 'puzzle_continue'
    | 'refill_lives'
    | 'native_reward'
    | 'admin'
    | 'game_day';
type CurrencyConsumeFeature = 'refill_lives' | 'extra_moves' | 'boosters' | 'power_blocks' | 'moves' | 'lives';

export type CurrencyFeature = CurrencyGrantFeature | CurrencyConsumeFeature;
export type Coins = 'coins' | 'coinsPremium';

export function getCurrencyEvent(
    state: State,
    opts: {
        feature: CurrencyFeature;
        subFeature?: string;
        oldCurrencies: { freeCoins: number; paidCoins: number; totalCoins: number };
        event: 'grant' | 'consume';
    },
) {
    const { feature, subFeature, oldCurrencies, event } = opts;
    const newFreeCoins = state.coins;
    const newPaidCoins = state.coinsPremium;
    const newTotalCoins = getCoinBalance(state);

    const diffMap = {
        grant: {
            currencyDiff: {
                freeCoinsGranted: newFreeCoins - oldCurrencies.freeCoins,
                paidCoinsGranted: newPaidCoins - oldCurrencies.paidCoins,
                totalCoinsGranted: newTotalCoins - oldCurrencies.totalCoins,
            },
            eventType: 'CurrencyGrant',
        },
        consume: {
            currencyDiff: {
                freeCoinsConsumed: oldCurrencies.freeCoins - newFreeCoins,
                paidCoinsConsumed: oldCurrencies.paidCoins - newPaidCoins,
                totalCoinsConsumed: oldCurrencies.totalCoins - newTotalCoins,
            },
            eventType: 'CurrencyConsume',
        },
    };

    const { currencyDiff, eventType } = diffMap[event];
    return {
        eventType,
        eventProperties: {
            feature,
            subFeature,
            oldFreeCoins: oldCurrencies.freeCoins,
            oldPaidCoins: oldCurrencies.paidCoins,
            oldTotalCoins: oldCurrencies.totalCoins,
            newFreeCoins,
            newPaidCoins,
            newTotalCoins,
            ...currencyDiff,
        },
    };
}
