import NakedPromise from '../../../lib/pattern/NakedPromise';
import { textLocaleFormat } from '../../../lib/util/textTools';
import { tween } from '../../../lib/util/tweens';
import { GameId } from '../../../replicant/defs/gameDay';
import { sleep } from '../../../replicant/util/jsTools';
import app from '../../getApp';
import { PlayerClipId, PlayerSkin } from '../../concept/BaseballPlayer';
import { trackGameDayStep } from '../../lib/analytics/gameDay';
import { GameDayPlayer, GameDayScreen } from '../../main/home/GameDayScreen';
import { GameDayFlow, GameDayFlowOpts, ScoreData } from './GameDayFlow';

const scoreboardData: ScoreData[] = [
    {
        balls: 0,
        strikes: 2,
        outs: 2,
        visitorData: ['0', '2', '0'],
        homeData: ['0', '1'],
        visitorR: 2,
        homeR: 1,
    },
    {
        balls: 0,
        strikes: 2,
        outs: 2,
        visitorData: ['0', '2', '0', '0', '1', '1', '0', '0'],
        homeData: ['0', '1', '0', '1', '1', '0', '0', '0'],
        visitorR: 4,
        homeR: 3,
    },
    {
        balls: 0,
        strikes: 0,
        outs: 0,
        visitorData: ['0', '2', '0', '0', '1', '1', '0', '0', '4'],
        homeData: ['0', '1', '0', '1', '1', '0', '0', '0', '0'],
        visitorR: 8,
        homeR: 3,
    },
];

export class GameDay6Flow extends GameDayFlow {
    // fields
    //-------------------------------------------------------------------------

    // init
    //-------------------------------------------------------------------------
    constructor(opts: GameDayFlowOpts) {
        super(opts);
        this._visitor = textLocaleFormat('[gameDay6Opponent]');
        this._home = app().server.state.name;
        this._opponent = opts.opponentOverride ?? textLocaleFormat('[gameDay6Opponent]');
        this._scoreboard = 'board0';
        this._gameDaySteps = [
            { handler: this._intro, substeps: this._introSubSteps },
            { handler: this._step1, substeps: 1 },
            { handler: this._step2, substeps: 2 },
            { handler: this._step3, substeps: 2 },
            { handler: this._step4, substeps: 1 },
            { handler: this._step5, substeps: 2 },
            { handler: this._stepEnding, substeps: this._endingSubSteps },
        ];
    }

    // impl
    //-------------------------------------------------------------------------
    private async _intro() {
        await this._baseIntro({
            scoreData: scoreboardData[0],
            tapDialog: `[gameDay6Step0|${this._home}]`,
            nextBgId: 'gameDay1',
        });
    }

    private async _step1() {
        let stepIndex = this._stepOffset[this._step];
        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay1',
            players: [
                {
                    clip: 'onbase3',
                    loop: true,
                    skin: 'character1_boy',
                },
            ] as GameDayPlayer[],
        })) as GameDayScreen;

        void app().nav.preload('gameDayScreen', { bgId: 'gameDay2' });
        await sleep(1.25);

        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay2',
            players: [
                {
                    clip: 'batter_sigh',
                    loop: false,
                    skin: 'character2_girl',
                } as GameDayPlayer,
            ],
        })) as GameDayScreen;

        await sleep(1.66);
        void this._gameDayScreen.playPlayerAnimation({ id: 'batter_idle', loop: true });

        const tapPromise1 = new NakedPromise();
        await this._gameDayScreen.spawnBubbleTap({
            dialogText: `[gameDay6Step1]`,
            promise: tapPromise1,
            bubbleOffset: { x: 0, y: this._gameDayScreen.base.height * 0.5 - this._gameDayScreen.base.height * 0.22 },
            parentOverride: this._gameDayScreen.base,
        });

        void this._gameDayScreen.preloadChoiceView();
        await tapPromise1;

        trackGameDayStep({ stepIndex: stepIndex++, stepName: 'sigh', stepType: 'tap' });
    }

    private async _step2() {
        let stepIndex = this._stepOffset[this._step];
        if (!this._gameDayScreen) {
            this._gameDayScreen = (await app().nav.open('gameDayScreen', {
                bgId: 'gameDay2',
                players: [
                    {
                        clip: 'batter_idle',
                        loop: true,
                        skin: 'character2_girl',
                    } as GameDayPlayer,
                ],
            })) as GameDayScreen;
        }

        const choicePromise = new NakedPromise<number>();
        void this._gameDayScreen.spawnBubbleChoice({
            dialogText: '[gameDay6Step2]',
            choices: ['[gameDay6Choice0]', '[gameDay6Choice1]'],
            promise: choicePromise,
            bubbleOffset: { x: 0, y: this._gameDayScreen.base.height * 0.5 - this._gameDayScreen.base.height * 0.22 },
            parentOverride: this._gameDayScreen.base,
        });

        const choiceIndex = await choicePromise;
        trackGameDayStep({ stepIndex: stepIndex++, stepName: 'first_choice', stepType: 'choice' });

        void app().resource.loadAsset(this._batSFX);
        void app().resource.loadAsset(this._whiffSFX);
        void app().resource.loadAsset(this._catchSFX);
        void app().nav.preload('gameDayScreen', { bgId: 'gameDay5' });
        const animationChoice = ['batter_homerun', 'batter_strikeout'] as PlayerClipId[];

        void sleep(0.4).then(() => app().sound.play(choiceIndex === 0 ? this._batSFX : this._whiffSFX));
        await this._gameDayScreen.playPlayerAnimation({ id: animationChoice[choiceIndex], loop: false });

        // extra for first choice
        if (choiceIndex === 0) {
            this._gameDayScreen = (await app().nav.open('gameDayScreen', {
                bgId: 'gameDay5',
                players: [
                    {
                        clip: 'fielder_catchfly',
                        loop: false,
                        skin: 'character7_boy_red',
                        scaleX: -1,
                    },
                ] as GameDayPlayer[],
            })) as GameDayScreen;
            void sleep(0.25).then(() => app().sound.play(this._catchSFX));

            await sleep(1.75);
            this._gameDayScreen = (await app().nav.open('gameDayScreen', {
                bgId: 'gameDay2',
                players: [
                    {
                        clip: 'batter_nervous',
                        loop: true,
                        skin: 'character2_girl',
                    } as GameDayPlayer,
                ],
            })) as GameDayScreen;
        }

        if (choiceIndex === 1) {
            // same scene already, just need to swap animation
            void this._gameDayScreen.playPlayerAnimation({ id: 'batter_nervous', loop: true, mix: 0.25 });
        }

        const choiceDialogs = ['[gameDay6Step3sub0]', '[gameDay6Step3sub1]'];
        const tapPromise = new NakedPromise();
        await this._gameDayScreen.spawnBubbleTap({
            dialogText: choiceDialogs[choiceIndex],
            promise: tapPromise,
            bubbleOffset: { x: 0, y: this._gameDayScreen.base.height * 0.5 - this._gameDayScreen.base.height * 0.22 },
            parentOverride: this._gameDayScreen.base,
        });
        await tapPromise;
        trackGameDayStep({ stepIndex: stepIndex++, stepName: 'sorry', stepType: 'tap' });
    }

    private async _step3() {
        let stepIndex = this._stepOffset[this._step];
        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay2',
            players: [
                {
                    clip: 'batter_sad',
                    loop: true,
                    skin: 'character2_girl',
                } as GameDayPlayer,
            ],
        })) as GameDayScreen;

        const tapPromise = new NakedPromise();
        await this._gameDayScreen.spawnBubbleTap({
            dialogText: `[gameDay6Step4]`,
            promise: tapPromise,
            narrator: 'arrow',
            bubbleOffset: { x: 0, y: this._gameDayScreen.base.height - 150 },
            parentOverride: this._gameDayScreen.base,
        });
        await tapPromise;

        trackGameDayStep({ stepIndex: stepIndex++, stepName: 'sad', stepType: 'tap' });

        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay2',
        })) as GameDayScreen;

        void this._gameDayScreen.spawnScoreDynamic({
            ...scoreboardData[1],
            scoreboard: this._scoreboard,
            home: this._home,
            visitor: this._visitor,
        });

        const scorePromise = new NakedPromise();
        await this._gameDayScreen.spawnBubbleTap({
            dialogText: `[gameDay6Step5|${this._home}]`,
            promise: scorePromise,
            narrator: 'arrow',
            bubbleOffset: { x: 0, y: this._gameDayScreen.base.height - 150 },
            parentOverride: this._gameDayScreen.base,
        });

        void app().nav.preload('gameDayScreen', { bgId: 'gameDay0' });
        await scorePromise;
        trackGameDayStep({ stepIndex: stepIndex++, stepName: 'mid_score', stepType: 'tap' });
    }

    private async _step4() {
        let stepIndex = this._stepOffset[this._step];

        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay0',
            players: [
                {
                    skin: 'character8_girl_red',
                    clip: 'onbase1_flipped',
                    loop: true,
                } as GameDayPlayer,
            ],
        })) as GameDayScreen;
        void app().nav.preload('gameDayScreen', { bgId: 'gameDay1' });
        await sleep(1.25);
        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay1',
            players: [
                {
                    skin: 'character6_girl_red2',
                    clip: 'onbase3',
                    loop: true,
                } as GameDayPlayer,
            ],
        })) as GameDayScreen;
        void app().nav.preload('gameDayScreen', { bgId: 'thirdBase' });
        await sleep(1.25);
        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'thirdBase',
            players: [
                {
                    skin: 'character3_boy_red',
                    clip: 'onbase1',
                    loop: true,
                } as GameDayPlayer,
            ],
        })) as GameDayScreen;
        void app().nav.preload('gameDayScreen', { bgId: 'gameDay3' });
        await sleep(1.25);

        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay3',
            players: [{ skin: 'character1_boy', clip: 'pitcher_tired_flipped', loop: true } as GameDayPlayer],
        })) as GameDayScreen;

        void this._gameDayScreen.preloadChoiceView(); // preload choice view for next step

        const tapPromise = new NakedPromise();
        await this._gameDayScreen.spawnBubbleTap({
            dialogText: `[gameDay6Step6]`,
            promise: tapPromise,
            bubbleOffset: { x: 0, y: this._gameDayScreen.base.height * 0.5 - this._gameDayScreen.base.height * 0.22 },
            parentOverride: this._gameDayScreen.base,
        });
        await tapPromise;
        trackGameDayStep({ stepIndex: stepIndex++, stepName: 'tired', stepType: 'tap' });
    }

    private async _step5() {
        let stepIndex = this._stepOffset[this._step];
        if (!this._gameDayScreen) {
            this._gameDayScreen = (await app().nav.open('gameDayScreen', {
                bgId: 'gameDay3',
                players: [{ skin: 'character1_boy', clip: 'pitcher_tired_flipped', loop: true } as GameDayPlayer],
            })) as GameDayScreen;
        }

        void app().resource.loadAsset(this._batSFX);

        void this._gameDayScreen.preloadChoiceView(); // preload choice view for next step

        const choicePromise = new NakedPromise<number>();
        await this._gameDayScreen.spawnBubbleChoice({
            dialogText: '[gameDay6Step7]',
            choices: ['[gameDay6Choice2]', '[gameDay6Choice3]'],
            promise: choicePromise,
            bubbleOffset: { x: 0, y: this._gameDayScreen.base.height * 0.5 - this._gameDayScreen.base.height * 0.22 },
            parentOverride: this._gameDayScreen.base,
        });

        void app().nav.preload('gameDayScreen', { bgId: 'gameDay2' });
        const choiceIndex = await choicePromise;
        trackGameDayStep({ stepIndex: stepIndex++, stepName: 'second_choice', stepType: 'choice' });

        if (choiceIndex === 1) {
            this._gameDayScreen = (await app().nav.open('gameDayScreen', {
                bgId: 'gameDay3',
                players: [
                    { skin: 'character5_boy', clip: 'pitcher_run_flipped', loop: true, offsetX: 610 } as GameDayPlayer,
                ],
            })) as GameDayScreen;

            const player = this._gameDayScreen.mainPlayer;
            await player
                .animate()
                .add(player, { x: player.x - 490 }, 1, tween.linear)
                .promise();
        }
        await this._gameDayScreen.playPlayerAnimation({ id: 'pitcher_throw_flipped', loop: false });

        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay2',
            players: [{ skin: 'character7_boy_red', clip: 'batter_homerun', loop: false } as GameDayPlayer],
        })) as GameDayScreen;
        void app().sound.play(this._batSFX);
        await sleep(1.1);

        type Choice = { skin: PlayerSkin; dialog: string };
        const choices = [
            {
                skin: 'character1_boy',
                dialog: '[gameDay6Step8sub0]',
            } as Choice,
            {
                skin: 'character5_boy',
                dialog: '[gameDay6Step8sub1]',
            } as Choice,
        ];

        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay3',
            players: [{ skin: choices[choiceIndex].skin, clip: 'pitcher_watchhomerun', loop: false } as GameDayPlayer],
        })) as GameDayScreen;

        void app().nav.preload('gameDayScreen', { bgId: 'gameDay4' });

        await sleep(1.1);
        void this._gameDayScreen.playPlayerAnimation({ id: 'pitcher_watchhomerun_idle', loop: true });

        const tapPromise = new NakedPromise();
        await this._gameDayScreen.spawnBubbleTap({
            dialogText: choices[choiceIndex].dialog,
            promise: tapPromise,
            bubbleOffset: { x: 0, y: this._gameDayScreen.base.height * 0.5 - this._gameDayScreen.base.height * 0.22 },
            parentOverride: this._gameDayScreen.base,
        });
        await tapPromise;
        trackGameDayStep({ stepIndex: stepIndex++, stepName: 'pitcher_watch', stepType: 'tap' });
    }

    private async _stepEnding() {
        await this._baseLoss({ scoreData: scoreboardData[2], tapDialog: `[gameDay6Step9|${this._home}]` });
    }
}
