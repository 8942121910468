import { gsap } from 'gsap';

import { uiCreateQuad } from '../../../lib/pixi/uiTools';
import type { IScreen } from '../IScreen';
import { ITransition } from '../ITransition';
import app from '../../../app/getApp';

// constants
//-----------------------------------------------------------------------------
const duration = 0.3;

/*
    black overlay fade alpha transition
*/
export class AlphaTransition implements ITransition {
    // impl
    //-------------------------------------------------------------------------
    public async open(screen: IScreen) {
        // make sure overlay is always larger than rendered screen
        const overlay = uiCreateQuad(0x0, 1, app().stage.canvas.width * 2, app().stage.canvas.height * 2);
        overlay.alpha = 1;
        screen.root.addChild(overlay);
        await gsap.to(overlay, {
            duration,
            alpha: 0,
        });
        overlay.destroy();
    }

    public async close(screen: IScreen) {
        // make sure overlay is always larger than rendered screen
        const overlay = uiCreateQuad(0x0, 1, app().stage.canvas.width * 2, app().stage.canvas.height * 2);
        overlay.alpha = 0;
        screen.root.addChild(overlay);
        await gsap.to(overlay, {
            duration,
            alpha: 1,
        });
        overlay.destroy();
    }
}
