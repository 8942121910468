import { FlexContainer } from '@play-co/flex';
import { Sprite, Texture } from 'pixi.js';

import UpdateObserver from '../../../lib/pattern/UpdateObserver';
import { formatResourceCount } from '../../lib/tools/appTools';
import { BasicText } from '../../lib/ui/text/BasicText';
import { layoutConfig } from './layout';
import { manifest } from './manifest';
import app from '../../getApp';

export class ShopHeaderCoins extends FlexContainer {
    private _text: BasicText;
    protected _observer = new UpdateObserver();

    constructor() {
        super({
            width: 180,
            height: 78,
            alignItems: 'center',
        });

        // add bg
        this.addChild(
            new Sprite(Texture.from(manifest.statsPanel)).setFlex({
                id: 'bg',
                position: 'absolute',
                width: '100%',
                height: '100%',
            }),
        );

        // add icon
        this.addChild(
            new Sprite(Texture.from(manifest.coin)).setFlex({
                id: 'icon',
                width: 48,
                height: 48,
                marginLeft: layoutConfig.halfPadding,
            }),
        );

        // add text
        this._text = this.addChild(
            new BasicText({
                text: '',
                style: {
                    fontFamily: 'sans-serif',
                    fontSize: 35,
                    fill: 'white',
                },
            }).setFlex({
                marginLeft: layoutConfig.halfPadding / 2,
            }),
        );

        this._observer.listen(
            () => app().game.player.coins,
            (stats) => {
                this._updateAmount(stats);
            },
        );
        this._observer.start();
    }

    private _updateAmount(amount: number) {
        this._text.text = formatResourceCount(amount);
        this.doLayout({ local: true });
    }
}
