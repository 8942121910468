import { SB } from '@play-co/replicant';

import { getCreativeText, ReplicantCreativeType } from '../chatbot/chatbotTexts';
import {
    chatbotMessageTemplates,
    FirstSessionAssetKey,
    FirstSessionAssetKeyV2,
    GameDayAssetKey,
    generateChatbotPayload,
} from '../chatbot/messageTemplates';
import { MutableState, ScheduledActionAPI, State, SyncActionAPI } from '../defs/replicant';
import settings, { LanguageId } from '../defs/settings';
import { timeFromComponents } from '../util/timeTools';
import { getTimeToClockTime } from './gameDay';

// constants
//-----------------------------------------------------------------------------
const firstSession0 = 'first_session_0';
const firstSession1 = 'first_session_1';
const firstSession2 = 'first_session_2';
const firstSession3 = 'first_session_3';
const firstSession4 = 'first_session_4';
const firstSession5 = 'first_session_5';

// actions
//-----------------------------------------------------------------------------
// scheduledActions
//-----------------------------------------------------------------------------
export const firstSessionScheduledActionsSchema = {
    // token cant ge generated by scheduled action, pass it in
    firstSession: SB.object({ id: SB.string(), token: SB.string().optional() }),
    nextDayV2: SB.object({
        day: SB.int(), // 0-indexed
        messageId: SB.int(), // 0-indexed
    }),
};

export const firstSessionScheduledActions = {
    firstSession: (
        state: MutableState,
        options: { id: FirstSessionAssetKey; token?: string },
        api: ScheduledActionAPI,
    ) => {
        // any message sent, toggle flag to not re-schedule anything on next session
        if (!state.sentInitMessage) {
            state.sentInitMessage = true;
        }
        sendFirstSessionOA(state, api, options.id, 'gameday1', options?.token);
    },
    nextDayV2: (
        state: MutableState,
        options: { day: number; messageId: number }, // same asset keys, different subFeature
        api: ScheduledActionAPI,
    ) => {
        const { day, messageId } = options;
        // any message sent, toggle flag to not re-schedule anything on next session
        if (!state.sentNextDayMessage) {
            state.sentNextDayMessage = true;
        }
        sendNextDayOA(state, api, { day, messageId, subFeature: 'gameday2' });
    },
};
// api
//-----------------------------------------------------------------------------

// events
//-----------------------------------------------------------------------------
export function onFirstSessionInit(api: SyncActionAPI, state: MutableState) {
    // retetion day 1
    api.scheduledActions.unschedule(firstSession0);
    api.scheduledActions.unschedule(firstSession1);
    api.scheduledActions.unschedule(firstSession2);
    // retetion day 2
    api.scheduledActions.unschedule(firstSession3);
    api.scheduledActions.unschedule(firstSession4);
    api.scheduledActions.unschedule(firstSession5);
}

export function onFirstSessionExit(api: ScheduledActionAPI, state: State, token?: string) {
    // if 1st message is successfully sent, dont schedule 'firstSession' messages
    if (!state.sentInitMessage) {
        const lang = state.language as LanguageId;
        // chatbot text and asset key shares id
        const isEn = lang === 'en';

        const prefix = 'first_session';
        api.scheduledActions.schedule.firstSession({
            args: { id: isEn ? `${prefix}_0_en` : `${prefix}_0_ja`, token },
            notificationId: firstSession0,
            delayInMS: timeFromComponents({ minutes: 15 }),
        });

        api.scheduledActions.schedule.firstSession({
            args: { id: isEn ? `${prefix}_1_en` : `${prefix}_1_ja`, token },
            notificationId: firstSession1,
            delayInMS: timeFromComponents({ hours: 4 }),
        });
        api.scheduledActions.schedule.firstSession({
            args: { id: isEn ? `${prefix}_2_en` : `${prefix}_2_ja`, token },
            notificationId: firstSession2,
            delayInMS: timeFromComponents({ hours: 8 }),
        });
    }

    // Only schedule regular retention day 2 if the player skips the first game day
    // if completed first game day, the game day OA system will take over, see gameDay.ts
    if (!state.sentNextDayMessage && state.gameDay.day === 0 && !state.gameDay.completed) {
        const next7am = getTimeToClockTime('sevenAM', api.date.now());
        // if 7am is passed, schedule for next day for all 3
        const clockOffset = next7am < 0 ? timeFromComponents({ days: 1 }) : 0;

        // --- Retention DAY 2, same image and text as real game day 1
        const now = api.date.now();
        api.scheduledActions.schedule.nextDayV2({
            args: { day: 0, messageId: 0 },
            notificationId: firstSession3,
            delayInMS: next7am + clockOffset,
        });
        api.scheduledActions.schedule.nextDayV2({
            args: { day: 0, messageId: 1 },
            notificationId: firstSession4,
            delayInMS: getTimeToClockTime('twelvePM', now) + clockOffset,
        });
        api.scheduledActions.schedule.nextDayV2({
            args: { day: 0, messageId: 2 },
            notificationId: firstSession5,
            delayInMS: getTimeToClockTime('fivePM', now) + clockOffset,
        });
    }
}

export function sendFirstSessionOA(
    state: MutableState,
    api: ScheduledActionAPI | SyncActionAPI,
    id: FirstSessionAssetKey | FirstSessionAssetKeyV2 | GameDayAssetKey,
    subFeature: string,
    token?: string,
) {
    const lang = state.language as LanguageId;

    const creativeId = id.replace('_en', '').replace('_ja', '').replace('_v2', '') as ReplicantCreativeType;
    // chatbot text and asset key shares id
    const creativeText = getCreativeText(lang, creativeId, api.math.random);
    // Default aspectRatio 1:1
    const aspectRatio = '3:2';

    const isEn = lang === 'en';
    const preFilledName = isEn ? settings.defaultNameEN : settings.defaultNameJA;

    api.chatbot.sendMessage(
        state.id,
        chatbotMessageTemplates.flexBubbleMessage({
            args: {
                imageKey: id,
                aspectRatio,
                text: creativeText.text,
                cta: creativeText.cta,
                senderName: state.name ? state.name : preFilledName,
            },
            payload: {
                ...generateChatbotPayload({ feature: 'first_session', subFeature, api, tokenOverride: token }),
                $creativeAssetID: id,
            },
        }),
    );
}

export function sendNextDayOA(
    state: MutableState,
    api: ScheduledActionAPI | SyncActionAPI,
    message: {
        day: number;
        messageId: number;
        subFeature: string;
    },
) {
    const { day, messageId, subFeature } = message;
    const lang = state.language as LanguageId;
    const assetPostFix = lang === 'en' ? 'en' : 'ja';
    const baseId = `game_day_${day}_${messageId}`;
    const assetId = `${baseId}_${assetPostFix}` as GameDayAssetKey;
    const creativeText = getCreativeText(lang, baseId as ReplicantCreativeType, api.math.random);
    // Default aspectRatio 1:1
    const aspectRatio = '3:2';

    const isEn = lang === 'en';
    const preFilledName = isEn ? settings.defaultNameEN : settings.defaultNameJA;

    api.chatbot.sendMessage(
        state.id,
        chatbotMessageTemplates.flexBubbleMessage({
            args: {
                imageKey: assetId,
                aspectRatio,
                text: creativeText.text,
                cta: creativeText.cta,
                senderName: state.name ? state.name : preFilledName,
            },
            payload: {
                ...generateChatbotPayload({ feature: 'first_session', subFeature, api }),
                $creativeAssetID: assetId,
            },
        }),
    );
}
