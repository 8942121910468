import { Layout } from '@pixi/layout';
import { NineSlicePlane, Sprite, Texture } from 'pixi.js';

import { BasicAsyncHandler } from '../../../lib/defs/types';
import { uiAlignCenter, uiAlignCenterX, uiCreateQuad } from '../../../lib/pixi/uiTools';
import { BoosterId, boosterMap } from '../../../replicant/defs/booster';
import { formatResourceCount } from '../../lib/tools/appTools';
import { TextImageButton } from '../../lib/ui/buttons/TextImageButton';
import { BasicText } from '../../lib/ui/text/BasicText';
import app from '../../getApp';
import { PopupScreen, PopupOptions } from '../../lib/screens/PopupScreen';

// types
//-----------------------------------------------------------------------------
export type AppRewardPopupOptions = {
    onClaim: BasicAsyncHandler;
    boosters: number;
    coins: number;
} & PopupOptions;

// constants
//-----------------------------------------------------------------------------
const manifest = {
    bg: 'frame.popup.yellow.png',
    button: 'button.green.png',
    panel: 'panel.native.reward.png',
};
const lazyManifest = {
    bonusEN: 'label.install.en.png',
    bonusJA: 'label.install.ja.png',
};

const WIDTH = 692;
const HEIGHT = 672;

export class AppRewardPopup extends PopupScreen {
    // fields
    //-------------------------------------------------------------------------
    // events
    public onClaim: BasicAsyncHandler;
    // scene
    protected main: Layout;

    private _claimButton: TextImageButton;

    public get claimButton(): TextImageButton {
        return this._claimButton;
    }

    // impl
    //-------------------------------------------------------------------------
    public preload() {
        const lang = app().settings.language;
        const assets = Object.values(manifest);
        lang === 'en' ? assets.push(lazyManifest.bonusEN) : assets.push(lazyManifest.bonusJA);
        Object.keys(boosterMap).forEach((id: BoosterId) => assets.push(boosterMap[id].asset));
        return app().resource.loadAssets(assets);
    }

    public override async spawning(options: AppRewardPopupOptions) {
        void super.spawning({
            ...options,
            width: WIDTH,
            height: HEIGHT,
            underlay: 0.6,
        });

        const { boosters, coins, onClaim } = options;
        this.onClaim = onClaim;

        const boosterAmount = new BasicText({
            text: `x${boosters}`,
            style: {
                align: 'center',
                fontSize: 42,
                fontWeight: 'bold',
                fill: 0xfff976,
                lineJoin: 'round',
                stroke: 0x0,
                strokeThickness: 8,
            },
        });

        const coinAmount = new BasicText({
            text: `x${formatResourceCount(coins)}`,
            style: {
                align: 'center',
                fontSize: 42,
                fontWeight: 'bold',
                fill: 0xfff976,
                lineJoin: 'round',
                stroke: 0x0,
                strokeThickness: 8,
            },
        });

        const rewardView = uiCreateQuad(0x0, 0.001, 500, 100);
        rewardView.addChild(boosterAmount, coinAmount);
        const offset = 132;
        uiAlignCenter(rewardView, boosterAmount, -offset, 0);
        uiAlignCenter(rewardView, coinAmount, offset, 0);

        const panel = Sprite.from(manifest.panel);

        const titleAsset = app().settings.language === 'en' ? lazyManifest.bonusEN : lazyManifest.bonusJA;
        const title = Sprite.from(titleAsset);
        this.baseLayout.addContent({
            bg: {
                content: new NineSlicePlane(Texture.from(manifest.bg), 95, 128, 32, 40).props({
                    width: WIDTH,
                    height: HEIGHT,
                }),
                styles: {
                    width: WIDTH,
                    height: HEIGHT,
                },
            },
            header: {
                content: title,
                styles: {
                    position: 'topCenter',
                    marginTop: -55,
                },
            },
            main: {
                content: panel,
                styles: {
                    position: 'topCenter',
                    marginTop: 130,
                },
            },
            rewards: {
                content: rewardView,
                styles: {
                    position: 'topCenter',
                    marginTop: 345,
                },
            },
        });

        const okButton = (this._claimButton = new TextImageButton({
            text: '[buttonClaim]',
            image: manifest.button,
            y: -5,
            slice: {
                width: 300,
                height: 122,
                left: 30,
                top: 16,
                right: 30,
                bottom: 32,
            },
            style: {
                fill: 'FFF',
                fontSize: 40,
                fontWeight: 'bold',
                lineJoin: 'round',
                fontStyle: 'normal',
                dropShadow: true,
                dropShadowDistance: 1,
            },
        }));
        okButton.props({
            y: this.baseLayout.height - 100,
            pivot: { x: okButton.width * 0.5, y: okButton.height * 0.5 },
            onPress: async () => this.onClaim?.(),
        });
        uiAlignCenterX(this.baseLayout, okButton); // Align with pivot to enable scale animation from center
        this.baseLayout.addChild(okButton);
    }
}
