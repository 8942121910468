import { action } from '@play-co/replicant';

import { MutableState, SyncActionAPI } from '../defs/replicant';
import { stateSchema } from '../state';
import { timeFromComponents } from '../util/timeTools';

// actions
//-----------------------------------------------------------------------------
export const accountActions = {
    setDeleteAccountIntent: action((state: MutableState, intent: boolean, api: SyncActionAPI) => {
        if (intent) {
            state.deleteAccountIntent = api.date.now();
        } else {
            delete state.deleteAccountIntent;
        }
    }),
    deleteAccount: action((state: MutableState, _, api: SyncActionAPI) => {
        if (!state.deleteAccountIntent) {
            throw new Error('No intent to delete account');
        }

        if (!(api.date.now() - state.deleteAccountIntent < timeFromComponents({ minutes: 1 }))) {
            throw new Error('Unintended account deletion request');
        }

        api.sendAnalyticsEvents([
            {
                eventType: 'AccountDelete',
                eventProperties: {},
            },
        ]);

        // Out with previous state.
        for (const key in state) {
            if (Object.prototype.hasOwnProperty.call(state, key)) {
                delete (state as any)[key];
            }
        }

        // In with the default state.
        const resetState = { ...stateSchema.getDefault(), gcinstant: { platformStorage: {} } };
        Object.assign(state, resetState);

        // Nuke metadata
        api.nukeUserMetainfo();
    }),
};
