import { Plugin, PluginOptions } from '@play-co/astro';
import { FlexLayoutSystem } from '@play-co/flex';
import { Container, Renderer } from 'pixi.js';

export interface FlexLayoutPluginOptions extends PluginOptions {
    root: Container;
    renderer: Renderer;
    enableInspector?: boolean;
}

export class FlexLayoutPlugin extends Plugin<FlexLayoutPluginOptions> {
    public async setup(): Promise<void> {
        await FlexLayoutSystem.init();
        if (process.env.IS_DEVELOPMENT && this.options.enableInspector !== false) {
            await loadInspector(this.options);
        }
    }
}

async function loadInspector(options: FlexLayoutPluginOptions) {
    const { LayoutInspector } = await import('../../lib/inspector');

    new LayoutInspector({
        root: options.root,
        renderer: options.renderer,
    });
}
