import { Layout } from '@pixi/layout';
import { Container, NineSlicePlane, Sprite, Texture } from 'pixi.js';

import { BasicHandler, SizeType } from '../../../lib/defs/types';
import { TouchInputComponent } from '../../../lib/pixi/components/TouchInputComponent';
import { uiAlignCenterX, uiAlignCenterY } from '../../../lib/pixi/uiTools';
import { LayoutScreen } from '../../lib/screens/LayoutScreen';
import { TextUpDownButton } from '../../lib/ui/buttons/TextUpDownButton';
import { BasicText } from '../../lib/ui/text/BasicText';
import app from '../../getApp';

// types
//-----------------------------------------------------------------------------
export type MessagePopupOptions = {
    title?: string;
    text: string;
    y?: number;
    width: number;
    opacity?: number;
    onOk?: BasicHandler;
    onTap?: BasicHandler;
};

// constants
//-----------------------------------------------------------------------------
const manifest = {
    // bg: 'frame.chat.png',
    bg: 'frame.narrator.png',
    close: 'button.close.png',
};

/*
    tooltip message popup
*/
export class MessagePopup extends LayoutScreen {
    // fields
    //-------------------------------------------------------------------------
    // scene
    protected _content: Container;
    protected _message: Layout;
    private _dialog: NineSlicePlane;
    private _text: BasicText;
    private _underlayInput: TouchInputComponent;

    // properties
    //-------------------------------------------------------------------------
    protected get content(): Container {
        return this._content;
    }

    protected get message(): Layout {
        return this._message;
    }

    public override resized(size: SizeType): void {
        super.resized(size);

        if (this.message) {
            this.message.width = this.root.width;
            this.message.height = this.root.height;
        }
    }

    // impl
    //-------------------------------------------------------------------------
    public preload() {
        return app().resource.loadAssets([...Object.values(manifest)]);
    }

    public async spawning(options?: MessagePopupOptions) {
        this._spawn(options);
    }

    public despawned() {
        // remove all children
        this.root.removeChildren();
        // FIXME workaround to remove all children
        for (const id of this.root.content.children.keys()) {
            this.root.removeChildByID(id);
        }

        // disable input
        if (this._underlayInput) this._underlayInput.enabled = false;
    }

    // private: scene
    //-------------------------------------------------------------------------
    private _spawn(options: MessagePopupOptions) {
        this._content = this.root.addChild(new Container());

        this._message = this.root.addChild(
            new Layout({
                styles: {
                    width: '100%',
                    height: '100%',
                },
            }),
        );

        this._spawnScreen(options);
        this._spawnMessage(options);

        if (options.onTap) {
            this._underlayInput = Object.assign(new TouchInputComponent(this.root), {
                onTap: async () => options.onTap?.(),
            });
        }
    }

    private _spawnScreen(options: MessagePopupOptions) {
        if (options.opacity !== 0) {
            const screen = Object.assign(Sprite.from(Texture.WHITE), {
                alpha: options.opacity,
                tint: 0,
                interactive: !!options.onOk,
            });
            this.message.addContent({
                screen: {
                    content: screen,
                    styles: {
                        position: 'center',
                        minWidth: '100%',
                        minHeight: '100%',
                    },
                },
            });
        }
    }

    private _spawnMessage(options: MessagePopupOptions) {
        if (!options.text) return;

        // const dialog = (this._dialog = new NineSlicePlane(Texture.from(manifest.bg), 20, 20, 20, 20));
        const dialog = (this._dialog = new NineSlicePlane(Texture.from(manifest.bg), 80, 80, 80, 80));

        const text = new BasicText({
            text: options.text,
            style: {
                align: 'center',
                fill: '#454299',
                fontSize: 30,
                lineJoin: 'round',
                wordWrap: true,
                wordWrapWidth: options.width || 600,
            },
        });
        this._text = dialog.addChild(text);
        this._updateBounds();

        if (options.onOk) {
            const button = new TextUpDownButton({
                up: manifest.close,
                down: manifest.close,
                style: {},
                animate: true,
            }).props({
                x: dialog.width - 40,
                y: -25,
                onPress: options.onOk,
            });
            dialog.addChild(button);
        }

        if (options.onTap) {
            this.message.props({
                onTap: options.onTap,
            });
        }

        this.message.addContent({
            dialog: {
                content: dialog,
                styles: {
                    marginTop: options.y || 0,
                    position: 'topCenter',
                },
            },
        });
    }

    private _updateBounds() {
        this._dialog.width = this._text.width + 80;
        // this._dialog.height = this._text.height + 100;
        uiAlignCenterX(this._dialog, this._text);
        uiAlignCenterY(this._dialog, this._text);
    }
}
