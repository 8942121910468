import { IFlow } from '../../../lib/pattern/IFlow';
import NakedPromise from '../../../lib/pattern/NakedPromise';
import app from '../../getApp';
import { HomeScreen } from '../../main/home/HomeScreen';

export class TutorialOriginalConceptFlow implements IFlow {
    private readonly _complete = new NakedPromise<boolean>();

    // impl
    //-------------------------------------------------------------------------
    public async execute() {
        await this._actionHome();

        return this._complete;
    }

    private async _actionHome() {
        const home = (await app().nav.open('homeScreen', {
            disableButtons: false,
            skipPointer: true,
        })) as HomeScreen;

        const onShowUnlockLater = async () => {
            await home.showNotification('[checkLater]');
        };

        home.shopButton.onPress = onShowUnlockLater;
        home.puzzleButton.onPress = onShowUnlockLater;
        home.upgradeButton.onPress = onShowUnlockLater;
    }
}
