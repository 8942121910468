import { BaseRibbonPopup, BaseRibbonPopupOptions } from './BaseRibbonPopup';

// types
//-----------------------------------------------------------------------------
export type AppPopupOptions = {} & BaseRibbonPopupOptions;

/*
    liff to native promo popup
*/
export abstract class AppPopup extends BaseRibbonPopup {
    public override preload() {
        return [...super.preload()];
    }

    // @ts-ignore
    public override async spawning(options: LevelSuccessMaxPopupOptions) {
        await super.spawning({
            onClose: options.onClose,
            ...options,
            ribbon: 'orange',
            header: `[nativePromoTitle]`,
            okButton: '[nativePromoButton]',
            okButtonWidth: 400,
            skipCommandPanel: true,
        });
    }
}
