const PROD = 'https://liff.line.me/2001676732-yaeZbD9a';
const DEV = 'https://liff.line.me/2001676731-5oVdbMO4';
const ALPHA = 'https://liff.line.me/2003919116-Gr9rRNp9';
const LOCAL = 'https://localhost:5173';

export function getGameUrl() {
    let url = LOCAL; // No stage -> local dev.
    if (process.env.STAGE === 'prod') url = PROD;
    if (process.env.STAGE === 'dev') url = DEV;
    if (process.env.STAGE === 'alpha' || process.env.STAGE === 'bravo') url = ALPHA;

    return url;
}
