import type { Entity, QueriesObject, QueryResults, System } from '@play-co/odie';
import { BasicHandler } from 'src/scripts/lib/defs/types';

import { BlockComponent } from '../components/BlockComponent';
import { PhaseComponent } from '../components/PhaseComponent';
import { ShellBlockComponent } from '../components/ShellBlockComponent';
import { PhaseId } from '../defs/types';
import { ShellBlockEntity } from '../entities/BlockEntity';
import type { GameScene } from '../GameScene';

export class ShellBlockSystem implements System {
    public static readonly NAME = 'blockShell';
    public static Queries: QueriesObject = {
        block: {
            components: [BlockComponent, ShellBlockComponent],
            removed: true,
        },
        phase: {
            components: [PhaseComponent],
            modified: true,
        },
    };

    public scene!: GameScene;
    public queries!: QueryResults;
    private _phaseHandlers: { [key in PhaseId]?: BasicHandler } = {
        input: this._onInputPhase,
    };

    public modifiedQuery(_: Entity, component: PhaseComponent, properties: { phase?: PhaseId }) {
        // handle phase change
        if (properties.phase) this._phaseHandlers[component.phase]?.call(this);
    }

    private _onInputPhase() {
        const phase = this.scene.sessionEntity.c.phase;

        if (!phase.moved || phase.round <= 0) return;
        this.toggleShells();
    }

    private toggleShells() {
        this.queries.block.forEach((entity: ShellBlockEntity) => {
            const { view } = entity.c.blockShell;
            view.open = !view.open;
        });
    }
}
