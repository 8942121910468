import { Container } from 'pixi.js';
import { SpineAnimation } from '../../lib/pixi/animation/SpineAnimation';

// constants
//-----------------------------------------------------------------------------
export const confettiClipId = 'confetti';

const manifest = {
    spine: 'spine.confetti.json',
};

export class Confetti extends Container {
    // fields
    //-------------------------------------------------------------------------
    // scene
    private _spine: SpineAnimation;

    // init
    //-------------------------------------------------------------------------
    static assets() {
        return Object.values(manifest);
    }

    public set speed(value: number) {
        this._spine.speed(value);
    }

    constructor() {
        super();

        // spawn
        this._spawn();
    }

    // api
    //-------------------------------------------------------------------------
    public async start(opts: { loop: boolean }) {
        const { loop } = opts;
        // stop previous
        this._spine.stop();
        const mix = 0;
        await this._spine.start({ id: confettiClipId, loop, mix });
    }

    // private: scene
    //-------------------------------------------------------------------------
    private _spawn() {
        const spine = (this._spine = new SpineAnimation({ json: manifest.spine }));
        spine.scale.set(1);
        this.pivot.set(this.width / 2, this.height / 2);

        this.addChild(spine);
    }
}
