import { Sprite } from 'pixi.js';

import { uiAlignCenter, uiCreateQuad } from '../../../lib/pixi/uiTools';
import { formatResourceCount } from '../../lib/tools/appTools';
import { BasicText } from '../../lib/ui/text/BasicText';
import { AppPopup, AppPopupOptions } from './AppPopup';
import app from '../../getApp';

// types
//-----------------------------------------------------------------------------
export type AppInstallPopupPopupOptions = {
    boosters: number;
    coins: number;
} & AppPopupOptions;

const manifest = {
    panel: 'panel.native.install.png',
};

export class AppInstallPopup extends AppPopup {
    public override preload() {
        return [...super.preload(), ...app().resource.loadAssets([...Object.values(manifest)])];
    }

    // @ts-ignore
    public override async spawning(options: AppInstallPopupOptions) {
        await super.spawning({
            ...options,
        });

        const { boosters, coins } = options;

        const panel = Sprite.from(manifest.panel);

        const installText = new BasicText({
            text: '[nativePromoMessage]',
            system: true,
            style: {
                dropShadow: true,
                dropShadowAngle: Math.PI / 2,
                dropShadowAlpha: 0.6,
                dropShadowDistance: 2,
                fill: '#fff',
                strokeThickness: 4,
                align: 'center',
                fontWeight: 'bold',
                lineJoin: 'round',
                fontSize: 28,
                wordWrap: true,
                wordWrapWidth: 380,
            },
        });

        const boosterAmount = new BasicText({
            text: `x${boosters}`,
            style: {
                align: 'center',
                fontSize: 36,
                fontWeight: 'bold',
                fill: 0xfff976,
                lineJoin: 'round',
                stroke: 0x0,
                strokeThickness: 8,
            },
        });

        const coinAmount = new BasicText({
            text: `x${formatResourceCount(coins)}`,
            style: {
                align: 'center',
                fontSize: 36,
                fontWeight: 'bold',
                fill: 0xfff976,
                lineJoin: 'round',
                stroke: 0x0,
                strokeThickness: 8,
            },
        });

        const rewardView = uiCreateQuad(0x0, 0.001, 500, 100);
        rewardView.addChild(boosterAmount, coinAmount);
        const offset = 70;
        uiAlignCenter(rewardView, boosterAmount, -offset, 0);
        uiAlignCenter(rewardView, coinAmount, offset, 0);

        // spawn
        this.main.addContent({
            bg: {
                content: panel,
                styles: {
                    position: 'center',
                },
            },
            install: {
                content: installText,
                styles: {
                    position: 'right',
                    marginTop: 62,
                    marginRight: 10,
                },
            },
            rewards: {
                content: rewardView,
                styles: {
                    position: 'right',
                    marginTop: 215,
                    marginRight: -100,
                },
            },
        });
    }
}
