import { analytics } from '@play-co/gcinstant';
import app from '../../getApp';

export function trackPuzzleStart() {
    const puzzleLevel = app().server.state.puzzle.level;
    analytics.pushEvent('PuzzleStart', { puzzleLevel });
}

export function trackPuzzleFinish(opts: {
    puzzleLevel: number;
    puzzleForMaxLevel: boolean;
    result: 'success' | 'fail' | 'quit';
    puzzleDuration: number;
    remainingMoves: number;
    limitMoves: number;
    usedMetalBat: number;
    usedRunner: number;
    usedTornadoFan: number;
    usedFireball: number;
    usedRocket: number;
    usedBomb: number;
    usedBaseball: number;
}) {
    analytics.pushEvent('PuzzleFinish', opts);
}
