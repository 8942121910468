import { analytics } from '@play-co/gcinstant';
import app from '../../getApp';

export function trackTaskStart() {
    const currentPuzzleLevel = app().server.state.puzzle.lastLevel + 1;
    const tasklevel = app().server.state.task.level + 1; // 1 indexed for analytics
    const currentStars = app().server.state.stars;
    analytics.pushEvent('TaskStart', { tasklevel, currentPuzzleLevel, currentStars });
}

export function trackTaskFinish() {
    const currentPuzzleLevel = app().server.state.puzzle.lastLevel + 1;
    const tasklevel = app().server.state.task.level + 1; // 1 indexed for analytics
    const currentStars = app().server.state.stars;
    analytics.pushEvent('TaskFinish', { tasklevel, currentPuzzleLevel, currentStars });
}

export function trackTaskStep(opts: { stepIndex: number }) {
    const { stepIndex } = opts;
    const currentPuzzleLevel = app().server.state.puzzle.lastLevel + 1;
    const tasklevel = app().server.state.task.level + 1; // 1 indexed for analytics
    const currentStars = app().server.state.stars;

    const step = stepIndex + 1; // 1 indexed for analytics
    analytics.pushEvent('TaskStep', { tasklevel, currentPuzzleLevel, currentStars, step });
}
