export const config = {
    blocks: {
        basic: {
            match: 2,
            powerMatch: {
                rocket: 5,
                bomb: 7,
                cube: 9,
            },
        },
        mystery: {
            count: {
                immobile: 3,
                mobile: 2,
            },
        },
        door: {
            count: 2,
        },
        wall: {
            size: {
                min: 2,
                max: 9,
            },
            count: {
                min: 1,
                max: 99,
            },
        },
        chain: {
            damage: 3,
        },
        pond: {
            damage: 1,
        },
    },
    sim: {
        gravity: 45, // gravity of anything that falls
        bounce: 20, // block bounce height
        bounceDuration: 0, // computed below
        rocket: {
            speed: 900, // pixels/sec
        },
        chain: {
            speed: 1300, // pixels/sec
        },
        spider: {
            moveSpeed: 200, // pixels/sec
            blockPushSpeed: 400, // pixels/sec
        },
    },
    map: {
        columns: 9,
        rows: 9,
        spawnRow: -4, // where new blocks spawn
    },
    tile: {
        size: 76,
    },
    goals: {
        limit: 999,
        goalsLimit: 4,
    },
    moves: {
        min: 1,
        max: 9999,
    },
};

// cache value, no point doing a square root every bounce tween for a static value
config.sim.bounceDuration = Math.sqrt(config.sim.bounce) / config.sim.gravity;
