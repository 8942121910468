import { SB } from '@play-co/replicant';

import { State } from './replicant';

// state
//-----------------------------------------------------------------------------
export const playerState = {
    // general
    name: SB.string().default(''),
    // stars gained from puzzles and consumed in upgrades
    stars: SB.int().min(0),
    // main currency
    coins: SB.int().min(0),
    // coins and premiumCoins share the same balance, use premiumCoins first for consumption
    coinsPremium: SB.int().min(0),
};

export function getCoinBalance(state: State) {
    return state.coins + state.coinsPremium;
}
