import { Container, filters, Sprite, Ticker, WRAP_MODES } from 'pixi.js';

import { IAnimation } from '../../../../lib/pattern/IAnimation';
import { tweenPower2In } from '../../../../lib/pixi/particles/behaviors/particle_tweens';
import { ParticleEmitter } from '../../../../lib/pixi/particles/ParticleEmitter';
import { integerRandom, numberContain } from '../../../../replicant/util/mathTools';
import app from '../../../getApp';

// types
//-----------------------------------------------------------------------------
export type ComboGlowAnimationOptions = Record<string, any>;

// constants
//-----------------------------------------------------------------------------
const manifest = {
    map: 'fxmap.2.jpg',
};

/*
    combo glowy effect shown under combo candidate blocks
*/
export class ComboGlowAnimation extends Container implements IAnimation {
    // fields
    //-------------------------------------------------------------------------
    // scene
    private _glowMap: Sprite;
    private _glow: Sprite;
    private _tingles: ParticleEmitter;
    // state
    private _started: boolean;
    // handlers
    private _stepper = (dt: number) => this._stepGlow();

    // init
    //-------------------------------------------------------------------------
    static assets(): string[] {
        return [...Object.values(manifest), 'fx.combo'];
    }

    // impl
    //-------------------------------------------------------------------------
    public async start(): Promise<void> {
        // if not started
        if (!this._started) {
            // load assets
            await Promise.all(app().resource.loadAssets(ComboGlowAnimation.assets()));

            // create glow
            this._createGlow();

            // create tingles
            this._createTingles();

            // set started
            this._started = true;

            // spawn
            this.addChild(this._glowMap);
            this.addChild(this._glow);
            this.addChild(this._tingles.view);

            // start
            Ticker.system.add(this._stepper);
            void this._tingles.start();
        }
    }

    public stop() {
        // if started
        if (this._started) {
            // reset started
            this._started = false;

            // stop
            this._tingles.complete();
            Ticker.system.remove(this._stepper);

            // despawn
            this.removeChild(this._tingles.view);
            this.removeChild(this._glow);
            this.removeChild(this._glowMap);
        }
    }

    // private: stppers
    //-------------------------------------------------------------------------
    private _stepGlow() {
        const map = this._glowMap;

        // tile pan the glow displacement map
        map.position.set(numberContain(map.x + 0.2, map.width), numberContain(map.y + 0.3, map.height));
    }

    // private: scene
    //-------------------------------------------------------------------------
    private _createGlow() {
        // create glow
        const glow = (this._glow = Sprite.from('fx.combo.glow.png'));
        glow.anchor.set(0.5);
        glow.scale.set(2.1);

        // create displacement map
        const map = (this._glowMap = Sprite.from(manifest.map));
        map.texture.baseTexture.wrapMode = WRAP_MODES.REPEAT;
        map.position.set(integerRandom(0, map.width), integerRandom(0, map.height));

        // create displacement filter
        const displace = new filters.DisplacementFilter(map);
        displace.scale.set(60);

        // assign filters
        glow.filters = [displace];
    }

    private _createTingles() {
        // create emitter
        this._tingles = app().particles.create({
            textures: ['fx.combo.tingle.png'],
            rate: 0.15,
            limit: 50,
            scale: [
                { x: 1.2, y: 1.2 },
                { x: 2, y: 2 },
            ],
            duration: 5.5,
            behaviors: [
                { type: 'explode', magnitude: [11, 14] },
                { type: 'kinematic' },
                { type: 'fade', to: 0, tween: tweenPower2In },
            ],
        });
    }
}
