import { arrayIterate2 } from '../../../replicant/util/jsTools';
import app from '../../getApp';
import { BlockId } from '../../main/match2-odie/defs/block';
import { PuzzleController } from '../../main/puzzle/controller/PuzzleController';
import { PuzzleScreen } from '../../main/puzzle/PuzzleScreen';

// types
//-----------------------------------------------------------------------------
type PuzzleLevelStats = {
    level: number;
    newBlocks: BlockId[];
};

// api
//-----------------------------------------------------------------------------
export function getActivePuzzleController(): PuzzleController | undefined {
    const controller = (app().nav.screens.puzzleScreen?.instance as PuzzleScreen)?.controller;
    return controller?.active ? controller : undefined;
}

// prints puzzle level stats for debugging/info
export async function printPuzzleLevelStats() {
    const stats: PuzzleLevelStats[] = [];
    const seenBlocks: Partial<Record<BlockId, boolean>> = {};
    const limit = app().puzzleMap.highestLevel;

    // iterate each map
    for (let level = 1; level <= limit; ++level) {
        const map = await app().puzzleMap.getMap(level);
        const newBlocks: BlockId[] = [];

        // iterate map cells
        arrayIterate2(map.grid.cells, (cell) => {
            if (cell.blocks) {
                for (const block of cell.blocks) {
                    if (!seenBlocks[block.id]) {
                        newBlocks.push(block.id);
                        seenBlocks[block.id] = true;
                    }
                }
            }
        });

        // add stat entry
        stats.push({
            level,
            newBlocks,
        });
    }

    // print stats
    for (const stat of stats) {
        if (stat.newBlocks.length > 0) console.log(`Level=${stat.level} Blocks=${stat.newBlocks.toString()}`);
    }
}
