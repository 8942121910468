import { Sprite } from 'pixi.js';

import { uiSizeToFit } from '../../../lib/pixi/uiTools';
import { BasicText } from '../../lib/ui/text/BasicText';
import { BasicPopup, BasicPopupOptions } from './BasicPopup';
import app from '../../getApp';

// types
//-----------------------------------------------------------------------------
export type PurchaseInterruptPopupOptions = BasicPopupOptions & {
    type: 'cancel' | 'error';
};

const manifest = {
    alertYellow: 'icon.alert.yellow.png',
    alertRed: 'icon.alert.red.png',
};

const renderMap = {
    cancel: {
        title: '[popupIapCancelTitle]',
        text: '[popupIapCancelText]',
        icon: manifest.alertYellow,
    },
    error: {
        title: '[popupIapErrorTitle]',
        text: '[popupIapErrorText]',
        icon: manifest.alertRed,
    },
};

/*
    iap cancel/error popup
*/
export class PurchaseInterruptPopup extends BasicPopup {
    // impl
    //-------------------------------------------------------------------------
    public override preload() {
        return [...super.preload(), ...app().resource.loadAssets([...Object.values(manifest)])];
    }

    // @ts-ignore
    public override async spawning(options: PurchaseInterruptPopupOptions) {
        const { type } = options;
        void super.spawning({
            header: renderMap[type].title,
            width: 700,
            height: 660,
            okButton: '[buttonContinue]',
            ...options,
        });

        const alert = Sprite.from(renderMap[type].icon);

        const text = new BasicText({
            text: renderMap[type].text,
            style: {
                fill: '#3E4047',
                align: 'center',
                fontWeight: 'bold',
                fontSize: 36,
                lineJoin: 'round',
                wordWrap: true,
                // this is just to support wrap for the long error casesm where we resize the
                // label to the height later
                wordWrapWidth: this.main.width + 30,
                // this removes weird random line spacing for japanese texts
                whiteSpace: 'normal',
            },
        });

        text.pivot.y = text.height / 2;
        uiSizeToFit(text, this.main.width - 50, 110);

        // spawn
        this.main.addContent({
            alert: {
                content: alert,
                styles: {
                    position: 'topCenter',
                    marginTop: 180,
                },
            },
            sometext: {
                content: text,
                styles: {
                    position: 'topCenter',
                    marginTop: 425,
                },
            },
        });
    }
}
