import { analytics, GCInstant, PinchConversionAction } from '@play-co/gcinstant';
import app from '../../getApp';

export type ShopPinchRestore = 'moves' | 'lives';

export type PinchResource = 'moves' | 'lives' | 'coins' | 'bombs' | 'rockets' | 'baseballs';
export type PinchEventTrigger =
    | 'puzzle_complete'
    | 'puzzle_start'
    | 'purchase_lives'
    | 'purchase_moves'
    | 'purchase_bombs'
    | 'purchase_rockets'
    | 'purchase_baseballs';
export type PinchEventSubTrigger = string;

export function trackPinchEvent(options: {
    resource: PinchResource;
    trigger: PinchEventTrigger;
    subTrigger?: PinchEventSubTrigger;
}) {
    const resourcePinchCounts = GCInstant.storage.trackPinchAllTimeCounts[options.resource] || 0;
    GCInstant.trackPinch({ ...options, first: resourcePinchCounts === 0 });
}

export function trackPinchConversion(options: {
    resource: PinchResource;
    action: PinchConversionAction;
    feature: string;
    subFeature: string;
    wasSurfacedByPinch: boolean;
    trigger?: string;
}) {
    app().analytics.sessionPinchTriggered = true;
    GCInstant.trackPinchConversion({ ...options });
}

export function trackImplicitPinchConversion(options: {
    resource: PinchResource;
    action: PinchConversionAction;
    feature: string;
    subFeature: string;
}) {
    app().analytics.sessionPinchTriggered = true;
    analytics.pushEvent('ImplicitPinchConversion', options);
}

export function trackRefillLivesPinchConversion(shopPinch: boolean) {
    trackPinchConversion({
        action: 'in_game',
        resource: 'lives',
        feature: `puzzle`,
        subFeature: `puzzle_start`,
        trigger: `puzzle_start`,
        wasSurfacedByPinch: true,
    });
    if (shopPinch) {
        // pinched first and then bought coins and consumed them -> conversion
        trackPinchConversion({
            action: 'purchase',
            resource: 'coins',
            feature: 'lives',
            subFeature: `purchase_lives`,
            trigger: `purchase_lives`,
            wasSurfacedByPinch: true,
        });
    }
}

export function trackNoMovesPinchConversion(shopPinch: boolean) {
    trackPinchConversion({
        action: 'in_game',
        resource: 'moves',
        feature: `puzzle`,
        subFeature: 'puzzle_complete',
        trigger: 'puzzle_complete',
        wasSurfacedByPinch: true,
    });

    if (shopPinch) {
        trackPinchConversion({
            action: 'purchase',
            resource: 'coins',
            feature: `continue`,
            subFeature: 'purchase_moves',
            trigger: 'purchase_moves',
            wasSurfacedByPinch: true,
        });
    }
}
