import { Sprite } from 'pixi.js';

import { uiAlignBottom, uiAlignCenter, uiAlignRight } from '../../../lib/pixi/uiTools';
import { BoosterId, boosterMap } from '../../../replicant/defs/booster';
import { ImageButton } from '../../lib/ui/buttons/ImageButton';
import { BasicText } from '../../lib/ui/text/BasicText';

// types
//-----------------------------------------------------------------------------
export type PuzzleBoosterButtonOptions = {
    id: BoosterId;
};

// constants
//-----------------------------------------------------------------------------
const manifest = {
    button: 'button.green.large.png',
    lock: 'icon.lock.png',
};

/*
    puzzle footer booster button
*/
export class PuzzleBoosterButton extends ImageButton {
    // fields
    //-------------------------------------------------------------------------
    // state
    private _count = 0;
    private _locked = false;
    // scene
    private _counter: BasicText;
    private _lock: Sprite;

    // properties
    //-------------------------------------------------------------------------
    // count
    public set count(count: number) {
        this._count = count;
        this._update();
    }

    // count
    public set locked(enabled: boolean) {
        this._locked = enabled;
        this._update();
    }

    // init
    //-------------------------------------------------------------------------
    constructor(options: PuzzleBoosterButtonOptions) {
        // spawn background
        super({
            image: manifest.button,
            slice: {
                width: 134,
                height: 134,
                left: 21,
                right: 21,
            },
        });

        // spawn icon
        this._spawnIcon(options.id);

        // spawn count
        this._spawnCount();

        // spawn lock
        this._spawnLock();

        // initial update
        this._update();
    }

    static assets(options: Partial<PuzzleBoosterButtonOptions>): string[] {
        return [...Object.values(manifest), boosterMap[options.id].asset];
    }

    // private: updates
    //-------------------------------------------------------------------------
    private _update() {
        const locked = this._locked;
        const count = this._count;
        const counter = this._counter;
        const lock = this._lock;

        // update visibility
        lock.visible = locked;
        counter.visible = !locked;
        this.enabled = !locked;

        // update counter
        if (!locked) {
            counter.text = count > 0 ? count.toString() : '+';
            uiAlignRight(this, counter, -12);
        }
    }

    // private: scene
    //-------------------------------------------------------------------------
    public _spawnIcon(id: BoosterId) {
        const icon = this.button.addChild(Sprite.from(boosterMap[id].asset));
        uiAlignCenter(this.button, icon, 0, -12);
    }

    public _spawnCount() {
        const counter = (this._counter = this.button.addChild(
            new BasicText({
                style: {
                    fill: 'white',
                    fontSize: 38,
                    fontWeight: 'bold',
                    strokeThickness: 5,
                    padding: 4,
                    lineJoin: 'round',
                },
            }),
        ));
        uiAlignBottom(this, counter, -13);
    }

    public _spawnLock() {
        const lock = (this._lock = this.addChild(Sprite.from(manifest.lock)));
        uiAlignRight(this, lock);
        uiAlignBottom(this, lock);
    }
}
