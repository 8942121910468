import { Sprite } from 'pixi.js';

import { uiAlignCenterY } from '../../../lib/pixi/uiTools';
import StatsView from './StatsView';
import app from '../../getApp';

const manifest = {
    icon: 'icon.star.png',
};

export default class MiniStarView extends StatsView {
    public get icon() {
        return this._icon;
    }

    constructor() {
        super({ startAmount: app().game.player.stars });
        this._icon = Sprite.from(manifest.icon);
        this._icon.pivot.set(this._icon.width / 2, this._icon.height / 2);
        this._icon.scale.set(0.88);
        this._icon.x = this._icon.width * 0.5 + 8;
        uiAlignCenterY(this, this._icon, -3);
        this.addChild(this._icon);

        this._observer.listen(
            () => app().game.player.stars,
            (stats) => {
                this._updateAmount(stats);
            },
        );
        this._observer.start();
    }

    public static override assets(): string[] {
        return [...StatsView.assets(), ...Object.values(manifest)];
    }
}
