import { PlayerClipId } from '../../app/concept/BaseballPlayer';

export type Dialog = {
    speechSource: 'player' | 'teamMateGuy' | 'teamMateGirl' | 'narrator';
    dialog?: string; // default dialog
    choiceDialogs?: string[]; // dialog depending on choice, list of choice indexes
    extraChoice?: string[];
    clip?: PlayerClipId;
};

// index is task level
const taskIntroDialogs: Dialog[][] = [
    // ------- task 1 dugout ------- (part of task tutorial with different flow)
    null,
    // ------- task 2 trainingEquipment -------
    [
        { speechSource: 'teamMateGuy', dialog: '[taskIntroTrainingEquipment0]' },
        {
            speechSource: 'player',
            dialog: '[taskIntroTrainingEquipment1]',
            extraChoice: ['[taskIntroTrainingEquipmentButton]'],
        },
        {
            speechSource: 'teamMateGuy',
            dialog: '[taskIntroTrainingEquipment2]',
        },
        {
            speechSource: 'player',
            dialog: '[taskIntroTrainingEquipment3]',
        },
    ],
    // ------- task 3 teamGear -------
    [
        { speechSource: 'narrator', dialog: '[taskIntroTeamGear0]' },
        { speechSource: 'narrator', dialog: '[taskIntroTeamGear1]' },
        { speechSource: 'narrator', dialog: '[taskIntroTeamGear2]' },
    ],
    // ------- task 4 fieldEquipment -------
    [
        { speechSource: 'narrator', dialog: '[taskIntroFieldEquipment0]' },
        { speechSource: 'player', dialog: '[taskIntroFieldEquipment1]' },
    ],
    // ------- task 5 muscleTraining -------
    [
        { speechSource: 'player', dialog: '[taskIntroMuscleTraining0]' },
        { speechSource: 'player', dialog: '[taskIntroMuscleTraining1]' },
        { speechSource: 'teamMateGuy', dialog: '[taskIntroMuscleTraining2]' },
    ],
    // ------- task 6 trainingCamp -------
    [
        { speechSource: 'teamMateGuy', dialog: '[taskIntroTrainingCamp0]', clip: 'nervous' },
        { speechSource: 'player', dialog: '[taskIntroTrainingCamp1]', clip: 'nervous' },
        { speechSource: 'teamMateGirl', dialog: '[taskIntroTrainingCamp2]', clip: 'nervous' },
        { speechSource: 'narrator', dialog: '[taskIntroTrainingCamp3]', clip: 'nervous' },
    ],
    // ------- task 7 cheerSquad -------
    [
        { speechSource: 'narrator', dialog: '[taskIntroCheerSquad0]' },
        { speechSource: 'player', dialog: '[taskIntroCheerSquad1]', clip: 'celebrate_nojump' },
        { speechSource: 'teamMateGuy', dialog: '[taskIntroCheerSquad2]', clip: 'celebrate_nojump' },
        { speechSource: 'teamMateGirl', dialog: '[taskIntroCheerSquad3]', clip: 'celebrate_nojump' },
    ],
    // ------- task 8 exams -------
    [
        { speechSource: 'narrator', dialog: '[taskIntroExams0]' },
        { speechSource: 'player', dialog: '[taskIntroExams1]', clip: 'nervous' },
        { speechSource: 'teamMateGirl', dialog: '[taskIntroExams2]', clip: 'nervous' },
        { speechSource: 'teamMateGuy', dialog: '[taskIntroExams3]', clip: 'nervous' },
    ],
    // ------- task 9 analyzeGame -------
    [
        { speechSource: 'teamMateGuy', dialog: '[taskIntroAnalyzeGame0]' },
        { speechSource: 'player', dialog: '[taskIntroAnalyzeGame1]' },
        { speechSource: 'teamMateGirl', dialog: '[taskIntroAnalyzeGame2]' },
        { speechSource: 'narrator', dialog: '[taskIntroAnalyzeGame3]' },
    ],
    // ------- task 10 bbqParty -------
    [
        { speechSource: 'teamMateGirl', dialog: '[taskIntroBbqParty0]' },
        { speechSource: 'teamMateGuy', dialog: '[taskIntroBbqParty1]' },
        { speechSource: 'player', dialog: '[taskIntroBbqParty2]' },
        { speechSource: 'narrator', dialog: '[taskIntroBbqParty3]' },
    ],
    // ------- task 11 designUniform -------
    [
        { speechSource: 'narrator', dialog: '[taskIntroDesignUniform0]' },
        { speechSource: 'teamMateGirl', dialog: '[taskIntroDesignUniform1]', clip: 'nervous' },
        { speechSource: 'narrator', dialog: '[taskIntroDesignUniform2]', clip: 'nervous' },
        { speechSource: 'narrator', dialog: '[taskIntroDesignUniform3]', clip: 'nervous' },
    ],
    // ------ task 12 workoutEquipment ------
    [
        { speechSource: 'teamMateGuy', dialog: '[taskIntroWorkoutEquipment0]' },
        { speechSource: 'player', dialog: '[taskIntroWorkoutEquipment1]' },
        { speechSource: 'narrator', dialog: '[taskIntroWorkoutEquipment2]' },
        { speechSource: 'teamMateGirl', dialog: '[taskIntroWorkoutEquipment3]' },
    ],
    // ------- task 13 managersJob -------
    [
        { speechSource: 'narrator', dialog: '[taskIntroManagersJob0]' },
        { speechSource: 'teamMateGirl', dialog: '[taskIntroManagersJob1]', clip: 'nervous' },
        { speechSource: 'narrator', dialog: '[taskIntroManagersJob2]', clip: 'nervous' },
        { speechSource: 'teamMateGirl', dialog: '[taskIntroManagersJob3]', clip: 'nervous' },
    ],
    // ------- task 14 schoolFest -------
    [
        { speechSource: 'teamMateGuy', dialog: '[taskIntroSchoolFest0]' },
        { speechSource: 'player', dialog: '[taskIntroSchoolFest1]' },
        { speechSource: 'teamMateGirl', dialog: '[taskIntroSchoolFest2]' },
        { speechSource: 'teamMateGuy', dialog: '[taskIntroSchoolFest3]' },
    ],
    // ------- task 15 expedition -------
    [
        { speechSource: 'narrator', dialog: '[taskIntroExpedition0]' },
        { speechSource: 'narrator', dialog: '[taskIntroExpedition1]' },
    ],
];

const taskEndingDialogs: Dialog[][] = [
    // ------- task 1 dugout ------- (part of task tutorial with different flow)
    [{ speechSource: 'player', dialog: '[taskEndingDugout0]' }],
    // ------- task 2 trainingEquipment -------
    [{ speechSource: 'teamMateGuy', dialog: '[taskEndingTrainingEquipment0]' }],
    // ------- task 3 teamGear -------
    [{ speechSource: 'narrator', dialog: '[taskEndingTeamGear0]' }],
    // ------- task 4 fieldEquipment -------
    [{ speechSource: 'narrator', dialog: '[taskEndingFieldEquipment0]', clip: 'celebrate' }],
    // ------- task 5 muscleTraining -------
    [{ speechSource: 'narrator', dialog: '[taskEndingMuscleTraining0]', clip: 'baseman_tired' }],
    // ------- task 6 trainingCamp -------
    [{ speechSource: 'narrator', dialog: '[taskEndingTrainingCamp0]', clip: 'baseman_tired' }],
    // ------- task 7 cheerSquad -------
    [{ speechSource: 'narrator', dialog: '[taskEndingCheerSquad0]' }],
    // ------- task 8 exams -------
    [{ speechSource: 'narrator', dialog: '[taskEndingExams0]', clip: 'celebrate_nojump' }],
    // ------- task 9 analyzeGame -------
    [{ speechSource: 'narrator', dialog: '[taskEndingAnalyzeGame0]' }],
    // ------- task 10 bbqParty -------
    [{ speechSource: 'narrator', dialog: '[taskEndingBbqParty0]' }],
    // ------- task 11 designUniform -------
    [{ speechSource: 'player', dialog: '[taskEndingDesignUniform0]', clip: 'celebrate_nojump' }],
    // ------- task 12 workoutEquipment -------
    [{ speechSource: 'teamMateGuy', dialog: '[taskEndingWorkoutEquipment0]' }],
    // ------- task 13 managersJob -------
    [{ speechSource: 'player', dialog: '[taskEndingManagersJob0]' }],
    // ------- task 14 schoolFest -------
    [{ speechSource: 'player', dialog: '[taskEndingSchoolFest0]' }],
    // ------- task 15 expedition -------
    [{ speechSource: 'player', dialog: '[taskEndingExpedition0]' }],
];

export default {
    taskIntroDialogs,
    taskEndingDialogs,
};
