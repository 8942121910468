import { analytics } from '@play-co/gcinstant';

import { IFlow } from '../../lib/pattern/IFlow';
import NakedPromise from '../../lib/pattern/NakedPromise';
import { getAvailableGameDayAB } from '../../replicant/components/gameDay';
import { isInfiniteLivesActive, livesGet } from '../../replicant/components/lives';
import { PuzzleCompleteResults } from '../../replicant/components/puzzle';
import { shouldStartTaskIntroTutorial } from '../../replicant/components/task';
import gameConfig from '../../replicant/defs/gameConfig';
import { getContinueCost } from '../../replicant/defs/product';
import { NavLayer } from '../defs/nav';
import { ShopPinchRestore, trackNoMovesPinchConversion, trackPinchEvent } from '../lib/analytics/pinch';
import { trackPuzzleFinish } from '../lib/analytics/puzzle';
import { HomeScreen } from '../main/home/HomeScreen';
import { PuzzleController, PuzzleResults, RewardState } from '../main/puzzle/controller/PuzzleController';
import { GameDayMainFlow } from './gameday/GameDayMainFlow';
import { HomeSequenceFlow } from './HomeSequenceFlow';
import { PuzzlePlayFlow } from './PuzzlePlayFlow';
import { TutorialPuzzleFlow } from './tutorials/TutorialPuzzleFlow';
import { TutorialTaskIntroFlow } from './tutorials/TutorialTaskIntroFlow';
import app from '../getApp';

/*
    puzzle complete command
*/
export class PuzzleCompleteFlow implements IFlow {
    // fields
    //-------------------------------------------------------------------------
    // input
    private readonly _controller: PuzzleController;
    private readonly _results: PuzzleResults;

    // init
    //-------------------------------------------------------------------------
    constructor(results: PuzzleResults, puzzleController: PuzzleController) {
        this._results = results;
        this._controller = puzzleController;
    }

    // impl
    //-------------------------------------------------------------------------
    async execute() {
        const result = this._results.result;

        // if quit
        if (result === 'quit') {
            const exitPuzzle = await this._actionQuit();
            if (exitPuzzle) {
                // track exit and go home, do nothing if cancelled
                this._trackComplete();
                await this._actionHome();
            }
            // if win
        } else if (result === 'success') {
            this._trackComplete();
            void this._actionSuccess(this._results.rewards);
            // else lose (out of moves)
        } else {
            // no moves
            const resumeGame = await this._actionNoMoves();

            if (resumeGame) {
                this._controller.grantContinue();
                return;
            }

            this._trackComplete();
            void this._actionFail();
        }
    }

    // private: actions
    //-------------------------------------------------------------------------
    private async _actionQuit() {
        const exitPromise = new NakedPromise<boolean>();
        void app().nav.open('confirmPopup', {
            onYes: () => exitPromise.resolve(true),
            onNo: () => exitPromise.resolve(false),
            underlay: 0.6,
        });
        const exit = await exitPromise;
        void app().nav.close('confirmPopup');
        return exit;
    }

    private async _actionFail() {
        const levelFailedPopupId = 'levelFailedPopup';

        // play lose sound
        void app().sound.play('disappoint.ogg', { rate: 0.9, volume: 0.5 });

        const now = app().server.now();
        const livesAvailable = livesGet(app().server.state, now);
        if (livesAvailable > 0 || isInfiniteLivesActive(app().server.state, now)) {
            const tryAgainPromise = new NakedPromise<boolean>();
            void app().nav.open(levelFailedPopupId, {
                onOk: () => tryAgainPromise.resolve(true),
                onClose: () => tryAgainPromise.resolve(false),
                underlay: 0.6,
                level: this._controller.mapLevel,
            });
            const tryAgain = await tryAgainPromise;
            void app().nav.close(levelFailedPopupId);

            if (tryAgain) {
                const { forceScene } = await this._actionNewGame();
                if (forceScene) {
                    const sceneMap = {
                        homeScreen: () => this._actionHome(),
                    };
                    void sceneMap[forceScene]();
                }
                // special RETURN, we're either in a puzzle or already at the forced sceeen (home)
                return;
            }
        } else {
            const tryAgainPromise = new NakedPromise<boolean>();
            void app().nav.open(levelFailedPopupId, {
                // only close button if not enough lives
                onClose: () => tryAgainPromise.resolve(false),
                underlay: 0.6,
                level: this._controller.mapLevel,
            });
            await tryAgainPromise;
            void app().nav.close(levelFailedPopupId);
        }

        void this._actionHome();
    }

    private async _actionHome(results?: PuzzleCompleteResults) {
        // force scripted flow in homescreen if they did not complete the first level (cancel/fail)
        const state = app().server.state;
        if (state.puzzle.lastLevel === 0) {
            await new TutorialPuzzleFlow().execute();
            return;
        }

        const starHandler = async (openedScreen: HomeScreen) => {
            if (results?.stars) {
                await openedScreen.starIncreaseAnimation();
                // allow true state update once animation is finished
                openedScreen.starView.resumeUpdateAmount();
            }
        };
        // close popups
        await app().nav.closeLayer(NavLayer.popup);
        const forcedStarAmount = results?.stars ? Math.max(state.stars - results.stars, 0) : undefined;
        const overrideMoraleScore = results?.moraleScore ? results.moraleScore.before : undefined;

        let homeScreen: HomeScreen;
        // go home or start task tutorial from homescreen if needed
        if (shouldStartTaskIntroTutorial(state, app().server.now())) {
            homeScreen = await new TutorialTaskIntroFlow({ overrideMoraleScore, starHandler }).execute();
        } else {
            // run through a game day if available
            const { index } = getAvailableGameDayAB(state, app().server.now());
            if (index !== -1) {
                await new GameDayMainFlow().execute();
            }

            homeScreen = (await app().nav.open('homeScreen', {
                forcedStarAmount,
                overrideMoraleScore,
            })) as HomeScreen;
            void starHandler(homeScreen);
            if (results?.moraleScore) {
                homeScreen.teamMoraleView.resumeMoraleUpdate();
            }
        }

        await new HomeSequenceFlow({ homeScreen }).execute();
    }

    private async _actionNoMoves(): Promise<boolean> {
        trackPinchEvent({ resource: 'moves', trigger: 'puzzle_complete' });

        const _noMovesFlow = async (popupId: string) => {
            let resume = false;
            let reOpenCoinConsumePopup = true;
            while (reOpenCoinConsumePopup) {
                const triggerPurchase = new NakedPromise<{ purchase: boolean; reOpen: boolean }>();
                await app().nav.open(popupId, {
                    onConfirm: () => triggerPurchase.resolve({ purchase: true, reOpen: false }),
                    onClose: () => triggerPurchase.resolve({ purchase: false, reOpen: false }),
                    onCoins: async () => {
                        void app().nav.close(popupId);
                        await this._openShop();
                        triggerPurchase.resolve({ purchase: false, reOpen: true });
                    },
                    underlay: 0.6,
                });

                void app().nav.preload('shop');
                const { purchase, reOpen } = await triggerPurchase;

                reOpenCoinConsumePopup = reOpen;
                void app().nav.close(popupId);
                if (purchase) {
                    const state = app().server.state;
                    if (app().game.player.coins >= getContinueCost(state)) {
                        void this._consumeMoves();
                        resume = true;
                    } else {
                        trackPinchEvent({ resource: 'coins', trigger: 'purchase_moves' });
                        void app().nav.close(popupId);
                        await this._openShop('moves');
                        if (app().game.player.coins >= getContinueCost(state)) {
                            void this._consumeMoves(true);
                            // return now to resume game after
                            reOpenCoinConsumePopup = false;
                            resume = true;
                        } else {
                            // re-open, they did not buy or get enough coins
                            reOpenCoinConsumePopup = true;
                        }
                    }
                }
            }
            return resume;
        };

        let resumeGame = await _noMovesFlow('noMovesPopup');
        if (!resumeGame) {
            // confirm
            resumeGame = await _noMovesFlow('noMovesConfirmPopup');
        }
        return resumeGame;
    }

    private async _consumeMoves(shopPinch = false) {
        await app().server.invoke.coinsPurchase({ id: 'moves' });
        trackNoMovesPinchConversion(shopPinch);
    }

    private async _actionNewGame() {
        // close popups and and puzzle
        await app().nav.closeLayer(NavLayer.popup);

        // execute puzzle play command
        return new PuzzlePlayFlow({}).execute();
    }

    private async _actionSuccess(rewards: RewardState) {
        // backend complete current puzzle
        const results = await app().server.invoke.puzzleComplete({
            playerMoves: this._controller.moves,
            mapMoves: this._controller.map.moves,
        });

        analytics.setUserProperties({ realtimeLastPuzzleLevel: results.completedLevel });

        // play victory sound
        void app().sound.play('victory.ogg');

        // play star reward animation in success popup if stars are granted
        const successPopupId = results.stars ? 'levelSuccessPopup' : 'levelSuccessMaxPopup';

        const okPromise = new NakedPromise();
        void app().nav.open(successPopupId, {
            onOk: () => okPromise.resolve(),
            level: results.completedLevel,
            underlay: 0.6,
        });
        await okPromise;
        await app().nav.closeLayer(NavLayer.popup);

        void this._actionHome(results);
    }

    private _trackComplete() {
        trackPuzzleFinish({
            puzzleLevel: this._controller.mapLevel,
            puzzleForMaxLevel: app().server.state.puzzle.lastLevel >= gameConfig.puzzle.levels.max,
            result: this._results.result,
            puzzleDuration: (app().server.now() - this._controller.time) / 1000,
            remainingMoves: this._controller.moves,
            limitMoves: this._controller.map.moves,
            usedMetalBat: this._controller.usedBoosters.dart,
            usedRunner: this._controller.usedBoosters.bullet,
            usedTornadoFan: this._controller.usedBoosters.roulette,
            usedFireball: this._controller.usedBoosters.drill,
            usedBaseball: this._controller.usedPowerBoosterCounts.cube,
            usedBomb: this._controller.usedPowerBoosterCounts.bomb,
            usedRocket: this._controller.usedPowerBoosterCounts.rocket,
        });
    }

    private async _openShop(shopPinch?: ShopPinchRestore) {
        const closePromise = new NakedPromise();
        void app().nav.open('shop', { shopPinch, onClose: closePromise.resolve });
        await closePromise;
    }
}
