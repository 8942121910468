import { NineSlicePlane, Sprite, Texture } from 'pixi.js';

import { pixiSetInterval } from '../../../lib/pixi/pixiTools';
import { uiAlignCenter, uiSizeToFit } from '../../../lib/pixi/uiTools';
import { timeFormatCountdown, timeToComponents } from '../../../replicant/util/timeTools';
import { BasicText } from '../../lib/ui/text/BasicText';
import { BasicPopup, BasicPopupOptions } from './BasicPopup';
import app from '../../getApp';

const HEIGHT = 670;

// types
//-----------------------------------------------------------------------------
export type OutOfLivesPopupOptions = {
    lifeTimeLeft: () => number;
    onOk: () => void;
} & BasicPopupOptions;

const manifest = {
    life: 'icon.life.large.png',
    inset: 'frame.inset.light.png',
    nameplate: 'panel.nameplate.png',
};

/*
    Out of lives popup
*/
export class OutOfLivesPopup extends BasicPopup {
    private _timer: BasicText;
    private _lifeTimeLeft: () => number;

    public override preload() {
        return [...super.preload(), ...app().resource.loadAssets([...Object.values(manifest)])];
    }

    // @ts-ignore
    public override async spawning(options: OutOfLivesPopupOptions) {
        void super.spawning({
            onClose: options.onOk,
            ...options,
            header: '[popupNoLivesTitle]',
            width: 690,
            height: HEIGHT,
        });

        this._lifeTimeLeft = options.lifeTimeLeft;

        const inset = new NineSlicePlane(Texture.from(manifest.inset), 28, 28, 28, 28);
        inset.width = 476;
        inset.height = 247;

        const heart = Sprite.from(manifest.life);

        inset.addChild(heart);
        uiAlignCenter(inset, heart, 0, -6);

        const zero = new BasicText({
            text: '0',
            style: {
                fill: 0xffffff,
                stroke: 0x05b001c,
                strokeThickness: 2,
                fontSize: 80,
                fontWeight: 'bold',
            },
        });

        heart.addChild(zero);
        uiAlignCenter(heart, zero, 0, 0);

        const timer = this._createTimer();

        // spawn
        this.main.addContent({
            inset: {
                content: inset,
                styles: {
                    position: 'topCenter',
                    marginTop: 180,
                },
            },
            timer: {
                content: timer,
                styles: {
                    position: 'topCenter',
                    marginTop: 390,
                },
            },
        });
    }

    private _createTimer() {
        const nameplate = new NineSlicePlane(Texture.from(manifest.nameplate), 28, 28, 28, 28);
        nameplate.width = 338;
        nameplate.height = 56;

        this._timer = new BasicText({
            text: ``,
            style: {
                fill: '#3E4047',
                align: 'center',
                fontWeight: 'bold',
                fontSize: 28,
                lineJoin: 'round',
            },
        });

        nameplate.addChild(this._timer);

        const updateTimer = () => {
            const remaining = this._lifeTimeLeft();
            const parsed = timeFormatCountdown(timeToComponents(remaining > 0 ? remaining : 0));
            this._timer.text = `[popupNoLivesNext|${parsed}]`;
            uiSizeToFit(this._timer, 300, 60);
            uiAlignCenter(nameplate, this._timer, 0, 0);
        };

        updateTimer();
        pixiSetInterval(nameplate, updateTimer, 1);

        return nameplate;
    }
}
