import { Assets, Container } from 'pixi.js';

import { PositionType } from '../../../../lib/defs/types';
import { IAnimation } from '../../../../lib/pattern/IAnimation';
import { ImageListAnimation } from '../../../../lib/pixi/animation/ImageListAnimation';
import { ParticleEmitter } from '../../../../lib/pixi/particles/ParticleEmitter';
import { tween } from '../../../../lib/util/tweens';
import { pixiConfig } from '../../../defs/config';
import { mapFromGlobalPosition } from '../util/mapTools';
import app from '../../../getApp';

// types
//-----------------------------------------------------------------------------
export type BulletAnimationOptions = {
    position: PositionType;
};

// constants
//-----------------------------------------------------------------------------
const manifest = {
    frame0: 'fx.bullet.0.png',
    frame1: 'fx.bullet.1.png',
    rocket: 'fx.rocket',
};

/*
    bullet booster animation
*/
export class BulletAnimation extends Container implements IAnimation {
    // fields
    //-------------------------------------------------------------------------
    // events
    public onMove: (column: number) => void;
    // input
    private _position: PositionType;

    // init
    //-------------------------------------------------------------------------
    constructor(options: BulletAnimationOptions) {
        super();
        this._position = options.position;
    }

    // init
    //-------------------------------------------------------------------------
    static assets(): string[] {
        return Object.values(manifest);
    }

    // impl
    //-------------------------------------------------------------------------
    public async start(): Promise<void> {
        // load assets
        await Assets.load(BulletAnimation.assets());

        // play sound
        void app().sound.play('puzzle-bullet.mp3');

        // create guy
        const guy = this._spawnGuy();

        // create dust trail
        const dust = this._createDustEmitter();
        guy.addChild(dust.view);

        // animate guy and dust
        guy.play();
        void dust.start();

        // move guy
        await guy.animate().add(guy, { x: this._position.x + pixiConfig.size.width + 100 }, 1.6, (t: number) => {
            const position = mapFromGlobalPosition(guy);
            this.onMove?.(position.x);
            return tween.linear(t);
        });

        // stop dust and animate guy
        dust.stop();
        guy.stop();

        // despawn
        this.removeChild(dust.view);
        this.removeChild(guy);
    }

    public stop() {}

    // private: scene
    //-------------------------------------------------------------------------
    private _spawnGuy(): ImageListAnimation {
        // create animation
        const guy = new ImageListAnimation([manifest.frame0, manifest.frame1]);
        this.addChild(guy);

        // position clip
        guy.x = -100;
        guy.y = this._position.y;
        guy.anchor.set(0.5, 0.8);

        // animation props
        guy.loop = true;
        guy.frameDelay = 0.15;

        return guy;
    }

    private _createDustEmitter(): ParticleEmitter {
        // create
        const emitter = app().particles.create({
            textures: ['fx.rocket.smoke1.png', 'fx.rocket.smoke2.png', 'fx.rocket.smoke3.png', 'fx.rocket.smoke4.png'],
            rate: 0.04,
            limit: 64,
            duration: 0.9,
            velocity: { x: -200, y: 0 },
            scale: [
                { x: 0.5, y: 0.5 },
                { x: 1, y: 1 },
            ],
            //tint: 0xff53d2,
            behaviors: [
                { type: 'explode', magnitude: [180, 90] },
                { type: 'scale', to: 0 },
                { type: 'gravity', gravity: { x: 0, y: -550 } },
                //{ type: 'tint', from: 0xff53d2, to: 0xffffff },
                { type: 'kinematic' },
            ],
        });
        emitter.view.position.set(-70, 20);
        return emitter;
    }
}
