import { TipProps } from '../services/TipService';

// types
//-----------------------------------------------------------------------------
export type TipId =
    | 'puzzleAndroid'
    | 'puzzleAnimal'
    | 'puzzleCage'
    | 'puzzleCandle'
    | 'puzzleChain'
    | 'puzzleChameleon'
    | 'puzzleColorDoor'
    | 'puzzleColorHat'
    | 'puzzleDog'
    | 'puzzleDoor'
    | 'puzzleFirebox'
    | 'puzzleFugu'
    | 'puzzleHazard'
    | 'puzzleHat'
    | 'puzzleIce'
    | 'puzzleKappa'
    | 'puzzleKettle'
    | 'puzzleLock'
    | 'puzzlePanther'
    | 'puzzlePipe'
    | 'puzzlePond'
    | 'puzzlePrison'
    | 'puzzleSiren'
    | 'puzzleShell'
    | 'puzzleSkull'
    | 'puzzleSlime'
    | 'puzzleStatue'
    | 'puzzleThrower'
    | 'puzzleWall';

// tables
//-----------------------------------------------------------------------------
export const tipMap: Record<TipId, TipProps> = {
    puzzleAnimal: {
        style: 'target',
        text: '[tipPuzzleAnimal]',
        type: 'arrow',
        width: 500,
    },
    puzzleAndroid: {
        style: 'target',
        text: '[tipPuzzleAndroid]',
        type: 'arrow',
        width: 500,
    },
    puzzleCage: {
        style: 'target',
        text: '[tipPuzzleCage]',
        type: 'arrow',
        width: 500,
    },
    puzzleCandle: {
        style: 'target',
        text: '[tipPuzzleCandle]',
        type: 'arrow',
        width: 500,
    },
    puzzleChain: {
        style: 'target',
        text: '[tipPuzzleChain]',
        type: 'arrow',
        width: 500,
    },
    puzzleChameleon: {
        style: 'target',
        text: '[tipPuzzleChameleon]',
        type: 'arrow',
        width: 500,
    },
    puzzleColorDoor: {
        style: 'target',
        text: '[tipPuzzleColorDoor]',
        type: 'arrow',
        width: 500,
    },
    puzzleColorHat: {
        style: 'target',
        text: '[tipPuzzleColorHat]',
        type: 'arrow',
        width: 500,
    },
    puzzleDog: {
        style: 'target',
        text: '[tipPuzzleDog]',
        type: 'arrow',
        width: 500,
    },
    puzzleDoor: {
        style: 'target',
        text: '[tipPuzzleDoor]',
        type: 'arrow',
        width: 500,
    },
    puzzleFirebox: {
        style: 'target',
        text: '[tipPuzzleFirebox]',
        type: 'arrow',
        width: 500,
    },
    puzzleFugu: {
        style: 'target',
        text: '[tipPuzzleFugu]',
        type: 'arrow',
        width: 500,
    },
    puzzleHazard: {
        style: 'target',
        text: '[tipPuzzleHazard]',
        type: 'arrow',
        width: 500,
    },
    puzzleHat: {
        style: 'target',
        text: '[tipPuzzleHats]',
        type: 'arrow',
        width: 500,
    },
    puzzleIce: {
        style: 'target',
        text: '[tipPuzzleIce]',
        type: 'arrow',
        width: 500,
    },
    puzzleKappa: {
        style: 'target',
        text: '[tipPuzzleKappa]',
        type: 'arrow',
        width: 500,
    },
    puzzleKettle: {
        style: 'target',
        text: '[tipPuzzleKettle]',
        type: 'arrow',
        width: 500,
    },
    puzzleLock: {
        style: 'target',
        text: '[tipPuzzleLock]',
        type: 'arrow',
        width: 500,
    },
    puzzlePanther: {
        style: 'target',
        text: '[tipPuzzlePanther]',
        type: 'arrow',
        width: 500,
    },
    puzzlePipe: {
        style: 'target',
        text: '[tipPuzzlePipe]',
        type: 'arrow',
        width: 500,
    },
    puzzlePond: {
        style: 'target',
        text: '[tipPuzzlePond]',
        type: 'arrow',
        width: 500,
    },
    puzzlePrison: {
        style: 'target',
        text: '[tipPuzzlePrison]',
        type: 'arrow',
        width: 500,
    },
    puzzleSiren: {
        style: 'target',
        text: '[tipPuzzleSiren]',
        type: 'arrow',
        width: 500,
    },
    puzzleShell: {
        style: 'target',
        text: '[tipPuzzleShell]',
        type: 'arrow',
        width: 500,
    },
    puzzleSkull: {
        style: 'target',
        text: '[tipPuzzleSkull]',
        type: 'arrow',
        width: 500,
    },
    puzzleSlime: {
        style: 'target',
        text: '[tipPuzzleSlime]',
        type: 'arrow',
        width: 500,
    },
    puzzleStatue: {
        style: 'target',
        text: '[tipPuzzleStatue]',
        type: 'arrow',
        width: 500,
    },
    puzzleThrower: {
        style: 'target',
        text: '[tipPuzzleThrower]',
        type: 'arrow',
        width: 500,
    },
    puzzleWall: {
        style: 'target',
        text: '[tipPuzzleWall]',
        type: 'arrow',
        width: 500,
    },
};
