import { SB } from '@play-co/replicant';

import { BoosterId, PowerBoosterId } from './booster';

export type DailyReward = {
    boosters: Record<BoosterId, number>;
    powerBoosters: Record<PowerBoosterId, number>;
    coins: number;
};

// state
//-----------------------------------------------------------------------------
export const dailyBonusState = {
    dailyBonus: SB.object({
        claimTimestamp: SB.int(),
    }),
};
