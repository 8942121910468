import { PositionType } from '../../lib/defs/types';
import { IFlow } from '../../lib/pattern/IFlow';
import NakedPromise from '../../lib/pattern/NakedPromise';
import { BoosterId } from '../../replicant/defs/booster';
import app from '../getApp';
import { NavLayer } from '../defs/nav';
import { PuzzleController } from '../main/puzzle/controller/PuzzleController';
import { PuzzleScreen } from '../main/puzzle/PuzzleScreen';

// types
//-----------------------------------------------------------------------------
export type BoosterFlowOptions = {
    id: BoosterId;
};

/*
    booster sequence
*/
export class BoosterFlow implements IFlow {
    // fields
    //-------------------------------------------------------------------------
    // input
    private _id: BoosterId;
    // state
    private _controller: PuzzleController;
    private readonly _completePromise = new NakedPromise<boolean>();

    // init
    //-------------------------------------------------------------------------
    constructor(options: BoosterFlowOptions) {
        this._id = options.id;
        this._controller = (app().nav.screens.puzzleScreen?.instance as PuzzleScreen)?.controller;
    }

    // impl
    //-------------------------------------------------------------------------
    public async execute() {
        // only during input phase
        if (this._controller.screen.scene.phaseSystem.phase !== 'input') return false;

        // run start action
        await this._start();

        // return complete promise
        return this._completePromise;
    }

    // private: actions
    //-------------------------------------------------------------------------
    public async _start() {
        const owned = app().server.state.boosters[this._id].count;

        // if player owns at least one booster, run activate flow
        if (owned > 0) return this._activate();

        // else buy flow
        return this._buy();
    }

    private async _activate() {
        // preload booster
        void this._controller.screen.scene.preloadBooster(this._id);

        // show booster activator view
        await app().nav.open('boosterActivatorPopup', {
            id: this._id,
            onActivate: (mapPosition: PositionType) => this._onActivate(mapPosition),
            onClose: () => this._onActivateClose(),
        });
    }

    private async _buy() {
        //TODO: pending spec
        /*
        // show booster buy menu
        await app().nav.open('boosterBuy', {
            id: this._id,
            total: app().game.player.gold,
            onBuy: () => this._onBuy(),
            onClose: () => this._onBuyCancel(),
        });
        */
    }

    private async _complete(success: boolean) {
        // close current dialog
        await app().nav.closeLayer(NavLayer.popup);

        // resolve complete
        this._completePromise.resolve(success);
    }

    // private: events
    //-------------------------------------------------------------------------
    // activate
    private async _onActivate(mapPosition: PositionType) {
        // disable input
        app().input.enabled = false;

        // close current dialog
        await app().nav.closeLayer(NavLayer.popup);

        // consume booster
        await app().server.invoke.boosterConsume({ id: this._id });

        // execute booster
        await this._controller.screen.scene.runBooster(this._id, mapPosition);

        // reenable input
        app().input.enabled = true;

        // complete success
        await this._complete(true);
    }

    private async _onActivateClose() {
        // complete fail
        await this._complete(false);
    }
}
