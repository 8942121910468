import { arrayCreate, sleep } from '../../replicant/util/jsTools';
import { floatRandom, integerRandom } from '../../replicant/util/mathTools';
import app from '../getApp';

// types
//-----------------------------------------------------------------------------
// public
export type SpeechProfileId = 'boy' | 'girl';
export type SpeechOptions = {
    profileId: SpeechProfileId;
    rate?: number;
};

// private
type Profile = {
    count: number;
    asset: (index: number) => string;
    volume: number;
    rate: {
        min: number;
        max: number;
    };
};

// constants
//-----------------------------------------------------------------------------
const pauseRate = 0.2;
const pauseDelay = 0.1;

const profileMap: Record<SpeechProfileId, Profile> = {
    boy: {
        count: 4,
        asset: (index) => `speech.boy.${index}.ogg`,
        volume: 0.4,
        rate: {
            min: 1.6,
            max: 2.0,
        },
    },
    girl: {
        count: 5,
        asset: (index) => `speech.girl.${index}.ogg`,
        volume: 0.45,
        rate: {
            min: 1.3,
            max: 1.7,
        },
    },
};

/*
    generates speech audio
*/
export class SpeechGenerator {
    // fields
    //-------------------------------------------------------------------------
    // input
    private _profile: Profile;
    private _rate: number;
    // state
    private _playing = false;
    private _lastIndex = 0;

    // properties
    //-------------------------------------------------------------------------
    public get playing(): boolean {
        return this._playing;
    }

    // init
    //-------------------------------------------------------------------------
    constructor(options: SpeechOptions) {
        const { profileId, rate } = options;
        this._profile = profileMap[profileId];
        this._rate = rate || 0;
    }

    static assets(options: SpeechOptions) {
        const profile = profileMap[options.profileId];
        return arrayCreate(profile.count, profile.asset);
    }

    // api
    //-------------------------------------------------------------------------
    public async play() {
        const { asset, volume, rate } = this._profile;

        // set playing state
        this._playing = true;

        // loop until stopped
        while (this._playing) {
            // get next sound index
            const index = this._getNextIndex();

            // play sound
            await app().sound.play(asset(index), { volume, rate: this._rate + floatRandom(rate.min, rate.max) });

            // add random pause
            if (Math.random() < pauseRate) await sleep(pauseDelay);
        }
    }

    public stop() {
        // reset playing state
        this._playing = false;
    }

    // private: support
    //-------------------------------------------------------------------------
    private _getNextIndex(): number {
        // choose a random value that isnt the last one
        // eslint-disable-next-line no-constant-condition
        while (true) {
            const index = integerRandom(0, this._profile.count - 1);
            if (index !== this._lastIndex) {
                this._lastIndex = index;
                return index;
            }
        }
    }
}
