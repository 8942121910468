import { NakedPromise } from '@play-co/astro';
import { BLEND_MODES, Container, Graphics, ITextStyle, NineSlicePlane, Sprite, Texture } from 'pixi.js';

import { Animation } from '../../../lib/animator/Animation';
import { BasicAsyncHandler, SizeType } from '../../../lib/defs/types';
import { GradientFilter } from '../../../lib/pixi/filters/Gradient/GradientFilter';
import { pixiGetScene, pixiSetInterval } from '../../../lib/pixi/pixiTools';
import {
    uiAlignBottom,
    uiAlignCenter,
    uiAlignCenterX,
    uiAlignCenterY,
    uiAlignLeft,
    uiAlignRight,
    uiAlignTop,
    uiCreateMask,
    uiCreateQuad,
    uiSizeToFit,
} from '../../../lib/pixi/uiTools';
import { isNativePromoEnabled } from '../../../lib/util/native';
import { tween } from '../../../lib/util/tweens';
import { getNextSecondGameOA } from '../../../replicant/components/gameDay';
import { isInfiniteLivesActive } from '../../../replicant/components/lives';
import {
    getAvailableSteps,
    getTaskCompleteCount,
    isTaskFullyCompleted,
    isTaskStepsCompleted,
} from '../../../replicant/components/task';
import features from '../../../replicant/defs/features';
import gameConfig from '../../../replicant/defs/gameConfig';
import gameDay, { GameId } from '../../../replicant/defs/gameDay';
import task from '../../../replicant/defs/task';
import { arrayRandom, arrayShuffle, sleep } from '../../../replicant/util/jsTools';
import {
    timeFormatCountdown,
    timeFromComponents,
    timeGetTimeOfDay,
    timeToComponents,
} from '../../../replicant/util/timeTools';
import { BaseballPlayer, PlayerClipId, PlayerSkin } from '../../concept/BaseballPlayer';
import { pixiConfig } from '../../defs/config';
import { PuzzlePlayFlow } from '../../flows/PuzzlePlayFlow';
import { RefillLivesFlow } from '../../flows/RefillLivesFlow';
import { SettingsFlow } from '../../flows/SettingsFlow';
import { trackPlayerTap } from '../../lib/analytics/gameplay';
import { ImageButton } from '../../lib/ui/buttons/ImageButton';
import { Pointer } from '../../lib/ui/Pointer';
import { BasicText } from '../../lib/ui/text/BasicText';
import AppPromoButton from './AppPromoButton';
import LifeComponent from './LifeComponent';
import MiniCoinView from './MiniCoinView';
import MiniStarView from './MiniStarView';
import { PLAYER_BUBBLE_MAP, SpeechScreen, TEAM_MATE_Y_OFF } from './SpeechScreen';
import app from '../../getApp';
import TeamMoraleView from './TeamMoraleView';
import { getAbTest } from '../../../replicant/util/replicantTools';

const ITEM_OFFSET_X = 262;
const SETTINGS_OFFSET_X = 330;
const PLAYER_Y_OFF = 432;

const POINTER_SCALE = 0.45;

const MAIN_UI_Z = 10000;

// types
//-----------------------------------------------------------------------------
export type HomeScreenOptions = {
    disableButtons?: boolean;
    disableNotification?: boolean;
    hideMenu?: boolean;
    skipPointer?: boolean;
    players?: 'none' | 'player' | 'teamMateGirl' | 'teamMateGuy'; // undefined -> default team of 3
    customOpening?: boolean;
    altPicher?: boolean; // another idle animation for main player
    forcedStarAmount?: number;
    overrideClip?: PlayerClipId; // used to spawn scene with specific clip id for the team
    tutorialName?: boolean;
    overrideMoraleScore?: number;
};

// constants
//-----------------------------------------------------------------------------
const idleClips: PlayerClipId[] = ['batter_idle', 'fielder_idle', 'idle1', 'idle2', 'pitcher_idle', 'pitcher_idle2'];

// manifest
//-----------------------------------------------------------------------------
const manifest = {
    puzzleButton: 'button.play.png',
    default: 'bg.main.png',
    topFrame: 'frame.top.blue.png',
    nameFrame: 'frame.green.plate.png',
    timerFrame: 'panel.timer.png',
    star: 'icon.star.png',
    settingsButton: 'button.settings2.png',
    blueButton: 'button.blue.big.png',
    shopIcon: 'icon.action.shop.png',
    taskIcon: 'icon.action.general.png',
};

// only loaded if needed
const badgeManifest = {
    badge: 'icon.badge.png',
    notification: 'icon.notification.png',
};

const tutorialManifest = {
    nameFrame: 'frame.green.plate.png',
};

// load only in tutorial
const skillManifest = {
    skill1: 'icon.skills.character1.png',
    skill2: 'icon.skills.character2.png',
    skill3: 'icon.skills.character3.png',
    hintSkill1: 'icon.skills.hint.character1.png',
    hintSkill2: 'icon.skills.hint.character2.png',
    hintSkill3: 'icon.skills.hint.character3.png',
};

const teamMateBgManifest = {
    teamMate: 'bg.team.mate.png',
};
const bgMap: { [key in PlayerId]: string } = {
    player: 'bg.main.png',
    teamMateGirl: teamMateBgManifest.teamMate,
    teamMateGuy: teamMateBgManifest.teamMate,
};

const SKILL_MAP = {
    player: {
        skill: skillManifest.skill2,
        hint: skillManifest.hintSkill2,
    },
    teamMateGuy: {
        skill: skillManifest.skill1,
        hint: skillManifest.hintSkill1,
    },
    teamMateGirl: {
        skill: skillManifest.skill3,
        hint: skillManifest.hintSkill3,
    },
};

const DEFAULT_ACTIONS = ['shop', 'puzzle', 'upgrade'] as const;
type ActionType = (typeof DEFAULT_ACTIONS)[number];

const DEFAULT_TEAM = ['player', 'teamMateGirl', 'teamMateGuy'] as const;

export type PlayerId = (typeof DEFAULT_TEAM)[number];

export class HomeScreen extends SpeechScreen {
    // events
    //-------------------------------------------------------------------------
    // scene
    private _nameFrame: NineSlicePlane;
    private _headerFrame: NineSlicePlane;
    private _lifeView: LifeComponent;
    private _starView: MiniStarView;
    private _coinView: MiniCoinView;
    private _moraleView: TeamMoraleView;
    private _settingsButton: ImageButton;
    private _bgMask: Graphics;
    private _playerButton: ImageButton;
    private _girlButton: ImageButton;
    private _guyButton: ImageButton;
    private _player: BaseballPlayer;
    private _girl: BaseballPlayer;
    private _guy: BaseballPlayer;
    private _name: BasicText;
    private _tapDialogsMain: string[];
    private _tapDialogsGuy: string[];
    private _tapDialogsGirl: string[];
    private _pointerTargets: PlayerId[];
    private _skipPointer = false;
    private _pointerShown = false;
    private _altPitcher = false;
    private _skillIcon?: Sprite; // optional during zoomed in scene
    private _timerFrame: Sprite;
    private _appPromoButton?: AppPromoButton;
    // used to limit spam clicking on the shop button(s)
    private _isShopOpen = false;

    private _notificationText: BasicText;
    private _notificationAnimation: Animation;

    private _buttonMap: Record<ActionType, ImageButton>;

    public get teamMoraleView() {
        return this._moraleView;
    }

    public timerFrame() {
        return this._timerFrame;
    }

    public get nameView() {
        return this._name;
    }

    public get starView() {
        return this._starView;
    }

    public get coinView() {
        return this._coinView;
    }

    public get puzzleButton() {
        return this._buttonMap.puzzle;
    }

    public get upgradeButton() {
        return this._buttonMap.upgrade;
    }

    public get shopButton() {
        return this._buttonMap.shop;
    }

    public get mainPlayer() {
        return this._playerButton;
    }

    public get teamMateGirl() {
        return this._girlButton;
    }

    public get teamMateGuy() {
        return this._guyButton;
    }

    // impl
    //-------------------------------------------------------------------------
    public override preload(opts: HomeScreenOptions) {
        const assets = [
            ...Object.values(manifest),
            ...Pointer.assets(),
            ...BaseballPlayer.assets(),
            ...MiniStarView.assets(),
            ...MiniCoinView.assets(),
            ...LifeComponent.assets(),
            ...TeamMoraleView.assets(),
        ];
        if (isNativePromoEnabled()) {
            assets.push(...AppPromoButton.assets());
        }

        if (app().server.state.task.timestamp >= 0) {
            assets.push(...Object.values(badgeManifest));
        }

        return [...super.preload(), ...app().resource.loadAssets(assets)];
    }

    public async preloadSkillIcons() {
        await Promise.all(app().resource.loadAssets(Object.values(skillManifest)));
    }

    public async preloadTeamMateBg() {
        await app().resource.loadAsset(teamMateBgManifest.teamMate);
    }

    public async init() {}

    public step(dt: number) {
        if (!this._skipPointer) {
            if (!this._isInteracting) {
                this._pointerIdleTime += dt;
            }
            if (this._pointerIdleTime > 3 && !this._pointer) {
                void this.spawnPointer();
                this._pointerIdleTime = 0;
            }
        }
    }

    public async spawning(options: HomeScreenOptions) {
        const {
            disableButtons,
            hideMenu,
            skipPointer,
            customOpening,
            altPicher,
            forcedStarAmount,
            disableNotification,
            tutorialName,
        } = options;
        void this.addOrientationListener();
        this._isShopOpen = false;
        this._spines = [];
        this._altPitcher = !!altPicher;
        this._skipPointer = !!skipPointer;

        this._buttonMap = {} as Record<ActionType, ImageButton>;

        // play music
        void app().music.play('bgm_training.ogg');

        this._isInteracting = false;

        // reset dialogs
        this._tapDialogsMain = arrayShuffle([...PLAYER_BUBBLE_MAP.player.dialogs]);
        this._tapDialogsGirl = arrayShuffle([...PLAYER_BUBBLE_MAP.teamMateGirl.dialogs]);
        this._tapDialogsGuy = arrayShuffle([...PLAYER_BUBBLE_MAP.teamMateGuy.dialogs]);
        // spawn scene
        await this._spawn(options);

        if (tutorialName) {
            void this._createNameSimple();
        }

        if (!hideMenu) {
            this.spawnMenu({
                animated: true,
                disableButtons: !!disableButtons,
                disableNotification: !!disableNotification,
            });
        }

        this._bgMask = uiCreateMask(this._bg.width, this._bg.height);
        this._bg.mask = this._bgMask;
        this._bg.addChild(this._bgMask);

        if (customOpening) void this._customOpening();

        if (forcedStarAmount !== undefined) {
            this.starView.forceUpdateAmount(options.forcedStarAmount);
        }
    }

    public despawned() {
        this.empty();
    }

    public override resized(size: SizeType): void {
        super.resized(size);
        this._bg.height = size.height;
        this._bgMask.height = size.height;

        for (const id of DEFAULT_ACTIONS) {
            const button = this._buttonMap[id];
            if (button) {
                this._alignButton(button);
            }
        }

        if (this._appPromoButton) {
            const offset = (size.width > 900 ? 900 : size.width) - 750;
            this._alignPromoButton(offset * 0.5);
        }

        if (this._headerFrame) {
            const offset = (size.width > 900 ? 900 : size.width) - pixiConfig.size.width;
            this._alignStatItems(offset * 0.5);
            this._alignSettings(offset * 0.5);
        }
    }

    public async despawnMenu() {
        const promises = [];
        for (const id of DEFAULT_ACTIONS) {
            const button = this._buttonMap[id];
            if (button) {
                promises.push(button.animate().add(button, { alpha: 0 }, 0.3, tween.pow2Out).promise());
            }
        }

        await Promise.all(promises);

        for (const id of DEFAULT_ACTIONS) {
            const button = this._buttonMap[id];
            if (button) {
                button.removeFromParent();
                this._buttonMap[id] = null;
            }
        }
    }

    public async openShop() {
        await this._onShop();
    }

    public enableMenu() {
        if (this._appPromoButton) {
            this._appPromoButton.disabled = false;
        }
        this._buttonMap.puzzle.onPress = this._onPuzzle.bind(this);
        this._buttonMap.upgrade.onPress = this._onUpgrade.bind(this);
        this._buttonMap.shop.onPress = this._onShop.bind(this);

        this._settingsButton.onPress = this._onSettings.bind(this);
        this._coinView.onPress = this._onShop.bind(this);
        this._lifeView.onPress = this._onLives.bind(this);

        if (this.mainPlayer) this.mainPlayer.onPress = () => this.onPlayerTap('character1_boy');
        if (this.teamMateGuy) this.teamMateGuy.onPress = () => this.onPlayerTap('character3_boy');
        if (this.teamMateGirl) this.teamMateGirl.onPress = () => this.onPlayerTap('character2_girl');
    }

    // toggle from outside
    public spawnMenu(opts: {
        animated?: boolean;
        disableButtons: boolean;
        disableNotification: boolean;
    }): ImageButton[] {
        const { animated, disableButtons, disableNotification } = opts;
        const buttonMap: Record<
            ActionType,
            {
                onPress: BasicAsyncHandler;
                buttonIcon: string;
                offset: number;
                // optional
                icon?: string;
                iconOffsetX?: number;
                labelOffset?: number;
                labelY?: number;
                maxLabelWidth?: number;
                getLabel?: () => string;
                style?: Partial<ITextStyle>;
            }
        > = {
            shop: {
                onPress: disableButtons ? null : this._onShop.bind(this),
                buttonIcon: manifest.blueButton,
                icon: manifest.shopIcon,
                // offset: -288,
                offset: -266,
            },
            puzzle: {
                onPress: disableButtons ? null : this._onPuzzle.bind(this),
                offset: 0,
                buttonIcon: manifest.puzzleButton,
                labelY: 34,
                maxLabelWidth: 250,
                getLabel: () =>
                    app().server.state.puzzle.lastLevel + 1 > gameConfig.puzzle.levels.max
                        ? `[puzzleMax]`
                        : `[buttonPlayLevel|${app().server.state.puzzle.lastLevel + 1}]`,
                style: {
                    fill: '#FFF',
                    fontSize: 36,
                    fontWeight: 'bold',
                    lineJoin: 'round',
                    align: 'center',
                } as Partial<ITextStyle>,
            },
            upgrade: {
                onPress: disableButtons ? null : this._onUpgrade.bind(this),
                offset: 266,
                buttonIcon: manifest.blueButton,
                iconOffsetX: -24,
                icon: manifest.taskIcon,
                labelOffset: 38,
                labelY: -9,
                maxLabelWidth: 60,
                getLabel: () =>
                    app().server.state.task.timestamp === -1
                        ? '[taskMax]'
                        : `${getTaskCompleteCount(app().server.state.task, app().server.now())}/${
                              task.taskList[app().server.state.task.level].steps.length
                          }`,
                style: {
                    fill: '#FFF',
                    fontSize: 32,
                    fontWeight: 'bold',
                    lineJoin: 'round',
                    align: 'center',
                    letterSpacing: 3,
                } as Partial<ITextStyle>,
            },
        };

        const buttonViews = [];
        for (const id of DEFAULT_ACTIONS) {
            const buttonData = buttonMap[id];
            const button = new ImageButton({
                image: buttonData.buttonIcon,
            });
            button.zIndex = MAIN_UI_Z;

            if (buttonData?.getLabel) {
                const label = new BasicText({
                    text: buttonData.getLabel(),
                    style: buttonData.style,
                });

                uiSizeToFit(label, buttonData.maxLabelWidth, 50);

                button.button.addChild(label);
                uiAlignCenter(button.button, label, buttonData.labelOffset, buttonData.labelY);
            }

            if (buttonData?.icon) {
                const icon = Sprite.from(buttonData.icon);
                button.button.addChild(icon);
                uiAlignCenter(button.button, icon, buttonData.iconOffsetX ?? 0, -14);
            }

            button.onPress = buttonData.onPress;

            this._bg.addChild(button);
            this._alignButton(button);
            uiAlignCenterX(this._bg, button, buttonData.offset);

            const state = app().server.state;
            const now = app().server.now();
            const availableSteps = getAvailableSteps(state, now);
            if (id === 'upgrade') {
                let redBadge;
                const stepsCompleted = isTaskStepsCompleted(state.task, now);
                const isTaskCompleted = isTaskFullyCompleted(state.task, now);
                if ((!disableNotification && availableSteps > 0) || (stepsCompleted && isTaskCompleted)) {
                    if (availableSteps) {
                        const count = new BasicText({
                            text: `${availableSteps}`,
                            style: {
                                fill: '#FFF',
                                fontWeight: 'bold',
                                fontSize: 32,
                                lineJoin: 'round',
                                align: 'center',
                            },
                        });
                        redBadge = Sprite.from(badgeManifest.badge);
                        redBadge.pivot.set(redBadge.width * 0.5, redBadge.height * 0.5);
                        redBadge.addChild(count);
                        uiAlignCenter(redBadge, count, 0, -4);
                    } else if (state.task.timestamp !== -1) {
                        redBadge = Sprite.from(badgeManifest.badge);
                        redBadge.pivot.set(redBadge.width * 0.5, redBadge.height * 0.5);
                        const notification = Sprite.from(badgeManifest.notification);
                        redBadge.addChild(notification);
                        uiAlignCenter(redBadge, notification, 0, -4);
                    }

                    if (redBadge) {
                        this._bg.addChild(redBadge);
                        uiAlignBottom(this._bg, redBadge, -74);
                        uiAlignCenterX(this._bg, redBadge, buttonData.offset + 72);

                        const defaultScale = 1;
                        const scaleDiff = 0.03;
                        redBadge
                            .animate()
                            .add(
                                redBadge.scale,
                                { x: defaultScale + scaleDiff, y: defaultScale + scaleDiff },
                                0.7,
                                tween.pow2InOut,
                            )
                            .add(
                                redBadge.scale,
                                { x: defaultScale - scaleDiff, y: defaultScale - scaleDiff },
                                0.7,
                                tween.pow2InOut,
                            )
                            .loop();
                    }
                }
            }

            if (animated) {
                button.alpha = 0;
                button.animate().add(button, { alpha: 1 }, 0.3, tween.pow2In);
            }

            this._buttonMap[id] = button;

            buttonViews.push(button);
        }

        // return in case spawned from flow
        return buttonViews;
    }

    public playPlayerAnimation(opts: { id: PlayerClipId; loop?: boolean; mix?: number }): Promise<void> {
        const { id, loop, mix } = opts;
        return this._player.start({ id, loop, mix });
    }

    public playAllAnimation(opts: { id: PlayerClipId; loop?: boolean; mix?: number }) {
        void this._player.start(opts);
        void this._girl.start(opts);
        void this._guy.start(opts);
    }

    public async playAllIdle(): Promise<void> {
        void this._player.start({ id: this._getRandomIdleClip(), loop: true });
        void this._girl.start({ id: this._getRandomIdleClip(), loop: true });
        void this._guy.start({ id: this._getRandomIdleClip(), loop: true });
    }

    public async setSetTutorialName(name: string, init = false): Promise<void> {
        if (!init) {
            await this._nameFrame.animate().add(this._nameFrame, { alpha: 0 }, 0.1, tween.linear);
        }

        this._name.text = name.trim();
        this._alignNameSimple();
        await this._nameFrame.animate().add(this._nameFrame, { alpha: 1 }, 0.15, tween.linear);
    }

    public async spawnPlayer(opts: {
        allowTap: boolean;
        player: PlayerId;
        animate?: boolean;
        overrideClip?: PlayerClipId;
    }) {
        const { allowTap, player, animate, overrideClip } = opts;

        const skinMap: { [key in PlayerId]: { skin: PlayerSkin; idle: PlayerClipId; offsetY: number } } = {
            player: {
                skin: 'character1_boy',
                idle: this._altPitcher ? 'pitcher_idle2' : 'pitcher_idle',
                offsetY: 0,
            },
            teamMateGirl: {
                skin: 'character2_girl',
                idle: 'fielder_idle',
                // offsetY: 130, // girl is too small at the lower part so lower the girl position
                offsetY: 0, // girl is too small at the lower part so lower the girl position
            },
            teamMateGuy: {
                skin: 'character3_boy',
                idle: 'batter_idle',
                offsetY: 0,
            },
        };

        this._player = new BaseballPlayer(skinMap[player].skin);
        this._spines.push(this._player);
        const button = new ImageButton({
            sound: 'tap-pet.ogg',
        });

        button.width = 260;
        button.height = 530;
        this._player.zIndex = 4;
        button.zIndex = 10;
        const container = new Container();
        container.sortableChildren = true;
        container.addChild(button, this._player);

        this._bg.addChild(container);
        container.pivot.set(container.width * 0.5, container.height * 0.5);

        container.position.set(this._bg.width * 0.5, this._bg.height - PLAYER_Y_OFF + skinMap[player].offsetY);

        uiAlignCenter(container, button);
        uiAlignCenter(container, this._player, button.width * 0.5 - 20, button.height * 0.5 + 50);

        if (allowTap) {
            button.onPress = async () =>
                this.onPlayerTap(skinMap[player].skin as 'character1_boy' | 'character2_girl' | 'character3_boy');
        }

        container.zIndex = container.y;
        this._playerButton = button;

        if (animate) {
            void this._scaleInPlayer(this._player);
        }
        const clipId = overrideClip ?? this._getRandomIdleClip();
        void this.playPlayerAnimation({ id: clipId, loop: true });
        this._player.speed = this._altPitcher ? 1 : 0.7;
    }

    public async spawnTeamMates(opts: { allowTap: boolean; animate?: boolean; overrideClip?: PlayerClipId }) {
        const { animate, overrideClip } = opts;
        let { allowTap } = opts;
        const state = app().server.state;

        if (getAbTest(state, '0009_OriginalConcept') === 'Enabled') {
            allowTap = true;
        }

        const teamMateMap = {
            character2_girl: {
                x: -220,
                scaleX: 1,
            },
            character3_boy: {
                x: 220,
                scaleX: -1,
            },
        };

        const initTeamMate = (skin: 'character2_girl' | 'character3_boy') => {
            const teamMate = new BaseballPlayer(skin);
            this._spines.push(teamMate);

            teamMate.scale.set(0.7);
            teamMate.scale.x = teamMate.scale.x * teamMateMap[skin].scaleX;

            const button = new ImageButton({
                sound: 'tap-pet.ogg',
            });

            button.width = 260;
            button.height = 390;
            teamMate.zIndex = 4;
            button.zIndex = 10;
            const container = new Container();
            container.sortableChildren = true;
            container.addChild(button, teamMate);

            this._bg.addChild(container);
            container.pivot.set(container.width * 0.5, container.height * 0.5);
            container.position.set(this._bg.width * 0.5 + teamMateMap[skin].x, this._bg.height - TEAM_MATE_Y_OFF);

            uiAlignCenter(container, button);
            uiAlignCenter(container, teamMate, teamMate.scale.x * button.width * 0.5 - 20, button.height * 0.5 + 30);

            if (allowTap) {
                button.onPress = async () => this.onPlayerTap(skin);
            }
            container.zIndex = container.y;
            return { teamMate, button };
        };

        const { teamMate: teamMateGirl, button: buttonGirl } = initTeamMate('character2_girl');
        this._girlButton = buttonGirl;
        this._girl = teamMateGirl;
        const { teamMate: teamMateGuy, button: buttonGuy } = initTeamMate('character3_boy');
        this._guyButton = buttonGuy;
        this._guy = teamMateGuy;

        void teamMateGirl.start({ id: overrideClip ?? this._getRandomIdleClip(), loop: true });
        teamMateGirl.speed = 0.8;

        void teamMateGuy.start({ id: overrideClip ?? this._getRandomIdleClip(), loop: true });
        teamMateGuy.speed = 0.9;

        if (animate) {
            const girlPromise = this._scaleInPlayer(teamMateGirl);
            // small delay between spawns
            const guyPromise = this._scaleInPlayer(teamMateGuy, 0.25);
            await Promise.all([girlPromise, guyPromise]);
        }
    }

    public async updateSKillHint(player: PlayerId) {
        this._skillIcon?.destroy();
        this._skillIcon = Sprite.from(SKILL_MAP[player].hint);
        this._alignSkillIcon();
    }

    public async starIncreaseAnimation(): Promise<void> {
        const duration = 0.84;
        const star = Sprite.from(manifest.star);
        const scale = 1.4 * this.root.scale.x;
        star.anchor.set(0.5, 0.5);
        // play star gain sound
        void app().sound.play('star1.mp3', { rate: 0.7, volume: 0.8 });

        const from = this._player.toGlobal({ x: 5, y: -280 });
        const target = this._starView.icon.toGlobal({
            x: 0,
            y: 0,
        });

        const starWidth = star.width;
        star.scale.set(0);
        // scale down for animation after getting width and height
        star.position.set(from.x, from.y);
        // get root to make star to be on top of everything
        const root = pixiGetScene();
        root.addChild(star);
        const targetX = target.x + starWidth * 0.5;
        const targetY = target.y + 30;

        star.animate()
            .set(star.scale, { x: 0, y: 0 })
            .add(star.scale, { x: scale, y: scale }, 0.25, tween.backOut(2.2));
        star.animate().add(star, { x: targetX }, duration * 0.333, tween.pow2InOut);

        const defaultScale = this._starView.icon.scale.x;
        void star
            .animate()
            .wait(duration - 0.15)
            .add(star.scale, { x: 0.6, y: 0.6 }, 0.15, tween.linear)
            .promise();

        this._starView.icon
            .animate()
            .wait(duration - 0.23) // start a little bit earlier for better timing
            .add(this._starView.icon.scale, { x: 1.1, y: 1.1 }, 0.21, tween.backIn())
            .add(this._starView.icon.scale, { x: defaultScale, y: defaultScale }, 0.21, tween.backOut());
        await star.animate().add(star, { y: targetY, x: targetX }, duration, tween.backIn(1.2)).promise();

        star.destroy();
    }

    // private: scene
    //-------------------------------------------------------------------------
    private async _spawn(options: HomeScreenOptions) {
        const { players, overrideClip, hideMenu, skipPointer, disableButtons, overrideMoraleScore } = options;
        this.root.sortableChildren = true;
        // spawn scene
        let bgAsset;
        if (!!players && players !== 'none') {
            if (players === 'teamMateGirl' || players === 'teamMateGuy') {
                await this.preloadTeamMateBg();
            }
            bgAsset = bgMap[players];
        } else {
            bgAsset = manifest.default;
        }

        this._bg = new NineSlicePlane(Texture.from(bgAsset), 0, 0, 0, 1334);
        this._bg.width = 900;
        this._bg.height =
            app().stage.canvas.height < pixiConfig.size.height ? pixiConfig.size.height : app().stage.canvas.height;

        this.base.addContent({
            bg: {
                content: this._bg,
                styles: {
                    position: 'bottomCenter',
                },
            },
        });

        // extra container, animated
        let hide = !!hideMenu;
        const is0009_OriginalConcept_Enable = getAbTest(app().server.state, '0009_OriginalConcept') === 'Enabled';

        if (is0009_OriginalConcept_Enable) {
            hide = true;
        }

        if (!hide) {
            this._spawnHeader({ disableButtons, overrideMoraleScore });
            this._moraleView = new TeamMoraleView({ scoreOverride: overrideMoraleScore });
            this._alignMoraleView();
        }

        if (!players) {
            // spawn full team as default
            void this.spawnTeamMates({ allowTap: !hide && !disableButtons, overrideClip });
            void this.spawnPlayer({ allowTap: !hide && !disableButtons, player: 'player', overrideClip });
        } else if (players !== 'none') {
            // preload also called from TutorialFlow so this will resolve constant time
            await this.preloadSkillIcons();
            // only one main player
            void this.spawnPlayer({ allowTap: !hide && !disableButtons, player: players, overrideClip });
            this._player.speed = 1; // override with default speed when its only 1 player
            this._skillIcon = Sprite.from(SKILL_MAP[players].skill);
            this._alignSkillIcon();
        }

        this._pointer = null; // reset reference
        if (!skipPointer) {
            void this.spawnPointer();
        }

        if (is0009_OriginalConcept_Enable) {
            this._createHeader();
        }
    }

    private _createHeader() {
        this._nameFrame = new NineSlicePlane(Texture.from(manifest.nameFrame), 70, 0, 170, 0);
        this._nameFrame.width = 400;

        const name = app().game.player.name.trim();
        this._name = new BasicText({
            text: name,
            style: {
                fill: '#FFF',
                fontSize: 33,
                fontWeight: 'bold',
                lineJoin: 'round',
                align: 'left',
                dropShadow: true,
                dropShadowColor: 0x275f57,
                dropShadowAngle: Math.PI / 2,
                dropShadowAlpha: 0.6,
                dropShadowDistance: 5,
            },
        });

        this._bg.addChild(this._nameFrame);
        this._nameFrame.addChild(this._name);

        uiSizeToFit(this._name, 290, 100);
        uiAlignCenter(this._nameFrame, this._name, -35, -5);
        this._nameFrame.y = 30;
        this._nameFrame.x = 90;
        this._name.zIndex = MAIN_UI_Z;

        if (!name) {
            this._nameFrame.alpha = 0;
        }

        return this._nameFrame;
    }

    private _alignMoraleView(yOverride?: number) {
        this._bg.addChild(this._moraleView);
        uiAlignCenterX(this._bg, this._moraleView);
        this.teamMoraleView.y = yOverride ?? 108;
    }

    private async _customOpening() {
        // make sure we still pitch black after regular opening animation
        this._bg.alpha = 0;

        const fadeHeight = 35;
        const padding = 80 - fadeHeight;
        const boxHeight = this._bg.height * 0.5 + padding;

        const topBlack = uiCreateQuad(0x0, 1, this._bg.width + 2, boxHeight);
        const bottomBlack = uiCreateQuad(0x0, 1, this._bg.width + 2, boxHeight);

        const topBlackFade = uiCreateQuad(0x0, 1, this._bg.width + 2, fadeHeight);
        const bottomBlackFade = uiCreateQuad(0x0, 1, this._bg.width + 2, fadeHeight);

        topBlack.addChild(topBlackFade);
        uiAlignBottom(topBlack, topBlackFade, fadeHeight - 1);
        bottomBlack.addChild(bottomBlackFade);
        uiAlignTop(bottomBlack, bottomBlackFade, -fadeHeight + 1);

        topBlack.blendMode = BLEND_MODES.SOFT_LIGHT;
        topBlack.y = -padding;
        bottomBlack.y = this._bg.height - boxHeight + padding;

        topBlack.zIndex = MAIN_UI_Z + 1;
        bottomBlack.zIndex = MAIN_UI_Z + 1;

        this._bg.addChild(topBlack, bottomBlack);
        uiAlignCenterX(this._bg, topBlack);
        uiAlignCenterX(this._bg, bottomBlack);

        topBlackFade.filters = [
            new GradientFilter({
                type: 0,
                angle: 0,
                stops: [
                    {
                        offset: 0,
                        color: 0,
                        alpha: 0,
                    },
                    {
                        offset: 1,
                        color: 0x0,
                        alpha: 1,
                    },
                ],
            }),
        ];

        bottomBlackFade.filters = [
            new GradientFilter({
                type: 0,
                angle: 0,
                stops: [
                    {
                        offset: 1,
                        color: 0,
                        alpha: 0,
                    },
                    {
                        offset: 0,
                        color: 0x0,
                        alpha: 1,
                    },
                ],
            }),
        ];

        await sleep(0.31); // stay black during the standard transition
        // start custom opening
        this._bg.alpha = 1;
        topBlack.animate().add(topBlack, { y: topBlack.y - 60 }, 0.5, tween.pow2Out);
        await bottomBlack
            .animate()
            .add(bottomBlack, { y: bottomBlack.y + 60 }, 0.55, tween.pow2Out)
            .promise();
        topBlack.animate().add(topBlack, { y: topBlack.y - boxHeight }, 1.05, tween.backIn(1.7));
        bottomBlack.animate().add(bottomBlack, { y: bottomBlack.y + boxHeight }, 1.05, tween.backIn(1.7));
    }

    private _createTimer() {
        const timerFrame = (this._timerFrame = Sprite.from(manifest.timerFrame));
        const timerText = new BasicText({
            text: '[timerNextGame]',
            style: {
                fill: '#BEE5D3',
                fontSize: 18,
                fontWeight: 'bold',
                lineJoin: 'round',
                align: 'left',
            },
        });
        const timer = new BasicText({
            text: '',
            style: {
                fill: '#FFF',
                fontSize: 24,
                lineJoin: 'round',
                align: 'center',
            },
        });

        const getNextGames = (now: number) => {
            let remainingFirst = gameDay.nextDayTimers.sevenAM - timeFromComponents(timeGetTimeOfDay(now));
            if (remainingFirst < 0) {
                remainingFirst += timeFromComponents({ days: 1 });
            }
            let remainingSecond = gameDay.nextDayTimers.threePM - timeFromComponents(timeGetTimeOfDay(now));
            const days = timeToComponents(remainingSecond).days;
            // depending on utc time the remaning second can have a delay above 24h, offset it to make the game Id calculation accurate
            remainingSecond -= days > 0 ? timeFromComponents({ days }) : 0;
            return { remainingFirst, remainingSecond };
        };

        const now = app().server.now();
        const { remainingFirst, remainingSecond } = getNextGames(now);
        const finishTimeFirst = remainingFirst + now;
        const finishTimeSecond = remainingSecond + now;
        const multipleEnabled = features.multipleGames;
        const nextSecondGameAvailable = getNextSecondGameOA(app().server.state, now);
        let game: GameId =
            // always 'first' GameId if not enabled
            multipleEnabled && nextSecondGameAvailable !== -1 && remainingFirst > remainingSecond ? 'second' : 'first';

        const finishTime = {
            first: finishTimeFirst,
            second: finishTimeSecond,
        };

        const updateTimer = () => {
            const updatedNow = app().server.now();
            const timeLeft = finishTime[game] - updatedNow;
            if (timeLeft < 0) {
                if (multipleEnabled) {
                    const nextSecondGame = getNextSecondGameOA(app().server.state, updatedNow);
                    const { remainingFirst, remainingSecond } = getNextGames(updatedNow);
                    game = nextSecondGame !== -1 && remainingFirst > remainingSecond ? 'second' : 'first';
                    finishTime.first = remainingFirst + updatedNow;
                    finishTime.second = remainingSecond + updatedNow;
                } else {
                    finishTime.first += timeFromComponents({ days: 1 });
                }
            }

            timer.text = timeFormatCountdown(timeToComponents(timeLeft), 2);

            uiSizeToFit(timerText, 120, 30);
            uiSizeToFit(timer, 98, 30);

            timerFrame.addChild(timerText, timer);
            uiAlignLeft(timerFrame, timerText, 15);
            uiAlignCenterX(timerFrame, timer, 64);
            uiAlignCenterY(timerFrame, timerText, -1);
            uiAlignCenterY(timerFrame, timer, -2);
        };

        // start timer
        updateTimer();
        pixiSetInterval(timerFrame, () => updateTimer(), 1);

        return timerFrame;
    }

    // special for tutorial
    private async _createNameSimple() {
        await app().resource.loadAsset(tutorialManifest.nameFrame);
        this._nameFrame = new NineSlicePlane(Texture.from(tutorialManifest.nameFrame), 70, 0, 170, 0);
        this._nameFrame.width = 400;

        const name = app().game.player.name.trim();
        this._name = new BasicText({
            text: name,
            style: {
                fill: '#FFF',
                fontSize: 33,
                fontWeight: 'bold',
                lineJoin: 'round',
                align: 'left',
                dropShadow: true,
                dropShadowColor: 0x275f57,
                dropShadowAngle: Math.PI / 2,
                dropShadowAlpha: 0.6,
                dropShadowDistance: 5,
            },
        });

        this._nameFrame.addChild(this._name);
        this._bg.addChild(this._nameFrame);
        this._alignNameSimple();

        if (!name) {
            this._nameFrame.alpha = 0;
        }
    }

    private _spawnHeader(opts: { disableButtons: boolean; overrideMoraleScore?: number }) {
        const { disableButtons } = opts;
        this._headerFrame = new NineSlicePlane(Texture.from(manifest.topFrame), 14, 0, 14, 0);
        this._headerFrame.width = 900;

        const name = `${app().game.player.name.trim()}`;
        this._name = new BasicText({
            text: name,
            style: {
                fill: '#FFF',
                fontSize: 36,
                fontWeight: 'bold',
                lineJoin: 'round',
                align: 'left',
            },
        });

        this._name.pivot.y = this._name.height * 0.5;

        this._alignName();

        if (!name) {
            this._name.alpha = 0;
        }

        if (isNativePromoEnabled()) {
            this._appPromoButton = new AppPromoButton();
            this._appPromoButton.y = 150;
            this._alignPromoButton();

            if (disableButtons) {
                this._appPromoButton.disabled = true;
            }
            this._headerFrame.addChild(this._appPromoButton);
        }

        this._lifeView = new LifeComponent();
        this._starView = new MiniStarView();
        this._coinView = new MiniCoinView();

        this._coinView.onPress = disableButtons ? null : this._onShop.bind(this);
        this._lifeView.onPress = disableButtons ? null : this._onLives.bind(this);

        this._alignStatItems();
        this._lifeView.y = 55;
        this._starView.y = 32;
        this._coinView.y = 81;
        this._headerFrame.addChild(this._name, this._lifeView, this._starView, this._coinView);

        const timerFrame = new Graphics();
        timerFrame.beginFill(0x338291, 0.0001);
        timerFrame.drawRoundedRect(0, 0, 278, 60, 12);
        timerFrame.endFill();

        const timer = this._createTimer();
        timerFrame.addChild(timer);
        uiAlignCenter(timerFrame, timer, 0, -22);

        this._settingsButton = new ImageButton({ image: manifest.settingsButton });
        this._settingsButton.onPress = disableButtons ? null : this._onSettings.bind(this);
        this._headerFrame.addChild(this._settingsButton);
        this._alignSettings();
        this._settingsButton.y = 125;

        this._headerFrame.addChild(timerFrame);
        uiAlignCenter(this._headerFrame, timerFrame, 0, 40);

        this._bg.addChild(this._headerFrame);
    }

    private async onPlayerTap(skin: 'character1_boy' | 'character2_girl' | 'character3_boy') {
        this._pointerIdleTime = 0;
        if (this._underlayInput) this._underlayInput.enabled = false;
        if (this._isInteracting) {
            this._skipSpeechCount++;
            return;
        }

        this._isInteracting = true;
        trackPlayerTap();

        const teamMates = {
            character1_boy: 'player',
            character2_girl: 'teamMateGirl',
            character3_boy: 'teamMateGuy',
        };

        const teamMate = teamMates[skin] as PlayerId;
        const bubble = await this._spawnRandomBubble(teamMate);

        if (this._skipSpeechCount < 2) {
            // if tapped 3+ times skip sleep after bubble.
            for (let i = 0; i < 10; i++) {
                if (this._skipSpeechCount > 1) break; // can be mutated from another tap callback, break if needed.
                await sleep(0.2); // sleep 2s total
            }
        }

        await bubble.animate().add(bubble, { alpha: 0 }, 0.3, tween.pow2Out);
        this._isInteracting = false;
        bubble.removeSelf();
        this._skipSpeechCount = 0;
        this._underlayInput.enabled = false;
    }

    private async _spawnRandomBubble(player: PlayerId) {
        const shuffleMap = {
            player: {
                shuffled: () => this._tapDialogsMain, // make sure we have latest reference
            },
            teamMateGirl: {
                shuffled: () => this._tapDialogsGirl, // make sure we have latest reference
            },
            teamMateGuy: {
                shuffled: () => this._tapDialogsGuy, // make sure we have latest reference
            },
        };

        const len = shuffleMap[player].shuffled().length;
        if (len === 0) {
            if (player === 'player') {
                this._tapDialogsMain = arrayShuffle([...PLAYER_BUBBLE_MAP[player].dialogs]);
            } else if (player === 'teamMateGirl') {
                this._tapDialogsGirl = arrayShuffle([...PLAYER_BUBBLE_MAP[player].dialogs]);
            } else if (player === 'teamMateGuy') {
                this._tapDialogsGuy = arrayShuffle([...PLAYER_BUBBLE_MAP[player].dialogs]);
            }
        }

        const dialogText = shuffleMap[player].shuffled().shift();
        return this.spawnSpeechBubble({
            dialogText,
            bubbleOffset: { x: PLAYER_BUBBLE_MAP[player].offsetX, y: PLAYER_BUBBLE_MAP[player].offsetY },
            slice: PLAYER_BUBBLE_MAP[player].slice,
            scaleX: PLAYER_BUBBLE_MAP[player].scaleX,
            player,
        });
    }

    public async showNotification(text: string): Promise<void> {
        await this._showNotification(text);
    }

    private async _showNotification(text: string): Promise<void> {
        this._notificationAnimation?.cancel();
        this._notificationText?.destroy();
        this._notificationText = null;

        this._notificationText = new BasicText({
            text,
            style: {
                fill: '#FFF',
                fontSize: 46,
                lineJoin: 'round',
                fontWeight: 'bold',
                stroke: 0x0,
                strokeThickness: 4,
                dropShadow: true,
                dropShadowAngle: Math.PI / 2,
                dropShadowAlpha: 0.6,
                dropShadowDistance: 2,
                align: 'center',
            },
        });

        this._notificationText.pivot.set(this._notificationText.width * 0.5, this._notificationText.height * 0.5);
        this.base.addChild(this._notificationText);
        uiAlignCenter(this.base, this._notificationText, 0, -60);
        this._notificationText.alpha = 0;
        this._notificationAnimation = this._notificationText
            .animate()
            .add(this._notificationText, { alpha: 1 }, 0.2, tween.pow2In)
            .wait(3.75)
            .add(this._notificationText, { alpha: 0 }, 0.2, tween.pow2Out)
            .then(() => {
                this._notificationText?.destroy();
                this._notificationText = null;
            });
    }

    private async _onPuzzle() {
        this._pointerIdleTime = 0;
        // await this._showNotification('[checkLater]');
        await new PuzzlePlayFlow({}).execute();
    }

    private async _onSettings() {
        this._pointerIdleTime = 0;
        await new SettingsFlow().execute();
    }

    private async _onShop() {
        if (this._isShopOpen) return;

        this._pointerIdleTime = 0;
        this._isShopOpen = true;
        const closePromise = new NakedPromise();
        void app().nav.open('shop', { onClose: closePromise.resolve });
        await closePromise;
        this._isShopOpen = false;
    }

    private async _onLives() {
        this._pointerIdleTime = 0;
        if (
            isInfiniteLivesActive(app().server.state, app().server.now()) ||
            app().game.player.lives >= gameConfig.lives.max
        ) {
            void this._showNotification('[livesFullToast]');
            return;
        }
        await new RefillLivesFlow({}).execute();
    }

    private async _onUpgrade() {
        this._pointerIdleTime = 0;
        // -1 indicates max level reached
        if (app().server.state.task.timestamp === -1) {
            void this._showNotification('[taskComingSoonToast]');
            return;
        }

        void app().nav.open('taskScreen', { stateTask: app().server.state.task });
    }

    public async spawnMoraleView(opts: { overrideMoraleScore?: number }) {
        this._moraleView = new TeamMoraleView({ scoreOverride: opts?.overrideMoraleScore, simple: true });
        this._moraleView.alpha = 0;
        this._alignMoraleView(40);

        await this._moraleView.animate().add(this._moraleView, { alpha: 1 }, 0.25, tween.pow2Out).promise();
    }

    public async spawnPointer() {
        this._pointer = new Pointer({ type: 'hand' });
        this._pointer.zIndex = 5;

        if (!this._pointerTargets || this._pointerTargets.length === 0) {
            this._pointerTargets = arrayShuffle([...DEFAULT_TEAM]);
        }

        const target = this._pointerTargets.shift();
        const pointerMap = {
            player: {
                view: this._player,
                scale: 1,
                offset: -100,
            },
            teamMateGirl: {
                view: this._girl,
                scale: 1,
                offset: -10,
            },
            teamMateGuy: {
                view: this._guy,
                offset: 140,
                scale: -1,
            },
        };

        const targetView = pointerMap[target].view;
        uiAlignCenterX(targetView, this._pointer, pointerMap[target].offset);
        targetView.addChild(this._pointer);

        this._pointer.scale.x *= pointerMap[target].scale;
        const x = this._pointer.x;
        const y = this._pointer.y - 200;

        this._pointer.position.set(x, y);
        this._pointer.scale.set(0);
        this._pointerAnimation = this._pointer
            .animate()
            .add(
                this._pointer.scale,
                { x: Math.sign(targetView.scale.x) * POINTER_SCALE, y: POINTER_SCALE },
                0.35,
                tween.backOut(1.2),
            )
            .add(this._pointer.position, { y: y + 8 }, 0.8, tween.powNInOut(1.1))
            .add(this._pointer.position, { y: y - 8 }, 0.8, tween.powNInOut(1.1))
            .loop();

        if (!this._pointerShown) {
            this._pointerText = new BasicText({
                text: '[tapToTalk]',
                style: {
                    fill: '#FFF',
                    fontSize: 34,
                    lineJoin: 'round',
                    fontWeight: 'bold',
                    align: 'center',
                    dropShadow: true,
                    dropShadowAngle: Math.PI / 2,
                    dropShadowAlpha: 0.6,
                    dropShadowDistance: 4,
                },
            });
            this._pointerText.alpha = 0;
            this._pointerText.zIndex = MAIN_UI_Z;
            // targetView.parent.addChild(this._pointerText);
            // uiAlignCenter(targetView.parent, this._pointerText);
            // this._pointerText.y += 150;

            // TODO discuss, one text or 3?
            // this._pointerText.position.set(this._bg.width * 0.5, this._bg.height - 200);
            uiAlignBottom(this._bg, this._pointerText, -200);
            uiAlignCenterX(this._bg, this._pointerText);
            this._bg.addChild(this._pointerText);

            this._pointerText.animate().add(this._pointerText, { alpha: 1 }, 0.35, tween.pow2Out);

            // show text once
            this._pointerShown = true;
        }
    }

    private _alignName(offsetY = 0) {
        uiSizeToFit(this._name, 310, 34);
        uiAlignCenter(this._headerFrame, this._name, 0, -32 + offsetY);
        this._name.zIndex = MAIN_UI_Z;
    }

    // tutorial name
    private _alignNameSimple() {
        uiSizeToFit(this._name, 290, 100);
        uiAlignCenter(this._nameFrame, this._name, -35, -5);
        this._nameFrame.y = 30;
        this._nameFrame.x = 90;
        this._name.zIndex = MAIN_UI_Z;
    }

    private _scaleInPlayer(target: Container, delay = 0): Promise<void> {
        const defaultScale = target.scale.clone();
        target.scale.set(0);
        return target
            .animate()
            .wait(delay)
            .add(target.scale, { x: defaultScale.x, y: defaultScale.y }, 0.25, tween.backOut(2.2))
            .promise();
    }

    private _alignSkillIcon() {
        this._bg.addChild(this._skillIcon);

        if (getAbTest(app().server.state, '0009_OriginalConcept') === 'Enabled') {
            // 0009_OriginalConcept Enabled
            uiAlignTop(this._bg, this._skillIcon, 20);
        } else {
            // 0009_OriginalConcept Control
            uiAlignTop(this._bg, this._skillIcon, 118);
        }

        this._skillIcon.x = this._bg.width - 260;
    }

    private _alignButton(button: ImageButton) {
        uiAlignBottom(this._bg, button, -8);
    }

    private _getRandomIdleClip() {
        const ids = idleClips.filter(
            (id) =>
                id !== this._player?.spine.activeId &&
                id !== this._girl?.spine.activeId &&
                id !== this._guy?.spine.activeId,
        );
        return arrayRandom(ids);
    }

    private _alignPromoButton(offset = 0) {
        uiAlignRight(this._headerFrame, this._appPromoButton, -92 + offset);
    }

    private _alignStatItems(offset = 0) {
        uiAlignCenterX(this._headerFrame, this._lifeView, -ITEM_OFFSET_X - offset);
        uiAlignCenterX(this._headerFrame, this._starView, ITEM_OFFSET_X + offset);
        uiAlignCenterX(this._headerFrame, this._coinView, ITEM_OFFSET_X + offset);
    }

    private _alignSettings(offset = 0) {
        uiAlignCenterX(this._headerFrame, this._settingsButton, -SETTINGS_OFFSET_X - offset);
    }
}
