import { CollideEvent, CollideId } from '../../defs/collide';
import type { ShellBlockEntity } from '../../entities/BlockEntity';
import { despawnBlockEntity } from '../../entities/BlockEntity';
import { GameScene } from '../../GameScene';
import { BreakEffect } from '../effects/BreakEffect';
import { InvalidEffect } from '../effects/InvalidEffect';
import { CollideHandler, ICollision } from './ICollision';

export class ShellCollision implements ICollision {
    private readonly _scene: GameScene;
    private readonly _entity: ShellBlockEntity;

    private readonly _resolvers: { [key in CollideId]?: CollideHandler } = {
        tap: this._resolveTap,
        block: this._resolveBlock,
        attack: this._resolveAttack,
    };

    constructor(scene: GameScene, entity: ShellBlockEntity) {
        this._scene = scene;
        this._entity = entity;
    }

    public async resolve(event: CollideEvent): Promise<boolean> {
        return this._resolvers[event.id]?.call(this, event) ?? false;
    }

    private async _resolveTap(_: CollideEvent) {
        await new InvalidEffect(this._entity).execute();
        return false;
    }

    private async _resolveBlock(event: CollideEvent) {
        const collider = event.collider;

        if (collider?.type === 'block') {
            await this.attack();
            return true;
        }
        return false;
    }

    private async _resolveAttack(_: CollideEvent) {
        await this.attack();
        return false;
    }

    private async attack() {
        const entity = this._entity;

        // Shell closed, bail.
        if (!entity.c.blockShell.getGameplayState()) {
            return;
        }

        const position = entity.c.position.mapPosition;

        await new BreakEffect(this._scene, {
            position,
            size: entity.c.block.props,
            color: 0x5d92f5,
        }).execute();
        entity.view.visible = false;

        // await new GoalEffect(this._scene, {
        //   position,
        //   id: 'shell',
        //   scale: 0.5,
        //   rotation: entity.rotation,
        // }).execute();

        despawnBlockEntity(this._scene, entity);
    }
}
