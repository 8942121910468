import { IFlow } from '../../lib/pattern/IFlow';
import NakedPromise from '../../lib/pattern/NakedPromise';
import app from '../getApp';
import { ShopPinchRestore } from '../lib/analytics/pinch';
import { PuzzleResults } from '../main/puzzle/controller/PuzzleController';

// types
//-----------------------------------------------------------------------------
export type RestoreStateFlowOptions = {
    openShop?: boolean;
    shopPinch?: ShopPinchRestore;
};

/*
    checks for any restore state and runs the corresponding flows
*/
export class RestoreStateFlow implements IFlow {
    // fields
    //-------------------------------------------------------------------------
    private _options: RestoreStateFlowOptions;
    private readonly _complete = new NakedPromise<boolean>();

    // init
    //-------------------------------------------------------------------------
    constructor(options: RestoreStateFlowOptions) {
        this._options = options;
    }

    // impl
    //-------------------------------------------------------------------------
    public async execute() {
        // check for puzzle save state
        if (app().server.state.puzzle.save) {
            if (this._options.openShop) {
                const closePromise = new NakedPromise();
                void app().nav.open('shop', { onClose: closePromise.resolve });
                void app().nav.preload('puzzleScreen');
                await closePromise;
            }
            // run puzzle from this state
            // PuzzleCompleteFlow will trigger if restored from 0 moves to allow for buying moves using new coins from the shop
            await app().nav.open('puzzleScreen', {
                restoreState: true,
                shopPinch: this._options.shopPinch,
                onComplete: this._onPuzzleComplete.bind(this),
            });

            // else complete fail, indicating no restore state flow found.
        } else {
            void this._actionComplete(false);
        }

        return this._complete;
    }

    private async _actionComplete(success: boolean) {
        this._complete.resolve(success);
    }

    // private: events
    //-------------------------------------------------------------------------
    private async _onPuzzleComplete(results: PuzzleResults) {
        // complete
        void this._actionComplete(true);
    }
}
