/**
 * List of invisible unicode characters.
 */
export const invisibleUnicodeCharacters = [
    '\0-\x08',
    '\u000B',
    '\u000C',
    '\u000E-\u001F',
    '\x7F-\u0084',
    '\u0086-\x9F',
    '\u00A0',
    '\xAD',
    '\u200B-\u200F',
    '\u202A-\u202E',
    '\u2060-\u206F',
    '\u2072',
    '\uFEFD-\uFF00',
    '\uFFEF-\uFFFB',
];

export function assertNever(value: never): never {
    // We throw an error in case the TS type of `value` is different from its run-time type.
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    throw new Error(`Invalid value: '${value}'`);
}

export function isValidLineId(lineID: string): boolean {
    const pattern = /^[U]{1}[A-Za-z0-9]{32}$/;
    return pattern.test(lineID);
}

export function parseSenderName(senderName: string): string {
    // not allowed keywords and common emojis
    let parsedName = senderName.replace(
        /line|ライン|らいん|ＬＩＮＥ|ｌｉｎｅ|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2580-\u27BF]|\uD83E[\uDD10-\uDDFF]/gi,
        '',
    );
    // not allowed invisible characters
    parsedName = removeInvisibleCharacters(parsedName);
    // name has a limit of 20 characters
    return parsedName.substring(0, 20);
}

export function removeInvisibleCharacters(input: string, replacement = '?') {
    const expression = `[${invisibleUnicodeCharacters.reduce(
        (acc, char, i) => `${acc}${i === 0 ? '' : '|'}${char}`,
        '',
    )}]`;
    return input.replace(new RegExp(expression, 'g'), replacement);
}
