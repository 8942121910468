import { Container, NineSlicePlane, Texture } from 'pixi.js';

import { BasicAsyncHandler } from '../../../lib/defs/types';
import { uiAlignCenterX } from '../../../lib/pixi/uiTools';
import { BoosterId, boosterIds, boosterMap } from '../../../replicant/defs/booster';
import { ImageButton } from '../../lib/ui/buttons/ImageButton';
import { PuzzleBoosterButton } from './PuzzleBoosterButton';
import app from '../../getApp';

// constants
//-----------------------------------------------------------------------------
const manifest = {
    close: 'button.close3.png',
    bg: 'puzzle.footer.bg.png',
};

/*
    app overlay footer
*/
export class PuzzleFooter extends NineSlicePlane {
    // fields
    //-------------------------------------------------------------------------
    private _container: Container;
    private _boosterButtons: { [key in BoosterId]?: PuzzleBoosterButton } = {};
    private _close: ImageButton;

    // properties
    //-------------------------------------------------------------------------
    public get boosterButtons() {
        return this._boosterButtons;
    }

    // init
    //-------------------------------------------------------------------------
    constructor(options: { onClose: BasicAsyncHandler }) {
        super(Texture.from(manifest.bg), 1, 0, 1, 0);
        this.height = 108;
        this.width = 900;

        // spawn container
        this._container = this.addChild(new Container());

        // spawn booster buttons
        this._spawnBoosterButtons();

        // spawn close button
        this._spawnCloseButton(options.onClose);

        // align
        uiAlignCenterX(this, this._container);
    }

    static assets(): string[] {
        return [
            ...Object.values(manifest),
            ...Object.keys(boosterMap)
                .map((id: BoosterId) => PuzzleBoosterButton.assets({ id }))
                .flat(),
        ];
    }

    public udpate() {
        // dont show on puzzle 1 if tutorial
        const show = app().server.state.puzzle.level > 1 || app().server.state.tutorial.puzzle;
        this._close.visible = show;
    }

    // private: scene
    //-------------------------------------------------------------------------
    private _spawnBoosterButtons(): void {
        const container = this._container.addChild(new Container());
        container.y = -36;
        let x = 0;

        // spawn button for each button id
        for (const key of boosterIds) {
            const id = key;
            const button = (this._boosterButtons[id] = container.addChild(new PuzzleBoosterButton({ id })));
            button.x = x;
            x += 142;
        }
    }

    private _spawnCloseButton(handler: BasicAsyncHandler): void {
        const close = (this._close = this._container.addChild(
            new ImageButton({
                image: manifest.close,
            }),
        ));
        close.x = 632;
        close.y = 5;
        close.onPress = handler;
    }
}
