import { NineSlicePlane, Sprite, Texture } from 'pixi.js';

import { BasicText } from '../../lib/ui/text/BasicText';
import { LevelPopup, LevelPopupOptions } from './LevelPopup';
import app from '../../getApp';

// types
//-----------------------------------------------------------------------------
export type LevelFailedPopupOptions = Omit<LevelPopupOptions, 'ribbon'>;

const manifest = {
    inset: 'frame.inset.light.png',
    lifeLost: 'icon.life.lost.png',
};

/*
    Failed popup
*/
export class LevelFailedPopup extends LevelPopup {
    public override preload() {
        return [...super.preload(), ...app().resource.loadAssets([...Object.values(manifest)])];
    }

    // @ts-ignore
    public override async spawning(options: LevelFailedPopupOptions) {
        await super.spawning({
            ...options,
            ribbon: 'red',
            okButton: '[buttonTryAgain]',
        });

        const inset = new NineSlicePlane(Texture.from(manifest.inset), 28, 28, 28, 28);
        inset.width = 476;
        inset.height = 247;

        const failed = new BasicText({
            text: '[popupLevelFailed]',
            style: {
                fill: 0xc61a4f,
                fontSize: 40,
                fontWeight: 'bold',
                lineJoin: 'round',
                align: 'center',
                stroke: 'white',
                strokeThickness: 8,
            },
        });

        const heart = Sprite.from(manifest.lifeLost);

        // spawn
        this.main.addContent({
            inset: {
                content: inset,
                styles: {
                    position: 'center',
                },
            },
            failed: {
                content: failed,
                styles: {
                    position: 'center',
                    marginTop: 10 - inset.height / 2,
                },
            },
            heart: {
                content: heart,
                styles: {
                    position: 'center',
                },
            },
        });
    }
}
