import { Immutable } from '@play-co/replicant';
import { Container, Graphics, ITextStyle, NineSlicePlane, Point, Sprite, Texture } from 'pixi.js';

import { pixiGetScene, pixiSetInterval } from '../../../lib/pixi/pixiTools';
import {
    uiAlignCenter,
    uiAlignCenterX,
    uiCreateMask,
    uiCreateQuad,
    uiSizeToFit,
    uiSizeToWidth,
} from '../../../lib/pixi/uiTools';
import { tween } from '../../../lib/util/tweens';
import { getTaskCompleteCount, isTaskFullyCompleted, isTaskStepsCompleted } from '../../../replicant/components/task';
import task, { Task } from '../../../replicant/defs/task';
import { timeFormatCountdown, timeToComponents } from '../../../replicant/util/timeTools';
import { HomeSequenceFlow } from '../../flows/HomeSequenceFlow';
import { PuzzlePlayFlow } from '../../flows/PuzzlePlayFlow';
import { TaskCompleteFlow } from '../../flows/TaskCompleteFlow';
import { TaskMainFlow } from '../../flows/TaskMainFlow';
import { TaskStepFlow } from '../../flows/TaskStepFlow';
import { LayoutScreen2 } from '../../lib/screens/LayoutScreen2';
import { ImageButton } from '../../lib/ui/buttons/ImageButton';
import { TextImageButton } from '../../lib/ui/buttons/TextImageButton';
import { BasicText } from '../../lib/ui/text/BasicText';
import StarComponent from '../home/StarComponent';
import TaskItem from './TaskItem';
import app from '../../getApp';
import { HomeScreen } from '../home/HomeScreen';

const actionStyle = {
    fill: '#FFF',
    fontSize: 32,
    fontWeight: 'bold',
    lineJoin: 'round',
    align: 'left',
    dropShadow: true,
    dropShadowColor: 0x0,
    dropShadowAngle: Math.PI / 2,
    dropShadowAlpha: 0.3,
    dropShadowDistance: 3,
} as Partial<ITextStyle>;

// types
//-----------------------------------------------------------------------------
export type TaskScreenOptions = {
    stateTask: Task;
    forcedStarAmount?: number;
    pauseProgress?: boolean;
};

type ActionState = 'stepsProgress' | 'stepsFinished' | 'active' | 'completed' | 'maxLevel';

// manifest
//-----------------------------------------------------------------------------
const manifest = {
    topFrame: 'frame.top.blue.dark.png',
    buttonMain: 'button.main.orange2.png',
    buttonFinalAction: 'button.main.green.png',
    buttonBack: 'button.back.png',
    todo: 'panel.item.todo.png',
    default: 'panel.item.default.png',

    timer: 'icon.timer.png',
    badge: 'icon.badge.png',
};

export class TaskScreen extends LayoutScreen2 {
    // events
    //-------------------------------------------------------------------------
    // scene
    private _bg: Sprite;
    private _bgMask: Graphics;
    private _progressLabel: BasicText;
    private _progressContainer: Graphics;
    private _tasks: TaskItem[];
    private _actionView: Container;

    private _actionState: ActionState;
    private _actionTimer: BasicText;
    private _completeStepCount: number;
    private _backButton: ImageButton;
    private _starView: StarComponent;

    private _taskTapped = false;
    private _isPausedProgress = false;

    public get tasks(): TaskItem[] {
        return this._tasks;
    }

    public get backButton() {
        return this._backButton;
    }

    public get starView() {
        return this._starView;
    }

    public get isPausedProgress() {
        return this._isPausedProgress;
    }

    public set isPausedProgress(value: boolean) {
        this._isPausedProgress = value;
    }

    // impl
    //-------------------------------------------------------------------------
    public preload(options: TaskScreenOptions) {
        const taskLevel = options.stateTask.level;
        return [
            ...app().resource.loadAssets([
                ...Object.values(manifest),
                ...StarComponent.assets(),
                ...this.getDynamicAssets(taskLevel),
            ]),
        ];
    }

    public async spawning(options: TaskScreenOptions) {
        void this.addOrientationListener();

        this._tasks = [];
        this._actionState = null;
        this._isPausedProgress = !!options?.pauseProgress;
        this._completeStepCount = -1; // make sure its different to any number real number larger than 0

        // spawn scene
        void this._spawn(options);

        this._bgMask = uiCreateMask(this._bg.width, this._bg.height);
        this._bg.mask = this._bgMask;
        this._bg.addChild(this._bgMask);

        if (options.forcedStarAmount !== undefined) {
            this._starView.forceUpdateAmount(options.forcedStarAmount);
        }

        // predictive preloading
        if (app().game.player.stars > 0 || isTaskStepsCompleted(options.stateTask, app().server.now())) {
            const level = options.stateTask.level;
            // if a next level
            void app().nav.preload('taskCompleteScreen', { level });
        } else {
            // not possible to go to next level, only preload no stars popup
            void app().nav.preload('notEnoughStarsPopup');
        }
    }

    public despawned() {
        this.empty();
    }

    public updateProgress(stateTask: Immutable<Task>, animated: boolean) {
        const now = app().server.now();
        const completeCount = getTaskCompleteCount(stateTask, now);

        const total = stateTask.steps.length;
        if (stateTask.timestamp === 0) {
            const label = `${completeCount}/${total}`;
            if (isTaskStepsCompleted(stateTask, now)) {
                if (this._actionState !== 'stepsFinished') {
                    this._completeStepCount = completeCount;
                    // spawn trigger button
                    // override animation for better transition from transparent to normal
                    this._updateActionView(stateTask, false);
                    const progress = task.taskList[stateTask.level].actionFinalSteps(label);
                    this._spawnProgressLabel(progress);
                }
            } else {
                if (this._actionState !== 'stepsProgress') {
                    this._updateActionView(stateTask, animated);
                }

                if (this._completeStepCount !== completeCount) {
                    this._completeStepCount = completeCount;
                    const progress = task.taskList[stateTask.level].actionCurrentSteps(label);
                    this._spawnProgressLabel(progress);
                    uiAlignCenterX(this._progressContainer, this._progressLabel);
                }
            }
        } else if (stateTask.timestamp === -1) {
            // TODO RENDER COMPLETE SET AT MAX LEVEL IF ALLOWED TO OPEN TASKCREEN
            if (this._actionState === 'maxLevel') return;

            this._updateActionView(stateTask, animated);
            this._spawnProgressLabel('Max Level Reached!');
        } else {
            if (isTaskFullyCompleted(stateTask, now)) {
                if (this._actionState === 'completed') return;
                // spawn final button claim/notify etc
                this._updateActionView(stateTask, animated);
                this._spawnProgressLabel(task.taskList[stateTask.level].actionComplete);
            } else {
                if (this._actionState !== 'active') {
                    this._actionState = 'active';
                    this._spawnProgressLabel(task.taskList[stateTask.level].actionProgress);
                    this._updateActionView(stateTask, animated);
                }

                const now = app().server.now();
                const finishTime = stateTask.timestamp + task.taskList[stateTask.level].completeTime;
                const timeLeft = finishTime - now;
                this._actionTimer.text = timeFormatCountdown(timeToComponents(timeLeft));
                uiSizeToWidth(this._actionTimer, 104);
                uiAlignCenter(this._actionView, this._actionTimer, 20, -2);
            }
        }
    }

    // private: scene
    //-------------------------------------------------------------------------
    private async _spawn(options: TaskScreenOptions) {
        this.root.sortableChildren = true;
        const title = task.taskList[options.stateTask.level].title;

        this._bg = Sprite.from(task.taskList[options.stateTask.level].bg);
        this.base.addContent({
            bg: {
                content: this._bg,
                styles: {
                    position: 'center',
                    minHeight: '100%',
                },
            },
            header: {
                content: this._createHeader(title),
                styles: {
                    position: 'topCenter',
                },
            },
            tasks: {
                content: this._createTasks(options.stateTask),
                styles: {
                    position: 'center',
                    maxHeight: '75%',
                    marginTop: -100,
                },
            },
        });

        this._spawnProgress(options.stateTask);
        pixiSetInterval(this.base, () => this._updateSteps(), 1);
    }

    public async starDecreaseAnimation(opts: { animationDuration?: number; target: Point }) {
        const { animationDuration, target } = opts;
        const duration = animationDuration ?? 0.9;
        const star = Sprite.from(StarComponent.starAsset());
        const scale = 1;
        star.anchor.set(0.5, 0.5);
        const from = this.starView.icon.toGlobal({ x: star.width * 0.5, y: star.height * 0.5 });
        star.scale.set(0);
        // scale down for animation after getting width and height
        star.position.set(from.x, from.y);
        // get root to make star to be on top of everything
        const root = pixiGetScene();
        root.addChild(star);

        const targetX = target.x + star.width;
        const targetY = target.y + star.height;

        star.animate()
            .set(star.scale, { x: 0, y: 0 })
            .add(star.scale, { x: scale, y: scale }, 0.22, tween.backOut(2.2));
        star.animate().add(star, { x: targetX }, duration, tween.pow2InOut);

        await star.animate().add(star, { y: targetY }, duration, tween.pow2InOut).promise();
        star.destroy();
    }

    private _updateSteps() {
        this.tasks.forEach((task) => task.updateTimer(true));
        if (!this._isPausedProgress) {
            this.updateProgress(app().server.state.task, true);
        }
    }

    private _spawnProgress(stateTask: Immutable<Task>) {
        this._progressContainer = uiCreateQuad(0x0, 0.001, 900, 40);
        this.updateProgress(stateTask, false);
        this.base.addContent({
            description: {
                content: this._progressContainer,
                styles: {
                    position: 'bottomCenter',
                    marginBottom: 250,
                },
            },
        });
    }

    private _spawnProgressLabel(text: string) {
        if (this._progressLabel) {
            this._progressLabel.removeSelf();
            this._progressLabel = null;
        }

        this._progressLabel = new BasicText({
            text,
            style: {
                fill: '#FFF',
                fontSize: 30,
                lineJoin: 'round',
                align: 'left',
                dropShadow: true,
                dropShadowColor: 0x0,
                dropShadowAngle: Math.PI / 2,
                dropShadowAlpha: 0.3,
                dropShadowDistance: 3,
            },
        });
        this._progressContainer.addChild(this._progressLabel);
        uiAlignCenterX(this._progressContainer, this._progressLabel);
    }

    //  timer, timed step button or final button
    private _spawnActionView(stateTask: Immutable<Task>) {
        const isTimed = task.taskList[stateTask.level].completeTime > 0;
        if (isTimed) {
            if (stateTask.timestamp === 0) {
                const mainButton = new ImageButton({
                    image: manifest.buttonMain,
                    slice: {
                        left: 25,
                        right: 25,
                        width: 310,
                        height: 100,
                    },
                });

                mainButton.onPress = this._onMainAction.bind(this);

                const badge = Sprite.from(manifest.badge);
                const timer = Sprite.from(manifest.timer);
                badge.addChild(timer);
                timer.x = 4;
                timer.y = 1;

                badge.scale.set(0.7);
                badge.x = 164;
                badge.y = 24;

                // extra container to render badge independely from button
                const buttonContainer = new Container();
                buttonContainer.addChild(mainButton);
                buttonContainer.scale.set(1.1);
                if (isTaskStepsCompleted(stateTask, app().server.now())) {
                    this._actionState = 'stepsFinished';
                    mainButton.addChild(badge);
                } else {
                    this._actionState = 'stepsProgress';
                    buttonContainer.addChild(badge); // keep color of badge
                    badge.alpha = 0.45;
                    mainButton.enabled = false;
                    mainButton.alpha = 0.45;
                }

                const buttonLabel = new BasicText({
                    text: task.taskList[stateTask.level].actionButton1,
                    style: actionStyle,
                });

                const parsedTime = timeFormatCountdown(
                    timeToComponents(task.taskList[stateTask.level].completeTime),
                    1,
                );
                const buttonTime = new BasicText({
                    text: parsedTime,
                    style: {
                        fill: '#FFF',
                        fontSize: 24,
                        fontWeight: 'bold',
                        lineJoin: 'round',
                        align: 'left',
                        dropShadow: true,
                        dropShadowColor: 0x0,
                        dropShadowAngle: Math.PI / 2,
                        dropShadowAlpha: 0.3,
                        dropShadowDistance: 3,
                    },
                });

                mainButton.button.addChild(buttonLabel, buttonTime);
                buttonLabel.x = 45;
                buttonLabel.y = 22;
                buttonTime.y = 26;
                buttonTime.x = 210;

                this._actionView = buttonContainer;
            } else {
                if (isTaskFullyCompleted(stateTask, app().server.now())) {
                    this._actionState = 'completed';
                    const completeButton = new TextImageButton({
                        text: task.taskList[stateTask.level].actionButton2,
                        y: -6,
                        image: manifest.buttonFinalAction,
                        style: actionStyle,
                        slice: {
                            left: 25,
                            right: 25,
                            width: 310,
                            height: 100,
                        },
                    });

                    completeButton.scale.set(1.1);
                    completeButton.onPress = this._onComplete.bind(this);
                    this._actionView = completeButton;
                } else if (stateTask.timestamp === -1) {
                    // Claimed and max level
                    this._actionState = 'maxLevel';
                    // TODO ADD NEXT BUTTON OR ADD NEW TASKS IF NEW AVALABLE
                    // if (isTaskMaxLevel(stateTask))
                } else {
                    this._actionState = 'active';
                    this._actionView = this._createTimer();
                    this._actionView.y -= 30;
                }
            }
        } else {
            if (stateTask.timestamp === -1) {
                this._actionState = 'maxLevel';
            } else {
                // Not timed step, only requirement are task step completion
                const completeButton = new TextImageButton({
                    text: task.taskList[stateTask.level].actionButton1,
                    y: -6,
                    image: manifest.buttonFinalAction,
                    style: actionStyle,
                    slice: {
                        left: 25,
                        right: 25,
                        width: 310,
                        height: 100,
                    },
                });

                completeButton.scale.set(1.1);
                completeButton.onPress = this._onComplete.bind(this);
                if (isTaskStepsCompleted(stateTask, app().server.now())) {
                    this._actionState = 'completed';
                } else {
                    this._actionState = 'stepsProgress';
                    completeButton.enabled = false;
                    completeButton.alpha = 0.45;
                }
                this._actionView = completeButton;
            }
        }

        return this._actionView;
    }

    private _updateActionView(taskState: Immutable<Task>, animated: boolean) {
        this.base.removeChildByID('actionView');
        this._actionView = null; // clear rerefence once removed from scene
        const actionView = this._spawnActionView(taskState);
        // no view rendering at max level, do a check before adding to scene
        if (actionView) {
            this.base.addContent({
                actionView: {
                    content: actionView,
                    styles: {
                        position: 'bottomCenter',
                        marginBottom: this._actionState === 'active' ? 108 : 100,
                    },
                },
            });
        }
        if (animated) {
            if (animated) {
                actionView.alpha = 0;
                actionView.animate().add(actionView, { alpha: 1 }, 0.25, tween.pow2Out);
            }
        }
    }

    private _createTimer() {
        const badge = Sprite.from(manifest.badge);
        const timerIcon = Sprite.from(manifest.timer);
        badge.addChild(timerIcon);
        timerIcon.x = 4;
        timerIcon.y = 1;

        badge.x = 3;
        badge.y = -11;
        const timerFrame = new Graphics();

        timerFrame.beginFill(0x0, 0.25);
        timerFrame.drawRoundedRect(0, 0, 300, 96, 30);
        timerFrame.endFill();

        timerFrame.addChild(badge);
        uiAlignCenter(timerFrame, badge, -70, -3);

        this._actionTimer = new BasicText({
            text: ``,
            style: actionStyle,
        });

        timerFrame.addChild(this._actionTimer);

        return timerFrame;
    }

    private _createHeader(title: string) {
        const header = new NineSlicePlane(Texture.from(manifest.topFrame), 14, 0, 14, 0);
        this._starView = new StarComponent();
        header.width = 900;

        const back = (this._backButton = new ImageButton({
            image: manifest.buttonBack,
        }));

        back.scale.set(1.04);

        back.onPress = this._onBack.bind(this);

        const titlePanel = new BasicText({
            text: title,
            style: {
                fill: '#FFF',
                fontSize: 36,
                fontWeight: 'bold',
                lineJoin: 'round',
                align: 'center',
                dropShadow: true,
                dropShadowColor: 0x0,
                dropShadowAngle: Math.PI / 2,
                dropShadowAlpha: 0.3,
                dropShadowDistance: 3,
            },
        });

        uiSizeToFit(titlePanel, 320, 80);
        uiAlignCenter(header, titlePanel, 0, -10);

        header.addChild(back, this._starView, titlePanel);
        uiAlignCenter(header, back, -318, -4);
        uiAlignCenterX(header, this._starView, 262);
        this._starView.y = 13;

        return header;
    }

    private _createTasks(stateTask: Immutable<Task>) {
        const defaultX = 0;
        let y = 0;
        let x = defaultX;

        const taskContainer = new Container();
        let taskRow = new Container();

        const level = stateTask.level;
        for (let i = 1; i <= task.taskList[level].steps.length; i++) {
            const timestamp = stateTask.steps[i - 1].timestamp;
            const task = new TaskItem({
                timestamp,
                level,
                stepIndex: i - 1,
                onPress: async (stepIndex: number) => this._onTask(stepIndex),
            });

            taskRow.addChild(task);
            task.x = x;
            task.y = y;

            if (i % 3 === 0) {
                taskContainer.addChild(taskRow);
                uiAlignCenterX(taskContainer, taskRow);
                taskRow = new Container();
                y += 380;
                x = defaultX;
            } else {
                x += 248;
            }

            this._tasks.push(task);
        }

        // add last row if it ended with less than 3 tasks
        if (taskRow.children.length > 0) {
            taskContainer.addChild(taskRow);
            uiAlignCenterX(taskContainer, taskRow);
        }

        return taskContainer;
    }

    private async _onBack() {
        const homeScreen = (await app().nav.open('homeScreen')) as HomeScreen;
        await new HomeSequenceFlow({ homeScreen }).execute();
    }

    private async _onTask(stepIndex: number) {
        // only allow one tap at a time to avoid breaking buttons when a player spams multiple button at the same time
        if (this._taskTapped) return;

        this._taskTapped = true;
        const { goToPuzzle } = await new TaskStepFlow({ screen: this, stepIndex }).execute();
        // not enough stars and player decided to abort and play for more stars
        if (goToPuzzle) {
            await new PuzzlePlayFlow({}).execute();
        }

        this._taskTapped = false;
    }

    private getDynamicAssets(taskLevel: number): string[] {
        return [...TaskItem.assets({ taskLevel }), task.taskList[taskLevel].bg];
    }

    private async _onMainAction() {
        await new TaskMainFlow({ screen: this }).execute();
    }

    private async _onComplete() {
        await new TaskCompleteFlow().execute();
    }

    // Use if we need scroll. For example more than 6 items and we need to adjust scaling + scroll
    // private _createTaskScroll(stateTask: Immutable<Task>) {
    //     const scrollWidth = pixiConfig.size.width + 30;
    //     const taskScroll = new ScrollBox({
    //         direction: 'down',
    //         width: scrollWidth,
    //         height: app().stage.canvas.height, // Use % in the base add content
    //     });

    //     const pad = 40;
    //     const topScrollPadding = uiCreateQuad(0x0, 0.001, scrollWidth, pad * 2);
    //     const botScrollPadding = uiCreateQuad(0x0, 0.001, scrollWidth, pad);

    //     const defaultX = 0;
    //     let y = topScrollPadding.height;
    //     let x = defaultX;
    //     taskScroll.content.addChild(topScrollPadding);
    //     let taskRow = new Container();

    //     const level = stateTask.level;
    //     for (let i = 1; i <= task.taskList[level].steps.length; i++) {
    //         const timestamp = stateTask.steps[i - 1].timestamp;
    //         const task = new TaskItem({ timestamp, level, stepIndex: i - 1, onPress: this._onTask.bind(this) });

    //         taskRow.addChild(task);
    //         task.x = x;
    //         task.y = y;

    //         if (i % 3 === 0) {
    //             taskScroll.content.addChild(taskRow);
    //             uiAlignCenterX(taskScroll, taskRow);
    //             taskRow = new Container();
    //             y += 380;
    //             x = defaultX;
    //         } else {
    //             x += 240;
    //         }
    //     }

    //     // add last row if it ended with less than 3 tasks
    //     if (taskRow.children.length > 0) {
    //         taskScroll.content.addChild(taskRow);
    //         uiAlignCenterX(taskScroll, taskRow);
    //     }

    //     taskScroll.content.addChild(botScrollPadding);

    //     botScrollPadding.y = y - botScrollPadding.height;
    //     taskScroll.content.addChild(botScrollPadding);
    //     return taskScroll;
    // }
}
