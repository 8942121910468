import { ScrollBox } from '@pixi/ui';
import { waitAFrame } from '@play-co/astro';
import type { Container } from 'pixi.js';
import { Rectangle } from 'pixi.js';
import { Signal } from 'typed-signals';

export type Fn<T extends any[], R = any> = (...args: T) => R;

export interface LoadableItem {
    view?: Container;
    getLoadableItems?: () => LoadableContainer[];
    load?: () => void;
}

type LoadableContainer = (Container | { view?: Container }) & { _isInViewport?: boolean } & LoadableItem;

const scrollerBounds = new Rectangle();
const itemBounds = new Rectangle();

export class LazyLoadingScrollBox extends ScrollBox {
    public static readonly MAX_DISTANCE = 500;
    public readonly onItemVisibilityChanged = new Signal<Fn<[LoadableItem, boolean]>>();
    private _lastScrollX!: number | null;
    private _lastScrollY!: number | null;

    protected override update(): void {
        super.update();

        // If the scrollbox has left the scene, reset the last scroll position
        // so that visibility is checked when it re-enters the scene.
        if (!this.inScene()) {
            this._lastScrollX = this._lastScrollY = null;
            this.items.forEach((item) => this._checkLoadableItems(item, false));
            return;
        }

        if (this._trackpad.x !== this._lastScrollX || this._trackpad.y !== this._lastScrollY) {
            /**
             * Wait a frame to ensure that the transforms of the scene graph are up-to-date.
             * Since we are skipping this step on the 'getBounds' calls for performance's sake,
             * this is necessary to ensure that the bounds are accurate.
             */
            void waitAFrame().then(() => this.items.forEach((item) => this._checkLoadableItems(item, true)));
            this._lastScrollX = this._trackpad.x;
            this._lastScrollY = this._trackpad.y;
        }
    }

    public override resize(force?: boolean): void {
        super.resize(force);
        this._lastScrollX = null;
        this._lastScrollY = null;
    }

    private _checkLoadableItems(item: LoadableContainer, isInScene: boolean): void {
        if (isInScene) {
            /**
             * Get the loadable item bounds, capping the width and height to at least 1
             * for the purposes of intersection checking.
             */
            const view = (item.view ?? item) as Container;
            view.getBounds(true, itemBounds);
            itemBounds.width = Math.max(itemBounds.width, 1);
            itemBounds.height = Math.max(itemBounds.height, 1);
            this._checkViewportVisibility(item);
        } else {
            this._setInViewport(item, false);
        }

        item.getLoadableItems?.().forEach((subItem) => this._checkLoadableItems(subItem, isInScene));
    }

    private _checkViewportVisibility(item: LoadableContainer) {
        // Get the scroller bounds and check for intersection
        this.borderMask.getBounds(true, scrollerBounds);
        this._setInViewport(item, scrollerBounds.intersects(itemBounds));

        if (item.load) {
            // Expand scroller bounds by the defined max distance to see if we should begin loading
            scrollerBounds.x -= LazyLoadingScrollBox.MAX_DISTANCE;
            scrollerBounds.y -= LazyLoadingScrollBox.MAX_DISTANCE;
            scrollerBounds.width += LazyLoadingScrollBox.MAX_DISTANCE * 2;
            scrollerBounds.height += LazyLoadingScrollBox.MAX_DISTANCE * 2;
            const inRange = scrollerBounds.intersects(itemBounds);
            if (inRange) item.load();
        }
    }

    private _setInViewport(item: LoadableContainer, isInViewport: boolean) {
        if (!!item._isInViewport !== isInViewport) {
            item._isInViewport = isInViewport;
            this.onItemVisibilityChanged.emit(item, isInViewport);
        }
    }
}
