import type { Component, Entity2D } from '@play-co/odie';

import { DirectionBlockOptionDef, ThrowerBlockProps } from '../defs/block';
import { ThrowerBlockView } from '../views/blocks/ThrowerBlockView';

// types
//-----------------------------------------------------------------------------
export type ThrowerBlockComponentOptions = {
    props: ThrowerBlockProps;
    option?: number;
};

/*
    thrower block component
*/
export class ThrowerBlockComponent implements Component {
    // constants
    //-------------------------------------------------------------------------
    static readonly NAME = 'blockThrower';

    // fields
    //-------------------------------------------------------------------------
    // injected
    public entity!: Entity2D;
    // scene
    public view!: ThrowerBlockView;

    // impl
    //-------------------------------------------------------------------------
    public init(options: ThrowerBlockComponentOptions): void {
        // get option def
        const optionDef = options.props.options[options.option || 0] as DirectionBlockOptionDef;

        // create view
        this.view = new ThrowerBlockView(options.props);
    }
}
