import NakedPromise from '../../../lib/pattern/NakedPromise';
import { textLocaleFormat } from '../../../lib/util/textTools';
import { GameId } from '../../../replicant/defs/gameDay';
import { sleep } from '../../../replicant/util/jsTools';
import app from '../../getApp';
import { trackGameDayStep } from '../../lib/analytics/gameDay';
import { GameDayPlayer, GameDayScreen } from '../../main/home/GameDayScreen';
import { GameDayFlow, GameDayFlowOpts, ScoreData } from './GameDayFlow';

const scoreboardData: ScoreData[] = [
    {
        balls: 2,
        strikes: 2,
        outs: 2,
        visitorData: ['2', '1', '1', '0'],
        homeData: ['0', '1', '2', '0'],
        visitorR: 4,
        homeR: 3,
    },
    {
        balls: 2,
        strikes: 2,
        outs: 2,
        visitorData: ['2', '1', '1', '0', '0', '0', '2', '1'],
        homeData: ['0', '1', '2', '0', '3', '0', '1', '0'],
        visitorR: 7,
        homeR: 7,
    },
    {
        balls: 0,
        strikes: 0,
        outs: 0,
        visitorData: ['2', '1', '1', '0', '0', '0', '2', '1', '0'],
        homeData: ['0', '1', '2', '0', '3', '0', '1', '0', '1'],
        visitorR: 7,
        homeR: 8,
    },
];

export class GameDay2Flow extends GameDayFlow {
    // fields
    //-------------------------------------------------------------------------

    // init
    //-------------------------------------------------------------------------
    constructor(opts: GameDayFlowOpts) {
        super(opts);
        this._visitor = textLocaleFormat('[gameDay2Opponent]');
        this._home = app().server.state.name;
        this._opponent = opts.opponentOverride ?? textLocaleFormat('[gameDay2Opponent]');
        this._scoreboard = 'board0';
        this._gameDaySteps = [
            { handler: this._intro, substeps: this._introSubSteps },
            { handler: this._step1, substeps: 1 },
            { handler: this._step2, substeps: 2 },
            { handler: this._step3, substeps: 1 },
            { handler: this._step4, substeps: 1 },
            { handler: this._step5, substeps: 3 },
            { handler: this._step6, substeps: 1 },
            { handler: this._stepEnding, substeps: this._endingSubSteps },
        ];
    }

    // impl
    //-------------------------------------------------------------------------
    private async _intro() {
        await this._baseIntro({
            scoreData: scoreboardData[0],
            tapDialog: `[gameDay2Step0|${this._home}]`,
            nextBgId: 'gameDay0',
        });
    }

    private async _step1() {
        let stepIndex = this._stepOffset[this._step];

        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay0',
            players: [
                {
                    skin: 'character3_boy_red',
                    clip: 'onbase1_flipped',
                    loop: true,
                } as GameDayPlayer,
            ],
        })) as GameDayScreen;
        void app().nav.preload('gameDayScreen', { bgId: 'gameDay1' });
        await sleep(1.25);
        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay1',
            players: [
                {
                    skin: 'character6_girl_red2',
                    clip: 'onbase3',
                    loop: true,
                } as GameDayPlayer,
            ],
        })) as GameDayScreen;
        void app().nav.preload('gameDayScreen', { bgId: 'thirdBase' });
        await sleep(1.25);
        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'thirdBase',
            players: [
                {
                    skin: 'character3_boy_red',
                    clip: 'onbase1',
                    loop: true,
                } as GameDayPlayer,
            ],
        })) as GameDayScreen;
        void app().nav.preload('gameDayScreen', { bgId: 'gameDay3' });
        await sleep(1.25);

        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay3',
            players: [{ skin: 'character1_boy', clip: 'pitcher_idle', loop: true, scaleX: -1 } as GameDayPlayer],
        })) as GameDayScreen;

        void this._gameDayScreen.preloadChoiceView(); // preload choice view for next step

        const tapPromise = new NakedPromise();
        await this._gameDayScreen.spawnBubbleTap({
            dialogText: `[gameDay2Step1]`,
            promise: tapPromise,
            bubbleOffset: { x: 0, y: this._gameDayScreen.base.height * 0.5 - this._gameDayScreen.base.height * 0.22 },
            parentOverride: this._gameDayScreen.base,
        });
        await tapPromise;
        trackGameDayStep({ stepIndex: stepIndex++, stepName: 'intro_players', stepType: 'tap' });
    }

    private async _step2() {
        let stepIndex = this._stepOffset[this._step];
        if (!this._gameDayScreen) {
            this._gameDayScreen = (await app().nav.open('gameDayScreen', {
                bgId: 'gameDay3',
                players: [{ skin: 'character1_boy', clip: 'pitcher_idle', loop: true, scaleX: -1 } as GameDayPlayer],
            })) as GameDayScreen;
        }

        const choicePromise = new NakedPromise<number>();
        void this._gameDayScreen.spawnBubbleChoice({
            dialogText: '[gameDay2Step2]',
            choices: ['[gameDay2Choice0]', '[gameDay2Choice1]'],
            promise: choicePromise,
            bubbleOffset: { x: 0, y: this._gameDayScreen.base.height * 0.5 - this._gameDayScreen.base.height * 0.22 },
            parentOverride: this._gameDayScreen.base,
        });

        void app().nav.preload('gameDayScreen', { bgId: 'gameDay2' });

        void app().resource.loadAsset(this._batSFX);
        const choiceIndex = await choicePromise;

        trackGameDayStep({ stepIndex: stepIndex++, stepName: 'first_choice', stepType: 'choice' });

        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay2',
            players: [
                {
                    skin: 'character3_boy_red',
                    clip: choiceIndex === 0 ? 'batter_swing' : 'batter_foul',
                    loop: false,
                } as GameDayPlayer,
            ],
        })) as GameDayScreen;

        void app().sound.play(this._batSFX);
        void app().nav.preload('gameDayScreen', { bgId: 'gameDay4' });
        void app().resource.loadAsset(this._catchSFX);

        await sleep(choiceIndex === 0 ? 0.7 : 1);

        const choiceMap = [
            {
                players: [
                    {
                        skin: 'character2_girl',
                        clip: 'baseman_catch',
                        loop: false,
                    } as GameDayPlayer,
                ],
                dialog: '[gameDay2Step3sub0]',
            },
            {
                players: [
                    {
                        skin: 'character5_boy',
                        clip: 'fielder_catch',
                        loop: false,
                    } as GameDayPlayer,
                ],
                dialog: '[gameDay2Step3sub1]',
            },
        ];

        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay4',
            players: choiceMap[choiceIndex].players,
        })) as GameDayScreen;

        void app().sound.play(this._catchSFX);

        let delayAnimationPromise: NakedPromise;
        if (choiceIndex === 0) {
            delayAnimationPromise = new NakedPromise();
            void sleep(1.5).then(() => {
                void this._gameDayScreen.playPlayerAnimation({ playerIndex: 0, id: 'baseman_catch_idle', loop: true });
                delayAnimationPromise.resolve();
            });
        } else if (choiceIndex === 1) {
            void app().nav.preload('gameDayScreen', { bgId: 'gameDay5' });
            await sleep(0.75);
            this._gameDayScreen = (await app().nav.open('gameDayScreen', {
                bgId: 'gameDay5',
                players: [
                    {
                        skin: 'character1_boy',
                        clip: 'laugh',
                        loop: true,
                    } as GameDayPlayer,
                ],
            })) as GameDayScreen;
        }

        const tapPromise = new NakedPromise();
        await this._gameDayScreen.spawnBubbleTap({
            dialogText: choiceMap[choiceIndex].dialog,
            promise: tapPromise,
            bubbleOffset: { x: 0, y: this._gameDayScreen.base.height * 0.5 - this._gameDayScreen.base.height * 0.22 },
            parentOverride: this._gameDayScreen.base,
        });
        await tapPromise;
        trackGameDayStep({ stepIndex: stepIndex++, stepName: 'first_choice_made', stepType: 'tap' });

        await delayAnimationPromise;
    }

    private async _step3() {
        let stepIndex = this._stepOffset[this._step];
        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay2',
            players: [
                {
                    skin: 'character3_boy_red',
                    clip: 'batter_sad',
                    loop: true,
                } as GameDayPlayer,
            ],
        })) as GameDayScreen;

        const sadPromise = new NakedPromise();
        await this._gameDayScreen.spawnBubbleTap({
            dialogText: `[gameDay2Step4]`,
            promise: sadPromise,
            narrator: 'arrow',
            bubbleOffset: { x: 0, y: this._gameDayScreen.base.height - 150 },
            parentOverride: this._gameDayScreen.base,
        });

        void this._gameDayScreen.preloadScoreboard(this._scoreboard);
        await sadPromise;

        trackGameDayStep({ stepIndex: stepIndex++, stepName: 'sad', stepType: 'tap' });
    }

    private async _step4() {
        let stepIndex = this._stepOffset[this._step];
        // do a transition for score panel as well (part of feedback)
        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay2',
        })) as GameDayScreen;

        void this._gameDayScreen.spawnScoreDynamic({
            ...scoreboardData[1],
            visitor: this._visitor,
            home: this._home,
            scoreboard: this._scoreboard,
        });

        const scorePromise = new NakedPromise();

        await this._gameDayScreen.spawnBubbleTap({
            dialogText: `[gameDay2Step5]`,
            promise: scorePromise,
            narrator: 'arrow',
            bubbleOffset: { x: 0, y: this._gameDayScreen.base.height - 150 },
            parentOverride: this._gameDayScreen.base,
        });

        void app().nav.preload('gameDayScreen', { bgId: 'gameDay0' });
        await scorePromise;

        trackGameDayStep({ stepIndex: stepIndex++, stepName: 'run_score', stepType: 'tap' });

        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay0',
            players: [
                {
                    skin: 'character3_boy_red',
                    offsetX: 60,
                    offsetY: -4,
                    scale: 0.81,
                    clip: 'fielder_idle',
                    loop: true,
                } as GameDayPlayer,
                {
                    skin: 'character4_girl',
                    offsetX: -115,
                    offsetY: 60,
                    scale: 0.81,
                    clip: 'onbase1_flipped',
                    loop: true,
                } as GameDayPlayer,
            ],
        })) as GameDayScreen;

        void app().resource.loadAsset(this._batSFX);
        void app().nav.preload('gameDayScreen', { bgId: 'gameDay2', speedlines: true });
        await sleep(1.25); // pause before going to next scene

        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay2',
            players: [{ skin: 'character1_boy', clip: 'batter_homerun', loop: false } as GameDayPlayer],
        })) as GameDayScreen;
        void app().sound.play(this._batSFX);
        await sleep(1.1); // pause before going to next scene
    }

    private async _step5() {
        let stepIndex = this._stepOffset[this._step];
        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay2',
            speedlines: true,
            players: [
                {
                    skin: 'character4_girl',
                    clip: 'run',
                    loop: true,
                    scaleX: -1,
                } as GameDayPlayer,
            ],
        })) as GameDayScreen;

        void this._gameDayScreen.preloadChoiceView();

        const tapPromise = new NakedPromise();
        await this._gameDayScreen.spawnBubbleTap({
            dialogText: `[gameDay2Step6]`,
            promise: tapPromise,
            bubbleOffset: { x: 0, y: this._gameDayScreen.base.height * 0.5 - this._gameDayScreen.base.height * 0.22 },
            parentOverride: this._gameDayScreen.base,
        });
        await tapPromise;

        trackGameDayStep({ stepIndex: stepIndex++, stepName: 'speedlines', stepType: 'tap' });

        const choicePromise = new NakedPromise<number>();
        void this._gameDayScreen.spawnBubbleChoice({
            dialogText: '[gameDay2Step7]',
            choices: ['[gameDay2Choice2]', '[gameDay2Choice3]'],
            promise: choicePromise,
            bubbleOffset: { x: 0, y: this._gameDayScreen.base.height * 0.5 - this._gameDayScreen.base.height * 0.22 },
            parentOverride: this._gameDayScreen.base,
        });

        const choiceIndex = await choicePromise;

        trackGameDayStep({ stepIndex: stepIndex++, stepName: 'second_choice', stepType: 'choice' });

        const choiceDialog = ['[gameDay2Step8sub0]', '[gameDay2Step8sub1]'];

        if (choiceIndex === 0) {
            void app().resource.loadAsset(this._batSFX);
            this._gameDayScreen = (await app().nav.open('gameDayScreen', {
                bgId: 'gameDay0',
                players: [
                    {
                        skin: 'character3_boy_red',
                        offsetX: 60,
                        offsetY: -4,
                        scale: 0.81,
                        clip: 'pitcher_watchhomerun',
                        loop: false,
                    } as GameDayPlayer,
                    {
                        skin: 'character4_girl',
                        offsetX: -115,
                        offsetY: 60,
                        scale: 0.81,
                        clip: 'onbase1_flipped',
                        loop: true,
                    } as GameDayPlayer,
                ],
            })) as GameDayScreen;

            await sleep(1.1);
            void this._gameDayScreen.playPlayerAnimation({
                playerIndex: 0,
                id: 'pitcher_watchhomerun_idle',
                loop: true,
            });
        }

        const tapPromise2 = new NakedPromise();
        await this._gameDayScreen.spawnBubbleTap({
            dialogText: choiceDialog[choiceIndex],
            promise: tapPromise2,
            bubbleOffset: { x: 0, y: this._gameDayScreen.base.height * 0.5 - this._gameDayScreen.base.height * 0.22 },
            parentOverride: this._gameDayScreen.base,
        });
        await tapPromise2;
        trackGameDayStep({ stepIndex: stepIndex++, stepName: 'second_choice_made', stepType: 'tap' });

        if (choiceIndex === 0) {
            this._gameDayScreen = (await app().nav.open('gameDayScreen', {
                bgId: 'gameDay2',
                players: [{ skin: 'character3_boy', clip: 'batter_homerun', loop: false } as GameDayPlayer],
            })) as GameDayScreen;
            void app().sound.play(this._batSFX);
            await sleep(1.1);
        } else if (choiceIndex === 1) {
            void this._gameDayScreen.horizontalAnimation();
            await sleep(0.75);
        }
    }

    private async _step6() {
        let stepIndex = this._stepOffset[this._step];
        // girl run
        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay2',
            players: [
                {
                    skin: 'character4_girl',
                    clip: 'run',
                    offsetX: 200,
                    offsetY: 40,
                    loop: true,
                    scaleX: -1,
                    animateRun: true,
                } as GameDayPlayer,
            ],
        })) as GameDayScreen;

        void app().sound.play(this._cheerSFX);

        const runPromise = new NakedPromise();
        await this._gameDayScreen.spawnBubbleTap({
            dialogText: `[gameDay2Step9]`,
            promise: runPromise,
            narrator: 'arrow',
            bubbleOffset: { x: 0, y: this._gameDayScreen.base.height - 150 },
            parentOverride: this._gameDayScreen.base,
        });
        await runPromise;

        void app().nav.preload('gameDayScreen', { bgId: 'gameDay0' });

        trackGameDayStep({ stepIndex: stepIndex++, stepName: 'run', stepType: 'tap' });
    }

    private async _stepEnding() {
        await this._baseVictory({ scoreData: scoreboardData[2], tapDialog: `[gameDay2Step10]` });
    }
}
