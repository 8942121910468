import { FlexContainer } from '@play-co/flex';
import { Container, TextStyle } from 'pixi.js';

import { BasicAsyncHandler } from '../../../lib/defs/types';
import { uiAlignCenter, uiCreateQuad } from '../../../lib/pixi/uiTools';
import gameConfig from '../../../replicant/defs/gameConfig';
import { LanguageId } from '../../../replicant/defs/settings';
import { TextButton } from '../../lib/ui/buttons/TextButton';
import { TextImageButton } from '../../lib/ui/buttons/TextImageButton';
import { TextSliceCheckbox } from '../../lib/ui/buttons/TextSliceCheckbox';
import { BasicText } from '../../lib/ui/text/BasicText';
import { BasicPopup } from './BasicPopup';
import app from '../../getApp';

const BUTTON_LABEL_Y = -5;

// types
//-----------------------------------------------------------------------------
export type SettingsPopupOptions = {
    bgm: boolean;
    sfx: boolean;
    language: LanguageId;
    isGuest: boolean;
    onClose: BasicAsyncHandler;
};

// constants
//-----------------------------------------------------------------------------
const manifest = {
    buttonGrey: 'button.grey.png',
    buttonBlue: 'button.blue.png',
    buttonRed: 'button.red.png',
};

/*
    settings popup
*/
export class SettingsPopup extends BasicPopup {
    // fields
    // expose all buttons so the flow can attach on press handlers
    public buttonEnableBGM: TextSliceCheckbox;
    public buttonDisableBGM: TextSliceCheckbox;
    public buttonEnableSFX: TextSliceCheckbox;
    public buttonDisableSFX: TextSliceCheckbox;
    public buttonJapanese: TextSliceCheckbox;
    public buttonEnglish: TextSliceCheckbox;
    public buttonSupport: TextImageButton;
    public buttonTos: TextButton;
    public buttonPrivacy: TextButton;
    // guest has delete and logout button in main settings while logged in players have it in the line faq
    public buttonDelete?: TextImageButton;
    public buttonLogout?: TextImageButton;
    //-------------------------------------------------------------------------
    // scene

    // properties
    //-------------------------------------------------------------------------

    // impl
    //-------------------------------------------------------------------------
    public override preload() {
        return [...super.preload(), ...app().resource.loadAssets(Object.values(manifest))];
    }

    // @ts-ignore
    public override async spawning(options: SettingsPopupOptions) {
        void super.spawning({
            header: '[popupSettingsTitle]',
            width: 600,
            height: 760,
            ...options,
        });

        const bgmView = this._createRow('bgm');
        const sfxView = this._createRow('sfx');
        const languageView = this._createRow('language');
        const lineSeparator = this._spawnLine();
        const supportContainer = this._createFaqSupportContainer(options.isGuest);

        // not combined, add delete button in main settings (guest for example)
        let guestContainer = null;
        if (options.isGuest) {
            // delete and logout
            guestContainer = this._createGuestButtons();
        }
        const tosContainer = this._createTosContainer();

        // spawn
        this.main.addContent({
            bgm: {
                content: bgmView,
                styles: {
                    position: 'topCenter',
                    marginTop: 60,
                    marginLeft: -30,
                },
            },
            sfx: {
                content: sfxView,
                styles: {
                    position: 'topCenter',
                    marginTop: 160,
                    marginLeft: -30,
                },
            },
            line: {
                content: lineSeparator,
                styles: {
                    position: 'topCenter',
                    marginTop: 250,
                },
            },
            language: {
                content: languageView,
                styles: {
                    position: 'topCenter',
                    marginTop: 300,
                    marginLeft: -30,
                },
            },
            support: {
                content: supportContainer,
                styles: {
                    position: 'topCenter',
                    marginTop: options.isGuest ? 385 : 425,
                },
            },
            delete: guestContainer
                ? {
                      content: guestContainer,
                      styles: {
                          position: 'topCenter',
                          marginTop: 478,
                      },
                  }
                : null,
            tosContainer: {
                content: tosContainer,
                styles: {
                    position: 'topCenter',
                    marginTop: 595,
                    marginLeft: tosContainer.width * 0.5,
                },
            },
        });

        this.initData(options);
    }

    private initData(opts: { bgm: boolean; sfx: boolean; language: LanguageId }) {
        const { bgm, sfx, language } = opts;
        // sync state
        this.buttonEnableBGM.selected = bgm;
        this.buttonDisableBGM.selected = !bgm;
        this.buttonEnableSFX.selected = sfx;
        this.buttonDisableSFX.selected = !sfx;
        this.buttonEnglish.selected = language === 'en';
        this.buttonJapanese.selected = language === 'ja';
    }

    private _createRow(type: 'bgm' | 'sfx' | 'language') {
        const sliceConst = {
            left: 25,
            right: 25,
            width: 190,
            height: 100,
            top: 0,
            bottom: 0,
        };

        const sliceBlue = {
            sliceUp: manifest.buttonGrey,
            sliceDown: manifest.buttonBlue,
            ...sliceConst,
        };

        const slice = {
            bgm: [sliceBlue, sliceBlue],
            sfx: [sliceBlue, sliceBlue],
            language: [sliceBlue, sliceBlue],
        };

        const label = {
            bgm: '[popupSettingsBGM]',
            sfx: '[popupSettingsSFX]',
            language: '[popupSettingLanguage]',
        };

        const buttonText = {
            bgm: ['[popupSettingsOn]', '[popupSettingsOff]'],
            sfx: ['[popupSettingsOn]', '[popupSettingsOff]'],
            language: ['[popupSettingsJapanese]', '[popupSettingsEnglish]'],
        };

        const container = new Container();
        const text = new BasicText({
            text: label[type],
            style: {
                align: 'left',
                fill: '#3E4047',
                fontSize: 28,
                fontWeight: 'bold',
                lineJoin: 'round',
                wordWrap: false,
            },
        }).props({ x: 35 });

        // button style
        const paddingX = 20;
        const style: Partial<TextStyle> = {
            fill: '#FFFFFF',
            fontSize: 26,
            fontWeight: 'bold',
            lineJoin: 'round',
            wordWrap: false,
        };

        const buttonY = 22;

        const leftButton = new TextSliceCheckbox({
            selected: false,
            text: buttonText[type][0],
            animate: true,
            offsetY: BUTTON_LABEL_Y,
            paddingX,
            slice: slice[type][0],
            style,
        });
        const rightButton = new TextSliceCheckbox({
            text: buttonText[type][1],
            selected: false,
            animate: true,
            offsetY: BUTTON_LABEL_Y,
            paddingX,
            slice: slice[type][1],
            style,
        });

        // change width and leave scale as is to allow for tap normal tap animations
        const scale = 0.85;
        leftButton.width *= scale;
        leftButton.height *= scale;
        rightButton.width *= scale;
        rightButton.height *= scale;
        // re-align after custom scaling
        uiAlignCenter(leftButton, leftButton.text, 0, BUTTON_LABEL_Y);
        uiAlignCenter(rightButton, rightButton.text, 0, BUTTON_LABEL_Y);

        leftButton.props({
            x: 270,
            y: buttonY,
            pivot: { x: leftButton.width * 0.5, y: leftButton.height * 0.5 },
        });

        rightButton.props({
            x: 435,
            y: buttonY,
            pivot: { x: rightButton.width * 0.5, y: rightButton.height * 0.5 },
        });

        const configExecutionMap = {
            bgm: () => {
                this.buttonEnableBGM = leftButton;
                this.buttonDisableBGM = rightButton;
            },
            sfx: () => {
                this.buttonEnableSFX = leftButton;
                this.buttonDisableSFX = rightButton;
            },
            language: () => {
                this.buttonJapanese = leftButton;
                this.buttonEnglish = rightButton;
            },
        };
        configExecutionMap[type]();

        container.addChild(text, leftButton, rightButton);

        return container;
    }

    private _spawnLine() {
        const lineMain = uiCreateQuad(0xb4b4b4, 1, 490, 3);
        return lineMain;
    }

    private _createFaqSupportContainer(isGuest: boolean): Container {
        if (isGuest) {
            const guestSupport = new BasicText({
                text: `[popupSettingSupportGuest|${gameConfig.support.email}]`,
                style: {
                    fill: '#3E4047',
                    align: 'center',
                    fontWeight: 'bold',
                    fontSize: 24,
                    lineJoin: 'round',
                },
            });

            return guestSupport;
        }

        // regular
        this.buttonSupport = new TextImageButton({
            text: '[faqPopupButton]',
            y: BUTTON_LABEL_Y,
            image: manifest.buttonBlue,
            slice: {
                width: isGuest ? 360 : 460,
                height: isGuest ? 84 : 100,
                left: 25,
                right: 25,
                top: 0,
                bottom: 0,
            },
            style: {
                fill: '#FFFFFF',
                fontSize: 26,
                fontWeight: 'bold',
                lineJoin: 'round',
            },
        });

        return this.buttonSupport;
    }

    private _createGuestButtons() {
        const slice = {
            width: 230,
            height: 84,
            left: 25,
            right: 25,
            top: 0,
            bottom: 0,
        };

        const style = {
            fill: '#FFFFFF',
            fontSize: 26,
            fontWeight: 'bold',
            lineJoin: 'round',
        } as Partial<TextStyle>;

        // guest does not need to trigger line faq
        this.buttonDelete = new TextImageButton({
            text: '[deleteAction]',
            y: BUTTON_LABEL_Y,
            image: manifest.buttonRed,
            slice,
            style,
        });
        this.buttonLogout = new TextImageButton({
            text: '[buttonLogout]',
            y: BUTTON_LABEL_Y,
            image: manifest.buttonBlue,
            slice,
            style,
        });

        const container = new FlexContainer({
            justifyContent: 'space-between',
            height: 120,
            width: 490,
            direction: 'row',
        });

        container.addChild(this.buttonDelete, this.buttonLogout);
        container.doLayout();
        return container;
    }

    private _createTosContainer() {
        this.buttonPrivacy = new TextButton({
            animate: true,
            text: '[privacyLink]',
            style: {
                fill: '#0000EE',
                align: 'center',
                fontSize: 24,
                fontWeight: 'bold',
                lineJoin: 'round',
                wordWrap: false,
            },
        });

        this.buttonPrivacy.props({
            x: -110,
            pivot: { x: this.buttonPrivacy.width * 0.5, y: this.buttonPrivacy.height * 0.5 },
        });

        this.buttonTos = new TextButton({
            animate: true,
            text: '[tosTitle]',
            style: {
                fill: '#0000EE',
                align: 'center',
                fontSize: 24,
                fontWeight: 'bold',
                lineJoin: 'round',
                wordWrap: false,
            },
        });
        this.buttonTos.props({
            x: 110,
            pivot: { x: this.buttonTos.width * 0.5, y: this.buttonTos.height * 0.5 },
        });

        const tosContainer = new Container();
        tosContainer.addChild(this.buttonPrivacy, this.buttonTos);
        return tosContainer;
    }
}
