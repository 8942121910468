import NakedPromise from '../../../lib/pattern/NakedPromise';
import { textLocaleFormat } from '../../../lib/util/textTools';
import { tween } from '../../../lib/util/tweens';
import { GameId } from '../../../replicant/defs/gameDay';
import { sleep } from '../../../replicant/util/jsTools';
import app from '../../getApp';
import { trackGameDayStep } from '../../lib/analytics/gameDay';
import { GameDayPlayer, GameDayScreen } from '../../main/home/GameDayScreen';
import { GameDayFlow, GameDayFlowOpts, ScoreData } from './GameDayFlow';

const scoreboardData: ScoreData[] = [
    {
        balls: 0,
        strikes: 2,
        outs: 2,
        visitorData: ['0', '1', '0'],
        homeData: ['0', '1', '2'],
        visitorR: 1,
        homeR: 3,
    },
    {
        balls: 0,
        strikes: 1,
        outs: 1,
        visitorData: ['0', '1', '0', '0', '2', '2', '0', '2'],
        homeData: ['0', '1', '2', '1', '0', '1', '0', '2'],
        visitorR: 7,
        homeR: 7,
    },
    {
        balls: 0,
        strikes: 0,
        outs: 0,
        visitorData: ['0', '1', '0', '0', '2', '2', '0', '2'],
        homeData: ['0', '1', '2', '1', '0', '1', '0', '2', '1'],
        visitorR: 7,
        homeR: 8,
    },
];

export class GameDay4Flow extends GameDayFlow {
    // fields
    //-------------------------------------------------------------------------

    // init
    //-------------------------------------------------------------------------
    constructor(opts: GameDayFlowOpts) {
        super(opts);
        this._visitor = textLocaleFormat('[gameDay4Opponent]');
        this._home = app().server.state.name;
        this._opponent = opts.opponentOverride ?? textLocaleFormat('[gameDay4Opponent]');
        this._scoreboard = 'board0';
        this._gameDaySteps = [
            { handler: this._intro, substeps: this._introSubSteps },
            { handler: this._step1, substeps: 1 },
            { handler: this._step2, substeps: 3 },
            { handler: this._step3, substeps: 2 },
            { handler: this._step4, substeps: 2 },
            { handler: this._step5, substeps: 1 },
            { handler: this._stepEnding, substeps: this._endingSubSteps },
        ];
    }

    // impl
    //-------------------------------------------------------------------------
    private async _intro() {
        await this._baseIntro({
            scoreData: scoreboardData[0],
            tapDialog: `[gameDay4Step0|${this._home}]`,
            nextBgId: 'gameDay3',
        });
    }

    private async _step1() {
        let stepIndex = this._stepOffset[this._step];

        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay3',
            players: [{ skin: 'character1_boy', clip: 'pitcher_idle_flip', loop: true } as GameDayPlayer],
        })) as GameDayScreen;

        const tapPromise = new NakedPromise();
        await this._gameDayScreen.spawnBubbleTap({
            dialogText: `[gameDay4Step1]`,
            promise: tapPromise,
            bubbleOffset: { x: 0, y: this._gameDayScreen.base.height * 0.5 - this._gameDayScreen.base.height * 0.22 },
            parentOverride: this._gameDayScreen.base,
        });

        void app().nav.preload('gameDayScreen', { bgId: 'gameDay2' });
        await tapPromise;

        trackGameDayStep({ stepIndex: stepIndex++, stepName: 'bases_loaded', stepType: 'tap' });

        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay2',
            players: [
                {
                    clip: 'batter_confident',
                    loop: true,
                    skin: 'character3_boy_red',
                } as GameDayPlayer,
            ],
        })) as GameDayScreen;

        void app().nav.preload('gameDayScreen', { bgId: 'gameDay4' });
        await sleep(1.25);
    }

    private async _step2() {
        let stepIndex = this._stepOffset[this._step];
        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay4',
            players: [
                {
                    skin: 'character2_girl',
                    offsetX: 80,
                    clip: 'fielder_idle',
                    loop: true,
                    scaleX: -1,
                } as GameDayPlayer,
            ],
        })) as GameDayScreen;

        const tapPromise = new NakedPromise();
        await this._gameDayScreen.spawnBubbleTap({
            dialogText: `[gameDay4Step2]`,
            promise: tapPromise,
            bubbleOffset: { x: 0, y: this._gameDayScreen.base.height * 0.5 - this._gameDayScreen.base.height * 0.22 },
            parentOverride: this._gameDayScreen.base,
        });

        await tapPromise;
        trackGameDayStep({ stepIndex: stepIndex++, stepName: 'fielder_intro', stepType: 'tap' });

        const choicePromise = new NakedPromise<number>();
        void this._gameDayScreen.spawnBubbleChoice({
            dialogText: '[gameDay4Step3]',
            choices: ['[gameDay4Choice0]', '[gameDay4Choice1]'],
            promise: choicePromise,
            bubbleOffset: { x: 0, y: this._gameDayScreen.base.height * 0.5 - this._gameDayScreen.base.height * 0.22 },
            parentOverride: this._gameDayScreen.base,
        });

        const choiceIndex = await choicePromise;
        trackGameDayStep({ stepIndex: stepIndex++, stepName: 'first_choice', stepType: 'choice' });

        const choiceDialogs = ['[gameDay4Step4sub0]', '[gameDay4Step4sub1]'];
        const tapPromise2 = new NakedPromise();
        await this._gameDayScreen.spawnBubbleTap({
            dialogText: choiceDialogs[choiceIndex],
            promise: tapPromise2,
            bubbleOffset: { x: 0, y: this._gameDayScreen.base.height * 0.5 - this._gameDayScreen.base.height * 0.22 },
            parentOverride: this._gameDayScreen.base,
        });
        await tapPromise2;

        trackGameDayStep({ stepIndex: stepIndex++, stepName: 'coach_decision', stepType: 'tap' });
        if (choiceIndex === 0) {
            void this._gameDayScreen.playPlayerAnimation({ id: 'fielder_walk', loop: true });
            const player = this._gameDayScreen.mainPlayer;
            await player
                .animate()
                .add(player.position, { x: player.x - 230, y: player.y - 160 }, 1.7, tween.linear)
                .promise();
        }
        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay3',
            players: [{ skin: 'character1_boy', clip: 'pitcher_idle_flip', loop: true } as GameDayPlayer],
        })) as GameDayScreen;

        void app().resource.loadAsset(this._batSFX);
        await sleep(0.5);

        await this._gameDayScreen.playPlayerAnimation({ id: 'pitcher_throw_flipped' });

        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay2',
            players: [
                {
                    clip: 'batter_foul',
                    loop: false,
                    skin: 'character3_boy_red',
                } as GameDayPlayer,
            ],
        })) as GameDayScreen;

        void app().sound.play(this._batSFX);

        await sleep(1.25);

        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay3',
            players: [{ skin: 'character1_boy', clip: 'pitcher_watchhomerun', loop: true } as GameDayPlayer],
        })) as GameDayScreen;

        void app().resource.loadAsset(this._catchSFX);
        await sleep(1.1);

        const choiceScreens = [
            async () => {
                this._gameDayScreen = (await app().nav.open('gameDayScreen', {
                    bgId: 'gameDay0',
                    players: [
                        {
                            skin: 'character5_boy',
                            offsetX: 60,
                            offsetY: -4,
                            scale: 0.81,
                            clip: 'baseman_catch',
                            loop: false,
                        } as GameDayPlayer,
                        {
                            skin: 'character6_girl_red2',
                            offsetX: -115,
                            offsetY: 60,
                            scale: 0.81,
                            clip: 'onbase1_out_flipped',
                            loop: false,
                        } as GameDayPlayer,
                    ],
                })) as GameDayScreen;
            },
            async () => {
                this._gameDayScreen = (await app().nav.open('gameDayScreen', {
                    bgId: 'gameDay4',
                    players: [
                        {
                            skin: 'character2_girl',
                            offsetX: 80,
                            clip: 'baseman_catch',
                            loop: false,
                            scaleX: -1,
                        } as GameDayPlayer,
                    ],
                })) as GameDayScreen;
            },
        ];

        void app().nav.preload('gameDayScreen', { bgId: 'gameDay3' });
        await choiceScreens[choiceIndex]();
        void app().sound.play(this._catchSFX);
        await sleep(1.5);
    }

    private async _step3() {
        let stepIndex = this._stepOffset[this._step];
        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay3',
            players: [{ skin: 'character1_boy', clip: 'celebrate_nojump', loop: true } as GameDayPlayer],
        })) as GameDayScreen;

        const tapPromise = new NakedPromise();
        await this._gameDayScreen.spawnBubbleTap({
            dialogText: '[gameDay4Step5]',
            promise: tapPromise,
            bubbleOffset: { x: 0, y: this._gameDayScreen.base.height * 0.5 - this._gameDayScreen.base.height * 0.22 },
            parentOverride: this._gameDayScreen.base,
        });
        await tapPromise;

        trackGameDayStep({ stepIndex: stepIndex++, stepName: 'mid_celebrate', stepType: 'tap' });

        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay3',
        })) as GameDayScreen;

        void this._gameDayScreen.spawnScoreDynamic({
            ...scoreboardData[1],
            scoreboard: this._scoreboard,
            home: this._home,
            visitor: this._visitor,
        });

        const scorePromise = new NakedPromise();
        await this._gameDayScreen.spawnBubbleTap({
            dialogText: `[gameDay4Step6]`,
            promise: scorePromise,
            narrator: 'arrow',
            bubbleOffset: { x: 0, y: this._gameDayScreen.base.height - 150 },
            parentOverride: this._gameDayScreen.base,
        });

        void app().nav.preload('gameDayScreen', { bgId: 'gameDay1' });

        await scorePromise;
        trackGameDayStep({ stepIndex: stepIndex++, stepName: 'mid_score', stepType: 'tap' });
    }

    private async _step4() {
        let stepIndex = this._stepOffset[this._step];
        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay1',
            players: [
                {
                    skin: 'character6_girl_red2',
                    offsetX: 80,
                    offsetY: -100,
                    scale: 0.68,
                    clip: 'fielder_idle',
                    loop: true,
                } as GameDayPlayer,
                {
                    skin: 'character5_boy',
                    offsetX: -60,
                    offsetY: 10,
                    scale: 0.81,
                    clip: 'onbase1_flipped',
                    loop: true,
                } as GameDayPlayer,
            ],
        })) as GameDayScreen;

        await sleep(1.25);

        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay3',
            players: [
                {
                    skin: 'character3_boy_red',
                    clip: 'pitcher_idle_flip',
                    loop: true,
                } as GameDayPlayer,
            ],
        })) as GameDayScreen;

        void app().resource.loadAsset(this._batSFX);
        void app().nav.preload('gameDayScreen', { bgId: 'gameDay2' });
        await sleep(0.5);

        await this._gameDayScreen.playPlayerAnimation({ id: 'pitcher_throw_flipped' });

        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay2',
            players: [
                {
                    clip: 'batter_foul',
                    loop: false,
                    skin: 'character1_boy',
                } as GameDayPlayer,
            ],
        })) as GameDayScreen;

        void app().sound.play(this._batSFX);
        void this._gameDayScreen.preloadChoiceView();
        await sleep(1.25);

        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay1',
            players: [
                {
                    skin: 'character6_girl_red2',
                    offsetX: 80,
                    offsetY: -100,
                    scale: 0.68,
                    clip: 'fielder_idle',
                    loop: true,
                } as GameDayPlayer,
                {
                    skin: 'character5_boy',
                    offsetX: -60,
                    offsetY: 10,
                    scale: 0.81,
                    clip: 'onbase1_flipped',
                    loop: true,
                } as GameDayPlayer,
            ],
        })) as GameDayScreen;

        const choicePromise = new NakedPromise<number>();
        void this._gameDayScreen.spawnBubbleChoice({
            dialogText: '[gameDay4Step7]',
            choices: ['[gameDay4Choice2]', '[gameDay4Choice3]'],
            promise: choicePromise,
            bubbleOffset: { x: 0, y: this._gameDayScreen.base.height * 0.5 - this._gameDayScreen.base.height * 0.22 },
            parentOverride: this._gameDayScreen.base,
        });

        void app().resource.loadAsset(this._catchSFX);
        void app().nav.preload('gameDayScreen', { bgId: 'thirdBase' });

        const choiceIndex = await choicePromise;
        trackGameDayStep({ stepIndex: stepIndex++, stepName: 'second_choice', stepType: 'choice' });

        const finalAnimationPromises = [];
        // new scene if first choice
        if (choiceIndex === 0) {
            this._gameDayScreen = (await app().nav.open('gameDayScreen', {
                bgId: 'thirdBase',
                players: [
                    {
                        skin: 'character3_boy_red',
                        offsetX: 80,
                        offsetY: -100,
                        scale: 0.68,
                        clip: 'fielder_idle',
                        loop: true,
                    } as GameDayPlayer,
                    {
                        skin: 'character5_boy',
                        offsetX: 600,
                        offsetY: 10,
                        scale: 0.81,
                        clip: 'run_flipped',
                        loop: true,
                    } as GameDayPlayer,
                ],
            })) as GameDayScreen;

            const runPromise = new NakedPromise();
            finalAnimationPromises.push(runPromise);
            void sleep(0.8).then(async () => {
                await this._gameDayScreen.playPlayerAnimation({ playerIndex: 0, id: 'fielder_surprised', loop: false });
                void this._gameDayScreen.playPlayerAnimation({
                    playerIndex: 0,
                    id: 'fielder_idle',
                    loop: true,
                    mix: 0.1,
                });
                runPromise.resolve();
            });
            await this._gameDayScreen.runStopAnimation({ playerIndex: 1, xOffset: 320 });
            await sleep(1);
        } else {
            // play animation on current scene
            void sleep(0.4).then(() => app().sound.play(this._catchSFX));
            const baseManPromise = new NakedPromise();
            void this._gameDayScreen
                .playPlayerAnimation({ playerIndex: 0, id: 'baseman_catch', loop: false })
                .then(() => {
                    baseManPromise.resolve();
                    void this._gameDayScreen.playPlayerAnimation({
                        playerIndex: 0,
                        id: 'fielder_idle',
                        loop: true,
                        mix: 0.2,
                    });
                });

            const onbasePromise = new NakedPromise();
            void this._gameDayScreen
                .playPlayerAnimation({ playerIndex: 1, id: 'onbase1_flipped_safe', loop: false })
                .then(() => {
                    onbasePromise.resolve();
                    void this._gameDayScreen.playPlayerAnimation({ playerIndex: 1, id: 'onbase1_flipped', loop: true });
                });

            finalAnimationPromises.push(baseManPromise, onbasePromise);
        }

        const dialogs = ['[gameDay4Step8sub0]', '[gameDay4Step8sub1]'];
        const tapPromise = new NakedPromise();
        await this._gameDayScreen.spawnBubbleTap({
            dialogText: dialogs[choiceIndex],
            promise: tapPromise,
            bubbleOffset: { x: 0, y: this._gameDayScreen.base.height * 0.5 - this._gameDayScreen.base.height * 0.22 },
            parentOverride: this._gameDayScreen.base,
        });

        await tapPromise;
        trackGameDayStep({ stepIndex: stepIndex++, stepName: 'runner', stepType: 'tap' });

        await Promise.all(finalAnimationPromises);
    }

    private async _step5() {
        let stepIndex = this._stepOffset[this._step];
        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay2',
            players: [{ skin: 'character2_girl', clip: 'batter_homerun', loop: false } as GameDayPlayer],
        })) as GameDayScreen;
        void app().sound.play(this._batSFX);
        await sleep(1.1);

        this._gameDayScreen = (await app().nav.open('gameDayScreen', {
            bgId: 'gameDay2',
            players: [
                {
                    skin: 'character5_boy',
                    clip: 'run',
                    offsetX: 200,
                    offsetY: 40,
                    loop: true,
                    scaleX: -1,
                    animateRun: true,
                } as GameDayPlayer,
            ],
        })) as GameDayScreen;

        void app().sound.play(this._cheerSFX);

        const runPromise = new NakedPromise();
        await this._gameDayScreen.spawnBubbleTap({
            dialogText: `[gameDay4Step9]`,
            promise: runPromise,
            narrator: 'arrow',
            bubbleOffset: { x: 0, y: this._gameDayScreen.base.height - 150 },
            parentOverride: this._gameDayScreen.base,
        });
        await runPromise;
        trackGameDayStep({ stepIndex: stepIndex++, stepName: 'run', stepType: 'tap' });
    }

    private async _stepEnding() {
        await this._baseVictory({ scoreData: scoreboardData[2], tapDialog: `[gameDay4Step10]` });
    }
}
