varying vec2 vTextureCoord;

uniform sampler2D uSampler;
uniform float uHue;
uniform float uSaturation;
uniform float uLuminance;

vec3 RGBToHSL( in vec3 c ){
  float h = 0.0;
	float s = 0.0;
	float l = 0.0;
	float r = c.r;
	float g = c.g;
	float b = c.b;
	float cMin = min( r, min( g, b ) );
	float cMax = max( r, max( g, b ) );

	l = ( cMax + cMin ) / 2.0;
	if ( cMax > cMin ) {
		float cDelta = cMax - cMin;
        
        //s = l < .05 ? cDelta / ( cMax + cMin ) : cDelta / ( 2.0 - ( cMax + cMin ) ); Original
		s = l < .0 ? cDelta / ( cMax + cMin ) : cDelta / ( 2.0 - ( cMax + cMin ) );
        
		if ( r == cMax ) {
			h = ( g - b ) / cDelta;
		} else if ( g == cMax ) {
			h = 2.0 + ( b - r ) / cDelta;
		} else {
			h = 4.0 + ( r - g ) / cDelta;
		}

		if ( h < 0.0) {
			h += 6.0;
		}
		h = h / 6.0;
	}
	return vec3( h, s, l );
}

vec3 HSLToRGB( in vec3 c )
{
    vec3 rgb = clamp( abs(mod(c.x*6.0+vec3(0.0,4.0,2.0),6.0)-3.0)-1.0, 0.0, 1.0 );

    return c.z + c.y * (rgb-0.5)*(1.0-abs(2.0*c.z-1.0));
}

vec3 HSLBlend(vec3 base, vec3 blend)
{
    vec3 r;

    // add hue
    r.x = base.x + blend.x;
    // add saturation
    r.y = base.y + blend.y;
    // multiply luminance
    r.z = base.z * blend.z;

    return r;
}

void main(void)
{
    vec4 c = texture2D(uSampler, vTextureCoord);
    vec3 blend = HSLToRGB(HSLBlend(RGBToHSL(c.rgb), vec3(uHue, uSaturation, uLuminance)));
    gl_FragColor = vec4(blend,c.a);
}
