import { Color } from '@play-co/odie';

import { DynamicNumber, importDynamicNumber, TweenFunction } from '../../../defs/types';
import { Particle } from '../Particle';
import { IBehavior } from './IBehavior';
import { tweenLinear } from './particle_tweens';

// types
//-----------------------------------------------------------------------------
export type TintBehaviorOptions = {
    type: 'tint';
    from?: DynamicNumber;
    to: DynamicNumber;
    tween?: TweenFunction;
};

/*
    tween tint
*/
export class TintBehavior implements IBehavior {
    // fields
    //-------------------------------------------------------------------------
    private _particle: Particle;
    private _from: Color;
    private _to: Color;
    private _tween: TweenFunction;

    // init
    //-------------------------------------------------------------------------
    constructor(particle: Particle, options: TintBehaviorOptions) {
        this._particle = particle;
        this._from = pixiColorFromHex(importDynamicNumber(options.from || 0xffffff));
        this._to = pixiColorFromHex(importDynamicNumber(options.to));
        this._tween = options.tween || tweenLinear;
    }

    // impl
    //-------------------------------------------------------------------------
    public step(dt: number) {
        // get tween value
        const tween = this._tween(this._particle.getTweenTime());

        // lerp color
        const color = this._from.clone().lerpHSL(this._to, tween);

        // commit tint
        this._particle.tint = color.getHex();
    }
}
function pixiColorFromHex(arg0: any): Color {
    const color = new Color(0, 0, 0);
    color.setHex(arg0);
    return color;
}
