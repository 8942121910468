import { IFlow } from '../../lib/pattern/IFlow';
import teamMorale from '../../replicant/defs/teamMorale';
import { sleep } from '../../replicant/util/jsTools';
import app from '../getApp';
import { trackMoralePinchFinish, trackMoralePinchStart } from '../lib/analytics/teamMorale';
import { DialogFlow } from './DialogFlow';

export type TeamMoraleFlowOpts = {
    pinchId: number;
    scenarioId: number;
};

export class TeamMoralePinchFlow extends DialogFlow implements IFlow {
    private readonly _pinchId: number;
    private readonly _scenarioId: number;
    // init
    //-------------------------------------------------------------------------
    constructor(opts: TeamMoraleFlowOpts) {
        super();
        const { pinchId, scenarioId } = opts;
        this._pinchId = pinchId;
        this._scenarioId = scenarioId;
        this._spawnMoraleView = true;
    }

    // impl
    //-------------------------------------------------------------------------
    public async execute() {
        await this._actionTeamMorale();
        return this._complete;
    }

    private async _actionTeamMorale() {
        const dialogs = teamMorale.teamMoraleConfig[this._scenarioId][this._pinchId].dialogs;
        trackMoralePinchStart({ pinchId: this._pinchId, scenarioId: this._scenarioId });

        await this._dialogSequence(dialogs);
        await app().server.invoke.consumeMoralePinch({ choiceIndex: this._choice });
        trackMoralePinchFinish({ pinchId: this._pinchId, scenarioId: this._scenarioId, selectedOption: this._choice });
        await sleep(2.5);
        void this._actionComplete(true);
    }
}
