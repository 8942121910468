import { createScheduledActions } from '@play-co/replicant';

import { asyncGetters } from './asyncGetters';
import { firstSessionScheduledActions, firstSessionScheduledActionsSchema } from './components/firstSession';
import { gameDayScheduledActions, gameDayScheduledActionsSchema } from './components/gameDay';
import { livesScheduledActions, livesScheduledActionsSchema } from './components/lives';
import { computedProperties } from './computedProperties';
import { messages } from './messages';
import { stateSchema } from './state';
import { teamMoraleScheduledActions, teamMoraleScheduledActionsSchema } from './components/teamMorale';

export const scheduledActions = createScheduledActions(
    stateSchema,
    {
        ...firstSessionScheduledActionsSchema,
        ...gameDayScheduledActionsSchema,
        ...livesScheduledActionsSchema,
        ...teamMoraleScheduledActionsSchema,
    },
    {
        asyncGetters,
        computedProperties,
        messages,
    },
)({
    ...firstSessionScheduledActions,
    ...gameDayScheduledActions,
    ...livesScheduledActions,
    ...teamMoraleScheduledActions,
});
