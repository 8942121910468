import { gsap } from 'gsap';

import { sleep } from '../../../replicant/util/jsTools';
import type { IScreen } from '../IScreen';
import { ITransition } from '../ITransition';
import app from '../../../app/getApp';

// constants
//-----------------------------------------------------------------------------
const duration = 0.28;

/*
    simple alpha fade transition
*/
export class ScaleTransition implements ITransition {
    // impl
    //-------------------------------------------------------------------------
    public async open(screen: IScreen) {
        const { width, height } = app().stage.renderer;
        const container = screen.root;
        container.alpha = 0;
        await sleep(0.02); // small sleep to make sure its pitch black for a few frames
        container.alpha = 0;
        container.scale.set(0);
        container.position.set(width / 2, height / 2);
        gsap.to(container, {
            alpha: 1,
            duration,
        });
        gsap.to(container.position, {
            x: 0,
            y: 0,
            duration,
            ease: 'back.out(1.7)',
        });
        await gsap.to(container.scale, {
            x: 1,
            y: 1,
            duration,
            ease: 'back.out(1.7)',
        });
    }

    public async close(screen: IScreen) {
        const { width, height } = app().stage.renderer;
        const container = screen.root;
        gsap.to(container, {
            alpha: 0,
            duration,
        });
        gsap.to(container.position, {
            x: width / 2,
            y: height / 2,
            duration,
        });
        await gsap.to(container.scale, {
            x: 0,
            y: 0,
            duration,
        });
    }
}
