import { IFlow } from '../../../lib/pattern/IFlow';
import NakedPromise from '../../../lib/pattern/NakedPromise';
import { getTeamMoraleScore } from '../../../replicant/components/teamMorale';
import task, { Task } from '../../../replicant/defs/task';
import teamMorale from '../../../replicant/defs/teamMorale';
import { sleep } from '../../../replicant/util/jsTools';
import app from '../../getApp';
import { trackTaskStart } from '../../lib/analytics/task';
import {
    trackTaskIntroFinish,
    trackTaskIntroStart,
    trackTaskIntroStepFinish,
    trackTaskIntroStepStart,
} from '../../lib/analytics/tutorial';
import { HomeScreen } from '../../main/home/HomeScreen';
import { TaskScreen } from '../../main/tasks/TaskScreen';
import { LineLoginFlow } from '../LineLoginFlow';
import { TutorialMoraleIntroFlow } from './TutorialMoraleIntroFlow';

const TUTORIAL_TASK = 0;
const id = 'task_intro';

export class TutorialTaskIntroFlow implements IFlow {
    private readonly _complete = new NakedPromise<HomeScreen>();

    private readonly _starHandler: (openedScreen: HomeScreen) => Promise<void>;
    private _stepIndex = 0;
    private _home: HomeScreen;
    private _homeOverrideMoraleScore: number;
    // init
    //-------------------------------------------------------------------------
    constructor(opts?: { overrideMoraleScore?: number; starHandler?: (openedScreen: HomeScreen) => Promise<void> }) {
        this._starHandler = opts?.starHandler;
        this._homeOverrideMoraleScore = opts?.overrideMoraleScore;
    }

    // impl
    //-------------------------------------------------------------------------
    public async execute() {
        trackTaskIntroStart({ stepIndex: this._stepIndex, stepName: id });
        await this._actionHomeInit();
        await this._actionTask();
        await this._actionHomeEnd();
        trackTaskIntroFinish({ stepIndex: this._stepIndex, stepName: id });

        if (app().platform.guest) {
            await new LineLoginFlow().execute();
        }
        return this._complete;
    }

    private async _actionComplete(home: HomeScreen) {
        this._complete.resolve(home);
    }

    private async _actionHomeInit() {
        trackTaskIntroStepStart({ stepIndex: this._stepIndex, stepName: 'home_task' });

        const triggerMoraleIntro = !app().server.state.teamMorale.ftue;
        this._home = (await app().nav.open('homeScreen', {
            disableButtons: true,
            skipPointer: true,
            // first time we get morale score, override with what we just gained from puzzle
            overrideMoraleScore: this._homeOverrideMoraleScore,
        })) as HomeScreen;

        // no star handler if started a new session in during TutorialTaskIntroFlow
        if (this._starHandler) {
            if (this._homeOverrideMoraleScore) this._home.teamMoraleView.resumeMoraleUpdate();
            await this._starHandler(this._home);
        }

        // points already gained, animate into real amount
        if (this._homeOverrideMoraleScore) await sleep(1);

        // not part of the actual task intro tutorial, this sub tutorial can be triggered in 2 ways depending on state
        if (triggerMoraleIntro) {
            await new TutorialMoraleIntroFlow().execute();
        }

        const tapPromise = new NakedPromise();
        const taskButton = this._home.upgradeButton;
        taskButton.onPress = async () => tapPromise.resolve();
        // point to task button in HomeScreen
        void app().nav.open('tipScreen', {
            y: app().stage.canvas.height * 0.5,
            text: '[taskIntroDugout0]',
            type: 'hand',
            motion: 'tap',
            pointerOffset: { x: -30, y: -20 },
            allowInput: true,
            targets: [taskButton.getBounds()],
        });

        // ------ Preload task screen
        void app().nav.preload('taskScreen', { stateTask: app().server.state.task });

        await tapPromise;
        void app().nav.close('tipScreen');
        trackTaskIntroStepFinish({ stepIndex: this._stepIndex, stepName: 'home_task' });
        this._stepIndex++;
    }

    private async _actionTask() {
        trackTaskIntroStepStart({ stepIndex: this._stepIndex, stepName: 'task_step' });
        const taskScreen = (await app().nav.open('taskScreen', {
            forcedStarAmount: 1,
            stateTask: app().server.state.task,
            pauseProgress: true,
        })) as TaskScreen;

        const tapPromise = new NakedPromise();
        taskScreen.tasks.forEach((task, index) => {
            index === TUTORIAL_TASK
                ? (task.button.onPress = async () => tapPromise.resolve())
                : (task.button.onPress = null);
        });

        const taskButton = taskScreen.tasks[TUTORIAL_TASK].button;
        // point to task button in HomeScreen
        void app().nav.open('tipScreen', {
            y: app().stage.canvas.height * 0.5,
            text: '[taskIntroDugout1]',
            type: 'hand',
            motion: 'tap',
            allowInput: true,
            targets: [taskButton.getBounds()],
        });

        await tapPromise;
        void app().nav.close('tipScreen');

        // mark step 1 as complete in mock
        const mockSteps = task.taskList[0].steps.map((_, i) => ({ timestamp: i === 0 ? -1 : 0 }));
        const mockStateTask = {
            level: 0,
            timestamp: 0,
            steps: mockSteps,
        } as Task;
        const timestamp = mockStateTask.steps[TUTORIAL_TASK].timestamp;

        // disable back button before animation start and override with new callback
        const backPromise = new NakedPromise();
        taskScreen.backButton.onPress = async () => backPromise.resolve();

        const targetStar = taskScreen.tasks[TUTORIAL_TASK].starIcon;
        const global = targetStar.toGlobal({ x: targetStar.width * 0.5, y: targetStar.height * 0.5 });
        const animationDuration = 0.7;
        void taskScreen.starDecreaseAnimation({ animationDuration, target: global });
        taskScreen.starView.forceUpdateAmount(0); // back to zero once star starts flying
        await sleep(animationDuration);
        taskScreen.tasks[TUTORIAL_TASK].update({ timestamp });
        taskScreen.updateProgress(mockStateTask, true);

        trackTaskIntroStepFinish({ stepIndex: this._stepIndex, stepName: 'task_step' });
        this._stepIndex++;
        trackTaskIntroStepStart({ stepIndex: this._stepIndex, stepName: 'home_navigation' });

        // point to back button
        void app().nav.open('tipScreen', {
            type: 'hand',
            motion: 'tap',
            allowInput: true,
            targets: [taskScreen.backButton.getBounds()],
        });

        await backPromise;
        void app().nav.close('tipScreen');
        trackTaskIntroStepFinish({ stepIndex: this._stepIndex, stepName: 'home_navigation' });
        this._stepIndex++;
    }

    private async _actionHomeEnd() {
        trackTaskIntroStepStart({ stepIndex: this._stepIndex, stepName: 'good_luck' });
        const home = (await app().nav.open('homeScreen', {
            disableButtons: true,
            skipPointer: true,
            disableNotification: true, // disable task notification since we have not updated the state yet
            forcedStarAmount: 0, // keep it at zero, we will deduct one star in the end
        })) as HomeScreen;

        const tapPromise = new NakedPromise();
        const infoPopupId = 'infoPopup';
        // point to ok button in popup
        void app().nav.open(infoPopupId, {
            header: '[goodLuck]',
            infoText: '[taskIntroDugout2]',
            height: 580,
            underlay: 0.6,
            onOk: async () => tapPromise.resolve(),
        });

        await tapPromise;
        void app().nav.close(infoPopupId);

        // update state as final step
        await app().server.invoke.updateTaskTutorial({ stepIndex: TUTORIAL_TASK });

        // Final step of "tutorial", trigger timestamp so first daily bonus will be the following day
        await app().server.invoke.triggerDailyBonusTimestamp();

        // task is "unlocked" once they complete the inital tutorial
        trackTaskStart();

        home.starView.resumeUpdateAmount();
        void home.spawnPointer();
        home.enableMenu();
        trackTaskIntroStepFinish({ stepIndex: this._stepIndex, stepName: 'good_luck' });
        void this._actionComplete(home);
    }
}
