import { Sprite } from 'pixi.js';

import { uiAlignBottom, uiAlignCenter, uiAlignRight } from '../../../../lib/pixi/uiTools';
import { PowerBoosterId } from '../../../../replicant/defs/booster';
import { ImageButton } from '../../../lib/ui/buttons/ImageButton';
import { BasicText } from '../../../lib/ui/text/BasicText';

// types
//-----------------------------------------------------------------------------
export type PowerBoosterButtonOptions = {
    id: PowerBoosterId;
    count: number;
    locked: boolean;
};

// constants
//-----------------------------------------------------------------------------
const manifest = {
    bomb: 'block.bomb.png',
    cube: 'block.cube.all.png',
    rocket: 'block.rocket.horizontal.png',
    button: 'button.power.png',
    selected: 'button.power.selected.png',
    check: 'icon.checkmark.png',
    lock: 'icon.lock.png',
};

/*
    LevelGoal: power booster button
*/
export class PowerBoosterButton extends ImageButton {
    // fields
    //-------------------------------------------------------------------------
    private _id: PowerBoosterId;
    private _counter: BasicText;
    private _check: Sprite;

    // fields
    //-------------------------------------------------------------------------
    public get id(): PowerBoosterId {
        return this._id;
    }

    // init
    //-------------------------------------------------------------------------
    constructor(options: PowerBoosterButtonOptions) {
        const { id, count, locked } = options;
        super({
            image: manifest.button,
            selected: manifest.selected,
        });

        // set fields
        this._id = id;

        // if not locked
        if (locked) {
            // spawn lock
            this._spawnIcon(manifest.lock);
        }
        // if not locked
        else {
            // spawn icon
            this._spawnIcon(manifest[id]);

            // spawn count
            this._spawnCount(count);

            // spawn check
            this._spawnCheck();
        }
    }

    static assets(): string[] {
        return Object.values(manifest);
    }

    // private: updaters
    //-------------------------------------------------------------------------
    protected override _updateSelected() {
        super._updateSelected();

        // show check else counter
        this._check.visible = this.selected;
        this._counter.visible = !this.selected;
    }

    // private: scene
    //-------------------------------------------------------------------------
    public _spawnIcon(icon: string) {
        const sprite = this.button.addChild(Sprite.from(icon));
        uiAlignCenter(this.button, sprite, 0, 0);
    }

    public _spawnCheck() {
        const sprite = (this._check = this.button.addChild(Sprite.from(manifest.check)));
        sprite.scale.set(0.5);
        uiAlignBottom(this, sprite, 2);
        uiAlignRight(this, sprite, 10);
        sprite.visible = false;
    }

    public _spawnCount(count: number) {
        const empty = count === 0;
        const counter = (this._counter = this.addChild(
            new BasicText({
                text: empty ? '+' : count.toString(),
                style: {
                    fill: '#fff',
                    fontSize: empty ? 56 : 34,
                    fontWeight: 'bold',
                    strokeThickness: 4,
                    padding: 4,
                },
            }),
        ));
        counter.pivot.set(counter.width, counter.height);
        uiAlignBottom(this, counter, empty ? 14 : 6);
        uiAlignRight(this, counter, -2);
    }
}
