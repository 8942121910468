import { AnalyticsPayload } from '@play-co/replicant';

import { LanguageId } from '../../../replicant/defs/settings';
import { getGameUrl } from '../../../replicant/util/envTools';
import { sleep } from '../../../replicant/util/jsTools';
import { captureGenericError } from '../sentry';
import app from '../../getApp';

export async function windowReload() {
    // Flush all actions before reloading the game to make sure the backend gets updated
    await app().server.flush();
    await sleep(0.22); // flush seems to be not enough, wait a bit more
    // dont use window.location.reload(), its broken for some(?) iphones
    window.location.href = window.location.href; // eslint-disable-line no-self-assign
}

export function openLineApp(args: { payload?: AnalyticsPayload }) {
    const redirectUrl = getGameUrl();

    const lineURL = new URL(redirectUrl);
    const searchParams = new URLSearchParams(window.location.search);

    let passthroughPayload = {};
    try {
        // Decode the param again before parsing because CDN might double encode
        const payloadJSON = decodeURIComponent(searchParams.get('payload'));
        passthroughPayload = JSON.parse(payloadJSON) ?? {};
    } catch (e) {
        captureGenericError('Error parsing Line payload', e);
    }

    if (args.payload) {
        Object.assign(passthroughPayload, args.payload);
    }

    const payloadJSON = JSON.stringify(passthroughPayload);
    searchParams.set('payload', payloadJSON);
    lineURL.search = searchParams.toString();
    window.location.href = lineURL.toString();
}

export function getUserPreferredLanguage() {
    const lang = navigator.language.toLowerCase();

    if (lang.startsWith('en')) {
        return 'en';
    } else if (lang.startsWith('ja')) {
        return 'ja';
    }

    return 'ja';
}

export function getForcedLanguage() {
    const params = new URLSearchParams(window.location.search);
    const langParam = params.has('lang') && params.get('lang');

    let forcedLanguage = null;
    if (langParam === 'en' || langParam === 'ja') {
        forcedLanguage = langParam as LanguageId;
    }

    return forcedLanguage;
}
