import { AppConfig } from '@play-co/astro';
import { ENV, settings } from 'pixi.js';

// settings
//-----------------------------------------------------------------------------
settings.PREFER_ENV = ENV.WEBGL2;

// frontend app config
//-----------------------------------------------------------------------------
// export const config = {
//     prop...
// };

// pixi app config
//-----------------------------------------------------------------------------
export const pixiConfig: AppConfig = {
    limitFPS: false, // 60 will cause fps gaps on some OS, probably rounding error
    size: {
        width: 750,
        height: 1334,
    },
    stats: {
        enabled: false,
    },
    stage: {
        alwaysOnAccessibility: false,
        accessibilityDebug: false,
        backgroundColor: 0x000000,
        clearBeforeRender: true,
        resolution: 1,
        antialias: false,
    },
};
