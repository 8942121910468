import { i18n } from '@play-co/astro';
import { Container, ITextStyle } from 'pixi.js';

import { MarkupRenderer } from '../../../../lib/pixi/tools/MarkupRenderer';
import { languageFontMap, systemFontMap, TextLanguageId } from '../../../defs/text';

// types
//-----------------------------------------------------------------------------
// public
export type MarkupTextOptions = {
    // markup formatted text
    text?: string;
    // text style
    style: Partial<ITextStyle>;
    // use given text directly instead of parsing it
    literal?: boolean; // default: false
    // use system font
    system?: boolean; // default: false
};

/*
    markup text
*/
export class MarkupText extends Container {
    // fields
    //-------------------------------------------------------------------------
    private _format: string;
    private _style: Partial<ITextStyle>;
    private _literal: boolean;

    // properties
    //-------------------------------------------------------------------------
    // text
    public get text(): string {
        return this._format;
    }

    public set text(format: string) {
        // set format
        this._format = format;

        // update view
        this._update();
    }

    // api
    //-------------------------------------------------------------------------
    constructor(options: MarkupTextOptions) {
        super();

        // add font family if missing
        if (options.style.fontFamily) this._style = options.style;
        else {
            const language = i18n.language as TextLanguageId;
            this._style = {
                ...options.style,
                fontFamily: options.system ? systemFontMap[language] : languageFontMap[language].fontName,
            };
        }

        // set fields
        this._literal = !!options.literal;

        // initial update
        this.text = options.text || '';
    }

    // private: updaters
    //-------------------------------------------------------------------------
    private _update() {
        // clear previous content
        this.removeChildren();

        // re-render
        new MarkupRenderer({
            format: this._format,
            style: this._style,
            literal: this._literal,
        })
            .build()
            .forEach((content) => this.addChild(content));

        // apply center alignment if set
        if (this._style.align === 'center') {
            const width = this.width;

            // group children into lines by y value
            const lines: Container[][] = [];
            for (const child of this.children) {
                const y = child.y;
                if (!lines[y]) lines[y] = [];
                lines[y].push(child as Container);
            }

            // center each line
            for (const [y, words] of Object.entries(lines)) {
                const lineLength = words.reduce((sum, child) => sum + child.width, 0);
                const offset = (width - lineLength) / 2;

                for (const child of words) {
                    child.x += offset;
                }
            }

            this.pivot.x = width * -0.5;
        }
    }
}
