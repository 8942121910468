//TODO: use ImageButton instead
import { ColorMatrixFilter, NineSlicePlane, Texture } from 'pixi.js';

import { Animation } from '../../../../lib/animator/Animation';
import { BasicAsyncHandler } from '../../../../lib/defs/types';
import { TouchInputComponent } from '../../../../lib/pixi/components/TouchInputComponent';
import { onDownAnimation, onTapAnimation, onUpAnimation } from '../../util/clickAnimation';
import app from '../../../getApp';

// types
//-----------------------------------------------------------------------------
type Slice = {
    sliceUp: string;
    sliceDown: string;
    defaultSlice?: string;
    width: number;
    height: number;
    left: number;
    top: number;
    right: number;
    bottom: number;
};

export type SliceButtonOptions = {
    slice: Slice;
    animate: boolean;
    sound?: string;
    scrollButton?: boolean;
};

/*
    basic slice image button
*/
export class SliceButton extends NineSlicePlane {
    // fields
    //-------------------------------------------------------------------------
    // events
    public onPress?: BasicAsyncHandler;
    // input
    protected _up: Texture;
    protected _down: Texture;
    protected _animate = false;
    private _sound: string | null;
    // components
    private _input: TouchInputComponent;
    private _animation: Animation;

    // properties
    //-------------------------------------------------------------------------
    public set up(resource: string) {
        this._up = Texture.from(resource);
    }

    public set down(resource: string) {
        this._down = Texture.from(resource);
    }

    public set sound(sound: string) {
        this._sound = sound;
    }

    // init
    //-------------------------------------------------------------------------
    constructor(options: SliceButtonOptions) {
        const { sliceUp, sliceDown, defaultSlice, left, top, right, bottom, width, height } = options.slice;
        super(Texture.from(sliceUp), left, top, right, bottom);

        this.height = height;
        this.width = width;

        // initialize
        this.up = sliceUp;
        this.down = sliceDown;
        if (options.animate) this._animate = true;
        this._input = Object.assign(new TouchInputComponent(this), {
            onTap: this._onTap.bind(this),
            // Issue with buttons inside a scroll with pointerup event
            onDown: options.scrollButton ? null : this._onDown.bind(this),
            onUp: options.scrollButton ? null : this._onUp.bind(this),
        });

        this._sound = options.sound === null ? null : options.sound || 'button.ogg';

        // Init texture
        this.texture = defaultSlice ? Texture.from(defaultSlice) : this._up;
    }

    public setEnabled(enabled: boolean) {
        const grayscale = new ColorMatrixFilter();
        grayscale.grayscale(0.4, false);
        this.interactive = enabled;
        this.filters = enabled ? [] : [grayscale];
    }

    public async trigger() {
        return this._onTap();
    }

    // private: handlers
    //-------------------------------------------------------------------------
    protected async _onTap() {
        if (this._animate) {
            this._animation?.cancel();
            this._animation = onTapAnimation(this);
        }

        // 4 dupes to support button spam clicks
        if (this._sound) void app().sound.play(this._sound, { dupes: 5, volume: 0.6 });

        // handle press
        await this.onPress?.();
    }

    protected _onDown() {
        if (this._animate) {
            this._animation?.cancel();
            this._animation = onDownAnimation(this);
        }

        // replace sprite with down texture
        this.texture = this._down;
    }

    protected _onUp() {
        if (this._animate) {
            this._animation?.cancel();
            this._animation = onUpAnimation(this);
        }
        // replace sprite with up texture
        this.texture = this._up;
    }
}
