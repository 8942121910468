import { Sprite, Texture } from 'pixi.js';

import UpdateObserver from '../../../lib/pattern/UpdateObserver';
import { uiAlignCenter, uiSizeToFit } from '../../../lib/pixi/uiTools';
import { BasicText } from '../../lib/ui/text/BasicText';
import app from '../../getApp';

const manifest = {
    star: 'icon.star.png',
    frame: 'panel.stats.png',
};

// smarter component handling its own state and rendering
export default class StarComponent extends Sprite {
    private _amount: BasicText;
    private _icon: Sprite;
    private _observer = new UpdateObserver();

    public static starAsset(): string {
        return manifest.star;
    }

    public get icon() {
        return this._icon;
    }

    constructor() {
        super(Texture.from(manifest.frame));

        this._amount = new BasicText({
            text: ``,
            style: {
                fill: '#FFF',
                fontSize: 56,
                lineJoin: 'round',
                align: 'center',
            },
        });

        this._icon = Sprite.from(manifest.star);

        this._icon.x = 5;
        this._icon.y = 13;

        this.addChild(this._icon, this._amount);

        this._observer.listen(
            () => app().game.player.stars,
            (stars) => {
                this._updateAmount(stars);
            },
        );

        this._observer.start();
    }

    public static assets(): string[] {
        return Object.values(manifest);
    }

    private _updateAmount(amount: number) {
        this._amount.text = `${amount}`;
        uiSizeToFit(this._amount, 98, 90);
        this._amount.y = 7;
        uiAlignCenter(this, this._amount, 25);
    }

    public forceUpdateAmount(amount: number) {
        this._observer.stop();
        this._updateAmount(amount);
    }

    public resumeUpdateAmount() {
        this._observer.start();
    }
}
