import { action, asyncAction, SB } from '@play-co/replicant';

import { PowerBoosterId } from '../defs/booster';
import gameConfig from '../defs/gameConfig';
import { AsyncActionAPI, MutableState, State, SyncActionAPI } from '../defs/replicant';
import { numberClamp } from '../util/mathTools';
import { powerBoosterAdd } from './boosters';
import { isInfiniteLivesActive, livesGet, livesSet } from './lives';
import { getTeamMoraleScore, updateTeamMorale } from './teamMorale';
import teamMorale from '../defs/teamMorale';
import { getAbTest } from '../util/replicantTools';

// types
//-----------------------------------------------------------------------------
export type PuzzleCompleteResults = {
    stars: number;
    moraleScore?: { before: number; after: number };
    completedLevel: number;
    //kcoins: number;
    //medals: number;
    //grantedItem?: CollectionItemId;
    // was the puzzle side of the mission completed
    missionCompleted?: boolean;
};

// state
//-----------------------------------------------------------------------------
export const puzzleState = {
    // puzzle
    puzzle: SB.object({
        // current level
        level: SB.int().default(1),
        // highest completed level
        lastLevel: SB.int().default(0),
        // start timestamp
        time: SB.int(),
        // continue counter
        continues: SB.int(),
        // save state
        save: SB.object({
            // saved level
            level: SB.int(),
            // encoded state
            state: SB.string(),
        }).optional(),
    }),
};

// actions
//-----------------------------------------------------------------------------
//TODO: cheaters!
export const puzzleActions = {
    // begin puzzle level
    puzzleBegin: action(
        (
            state: MutableState,
            options: { levelOverride?: number; powerBoosters: PowerBoosterId[] },
            api: SyncActionAPI,
        ) => {
            const now = api.date.now();

            const { levelOverride } = options;

            // require has lives
            const availableLives = livesGet(state, now);
            const infiniteActive = isInfiniteLivesActive(state, api.date.now());
            if (availableLives > 0 || infiniteActive) {
                if (!infiniteActive) {
                    // consume life and fail stat (will refund on complete)
                    livesSet(state, availableLives - 1, now);
                }
                //++state.collectionStats.puzzleFails;
                const puzzle = state.puzzle;

                if (state.puzzle.lastLevel < gameConfig.puzzle.levels.max && levelOverride) {
                    throw new Error(`Not allowed to play override level at ${state.puzzle.lastLevel}`);
                }

                if (levelOverride) {
                    if (levelOverride < 1 || levelOverride > gameConfig.puzzle.levels.max) {
                        throw new Error(`Invalid level override ${levelOverride}`);
                    }
                    puzzle.level = levelOverride;
                }
                // set start timestamp
                puzzle.time = now;

                // reset continue count
                puzzle.continues = 0;
            }

            //TODO: cheaters!
            // consume power boosters
            for (const type of options.powerBoosters) powerBoosterAdd(state, type, -1);
        },
    ),
    puzzleBeginForce: action((state: MutableState, _, api: SyncActionAPI) => {
        const now = api.date.now();
        // No lives check, livesSet will go to 0 if the player failed 5 times
        const availableLives = livesGet(state, now);
        // consume life and fail stat (will refund on complete)
        livesSet(state, availableLives - 1, now);
        const puzzle = state.puzzle;

        // set start timestamp
        puzzle.time = now;

        // reset continue count
        puzzle.continues = 0;
    }),
    // complete puzzle level
    //TODO: cheaters!
    puzzleComplete: asyncAction(
        async (state: MutableState, options: { playerMoves: number; mapMoves: number }, api: AsyncActionAPI) => {
            const puzzle = state.puzzle;
            const now = api.date.now();
            const completedLevel = state.puzzle.level;
            const out = {} as PuzzleCompleteResults;
            out.completedLevel = completedLevel;

            // require has start time
            //if (puzzle.time === 0)                return out;

            // reset start time
            puzzle.time = 0;

            // refund life and fail stat
            if (!isInfiniteLivesActive(state, now)) {
                // only give back if we did not enter for free
                livesSet(state, livesGet(state, now) + 1, now);
            }
            //--state.collectionStats.puzzleFails;

            const moraleGain = teamMorale.score.puzzle;
            const before = getTeamMoraleScore(state, now);
            updateTeamMorale(state, moraleGain, now);
            const after = getTeamMoraleScore(state, now);
            out.moraleScore = { before, after };

            // update last level
            if (puzzle.level > puzzle.lastLevel) {
                puzzle.lastLevel = puzzle.level;
                state.stars += gameConfig.stars.puzzleFinishGrant;
                out.stars = gameConfig.stars.puzzleFinishGrant;
            }

            puzzleLevelSetPlayer(state, completedLevel + 1);

            // update stats
            //++state.collectionStats.puzzleWins;

            // reset any save state
            puzzle.save = undefined;

            return out;
        },
    ),

    // set puzzle level (dev only)
    puzzleSetLevel: action((state: MutableState, options: { level: number }) => {
        // if dev env
        if (process.env.IS_DEVELOPMENT) {
            // set dev puzzle level
            puzzleLevelSetDev(state, options.level);
        }
    }),

    // save puzzle state for an active puzzle
    puzzleSave: action((state: MutableState, options: { level: number; state: string }) => {
        state.puzzle.save = options;
    }),

    // clear puzzle save state
    puzzleSaveClear: action((state: MutableState) => {
        state.puzzle.save = undefined;
    }),
};

// api
//-----------------------------------------------------------------------------
export function puzzleIsMaxLevel(state: State): boolean {
    return state.puzzle.level >= gameConfig.puzzle.levels.max;
}

export function puzzleLevelSetPlayer(state: MutableState, level: number) {
    state.puzzle.level = Math.min(level, gameConfig.puzzle.levels.max);
}

export function puzzleLevelSetDev(state: MutableState, level: number) {
    state.puzzle.level = numberClamp(level, 1, gameConfig.puzzle.levels.max);
    state.puzzle.lastLevel = state.puzzle.level - 1;
}
