import { Layout } from '@pixi/layout';
import { NineSlicePlane, Sprite, Texture } from 'pixi.js';

import { BasicAsyncHandler } from '../../../lib/defs/types';
import { uiAlignCenterX, uiSizeToWidth } from '../../../lib/pixi/uiTools';
import { textLocaleFormat } from '../../../lib/util/textTools';
import { boosterMap } from '../../../replicant/defs/booster';
import { coinsProductDefs } from '../../../replicant/defs/product';
import { PopupOptions, PopupScreen } from '../../lib/screens/PopupScreen';
import { ConsumeButton } from '../../lib/ui/buttons/ConsumeButton';
import { UpDownButton } from '../../lib/ui/buttons/UpDownButton';
import { BasicText } from '../../lib/ui/text/BasicText';
import PopupCoinView from '../home/PopupCoinView';
import app from '../../getApp';

//TEMP: use official purchase table
export type tmp_ItemId = 'bomb' | 'cube' | 'rocket' | 'dart' | 'bullet' | 'drill' | 'roulette';
export type tmp_ItemProps = {
    text: string;
    tip: string;
    asset: string;
    cost: number;
    count: number;
};

export function tmp_GetItemProps(id: tmp_ItemId): tmp_ItemProps {
    const tmp_itemTable = {
        bomb: {
            text: '[itemBombName]',
            tip: '[itemBombInfo]',
            asset: 'block.bomb.png',
            cost: coinsProductDefs.bomb.getCost(app().server.state),
            count: 3,
        },
        cube: {
            text: '[itemBaseballName]',
            tip: '[itemBaseballInfo]',
            asset: 'block.cube.all.png',
            cost: coinsProductDefs.baseball.getCost(app().server.state),
            count: 3,
        },
        rocket: {
            text: '[itemRocketName]',
            tip: '[itemRocketInfo]',
            asset: 'block.rocket.horizontal.png',
            cost: coinsProductDefs.rocket.getCost(app().server.state),
            count: 3,
        },
    };

    switch (id) {
        case 'bomb':
        case 'cube':
        case 'rocket':
            return tmp_itemTable[id];
        case 'dart':
        case 'bullet':
        case 'drill':
        case 'roulette':
            return boosterMap[id];
    }
    throw Error();
}

// types
//-----------------------------------------------------------------------------
export type PurchasePopupOptions = {
    id: tmp_ItemId;
    onOk?: BasicAsyncHandler;
    onClose?: BasicAsyncHandler;
    onCoins: BasicAsyncHandler;
} & PopupOptions;

// constants
//-----------------------------------------------------------------------------
const styles = {
    width: 700,
    height: 660,
};
const manifest = {
    bg: 'frame.popup.default.png',
    button: 'button.green.png',
    close: 'button.close.png',
    panel: 'panel.purchase.png',
};
//!!!xxx
/*
    item purchase popup
*/
export class PurchasePopup extends PopupScreen {
    // fields
    //-------------------------------------------------------------------------
    // events
    public onOk?: BasicAsyncHandler;
    // scene
    protected main: Layout;

    private _okButton: ConsumeButton;

    public get okButton(): ConsumeButton {
        return this._okButton;
    }

    // impl
    //-------------------------------------------------------------------------
    public preload(options: Partial<PurchasePopupOptions>) {
        return app().resource.loadAssets([
            ...Object.values(manifest),
            ...ConsumeButton.assets(),
            tmp_GetItemProps(options.id).asset,
        ]);
    }

    public override async spawning(options: PurchasePopupOptions) {
        void super.spawning({
            ...options,
            width: styles.width,
            height: styles.height,
            underlay: 0.6,
        });
        const { text, tip, asset, cost, count } = tmp_GetItemProps(options.id);

        // set fields
        this.onOk = options.onOk;
        this.onClose = options.onClose;

        const title = new BasicText({
            text,
            style: {
                align: 'center',
                fontSize: 50,
                fontWeight: 'bold',
                fill: '#FFFFFF',
                stroke: '#092933',
                strokeThickness: 6,
                lineJoin: 'round',
                dropShadow: true,
                dropShadowAngle: Math.PI / 2,
                dropShadowAlpha: 0.3,
            },
        });
        uiSizeToWidth(title, options.width - 170); // make room for x button

        const coinView = new PopupCoinView();
        coinView.onPress = options.onCoins;

        // scene
        this.baseLayout.addContent({
            // background
            bg: {
                content: new NineSlicePlane(Texture.from(manifest.bg), 95, 128, 32, 40).props({
                    width: styles.width,
                    height: styles.height,
                }),
                styles: {
                    width: styles.width,
                    height: styles.height,
                },
            },
            coins: {
                content: coinView,
                styles: {
                    position: 'topCenter',
                    marginTop: -23,
                    marginLeft: -130,
                },
            },
            header: title
                ? {
                      content: title,
                      styles: {
                          position: 'topCenter',
                          marginTop: 38,
                      },
                  }
                : null,
            main: {
                content: (this.main = new Layout({
                    styles: {
                        width: '100%',
                        height: '100%',
                    },
                })),
                styles: {
                    width: styles.width - 40,
                    height: styles.width - 40,
                    position: 'center',
                },
            },
        });

        if (this.onClose) {
            const button = new UpDownButton({
                up: manifest.close,
                down: manifest.close,
                animate: true,
            });
            button.props({
                pivot: { x: button.width * 0.5, y: button.height * 0.5 },
                x: 68,
                y: 24,
                onPress: async () => this.onClose?.(),
            });
            this.baseLayout.addContent({
                // close button
                close: {
                    content: button,
                    styles: {
                        marginTop: 61,
                        marginRight: -15,
                        position: 'topRight',
                    },
                },
            });
        }

        // ok button
        if (this.onOk) {
            const okButton = (this._okButton = new ConsumeButton({
                image: manifest.button,
                text: '[popupItemButton]',
                consumeAmount: cost,
                slice: {
                    width: 360,
                    height: 122,
                    left: 30,
                    top: 16,
                    right: 30,
                    bottom: 32,
                },
                style: {
                    fill: 'FFF',
                    fontSize: 50,
                    fontWeight: 'bold',
                    lineJoin: 'round',
                    fontStyle: 'normal',
                    dropShadow: true,
                    dropShadowDistance: 1,
                },
            }));
            okButton.props({
                y: this.baseLayout.height - 100,
                pivot: { x: okButton.width * 0.5, y: okButton.height * 0.5 },
                onPress: async () => this.onOk?.(),
            });
            uiAlignCenterX(this.baseLayout, okButton); // Align with pivot to enable scale animation from center
            this.baseLayout.addChild(okButton);
        }

        const tipText = new BasicText({
            text: textLocaleFormat(tip).replace('\n', ' '),
            style: {
                fontSize: 40,
                fontWeight: 'bold',
                fill: 0,
            },
        });
        uiSizeToWidth(tipText, 380);
        const icon = Sprite.from(asset);
        icon.scale.set(1.2);
        const iconMargin = -10;
        const countMargin = iconMargin + icon.height * 0.4;
        this.baseLayout.addContent({
            panel: {
                content: Sprite.from(manifest.panel),
                styles: {
                    position: 'center',
                },
            },
            name: {
                content: tipText,
                styles: {
                    position: 'center',
                    marginTop: 108,
                },
            },
            icon: {
                content: icon,
                styles: {
                    position: 'center',
                    marginTop: iconMargin,
                },
            },
            count: {
                content: new BasicText({
                    text: `x${count}`,
                    style: {
                        fontSize: 38,
                        fontWeight: 'bold',
                        fill: '#FFF976',
                        strokeThickness: 6,
                        lineJoin: 'round',
                    },
                }),
                styles: {
                    position: 'center',
                    marginTop: countMargin,
                },
            },
        });
    }
}
