import { Container, DisplayObject, ITextStyle, Sprite, Text } from 'pixi.js';

import { textLocaleFormat } from '../../util/textTools';
import { uiScaleToHeight } from '../uiTools';

// types
//-----------------------------------------------------------------------------
// public
export type MarkupBuilderOptions = {
    // formatted text
    format: string;
    // base text style
    style: Partial<ITextStyle>;
    // whether to reformat non-markup text. such as for locale tags.
    literal?: boolean;
};

// private
type TagId = 'icon' | 'props' | 'style';

// private: stack
type PropsEntry = {
    id: 'props';
    props: any;
};
type StyleEntry = {
    id: 'style';
    style: Partial<ITextStyle>;
};
type StackEntry = PropsEntry | StyleEntry;

/*
    markup text. format example
        normal text <style fill="#f00">red text</>
*/
export class MarkupRenderer {
    // fields
    //-------------------------------------------------------------------------
    // input
    private _format: string;
    // state
    private _stack: StackEntry[] = [];
    private _objects: DisplayObject[] = [];
    private _x = 0;
    private _y = 0;
    private _height = 0;
    // factorys
    private _tagBuilders: Record<TagId, (props: any) => void> = {
        icon: this._handleIcon,
        props: this._handleProps,
        style: this._handleStyle,
    };

    // init
    //-------------------------------------------------------------------------
    constructor(options: MarkupBuilderOptions) {
        // set format. locale format first unless literal.
        this._format = options.literal ? options.format : textLocaleFormat(options.format);

        // push initial style
        this._stack.push({
            id: 'style',
            style: options.style,
        });
    }

    // api
    //-------------------------------------------------------------------------
    public build(): DisplayObject[] {
        const rxTags = /<[^>]+>/g;
        const format = this._format;
        const iTag = format.matchAll(rxTags);
        const groups = format.split(rxTags);

        // for each split element (text components)
        for (const group of groups) {
            // parse text
            this._parseText(group);

            // parse tag
            const tag = iTag.next();
            if (!tag.done) this._parseTag(tag.value[0]);
        }

        return this._objects;
    }

    // private: parsing
    //-------------------------------------------------------------------------
    private _parseText(format: string) {
        // require non-empty
        if (format.length > 0) {
            // split into separate lines
            format.split(/\n/g).forEach((line, index) => {
                // if new line
                if (index > 0) this._nextRow();

                // create view. apply current style and props.
                const view = new Text(line, this._getStyle()).props(this._getProps());

                // add view
                this._addObject(view);
            });
        }
    }

    private _parseTag(format: string) {
        // parse match
        const match = /<([/\w]+)\s*(.*)>/.exec(format);

        // get tag
        const tag = match[1];

        // if pop char, then pop stack
        if (tag.startsWith('/')) {
            this._stack.pop();
            return;
        }

        // parse properties
        const props =
            match[2]?.split(/\s+/g).reduce((all, format) => {
                this._parseProp(all, format);
                return all;
            }, {} as any) || {};

        // build
        this._tagBuilders[tag as TagId]?.call(this, props);
    }

    private _parseProp(props: any, format: string) {
        const match = /(\w+)=(\S+)/.exec(format);
        if (match?.length === 3) {
            try {
                props[match[1]] = JSON.parse(match[2]);
            } catch {}
        }
    }

    // private: tag handlers
    //-------------------------------------------------------------------------
    private _handleIcon(props: any) {
        // create sprite. apply current props.
        const view = Sprite.from(props.image).props(this._getProps());

        // do fit
        if (props.fit) uiScaleToHeight(view, props.fit, true);

        // add view
        this._addObject(view);
    }

    private _handleProps(props: any) {
        // clone top props, merge props, and push
        this._stack.push({
            id: 'props',
            props: Object.assign({}, this._getProps(), props),
        });
    }

    private _handleStyle(props: any) {
        // clone top style, merge props, and push
        this._stack.push({
            id: 'style',
            style: Object.assign({}, this._getStyle(), props),
        });
    }

    // private: etc
    //-------------------------------------------------------------------------
    private _addObject(object: Container) {
        // set position
        object.x += this._x;
        object.y += this._y;

        // update current row height
        this._height = Math.max(this._height, object.height);

        // add to objects
        this._objects.push(object);

        // increment x offset
        this._x += object.width;
    }

    private _nextRow() {
        // update y
        this._y += this._height;

        // reset row state
        this._x = 0;
        this._height = 0;
    }

    private _getStyle(): Partial<ITextStyle> {
        // find first style at top of the stack
        for (let i = this._stack.length; i--; ) {
            const entry = this._stack[i];
            if (entry.id === 'style') return entry.style;
        }
        return undefined;
    }

    private _getProps(): any {
        // find first props at top of the stack
        for (let i = this._stack.length; i--; ) {
            const entry = this._stack[i];
            if (entry.id === 'props') return entry.props;
        }
        return {};
    }
}
