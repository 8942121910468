import { Spacer } from '@play-co/flex';
import { Sprite, Texture } from 'pixi.js';

import { getCoinImage, ProductItem } from '../../../replicant/defs/product';
import { formatResourceCount } from '../../lib/tools/appTools';
import { BasicText } from '../../lib/ui/text/BasicText';
import { layoutConfig } from './layout';
import { ShopBuyButton } from './ShopBuyButton';
import { ShopListItem } from './ShopListItem';

export class ShopCoinPackItem extends ShopListItem {
    constructor(item: ProductItem) {
        super({
            height: 150,
            alignItems: 'center',
        });

        // create coins icon
        const resourceId = getCoinImage(item.coins);
        const coinTexture = Texture.from(resourceId);
        this.content
            .addChild(new Sprite(coinTexture))
            .setFlex({
                marginLeft: layoutConfig.padding,
            })
            .setProportionalFlexHeight(80);

        // create coins text
        this.content.addChild(
            new BasicText({
                text: `x${formatResourceCount(item.coins)}`,
                style: {
                    fontSize: 40,
                    ...layoutConfig.fontBlack,
                },
            }).setFlex({
                marginLeft: layoutConfig.halfPadding,
            }),
        );

        // add spacer
        this.content.addChild(new Spacer());

        // create buy button
        this.content.addChild(
            new ShopBuyButton(item).setFlex({
                marginRight: layoutConfig.halfPadding,
            }),
        ).button.onPress = async () => this.onPress(item.id);
    }
}
