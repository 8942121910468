import type { Component, Entity2D } from '@play-co/odie';

import type { BlockId, BlockProps } from '../defs/block';
import { CollideEvent, CollideId, Collider } from '../defs/collide';

// types
//-----------------------------------------------------------------------------
export type BlockOptions = {
    id: BlockId;
    implId: string;
    props: BlockProps;
};

/*
    common block component
*/
export class BlockComponent implements Component {
    // constants
    //---------------------------------------------------------------------------
    static readonly NAME = 'block';

    // fields
    //---------------------------------------------------------------------------
    // injected
    public entity!: Entity2D;
    // input
    public blockId!: BlockId;
    public props!: BlockProps;
    public implId!: string;
    // state
    private _width: number;
    private _height: number;

    // properties
    //---------------------------------------------------------------------------
    // width
    public get width(): number {
        return this._width;
    }

    // height
    public get height(): number {
        return this._height;
    }

    // height
    public get overlap(): boolean {
        return !!this.props.overlap;
    }

    // impl
    //---------------------------------------------------------------------------
    init(options: BlockOptions): void {
        this.blockId = options.id;
        this.implId = options.implId;
        this.props = options.props;
        this._width = this.props.width || 1;
        this._height = this.props.height || 1;
    }

    // api
    //---------------------------------------------------------------------------
    public collide(id: CollideId, collider?: Collider) {
        this.entity.setModified(BlockComponent, { collide: { id, collider } as CollideEvent });
    }
}
