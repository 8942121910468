import { Ticker } from 'pixi.js';

import { trackPerformance } from '../../app/lib/analytics/performance';

const ANALYTICS_TIMER = 60 * 1000; // Send analytics event timer
const MAX_FRAME_DT = 2 * 1000; // Maximum delta to take into consideration
const START_TRACKING_AFTER = 20 * 1000; // Only log performance data a while after launch

type GamePerformance = {
    stdDevCount: number;
    stdDevMean: number;
    stdDevSquared: number;
    ticks: number;
    fpsTime: number;
};

export class PerformanceAnalyticsClass {
    private paused = false;
    // Help to track first event
    private isFirstEvent = true;

    private timeTo: {
        // Listed in estimated order of occurrence
        appConstructor?: number;
        initializeAsync?: number;
        replicantLogin?: number;
        earlyLoadTournament?: number;
        blockingAssets?: number;
        startGameAsync?: number;
        entryPreChecks?: number;
        entryFinalEvent?: number;
        gameVisible?: number;
        paymentsReady?: number;
    } = {};

    private lastTickTime = 0;

    private readonly session: GamePerformance = {
        stdDevCount: 0,
        stdDevMean: 0,
        stdDevSquared: 0,
        ticks: 0,
        fpsTime: 0,
    };

    private readonly realtime: GamePerformance = {
        stdDevCount: 0,
        stdDevMean: 0,
        stdDevSquared: 0,
        ticks: 0,
        fpsTime: 0,
    };

    public init() {
        Ticker.shared.add(() => this.tick());
        setInterval(() => this.trackAnalytics(), ANALYTICS_TIMER);
    }

    public pause() {
        this.paused = true;
    }

    public resume() {
        this.paused = false;
    }

    public trackTimeTo(key: keyof PerformanceAnalyticsClass['timeTo']) {
        this.timeTo[key] = Math.round(window.performance.now()) / 1000;
    }

    private tick() {
        if (this.paused) return;

        const timeNow = performance.now();
        const delta = timeNow - this.lastTickTime;

        // Discard the first tick and any "alt-tabs"
        // Also ignore the first few seconds of the game launching
        if (this.lastTickTime !== 0 && delta < MAX_FRAME_DT && timeNow > START_TRACKING_AFTER) {
            this.updateVariance(delta, this.realtime);
            this.updateVariance(delta, this.session);
            this.updateFPS(delta, this.realtime);
            this.updateFPS(delta, this.session);
        }

        this.lastTickTime = timeNow;
    }

    // https://en.wikipedia.org/wiki/Algorithms_for_calculating_variance#Welford's_online_algorithm
    private updateVariance(value: number, stats: GamePerformance) {
        stats.stdDevCount++;

        const delta = value - stats.stdDevMean;

        stats.stdDevMean += delta / stats.stdDevCount;

        const delta2 = value - stats.stdDevMean;

        stats.stdDevSquared += delta * delta2;
    }

    private getVariance(stats: GamePerformance): number {
        if (stats.stdDevCount === 0) return 0;

        return stats.stdDevSquared / stats.stdDevCount;
    }

    private getVarianceSample(stats: GamePerformance): number {
        if (stats.stdDevCount === 0) return 0;

        return stats.stdDevSquared / (stats.stdDevCount - 1);
    }

    private updateFPS(delta: number, stats: GamePerformance) {
        stats.fpsTime += delta;
        stats.ticks++;
    }

    private getFPS(stats: GamePerformance): number {
        if (stats.ticks === 0) return 0;

        return 1000 / (stats.fpsTime / stats.ticks);
    }

    private resetStats(stats: GamePerformance) {
        stats.fpsTime = 0;
        stats.stdDevCount = 0;
        stats.stdDevMean = 0;
        stats.stdDevSquared = 0;
        stats.ticks = 0;
    }

    private trackAnalytics() {
        if (this.paused) return;

        const memoryInfo = (performance as any)?.memory || {};

        trackPerformance({
            // Is this first session event
            isFirst: this.isFirstEvent,

            // Whole session average params
            fps: this.getFPS(this.session),
            choppiness: this.getVariance(this.session),
            choppinessSample: this.getVarianceSample(this.session),

            // Average params between current performance events and previous performance events
            realtimeFPS: this.getFPS(this.realtime),
            realtimeChoppiness: this.getVariance(this.realtime),
            realtimeChoppinessSample: this.getVarianceSample(this.realtime),

            jsHeapSizeLimit: memoryInfo.jsHeapSizeLimit,
            jsHeapSizeTotal: memoryInfo.totalJSHeapSize,
            jsHeapSizeUsed: memoryInfo.usedJSHeapSize,

            // Listed in estimated order of occurrence
            timeToAppConstructor: this.timeTo.appConstructor,
            timeToInitializeAsync: this.timeTo.initializeAsync,
            timeToReplicantLogin: this.timeTo.replicantLogin,
            timeToEarlyLoadTournament: this.timeTo.earlyLoadTournament,
            timeToBlockingAssets: this.timeTo.blockingAssets,
            timeToStartGameAsync: this.timeTo.startGameAsync,
            timeToEntryPreChecks: this.timeTo.entryPreChecks,
            timeToEntryFinalEvent: this.timeTo.entryFinalEvent,
            timeToGameVisible: this.timeTo.gameVisible,
            timeToPaymentsReady: this.timeTo.paymentsReady,
        });

        // After each performance event we start collecting statistic again
        this.resetStats(this.realtime);

        // Set first event to false after first event was sent
        this.isFirstEvent = false;
    }
}

export const PerformanceAnalytics = new PerformanceAnalyticsClass();
