import { i18n, Plugin, PluginOptions } from '@play-co/astro';
import { analytics } from '@play-co/gcinstant';

import { coreIsNewPlayer } from '../../replicant/components/core';
import { LanguageId } from '../../replicant/defs/settings';
import app from '../getApp';
import { windowReload } from '../lib/util/device';
import type { App } from '../App';

// types
//-----------------------------------------------------------------------------
export interface SettingsServiceOptions extends PluginOptions {
    forcedLanguage?: LanguageId;
    preferredLanguage?: LanguageId;
}

/*
    general settings service
*/
export class SettingsService extends Plugin {
    // fields
    private _options: SettingsServiceOptions;
    //-------------------------------------------------------------------------
    // inject
    public override app: App;

    // properties
    //-------------------------------------------------------------------------
    // language
    public get language(): LanguageId {
        return app().server.state.language as LanguageId;
    }

    // music
    public get music(): boolean {
        return app().server.state.bgm;
    }

    // sound
    public get sound(): boolean {
        return app().server.state.sfx;
    }

    // api
    //-------------------------------------------------------------------------
    public async setMusic(value: boolean) {
        await app().server.invoke.settingsSetBGM({ bgm: value });
        this._updateMusic();
        analytics.setUserProperties({
            bgm: value ? 'on' : 'off',
        } as any);
    }

    public async setSound(value: boolean) {
        await app().server.invoke.settingsSetSFX({ sfx: value });
        this._updateSound();
        analytics.setUserProperties({
            sound: value ? 'on' : 'off',
        } as any);
    }

    public async setLanguage(language: LanguageId) {
        // Dont reload the game if player selects current lang
        if (app().settings.language === language) return;

        await app().server.invoke.settingsSetLanguage({ language });

        await windowReload();
    }

    // init
    //-------------------------------------------------------------------------
    constructor(app: App, options: Partial<SettingsServiceOptions>) {
        super(app, options);
        this.app = app;

        this._options = options;
    }

    public async start(): Promise<void> {
        const forcedLanguage = this._options.forcedLanguage;
        const preferredLanguage = this._options.preferredLanguage;
        if (preferredLanguage && coreIsNewPlayer(app().server.state)) {
            await app().server.invoke.settingsSetLanguage({ language: preferredLanguage });
        }
        if (forcedLanguage) {
            await app().server.invoke.settingsSetLanguage({ language: forcedLanguage });
        }

        await this._updateLanguage();
        this._updateMusic();
        this._updateSound();
    }

    // private: updaters
    //-------------------------------------------------------------------------
    private async _updateLanguage(): Promise<void> {
        // sync language with i18n module
        await i18n.changeLanguage(this.language);
    }

    private _updateMusic() {
        // update music mute state
        this.app.music.mute = !this.music;
    }

    private _updateSound() {
        // update sound mute state
        this.app.sound.mute = !this.sound;
    }
}
