import { FlexContainer, FlexLayoutProps } from '@play-co/flex';
import { NineSlicePlane, Sprite, Texture } from 'pixi.js';

import { BasicText } from '../../lib/ui/text/BasicText';
import { layoutConfig } from './layout';
import { manifest } from './manifest';

const panelHeight = 165;

export interface ShopItemGroupOptions {
    text: string;
    fontSize?: number;
}

export class ShopItemPanel extends FlexContainer {
    constructor(props?: Partial<FlexLayoutProps>) {
        super({
            ...props,
            marginLeft: layoutConfig.halfPadding,
        });

        // add bg
        this.addChild(new NineSlicePlane(Texture.from(manifest.panelItem))).setFlex({
            position: 'absolute',
            width: '100%',
            height: '100%',
        });

        // add corner
        this.addChild(new Sprite(Texture.from(manifest.panelItemCorner))).setFlex({
            position: 'absolute',
            right: 2,
            bottom: 2,
        });
    }

    protected createText(opts: ShopItemGroupOptions) {
        return new BasicText({
            text: opts.text,
            style: {
                fontSize: opts.fontSize ?? 30,
                ...layoutConfig.fontBlack,
                strokeThickness: 10,
            },
        });
    }
}

export class ShopItemInfiniteLives extends ShopItemPanel {
    constructor(opts: ShopItemGroupOptions) {
        super({
            width: 110,
            height: panelHeight,
            alignItems: 'center',
            justifyContent: 'center',
        });

        // add centred container
        const container = this.addChild(
            new FlexContainer({
                height: '100%',
                alignItems: 'center',
                justifyContent: 'space-between',
                direction: 'column',
            }),
        );

        // add icon
        container.addChild(new Sprite(Texture.from(manifest.unlimitedLivesIcon)).setFlex({ marginTop: 20 }));

        // add text
        container.addChild(this.createText(opts).setFlex({ top: -5 }));
    }
}

export class ShopItemGroup extends ShopItemPanel {
    constructor(opts: ShopItemGroupOptions) {
        super({
            width: 160,
            height: panelHeight,
        });

        // add text
        this.addChild(this.createText(opts)).setFlex({
            position: 'absolute',
            bottom: 5,
            right: 10,
        });
    }
}

export class ShopItemBombGroup extends ShopItemGroup {
    constructor(opts: ShopItemGroupOptions) {
        super(opts);

        // add icons
        this.addChild(
            new Sprite(Texture.from(manifest.iconFirecracker)).setFlex({
                position: 'absolute',
                left: 0,
                top: 0,
            }),
        );

        // add baseball
        this.addChild(
            new Sprite(Texture.from(manifest.iconBaseball)).setFlex({
                position: 'absolute',
                right: 10,
                top: 10,
            }),
        );

        // add bomb
        this.addChild(
            new Sprite(Texture.from(manifest.iconBomb)).setFlex({
                position: 'absolute',
                left: 15,
                bottom: 10,
            }),
        );
    }
}

export class ShopItemRunnerGroup extends ShopItemGroup {
    constructor(opts: ShopItemGroupOptions) {
        super(opts);

        // add icons
        this.addChild(
            new Sprite(Texture.from(manifest.iconFan)).setFlex({
                position: 'absolute',
                left: 10,
                top: 10,
            }),
        );

        // add metal bat
        this.addChild(
            new Sprite(Texture.from(manifest.iconBat)).setFlex({
                position: 'absolute',
                right: 10,
                top: 5,
            }),
        );

        // add fireball
        this.addChild(
            new Sprite(Texture.from(manifest.iconFireball)).setFlex({
                position: 'absolute',
                left: 10,
                bottom: 15,
            }),
        );

        // add runner
        this.addChild(
            new Sprite(Texture.from(manifest.iconRunner)).setFlex({
                position: 'absolute',
                left: 60,
                top: 50,
            }),
        );
    }
}
