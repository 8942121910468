import type { App } from './App';

let instance: App | null = null;

export default function app(): App {
    if (!instance) throw new Error('App is not set');

    return instance;
}

export function setApp(app: App) {
    instance = app;
}
