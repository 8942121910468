import { IFlow } from '../../lib/pattern/IFlow';
import NakedPromise from '../../lib/pattern/NakedPromise';
import { getTeamMoraleScore } from '../../replicant/components/teamMorale';
import { sleep } from '../../replicant/util/jsTools';
import app from '../getApp';
import { trackTaskFinish } from '../lib/analytics/task';
import { HomeScreen } from '../main/home/HomeScreen';
import { TaskEndingFlow } from './TaskEndingFlow';
import { TaskIntroFlow } from './TaskIntroFlow';

const completeSound = 'task-complete.mp3';

export class TaskCompleteFlow implements IFlow {
    private readonly _complete = new NakedPromise<boolean>();

    // init
    //-------------------------------------------------------------------------

    // impl
    //-------------------------------------------------------------------------
    public async execute() {
        await this._actionTaskComplete();

        // preloads
        void app().resource.loadAsset(completeSound);

        return this._complete;
    }

    private async _actionComplete(success: boolean) {
        this._complete.resolve(success);
    }

    private async _actionTaskComplete() {
        const tapPromise = new NakedPromise();

        // play task complete sound, timed to when item shows
        void sleep(0.7).then(() => app().sound.play(completeSound, { volume: 0.7 }));

        const level = app().server.state.task.level;
        void app().nav.open('taskCompleteScreen', {
            level,
            onScreenPress: async () => {
                tapPromise.resolve();
            },
        });
        await tapPromise;

        await new TaskEndingFlow({ taskLevel: level }).execute();

        const stateTask = app().server.state.task;

        stateTask.introSeen // if seen then it means we just completed and ended up at max level
            ? void app().nav.open('homeScreen', {})
            : await new TaskIntroFlow({ taskLevel: stateTask.level }).execute();

        void this._actionComplete(true);
    }
}
