import type { EntityClass, EntityType, KeyByComponent, Vector2 } from '@play-co/odie';
import { createEntity, DefineEntity, Entity2D, randomItem } from '@play-co/odie';
import { Container } from 'pixi.js';

import { AndroidBlockComponent } from '../components/AndroidBlockComponent';
import { AnimalBlockComponent } from '../components/AnimalBlockComponent';
import { BasicBlockComponent } from '../components/BasicBlockComponent';
import { BlockComponent } from '../components/BlockComponent';
import { BombBlockComponent } from '../components/BombBlockComponent';
import { CageBlockComponent } from '../components/CageBlockComponent';
import { CandleBlockComponent } from '../components/CandleBlockComponent';
import { ChainBlockComponent } from '../components/ChainBlockComponent';
import { ChameleonBlockComponent } from '../components/ChameleonBlockComponent';
import { CoffinBlockComponent } from '../components/CoffinBlockComponent';
import { CubeBlockComponent } from '../components/CubeBlockComponent';
import { DogBlockComponent } from '../components/DogBlockComponent';
import { DoorBlockComponent } from '../components/DoorBlockComponent';
import { FireboxBlockComponent } from '../components/FireboxBlockComponent';
import { FuguBlockComponent } from '../components/FuguBlockComponent';
import { GlowComponent } from '../components/GlowComponent';
import { HatBlockComponent } from '../components/HatBlockComponent';
import { HazardBlockComponent } from '../components/HazardBlockComponent';
import { IceBlockComponent } from '../components/IceBlockComponent';
import { KappaBlockComponent } from '../components/KappaBlockComponent';
import { KettleBlockComponent } from '../components/KettleBlockComponent';
import { LockBlockComponent } from '../components/LockBlockComponent';
import { MiniFuguBlockComponent } from '../components/MiniFuguBlockComponent';
import { MysteryBlockComponent } from '../components/MysteryBlockComponent';
import { PantherBlockComponent } from '../components/PantherBlockComponent';
import { PipeBlockComponent } from '../components/PipeBlockComponent';
import { PondBlockComponent } from '../components/PondBlockComponent';
import { PositionComponent } from '../components/PositionComponent';
import { PrisonBlockComponent } from '../components/PrisonBlockComponent';
import { RocketBlockComponent } from '../components/RocketBlockComponent';
import { ShellBlockComponent } from '../components/ShellBlockComponent';
import { SirenBlockComponent } from '../components/SirenBlockComponent';
import { SkullBlockComponent } from '../components/SkullBlockComponent';
import { SlimeBlockComponent } from '../components/SlimeBlockComponent';
import { SpiderBlockComponent } from '../components/SpiderBlockComponent';
import { StatueBlockComponent } from '../components/StatueBlockComponent';
import { ThrowerBlockComponent } from '../components/ThrowerBlockComponent';
import { VirusBlockComponent } from '../components/VirusBlockComponent';
import { WallBlockComponent } from '../components/WallBlockComponent';
import { ZombieBlockComponent } from '../components/ZombieBlockComponent';
import type {
    AndroidBlockProps,
    AnimalBlockProps,
    BasicBlockProps,
    BlockId,
    BlockProps,
    BlockType,
    BombBlockProps,
    CageBlockProps,
    CandleBlockProps,
    ChainBlockProps,
    ChameleonBlockProps,
    CoffinBlockProps,
    CubeBlockProps,
    DogBlockProps,
    DoorBlockProps,
    FireboxBlockProps,
    FuguBlockProps,
    HatBlockProps,
    HazardBlockProps,
    IceBlockProps,
    KappaBlockProps,
    KettleBlockProps,
    LockBlockProps,
    MiniFuguBlockProps,
    MysteryBlockProps,
    PantherBlockProps,
    PipeBlockProps,
    PondBlockProps,
    PrisonBlockProps,
    RocketBlockProps,
    ShellBlockProps,
    SirenBlockProps,
    SkullBlockProps,
    SlimeBlockProps,
    SpiderBlockProps,
    StatueBlockProps,
    ThrowerBlockProps,
    VirusBlockProps,
    WallBlockProps,
    ZombieBlockProps,
} from '../defs/block';
import { blockPropsMap } from '../defs/block';
import { BlockDef } from '../defs/map';
import type { GameScene } from '../GameScene';
import { blockOptionToWall } from '../util/blockTools';

// types
//-----------------------------------------------------------------------------
const BlockEntityDef = DefineEntity(Entity2D, PositionComponent, BlockComponent);
export type BlockEntity = EntityType<typeof BlockEntityDef>;

const AndroidBlockEntityDef = DefineEntity(Entity2D, PositionComponent, BlockComponent, AndroidBlockComponent);
export type AndroidBlockEntity = EntityType<typeof AndroidBlockEntityDef>;

const AnimalBlockEntityDef = DefineEntity(Entity2D, PositionComponent, BlockComponent, AnimalBlockComponent);
export type AnimalBlockEntity = EntityType<typeof AnimalBlockEntityDef>;

const BasicBlockEntityDef = DefineEntity(Entity2D, PositionComponent, BlockComponent, BasicBlockComponent);
export type BasicBlockEntity = EntityType<typeof BasicBlockEntityDef>;

const BombBlockEntityDef = DefineEntity(Entity2D, PositionComponent, BlockComponent, BombBlockComponent, GlowComponent);
export type BombBlockEntity = EntityType<typeof BombBlockEntityDef>;

const CageBlockEntityDef = DefineEntity(Entity2D, PositionComponent, BlockComponent, CageBlockComponent);
export type CageBlockEntity = EntityType<typeof CageBlockEntityDef>;

const CandleBlockEntityDef = DefineEntity(Entity2D, PositionComponent, BlockComponent, CandleBlockComponent);
export type CandleBlockEntity = EntityType<typeof CandleBlockEntityDef>;

const ChainBlockEntityDef = DefineEntity(Entity2D, PositionComponent, BlockComponent, ChainBlockComponent);
export type ChainBlockEntity = EntityType<typeof ChainBlockEntityDef>;

const ChameleonBlockEntityDef = DefineEntity(Entity2D, PositionComponent, BlockComponent, ChameleonBlockComponent);
export type ChameleonBlockEntity = EntityType<typeof ChameleonBlockEntityDef>;

const CoffinBlockEntityDef = DefineEntity(Entity2D, PositionComponent, BlockComponent, CoffinBlockComponent);
export type CoffinBlockEntity = EntityType<typeof CoffinBlockEntityDef>;

const CubeBlockEntityDef = DefineEntity(Entity2D, PositionComponent, BlockComponent, CubeBlockComponent, GlowComponent);
export type CubeBlockEntity = EntityType<typeof CubeBlockEntityDef>;

const DogBlockEntityDef = DefineEntity(Entity2D, PositionComponent, BlockComponent, DogBlockComponent);
export type DogBlockEntity = EntityType<typeof DogBlockEntityDef>;

const DoorBlockEntityDef = DefineEntity(Entity2D, PositionComponent, BlockComponent, DoorBlockComponent);
export type DoorBlockEntity = EntityType<typeof DoorBlockEntityDef>;

const FireboxBlockEntityDef = DefineEntity(Entity2D, PositionComponent, BlockComponent, FireboxBlockComponent);
export type FireboxBlockEntity = EntityType<typeof FireboxBlockEntityDef>;

const HatBlockEntityDef = DefineEntity(Entity2D, PositionComponent, BlockComponent, HatBlockComponent);
export type HatBlockEntity = EntityType<typeof HatBlockEntityDef>;

const HazardBlockEntityDef = DefineEntity(Entity2D, PositionComponent, BlockComponent, HazardBlockComponent);
export type HazardBlockEntity = EntityType<typeof HazardBlockEntityDef>;

const IceBlockEntityDef = DefineEntity(Entity2D, PositionComponent, BlockComponent, IceBlockComponent);
export type IceBlockEntity = EntityType<typeof IceBlockEntityDef>;

const KettleBlockEntityDef = DefineEntity(Entity2D, PositionComponent, BlockComponent, KettleBlockComponent);
export type KettleBlockEntity = EntityType<typeof KettleBlockEntityDef>;

const LockBlockEntityDef = DefineEntity(Entity2D, PositionComponent, BlockComponent, LockBlockComponent);
export type LockBlockEntity = EntityType<typeof LockBlockEntityDef>;

const MysteryBlockEntityDef = DefineEntity(Entity2D, PositionComponent, BlockComponent, MysteryBlockComponent);
export type MysteryBlockEntity = EntityType<typeof MysteryBlockEntityDef>;

const PantherBlockEntityDef = DefineEntity(Entity2D, PositionComponent, BlockComponent, PantherBlockComponent);
export type PantherBlockEntity = EntityType<typeof PantherBlockEntityDef>;

const PipeBlockEntityDef = DefineEntity(Entity2D, PositionComponent, BlockComponent, PipeBlockComponent);
export type PipeBlockEntity = EntityType<typeof PipeBlockEntityDef>;

const PondBlockEntityDef = DefineEntity(Entity2D, PositionComponent, BlockComponent, PondBlockComponent);
export type PondBlockEntity = EntityType<typeof PondBlockEntityDef>;

const PrisonBlockEntityDef = DefineEntity(Entity2D, PositionComponent, BlockComponent, PrisonBlockComponent);
export type PrisonBlockEntity = EntityType<typeof PrisonBlockEntityDef>;

const RocketBlockEntityDef = DefineEntity(
    Entity2D,
    PositionComponent,
    BlockComponent,
    RocketBlockComponent,
    GlowComponent,
);
export type RocketBlockEntity = EntityType<typeof RocketBlockEntityDef>;

const SirenBlockEntityDef = DefineEntity(Entity2D, PositionComponent, BlockComponent, SirenBlockComponent);
export type SirenBlockEntity = EntityType<typeof SirenBlockEntityDef>;

const SkullBlockEntityDef = DefineEntity(Entity2D, PositionComponent, BlockComponent, SkullBlockComponent);
export type SkullBlockEntity = EntityType<typeof SkullBlockEntityDef>;

const FuguBlockEntityDef = DefineEntity(Entity2D, PositionComponent, BlockComponent, FuguBlockComponent);
export type FuguBlockEntity = EntityType<typeof FuguBlockEntityDef>;

const MiniFuguBlockEntityDef = DefineEntity(Entity2D, PositionComponent, BlockComponent, MiniFuguBlockComponent);
export type MiniFuguBlockEntity = EntityType<typeof MiniFuguBlockEntityDef>;

const KappaBlockEntityDef = DefineEntity(Entity2D, PositionComponent, BlockComponent, KappaBlockComponent);
export type KappaBlockEntity = EntityType<typeof KappaBlockEntityDef>;

const ShellBlockEntityDef = DefineEntity(Entity2D, PositionComponent, BlockComponent, ShellBlockComponent);
export type ShellBlockEntity = EntityType<typeof ShellBlockEntityDef>;

const SlimeBlockEntityDef = DefineEntity(Entity2D, PositionComponent, BlockComponent, SlimeBlockComponent);
export type SlimeBlockEntity = EntityType<typeof SlimeBlockEntityDef>;

const SpiderBlockEntityDef = DefineEntity(Entity2D, PositionComponent, BlockComponent, SpiderBlockComponent);
export type SpiderBlockEntity = EntityType<typeof SpiderBlockEntityDef>;

const StatueBlockEntityDef = DefineEntity(Entity2D, PositionComponent, BlockComponent, StatueBlockComponent);
export type StatueBlockEntity = EntityType<typeof StatueBlockEntityDef>;

const ThrowerBlockEntityDef = DefineEntity(Entity2D, PositionComponent, BlockComponent, ThrowerBlockComponent);
export type ThrowerBlockEntity = EntityType<typeof ThrowerBlockEntityDef>;

const VirusBlockEntityDef = DefineEntity(Entity2D, PositionComponent, BlockComponent, VirusBlockComponent);
export type VirusBlockEntity = EntityType<typeof VirusBlockEntityDef>;

const WallBlockEntityDef = DefineEntity(Entity2D, PositionComponent, BlockComponent, WallBlockComponent);
export type WallBlockEntity = EntityType<typeof WallBlockEntityDef>;

const ZombieBlockEntityDef = DefineEntity(Entity2D, PositionComponent, BlockComponent, ZombieBlockComponent);
export type ZombieBlockEntity = EntityType<typeof ZombieBlockEntityDef>;

export type PowerBlockEntity = BombBlockEntity | CubeBlockEntity | RocketBlockEntity;

// constants
//-----------------------------------------------------------------------------
const spawnMap = {
    android: _spawnAndroidBlockEntity,
    animal: _spawnAnimalBlockEntity,
    basic: _spawnBasicBlockEntity,
    bomb: _spawnBombBlockEntity,
    cage: _spawnCageBlockEntity,
    candle: _spawnCandleBlockEntity,
    chain: _spawnChainBlockEntity,
    chameleon: _spawnChameleonBlockEntity,
    coffin: _spawnCoffinBlockEntity,
    cube: _spawnCubeBlockEntity,
    dog: _spawnDogBlockEntity,
    door: _spawnDoorBlockEntity,
    firebox: _spawnFireboxBlockEntity,
    hat: _spawnHatBlockEntity,
    hazard: _spawnHazardBlockEntity,
    ice: _spawnIceBlockEntity,
    kappa: _spawnKappaBlockEntity,
    kettle: _spawnKettleBlockEntity,
    lock: _spawnLockBlockEntity,
    miniFugu: _spawnMiniFuguBlockEntity,
    mystery: _spawnMysteryBlockEntity,
    panther: _spawnPantherBlockEntity,
    pipe: _spawnPipeBlockEntity,
    pond: _spawnPondBlockEntity,
    prison: _spawnPrisonBlockEntity,
    randomSpawn: _spawnRandomSpawnBlockEntity,
    rocket: _spawnRocketBlockEntity,
    shell: _spawnShellBlockEntity,
    siren: _spawnSirenBlockEntity,
    skull: _spawnSkullBlockEntity,
    slime: _spawnSlimeBlockEntity,
    spider: _spawnSpiderBlockEntity,
    fugu: _spawnFuguBlockEntity,
    statue: _spawnStatueBlockEntity,
    thrower: _spawnThrowerBlockEntity,
    virus: _spawnVirusBlockEntity,
    wall: _spawnWallBlockEntity,
    zombie: _spawnZombieBlockEntity,
};

const onDespawnMap: { [key in BlockType]?: (entity: BlockEntity) => void } = {
    bomb: _onDespawnBombBlockEntity,
};

// api
//-----------------------------------------------------------------------------
export function spawnBlockEntity(scene: GameScene, def: BlockDef, position: Vector2): BlockEntity {
    const props = blockPropsMap[def.id];
    const entity = spawnMap[props.type](scene, def, props, position);
    const view = entity.c.view2d.view;
    view.scale.set(0.88);
    return entity;
}

export function despawnBlockEntity(scene: GameScene, entity: BlockEntity) {
    // if spawned
    if (entity.scene) {
        // handle entity specific cleanup
        onDespawnMap[entity.c.block.props.type as BlockType]?.(entity);

        // unplace block entity from current position
        unplaceBlockEntity(scene, entity);

        // remove from scene
        scene.removeFromScene(entity);

        // mark despawned. itd be nice if .removeFromScene did this /shrug
        entity.scene = undefined;

        // notify
        scene.events.publish({ id: 'destroy', blockId: entity.c.block.blockId });
    }
}

export function placeBlockEntity(scene: GameScene, entity: BlockEntity, position: Vector2) {
    // add to map
    scene.sessionEntity.c.map.setBlockEntity(entity, position);

    // update block position
    entity.c.position.mapPosition = position;
}

export function unplaceBlockEntity(scene: GameScene, entity: BlockEntity) {
    // remove from map
    scene.sessionEntity.c.map.unsetBlockEntity(entity);
}

export function moveBlockEntity(scene: GameScene, entity: BlockEntity, position: Vector2) {
    // unplace block entity from current position
    unplaceBlockEntity(scene, entity);

    // place block entity at new position
    placeBlockEntity(scene, entity, position);
}

export function swapBlockEntities(scene: GameScene, entity1: BlockEntity, entity2: BlockEntity) {
    const position1 = entity1.c.position.mapPosition;
    const position2 = entity2.c.position.mapPosition;

    // unplace entity 1
    unplaceBlockEntity(scene, entity1);

    // move entity 2 to entity 1 position
    moveBlockEntity(scene, entity2, position1);

    // place entity 1 at entity 2 position
    placeBlockEntity(scene, entity1, position2);
}

// private: spawn
//-----------------------------------------------------------------------------
function _spawnBaseBlockEntity<E extends BlockEntity>(
    Class: EntityClass<[], [], {}, E>,
    scene: GameScene,
    id: BlockId,
    props: BlockProps,
    position: Vector2,
    data?: KeyByComponent<E['c']>,
): E {
    const implId = Object.keys(data)[0];

    // create entity
    const entity = createEntity(Class, {
        view2d: { view: new Container(), layer: 'default' },
        block: { id, implId, props },
        position: { position },
        ...data,
    });

    // place block entity
    placeBlockEntity(scene, entity, position);

    // add to scene
    scene.addToScene(entity);

    return entity;
}

function _spawnAndroidBlockEntity(
    scene: GameScene,
    def: BlockDef,
    props: BlockProps,
    position: Vector2,
): AndroidBlockEntity {
    // spawn base block
    const entity = _spawnBaseBlockEntity(AndroidBlockEntityDef, scene, def.id, props, position, {
        blockAndroid: {
            props: props as AndroidBlockProps,
            option: def.option,
        },
    });

    // build scene
    entity.c.view2d.view.addChild(entity.c.blockAndroid.view);

    return entity;
}

function _spawnAnimalBlockEntity(
    scene: GameScene,
    def: BlockDef,
    props: BlockProps,
    position: Vector2,
): AnimalBlockEntity {
    // spawn base block
    const entity = _spawnBaseBlockEntity(AnimalBlockEntityDef, scene, def.id, props, position, {
        blockAnimal: {
            props: props as AnimalBlockProps,
        },
    });

    // build scene
    entity.c.view2d.view.addChild(entity.c.blockAnimal.view);

    return entity;
}

function _spawnBasicBlockEntity(
    scene: GameScene,
    def: BlockDef,
    props: BlockProps,
    position: Vector2,
): BasicBlockEntity {
    // spawn base block
    const entity = _spawnBaseBlockEntity(BasicBlockEntityDef, scene, def.id, props, position, {
        blockBasic: {
            props: props as BasicBlockProps,
        },
    });

    // build scene
    entity.c.view2d.view.addChild(entity.c.blockBasic.view);

    return entity;
}

function _spawnBombBlockEntity(scene: GameScene, def: BlockDef, props: BlockProps, position: Vector2): BombBlockEntity {
    // spawn base block
    const entity = _spawnBaseBlockEntity(BombBlockEntityDef, scene, def.id, props, position, {
        blockBomb: {
            props: props as BombBlockProps,
        },
    });

    // build scene
    entity.c.view2d.view.addChild(entity.c.glow.view, entity.c.blockBomb.view);

    return entity;
}

function _onDespawnBombBlockEntity(entity: BombBlockEntity) {
    // shutdown glow component
    entity.c.glow.shutdown();
}

function _spawnCageBlockEntity(scene: GameScene, def: BlockDef, props: BlockProps, position: Vector2): CageBlockEntity {
    // spawn base block
    const entity = _spawnBaseBlockEntity(CageBlockEntityDef, scene, def.id, props, position, {
        blockCage: {
            props: props as CageBlockProps,
        },
    });

    // build scene
    entity.c.view2d.view.addChild(entity.c.blockCage.view);

    return entity;
}

function _spawnCandleBlockEntity(
    scene: GameScene,
    def: BlockDef,
    props: BlockProps,
    position: Vector2,
): CandleBlockEntity {
    // spawn base block
    const entity = _spawnBaseBlockEntity(CandleBlockEntityDef, scene, def.id, props, position, {
        blockCandle: {
            props: props as CandleBlockProps,
            option: def.option,
        },
    });

    // build scene
    entity.c.view2d.view.addChild(entity.c.blockCandle.view);

    return entity;
}

function _spawnChainBlockEntity(
    scene: GameScene,
    def: BlockDef,
    props: BlockProps,
    position: Vector2,
): ChainBlockEntity {
    // spawn base block
    const entity = _spawnBaseBlockEntity(ChainBlockEntityDef, scene, def.id, props, position, {
        blockChain: {
            props: props as ChainBlockProps,
            option: def.option,
        },
    });

    // build scene
    entity.c.view2d.view.addChild(entity.c.blockChain.view);

    return entity;
}

function _spawnChameleonBlockEntity(
    scene: GameScene,
    def: BlockDef,
    props: BlockProps,
    position: Vector2,
): ChameleonBlockEntity {
    // spawn base block
    const entity = _spawnBaseBlockEntity(ChameleonBlockEntityDef, scene, def.id, props, position, {
        blockChameleon: {
            props: props as ChameleonBlockProps,
            option: def.option,
        },
    });

    // build scene
    entity.c.view2d.view.addChild(entity.c.blockChameleon.view);

    return entity;
}

function _spawnCoffinBlockEntity(
    scene: GameScene,
    def: BlockDef,
    props: BlockProps,
    position: Vector2,
): CoffinBlockEntity {
    // spawn base block
    const entity = _spawnBaseBlockEntity(CoffinBlockEntityDef, scene, def.id, props, position, {
        blockCoffin: {
            props: props as CoffinBlockProps,
        },
    });

    // build scene
    entity.c.view2d.view.addChild(entity.c.blockCoffin.view);

    return entity;
}

function _spawnCubeBlockEntity(scene: GameScene, def: BlockDef, props: BlockProps, position: Vector2): CubeBlockEntity {
    // spawn base block
    const entity = _spawnBaseBlockEntity(CubeBlockEntityDef, scene, def.id, props, position, {
        blockCube: {
            props: props as CubeBlockProps,
        },
    });

    // build scene
    entity.c.view2d.view.addChild(entity.c.glow.view, entity.c.blockCube.view);

    return entity;
}

function _spawnDogBlockEntity(scene: GameScene, def: BlockDef, props: BlockProps, position: Vector2): DogBlockEntity {
    // spawn base block
    const entity = _spawnBaseBlockEntity(DogBlockEntityDef, scene, def.id, props, position, {
        blockDog: {
            props: props as DogBlockProps,
            option: def.option,
        },
    });

    // build scene
    entity.c.view2d.view.addChild(entity.c.blockDog.view);

    return entity;
}

function _spawnDoorBlockEntity(scene: GameScene, def: BlockDef, props: BlockProps, position: Vector2): DoorBlockEntity {
    // spawn base block
    const entity = _spawnBaseBlockEntity(DoorBlockEntityDef, scene, def.id, props, position, {
        blockDoor: {
            props: props as DoorBlockProps,
            option: def.option,
        },
    });

    // build scene
    entity.c.view2d.view.addChild(entity.c.blockDoor.view);

    return entity;
}

function _spawnFireboxBlockEntity(
    scene: GameScene,
    def: BlockDef,
    props: BlockProps,
    position: Vector2,
): FireboxBlockEntity {
    // spawn base block
    const entity = _spawnBaseBlockEntity(FireboxBlockEntityDef, scene, def.id, props, position, {
        blockFirebox: {
            props: props as FireboxBlockProps,
            option: def.option,
        },
    });

    // build scene
    entity.c.view2d.view.addChild(entity.c.blockFirebox.view);

    return entity;
}

function _spawnHatBlockEntity(scene: GameScene, def: BlockDef, props: BlockProps, position: Vector2): HatBlockEntity {
    // spawn base block
    const entity = _spawnBaseBlockEntity(HatBlockEntityDef, scene, def.id, props, position, {
        blockHat: {
            props: props as HatBlockProps,
        },
    });

    // build scene
    entity.c.view2d.view.addChild(entity.c.blockHat.view);

    return entity;
}

function _spawnHazardBlockEntity(
    scene: GameScene,
    def: BlockDef,
    props: BlockProps,
    position: Vector2,
): HazardBlockEntity {
    // spawn base block
    const entity = _spawnBaseBlockEntity(HazardBlockEntityDef, scene, def.id, props, position, {
        blockHazard: {
            props: props as HazardBlockProps,
        },
    });

    // build scene
    entity.c.view2d.view.addChild(entity.c.blockHazard.view);

    return entity;
}

function _spawnIceBlockEntity(scene: GameScene, def: BlockDef, props: BlockProps, position: Vector2): IceBlockEntity {
    // spawn base block
    const entity = _spawnBaseBlockEntity(IceBlockEntityDef, scene, def.id, props, position, {
        blockIce: {
            props: props as IceBlockProps,
        },
    });

    // build scene
    entity.c.view2d.view.addChild(entity.c.blockIce.view);

    return entity;
}

function _spawnKettleBlockEntity(
    scene: GameScene,
    def: BlockDef,
    props: BlockProps,
    position: Vector2,
): KettleBlockEntity {
    // spawn base block
    const entity = _spawnBaseBlockEntity(KettleBlockEntityDef, scene, def.id, props, position, {
        blockKettle: {
            props: props as KettleBlockProps,
        },
    });

    // build scene
    entity.c.view2d.view.addChild(entity.c.blockKettle.view);

    return entity;
}
function _spawnLockBlockEntity(scene: GameScene, def: BlockDef, props: BlockProps, position: Vector2): LockBlockEntity {
    // spawn base block
    const entity = _spawnBaseBlockEntity(LockBlockEntityDef, scene, def.id, props, position, {
        blockLock: {
            props: props as LockBlockProps,
            option: def.option,
        },
    });

    // build scene
    entity.c.view2d.view.addChild(entity.c.blockLock.view);

    return entity;
}

function _spawnMiniFuguBlockEntity(
    scene: GameScene,
    def: BlockDef,
    props: BlockProps,
    position: Vector2,
): MiniFuguBlockEntity {
    // spawn base block
    const entity = _spawnBaseBlockEntity(MiniFuguBlockEntityDef, scene, def.id, props, position, {
        blockMiniFugu: {
            props: props as MiniFuguBlockProps,
        },
    });

    // build scene
    entity.c.view2d.view.addChild(entity.c.blockMiniFugu.view);

    return entity;
}

function _spawnMysteryBlockEntity(
    scene: GameScene,
    def: BlockDef,
    props: BlockProps,
    position: Vector2,
): MysteryBlockEntity {
    // spawn base block
    const entity = _spawnBaseBlockEntity(MysteryBlockEntityDef, scene, def.id, props, position, {
        blockMystery: {
            props: props as MysteryBlockProps,
            option: def.option,
        },
    });

    // build scene
    entity.c.view2d.view.addChild(entity.c.blockMystery.view);

    return entity;
}

function _spawnPantherBlockEntity(
    scene: GameScene,
    def: BlockDef,
    props: BlockProps,
    position: Vector2,
): PantherBlockEntity {
    // spawn base block
    const entity = _spawnBaseBlockEntity(PantherBlockEntityDef, scene, def.id, props, position, {
        blockPanther: {
            props: props as PantherBlockProps,
        },
    });

    // build scene
    entity.c.view2d.view.addChild(entity.c.blockPanther.view);

    return entity;
}

function _spawnPipeBlockEntity(scene: GameScene, def: BlockDef, props: BlockProps, position: Vector2): PipeBlockEntity {
    // spawn base block
    const entity = _spawnBaseBlockEntity(PipeBlockEntityDef, scene, def.id, props, position, {
        blockPipe: {
            props: props as PipeBlockProps,
        },
    });

    // build scene
    entity.c.view2d.view.addChild(entity.c.blockPipe.view);

    return entity;
}

function _spawnPondBlockEntity(scene: GameScene, def: BlockDef, props: BlockProps, position: Vector2): PondBlockEntity {
    // spawn base block
    const entity = _spawnBaseBlockEntity(PondBlockEntityDef, scene, def.id, props, position, {
        blockPond: {
            props: props as PondBlockProps,
            option: def.option,
        },
    });

    // build scene
    entity.c.view2d.view.addChild(entity.c.blockPond.view);

    return entity;
}

function _spawnPrisonBlockEntity(
    scene: GameScene,
    def: BlockDef,
    props: BlockProps,
    position: Vector2,
): PrisonBlockEntity {
    // spawn base block
    const entity = _spawnBaseBlockEntity(PrisonBlockEntityDef, scene, def.id, props, position, {
        blockPrison: {
            props: props as PrisonBlockProps,
        },
    });

    // build scene
    entity.c.view2d.view.addChild(entity.c.blockPrison.view);

    return entity;
}

function _spawnRandomSpawnBlockEntity(scene: GameScene, def: BlockDef, props: BlockProps, position: Vector2) {
    // This is a special type of block that will spawn one of the blocks defined in the level data spawns
    // so in fact this block is never spawned, it just spawns another block instead
    const spawns = scene.mapDef.spawns;
    const id = randomItem(spawns);
    return spawnBlockEntity(scene, { id }, position);
}

function _spawnRocketBlockEntity(
    scene: GameScene,
    def: BlockDef,
    props: BlockProps,
    position: Vector2,
): RocketBlockEntity {
    // spawn base block
    const entity = _spawnBaseBlockEntity(RocketBlockEntityDef, scene, def.id, props, position, {
        blockRocket: {
            props: props as RocketBlockProps,
        },
    });

    // build scene
    entity.c.view2d.view.addChild(entity.c.glow.view, entity.c.blockRocket.view);

    return entity;
}

function _spawnShellBlockEntity(
    scene: GameScene,
    def: BlockDef,
    props: BlockProps,
    position: Vector2,
): ShellBlockEntity {
    // spawn base block
    const entity = _spawnBaseBlockEntity(ShellBlockEntityDef, scene, def.id, props, position, {
        blockShell: {
            props: props as ShellBlockProps,
            option: def.option,
        },
    });

    // build scene
    entity.c.view2d.view.addChild(entity.c.blockShell.view);

    return entity;
}

function _spawnSirenBlockEntity(
    scene: GameScene,
    def: BlockDef,
    props: BlockProps,
    position: Vector2,
): SirenBlockEntity {
    // spawn base block
    const entity = _spawnBaseBlockEntity(SirenBlockEntityDef, scene, def.id, props, position, {
        blockSiren: {
            props: props as SirenBlockProps,
            option: def.option,
        },
    });

    // build scene
    entity.c.view2d.view.addChild(entity.c.blockSiren.view);

    return entity;
}

function _spawnSkullBlockEntity(
    scene: GameScene,
    def: BlockDef,
    props: BlockProps,
    position: Vector2,
): SkullBlockEntity {
    // spawn base block
    const entity = _spawnBaseBlockEntity(SkullBlockEntityDef, scene, def.id, props, position, {
        blockSkull: {
            props: props as SkullBlockProps,
            option: def.option,
        },
    });

    // build scene
    entity.c.view2d.view.addChild(entity.c.blockSkull.view);

    return entity;
}

function _spawnSlimeBlockEntity(
    scene: GameScene,
    def: BlockDef,
    props: BlockProps,
    position: Vector2,
): SlimeBlockEntity {
    // spawn base block
    const entity = _spawnBaseBlockEntity(SlimeBlockEntityDef, scene, def.id, props, position, {
        blockSlime: {
            props: props as SlimeBlockProps,
        },
    });

    // build scene
    entity.c.view2d.view.addChild(entity.c.blockSlime.view);

    return entity;
}

function _spawnSpiderBlockEntity(
    scene: GameScene,
    def: BlockDef,
    props: BlockProps,
    position: Vector2,
): SpiderBlockEntity {
    const entity = _spawnBaseBlockEntity(SpiderBlockEntityDef, scene, def.id, props, position, {
        blockSpider: {
            props: props as SpiderBlockProps,
        },
    });

    entity.c.view2d.view.addChild(entity.c.blockSpider.view);

    return entity;
}

function _spawnStatueBlockEntity(
    scene: GameScene,
    def: BlockDef,
    props: BlockProps,
    position: Vector2,
): StatueBlockEntity {
    // spawn base block
    const entity = _spawnBaseBlockEntity(StatueBlockEntityDef, scene, def.id, props, position, {
        blockStatue: {
            props: props as StatueBlockProps,
            option: def.option,
        },
    });

    // build scene
    entity.c.view2d.view.addChild(entity.c.blockStatue.view);

    return entity;
}

function _spawnFuguBlockEntity(scene: GameScene, def: BlockDef, props: BlockProps, position: Vector2): FuguBlockEntity {
    // spawn base block
    const entity = _spawnBaseBlockEntity(FuguBlockEntityDef, scene, def.id, props, position, {
        blockFugu: {
            props: props as FuguBlockProps,
        },
    });

    // build scene
    entity.c.view2d.view.addChild(entity.c.blockFugu.view);

    return entity;
}
function _spawnKappaBlockEntity(
    scene: GameScene,
    def: BlockDef,
    props: BlockProps,
    position: Vector2,
): KappaBlockEntity {
    // spawn base block
    const entity = _spawnBaseBlockEntity(KappaBlockEntityDef, scene, def.id, props, position, {
        blockKappa: {
            props: props as KappaBlockProps,
        },
    });

    // build scene
    entity.c.view2d.view.addChild(entity.c.blockKappa.view);

    return entity;
}

function _spawnThrowerBlockEntity(
    scene: GameScene,
    def: BlockDef,
    props: BlockProps,
    position: Vector2,
): ThrowerBlockEntity {
    // spawn base block
    const entity = _spawnBaseBlockEntity(ThrowerBlockEntityDef, scene, def.id, props, position, {
        blockThrower: {
            props: props as ThrowerBlockProps,
            option: def.option,
        },
    });

    // build scene
    entity.c.view2d.view.addChild(entity.c.blockThrower.view);

    return entity;
}

function _spawnVirusBlockEntity(
    scene: GameScene,
    def: BlockDef,
    props: BlockProps,
    position: Vector2,
): VirusBlockEntity {
    // spawn base block
    const entity = _spawnBaseBlockEntity(VirusBlockEntityDef, scene, def.id, props, position, {
        blockVirus: {
            props: props as VirusBlockProps,
        },
    });

    // build scene
    entity.c.view2d.view.addChild(entity.c.blockVirus.view);

    return entity;
}

function _spawnWallBlockEntity(scene: GameScene, def: BlockDef, props: BlockProps, position: Vector2): WallBlockEntity {
    // parse wall option
    const wallOption = blockOptionToWall(def.option);

    // merge into props
    props = { ...props, ...wallOption.size } as WallBlockProps;

    // spawn base block
    const entity = _spawnBaseBlockEntity(WallBlockEntityDef, scene, def.id, props, position, {
        blockWall: {
            props,
            count: wallOption.count,
            positionHint: position,
        },
    });

    // build scene
    entity.c.view2d.view.addChild(entity.c.blockWall.view);

    return entity;
}

function _spawnZombieBlockEntity(
    scene: GameScene,
    def: BlockDef,
    props: BlockProps,
    position: Vector2,
): ZombieBlockEntity {
    // spawn base block
    const entity = _spawnBaseBlockEntity(ZombieBlockEntityDef, scene, def.id, props, position, {
        blockZombie: {
            props: props as ZombieBlockProps,
        },
    });

    // build scene
    entity.c.view2d.view.addChild(entity.c.blockZombie.view);

    return entity;
}
