import { SB } from '@play-co/replicant';
// state
//-----------------------------------------------------------------------------
export const nativeState = {
    native: SB.object({
        // native app last launched timestamp
        iosLastLaunched: SB.int().min(0),
        androidLastLaunched: SB.int().min(0),

        // native reward feature state (0: unseen, -1: seen, >0: claimed)
        iosRewardTimestamp: SB.int().min(-1),
        androidRewardTimestamp: SB.int().min(-1),

        // Store payload for session resume.
        payload: SB.string().optional(),
    }),
};

// constants
//-----------------------------------------------------------------------------
export default {
    appLaunchUrl: 'co.play.baseball://main',
    googlePlayURL: 'https://play.google.com/store/apps/details?id=co.play.baseball',
    appStoreURL:
        'https://apps.apple.com/us/app/%E7%9B%AE%E6%8C%87%E3%81%9B-%E7%94%B2%E5%AD%90%E5%9C%92%E3%81%B8%E3%81%AE%E9%81%93/id6479584050',
    rewards: {
        boosters: 5,
        coins: 200,
    },
};
