import app from '../../../../getApp';
import { arrayRandom } from '../../../../../replicant/util/jsTools';
import { blockColorMap, ColorId } from '../../defs/block';
import { CollideEvent, CollideId } from '../../defs/collide';
import type { SirenBlockEntity } from '../../entities/BlockEntity';
import { despawnBlockEntity } from '../../entities/BlockEntity';
import { GameScene } from '../../GameScene';
import { mapGetPan } from '../../util/mapTools';
import { BreakEffect } from '../effects/BreakEffect';
import { InvalidEffect } from '../effects/InvalidEffect';
import { CollideHandler, ICollision } from './ICollision';

/*
    siren block collision resolver
*/
export class SirenCollision implements ICollision {
    // fields
    //-------------------------------------------------------------------------
    // input
    private readonly _scene: GameScene;
    private readonly _entity: SirenBlockEntity;
    // map
    private readonly _resolvers: { [key in CollideId]?: CollideHandler } = {
        tap: this._resolveTap,
        block: this._resolveBlock,
        attack: this._resolveAttack,
    };

    // init
    //-------------------------------------------------------------------------
    constructor(scene: GameScene, entity: SirenBlockEntity) {
        this._scene = scene;
        this._entity = entity;
    }

    // impl
    //-------------------------------------------------------------------------
    public async resolve(event: CollideEvent): Promise<boolean> {
        return this._resolvers[event.id]?.call(this, event);
    }

    // private: resolvers
    //-------------------------------------------------------------------------
    private async _resolveTap(event: CollideEvent) {
        // invalid effect
        await new InvalidEffect(this._entity).execute();
        return false;
    }

    private async _resolveBlock(event: CollideEvent) {
        const collider = event.collider;

        // if block collider
        if (collider?.type === 'block') {
            const colliderProps = collider.entity.c.block.props;

            // if block collider is basic block, poke siren with its color
            if (colliderProps.type === 'basic') {
                void this._poke(colliderProps.color);
                return true;
            }
        }
        return false;
    }

    private async _resolveAttack(event: CollideEvent) {
        // choose random color among existing sirens
        const colorId = arrayRandom(Object.keys(this._entity.c.blockSiren.view.sirens) as ColorId[]);

        // poke that color
        if (colorId) void this._poke(colorId);
        return true;
    }

    // private: actions
    //-------------------------------------------------------------------------
    private async _poke(colorId: ColorId) {
        const c = this._entity.c;
        const component = c.blockSiren;
        const position = c.position.mapPosition;

        // poke siren component with color of collider
        component.poke(colorId);

        // if siren block empty, despawn it
        if (component.isEmpty()) {
            despawnBlockEntity(this._scene, this._entity);
        }

        // play sound
        void app().sound.play(`puzzle-siren-${component.isEmpty() ? 'destroy' : 'poke'}.mp3`, {
            pan: mapGetPan(this._entity.c.position.mapPosition),
        });

        // execute break effect
        return new BreakEffect(this._scene, {
            position,
            size: this._entity.c.block.props,
            color: blockColorMap[colorId].base,
        }).execute();
    }
}
