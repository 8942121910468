import { Container } from 'pixi.js';

import { IFlow } from '../../../lib/pattern/IFlow';
import NakedPromise from '../../../lib/pattern/NakedPromise';
import { tween } from '../../../lib/util/tweens';
import settings from '../../../replicant/defs/settings';
import { sleep } from '../../../replicant/util/jsTools';
import { NavLayer } from '../../defs/nav';
import {
    trackTutorialFinish,
    trackTutorialStart,
    trackTutorialStepFinish,
    trackTutorialStepStart,
} from '../../lib/analytics/tutorial';
import { HomeScreen } from '../../main/home/HomeScreen';
import { IntroScreen } from '../../main/intro/IntroScreen';
import app from '../../getApp';

// types
//-----------------------------------------------------------------------------
const id = 'baseball';

const TAP_PAUSE = 0.15;

/*
    Baseball tutorial flow
*/
export class TutorialFlow implements IFlow {
    // fields
    //-------------------------------------------------------------------------
    private _homeScreen: HomeScreen;
    private _introScreen: IntroScreen;
    // state
    private _tutorialOffset: number[];
    // step map
    private _steps = [
        { name: 'intro', handler: this._intro, substeps: 2 },
        { name: 'select', handler: this._select, substeps: 2 },
        { name: 'name', handler: this._name, substeps: 3 },
        { name: 'guy', handler: this._guy, substeps: 5 },
        { name: 'mainPlayer', handler: this._mainPlayer, substeps: 5 },
        { name: 'girl', handler: this._girl, substeps: 5 },
        { name: 'end', handler: this._end, substeps: 2 },
    ];

    // props
    //-------------------------------------------------------------------------
    // step
    private get step(): number {
        return app().server.state.tutorial.step;
    }

    private set step(step: number) {
        void app().server.invoke.tutorialSetStep({ step });
    }

    // init
    //-------------------------------------------------------------------------
    constructor() {
        this._tutorialOffset = this._steps.reduce(
            (acc, cur) => {
                acc.push(acc[acc.length - 1] + cur.substeps);
                return acc;
            },
            [0],
        );
    }

    // impl
    //-------------------------------------------------------------------------
    async execute(): Promise<void> {
        // this.step = 2;

        // run tutorial
        await this._run();

        // complete
        await this._complete();
    }

    // private: control
    //-------------------------------------------------------------------------
    private async _run() {
        // track overall tutorial start
        trackTutorialStart({ stepIndex: 0, stepName: id });

        // for each tutorial step
        for (; this.step < this._steps.length; this.step++) {
            const entry = this._steps[this.step];
            const stepName = `${id}.${entry.name}`;

            // execute tutorial
            await entry.handler.call(this, {
                stepName,
            });
        }
    }

    private async _complete() {
        // complete tutorial
        await app().server.invoke.tutorialComplete();

        trackTutorialFinish({ stepIndex: this._tutorialOffset[this.step], stepName: id });

        // close any popups
        await app().nav.closeLayer(NavLayer.popup);
    }

    // private: steps
    //-------------------------------------------------------------------------
    private async _intro() {
        let stepIndex = this._tutorialOffset[this.step];
        trackTutorialStepStart({ stepIndex, stepName: `intro_stadium` });
        this._introScreen = (await app().nav.open('introScreen')) as IntroScreen;

        this._introScreen.panDown();
        const introPromise = new NakedPromise();
        await this._introScreen.spawnBubbleTap({
            dialogText: `[tutorialDialog0]`,
            promise: introPromise,
            narrator: 'arrow',
            bubbleOffset: { x: -170, y: -290 },
        });

        // load for the rest of the tutorial in intro scene
        void this._introScreen.preloadLazyAsset('scorePanelIntro');

        await introPromise;
        trackTutorialStepFinish({ stepIndex, stepName: `intro_stadium` });
        stepIndex++;
        trackTutorialStepStart({ stepIndex, stepName: `intro_score` });

        const introPromise2 = new NakedPromise();
        void this._introScreen.spawnBubbleTap({
            dialogText: `[tutorialDialog1]`,
            promise: introPromise2,
            narrator: 'arrow',
            // bubbleOffset: { x: -655, y: -290 }, // use this offset if score panel is spawned before bubble
            bubbleOffset: { x: -170, y: -290 },
        });
        void this._introScreen.spawnScore({
            visitor: '[dreamTeam0]',
            home: '[dreamTeam1]',
        });

        // load for the rest of the tutorial in intro scene
        void this._introScreen.preloadLazyAsset('dualBg');
        void this._introScreen.preloadPlayers();
        await introPromise2;

        trackTutorialStepFinish({ stepIndex, stepName: `intro_score` });
    }

    private async _select() {
        let stepIndex = this._tutorialOffset[this.step];
        trackTutorialStepStart({ stepIndex, stepName: `select_bubble` });

        this._introScreen = (await app().nav.open('introScreen', {
            dual: true,
            fadeTime: 0.8, // custom fade in from previous step with pitch black screen
        })) as IntroScreen;

        const introPromise = new NakedPromise();

        void this._introScreen.preloadSpeedLines();
        void this._introScreen.preloadChoiceView();

        await this._introScreen.spawnBubbleTap({
            dialogText: `[tutorialDialog2]`,
            promise: introPromise,
            narrator: 'arrow',
            bubbleOffset: { x: -170, y: -290 },
        });

        await introPromise;
        trackTutorialStepFinish({ stepIndex, stepName: `select_bubble` });
        stepIndex++;
        trackTutorialStepStart({ stepIndex, stepName: `select_player` });
        await sleep(TAP_PAUSE);

        const titleView = this._introScreen.spawnTiltedHeader({ title: '[tutorialChooseBatter]', offsetY: -493 });

        const choicePromise = new NakedPromise<number>();
        void this._introScreen.spawnBubbleChoice({
            choices: ['[choice0]', '[choice1]'],
            promise: choicePromise,
            narrator: 'simple',
            bubbleOffset: { x: -170, y: -560 },
        });

        // preload next scene
        void app().nav.preload('homeScreen');

        await choicePromise;

        void this._despawnView(titleView);

        void this._introScreen.swingAnimation();
        await sleep(3.2);
        await this._introScreen.exitFade();
        // stay faded for a little bit
        await sleep(1.3);
        // trackTutorialStepFinish({ stepIndex, stepName: `swing` });
        trackTutorialStepFinish({ stepIndex, stepName: `select_player` });
    }

    private async _name() {
        let stepIndex = this._tutorialOffset[this.step];
        trackTutorialStepStart({ stepIndex, stepName: `wake_up` });
        // new empty home screen when waking up from dream
        this._homeScreen = (await app().nav.open('homeScreen', {
            hideMenu: true,
            skipPointer: true,
            players: 'none',
            customOpening: true,
            altPicher: true,
            tutorialName: true,
        })) as HomeScreen;

        const namePopupId = 'namePopup';
        void app().nav.preload(namePopupId);
        // allow custom opnening to animate a little bit before spawning dialog
        await sleep(1.9);
        const dreamPromise = new NakedPromise();
        await this._homeScreen.spawnBubbleTap({
            dialogText: `[tutorialDialog3]`,
            promise: dreamPromise,
            narrator: 'arrow',
            bubbleOffset: { x: 0, y: -150 },
        });
        await dreamPromise;

        trackTutorialStepFinish({ stepIndex, stepName: `wake_up` });
        stepIndex++;
        trackTutorialStepStart({ stepIndex, stepName: `name_bubble` });
        await sleep(TAP_PAUSE);

        const preNamePromise = new NakedPromise();
        await this._homeScreen.spawnBubbleTap({
            dialogText: `[tutorialDialog4]`,
            promise: preNamePromise,
            narrator: 'arrow',
            bubbleOffset: { x: 0, y: -150 },
        });
        await preNamePromise;
        trackTutorialStepFinish({ stepIndex, stepName: `name_bubble` });
        stepIndex++;
        trackTutorialStepStart({ stepIndex, stepName: `name_set` });
        await sleep(TAP_PAUSE);

        const namePromise = new NakedPromise<string>();

        const isEn = app().settings.language === 'en';
        const preFilledName = isEn ? settings.defaultNameEN : settings.defaultNameJA;

        const header = this._homeScreen.spawnTiltedHeader({ title: '[tutorialChooseName]' });
        void app().nav.open(namePopupId, { onConfirm: namePromise, preFilledName });
        const name = await namePromise;
        void this._despawnView(header);
        // render new name
        void this._homeScreen.setSetTutorialName(name, true);

        const popupPromise = app().nav.close(namePopupId);

        //make sure keyboard and popup has time to close and then force a resize to fix layout edge case on android devices
        void popupPromise.then(() => sleep(0.5)).then(() => this._homeScreen.forcedResize());

        const allowTap = false;
        const animate = true;
        await sleep(0.5);

        await this._homeScreen.spawnTeamMates({ allowTap, animate });
        await this._homeScreen.spawnPlayer({ allowTap, player: 'player', animate });

        // store name before exiting the step
        await app().server.invoke.playerSetName({ name });
        trackTutorialStepFinish({ stepIndex, stepName: `name_set` });
    }

    private async _guy() {
        let stepIndex = this._tutorialOffset[this.step];
        trackTutorialStepStart({ stepIndex, stepName: `guy_tap` });
        if (!this._homeScreen) {
            this._homeScreen = (await app().nav.open('homeScreen', {
                hideMenu: true,
                skipPointer: true,
                altPicher: true,
                tutorialName: true,
            })) as HomeScreen;
        }

        void this._homeScreen.preloadSkillIcons();
        void this._homeScreen.preloadTeamMateBg();
        const guyTarget = this._homeScreen.teamMateGuy;

        const tapPromise = new NakedPromise();
        guyTarget.onPress = async () => tapPromise.resolve();
        // point to garden button in HomeScreen
        void app().nav.open('tipScreen', {
            type: 'hand',
            motion: 'tap',
            pointerOffset: { x: 0, y: -30 },
            allowInput: true,
            targets: [guyTarget.getBounds()],
        });

        await tapPromise;
        void app().nav.close('tipScreen');
        trackTutorialStepFinish({ stepIndex, stepName: `guy_tap` });
        stepIndex++;
        trackTutorialStepStart({ stepIndex, stepName: `guy_1` });

        // scene reset with  zoomed in view of one player
        this._homeScreen = (await app().nav.open('homeScreen', {
            hideMenu: true,
            skipPointer: true,
            players: 'teamMateGuy',
            tutorialName: true,
        })) as HomeScreen;

        const tapPromise1 = new NakedPromise();
        await this._homeScreen.spawnBubbleTap({
            dialogText: `[tutorialDialog5]`,
            promise: tapPromise1,
        });
        await tapPromise1;

        trackTutorialStepFinish({ stepIndex, stepName: `guy_1` });
        stepIndex++;
        trackTutorialStepStart({ stepIndex, stepName: `guy_2` });
        void this._homeScreen.preloadChoiceView();
        await sleep(TAP_PAUSE);

        const tapPromise2 = new NakedPromise();
        await this._homeScreen.spawnBubbleTap({
            dialogText: `[tutorialDialog6]`,
            promise: tapPromise2,
        });

        await tapPromise2;
        trackTutorialStepFinish({ stepIndex, stepName: `guy_2` });
        stepIndex++;
        trackTutorialStepStart({ stepIndex, stepName: `guy_3` });

        void this._homeScreen.updateSKillHint('teamMateGuy');
        await sleep(TAP_PAUSE);

        const choicePromise = new NakedPromise<number>();
        void this._homeScreen.spawnBubbleChoice({
            dialogText: '[tutorialDialog7]',
            choices: ['[choice2]', '[choice3]'],
            promise: choicePromise,
        });
        await choicePromise;

        void this._homeScreen.playPlayerAnimation({ id: 'batter_swing' }).then(() => {
            void this._homeScreen.playPlayerAnimation({ id: 'batter_idle', loop: true, mix: 0.2 });
        });
        // sleep a little for the animation
        await sleep(0.7);
        trackTutorialStepFinish({ stepIndex, stepName: `guy_3` });
        stepIndex++;
        trackTutorialStepStart({ stepIndex, stepName: `guy_4` });
        await sleep(TAP_PAUSE);

        const returnPromise = new NakedPromise();
        await this._homeScreen.spawnBubbleTap({
            dialogText: `[tutorialDialog8]`,
            promise: returnPromise,
            buttonOverride: true,
        });
        await returnPromise;
        trackTutorialStepFinish({ stepIndex, stepName: `guy_4` });
    }

    private async _mainPlayer() {
        let stepIndex = this._tutorialOffset[this.step];
        trackTutorialStepStart({ stepIndex, stepName: `main_player_tap` });
        // force fresh scene with full team
        this._homeScreen = (await app().nav.open('homeScreen', {
            hideMenu: true,
            skipPointer: true,
            altPicher: true,
            tutorialName: true,
        })) as HomeScreen;

        void this._homeScreen.preloadSkillIcons();
        const target = this._homeScreen.mainPlayer;

        const tapPromise = new NakedPromise();
        target.onPress = async () => tapPromise.resolve();
        // point to garden button in HomeScreen
        void app().nav.open('tipScreen', {
            type: 'hand',
            motion: 'tap',
            pointerOffset: { x: 0, y: -50 },
            allowInput: true,
            targets: [target.getBounds()],
        });

        await tapPromise;
        void app().nav.close('tipScreen');
        trackTutorialStepFinish({ stepIndex, stepName: `main_player_tap` });
        stepIndex++;
        trackTutorialStepStart({ stepIndex, stepName: `main_player_1` });

        // scene reset with  zoomed in view of one player
        this._homeScreen = (await app().nav.open('homeScreen', {
            hideMenu: true,
            skipPointer: true,
            players: 'player', // player close up
            altPicher: true,
            tutorialName: true,
        })) as HomeScreen;

        const tapPromise1 = new NakedPromise();
        await this._homeScreen.spawnBubbleTap({
            dialogText: `[tutorialDialog9]`,
            promise: tapPromise1,
        });
        await tapPromise1;
        trackTutorialStepFinish({ stepIndex, stepName: `main_player_1` });
        stepIndex++;
        trackTutorialStepStart({ stepIndex, stepName: `main_player_2` });
        void this._homeScreen.preloadChoiceView();
        await sleep(TAP_PAUSE);

        const tapPromise2 = new NakedPromise();
        await this._homeScreen.spawnBubbleTap({
            dialogText: `[tutorialDialog10]`,
            promise: tapPromise2,
        });

        await tapPromise2;
        trackTutorialStepFinish({ stepIndex, stepName: `main_player_2` });
        stepIndex++;
        trackTutorialStepStart({ stepIndex, stepName: `main_player_3` });

        void this._homeScreen.updateSKillHint('player');
        await sleep(TAP_PAUSE);

        const choicePromise = new NakedPromise<number>();
        void this._homeScreen.spawnBubbleChoice({
            dialogText: '[tutorialDialog11]',
            choices: ['[choice4]', '[choice5]'],
            promise: choicePromise,
        });
        await choicePromise;

        void this._homeScreen.playPlayerAnimation({ id: 'pitcher_throw' }).then(() => {
            void this._homeScreen.playPlayerAnimation({ id: 'pitcher_idle2', loop: true, mix: 0.09 });
        });
        // sleep a little for the animation
        await sleep(0.7);
        trackTutorialStepFinish({ stepIndex, stepName: `main_player_3` });
        stepIndex++;
        trackTutorialStepStart({ stepIndex, stepName: `main_player_4` });
        await sleep(TAP_PAUSE);

        const returnPromise = new NakedPromise();
        await this._homeScreen.spawnBubbleTap({
            dialogText: `[tutorialDialog12]`,
            promise: returnPromise,
            buttonOverride: true,
        });
        await returnPromise;
        trackTutorialStepFinish({ stepIndex, stepName: `main_player_4` });
    }

    private async _girl() {
        let stepIndex = this._tutorialOffset[this.step];
        trackTutorialStepStart({ stepIndex, stepName: `girl_tap` });
        // force fresh scene with full team
        this._homeScreen = (await app().nav.open('homeScreen', {
            hideMenu: true,
            skipPointer: true,
            altPicher: true,
            tutorialName: true,
        })) as HomeScreen;

        void this._homeScreen.preloadSkillIcons();
        void this._homeScreen.preloadTeamMateBg();
        const target = this._homeScreen.teamMateGirl;

        const tapPromise = new NakedPromise();
        target.onPress = async () => tapPromise.resolve();
        // point to garden button in HomeScreen
        void app().nav.open('tipScreen', {
            type: 'hand',
            motion: 'tap',
            pointerOffset: { x: 0, y: -30 },
            allowInput: true,
            targets: [target.getBounds()],
        });

        await tapPromise;
        void app().nav.close('tipScreen');
        trackTutorialStepFinish({ stepIndex, stepName: `girl_tap` });
        stepIndex++;
        trackTutorialStepStart({ stepIndex, stepName: `girl_1` });
        await sleep(TAP_PAUSE);

        // scene reset with  zoomed in view of one player
        this._homeScreen = (await app().nav.open('homeScreen', {
            hideMenu: true,
            skipPointer: true,
            players: 'teamMateGirl', // player close up
            tutorialName: true,
        })) as HomeScreen;

        const tapPromise1 = new NakedPromise();
        await this._homeScreen.spawnBubbleTap({
            dialogText: `[tutorialDialog13]`,
            promise: tapPromise1,
        });
        await tapPromise1;
        trackTutorialStepFinish({ stepIndex, stepName: `girl_1` });
        stepIndex++;
        trackTutorialStepStart({ stepIndex, stepName: `girl_2` });
        void this._homeScreen.preloadChoiceView();
        await sleep(TAP_PAUSE);

        const tapPromise2 = new NakedPromise();
        await this._homeScreen.spawnBubbleTap({
            dialogText: `[tutorialDialog14]`,
            promise: tapPromise2,
        });

        await tapPromise2;
        trackTutorialStepFinish({ stepIndex, stepName: `girl_2` });
        stepIndex++;
        trackTutorialStepStart({ stepIndex, stepName: `girl_3` });

        void this._homeScreen.updateSKillHint('teamMateGirl');
        await sleep(TAP_PAUSE);

        const choicePromise = new NakedPromise<number>();
        void this._homeScreen.spawnBubbleChoice({
            dialogText: '[tutorialDialog15]',
            choices: ['[choice6]', '[choice7]'],
            promise: choicePromise,
        });
        await choicePromise;

        void this._homeScreen.playPlayerAnimation({ id: 'fielder_catchthrow' }).then(() => {
            void this._homeScreen.playPlayerAnimation({ id: 'fielder_idle', loop: true, mix: 0.2 });
        });
        // sleep a little for the animation
        await sleep(0.7);
        trackTutorialStepFinish({ stepIndex, stepName: `girl_3` });
        stepIndex++;
        trackTutorialStepStart({ stepIndex, stepName: `girl_4` });
        await sleep(TAP_PAUSE);

        const returnPromise = new NakedPromise();
        await this._homeScreen.spawnBubbleTap({
            dialogText: `[tutorialDialog16]`,
            promise: returnPromise,
            buttonOverride: true,
        });
        await returnPromise;
        trackTutorialStepFinish({ stepIndex, stepName: `girl_4` });
    }

    private async _end() {
        let stepIndex = this._tutorialOffset[this.step];
        trackTutorialStepStart({ stepIndex, stepName: `end_1` });
        // force fresh home scene with full team
        this._homeScreen = (await app().nav.open('homeScreen', {
            hideMenu: true,
            skipPointer: true,
            altPicher: true,
            tutorialName: true,
        })) as HomeScreen;

        const endPromise1 = new NakedPromise();
        await this._homeScreen.spawnBubbleTap({
            dialogText: `[tutorialDialog17]`,
            promise: endPromise1,
            narrator: 'arrow',
            bubbleOffset: { x: 0, y: -150 },
        });
        await endPromise1;
        trackTutorialStepFinish({ stepIndex, stepName: `end_1` });
        stepIndex++;
        trackTutorialStepStart({ stepIndex, stepName: `end_2` });
        await sleep(TAP_PAUSE);

        const endPromise2 = new NakedPromise();
        await this._homeScreen.spawnBubbleTap({
            dialogText: `[tutorialDialog18]`,
            promise: endPromise2,
            narrator: 'arrow',
            bubbleOffset: { x: 0, y: -150 },
        });
        await endPromise2;
        trackTutorialStepFinish({ stepIndex, stepName: `end_2` });
    }

    // helper
    private async _despawnView(view: Container) {
        await view.animate().add(view, { alpha: 0 }, 0.3, tween.pow2Out);
        view.removeSelf();
    }
}
