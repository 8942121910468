import { action, Immutable } from '@play-co/replicant';

import { MutableState, State, SyncActionAPI } from '../defs/replicant';
import task, { Task } from '../defs/task';
import { getTeamMoraleScore, updateTeamMorale } from './teamMorale';
import teamMorale from '../defs/teamMorale';
import { getAbTest } from '../util/replicantTools';

// actions
//-----------------------------------------------------------------------------
export const taskActions = {
    updateTask: action((state: MutableState, options: { stepIndex: number }, api: SyncActionAPI) => {
        const { stepIndex } = options;
        const level = state.task.level;
        const step = task.taskList[level].steps[stepIndex];
        const cost = step.starCost;

        if (!isEnoughStarsToStep(state.task, stepIndex, state.stars)) throw new Error(`Not enough stars to step`);

        state.stars -= cost;
        // set timestamp or set -1 for non timed-step to indicate task is complete
        if (step.timedStep) {
            // start timed step
            state.task.steps[stepIndex].timestamp = api.date.now();
        } else {
            // mark non timed-step as completed
            state.task.steps[stepIndex].timestamp = -1;
        }
    }),
    updateTaskIntro: action((state: MutableState, _, api: SyncActionAPI) => {
        state.task.introSeen = true;
    }),
    updateTaskTutorial: action((state: MutableState, options: { stepIndex: number }, api: SyncActionAPI) => {
        const { stepIndex } = options;
        const level = state.task.level;
        const step = task.taskList[level].steps[stepIndex];
        const cost = step.starCost;

        if (level > 0) throw new Error(`Not allowed to update tutorial task at level ${level}`);
        if (getTaskCompleteCount(state.task, api.date.now()) > 0) {
            throw new Error(`Not allowed to update tutorial task`);
        }

        // No stars verification for tutorial just in case they manage to have 0 stars for some reason
        // if 0 allow to continue and finish tutorial
        if (state.stars - cost >= 0) {
            state.stars -= cost;
        }
        // set timestamp or set -1 for non timed-step to indicate task is complete
        if (step.timedStep) {
            // start timed step
            state.task.steps[stepIndex].timestamp = api.date.now();
        } else {
            // mark non timed-step as completed
            state.task.steps[stepIndex].timestamp = -1;
        }

        // mark intro as seen
        state.task.introSeen = true;
    }),
    updateTaskSet: action((state: MutableState, _, api: SyncActionAPI) => {
        const now = api.date.now();
        updateTaskSet(state, now);
    }),
    completeTaskSet: action((state: MutableState, _, api: SyncActionAPI) => {
        const now = api.date.now();
        updateTaskSet(state, now);

        const before = getTeamMoraleScore(state, now);
        updateTeamMorale(state, teamMorale.score.task, now);
        const after = getTeamMoraleScore(state, now);
        const moraleScore = { before, after };
        return { moraleScore };
    }),
};

export function onTaskInit(api: SyncActionAPI, state: MutableState) {
    // add initial tasks
    if (state.task.level === 0 && state.task.steps.length === 0) {
        state.task.steps = task.taskList[0].steps.map((_) => ({ timestamp: 0 }));
    }

    // make sure the player gets latest tasks if completed and new tasks are available
    if (state.task.timestamp === -1 && state.task.level + 1 < task.taskList.length) {
        updateTaskSet(state, api.date.now());
    }
}

function updateTaskSet(state: MutableState, now: number) {
    const level = state.task.level;

    // validate all steps are complete
    state.task.steps.forEach((stateStep, index) => {
        const step = task.taskList[level].steps[index];
        if (step.timedStep) {
            if (now < stateStep.timestamp + step.timedStep.time || stateStep.timestamp !== -1) {
                throw new Error(`Timed step ${index} is not complete`);
            }
        } else {
            if (stateStep.timestamp !== -1) throw new Error(`Step ${index} is not complete`);
        }
    });

    let allowProgress = false;
    // validate if overall task time is complete if needed
    if (task.taskList[state.task.level].completeTime > 0) {
        if (state.task.timestamp > 0) {
            if (!isTaskFullyCompleted(state.task, now)) {
                throw new Error(`Main step is not finished`);
            }
            allowProgress = true;
        } else {
            state.task.timestamp = now;
        }
    } else {
        allowProgress = true;
    }

    // increment level if possible
    if (allowProgress) {
        // Make sure there's another task set available, if not stay in completed state
        if (level + 1 < task.taskList.length) {
            // all good complete task set
            state.task.introSeen = false;
            state.task.level++;
            state.task.timestamp = 0;
            // set new tasks for new level
            state.task.steps = task.taskList[state.task.level].steps.map((_) => ({ timestamp: 0 }));
        } else {
            // Max level, set -1 to indicate task is complete and keep the rest of the data
            state.task.timestamp = -1;
        }
    }
}

export function isTaskStepsCompleted(stateTask: Immutable<Task>, now: number) {
    for (let i = 0; i < stateTask.steps.length; i++) {
        if (!isTaskStepComplete(stateTask, now, i)) return false;
    }

    return true;
}

export function isTaskFullyCompleted(stateTask: Immutable<Task>, now: number) {
    // not timed, allow for completion without duration
    if (task.taskList[stateTask.level].completeTime === 0) {
        return true;
    }
    // verify final time
    if (stateTask.timestamp > 0 && now > stateTask.timestamp + task.taskList[stateTask.level].completeTime) {
        return true;
    }

    return false;
}

export function getTaskCompleteCount(stateTask: Immutable<Task>, now: number): number {
    let count = 0;
    for (let i = 0; i < stateTask.steps.length; i++) {
        const stateStep = stateTask.steps[i];
        const step = task.taskList[stateTask.level].steps[i];
        if (step.timedStep) {
            if (
                stateStep.timestamp === 0 ||
                (stateStep.timestamp > 0 && now < stateStep.timestamp + step.timedStep.time)
            ) {
                continue;
            }
        } else {
            if (stateStep.timestamp !== -1) continue;
        }

        count++;
    }

    return count;
}

export function isTaskMaxLevel(stateTask: Immutable<Task>): boolean {
    return stateTask.level >= task.taskList.length - 1 && stateTask.timestamp === -1;
}

export function isEnoughStarsToStep(stateTask: Immutable<Task>, stepIndex: number, availableStars: number): boolean {
    const level = stateTask.level;
    const step = task.taskList[level].steps[stepIndex];
    const cost = step.starCost;

    return cost <= availableStars;
}

// assumed step cost design as implemened for MVP, higher index -> more expensive
// run sort before iteration if cost is random
export function getAvailableSteps(state: State, now: number): number {
    let count = 0;
    let stars = state.stars;
    state.task.steps.forEach((_, index) => {
        if (!isTaskStepComplete(state.task, now, index) && isEnoughStarsToStep(state.task, index, stars)) {
            stars -= task.taskList[state.task.level].steps[index].starCost;
            count++;
        }
    });
    return count;
}

export function shouldStartTaskIntroTutorial(state: State, now: number) {
    return state.task.level === 0 && getTaskCompleteCount(state.task, now) === 0;
}

function isTaskStepComplete(stateTask: Immutable<Task>, now: number, index: number) {
    const stateStep = stateTask.steps[index];
    const step = task.taskList[stateTask.level].steps[index];
    if (step.timedStep) {
        if (now < stateStep.timestamp + step.timedStep.time) {
            return false;
        }
    } else {
        if (stateStep.timestamp !== -1) return false;
    }
    return true;
}
