import { waitFor } from '@play-co/astro';
import { gsap } from 'gsap';
import { Assets, Container, Sprite, Ticker } from 'pixi.js';

import { PositionType } from '../../../../lib/defs/types';
import { IAnimation } from '../../../../lib/pattern/IAnimation';
import { tweenPower2In } from '../../../../lib/pixi/particles/behaviors/particle_tweens';
import { arrayRandom } from '../../../../replicant/util/jsTools';
import { integerRandom } from '../../../../replicant/util/mathTools';
import { blockColorMap, ColorId, colorIds } from '../defs/block';
import { BlockEntity } from '../entities/BlockEntity';
import { mapToViewPosition } from '../util/mapTools';
import { CubeBeamAnimation } from './CubeBeamAnimation';
import app from '../../../getApp';

// types
//-----------------------------------------------------------------------------
// public
export type CubeAnimationOptions = {
    color: ColorId;
    position: PositionType;
    targets: BlockEntity[];
};

// public
type BeamEntry = {
    beam: CubeBeamAnimation;
    target: BlockEntity;
};

// constants
//-----------------------------------------------------------------------------
const manifest = {
    all: 'block.cube.all.png',
    blue: 'block.cube.blue.png',
    green: 'block.cube.green.png',
    purple: 'block.cube.purple.png',
    red: 'block.cube.red.png',
    yellow: 'block.cube.yellow.png',
    tingle: 'fx.cube.tingle.png',
};

/*
    cube effect animation
*/
export class CubeAnimation extends Container implements IAnimation {
    // fields
    //-------------------------------------------------------------------------
    // input
    private _options: CubeAnimationOptions;
    // scene
    private _beams: BeamEntry[] = [];
    // state
    private _completedPromise: Promise<any>;
    // handlers
    private _beamStepper = () => this._stepBeams();

    // promise
    //-------------------------------------------------------------------------
    public get completed() {
        return this._completedPromise;
    }

    // init
    //-------------------------------------------------------------------------
    constructor(options: CubeAnimationOptions) {
        super();
        this._options = options;
    }

    static assets(): string[] {
        return [...CubeBeamAnimation.assets(), ...Object.values(manifest)];
    }

    // impl
    //-------------------------------------------------------------------------
    public async start(): Promise<void> {
        // load assets
        await Assets.load(CubeAnimation.assets());

        // create beams
        this._createBeams();

        // create cube
        const cube = this._createCube();

        // start animate beams
        void this._animateBeamsStart();

        // animate cube. beams last long as this does.
        await this._animateCube(cube);

        // stop animating beams
        void this._animateBeamsStop();

        // animate explode
        this._completedPromise = this._animateExplode();
    }

    public stop() {}

    // private: steppers
    //-------------------------------------------------------------------------
    private _stepBeams() {
        // for each beam
        for (const entry of this._beams) {
            // if target despawned, stop and despawn early
            if (!entry.target.scene) {
                entry.beam.stop();
                this.removeChild(entry.beam);
            }
        }
    }

    // private: scene
    //-------------------------------------------------------------------------
    private _createBeams() {
        const { color, position, targets } = this._options;
        const from = mapToViewPosition(position, true);

        // for each target
        for (const target of targets) {
            const fromGlow = target === targets[0];
            const targetColor = blockColorMap[color === 'all' ? arrayRandom(colorIds) : color].glow;
            const to = mapToViewPosition(target.c.position.mapPosition, true);
            const direction = to.sub(from);
            const length = direction.length();

            // spawn beam. only one needs a from glow
            const beam = new CubeBeamAnimation({ color: targetColor, length, fromGlow });
            beam.rotation = direction.angle();

            // add to list
            this._beams.push({ beam, target });
        }
    }

    private _createCube(): Sprite {
        const { color } = this._options;
        const cube = Sprite.from(manifest[color]);
        cube.anchor.set(0.5);
        cube.zIndex = 1;
        cube.visible = false;
        return cube;
        /*
        const { color } = this._options;
        const asset = color === 'all' ? manifest.bigcube : manifest.cube;

        // create animation
        const cube = new SpritesheetAnimation(asset);
        cube.zIndex = 1;
        cube.animationSpeed = 1.2;
        cube.anchor.set(0.5);

        // adjust for basic cube
        if (color !== 'all') {
            cube.scale.set(1.2);
            cube.tint = blockColorMap[color].base;
        }

        return cube;
        */
    }

    // private: animation
    //-------------------------------------------------------------------------
    private async _animateCube(cube: Sprite) {
        const big = this._options.color === 'all';
        const duration = big ? 2.2 : 1.4;
        const alphaDuration = duration * 0.2;
        const scale = big ? 3.2 : 1.5;

        // set initial values
        cube.visible = true;
        cube.scale.set(0.5);
        cube.alpha = 1;
        cube.rotation = 1;

        // spawn
        this.addChild(cube);

        // animate
        await gsap
            .timeline()
            .to(cube.scale, { x: scale, y: scale, duration, ease: 'none' })
            .to(cube, { alpha: 0, duration: alphaDuration, ease: 'power2.in' }, duration - alphaDuration)
            .to(cube, { rotation: Math.PI * 6, duration }, 0);

        // despawn
        this.removeChild(cube);

        /*
        // spawn
        this.addChild(cube);

        // animate
        await cube.start();

        // despawn
        this.removeChild(cube);
        */
    }

    private async _animateBeamsStart() {
        // start beams
        for (const entry of this._beams) {
            // add random delay unless first
            void waitFor(integerRandom(0, entry === this._beams[0] ? 0 : 800)).then(() => {
                // spawn beam
                this.addChild(entry.beam);
                this.sortChildren();

                // animate
                void entry.beam.start();
            });
        }

        // step beams
        Ticker.system.add(this._beamStepper);
    }

    private async _animateBeamsStop() {
        // unstep beams
        Ticker.system.remove(this._beamStepper);

        // stop and despawn beams
        for (const entry of this._beams) {
            entry.beam.stop();
            this.removeChild(entry.beam);
        }
    }

    private async _animateExplode() {
        const { color } = this._options;
        const all = color === 'all';
        const toColor = () => blockColorMap[all ? arrayRandom(colorIds) : color].glow;

        // determine scale
        const scale = all ? 3 : 1.5;

        // determine magnitude
        const magnitude = all ? 350 : 250;

        // determine duration
        const duration = all ? 2 : 1.5;

        // create emitter
        const emitter = app().particles.create({
            textures: [manifest.tingle],
            rate: 0.002,
            limit: 75,
            emitterDuration: 0.15,
            scale: [
                { x: 0.5, y: 0.5 },
                { x: scale, y: scale },
            ],
            duration,
            tint: toColor(),
            behaviors: [
                { type: 'explode', magnitude: [50, magnitude] },
                { type: 'kinematic' },
                { type: 'fade', to: 0, tween: tweenPower2In },
                //{ type: 'tint', to: toColor, tween: tweenPower4Out },
                { type: 'drag', drag: 3 },
                { type: 'gravity', gravity: { x: 0, y: 80 } },
            ],
        });

        // spawn emitter
        this.addChild(emitter.view);

        // aniamte
        await emitter.start();

        // despawn
        this.removeChild(emitter.view);
    }
}
