// boosters

import { CoinsProductId } from './product';

//import { ResourceId } from './resources';

//-----------------------------------------------------------------------------
export const powerBoosterIds = ['cube', 'bomb', 'rocket'] as const;
export type PowerBoosterId = (typeof powerBoosterIds)[number];

export const boosterIds = ['dart', 'bullet', 'drill', 'roulette'] as const;
export type BoosterId = (typeof boosterIds)[number];
export type BoosterEntry = {
    count: number;
    ftue: boolean;
};
export type BoosterCollection = { [key in BoosterId]?: BoosterEntry };

// config
//-----------------------------------------------------------------------------
export const powerBoosterConfig = {
    unlockLevel: 7,
};

// maps
//-----------------------------------------------------------------------------
export const boosterMap: {
    [key in BoosterId]: {
        text: string;
        asset: string;
        tip: string;
        cost: number; // in gold
        level: number; // puzzle unlock level
        count: number; // number booseters granted with a purchase
    };
} = {
    dart: {
        text: '[boosterDart]',
        tip: '[tipBoosterDart]',
        asset: 'icon.dart.png',
        cost: 400,
        level: 10,
        count: 3,
    },
    bullet: {
        text: '[boosterBullet]',
        tip: '[tipBoosterBullet]',
        asset: 'icon.bullet.png',
        cost: 800,
        level: 13,
        count: 3,
    },
    drill: {
        text: '[boosterDrill]',
        tip: '[tipBoosterDrill]',
        asset: 'icon.drill.png',
        cost: 800,
        level: 17,
        count: 3,
    },
    roulette: {
        text: '[boosterRoulette]',
        tip: '[tipBoosterRoulette]',
        asset: 'icon.roulette.png',
        cost: 200,
        level: 19,
        count: 3,
    },
};

// helpers
//-----------------------------------------------------------------------------
export function boosterToProductId(id: BoosterId): CoinsProductId {
    return id;
}

export function powerBoosterToProductId(id: PowerBoosterId): CoinsProductId {
    switch (id) {
        case 'cube':
            return 'baseball';
        case 'bomb':
            return 'bomb';
        case 'rocket':
            return 'rocket';
        default:
            throw Error();
    }
}

// export function isResourceBooster(resourceId: ResourceId): boolean {
//     return !!boosterMap[resourceId as BoosterId];
// }
