import { Container, Graphics, NineSlicePlane, Sprite, Texture } from 'pixi.js';

import { TouchInputComponent } from '../../../lib/pixi/components/TouchInputComponent';
import { uiAlignCenter, uiAlignTop, uiCreateMask, uiSizeToWidth } from '../../../lib/pixi/uiTools';
import { tween } from '../../../lib/util/tweens';
import task from '../../../replicant/defs/task';
import { Speedlines } from '../../concept/Speedlines';
import { LayoutScreen2 } from '../../lib/screens/LayoutScreen2';
import { BasicText } from '../../lib/ui/text/BasicText';
import app from '../../getApp';

// types
//-----------------------------------------------------------------------------
export type TaskCompleteScreenOptions = {
    level: number;
    onScreenPress: () => Promise<void>;
};

// manifest
//-----------------------------------------------------------------------------
const manifest = {
    bg: 'bg.checkers.png',
    splitFrame: 'frame.bg.split.png',
    glowFrame: 'frame.glow.png',
    glow: 'fx.glow2.png',
    newIcon: 'icon.new.png',
};

export class TaskCompleteScreen extends LayoutScreen2 {
    // events
    //-------------------------------------------------------------------------
    // scene
    private _bg: Sprite;
    private _bgMask: Graphics;

    private _underlayInput: TouchInputComponent;

    // impl
    //-------------------------------------------------------------------------
    public preload(options: TaskCompleteScreenOptions) {
        return [
            ...app().resource.loadAssets([
                ...Object.values(manifest),
                ...Speedlines.assets(),
                task.taskList[options.level].icon,
            ]),
        ];
    }

    public async spawning(options: TaskCompleteScreenOptions) {
        void this.addOrientationListener();

        this._initializeInput(options.onScreenPress);

        // spawn scene
        void this._spawn(options);

        this._bgMask = uiCreateMask(this._bg.width, this._bg.height);
        this._bg.mask = this._bgMask;
        this._bg.addChild(this._bgMask);
    }

    public despawned() {
        this.empty();
    }

    // private: scene
    //-------------------------------------------------------------------------
    private async _spawn(options: TaskCompleteScreenOptions) {
        this.root.sortableChildren = true;

        const iconGlow = Sprite.from(manifest.glow);
        iconGlow.pivot.set(iconGlow.width * 0.5, iconGlow.height * 0.5);
        iconGlow.scale.set(2.48);
        const glowFrame = new NineSlicePlane(Texture.from(manifest.glowFrame), 70, 70, 70, 70);
        glowFrame.width = 400;
        glowFrame.height = 400;
        glowFrame.pivot.set(glowFrame.width * 0.5, glowFrame.height * 0.5);

        const tapLabel = new BasicText({
            text: '[tapToContinue]',
            style: {
                fill: '#FFF',
                fontSize: 36,
                fontWeight: 'bold',
                lineJoin: 'round',
                align: 'center',
                dropShadow: true,
                dropShadowColor: 0x0,
                dropShadowAngle: Math.PI / 2,
                dropShadowAlpha: 0.3,
                dropShadowDistance: 3,
                lineHeight: 25,
            },
        });

        glowFrame.scale.set(0);
        iconGlow.alpha = 0;

        const newView = this._createNewView(options.level);
        newView.alpha = 0;
        tapLabel.alpha = 0;

        void glowFrame
            .animate()
            .wait(0.6)
            .add(glowFrame.scale, { x: 1, y: 1 }, 0.25, tween.backOut())
            .promise()
            .then(() => {
                iconGlow
                    .animate()
                    .add(iconGlow, { alpha: 0.6 }, 0.75, tween.pow2In)
                    .add(iconGlow, { alpha: 1 }, 0.75, tween.pow2Out)
                    .loop();
                iconGlow
                    .animate()
                    .set(iconGlow, { rotation: 0 })
                    .add(iconGlow, { rotation: Math.PI * 2 }, 7, tween.linear)
                    .loop();

                const taskIcon = Sprite.from(task.taskList[options.level].icon);
                taskIcon.alpha = 0;
                glowFrame.addChild(taskIcon);
                taskIcon.scale.set(1.2);

                const duration = 0.85;
                taskIcon.animate().add(taskIcon, { alpha: 1 }, duration, tween.pow2Out);
                newView.animate().add(newView, { alpha: 1 }, duration, tween.pow2Out);
                tapLabel.animate().add(tapLabel, { alpha: 1 }, duration, tween.pow2Out);
                uiAlignCenter(glowFrame, taskIcon);
            });

        // activate input once the animations are done~
        setTimeout(() => {
            this._underlayInput.enabled = true;
        }, 1000);

        this._bg = this._createBg();
        this.base.addContent({
            bg: {
                content: this._bg,
                styles: {
                    position: 'center',
                    minHeight: '100%',
                },
            },
            glowAnimation: {
                content: iconGlow,
                styles: {
                    position: 'center',
                    marginTop: iconGlow.height * 0.5 - 100,
                    marginLeft: iconGlow.width * 0.5,
                },
            },
            frame: {
                content: glowFrame,
                styles: {
                    position: 'center',
                    // marginTop: -100,
                    marginTop: glowFrame.height * 0.5 - 100,
                    marginLeft: glowFrame.width * 0.5,
                },
            },
            info: {
                content: newView,
                styles: {
                    position: 'center',
                    marginTop: 210,
                },
            },
            tapLabel: {
                content: tapLabel,
                styles: {
                    position: 'bottomCenter',
                    marginBottom: 100,
                },
            },
        });
    }

    private _initializeInput(onScreenPress: () => Promise<void>) {
        // once spawned its part of the scene in memory after closing. Make sure we only spawn once.
        if (!this._underlayInput) {
            this._underlayInput = new TouchInputComponent(this.root);
        }

        this._underlayInput.enabled = false;
        this._underlayInput.onTap = async () => onScreenPress();
    }

    private _createNewView(level: number) {
        const container = new Container();
        const newIcon = Sprite.from(manifest.newIcon);

        const title = new BasicText({
            text: task.taskList[level].completeTitle,
            style: {
                fill: '#FFFCA8',
                fontSize: 50,
                fontWeight: 'bold',
                lineJoin: 'round',
                align: 'center',
                dropShadow: true,
                dropShadowColor: 0x0,
                dropShadowAngle: Math.PI / 2,
                dropShadowAlpha: 0.3,
                dropShadowDistance: 3,
                lineHeight: 25,
            },
        });
        uiSizeToWidth(title, 700);

        const subTitle = new BasicText({
            text: task.taskList[level].completeSubTitle,
            style: {
                fill: '#FFF',
                fontSize: 40,
                fontWeight: 'bold',
                lineJoin: 'round',
                align: 'center',
                dropShadow: true,
                dropShadowColor: 0x0,
                dropShadowAngle: Math.PI / 2,
                dropShadowAlpha: 0.3,
                dropShadowDistance: 3,
            },
        });

        container.addChild(newIcon, title, subTitle);
        uiAlignCenter(container, newIcon);
        uiAlignCenter(container, title, 0, 75);
        uiAlignCenter(container, subTitle, 0, 105);
        return container;
    }

    private _createBg() {
        const bg = Sprite.from(manifest.bg);

        const speedlines = new Speedlines();
        speedlines.scale.set(1.76);
        speedlines.angle = -15;
        void speedlines.start();
        speedlines.alpha = 0.25;

        bg.addChild(speedlines);
        uiAlignTop(bg, speedlines, 350);
        speedlines.x = 160;

        const split = new NineSlicePlane(Texture.from(manifest.splitFrame), 0, 310, 0, 3);
        split.height = bg.height * 0.6;
        bg.addChild(split);
        uiAlignCenter(bg, split, 0, 270);

        return bg;
    }
}
