import { Sprite } from 'pixi.js';

import { uiAlignCenter, uiAlignCenterY, uiAlignRight, uiSizeToFit } from '../../../lib/pixi/uiTools';
import { formatResourceCount } from '../../lib/tools/appTools';
import StatsView from './StatsView';
import app from '../../getApp';

const manifest = {
    icon: 'icon.coin.png',
    plus: 'button.plus.png',
};

export default class PopupCoinView extends StatsView {
    public get icon() {
        return this._icon;
    }

    constructor() {
        super({ startAmount: app().game.player.coins });
        this._icon = Sprite.from(manifest.icon);
        const plus = Sprite.from(manifest.plus);
        this._icon.scale.set(0.9);
        this._icon.x = 8;
        uiAlignCenterY(this, this._icon, -3);
        this.addChild(this._icon, plus);

        uiAlignCenterY(this, plus, 3);
        uiAlignRight(this._bg, plus, 20);

        const scale = 1.15;
        this.scale.set(scale);
        this._defaultScale = scale;

        this._observer.listen(
            () => app().game.player.coins,
            (stats) => {
                this._updateAmount(stats);
            },
        );
        this._observer.start();
    }

    // this component is using custom scaling so it needs a custom amount update
    protected override _updateAmount(amount: number) {
        this._amount.text = formatResourceCount(amount);
        uiSizeToFit(this._amount, 74, 90);
        uiAlignCenter(this, this._amount, -8, -7);
    }

    public static override assets(): string[] {
        return [...StatsView.assets(), ...Object.values(manifest)];
    }
}
