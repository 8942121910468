import { SB } from '@play-co/replicant';

import { boosterState } from './components/boosters';
import { livesState } from './components/lives';
import { puzzleState } from './components/puzzle';
import { accountState } from './defs/account';
import { adminState } from './defs/admin';
import { coreState } from './defs/core';
import { dailyBonusState } from './defs/dailyBonus';
import { firstSessionState } from './defs/firstSession';
import { gameDayState } from './defs/gameDay';
import { nativeState } from './defs/native';
import { playerState } from './defs/player';
import { productState } from './defs/product';
import { settingsState } from './defs/settings';
import { taskState } from './defs/task';
import { tutorialState } from './defs/tutorial';
import { teamMoraleState } from './defs/teamMorale';

// combined state
//-----------------------------------------------------------------------------
export const stateSchema = SB.object({
    ...adminState,
    ...accountState,
    ...boosterState,
    ...coreState,
    ...dailyBonusState,
    ...livesState,
    ...nativeState,
    ...playerState,
    ...productState,
    ...puzzleState,
    ...settingsState,
    ...firstSessionState,
    ...gameDayState,
    ...taskState,
    ...tutorialState,
    ...teamMoraleState,
});
