import { IFlow } from '../../lib/pattern/IFlow';
import NakedPromise from '../../lib/pattern/NakedPromise';
import { getDailyBonusList, isDailyBonusAvailable } from '../../replicant/components/dailyBonus';
import { AdminId } from '../../replicant/defs/admin';
import app from '../getApp';
import { HomeScreen } from '../main/home/HomeScreen';
import { GameRewardFlow } from './GameRewardFlow';

// high priority popups to show when navigating to home.
// Should only be triggered when home is cleared with no overlays/tutorial etc
export class HomeSequenceFlow implements IFlow {
    private readonly _complete = new NakedPromise<boolean>();
    private _homeScreen: HomeScreen;

    // init
    //-------------------------------------------------------------------------
    constructor(opts: { homeScreen: HomeScreen }) {
        this._homeScreen = opts.homeScreen;
    }

    // impl
    //-------------------------------------------------------------------------
    public async execute() {
        await new GameRewardFlow({ homeScreen: this._homeScreen }).execute();
        await this._checkAdminMessages();

        void this._actionComplete(true);
        return this._complete;
    }

    private async _actionComplete(success: boolean) {
        this._complete.resolve(success);
    }

    private async _checkAdminMessages() {
        const rewards: Record<AdminId, number> = {
            dart: 0,
            bullet: 0,
            drill: 0,
            roulette: 0,
            bomb: 0,
            rocket: 0,
            cube: 0,
            stars: 0,
            infiniteLives: 0,
            coins: 0,
        };

        const items = app().server.state.adminMessages;
        const length = items.length;
        if (length === 0) return;

        // accumulate in case several messages contain the same item
        for (const item of items) {
            rewards[item.id as AdminId] += item.amount;
        }

        const claimPromise = new NakedPromise();
        void app().nav.open('adminRewardPopup', {
            rewards,
            onClaim: () => claimPromise.resolve(),
        });
        await claimPromise;
        // claim grant for items we just rendered in the popup
        await app().server.invoke.grantAdminItem({ messageCount: length });
        void app().nav.close('adminRewardPopup');
    }

    private async _handleDailyBonus() {
        const state = app().server.state;
        if (isDailyBonusAvailable(state, app().server.now())) {
            const claimPromise = new NakedPromise();
            void app().nav.open('dailyBonusPopup', {
                rewards: getDailyBonusList(state),
                onClaim: async () => {
                    await app().server.invoke.claimDailyBonus();
                    claimPromise.resolve();
                },
            });

            await claimPromise;
            void app().nav.close('dailyBonusPopup');
        }
    }
}
