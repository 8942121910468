import app from '../../app/getApp';
import { captureGenericError } from '../../app/lib/sentry';
import { InstantGame } from '../../plugins/instantGames/InstantGame';
import { getAndroidRewardState, getIosRewardState } from '../../replicant/components/native';
import { timeFromComponents } from '../../replicant/util/timeTools';

export function isPlatformNative() {
    return InstantGame.platform.osType === 'ANDROID_APP' || InstantGame.platform.osType === 'IOS_APP';
}

export function isNativePromoEnabled(): boolean {
    const osType = InstantGame.platform.osType;
    if (app().platform.guest) return false;
    // Always show promo on dev. computer
    if (process.env.IS_DEVELOPMENT && osType === 'WEB') return true;

    return osType === 'IOS' || osType === 'ANDROID';
}

export function logoutNativeApp() {
    // only works on android, use legacy for both
    // window.Native.login.logout();
    void InstantGame.platform.legacyNative?.auth.logout();
}

export function guessInstalledNativeAppPlatform(): 'ANDROID_APP' | 'IOS_APP' | null {
    const state = app().server.state;
    const now = app().server.now();

    // OS detection
    const isIos = InstantGame.platform.osType === 'IOS';
    const isAndroid = InstantGame.platform.osType === 'ANDROID';

    // Is IOS app installed
    const iosInstalled = isIos && now - state.native.iosLastLaunched < timeFromComponents({ days: 7 });

    // Is Android app installed
    const androidInstalled = isAndroid && now - state.native.androidLastLaunched < timeFromComponents({ days: 7 });

    let result: 'ANDROID_APP' | 'IOS_APP' = null;
    if (iosInstalled) {
        result = 'IOS_APP';
    } else if (androidInstalled) {
        result = 'ANDROID_APP';
    }

    return result;
}

export function isNativeRewardClaimed() {
    const state = InstantGame.replicant.state;
    const os = InstantGame.platform.osType;

    return (
        ((os === 'IOS' || os === 'IOS_APP') && getIosRewardState(state) === 'claimed') ||
        ((os === 'ANDROID' || os === 'ANDROID_APP') && getAndroidRewardState(state) === 'claimed')
    );
}

export function shouldTriggerNativeReward() {
    const state = InstantGame.replicant.state;
    const os = InstantGame.platform.osType;

    // Native app opened when the player has seen the promo popup earlier in LIFF
    return (
        (os === 'IOS_APP' && getIosRewardState(state) === 'seen') ||
        (os === 'ANDROID_APP' && getAndroidRewardState(state) === 'seen')
    );
}

export function showNativePromo(): boolean {
    // Always show promo on dev. computer
    if (process.env.IS_DEVELOPMENT && InstantGame.platform.osType === 'WEB') return true;

    const state = InstantGame.replicant.state;
    const os = InstantGame.platform.osType;

    return (
        (os === 'IOS' && getIosRewardState(state) !== 'claimed') ||
        (os === 'ANDROID' && getAndroidRewardState(state) !== 'claimed')
    );
}

export function hasInstalledNative() {
    const os = InstantGame.platform.osType;
    const state = InstantGame.replicant.state;
    if ((os === 'ANDROID' || os === 'ANDROID_APP') && state.native.androidLastLaunched !== 0) return true;

    if ((os === 'IOS' || os === 'IOS_APP') && state.native.iosLastLaunched !== 0) return true;

    return false;
}

export const subscribeToLinks = (callback: (url: string) => void) => {
    let executed = false;
    InstantGame.platform.legacyNative?.links.subscribe((url: string) => {
        // call once only since we're force reloading the game on links
        if (!executed) {
            executed = true;
            callback(url);
        }
    });
};

export const forceGameUpdateAndReentry = (requireLogin = false) => {
    // TODO do we need this legacy call?
    // (window as any).TLNative?.core?.restart?.(requireLogin);

    // new app can just reload the window
    if (requireLogin) {
        void Promise.resolve(logoutNativeApp()).then(() => window.location.reload());
    } else {
        window.location.reload();
    }
};

export async function consumeSavedPayload(): Promise<{ payload: Record<string, unknown>; isConsumed: boolean }> {
    const payload = {};
    // merge the cached native payload if it exists
    const statePayload = app().server.state.native.payload ?? null;

    let isConsumed = false;
    if (statePayload) {
        isConsumed = true;
        await app().server.invoke.setPersistedPayload({ payload: undefined });
        try {
            Object.assign(payload, JSON.parse(statePayload));
        } catch (err) {
            captureGenericError(`Error augmenting entry data with saved native payload`, err);
        }
    }

    return { payload, isConsumed };
}
