import { action } from '@play-co/replicant';

import { powerBoosterIds } from '../defs/booster';
import native from '../defs/native';
import { MutableState, State, SyncActionAPI } from '../defs/replicant';
import { powerBoosterAdd } from './boosters';
import { grantCoins } from './shop';

export const nativeActions = {
    updateIosNative: action((state: MutableState, _, api: SyncActionAPI) => {
        state.native.iosLastLaunched = api.date.now();
    }),

    updateAndroidNative: action((state: MutableState, _, api: SyncActionAPI) => {
        state.native.androidLastLaunched = api.date.now();
    }),

    setIosRewardToSeen: action((state: MutableState, _, api: SyncActionAPI) => {
        // reward cant be claimed more than once
        if (getIosRewardState(state) === 'claimed') {
            throw new Error(`IOS reward already claimed!`);
        }

        setIosRewardState(state, 'seen', api.date.now());
    }),

    setAndroidRewardToSeen: action((state: MutableState, _, api: SyncActionAPI) => {
        // reward cant be claimed more than once
        if (getAndroidRewardState(state) === 'claimed') {
            throw new Error(`Android reward already claimed!`);
        }

        setandroidRewardState(state, 'seen', api.date.now());
    }),
    claimIosNativeReward: action((state: MutableState, _, api: SyncActionAPI) => {
        if (getIosRewardState(state) === 'claimed') {
            throw new Error('Not allowed to trigger IOS native rewards');
        }

        grantNativeReward(state, api);
        // Mark as claimed once we send the mail even though they can claim at any time
        setIosRewardState(state, 'claimed', api.date.now());
    }),

    claimAndroidNativeReward: action((state: MutableState, _, api: SyncActionAPI) => {
        if (getAndroidRewardState(state) === 'claimed') {
            throw new Error('Not allowed to trigger Android native rewards');
        }

        grantNativeReward(state, api);
        // Mark as claimed once we send the mail even though they can claim at any time
        setandroidRewardState(state, 'claimed', api.date.now());
    }),

    setPersistedPayload: action((state: MutableState, opts: { payload?: string }, api: SyncActionAPI) => {
        const { payload } = opts;
        if (!payload) {
            delete state.native.payload;
            return;
        }
        state.native.payload = payload;
    }),
};

export function getIosRewardState(state: State): 'unseen' | 'seen' | 'claimed' {
    if (state.native.iosRewardTimestamp === 0) return 'unseen';
    if (state.native.iosRewardTimestamp === -1) return 'seen';
    return 'claimed';
}

export function getAndroidRewardState(state: State): 'unseen' | 'seen' | 'claimed' {
    if (state.native.androidRewardTimestamp === 0) return 'unseen';
    if (state.native.androidRewardTimestamp === -1) return 'seen';
    return 'claimed';
}

function setIosRewardState(state: MutableState, value: 'unseen' | 'seen' | 'claimed', now: number) {
    if (value === 'unseen') {
        state.native.iosRewardTimestamp = 0;
    } else if (value === 'seen') {
        state.native.iosRewardTimestamp = -1;
    } else {
        state.native.iosRewardTimestamp = now;
    }
}

function setandroidRewardState(state: MutableState, value: 'unseen' | 'seen' | 'claimed', now: number) {
    if (value === 'unseen') {
        state.native.androidRewardTimestamp = 0;
    } else if (value === 'seen') {
        state.native.androidRewardTimestamp = -1;
    } else {
        state.native.androidRewardTimestamp = now;
    }
}

function grantNativeReward(state: MutableState, api: SyncActionAPI) {
    const rewards = native.rewards;

    grantCoins(state, api, { type: 'coins', amount: rewards.coins, feature: 'native_reward' });

    powerBoosterIds.forEach((id) => {
        powerBoosterAdd(state, id, rewards.boosters);
    });
}
