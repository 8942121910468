#version 300 es
#define SHADER_NAME uber
precision mediump float;

// constants
//-------------------------------------
const int TYPE_TILE =       0x01;
const int TYPE_DISPLACE =   0x02;
const int TYPE_HSL =        0x04;

// in/out
//-------------------------------------
in vec2   vTextureCoord;
in vec2   vUv;
out vec4  fragmentColor;

// uniforms
//-------------------------------------
uniform int       types;
uniform vec4      uColor;
uniform sampler2D uSampler;
uniform vec4      uvBounds;
uniform vec2      disOffset;
uniform vec2      disScale;
uniform float     disAmp;
uniform sampler2D disMap;
uniform vec3      hsl;
uniform vec2      tile;

// support
//-------------------------------------
vec3 RgbToHsl( in vec3 c ) {
  float h = 0.0;
	float s = 0.0;
	float l = 0.0;
	float r = c.r;
	float g = c.g;
	float b = c.b;
	float cMin = min( r, min( g, b ) );
	float cMax = max( r, max( g, b ) );

	l = ( cMax + cMin ) / 2.0;
	if ( cMax > cMin ) {
		float cDelta = cMax - cMin;
		s = l < .0 ? cDelta / ( cMax + cMin ) : cDelta / ( 2.0 - ( cMax + cMin ) );
		if ( r == cMax ) {
			h = ( g - b ) / cDelta;
		} else if ( g == cMax ) {
			h = 2.0 + ( b - r ) / cDelta;
		} else {
			h = 4.0 + ( r - g ) / cDelta;
		}
		if ( h < 0.0) {
			h += 6.0;
		}
		h = h / 6.0;
	}
	return vec3( h, s, l );
}

vec3 HslToRgb( in vec3 c )
{
    vec3 rgb = clamp( abs(mod(c.x*6.0+vec3(0.0,4.0,2.0),6.0)-3.0)-1.0, 0.0, 1.0 );
    return c.z + c.y * (rgb-0.5)*(1.0-abs(2.0*c.z-1.0));
}

vec3 HslBlend(vec3 base, vec3 blend) {
    vec3 r;
    r.x = base.x + blend.x;
    r.y = base.y + blend.y;
    r.z = base.z * blend.z;
    return r;
}

// effects
//-------------------------------------
vec2 tileEffect(vec2 uv)
{
  return fract(uv + tile);
}

vec4 displaceEffect(vec2 uv)
{
  //return texture(disMap, vUv + disOffset);
  return texture(uSampler, clamp(uv + (texture(disMap, vUv * disScale + disOffset).xy - 0.5) * disAmp, uvBounds.xy, uvBounds.zw));
}

vec4 hslEffect(vec4 color)
{
  vec3 blend = HslToRgb(HslBlend(RgbToHsl(color.rgb), vec3(hsl.x, hsl.y, hsl.z)));
  return vec4(blend, color.a);
}

// main
//-------------------------------------
void main(void)
{
  vec2 uv = vTextureCoord;

  // tile
  if((types & TYPE_TILE) != 0)
    uv = tileEffect(uv);

  // displace else default color
  vec4 color = ((types & TYPE_DISPLACE) != 0) ? displaceEffect(uv) : texture(uSampler, uv);

  // hsl
  if((types & TYPE_HSL) != 0)
    color = hslEffect(color);

  fragmentColor = color * uColor;
}
