import { ColorMatrixFilter, Container, Graphics, NineSlicePlane, Sprite, Texture } from 'pixi.js';

import { GradientFilter } from '../../../lib/pixi/filters/Gradient/GradientFilter';
import { uiAlignCenter, uiAlignCenterX, uiCreateQuad, uiSizeToWidth } from '../../../lib/pixi/uiTools';
import { tween } from '../../../lib/util/tweens';
import task, { StepData } from '../../../replicant/defs/task';
import { objectCreateWithProperty } from '../../../replicant/util/jsTools';
import { timeFormatCountdown, timeToComponents } from '../../../replicant/util/timeTools';
import { ImageButton } from '../../lib/ui/buttons/ImageButton';
import { BasicText } from '../../lib/ui/text/BasicText';
import app from '../../getApp';

const manifest = {
    todo: 'panel.item.todo.png',
    default: 'panel.item.default.png',
    checkmark: 'icon.checkmark.png',
    button: 'button.main.orange2.png',
    star: 'icon.star.png',
    timer: 'icon.timer.png',
    badge: 'icon.badge.png',
    timerFrame: 'frame.timer.png',
    glow: 'fx.glow2.png',
};

class GlowSizeHack extends Sprite {
    protected override _calculateBounds(): void {
        const bounds = this._bounds;
        bounds.minX = bounds.minY = 0;
        bounds.maxX = 1;
        bounds.maxY = 1;
    }

    constructor() {
        super(Texture.from(manifest.glow));
    }
}

export default class TaskItem extends Container {
    private _timestamp: number;
    private _level: number;
    private _stepIndex: number;

    private _button?: ImageButton; // only visible depending on state
    private _starIcon?: Sprite; // only visible depending on state
    private _iconFrame: Sprite;
    private _mainContainer: Graphics;
    private _timerFrame: Graphics;
    private _durationFrame: NineSlicePlane;
    private _timer: BasicText;
    private _stepDuration = 0;
    private _completeSpawned = false;
    private _step: StepData;

    static assets(opts: { taskLevel: number }): string[] {
        return [...Object.values(manifest), ...task.taskList[opts.taskLevel].steps.map((step) => step.icon)];
    }

    public get button() {
        return this._button;
    }

    public get starIcon() {
        return this._starIcon;
    }

    constructor(opts: {
        timestamp: number;
        level: number;
        stepIndex: number;
        onPress: (stepIndex: number) => Promise<void>;
    }) {
        super();
        const { timestamp, level, stepIndex } = opts;

        this._step = task.taskList[level].steps[stepIndex];
        this._timestamp = timestamp;
        this._level = level;
        this._stepIndex = stepIndex;
        this._stepDuration = this._step?.timedStep?.time ?? 0;

        // const mainContainer = (this._mainContainer = uiCreateQuad(0x0, 0.001, 220, 330));
        const mainContainer = (this._mainContainer = uiCreateQuad(0x0, 0.001, 248, 370));

        this._spawnIconFrame();

        const label = new BasicText({
            text: this._step.label,
            style: {
                fill: '#FFF',
                fontSize: 30,
                lineJoin: 'round',
                align: 'left',
                dropShadow: true,
                dropShadowColor: 0x0,
                dropShadowAngle: Math.PI / 2,
                dropShadowAlpha: 0.3,
                dropShadowDistance: 3,
            },
        });

        mainContainer.addChild(label);
        uiSizeToWidth(label, 216);
        uiAlignCenter(this._iconFrame, label, 0, 150);
        uiAlignCenterX(mainContainer, label, -4);

        if (this._isCompleted()) {
            this._spawnCheckmark();
        } else {
            // ------- TIMER
            if (this._step.timedStep && this._timestamp === 0) {
                this._durationFrame = new NineSlicePlane(Texture.from(manifest.timerFrame), 18, 18, 18, 18);
                this._durationFrame.width = 174;
                this._durationFrame.height = 44;

                const badge = Sprite.from(manifest.badge);
                const timerIcon = Sprite.from(manifest.timer);
                badge.addChild(timerIcon);
                timerIcon.x = 4;
                timerIcon.y = 1;

                badge.x = 3;
                badge.y = -11;

                this._iconFrame.addChild(this._durationFrame);
                uiAlignCenter(this._iconFrame, this._durationFrame, 0, 65);

                const stepTime = new BasicText({
                    text: `${timeFormatCountdown(timeToComponents(this._step.timedStep.time), 1)}`,
                    style: {
                        fill: '#FFF',
                        fontSize: 27,
                        fontWeight: 'bold',
                        lineJoin: 'round',
                        align: 'center',
                    },
                });

                this._durationFrame.addChild(badge, stepTime);
                uiAlignCenter(this._durationFrame, stepTime, 2, -3);
            }

            if (this._step.timedStep && this._timestamp > 0) {
                // if (stepIndex === 1) {
                this._spawnTimer();
            } else {
                // button or timer
                this._button = new ImageButton({
                    image: manifest.button,
                    slice: {
                        left: 25,
                        right: 25,
                        width: 211,
                        height: 100,
                    },
                });

                this._button.onPress = async () => opts.onPress(stepIndex);

                const star = (this._starIcon = Sprite.from(manifest.star));
                const starAmount = this._step.starCost;

                const amount = new BasicText({
                    text: `${starAmount}`,
                    style: {
                        fill: '#FFF',
                        fontSize: 41,
                        fontWeight: 'bold',
                        lineJoin: 'round',
                        align: 'left',
                        dropShadow: true,
                        dropShadowColor: 0x0,
                        dropShadowAngle: Math.PI / 2,
                        dropShadowAlpha: 0.3,
                        dropShadowDistance: 3,
                    },
                });

                this._button.button.addChild(star, amount);

                uiSizeToWidth(amount, 62);
                uiAlignCenter(this._button.button, amount, 28, -5);
                star.x = 40;
                star.y = 16;
                mainContainer.addChild(this._button);
                uiAlignCenter(mainContainer, this._button, 0, 128);
            }
        }

        this.addChild(mainContainer);
        uiAlignCenter(this, mainContainer);
    }

    public updateTimer(iconGlow = false) {
        if (this._timestamp <= 0) return;
        if (this._completeSpawned) return;

        const now = app().server.now();
        const finishTime = this._timestamp + this._stepDuration;
        const timeLeft = finishTime - now;

        const isCompleted = now > finishTime;

        if (isCompleted) {
            this._completeSpawned = true;
            // remove all timer views
            this._timerFrame.removeFromParent();
            this._spawnCheckmark(true);

            // spawn new frame and put back icon
            this._iconFrame.removeFromParent();
            this._spawnIconFrame(iconGlow);
        } else {
            this._timer.text = timeFormatCountdown(timeToComponents(timeLeft));
        }

        uiSizeToWidth(this._timer, 104);
        uiAlignCenter(this._timerFrame, this._timer, 20, -2);
    }

    public update(opts: { timestamp: number }) {
        const { timestamp } = opts;
        // if timed step then the timestamp will trigger a re-render on its own
        this._timestamp = timestamp;

        if (this._timestamp === -1) {
            this._spawnCheckmark(true);
            this._iconFrame.removeFromParent();
            this._spawnIconFrame(true);
        } else {
            if (this._durationFrame) {
                this._durationFrame.removeFromParent();
                this._durationFrame = null;
            }
            if (!this._timer) this._spawnTimer(true);
        }

        if (this._button) {
            this._button.removeFromParent();
            this._button = null;
        }
    }

    private _isCompleted() {
        const isCompleted =
            this._step.timedStep && this._timestamp > 0
                ? app().server.now() > this._timestamp + this._stepDuration
                : this._timestamp === -1;

        return isCompleted;
    }

    private _spawnGlowAnimation(parent: Container) {
        const iconGlow = new GlowSizeHack();
        iconGlow.pivot.set(iconGlow.width * 0.5, iconGlow.height * 0.5);
        iconGlow
            .animate()
            .add(iconGlow, { alpha: 0.6 }, 0.75, tween.pow2In)
            .add(iconGlow, { alpha: 1 }, 0.75, tween.pow2Out)
            .loop();
        iconGlow
            .animate()
            .set(iconGlow, { rotation: 0 })
            .add(iconGlow, { rotation: Math.PI * 2 }, 7, tween.linear)
            .loop();

        parent.addChild(iconGlow);

        uiAlignCenter(parent, iconGlow);

        setTimeout(async () => {
            await iconGlow.animate().add(iconGlow, { alpha: 0 }, 0.5, tween.pow2In).promise();
            iconGlow.removeFromParent();
        }, 3000);
    }

    private _spawnCheckmark(animated = false) {
        const checkmark = Sprite.from(manifest.checkmark);
        this._mainContainer.addChild(checkmark);
        uiAlignCenter(this._mainContainer, checkmark, 0, 122);

        if (animated) {
            checkmark.alpha = 0;
            checkmark.animate().add(checkmark, { alpha: 1 }, 0.25, tween.pow2Out);
        }

        this._completeSpawned = true;
    }

    private _spawnTimer(animated = false) {
        const badge = Sprite.from(manifest.badge);
        const timerIcon = Sprite.from(manifest.timer);
        badge.addChild(timerIcon);
        timerIcon.x = 4;
        timerIcon.y = 1;

        badge.x = 3;
        badge.y = -11;
        this._timerFrame = new Graphics();

        this._timerFrame.beginFill(0x0, 0.25);
        this._timerFrame.drawRoundedRect(0, 0, 200, 56, 30);
        this._timerFrame.endFill();

        this._timerFrame.addChild(badge);
        uiAlignCenter(this._timerFrame, badge, -60, -3);

        this._mainContainer.addChild(this._timerFrame);
        uiAlignCenter(this._mainContainer, this._timerFrame, 0, 118);

        this._timer = new BasicText({
            text: ``,
            style: {
                fill: '#FFF',
                fontSize: 28,
                fontWeight: 'bold',
                lineJoin: 'round',
                align: 'center',
            },
        });

        this._timerFrame.addChild(this._timer);

        if (animated) {
            this._timer.alpha = 0;
            this._timer.animate().add(this._timer, { alpha: 1 }, 0.25, tween.pow2Out);
        }

        this.updateTimer();
    }

    private _spawnIconFrame(triggerGlow = false) {
        const isCompleted = this._isCompleted();
        this._iconFrame = Sprite.from(this._isCompleted() ? manifest.default : manifest.todo);

        const icon = Sprite.from(task.taskList[this._level].steps[this._stepIndex].icon);

        icon.width = 154;
        icon.height = 154;
        if (!isCompleted) {
            const type = task.taskList[this._level].iconType;
            const filterMap = {
                grayscale: () => {
                    const grayscale = new ColorMatrixFilter();
                    grayscale.grayscale(0.5, false);
                    return grayscale;
                },
                silouetteBlue: () => {
                    const gradient = new GradientFilter({
                        type: GradientFilter.LINEAR,
                        stops: [
                            { offset: 0.0, color: 0x0b3c4a, alpha: 1 },
                            { offset: 1.0, color: 0x0b3c4a, alpha: 1 },
                        ],
                        alpha: 1,
                    });
                    return gradient;
                },
                silouetteGreen: () => {
                    const gradient = new GradientFilter({
                        type: GradientFilter.LINEAR,
                        stops: [
                            { offset: 0.0, color: 0x355936, alpha: 1 },
                            { offset: 1.0, color: 0x355936, alpha: 1 },
                        ],
                        alpha: 1,
                    });
                    return gradient;
                },
                silouetteQuestion: () => {
                    const gradient = new GradientFilter({
                        type: GradientFilter.LINEAR,
                        stops: [
                            { offset: 0.0, color: 0x355936, alpha: 1 },
                            { offset: 1.0, color: 0x355936, alpha: 1 },
                        ],
                        alpha: 1,
                    });
                    return gradient;
                },
            };
            icon.filters = [filterMap[type]()];

            this._iconFrame.addChild(icon);

            if (type === 'grayscale') {
                icon.alpha = 0.4;
            } else if (type === 'silouetteQuestion') {
                const questionMark = new BasicText({
                    text: '?',
                    style: {
                        fill: '#FFF',
                        fontSize: 80,
                        lineJoin: 'round',
                        fontWeight: 'bold',
                        align: 'center',
                        stroke: 0x345231,
                        strokeThickness: 12,
                    },
                });
                this._iconFrame.addChild(questionMark);
                uiAlignCenter(this._iconFrame, questionMark);
            }
        } else {
            if (triggerGlow) {
                // animate glow
                this._spawnGlowAnimation(this._iconFrame);

                // animate icon
                const bright = new ColorMatrixFilter();
                icon.filters = [bright];
                const brightWrap = objectCreateWithProperty(
                    'value',
                    (value: number) => bright.brightness(value, false),
                    10,
                );
                icon.animate().add(brightWrap, { value: 1 }, 3, tween.pow3Out);
            }

            this._iconFrame.addChild(icon);
        }

        uiAlignCenter(this._iconFrame, icon);

        this._mainContainer.addChild(this._iconFrame);
        uiAlignCenterX(this._mainContainer, this._iconFrame);
        this._iconFrame.y = 30;
    }
}
