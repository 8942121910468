import { Vector2 } from '@play-co/odie';
import { gsap } from 'gsap';

import { PositionType } from '../../../../../lib/defs/types';
import { despawnBlockEntity, DogBlockEntity, moveBlockEntity } from '../../entities/BlockEntity';
import { GameScene } from '../../GameScene';
import { mapCellBaseOpen } from '../../util/mapTools';
import { IEffect } from './IEffect';

// types
//-----------------------------------------------------------------------------
// public
export type DogEffectOptions = {
    subject: DogBlockEntity;
};

// private
type FindResult = {
    empty?: boolean;
    position?: Vector2;
};

/*
    moves the dog in the given direction if theres any room
*/
export class DogEffect implements IEffect {
    // fields
    //-------------------------------------------------------------------------
    // input
    private readonly _scene: GameScene;
    private readonly _options: DogEffectOptions;

    // init
    //-------------------------------------------------------------------------
    constructor(scene: GameScene, options: DogEffectOptions) {
        this._scene = scene;
        this._options = options;
    }

    static assets(): string[] {
        return [];
    }

    // impl
    //-------------------------------------------------------------------------
    public async execute() {
        const dog = this._options.subject;
        const oldViewPosition = dog.c.view2d.view.position.clone();

        // get to position for dog to move to
        const result = this._findToPosition();

        // if position found
        if (result.position) {
            const phase = this._scene.sessionEntity.c.phase;

            // push activations
            phase.activePush();

            // move dog to this cell
            moveBlockEntity(this._scene, dog, result.position);

            // animate move
            await this._animateMove(dog, oldViewPosition);

            // pop activations
            await phase.activePop();
            // else if there is no last cell
        } else if (result.empty) {
            //TODO: animate despawn

            // despawn dog
            despawnBlockEntity(this._scene, dog);
        } else return false;

        return true;
    }

    // private: animations
    //-------------------------------------------------------------------------
    private async _animateMove(dog: DogBlockEntity, fromPosition: PositionType) {
        const duration = 0.32;

        // animate to requested view position
        await gsap.from(dog.c.view2d.view, { x: fromPosition.x, y: fromPosition.y, duration, ease: 'power1.inOut' });
    }

    // private: support
    //-------------------------------------------------------------------------
    private _findToPosition(): FindResult {
        const dog = this._options.subject;
        const offset = dog.c.blockDog.view.direction === 'right' ? 1 : -1;
        const position = dog.c.position.mapPosition.clone();
        const last: FindResult = {};

        // find last open cell/position
        // eslint-disable-next-line no-constant-condition
        while (true) {
            // shift position
            position.x += offset;

            // get map cell for this position
            const cell = this._scene.sessionEntity.c.map.getCellAt(position);

            // end if empty cell
            if (!cell?.enabled) {
                last.empty = true;
                break;
            }

            // end if cell occupied
            if (!mapCellBaseOpen(cell)) break;

            // update last
            last.position = position.clone();
        }

        return last;
    }
}
