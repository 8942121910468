import { action, teaHash } from '@play-co/replicant';

import { BoosterId, boosterIds, boosterMap, PowerBoosterId, powerBoosterIds } from '../defs/booster';
import { DailyReward } from '../defs/dailyBonus';
import { MutableState, State, SyncActionAPI } from '../defs/replicant';
import { timeFromComponents } from '../util/timeTools';
import { boosterAdd, powerBoosterAdd } from './boosters';
import { getTimeToNextDay } from './gameDay';
import { grantCoins } from './shop';

// actions
//-----------------------------------------------------------------------------
export const dailyBonusActions = {
    claimDailyBonus: action((state: MutableState, _, api: SyncActionAPI) => {
        if (!isDailyBonusAvailable(state, api.date.now())) throw new Error('Daily bonus not available');

        const rewards = getDailyBonus(state);

        const boosteRewards = rewards.boosters;
        Object.keys(boosteRewards).forEach((id: BoosterId) => {
            if (boosteRewards[id] > 0) {
                boosterAdd(state, id, boosteRewards[id]);
            }
        });

        const powerBoosterRewards = rewards.powerBoosters;
        Object.keys(powerBoosterRewards).forEach((id: PowerBoosterId) => {
            if (powerBoosterRewards[id] > 0) {
                powerBoosterAdd(state, id, powerBoosterRewards[id]);
            }
        });

        if (rewards.coins > 0) {
            grantCoins(state, api, { type: 'coins', amount: rewards.coins, feature: 'daily_bonus' });
        }

        state.dailyBonus.claimTimestamp = api.date.now();
    }),
    triggerDailyBonusTimestamp: action((state: MutableState, _, api: SyncActionAPI) => {
        state.dailyBonus.claimTimestamp = api.date.now();
    }),
};

export function isDailyBonusAvailable(state: State, now: number) {
    if (state.dailyBonus.claimTimestamp === 0) return true;

    let time = now - state.dailyBonus.claimTimestamp;

    let passedDays = 0;
    time += getTimeToNextDay('sevenAM', now); // offset accordingly to game start
    passedDays -= 1; // subtract one since we set the offset to next day
    while (time > 0) {
        time -= timeFromComponents({ days: 1 });
        passedDays++;
        // not within the same days 7am, allow bonus
        if (passedDays === 1) return true;
    }
    return false;
}

export function getDailyBonus(state: State): DailyReward {
    // use previous timestamp as hash to generate rewards
    const timestamp = state.dailyBonus.claimTimestamp;
    let index = 0;
    const hash0 = teaHash(timestamp, index++);
    const hash1 = teaHash(timestamp, index++);
    let coins = 0;
    const powerBoosters = {
        bomb: 0,
        rocket: 0,
        cube: 0,
    };

    const boosters = {
        dart: 0, // bat
        roulette: 0, // tornado fan
        drill: 0, // fireball
        bullet: 0, // runner
    };

    // one booster or power block or just coins
    if (hash0 < 0.95) {
        // 95% chance to get a booster or power block
        const boosterMix = [...boosterIds, ...powerBoosterIds];
        // equal weight for all boosters
        const id = boosterMix[Math.floor(hash1 * boosterMix.length)];

        if (boosterMap[id as BoosterId]) {
            boosters[id as BoosterId] = 1; // random booster
        } else {
            powerBoosters[id as PowerBoosterId] = 1; // random power booster
        }
    } else {
        // just coins
        coins = 100;
    }

    const rewards: DailyReward = {
        coins,
        boosters,
        powerBoosters,
    };

    return rewards;
}

export function getDailyBonusList(state: State): { id: string; amount: number }[] {
    const reward = getDailyBonus(state);

    // parse into a list
    const boosters = Object.keys(reward.boosters).map((id: BoosterId) => ({ id, amount: reward.boosters[id] }));
    const powerBoosters = Object.keys(reward.powerBoosters).map((id: PowerBoosterId) => ({
        id,
        amount: reward.powerBoosters[id],
    }));

    const totalItems = [...boosters, ...powerBoosters, { id: 'coins', amount: reward.coins }].filter(
        (item) => item.amount > 0,
    ) as {
        id: string;
        amount: number;
    }[];

    return totalItems;
}
