import { BaseRibbonPopup, BaseRibbonPopupOptions } from './BaseRibbonPopup';

// types
//-----------------------------------------------------------------------------
export type LevelPopupOptions = {
    level: number;
} & BaseRibbonPopupOptions;

/*
    Level popup with level banner, subclass this for specific level design
*/
export abstract class LevelPopup extends BaseRibbonPopup {
    public override async spawning(options: LevelPopupOptions) {
        await super.spawning({
            header: `[popupLevelTitle|${options.level}]`,
            ...options,
        });
    }
}
