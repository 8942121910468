import { NineSlicePlane, Sprite, Texture } from 'pixi.js';

import { uiAlignCenter } from '../../../lib/pixi/uiTools';
import { BasicText } from '../../lib/ui/text/BasicText';
import { BasicPopup, BasicPopupOptions } from './BasicPopup';
import app from '../../getApp';

const HEIGHT = 660;

// types
//-----------------------------------------------------------------------------
export type TaskNotReadyPopupOptions = {
    completed: number;
    required: number;
} & BasicPopupOptions;

const manifest = {
    frame: 'frame.inset.light.png',
    hammer: 'icon.build.png',
};

export class TaskNotReadyPopup extends BasicPopup {
    public override preload() {
        return [...super.preload(), ...app().resource.loadAssets([...Object.values(manifest)])];
    }

    // @ts-ignore
    public override async spawning(options: TaskNotReadyPopupOptions) {
        void super.spawning({
            onClose: options.onOk,
            ...options,
            header: '[popupTaskNotReadyTitle]',
            width: 740,
            height: HEIGHT,
        });

        const description = new BasicText({
            text: '[popupTaskNotReadyMsg]',
            style: {
                fill: '#3E4047',
                align: 'center',
                fontWeight: 'bold',
                fontSize: 30,
                lineJoin: 'round',
            },
        });

        const frame = this._spawnMainFrame(options);

        // spawn
        this.main.addContent({
            main: {
                content: frame,
                styles: {
                    position: 'topCenter',
                    marginTop: 180,
                },
            },
            description: {
                content: description,
                styles: {
                    position: 'topCenter',
                    marginTop: 433,
                },
            },
        });
    }

    private _spawnMainFrame(args: { completed: number; required: number }) {
        const frame = new NineSlicePlane(Texture.from(manifest.frame), 28, 28, 28, 28);
        frame.width = 288;
        frame.height = 185;

        const hammer = Sprite.from(manifest.hammer);

        const { completed, required } = args;
        const progress = new BasicText({
            text: `${completed}/${required}`,
            style: {
                fill: 0xaf1342,
                stroke: 'white',
                strokeThickness: 8,
                fontSize: 60,
                fontWeight: 'bold',
            },
        });

        const offset = hammer.width / 2 + 5;
        const delta = (progress.width - hammer.width) / 2;
        const jaOffset = app().settings.language === 'ja' ? 20 : 0;
        uiAlignCenter(frame, hammer, -offset - delta);
        uiAlignCenter(frame, progress, offset - delta + jaOffset);

        frame.addChild(hammer);
        frame.addChild(progress);

        return frame;
    }
}
