import { IFlow } from '../../lib/pattern/IFlow';
import NakedPromise from '../../lib/pattern/NakedPromise';
import { getGameDayReward } from '../../replicant/components/gameDay';
import { sleep } from '../../replicant/util/jsTools';
import app from '../getApp';
import { HomeScreen } from '../main/home/HomeScreen';
import { TutorialMoraleIntroFlow } from './tutorials/TutorialMoraleIntroFlow';

export class GameRewardFlow implements IFlow {
    private readonly _complete = new NakedPromise<boolean>();
    private _homeScreen: HomeScreen;

    // init
    //-------------------------------------------------------------------------
    constructor(opts: { homeScreen: HomeScreen }) {
        this._homeScreen = opts.homeScreen;
    }

    // impl
    //-------------------------------------------------------------------------
    public async execute() {
        await this._handleGameReward();

        void this._actionComplete(true);
        return this._complete;
    }

    private async _actionComplete(success: boolean) {
        this._complete.resolve(success);
    }

    private async _handleGameReward() {
        const rewards = app().server.state.gameDay.rewards;
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < rewards.length; i++) {
            const rewardData = rewards[i];
            const reward = getGameDayReward(app().server.state, rewardData);
            const claimPromise = new NakedPromise();
            void app().nav.open('gameDayRewardPopup', {
                rewards: reward.items,
                result: reward.result,
                onClaim: claimPromise.resolve,
            });

            await claimPromise;

            this._homeScreen.teamMoraleView.pauseMoraleUpdate();

            const { moraleScore } = await app().server.invoke.claimGameDayReward();
            void app().nav.close('gameDayRewardPopup');

            // if score got added by backend its enabled
            if (moraleScore) {
                this._homeScreen.teamMoraleView.resumeMoraleUpdate();
                await sleep(2);
                if (!app().server.state.teamMorale.ftue) {
                    await new TutorialMoraleIntroFlow().execute();
                } else {
                    let id;
                    if (reward.result === 'win') {
                        id = rewardData.teamMorale > 70 ? 0 : 1;
                    } else {
                        id = rewardData.teamMorale > 20 ? 2 : 3;
                    }

                    const infoPopupId = 'infoPopup';
                    const tapPromise = new NakedPromise();
                    void app().nav.open(infoPopupId, {
                        header: `[popupTeamMoraleTitle${id}]`,
                        infoText: `[popupTeamMoraleText${id}]`,
                        height: 580,
                        underlay: 0.6,
                        onOk: async () => tapPromise.resolve(),
                    });
                    await tapPromise;
                    void app().nav.close(infoPopupId);
                }
            }
        }
    }
}
