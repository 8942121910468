import { LoaderDefs } from '../../plugins/nav/LoaderManager';
import { ScreenDefs } from '../../plugins/nav/NavPlugin';
import { AlphaTransition } from '../../plugins/nav/transitions/AlphaTransition';
import { ScaleTransition } from '../../plugins/nav/transitions/ScaleTransition';
import { SimpleAlphaTransition } from '../../plugins/nav/transitions/SimpleAlphaTransition';
import { GameDayScreen } from '../main/home/GameDayScreen';
import { HomeScreen } from '../main/home/HomeScreen';
import { NamePopup } from '../main/home/NamePopup';
import { IntroScreen } from '../main/intro/IntroScreen';
import { VersusScreen } from '../main/intro/VersusScreen';
import { MajorLoader } from '../main/loaders/MajorLoader';
import { SpinnerLoader } from '../main/loaders/SpinnerLoader';
import { SplashLoader } from '../main/loaders/SplashLoader';
import { AdminRewardPopup } from '../main/popups/AdminRewardPopup';
import { AppInstallPopup } from '../main/popups/AppInstallPopup';
import { AppPlayPopup } from '../main/popups/AppPlayPopup';
import { AppRewardPopup } from '../main/popups/AppRewardPopup';
import { BuyCompletePopup } from '../main/popups/BuyComplete/BuyCompletePopup';
import { ConfirmPopup } from '../main/popups/ConfirmPopup';
import { DailyBonusPopup } from '../main/popups/DailyBonusPopup';
import { FaqPopup } from '../main/popups/FaqPopup';
import { GameDayRewardPopup } from '../main/popups/GameRewardPopup';
import { InfoPopup } from '../main/popups/InfoPopup';
import { LegalPopup } from '../main/popups/LegalPopup';
import { LevelFailedPopup } from '../main/popups/LevelFailedPopup';
import { LevelGoalPopup } from '../main/popups/LevelGoal/LevelGoalPopup';
import { LevelSuccessMaxPopup } from '../main/popups/LevelSuccessMaxPopup';
import { LevelSuccessPopup } from '../main/popups/LevelSuccessPopup';
import { LineLoginPopup } from '../main/popups/LineLoginPopup';
import { NoMovesConfirmPopup } from '../main/popups/NoMovesConfirmPopup';
import { NoMovesPopup } from '../main/popups/NoMovesPopup';
import { NotEnoughStarsPopup } from '../main/popups/NotEnoughStarsPopup';
import { OutOfLivesPopup } from '../main/popups/OutOfLivesPopup';
import { PurchaseInterruptPopup } from '../main/popups/PurchaseInterruptPopup';
import { PurchasePopup } from '../main/popups/PurchasePopup';
import { RefillLivesPopup } from '../main/popups/RefillLivesPopup.ts';
import { SettingsPopup } from '../main/popups/SettingsPopup';
import { TaskNotReadyPopup } from '../main/popups/TaskNotReadyPopup';
import { TeamMoraleIntroPopup } from '../main/popups/TeamMoraleIntroPopup';
import { BoosterActivatorPopup } from '../main/puzzle/BoosterActivatorPopup';
import { PuzzleScreen } from '../main/puzzle/PuzzleScreen';
import { ShopPopup } from '../main/shop/ShopPopup';
import { TaskCompleteScreen } from '../main/tasks/TaskCompleteScreen';
import { TaskScreen } from '../main/tasks/TaskScreen';
import { TipScreen } from '../main/tip/TipScreen';
import { TipScreen2 } from '../main/tip/TipScreen2';

// config
//-----------------------------------------------------------------------------
export enum NavLayer {
    main,
    menu,
    fullscreenPopup,
    popup,
    tip,
    cheat,
    cheatPopup,
    count,
}

export const navLoaders: LoaderDefs = {
    splash: SplashLoader,
    boot: MajorLoader,
    default: SpinnerLoader,
};

// screens
//-----------------------------------------------------------------------------
export const navScreens: ScreenDefs = {
    adminRewardPopup: {
        screen: AdminRewardPopup,
        layer: NavLayer.popup,
    },
    appInstallPopup: {
        screen: AppInstallPopup,
        layer: NavLayer.popup,
    },
    appPlayPopup: {
        screen: AppPlayPopup,
        layer: NavLayer.popup,
    },
    appRewardPopup: {
        screen: AppRewardPopup,
        layer: NavLayer.popup,
    },
    boosterActivatorPopup: {
        screen: BoosterActivatorPopup,
        layer: NavLayer.popup,
    },
    buyCompletePopup: {
        screen: BuyCompletePopup,
        layer: NavLayer.popup,
    },
    confirmPopup: {
        screen: ConfirmPopup,
        layer: NavLayer.popup,
    },
    infoPopup: {
        screen: InfoPopup,
        layer: NavLayer.popup,
    },
    dailyBonusPopup: {
        screen: DailyBonusPopup,
        layer: NavLayer.popup,
    },
    faqPopup: {
        screen: FaqPopup,
        layer: NavLayer.popup,
    },
    gameDayScreen: {
        screen: GameDayScreen,
        transition: AlphaTransition,
        layer: NavLayer.main,
    },
    gameDayRewardPopup: {
        screen: GameDayRewardPopup,
        layer: NavLayer.popup,
    },
    homeScreen: {
        screen: HomeScreen,
        transition: AlphaTransition,
        layer: NavLayer.main,
    },
    introScreen: {
        screen: IntroScreen,
        // no transition, use custom
        // transition: AlphaTransition,
        layer: NavLayer.main,
    },
    purchasePopup: {
        screen: PurchasePopup,
        layer: NavLayer.popup,
    },
    puzzleScreen: {
        screen: PuzzleScreen,
        transition: AlphaTransition,
        layer: NavLayer.main,
    },
    tipScreen: {
        screen: TipScreen,
        layer: NavLayer.tip,
    },
    tipScreen2: {
        screen: TipScreen2,
        layer: NavLayer.tip,
    },
    levelGoalPopup: {
        screen: LevelGoalPopup,
        layer: NavLayer.popup,
    },
    levelFailedPopup: {
        screen: LevelFailedPopup,
        layer: NavLayer.popup,
    },
    levelSuccessPopup: {
        screen: LevelSuccessPopup,
        layer: NavLayer.popup,
    },
    levelSuccessMaxPopup: {
        screen: LevelSuccessMaxPopup,
        layer: NavLayer.popup,
    },
    legalPopup: {
        screen: LegalPopup,
        layer: NavLayer.popup,
    },
    lineLoginPopup: {
        screen: LineLoginPopup,
        layer: NavLayer.popup,
    },
    namePopup: {
        screen: NamePopup,
        transition: SimpleAlphaTransition,
        layer: NavLayer.popup,
    },
    noMovesPopup: {
        screen: NoMovesPopup,
        layer: NavLayer.popup,
    },
    noMovesConfirmPopup: {
        screen: NoMovesConfirmPopup,
        layer: NavLayer.popup,
    },
    settingsPopup: {
        screen: SettingsPopup,
        layer: NavLayer.popup,
    },
    taskCompleteScreen: {
        screen: TaskCompleteScreen,
        transition: AlphaTransition,
        layer: NavLayer.main,
    },
    taskScreen: {
        screen: TaskScreen,
        transition: AlphaTransition,
        layer: NavLayer.main,
    },
    teamMoraleIntroPopup: {
        screen: TeamMoraleIntroPopup,
        layer: NavLayer.popup,
    },
    notEnoughStarsPopup: {
        screen: NotEnoughStarsPopup,
        layer: NavLayer.popup,
    },
    outOfLivesPopup: {
        screen: OutOfLivesPopup,
        layer: NavLayer.popup,
    },
    purchaseInterruptPopup: {
        screen: PurchaseInterruptPopup,
        layer: NavLayer.popup,
    },
    refillLivesPopup: {
        screen: RefillLivesPopup,
        layer: NavLayer.popup,
    },
    taskNotReadyPopup: {
        screen: TaskNotReadyPopup,
        layer: NavLayer.popup,
    },
    shop: {
        screen: ShopPopup,
        transition: ScaleTransition,
        layer: NavLayer.fullscreenPopup,
    },
    versusScreen: {
        screen: VersusScreen,
        transition: AlphaTransition,
        layer: NavLayer.main,
    },
};
