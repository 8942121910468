import { Container, Sprite, Texture } from 'pixi.js';

import { ShellBlockProps } from '../../defs/block';
import { blockPositionView } from '../../util/blockTools';

const manifest = {
    closed: 'block.shell.closed.png',
    open: 'block.shell.open.png',
};

export class ShellBlockView extends Container {
    public sprite: Sprite;
    //private sparkles: SparkleAnimation = new SparkleAnimation();
    //private bubbles: BubbleAnimation = new BubbleAnimation();

    public get open(): boolean {
        return this._open;
    }

    public set open(value: boolean) {
        if (this._open === value) return;
        //this.animateTransition(value);
        this.setOpen(value);
        this._open = value;
    }

    private _open = false;

    constructor(props: ShellBlockProps) {
        super();
        //this.sprite = new FitWidthBlockSprite(manifest.closed, props);
        this.sprite = Sprite.from(manifest.closed);
        this.addChild(this.sprite);
        blockPositionView(this, props);
        //this.y += 4;

        //this.sprite.addChild(this.sparkles);
        //this.sprite.addChild(this.bubbles);
    }

    public static assets(): string[] {
        return Object.values(manifest);
    }

    public setOpen(value: boolean) {
        this._open = value;
        this.sprite.texture = Texture.from(manifest[value ? 'open' : 'closed']);
        // if (value) {
        //   this.bubbles.stop();
        //   this.sparkles.start();
        //   return;
        // }

        // this.sparkles.stop();
        // this.bubbles.start();
    }

    //   private async animateTransition(value: boolean) {
    //     const originalScale = this.sprite.view.style.scaleX;

    //     await animate(this.sprite.view)
    //       .commit()
    //       .then(
    //         {
    //           scaleX: 1.1 * originalScale,
    //           scaleY: 0.9 * originalScale,
    //         },
    //         50,
    //         animate.easeOutQuad,
    //       )
    //       .then(
    //         {
    //           scaleX: 0.9 * originalScale,
    //           scaleY: 1.1 * originalScale,
    //         },
    //         50,
    //         animate.easeInQuad,
    //       )
    //       .then(() => {
    //         this.setOpen(value);
    //       })
    //       .toPromise();

    //     animate(this.sprite.view).commit().then(
    //       {
    //         scaleX: originalScale,
    //         scaleY: originalScale,
    //       },
    //       100,
    //       animate.easeOutQuart,
    //     );
    //   }
}
