import {
    AndroidBlockEntity,
    AnimalBlockEntity,
    BasicBlockEntity,
    BlockEntity,
    CageBlockEntity,
    CandleBlockEntity,
    ChainBlockEntity,
    ChameleonBlockEntity,
    CoffinBlockEntity,
    DogBlockEntity,
    DoorBlockEntity,
    FireboxBlockEntity,
    FuguBlockEntity,
    HatBlockEntity,
    HazardBlockEntity,
    IceBlockEntity,
    KappaBlockEntity,
    KettleBlockEntity,
    LockBlockEntity,
    MiniFuguBlockEntity,
    PantherBlockEntity,
    PipeBlockEntity,
    PondBlockEntity,
    PrisonBlockEntity,
    ShellBlockEntity,
    SirenBlockEntity,
    SkullBlockEntity,
    SlimeBlockEntity,
    SpiderBlockEntity,
    StatueBlockEntity,
    ThrowerBlockEntity,
    VirusBlockEntity,
    WallBlockEntity,
} from '../../entities/BlockEntity';
import { GameScene } from '../../GameScene';
import { AndroidCollision } from './AndroidCollision';
import { AnimalCollision } from './AnimalCollision';
import { BasicCollision } from './BasicCollision';
import { BombCollision } from './BombCollision';
import { CageCollision } from './CageCollision';
import { CandleCollision } from './CandleCollision';
import { ChainCollision } from './ChainCollision';
import { ChameleonCollision } from './ChameleonCollision';
import { CoffinCollision } from './CoffinCollision';
import { CubeCollision } from './CubeCollision';
import { DogCollision } from './DogCollision';
import { DoorCollision } from './DoorCollision';
import { FireboxCollision } from './FireboxCollision';
import { FuguCollision } from './FuguCollision';
import { HatCollision } from './HatCollision';
import { HazardCollision } from './HazardCollision';
import { IceCollision } from './IceCollision';
import { ICollision } from './ICollision';
import { KappaCollision } from './KappaCollision';
import { KettleCollision } from './KettleCollision';
import { LockCollision } from './LockCollision';
import { MiniFuguCollision } from './MiniFuguCollision';
import { PantherCollision } from './PantherCollision';
import { PipeCollision } from './PipeCollision';
import { PondCollision } from './PondCollision';
import { PrisonCollision } from './PrisonCollision';
import { RocketCollision } from './RocketCollision';
import { ShellCollision } from './ShellCollision';
import { SirenCollision } from './SirenCollision';
import { SkullCollision } from './SkullCollision';
import { SlimeCollision } from './SlimeCollision';
import { SpiderCollision } from './SpiderCollision';
import { StatueCollision } from './StatueCollision';
import { ThrowerCollision } from './ThrowerCollision';
import { VirusCollision } from './VirusCollision';
import { WallCollision } from './WallCollision';
import { ZombieCollision } from './ZombieCollision';

// api
//-----------------------------------------------------------------------------
export function createCollision(scene: GameScene, entity: BlockEntity): ICollision | undefined {
    // create collision from entity's colliding state
    switch (entity.c.block.props.type) {
        case 'android':
            return new AndroidCollision(scene, entity as AndroidBlockEntity);
        case 'animal':
            return new AnimalCollision(scene, entity as AnimalBlockEntity);
        case 'basic':
            return new BasicCollision(scene, entity as BasicBlockEntity);
        case 'bomb':
            return new BombCollision(scene, entity);
        case 'cage':
            return new CageCollision(scene, entity as CageBlockEntity);
        case 'candle':
            return new CandleCollision(scene, entity as CandleBlockEntity);
        case 'chain':
            return new ChainCollision(scene, entity as ChainBlockEntity);
        case 'chameleon':
            return new ChameleonCollision(scene, entity as ChameleonBlockEntity);
        case 'coffin':
            return new CoffinCollision(scene, entity as CoffinBlockEntity);
        case 'cube':
            return new CubeCollision(scene, entity);
        case 'dog':
            return new DogCollision(scene, entity as DogBlockEntity);
        case 'door':
            return new DoorCollision(scene, entity as DoorBlockEntity);
        case 'firebox':
            return new FireboxCollision(scene, entity as FireboxBlockEntity);
        case 'hat':
            return new HatCollision(scene, entity as HatBlockEntity);
        case 'hazard':
            return new HazardCollision(scene, entity as HazardBlockEntity);
        case 'ice':
            return new IceCollision(scene, entity as IceBlockEntity);
        case 'kappa':
            return new KappaCollision(scene, entity as KappaBlockEntity);
        case 'kettle':
            return new KettleCollision(scene, entity as KettleBlockEntity);
        case 'lock':
            return new LockCollision(scene, entity as LockBlockEntity);
        case 'miniFugu':
            return new MiniFuguCollision(scene, entity as MiniFuguBlockEntity);
        case 'panther':
            return new PantherCollision(scene, entity as PantherBlockEntity);
        case 'pipe':
            return new PipeCollision(scene, entity as PipeBlockEntity);
        case 'pond':
            return new PondCollision(scene, entity as PondBlockEntity);
        case 'prison':
            return new PrisonCollision(scene, entity as PrisonBlockEntity);
        case 'rocket':
            return new RocketCollision(scene, entity);
        case 'shell':
            return new ShellCollision(scene, entity as ShellBlockEntity);
        case 'siren':
            return new SirenCollision(scene, entity as SirenBlockEntity);
        case 'skull':
            return new SkullCollision(scene, entity as SkullBlockEntity);
        case 'slime':
            return new SlimeCollision(scene, entity as SlimeBlockEntity);
        case 'spider':
            return new SpiderCollision(scene, entity as SpiderBlockEntity);
        case 'statue':
            return new StatueCollision(scene, entity as StatueBlockEntity);
        case 'fugu':
            return new FuguCollision(scene, entity as FuguBlockEntity);
        case 'thrower':
            return new ThrowerCollision(scene, entity as ThrowerBlockEntity);
        case 'virus':
            return new VirusCollision(scene, entity as VirusBlockEntity);
        case 'wall':
            return new WallCollision(scene, entity as WallBlockEntity);
        case 'zombie':
            return new ZombieCollision(scene, entity as CoffinBlockEntity);
        default:
            return undefined;
    }
}
