import { Container, Sprite } from 'pixi.js';

import { SizeType } from '../../../lib/defs/types';
import { uiAlignCenterX } from '../../../lib/pixi/uiTools';
import { BaseballPlayer, PlayerClipId, PlayerSkin } from '../../concept/BaseballPlayer';
import { PuzzleCompleteFlow } from '../../flows/PuzzleCompleteFlow';
import { LayoutScreen2 } from '../../lib/screens/LayoutScreen2';
//import { CellDef, MapDef } from '../match2/defs/map';
// import { Match2 } from '../match2/Match2';
import { GameScene } from '../match2-odie/GameScene';
import { PuzzleController, PuzzleControllerOptions } from './controller/PuzzleController';
import { PuzzleFooter } from './PuzzleFooter';
import { PuzzleHeader } from './PuzzleHeader';
import app from '../../getApp';

// constants
//-----------------------------------------------------------------------------
const manifest = {
    bg: 'bg.puzzle.png',
};

const idleDefault: PlayerClipId = 'batter_idle';

const characterSkins: PlayerSkin[] = [
    'character1_boy',
    'character2_girl',
    'character3_boy',
    'character4_girl',
    'character5_boy',
];

/*
    puzzle screen
*/
export class PuzzleScreen extends LayoutScreen2 {
    // fields
    //-------------------------------------------------------------------------
    // scene
    private _header: PuzzleHeader;
    private _footer: PuzzleFooter;
    private _characterView: BaseballPlayer;
    private _bg: Sprite;
    private _puzzleScene: GameScene;
    private _puzzleContainer: Container;
    private _controller: PuzzleController;

    public static instance: PuzzleScreen;

    // properties
    //-------------------------------------------------------------------------
    public get header(): PuzzleHeader {
        return this._header;
    }

    public get footer(): PuzzleFooter {
        return this._footer;
    }

    public get scene(): GameScene {
        return this._puzzleScene;
    }

    public get controller(): PuzzleController {
        return this._controller;
    }

    public get puzzleContainer(): Container {
        return this._puzzleContainer;
    }

    public get activeReaction(): PlayerClipId {
        return this._characterView.spine.activeId as PlayerClipId;
    }

    // init
    //-------------------------------------------------------------------------
    constructor() {
        super();
        //this.root.sortableChildren = true;
        this._controller = new PuzzleController(this);
        PuzzleScreen.instance = this;
    }

    public playCharacterReaction(id: PlayerClipId) {
        // play reaction then back to idle loop
        void this._characterView.start({ id }).then(() => {
            void this._characterView.start({ id: idleDefault, loop: true, mix: 0.2 });
        });
    }

    // impl
    //-------------------------------------------------------------------------
    public preload() {
        return app().resource.loadAssets([
            ...Object.values(manifest),
            ...PuzzleHeader.assets(),
            ...PuzzleFooter.assets(),
        ]);
    }

    public async init() {
        // spawn background
        this._spawnBackground();

        // spawn header
        this._spawnFooter();

        // spawn puzzle
        this._spawnPuzzle();

        // spawn header
        this._spawnHeader();
    }

    public async spawning(options?: PuzzleControllerOptions) {
        // update footer
        this._footer.udpate();

        // updates avatar
        this._updateAvatar();

        // sort z indexes
        this._bg.sortChildren();

        //TODO: refactor so this isnt needed
        await Promise.all(app().resource.loadAssets(await this._controller.assets(options)));
        await this._controller.prepare(options);
    }

    public async spawned() {}

    public despawned() {
        this._controller.hidden();
    }

    public step(dt: number) {}

    public override resized(size: SizeType): void {
        super.resized(size);
        //this._puzzleScene.resize(pixiConfig.size.width, pixiConfig.size.height);
        this._bg.x = (size.width - this._bg.width) / 2;
        this._footer.y = size.height - this._footer.height;
        //uiAlignCenterX(this._bg, this._puzzleContainer);
    }

    // private: update
    //-------------------------------------------------------------------------
    private _updateAvatar() {
        const scale = 0.6;

        // remove existing avatar
        this._bg.removeChild(this._characterView);

        // add character view
        const character = (this._characterView = new BaseballPlayer(
            characterSkins[app().server.state.puzzle.level % characterSkins.length],
        ));

        this._spines.push(character);

        character.y = 342;
        character.scale.set(scale);
        this._bg.addChild(character);
        character.x = this._bg.width / 2 - 2;

        // start idling
        this.playCharacterReaction(idleDefault);
    }

    // private: spawn
    //-------------------------------------------------------------------------
    private _spawnBackground() {
        this._bg = this.root.addChild(Sprite.from(manifest.bg));
    }

    private _spawnPuzzle() {
        // create puzzle container
        this._puzzleContainer = new Container();

        // create puzzle scene
        const stage = new Container();
        this._puzzleScene = new GameScene({
            stage,
        });
        this._puzzleContainer.addChild(stage);

        // complete ui
        this._bg.addChild(this._puzzleContainer);

        // align
        this._puzzleContainer.x = this._bg.width / 2;
        this._puzzleContainer.y = 710;
        this._puzzleContainer.zIndex = 1;
    }

    private _spawnHeader() {
        const header = (this._header = new PuzzleHeader());
        header.zIndex = 1;
        this._bg.addChild(header);
        uiAlignCenterX(this._bg, header);
    }

    private _spawnFooter() {
        const footer = (this._footer = new PuzzleFooter({
            onClose: this._onClose.bind(this),
        }));
        footer.zIndex = 2;
        this._bg.addChild(footer);
        uiAlignCenterX(this._bg, footer);
    }

    private async _onClose() {
        await new PuzzleCompleteFlow({ result: 'quit' }, this._controller).execute();
    }
}
