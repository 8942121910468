import { Container, Sprite, TextStyle, Texture } from 'pixi.js';

import { BasicAsyncHandler } from '../../../lib/defs/types';
import { uiAlignCenter } from '../../../lib/pixi/uiTools';
import { TextImageButton } from '../../lib/ui/buttons/TextImageButton';
import { BasicText } from '../../lib/ui/text/BasicText';
import { BasicPopup } from './BasicPopup';
import app from '../../getApp';

const SLICE = {
    width: 270,
    height: 76,
    left: 25,
    right: 25,
    top: 0,
    bottom: 0,
};

const STYLE: Partial<TextStyle> = {
    fill: '#FFFFFF',
    fontSize: 22,
    fontWeight: 'bold',
    lineJoin: 'round',
};

// types
//-----------------------------------------------------------------------------
export type FaqPopupOptions = {
    onClose: BasicAsyncHandler;
    onSupport: BasicAsyncHandler;
    onLineSettings: BasicAsyncHandler;
    onDelete?: BasicAsyncHandler;
    onLogout?: BasicAsyncHandler; // for native apps
};

// constants
//-----------------------------------------------------------------------------
const manifest = {
    buttonBlue: 'button.blue.png',
    buttonRed: 'button.red.png',
};

/*
    faq popup
*/
export class FaqPopup extends BasicPopup {
    // impl
    //-------------------------------------------------------------------------
    public override preload() {
        return [...super.preload(), ...app().resource.loadAssets(Object.values(manifest))];
    }

    // @ts-ignore
    public override async spawning(options: FaqPopupOptions) {
        void super.spawning({
            header: '[faqPopupTitle]',
            width: 600,
            height: 860,
            underlay: 0.8,
            ...options,
        });

        this.baseLayout.addContent({
            faq: {
                content: this._spawnFaq(),
                styles: {
                    position: 'topLeft',
                    marginTop: 144,
                    marginLeft: 60,
                },
            },
            support: {
                content: this._spawnSupport(options),
                styles: {
                    position: 'topCenter',
                    marginTop: 660,
                },
            },
            account: {
                content: this._spawnAccountButtons(options),
                styles: {
                    position: 'topCenter',
                    marginTop: 740,
                },
            },
        });
    }

    private _spawnFaq() {
        const container = new Container();

        const textStyle: Partial<TextStyle> = {
            align: 'left',
            fill: '#3E4047',
            fontSize: 20,
            lineJoin: 'round',
            wordWrap: false,
            fontStyle: 'normal',
            leading: 4,
        };
        let offsetY = 0;

        ['faq1', 'faq2'].forEach((faq) => {
            const question = new BasicText({
                text: `[${faq}Question]`,
                style: { ...textStyle, fill: '#132445', fontWeight: 'bold' },
            }).props({
                y: 6 + offsetY,
            });

            const questionBackground = Sprite.from(Texture.WHITE).props({
                y: offsetY,
                width: 480,
                height: question.height + 12,
                tint: '#b5d2d7',
                zIndex: -1,
            });

            const answer = new BasicText({
                text: `[${faq}Answer]`,
                style: textStyle,
            }).props({
                y: questionBackground.height + 10 + offsetY,
            });

            offsetY = answer.y + answer.height + 18;

            container.addChild(questionBackground);
            container.addChild(question);
            container.addChild(answer);
            container.sortChildren();
        });

        return container;
    }

    private _spawnSupport(options: FaqPopupOptions) {
        const container = new Container();
        const view = Sprite.from(Texture.EMPTY);
        // make sure it's the first child it gets the correct sizing;
        container.addChild(view);
        view.width = 550;
        view.height = 80;

        const buttonSettings = new TextImageButton({
            text: '[faqOpenLineSettings]',
            y: -5,
            image: manifest.buttonBlue,
            slice: SLICE,
            style: STYLE,
        }).props({
            onPress: options.onLineSettings,
        });

        const buttonSupport = new TextImageButton({
            text: '[faqContactSupport]',
            y: -5,
            image: manifest.buttonBlue,
            slice: SLICE,
            style: STYLE,
        }).props({
            onPress: options.onSupport,
        });

        container.addChild(buttonSettings, buttonSupport);
        const offset = 140;
        uiAlignCenter(container, buttonSettings, -offset, 0);
        uiAlignCenter(container, buttonSupport, offset, 0);

        return container;
    }

    private _spawnAccountButtons(options: FaqPopupOptions) {
        const container = new Container();
        const view = Sprite.from(Texture.EMPTY);
        // make sure it's the first child it gets the correct sizing;
        container.addChild(view);
        view.width = 550;
        view.height = 80;

        let deleteButton: TextImageButton;
        if (options.onDelete) {
            deleteButton = new TextImageButton({
                text: '[deleteAction]',
                y: -5,
                image: manifest.buttonRed,
                slice: SLICE,
                style: STYLE,
            }).props({
                onPress: options.onDelete,
            });
            container.addChild(deleteButton);
        }

        let logoutButton: TextImageButton;
        if (options.onLogout) {
            logoutButton = new TextImageButton({
                text: '[nativeLogoutCta]',
                y: -5,
                image: manifest.buttonBlue,
                slice: SLICE,
                style: STYLE,
            }).props({
                onPress: options.onLogout,
            });
            container.addChild(logoutButton);
        }
        if (deleteButton && logoutButton) {
            const offset = 140;
            uiAlignCenter(container, deleteButton, -offset, 0);
            uiAlignCenter(container, logoutButton, offset, 0);
        } else {
            if (deleteButton) {
                container.addChild(deleteButton);
                uiAlignCenter(container, deleteButton);
            }
            if (logoutButton) {
                container.addChild(logoutButton);
                uiAlignCenter(container, logoutButton);
            }
        }

        return container;
    }
}
