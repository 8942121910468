import { FlexContainer, FlexLayoutProps } from '@play-co/flex';
import { NineSlicePlane, Texture } from 'pixi.js';

import { ProductId } from '../../../replicant/defs/product';
import { layoutConfig } from './layout';
import { manifest } from './manifest';

export class ShopListItem extends FlexContainer {
    onPress?: (id: ProductId) => Promise<void>;

    protected content: FlexContainer;

    constructor(props?: Partial<FlexLayoutProps>) {
        super({
            width: '100%',
            ...props,
        });

        // add bg
        const slice = 50;
        this.addChild(new NineSlicePlane(Texture.from(manifest.listItemBg), slice, slice, slice, slice)).setFlex({
            position: 'absolute',
            width: '100%',
            height: '100%',
        });

        // add content container
        const inset = layoutConfig.halfPadding + 5;
        this.content = this.addChild(
            new FlexContainer({
                id: 'content',
                position: 'absolute',
                alignItems: 'center',
                left: inset,
                right: inset,
                bottom: inset,
                top: inset,
            }),
        );
    }
}
