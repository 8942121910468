import { CompleteCallback, IMediaInstance, Sound, SoundMap } from '@pixi/sound';

export type PlayOptions = {
    volume?: number;
    pan?: number;
    dupes?: number;
    rate?: number;
    loop?: boolean;
};

export class SoundChannel {
    public readonly id: string;
    private _sounds: SoundMap;
    private _volume = 1;
    private _speed = 1;
    private _paused = false;
    private _muted = false;

    public constructor(id: string) {
        this.id = id;
        this._sounds = {};
    }

    /**
     * Register an existing sound with the library cache.
     *
     * @param alias - The sound alias reference.
     * @param sound - Sound reference to use.
     * @returns Instance of the Sound object.
     */
    public add(name: string, sound: Sound): any {
        if (this._muted) sound.muted = true;
        this._sounds[name] = sound;

        return sound;
    }

    /**
     * Removes a sound by alias.
     *
     * @param alias - The sound alias reference.
     * @param assert - Whether enable console.assert.
     * @returns Instance for chaining.
     */
    public remove(alias: string, assert = true): this {
        if (!this.exists(alias, assert)) return this;
        this._sounds[alias].destroy();
        delete this._sounds[alias];

        return this;
    }

    /**
     * Set the global volume for all sounds. To set per-sound volume see {@link SoundChannel#volume}.
     */
    public get volumeAll(): number {
        return this._volume;
    }

    public set volumeAll(volume: number) {
        this._volume = volume;
        for (const alias in this._sounds) {
            this._sounds[alias].volume = volume;
        }
    }

    /**
     * Set the global speed for all sounds. To set per-sound speed see {@link SoundChannel#speed}.
     */
    public get speedAll(): number {
        return this._speed;
    }

    public set speedAll(speed: number) {
        this._speed = speed;
        for (const alias in this._sounds) {
            this._sounds[alias].speed = speed;
        }
    }

    /**
     * Toggle paused property for all sounds.
     *
     * @returns `true` if all sounds are paused.
     */
    public togglePauseAll(): boolean {
        const sounds = Object.values(this._sounds);

        this._paused = !this._paused;

        sounds.forEach((sound) => {
            sound.paused = this._paused;
        });

        return this._paused;
    }

    /**
     * Pauses any playing sounds.
     *
     * @returns Instance for chaining.
     */
    public pauseAll(): this {
        this._paused = true;
        for (const alias in this._sounds) {
            this._sounds[alias].paused = true;
        }

        return this;
    }

    /**
     * Resumes any sounds.
     *
     * @returns Instance for chaining.
     */
    public resumeAll(): this {
        this._paused = false;

        for (const alias in this._sounds) {
            this._sounds[alias].paused = false;
        }

        return this;
    }

    /**
     * Toggle muted property for all sounds.
     *
     * @returns `true` if all sounds are muted.
     */
    public toggleMuteAll(): boolean {
        this._muted = !this._muted;
        for (const alias in this._sounds) {
            this._sounds[alias].muted = this._muted;
        }

        return this._muted;
    }

    /**
     * Mutes all playing sounds.
     *
     * @returns Instance for chaining.
     */
    public muteAll(): this {
        this._muted = true;

        for (const alias in this._sounds) {
            this._sounds[alias].muted = true;
        }

        return this;
    }

    /**
     * Unmutes all playing sounds.
     *
     * @returns Instance for chaining.
     */
    public unmuteAll(): this {
        this._muted = false;

        for (const alias in this._sounds) {
            this._sounds[alias].muted = false;
        }

        return this;
    }

    /**
     * Stops and removes all sounds. They cannot be used after this.
     *
     * @returns Instance for chaining.
     */
    public removeAll(): this {
        for (const alias in this._sounds) {
            this._sounds[alias].destroy();
            delete this._sounds[alias];
        }

        return this;
    }

    /**
     * Stops all sounds.
     *
     * @returns Instance for chaining.
     */
    public stopAll(): this {
        for (const alias in this._sounds) {
            this._sounds[alias].stop();
        }

        return this;
    }

    /**
     * Checks if a sound by alias exists.
     *
     * @param alias - Check for alias.
     * @param assert - Whether enable console.assert.
     * @returns true if the sound exists.
     */
    public exists(alias: string, assert = false): boolean {
        const exists = !!this._sounds[alias];

        if (assert) {
            // eslint-disable-next-line no-console
            console.assert(exists, `No sound matching alias '${alias}'.`);
        }

        return exists;
    }

    /**
     * Convenience function to check to see if any sound is playing.
     *
     * @returns `true` if any sound is currently playing.
     */
    public isPlaying(): boolean {
        for (const alias in this._sounds) {
            if (this._sounds[alias].isPlaying) {
                return true;
            }
        }

        return false;
    }

    /**
     * Convenience function to check which sounds are playing
     *
     * @returns a list of active sound aliases
     */
    public getActiveSoundAliases(): string[] {
        const aliases = [];
        for (const alias in this._sounds) {
            if (this._sounds[alias].isPlaying) {
                aliases.push(alias);
            }
        }
        return aliases;
    }

    /**
     * Find a sound by alias.
     *
     * @param alias - The sound alias reference.
     * @returns Sound object.
     */
    public find(alias: string): Sound {
        this.exists(alias, true);

        return this._sounds[alias];
    }

    /**
     * Plays a sound.
     *
     * @param alias - The sound alias reference.
     * @param options - The options or callback when done.
     * @returns The sound instance,
     *        this cannot be reused after it is done playing. Returns a Promise if the sound
     *        has not yet loaded.
     */
    public play(alias: string, options?: PlayOptions | CompleteCallback): IMediaInstance | Promise<IMediaInstance> {
        const sound = this.find(alias);
        return sound.play({
            ...options,
            muted: sound.muted,
        });
    }

    /**
     * Stops a sound.
     *
     * @param alias - The sound alias reference.
     * @returns Sound object.
     */
    public stop(alias: string): Sound {
        return this.find(alias).stop();
    }

    /**
     * Pauses a sound.
     *
     * @param alias - The sound alias reference.
     * @returns Sound object.
     */
    public pause(alias: string): Sound {
        return this.find(alias).pause();
    }

    /**
     * Resumes a sound.
     *
     * @param alias - The sound alias reference.
     * @returns Instance for chaining.
     */
    public resume(alias: string): Sound {
        return this.find(alias).resume();
    }

    /**
     * Get or set the volume for a sound.
     *
     * @param alias - The sound alias reference.
     * @param volume - Optional current volume to set.
     * @returns The current volume.
     */
    public volume(alias: string, volume?: number): number {
        const sound = this.find(alias);

        if (volume !== undefined) {
            sound.volume = volume;
        }

        return sound.volume;
    }

    /**
     * Get or set the speed for a sound.
     *
     * @param alias - The sound alias reference.
     * @param speed - Optional current speed to set.
     * @returns The current speed.
     */
    public speed(alias: string, speed?: number): number {
        const sound = this.find(alias);

        if (speed !== undefined) {
            sound.speed = speed;
        }

        return sound.speed;
    }

    /**
     * Get the length of a sound in seconds.
     *
     * @param alias - The sound alias reference.
     * @returns The current duration in seconds.
     */
    public duration(alias: string): number {
        return this.find(alias).duration;
    }

    public close(): this {
        this.removeAll();
        this._sounds = null;

        return this;
    }
}
