import type { Component, Entity2D } from '@play-co/odie';

import { CountBlockOptionDef, SkullBlockProps } from '../defs/block';
import { SkullBlockView } from '../views/blocks/SkullBlockView';

// types
//-----------------------------------------------------------------------------
export type SkullBlockComponentOptions = {
    props: SkullBlockProps;
    option?: number;
};

/*
    skull block component
*/
export class SkullBlockComponent implements Component {
    // constants
    //-------------------------------------------------------------------------
    static readonly NAME = 'blockSkull';

    // fields
    //-------------------------------------------------------------------------
    // injected
    public entity!: Entity2D;
    // scene
    public view!: SkullBlockView;
    // state
    private _idle: boolean;
    public props!: SkullBlockProps;

    // properties
    //-------------------------------------------------------------------------
    // count
    public get count(): number {
        return this.view.count;
    }

    public set count(value: number) {
        this.view.count = value;
        this._idle = false;
    }

    // idle
    public get idle(): boolean {
        return this._idle;
    }

    public set idle(value: boolean) {
        this._idle = value;
    }

    // current option
    public get option(): number {
        const index = (this.props.options as CountBlockOptionDef[]).findIndex((option) => this.count === option.count);
        return index >= 0 ? index : 0;
    }

    // impl
    //-------------------------------------------------------------------------
    public init(options: SkullBlockComponentOptions): void {
        this.props = options.props;

        // get option def
        const optionDef = options.props.options[options.option || 0] as CountBlockOptionDef;

        // create view
        this.view = new SkullBlockView(options.props);

        // set initial count
        this.count = optionDef.count;

        // set idle state
        this._idle = false;
    }
}
