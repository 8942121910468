import { Sprite } from 'pixi.js';

import { BasicAsyncHandler } from '../../../lib/defs/types';
import gameConfig from '../../../replicant/defs/gameConfig';
import { getContinueCost } from '../../../replicant/defs/product';
import { ConsumeButton } from '../../lib/ui/buttons/ConsumeButton';
import { BasicText } from '../../lib/ui/text/BasicText';
import { MarkupText } from '../../lib/ui/text/MarkupText';
import PopupCoinView from '../home/PopupCoinView';
import { BasicPopup, BasicPopupOptions } from './BasicPopup';
import app from '../../getApp';

const HEIGHT = 670;

// types
//-----------------------------------------------------------------------------
export type NoMovesPopupOptions = {
    onConfirm: BasicAsyncHandler;
    onCoins: BasicAsyncHandler;
} & BasicPopupOptions;

const manifest = {
    panel: 'panel.no.moves.png',
    button: 'button.green.png',
};

export class NoMovesPopup extends BasicPopup {
    public override preload() {
        return [
            ...super.preload(),
            ...app().resource.loadAssets([...Object.values(manifest), ...ConsumeButton.assets()]),
        ];
    }

    // @ts-ignore
    public override async spawning(options: NoMovesPopupOptions) {
        void super.spawning({
            onClose: options.onOk,
            ...options,
            header: '[popupNoMovesTitle]',
            width: 690,
            height: HEIGHT,
        });

        const coinView = new PopupCoinView();
        coinView.onPress = options.onCoins;

        const panel = Sprite.from(manifest.panel);

        const moves = gameConfig.puzzle.continue.moves;
        const amount = new BasicText({
            text: `+${moves}`,
            style: {
                fill: 0xffffff,
                stroke: 0x00686b,
                strokeThickness: 9,
                fontSize: 74,
                fontWeight: 'bold',
            },
        });

        const button = new ConsumeButton({
            image: manifest.button,
            text: '[popupNoMovesButton]',
            consumeAmount: getContinueCost(app().server.state),
            slice: {
                width: 360,
                height: 122,
                left: 30,
                top: 16,
                right: 30,
                bottom: 32,
            },
            style: {
                fill: 'FFF',
                fontSize: 50,
                fontWeight: 'bold',
                lineJoin: 'round',
                fontStyle: 'normal',
                dropShadow: true,
                dropShadowDistance: 1,
            },
        });

        button.onPress = options.onConfirm;

        const description = new MarkupText({
            text: `[popupNoMovesText|<style fill="#AF1342">${moves}</>]`,
            style: {
                fill: '#3E4047',
                lineJoin: 'round',
                fontWeight: 'bold',
                fontSize: 22,
            },
        });

        // spawn
        this.main.addContent({
            coins: {
                content: coinView,
                styles: {
                    position: 'topCenter',
                    marginTop: -30,
                    marginLeft: -130,
                },
            },
            panel: {
                content: panel,
                styles: {
                    position: 'topCenter',
                    marginTop: 180,
                },
            },
            amount: {
                content: amount,
                styles: {
                    position: 'topCenter',
                    marginTop: 238,
                },
            },
            description: {
                content: description,
                styles: {
                    position: 'topCenter',
                    marginTop: 404,
                },
            },
            button: {
                content: button,
                styles: {
                    position: 'bottomCenter',
                    marginBottom: 30,
                },
            },
        });
    }
}
