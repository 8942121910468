//TODO: fix annoying warning spam. may need to just reimpl AnimatedSprite.
import { AnimatedSprite, Assets, Spritesheet } from 'pixi.js';

import { IAnimation } from '../../pattern/IAnimation';
import NakedPromise from '../../pattern/NakedPromise';

/*
    extension to AnimatedSprite that accepts a spritesheet asset and implements
    IAnimation
*/
export class SpritesheetAnimation extends AnimatedSprite implements IAnimation {
    // fields
    //-------------------------------------------------------------------------
    // state
    private _promise: NakedPromise;

    // properties
    //-------------------------------------------------------------------------
    // delay between frames in seconds
    public set frameDelay(value: number) {
        this.animationSpeed = 1 / (value * 60);
    }

    // init
    //-------------------------------------------------------------------------
    constructor(spritesheetId: string) {
        // get spritesheet
        const spritesheet = Assets.get<Spritesheet>(spritesheetId);

        // get textures sorted by name
        const textures = Object.keys(spritesheet.textures)
            .sort()
            .map((name) => spritesheet.textures[name]);

        // init base
        super(textures);

        // dont loop
        // @ts-ignore
        super.loop = false;
    }

    // impl
    //-------------------------------------------------------------------------
    public async start() {
        const promise = (this._promise = new NakedPromise());

        // resolve on complete
        // @ts-ignore
        super.onComplete = promise.resolve;

        // player
        super.play();

        return promise;
    }

    public override stop() {
        // stop
        super.stop();

        // resolve promise
        this._promise?.resolve();
    }
}
