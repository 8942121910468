import type { QueryResults, System } from '@play-co/odie';

import { Cell } from '../components/MapComponent';
import { config } from '../defs/config';
import type { BlockEntity, CageBlockEntity, ChainBlockEntity, SpiderBlockEntity } from '../entities/BlockEntity';
import type { GameScene } from '../GameScene';
import { mapIterateCells } from '../util/mapTools';
import { isPostLayoutWithHigherZ } from '../views/blocks/ChainBlockView';

function isChainBlockEntity(entity: BlockEntity): entity is ChainBlockEntity {
    const maybeChainBlockEntity = entity as ChainBlockEntity;
    return !!maybeChainBlockEntity.c.blockChain;
}

function isSpiderBlockEntity(entity: BlockEntity): entity is SpiderBlockEntity {
    const maybeSpiderBlockEntity = entity as SpiderBlockEntity;
    return !!maybeSpiderBlockEntity.c.blockSpider;
}

function isCageBlockEntity(entity: BlockEntity): entity is CageBlockEntity {
    const cage = entity as CageBlockEntity;
    return !!cage.c.blockCage;
}

/*
    render system that does a few extra things needed for this game
*/
export class RenderSystem implements System {
    // constants
    //-------------------------------------------------------------------------
    public static readonly NAME = 'render';

    // fields
    //-------------------------------------------------------------------------
    // injected
    public scene!: GameScene;
    public queries!: QueryResults;

    // impl
    //-------------------------------------------------------------------------
    public update(): void {
        // iterate map cells
        mapIterateCells(this.scene, (cell: Cell) => {
            const base = cell.base;
            const overlay = cell.overlay;

            // step base then overlay. ignore if child.
            if (base && !base.parent) this._stepBase(base.entity);
            if (overlay && !overlay.parent) this._stepOverlay(overlay.entity, base?.entity);
        });
    }

    // private: step
    //-------------------------------------------------------------------------
    private _stepBase(entity: BlockEntity) {
        // blocks above should overlap those below. so we just invert screen y.
        // then we align by tile size to simplify following calculations. these
        // should not increase z by more than <1.
        let z = -entity.view.y / config.tile.size;

        // on overlap prop, add just enough to be over neighbors (left,right,below)
        if (entity.c.block.overlap) z += 0.01;

        //TODO: no block specifics
        if (isChainBlockEntity(entity) && isPostLayoutWithHigherZ(entity.c.blockChain.layout)) {
            // for a chain block post we *always* want it to be above the preceding line
            // when it isn't at the top of the chain
            z += 1;
        }

        if (isSpiderBlockEntity(entity)) {
            // spiders should always be rendered above all lines, so they can move
            // up over preceding blocks and so their web can be drawn above the whole
            // grid, while behaving like immobile base blocks
            z += 10;
        }

        // set new z index
        entity.view.zIndex = z;
    }

    private _stepOverlay(entity: BlockEntity, base?: BlockEntity) {
        // if base exists, overlay overlaps base position, else uses same logic as base
        let z = base ? base.view.zIndex + 0.1 : -entity.view.y / config.tile.size;

        if (isCageBlockEntity(entity)) {
            z += 10;
        }

        // set new z index
        entity.view.zIndex = z;
    }
}
