import { Container } from 'pixi.js';

import { uiAlignCenter, uiSizeToWidth } from '../../../lib/pixi/uiTools';
import { NativePromoFlow } from '../../flows/NativePromoFlow';
import { ImageButton } from '../../lib/ui/buttons/ImageButton';
import { BasicText } from '../../lib/ui/text/BasicText';

const manifest = {
    button: 'button.app.png',
};

export default class AppPromoButton extends Container {
    public disabled = false;

    static assets(): string[] {
        return [manifest.button];
    }

    constructor() {
        super();

        const button = new ImageButton({ image: manifest.button });

        button.onPress = this._onTap.bind(this);

        this.addChild(button);

        const appPlay = new BasicText({
            text: '[nativePromoHomeButton]',
            style: {
                align: 'center',
                fontSize: 20,
                fill: '#fcffd5',
                fontWeight: 'bold',
                stroke: 'black',
                strokeThickness: 4,
                lineJoin: 'round',
            },
        });

        appPlay.pivot.set(appPlay.width * 0.5, appPlay.height * 0.5);

        button.button.addChild(appPlay);
        uiSizeToWidth(appPlay, button.button.width - 32);
        uiAlignCenter(button.button, appPlay, 0, 70);
    }

    private async _onTap() {
        if (this.disabled) return;
        await new NativePromoFlow({
            payload: {
                triggerSource: 'home_icon_install',
                feature: 'play_on_app_popup',
                subFeature: 'play_on_app_popup_cta',
            },
        }).execute();
    }
}
