import NakedPromise from '../../lib/pattern/NakedPromise';
import { Dialog } from '../../replicant/defs/storyDialogs';
import { PlayerClipId } from '../concept/BaseballPlayer';
import app from '../getApp';
import { HomeScreen, PlayerId } from '../main/home/HomeScreen';

export abstract class DialogFlow {
    protected readonly _complete = new NakedPromise<boolean>();
    protected _lastClipId: PlayerClipId;
    protected _choice: number;
    protected _spawnMoraleView = false;
    protected _homeScreen: HomeScreen;

    protected async _actionComplete(success: boolean) {
        this._complete.resolve(success);
    }

    protected async _dialogSequence(dialogs: Dialog[]) {
        const initialClip = dialogs[0]?.clip;
        this._lastClipId = initialClip;
        this._homeScreen = (await app().nav.open('homeScreen', {
            hideMenu: true,
            skipPointer: true,
            overrideClip: initialClip,
        })) as HomeScreen;

        if (this._spawnMoraleView) {
            void this._homeScreen.spawnMoraleView({});
        }

        for (let i = 0; i < dialogs.length; i++) {
            const speech = dialogs[i];
            const isNarrator = speech.speechSource === 'narrator';

            // play custom clip else clear/idle if last was custom
            // if first then skip initial clip and just play dialog since its included in the opening of the scene
            if (i > 0 && speech.clip) {
                if (this._lastClipId !== speech.clip) {
                    this._homeScreen.playAllAnimation({ id: speech.clip, loop: true, mix: 0.15 });
                    this._lastClipId = speech.clip;
                }
            } else if (dialogs[i - 1]?.clip) void this._homeScreen.playAllIdle();

            const dialog = speech.dialog ?? speech.choiceDialogs[this._choice];

            if (speech?.extraChoice) {
                const choicePromise = new NakedPromise<number>();
                void this._homeScreen.spawnBubbleChoice({
                    player: (isNarrator ? null : speech.speechSource) as PlayerId,
                    dialogText: dialog,
                    choices: speech.extraChoice,
                    promise: choicePromise,
                    narrator: isNarrator ? 'simple' : null, // null if player
                    bubbleOffset: isNarrator ? { x: 0, y: -470 } : null,
                });
                this._choice = await choicePromise;
            } else {
                // default
                const tapPromise = new NakedPromise();
                await this._homeScreen.spawnBubbleTap({
                    player: (isNarrator ? null : speech.speechSource) as PlayerId,
                    dialogText: dialog,
                    promise: tapPromise,
                    narrator: isNarrator ? 'arrow' : null, // null if player
                    bubbleOffset: isNarrator ? { x: 0, y: -150 } : null,
                });

                await tapPromise;
            }
        }
    }
}
