import { Sprite } from 'pixi.js';

import { BasicAsyncHandler } from '../../../lib/defs/types';
import { getContinueCost } from '../../../replicant/defs/product';
import { ConsumeButton } from '../../lib/ui/buttons/ConsumeButton';
import { BasicText } from '../../lib/ui/text/BasicText';
import PopupCoinView from '../home/PopupCoinView';
import { BasicPopup, BasicPopupOptions } from './BasicPopup';
import app from '../../getApp';

const HEIGHT = 670;

// types
//-----------------------------------------------------------------------------
export type NoMovesConfirmPopupOptions = {
    onConfirm: BasicAsyncHandler;
    onCoins: BasicAsyncHandler;
} & BasicPopupOptions;

const manifest = {
    panel: 'icon.character.pitcher.nervous.guy.png',
    button: 'button.green.png',
};

export class NoMovesConfirmPopup extends BasicPopup {
    public override preload() {
        return [
            ...super.preload(),
            ...app().resource.loadAssets([...Object.values(manifest), ...ConsumeButton.assets()]),
        ];
    }

    // @ts-ignore
    public override async spawning(options: NoMovesConfirmPopupOptions) {
        void super.spawning({
            onClose: options.onOk,
            ...options,
            header: '[popupNoMovesTitle]',
            width: 690,
            height: HEIGHT,
        });

        const coinView = new PopupCoinView();
        coinView.onPress = options.onCoins;

        const panel = Sprite.from(manifest.panel);

        const button = new ConsumeButton({
            image: manifest.button,
            text: '[popupNoMovesButton]',
            consumeAmount: getContinueCost(app().server.state),
            slice: {
                width: 360,
                height: 122,
                left: 30,
                top: 16,
                right: 30,
                bottom: 32,
            },
            style: {
                fill: 'FFF',
                fontSize: 50,
                fontWeight: 'bold',
                lineJoin: 'round',
                fontStyle: 'normal',
                dropShadow: true,
                dropShadowDistance: 1,
            },
        });

        button.onPress = options.onConfirm;

        const description = new BasicText({
            text: `[popupNoMovesConfirm]`,
            style: {
                fill: '#3E4047',
                lineJoin: 'round',
                fontWeight: 'bold',
                fontSize: 30,
                align: 'center',
            },
        });
        description.pivot.x = description.width * -0.5;

        this.main.addChild(description);

        // spawn
        this.main.addContent({
            coins: {
                content: coinView,
                styles: {
                    position: 'topCenter',
                    marginTop: -30,
                    marginLeft: -130,
                },
            },
            panel: {
                content: panel,
                styles: {
                    position: 'centerLeft',
                    marginTop: -30,
                    marginLeft: 30,
                },
            },
            description: {
                content: description,
                styles: {
                    position: 'centerRight',
                    marginRight: 220,
                },
            },
            button: {
                content: button,
                styles: {
                    position: 'bottomCenter',
                    marginBottom: 30,
                },
            },
        });
    }
}
