import { waitAFrame } from '@play-co/astro';
import type { Entity, QueriesObject, QueryResults, System } from '@play-co/odie';

import { arrayShuffle } from '../../../../replicant/util/jsTools';
import { BlockComponent } from '../components/BlockComponent';
import { GlowComponent } from '../components/GlowComponent';
import { PhaseComponent } from '../components/PhaseComponent';
import { BasicBlockProps, BlockId, colorToBlockId, PowerBlockType, powerBlockTypes } from '../defs/block';
import { BlockEntity, despawnBlockEntity, PowerBlockEntity, spawnBlockEntity } from '../entities/BlockEntity';
import type { GameScene } from '../GameScene';
import { blockIsFrozen, blockIterateAll, blockIterateNeighbors } from '../util/blockTools';

/*
    handles power block specifics such as combo glows
*/
export class PowerBlockSystem implements System {
    // constants
    //-------------------------------------------------------------------------
    public static readonly NAME = 'power';
    public static Queries: QueriesObject = {
        block: {
            components: [BlockComponent, GlowComponent],
        },
        phase: {
            components: [PhaseComponent],
            modified: true,
        },
    };

    // fields
    //-------------------------------------------------------------------------
    // injected
    public scene!: GameScene;
    public queries!: QueryResults;

    // api
    //-------------------------------------------------------------------------
    public async spawnPowerBoosters(powerBoosters: PowerBlockType[]) {
        const phase = this.scene.sessionEntity.c.phase;

        // collect random candidates (unfrozen basic blocks)
        let candidates: BlockEntity[] = [];
        blockIterateAll(this.scene, (base) => {
            const block = base.c.block;

            // if basic and not frozen
            if (block?.props.type === 'basic' && !blockIsFrozen(this.scene, base)) candidates.push(base);

            return false;
        });
        candidates = arrayShuffle(candidates);

        // push activations
        phase.activePush();

        // replace random candidates with power boosters
        for (const type of powerBoosters) {
            const candidate = candidates.pop();
            if (!candidate) break;
            despawnBlockEntity(this.scene, candidate);
            spawnBlockEntity(
                this.scene,
                { id: this._powerBoosterToBlockId(type, candidate) },
                candidate.c.position.mapPosition,
            );
        }
        await waitAFrame();
        // spawn new block entity
        //despawnBlockEntity(this._scene, entity);
        //spawnBlockEntity(this._scene, { id: spawnBlockId }, this._entity.c.position.mapPosition);

        // pop activations
        await phase.activePop();
    }

    // impl
    //-------------------------------------------------------------------------
    public modifiedQuery(entity: Entity, component: PhaseComponent, properties: any) {
        // if input phase update power block combo glows
        if (properties.phase && component.phase === 'input') {
            this._updateComboGlows();
        }
    }

    // private: actions
    //-------------------------------------------------------------------------
    private _updateComboGlows() {
        // update combo glows
        this.queries.block.forEach((entity: PowerBlockEntity) => this._updateBlockGlow(entity));
    }

    private _updateBlockGlow(entity: PowerBlockEntity) {
        const glow = entity.c.glow;

        // start else stop glow if has power neighbor
        this._hasPowerNeighbor(entity) ? glow.start() : glow.stop();
    }

    // private: support
    //-------------------------------------------------------------------------
    private _hasPowerNeighbor(entity: PowerBlockEntity): boolean {
        let found = false;

        // iterate direct neighbor base blocks starting at given entity
        blockIterateNeighbors(this.scene.sessionEntity.c.map, entity, (base) => {
            // if not frozen
            if (!blockIsFrozen(this.scene, base)) {
                // skip ourselves
                if (base === entity) return true;
                // found if power block
                if (powerBlockTypes.includes(base.c.block.props.type as PowerBlockType)) found = true;
            }
            // dont go past direct neighbor
            return false;
        });

        return found;
    }

    private _powerBoosterToBlockId(type: PowerBlockType, base: BlockEntity): BlockId {
        switch (type) {
            case 'bomb':
                return 'bomb';
            case 'cube': {
                const baseProps = base.c.block.props as BasicBlockProps;
                return colorToBlockId[baseProps.color].cube;
            }
            case 'rocket':
                return Math.random() > 0.5 ? 'rocketHorizontal' : 'rocketVertical';
            default:
                throw Error();
        }
    }
}
