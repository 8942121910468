import { Plugin, PluginOptions } from '@play-co/astro';
import { analytics, Product, Purchase } from '@play-co/gcinstant';
import { captureMessage } from '@sentry/browser';

import NakedPromise from '../../lib/pattern/NakedPromise';
import { PerformanceAnalytics } from '../../lib/performance/PerformanceAnalytics';
import { realMoneyPriceToReadableString } from '../../lib/util/iapConversion';
import { InstantGame } from '../../plugins/instantGames/InstantGame';
import { productIsPurchasable } from '../../replicant/components/product';
import { productDefs, ProductId, productIds, ProductItem } from '../../replicant/defs/product';
import app from '../getApp';
import { RevenueAnalytics, trackPurchaseFailure, trackPurchaseSuccess, trackRevenue } from '../lib/analytics/product';
import { captureGenericError } from '../lib/sentry';
import { ShopPopup } from '../main/shop/ShopPopup';
import type { App } from '../App';

// types
//-----------------------------------------------------------------------------
// public
export type IapServiceOptions = PluginOptions;
export type PurchaseResult = 'ok' | 'cancel' | 'error';

/*
    product purchase service (IAP)
*/
export class ProductService extends Plugin {
    // fields
    //-------------------------------------------------------------------------
    // state
    private _productPromise = new NakedPromise<void>();

    private _products: ProductItem[] = [];

    // properties
    //-------------------------------------------------------------------------
    public get supported(): boolean {
        return InstantGame.platform.paymentsAvailable;
    }

    // init
    //-------------------------------------------------------------------------
    constructor(app: App, options: Partial<IapServiceOptions>) {
        super(app, options);
    }

    public async start() {
        // initialize iap if supported. no need to await
        if (this.supported) {
            void this._initNativeIap().then(() => this._productPromise.resolve());
        } else {
            // load fake product list
            Object.keys(productDefs).forEach((id: ProductId) => {
                this._products.push({
                    ...productDefs[id],
                    getRenderPrice: () => realMoneyPriceToReadableString(`${productDefs[id].priceYen}`, 'JPY'),
                });
            });
            this._productPromise.resolve();
        }
    }

    // api
    //-------------------------------------------------------------------------
    public async getProducts(): Promise<ProductItem[]> {
        // wait on product list then return it
        await this._productPromise;
        return this._products;
    }

    public async purchase(id: ProductId, opts: RevenueAnalytics): Promise<PurchaseResult> {
        // initiate purchase transaction
        return InstantGame.platform
            .purchaseAsync(id)
            .then<PurchaseResult>(({ purchase, product }) => {
                trackRevenue({
                    revenueType: 'in_app_purchase',
                    currencyCodeLocal: purchase.currency || product.currencyCode,
                    dollarToLocalRate: purchase.dollarToLocalRate,
                    revenueGrossLocal: purchase.revenueGrossLocal,
                    ...opts,
                    // Override ruleset product price with the USD revenue reported by GCInstant:
                    revenueGross: purchase.revenueGrossUSD ?? opts.revenueGross,
                });

                trackPurchaseSuccess({
                    ...opts,
                    elapsedSeconds: (app().server.now() - app().analytics.purchaseShownTime) / 1000,
                });
                return 'ok';
            })
            .catch((error) => {
                let result: PurchaseResult = 'error';
                if (error.code === 'USER_INPUT' || error.code === 'paymentCancelled') {
                    result = 'cancel';
                }

                trackPurchaseFailure({
                    ...opts,
                    elapsedSeconds: (app().server.now() - app().analytics.purchaseShownTime) / 1000,
                    userCancelled: result === 'cancel',
                    errorMessage: error.message,
                    errorCode: error.code,
                });

                return result;
            });
    }

    public isPurchasable(id: ProductId): boolean {
        return productIsPurchasable(InstantGame.replicant.state, id);
    }

    // private: init
    //-------------------------------------------------------------------------
    private async _initNativeIap() {
        // initialize iap with our product id list
        await InstantGame.platform.iapInit({ productIDs: productIds as unknown as string[] });

        // setup validation
        InstantGame.platform.onPaymentsReady(() => {
            PerformanceAnalytics.trackTimeTo('paymentsReady');
        });

        // register onProvisionProductAsync() to handle purchases
        InstantGame.platform.onProvisionProductAsync(this._onProvisionProductAsync.bind(this));

        try {
            // load product list
            void InstantGame.platform.getCatalogAsync().then((catalog) => {
                const catalogMap = {} as Record<ProductId, ProductItem>;
                catalog.forEach((product) => {
                    const id = product.id as ProductId;
                    catalogMap[id] = {
                        ...productDefs[id],
                        getRenderPrice: () => realMoneyPriceToReadableString(product.price, product.currencyCode),
                    };
                });

                const missingIds: ProductId[] = [];
                // same order as defined in defs
                Object.keys(productDefs).forEach((id: ProductId) => {
                    const product = catalogMap[id];
                    if (product) {
                        // add in real order
                        this._products.push(product);
                    } else {
                        missingIds.push(id);
                        captureMessage(
                            `Product '${id}' not found in native catalog, catalog size: ${
                                Object.keys(catalogMap).length
                            }`,
                        );
                    }
                });

                if (missingIds.length > 0) {
                    const expectedIds = Object.keys(productDefs);
                    const size = Object.keys(catalogMap).length;
                    analytics.pushEvent('DebugCatalog', { size, expectedIds, missingIds });
                }
            });
        } catch (e) {
            captureGenericError('IAP init failed', e);
        }
    }

    // private: actions
    //-------------------------------------------------------------------------
    private async _showBuySuccess(productId: ProductId) {
        const closePromise = new NakedPromise();
        const popup = 'buyCompletePopup';
        void app().nav.open(popup, {
            product: productDefs[productId],
            onContinue: async () => {
                await app().nav.close(popup);
                closePromise.resolve();
            },
            onClose: async () => {
                await app().nav.close(popup);
                closePromise.resolve();
            },
        });

        await closePromise;
    }

    // private: events
    //-------------------------------------------------------------------------
    private async _onProvisionProductAsync(purchase: Purchase, product: Product) {
        const os = InstantGame.platform.legacyNative?.platform.os;
        if (!os) return;

        // prepare purchase validation args
        const validationArgs = {
            paymentProcessor: os,
            signedRequest: purchase.signedRequest,
            transactionId: purchase.token,
            productIdPrefix: '',
            productId: product?.id ?? '',
        };

        //TODO: use this?
        // const revenueData = {
        //     revenueGrossLocal: purchase.revenueGrossLocal,
        //     revenueGrossUSD: purchase.revenueGrossUSD,
        // };

        // complete purchase
        const result = await InstantGame.replicant.invoke.productPurchaseComplete(validationArgs);

        --app().busy.count; // remove spinner initiated by the shop
        if (result instanceof Error) {
            captureGenericError('IAP validation failed', result);
            // show error
            await app().showError(result);
        } else {
            // show buy success
            await this._showBuySuccess(product.id as ProductId);
        }

        // close if purchase was triggered from the shop
        void ShopPopup.instance?.close();
    }
}
