import { BasicText } from '../../lib/ui/text/BasicText';
import { BasicPopup, BasicPopupOptions } from './BasicPopup';

// types
//-----------------------------------------------------------------------------
export type InfoPopupOptions = BasicPopupOptions & {
    title: string;
    infoText: string;
};

/*
    general info popup
*/
export class InfoPopup extends BasicPopup {
    // @ts-ignore
    public override async spawning(options: InfoPopupOptions) {
        void super.spawning({
            header: options.title,
            width: 600,
            height: 460,
            ...options,
        });

        // spawn
        this.main.addContent({
            sometext: {
                content: new BasicText({
                    text: options.infoText,
                    style: {
                        fill: '#3E4047',
                        align: 'center',
                        fontWeight: 'bold',
                        fontSize: 26,
                        lineJoin: 'round',
                        wordWrap: true,
                        wordWrapWidth: this.main.width - 50,
                    },
                }),
                styles: {
                    position: 'center',
                    marginTop: -10,
                },
            },
        });
    }
}
