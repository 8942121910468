import { GCInstant } from '@play-co/gcinstant';

import { livesGet } from '../../replicant/components/lives';
import { CooldownID, cooldowns } from '../../replicant/defs/cooldowns';
import gameConfig from '../../replicant/defs/gameConfig';
import { getCoinBalance } from '../../replicant/defs/player';
import { Player } from '../../replicant/objects/Player';
import app from '../getApp';
import { getTeamMoraleScore } from '../../replicant/components/teamMorale';

/*
    implementation of the active user player
*/
export class UserPlayer implements Player {
    // properties
    //-------------------------------------------------------------------------
    public get id(): string {
        return GCInstant.playerID;
    }

    public get image(): string {
        return GCInstant.playerPhoto || gameConfig.person.defaultImage;
    }

    public get name(): string {
        return app().server.state.name;
    }

    public get lives(): number {
        return livesGet(app().server.state, app().server.now());
    }

    public get morale(): number {
        return getTeamMoraleScore(app().server.state, app().server.now());
    }

    public get stars(): number {
        return app().server.state.stars;
    }

    public get coins(): number {
        return getCoinBalance(app().server.state);
    }

    public isCooldownFinished(cooldownId: CooldownID): boolean {
        const cooldownStartTimestamp = app().server.state.cooldowns[cooldownId]?.startTimestamp || 0;
        const cooldownDuration = cooldowns[cooldownId].duration;
        return cooldownStartTimestamp + cooldownDuration < app().server.now();
    }
}
