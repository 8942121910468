import { BlockId } from './block';

// goals
//-----------------------------------------------------------------------------
export type GoalId = BlockId;

// maps
//-----------------------------------------------------------------------------
export const goalMap: { [key in GoalId]: string } = {
    android: 'block.android.2.png',
    animal: 'block.animal.png',
    basicBlue: 'block.basic.blue.png',
    basicGreen: 'block.basic.green.png',
    basicPurple: 'block.basic.purple.png',
    basicRed: 'block.basic.red.png',
    basicYellow: 'block.basic.yellow.png',
    bomb: 'block.bomb.png',
    cage: 'block.cage.front.png',
    candle: 'icon.candles.png',
    chain: 'block.barbedwire.top.damage0.png',
    chameleon: 'block.chameleon.blue.png',
    coffin: 'block.coffin.png',
    cubeBlue: 'block.cube.blue.png',
    cubeGreen: 'block.cube.green.png',
    cubePurple: 'block.cube.purple.png',
    cubeRed: 'block.cube.red.png',
    cubeYellow: 'block.cube.yellow.png',
    dog: 'block.dog.png',
    doorAll: 'block.door.all.png',
    doorBlue: 'block.door.blue.png',
    doorGreen: 'block.door.green.png',
    doorPurple: 'block.door.purple.png',
    doorRed: 'block.door.red.png',
    doorYellow: 'block.door.yellow.png',
    firebox: 'block.firebox.png',
    hatAll: 'block.hat.all.png',
    hatBlue: 'block.hat.blue.png',
    hatGreen: 'block.hat.green.png',
    hatPurple: 'block.hat.purple.png',
    hatRed: 'block.hat.red.png',
    hatYellow: 'block.hat.yellow.png',
    hazard: 'block.hazard.png',
    ice: 'icon.ice.full.png',
    kettle: 'block.kettle.png',
    lock: 'block.lock.1.png',
    mysteryImmobile: 'block.mystery.png',
    mysteryMobile: 'block.mystery.round.png',
    pantherLarge: 'block.panther.large.png',
    pantherSmall: 'block.panther.small.png',
    pipe: 'icon.smoke.png',
    pond: 'icon.pond.png',
    prison: 'icon.girl.png',
    randomSpawn: '',
    rocketHorizontal: 'block.rocket.horizontal.png',
    rocketVertical: 'block.rocket.vertical.png',
    siren: 'icon.sirens.png',
    shell: 'icon.shell.png',
    skull: 'block.skull.0.png',
    slime: 'block.slime.png',
    spider: 'block.spider.png',
    fugu: 'block.fugu.1.png',
    miniFugu: 'block.fugu.2.png',
    kappa: 'block.kappa.1.png',
    statue: 'block.statue.2.png',
    thrower: 'block.thrower.png',
    virus: 'block.virus.png',
    wallAll: 'goal.brick.all.png',
    wallBlue: 'goal.brick.blue.png',
    wallGreen: 'goal.brick.green.png',
    wallPurple: 'goal.brick.purple.png',
    wallRed: 'goal.brick.red.png',
    wallYellow: 'goal.brick.yellow.png',
    zombie: 'block.zombie.png',
};
