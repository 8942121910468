import { IFlow } from '../../lib/pattern/IFlow';
import NakedPromise from '../../lib/pattern/NakedPromise';
import { logoutNativeApp } from '../../lib/util/native';
import app from '../getApp';

export class LineLoginFlow implements IFlow {
    private readonly _complete = new NakedPromise<boolean>();

    private _currentAppLaunchRequest: Promise<unknown> | undefined;

    // impl
    //-------------------------------------------------------------------------
    public async execute() {
        const continueLogin = new NakedPromise<boolean>();
        void app().nav.open('lineLoginPopup', {
            onOk: () => continueLogin.resolve(true),
            onClose: () => continueLogin.resolve(false),
            underlay: 0.6,
        });
        const login = await continueLogin;
        void app().nav.close('lineLoginPopup');

        if (login) {
            await this._nativeLogin();
        }

        void this._actionComplete(true);

        return this._complete;
    }

    private async _actionComplete(success: boolean) {
        this._complete.resolve(success);
    }

    private async _nativeLogin() {
        // potentially use for android only when its available in PlatformMock
        // InstantGame.platform.legacyNative.game.reload(true);
        logoutNativeApp();
    }
}
