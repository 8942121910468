import { timeFromComponents } from '../util/timeTools';

export type Cooldown = { duration: number };

export const cooldowns = {
    invitePopup: { duration: timeFromComponents({ hours: 23 }) },
    launchAppPromo: { duration: timeFromComponents({ hours: 23 }) },
};

export type CooldownID = keyof typeof cooldowns;
