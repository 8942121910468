import widget, { Widget } from '@play-co/customer-support-widget';
import { GCInstant } from '@play-co/gcinstant';
import userAgent from '@play-co/user-agent';

import { InstantGame } from '../../../plugins/instantGames/InstantGame';
import app from '../../getApp';

const inputCss = `
            box-sizing: border-box;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            border: 1px solid #0088ff;
            border-radius: 5px;
            padding: 7px;
            margin:0px;
            -webkit-transition: all 0.30s ease-in-out;
            -moz-transition: all 0.30s ease-in-out;
            -ms-transition: all 0.30s ease-in-out;
            -o-transition: all 0.30s ease-in-out;
            outline: none;
          `;

const resultCss = `
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            max-width: 400px;
            padding: 0px 12px 0px 12px;
            font: 20px "Lucida Sans Unicode", "Lucida Grande", sans-serif;
            `;

const buttonCss = `
            float: none;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #0088ff;
            padding: 8px 15px 8px 15px;
            border: none;
            border-radius: 5px;
            color: #fff;
            font-weight: bold;
            width: 100%;
            margin-top: 0px;
            `;

const css = [
    {
        id: 'popup',
        css: `
            max-width: 360px;
            height: 600px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 10px;
            border: 5px solid #0088ff;
            background-color: #ddddee;
            color: #660066;
            `,
    },
    {
        id: 'popup',
        descendant: 'hr',
        css: `
            border: 1px solid #0088ff;
            `,
    },
    {
        id: 'popup-header',
        css: `
            text-align: center;
            height: auto;
            `,
    },
    {
        id: 'title',
        css: `
            margin-top: 15px;
            font: 15px "Lucida Sans Unicode", "Lucida Grande", sans-serif;
            font-weight: bold;
            `,
    },

    {
        id: 'close',
        css: `
            margin-right: 15px;
            font: 15px "Lucida Sans Unicode", "Lucida Grande", sans-serif;
            font-weight: bold;
            `,
    },
    {
        id: 'sending',
        css: resultCss,
    },
    {
        id: 'sending',
        descendant: 'button',
        css: buttonCss,
    },
    {
        id: 'success',
        css: resultCss,
    },
    {
        id: 'success',
        descendant: 'button',
        css: buttonCss,
    },
    {
        id: 'error',
        css: resultCss,
    },
    {
        id: 'error',
        descendant: 'button',
        css: buttonCss,
    },
    {
        id: 'form',
        css: `
            display: flex;
            flex-direction: column;
            max-width: 400px;
            padding: 0px 12px 0px 12px;
            font: 13px "Lucida Sans Unicode", "Lucida Grande", sans-serif;
            `,
    },
    {
        id: 'form',
        descendant: 'label',
        css: `
            display: flex;
            margin-top:0px;
            margin-bottom:-12px;
            padding:0px;
            display:block;
            font-weight: bold;
            `,
    },
    {
        id: 'form',
        descendant: 'input[type=text]',
        css: inputCss,
    },
    {
        id: 'form',
        descendant: 'input[type=email]',
        css: inputCss,
    },
    {
        id: 'form',
        descendant: 'select',
        css: inputCss,
    },
    {
        id: 'form',
        descendant: 'textarea',
        css: inputCss,
    },
    {
        id: 'form',
        descendant: 'input[type=submit]',
        css: buttonCss,
    },
];

let csWidget: Widget;

export async function getSupportWidget() {
    if (csWidget) {
        return csWidget;
    }

    let categoryFieldName: string | undefined;
    const extraFreshdeskFieldNames: string[] = [];
    const locale: 'en' | 'ja' = app().settings.language;
    if (locale === 'ja') {
        categoryFieldName = 'cf_featurebaseballjp';
        extraFreshdeskFieldNames.push('cf_rand175414');
    } else {
        categoryFieldName = undefined;
        extraFreshdeskFieldNames.push('cf_featurebaseball');
    }

    csWidget = widget({
        userId: GCInstant.playerID,
        sid: await app().server.getToken(),
        groupId: 63000249421,
        gameVersion: process.env.APP_VERSION,
        // dev host
        backendHost: process.env.IS_DEVELOPMENT
            ? 'https://cs-widget-backend-dev.us-east-1.replicant.gc-internal.net'
            : undefined,
        form: {
            css,
            categoryFieldName,
            extraFreshdeskFieldNames,
            locale,
        },
    });

    await csWidget.render();

    return csWidget;
}

export async function showSupportWidget() {
    const widget = await getSupportWidget();
    widget.show({
        name: InstantGame.platform.playerName || 'Unknown',
        ltv: 0, //TODO: buru has something here, but it doesnt appear to be written
        browser: userAgent.browser.name,
        os: userAgent.os.name,
        memGB: 0, //TODO: buru uses some timestep lib for this
        lang: navigator?.language ?? 'unknown',
    });
}
