import { Vector2 } from '@play-co/odie';
import { Assets } from 'pixi.js';

import { CubeAnimation } from '../../animations/CubeAnimation';
import { ColorId, PowerBlockType, powerBlockTypes } from '../../defs/block';
import { BlockEntity } from '../../entities/BlockEntity';
import { despawnOverlayEntity, spawnOverlayEntity } from '../../entities/OverlayEntity';
import { GameScene } from '../../GameScene';
import { blockIsFrozen, blockIterateAll, blockNearestNeighbors } from '../../util/blockTools';
import { CollisionTracker } from '../../util/CollisionTracker';
import { IEffect } from './IEffect';

// types
//-----------------------------------------------------------------------------
export type CubeEffectOptions = {
    position: Vector2;
    color: ColorId;
};

/*
    cube block clear effect
*/
export class CubeEffect implements IEffect {
    // fields
    //-------------------------------------------------------------------------
    // input
    private readonly _scene: GameScene;
    private _options: CubeEffectOptions;

    // init
    //-------------------------------------------------------------------------
    constructor(scene: GameScene, options: CubeEffectOptions) {
        this._scene = scene;
        this._options = options;
    }

    static assets(options: Partial<CubeEffectOptions>): string[] {
        return CubeAnimation.assets();
    }

    // impl
    //-------------------------------------------------------------------------
    public async execute() {
        const collisionTracker = new CollisionTracker();
        const targets: BlockEntity[] = [];

        // load assets
        await Assets.load(CubeEffect.assets(this._options));

        // collect targets
        blockIterateAll(this._scene, (base) => {
            if (this._isTarget(base)) targets.push(base);
        });

        // animate targets
        await this._animate(targets);

        // collide targets
        for (const target of targets) {
            collisionTracker.collide(target.c.block, 'attack', { type: 'cube' });
        }
        // then also attack neighbors of targets
        for (const target of targets) {
            const neighbors = blockNearestNeighbors(this._scene.sessionEntity.c.map, target, () => true)?.filter(
                (n) => n !== undefined,
            );
            neighbors?.forEach((neighbor) => {
                collisionTracker.collideAt(this._scene.sessionEntity.c.map, neighbor.c.position.mapPosition, 'block', {
                    type: 'block',
                    entity: target,
                });
            });
        }

        // notify group break event
        this._scene.events.publish({ id: 'group', cause: 'cube', count: targets.length });
    }

    // private: animation
    //-------------------------------------------------------------------------
    private async _animate(targets: BlockEntity[]) {
        const { color, position } = this._options;
        const animation = new CubeAnimation({
            color,
            position,
            targets,
        });

        // spawn animation as overlay entity
        const entity = spawnOverlayEntity(this._scene, position, { width: 1, height: 1 }, () => animation);

        // run animation
        await animation.start();

        // despawn entity when completed
        void animation.completed.then(() => despawnOverlayEntity(this._scene, entity));
    }

    // private: support
    //-------------------------------------------------------------------------
    private _isTarget(entity: BlockEntity): boolean {
        const { color } = this._options;
        const block = entity.c.block;

        // not if frozen
        if (blockIsFrozen(this._scene, entity)) return false;

        // if targeting all colors, target everything except power blocks
        if (color === 'all') {
            return !powerBlockTypes.includes(block.props.type as PowerBlockType);
            // else target basic blocks matching the cube's color
        } else if (block?.props.type === 'basic' && color === block.props.color) {
            return true;
        }

        return false;
    }
}
