import { IFlow } from '../../lib/pattern/IFlow';
import storyDialogs from '../../replicant/defs/storyDialogs';
import { sleep } from '../../replicant/util/jsTools';
import app from '../getApp';
import { trackTaskStart } from '../lib/analytics/task';
import { DialogFlow } from './DialogFlow';

export type TaskIntroFlowOpts = {
    taskLevel: number;
};

export class TaskIntroFlow extends DialogFlow implements IFlow {
    private readonly _taskLevel: number;
    // init
    //-------------------------------------------------------------------------
    constructor(opts: TaskIntroFlowOpts) {
        super();
        this._taskLevel = opts.taskLevel;
    }

    // impl
    //-------------------------------------------------------------------------
    public async execute() {
        await this._actionIntro();
        return this._complete;
    }

    private async _actionIntro() {
        const dialogs = storyDialogs.taskIntroDialogs[this._taskLevel];
        // ------ Preload task screen
        // small pause to allow for dialog scene+assets to load and render
        void sleep(0.5).then(() => app().nav.preload('taskScreen', { stateTask: app().server.state.task }));

        await this._dialogSequence(dialogs);
        await app().server.invoke.updateTaskIntro();

        trackTaskStart();

        void app().nav.open('taskScreen', { stateTask: app().server.state.task });

        void this._actionComplete(true);
    }
}
