import { BLEND_MODES, Graphics } from 'pixi.js';

import { BoundsType } from '../../../../lib/defs/types';
import { pixiToLocalBounds } from '../../../../lib/pixi/pixiTools';
import { SpotLight, SpotLightOptions } from './SpotLight';

// types
//-----------------------------------------------------------------------------
type ShapeId = 'square' | 'circle';
export type TargetSpotLightOptions = {
    targets: BoundsType[];
    radius?: number;
    pad?: number;
    shape?: ShapeId;
} & SpotLightOptions;

/*
    spotlight on targets
*/
export class TargetSpotLight extends SpotLight {
    // fields
    //-------------------------------------------------------------------------
    private _options: TargetSpotLightOptions;

    // init
    //-------------------------------------------------------------------------
    constructor(options: TargetSpotLightOptions) {
        super(options);
        this._options = options;
    }

    // api
    //-------------------------------------------------------------------------
    public override start() {
        // spawn lights around targets. requires we are already spawned
        for (const target of this._options.targets) {
            const bounds = pixiToLocalBounds(this, target);

            // pad the bounds
            bounds.pad(this._options.pad || 0);

            const light = this._spawnShape(this._options.shape || 'circle', bounds.width, bounds.height);
            light.blendMode = BLEND_MODES.ERASE;
            this.addChild(light);
            light.position.set(bounds.x + bounds.width / 2, bounds.y + bounds.height / 2);
        }
    }

    // private
    //-------------------------------------------------------------------------
    private _spawnShape(id: ShapeId, width: number, height: number): Graphics | undefined {
        switch (id) {
            case 'circle':
                return new Graphics()
                    .beginFill()
                    .drawCircle(0, 0, Math.max(width, height) / 2)
                    .endFill();
            case 'square':
                return new Graphics()
                    .beginFill()
                    .drawRect(-width / 2, -height / 2, width, height)
                    .endFill();
        }
        return undefined;
    }
}
