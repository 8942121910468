import { Plugin, PluginOptions } from '@play-co/astro';

import { UserPlayer } from '../objects/UserPlayer';
import type { App } from '../App';

// types
//-----------------------------------------------------------------------------
export type PlayerServiceOptions = PluginOptions;

// settings
//-----------------------------------------------------------------------------

/*
    general game management service
*/
export class GameService extends Plugin {
    // fields
    //-------------------------------------------------------------------------
    // state
    private readonly _player: UserPlayer;

    // properties
    //-------------------------------------------------------------------------
    public get player(): UserPlayer {
        return this._player;
    }

    // init
    //-------------------------------------------------------------------------
    constructor(app: App, options: Partial<PlayerServiceOptions>) {
        super(app, options);

        // create user player
        this._player = new UserPlayer();
    }

    public async init() {}

    public async start() {
        // run tasks
    }

    // api
    //-------------------------------------------------------------------------

    // messages
    public async consumeMessages(ids: number[]) {
        //return InstantGame.replicant.invoke.mailConsume({ ids });
    }
}
