import { Sprite } from 'pixi.js';

import { AppPopup, AppPopupOptions } from './AppPopup';
import app from '../../getApp';

// types
//-----------------------------------------------------------------------------
export type AppPlayPopupOptions = Record<string, any> & AppPopupOptions;

const manifest = {
    panel: 'panel.native.play.png',
};

export class AppPlayPopup extends AppPopup {
    public override preload() {
        return [...super.preload(), ...app().resource.loadAssets([...Object.values(manifest)])];
    }

    // @ts-ignore
    public override async spawning(options: AppPlayPopupOptions) {
        await super.spawning({
            ...options,
        });

        const panel = Sprite.from(manifest.panel);

        // spawn
        this.main.addContent({
            bg: {
                content: panel,
                styles: {
                    position: 'center',
                },
            },
        });
    }
}
