import { SB } from '@play-co/replicant';

const taskSchema = SB.object({
    level: SB.int(),
    introSeen: SB.boolean(), // flag to show scripted intro
    // triggered on task set completion
    timestamp: SB.int(),
    // arbitrary number depending on task set
    steps: SB.array(SB.object({ timestamp: SB.int() })), // timestamp of step was started, -1 if its not a timedstep and completed
});

export type Task = SB.ExtractType<typeof taskSchema>;
// state
//-----------------------------------------------------------------------------
export const taskState = {
    task: taskSchema,
};
//-----------------------------------------------------------------------------

export type TimedStep = {
    time: number;
};

export type StepData = {
    starCost: number;
    label: string;
    icon: string;
    timedStep?: TimedStep;
};

// index is task level
const taskList: {
    title: string;
    completeTitle: string;
    completeSubTitle: string;
    actionButton1: string;
    actionButton2: string;
    actionComplete: string;
    actionProgress: string;
    actionCurrentSteps: (progressLabel: string) => string;
    actionFinalSteps: (progressLabel: string) => string;

    icon: string;
    completeTime: number;
    // homeButtonIcon: string; not used for now, all buttons use a general icon
    bg: string;
    // TODO fix general siloette instead of green/blue
    // iconType: 'grayscale' | 'silouetteGreen' | 'silouetteBlue' | 'silouetteQuestion';
    iconType: 'grayscale' | 'silouetteGreen' | 'silouetteBlue' | 'silouetteQuestion';
    steps: StepData[];
}[] = [
    // --- dugout ---
    {
        title: '[taskDugoutTitle]',
        completeTitle: '[taskDugoutCompleteTitle]',
        completeSubTitle: '[actionBuildSubTitle]',
        actionButton1: '[buttonBuild]',
        actionButton2: '[buttonClaim]',
        actionComplete: '[actionBuildCompleted]',
        actionProgress: '[actionBuildProgress]',
        actionCurrentSteps: (progressLabel: string) => `[actionBuildAmount|${progressLabel}]`,
        actionFinalSteps: (progressLabel: string) => `[actionBuildFinal|${progressLabel}]`,
        icon: `icon.task.dugout.main.png`,

        // homeButtonIcon: 'icon.action.upgrade.png',
        bg: 'bg.squares.png',
        // completeTime: timeFromComponents({ seconds: 4 }),
        completeTime: 0,
        iconType: 'silouetteBlue',
        steps: [
            {
                starCost: 1,
                label: '[taskDugout0]',
                icon: 'icon.task.dugout0.png',
            },
            {
                starCost: 1,
                label: '[taskDugout1]',
                icon: 'icon.task.dugout1.png',
                // timedStep: {
                //     time: timeFromComponents({ minutes: 15 }),
                // },
            },
            {
                starCost: 2,
                label: '[taskDugout2]',
                icon: 'icon.task.dugout2.png',
            },
            {
                starCost: 2,
                label: '[taskDugout3]',
                icon: 'icon.task.dugout3.png',
            },
            {
                starCost: 2,
                label: '[taskDugout4]',
                icon: 'icon.task.dugout4.png',
            },
        ],
    },
    // --- trainingEquipment ---
    {
        title: '[taskTrainingEquipmentTitle]',
        completeTitle: '[taskTrainingEquipmentCompleteTitle]',
        completeSubTitle: '[actionDeliverSubTitle]',
        actionButton1: '[buttonDeliver]',
        actionButton2: '[buttonClaim]',
        actionComplete: '[actionDeliverCompleted]',
        actionProgress: '[actionDeliverProgress]',
        actionCurrentSteps: (progressLabel: string) => `[actionDeliverAmount|${progressLabel}]`,
        actionFinalSteps: (progressLabel: string) => `[actionDeliverFinal|${progressLabel}]`,
        icon: `icon.task.training.equipment.main.png`,
        // completeTime: timeFromComponents({ minutes: 20 }),
        completeTime: 0,
        // homeButtonIcon: 'icon.action.new.png',
        bg: 'bg.squares.png',
        iconType: 'silouetteBlue',
        steps: [
            {
                starCost: 1,
                label: '[taskTrainingEquipment0]',
                icon: 'icon.task.training.equipment0.png',
            },
            {
                starCost: 1,
                label: '[taskTrainingEquipment1]',
                icon: 'icon.task.training.equipment1.png',
                // timedStep: {
                //     time: timeFromComponents({ minutes: 15 }),
                // },
            },
            {
                starCost: 2,
                label: '[taskTrainingEquipment2]',
                icon: 'icon.task.training.equipment2.png',
            },
            {
                starCost: 2,
                label: '[taskTrainingEquipment3]',
                icon: 'icon.task.training.equipment3.png',
            },
            {
                starCost: 2,
                label: '[taskTrainingEquipment4]',
                icon: 'icon.task.training.equipment4.png',
            },
        ],
    },
    // --- teamGear ---
    {
        title: '[taskTeamGearTitle]',
        completeTitle: '[taskTeamGearCompleteTitle]',
        completeSubTitle: '[actionFindSubTitle]',
        actionButton1: '[buttonNotifyTeam]',
        actionButton2: '[buttonNotifyTeam]',
        actionComplete: '[actionFindCompleted]',
        actionProgress: '[actionFindAmount]',
        actionCurrentSteps: (progressLabel: string) => `[actionFindAmount|${progressLabel}]`,
        actionFinalSteps: (progressLabel: string) => `[actionFindAmount|${progressLabel}]`,

        icon: `icon.task.team.gear.main.png`,
        // completeTime: timeFromComponents({ minutes: 20 }),
        completeTime: 0,

        // homeButtonIcon: 'icon.action.find.png',
        bg: 'bg.grass.png',
        iconType: 'silouetteGreen',
        steps: [
            {
                starCost: 1,
                label: '[taskTeamGear0]',
                icon: 'icon.task.team.gear0.png',
            },
            {
                starCost: 1,
                label: '[taskTeamGear1]',
                icon: 'icon.task.team.gear1.png',
                // timedStep: {
                // time: timeFromComponents({ minutes: 15 }),
                // },
            },
            {
                starCost: 2,
                label: '[taskTeamGear2]',
                icon: 'icon.task.team.gear2.png',
            },
            {
                starCost: 2,
                label: '[taskTeamGear3]',
                icon: 'icon.task.team.gear3.png',
            },
            {
                starCost: 2,
                label: '[taskTeamGear4]',
                icon: 'icon.task.team.gear4.png',
            },
            {
                starCost: 2,
                label: '[taskTeamGear5]',
                icon: 'icon.task.team.gear5.png',
            },
        ],
    },
    // --- fieldEquipment ---
    {
        title: '[taskFieldEquipmentTitle]',
        completeTitle: '[taskFieldEquipmentCompleteTitle]',
        completeSubTitle: '[actionRepairSubTitle]',
        actionButton1: '[buttonNotifyTeam]',
        actionButton2: '[buttonNotifyTeam]',

        actionComplete: '[actionRepairCompleted]',
        actionProgress: '[actionRepairAmount]',
        actionCurrentSteps: (progressLabel: string) => `[actionRepairAmount|${progressLabel}]`,
        actionFinalSteps: (progressLabel: string) => `[actionRepairAmount|${progressLabel}]`,

        icon: `icon.task.team.gear.main.png`,
        // completeTime: timeFromComponents({ minutes: 20 }),
        completeTime: 0,

        // homeButtonIcon: 'icon.action.repair.png',
        bg: 'bg.grass.png',
        iconType: 'silouetteGreen',
        steps: [
            {
                starCost: 1,
                label: '[taskFieldEquipment0]',
                icon: 'icon.task.field.equipment0.png',
            },
            {
                starCost: 1,
                label: '[taskFieldEquipment1]',
                icon: 'icon.task.field.equipment1.png',
                // timedStep: {
                // time: timeFromComponents({ minutes: 15 }),
                // },
            },
            {
                starCost: 2,
                label: '[taskFieldEquipment2]',
                icon: 'icon.task.field.equipment2.png',
            },
            {
                starCost: 2,
                label: '[taskFieldEquipment3]',
                icon: 'icon.task.field.equipment3.png',
            },
            {
                starCost: 2,
                label: '[taskFieldEquipment4]',
                icon: 'icon.task.field.equipment4.png',
            },
            {
                starCost: 2,
                label: '[taskFieldEquipment5]',
                icon: 'icon.task.field.equipment5.png',
            },
        ],
    },
    // --- muscleTraining ---
    {
        title: '[taskMuscleTrainingTitle]',
        completeTitle: '[taskMuscleTrainingCompleteTitle]',
        completeSubTitle: '[actionMenuSubTitle]',
        actionButton1: '[buttonPraise]',
        actionButton2: '[buttonPraise]',

        actionComplete: '[actionMenuCompleted]',
        actionProgress: '[actionMenuAmount]',
        actionCurrentSteps: (progressLabel: string) => `[actionMenuAmount|${progressLabel}]`,
        actionFinalSteps: (progressLabel: string) => `[actionMenuAmount|${progressLabel}]`,

        icon: `icon.task.muscle.training.main.png`,
        // completeTime: timeFromComponents({ minutes: 20 }),
        completeTime: 0,

        // homeButtonIcon: 'icon.action.general.png',
        bg: 'bg.squares.png',
        iconType: 'silouetteBlue',
        steps: [
            {
                starCost: 1,
                label: '[taskMuscleTraining0]',
                icon: 'icon.task.muscle.training0.png',
            },
            {
                starCost: 2,
                label: '[taskMuscleTraining1]',
                icon: 'icon.task.muscle.training1.png',
                // timedStep: {
                // time: timeFromComponents({ minutes: 15 }),
                // },
            },
            {
                starCost: 2,
                label: '[taskMuscleTraining2]',
                icon: 'icon.task.muscle.training2.png',
            },
            {
                starCost: 2,
                label: '[taskMuscleTraining3]',
                icon: 'icon.task.muscle.training3.png',
            },
            {
                starCost: 2,
                label: '[taskMuscleTraining4]',
                icon: 'icon.task.muscle.training4.png',
            },
            {
                starCost: 2,
                label: '[taskMuscleTraining5]',
                icon: 'icon.task.muscle.training5.png',
            },
        ],
    },
    // --- trainingCamp ---
    {
        title: '[taskTrainingCampTitle]',
        completeTitle: '[taskTrainingCampCompleteTitle]',
        completeSubTitle: '[actionTCampSubTitle]',
        actionButton1: '[buttonPraise]',
        actionButton2: '[buttonPraise]',

        actionComplete: '[actionTCampCompleted]',
        actionProgress: '[actionTCampAmount]',
        actionCurrentSteps: (progressLabel: string) => `[actionTCampAmount|${progressLabel}]`,
        actionFinalSteps: (progressLabel: string) => `[actionTCampAmount|${progressLabel}]`,

        icon: `icon.task.training.camp.main.png`,
        completeTime: 0,
        bg: 'bg.squares.png',
        iconType: 'silouetteBlue',
        steps: [
            {
                starCost: 1,
                label: '[taskTrainingCamp0]',
                icon: 'icon.task.training.camp0.png',
            },
            {
                starCost: 2,
                label: '[taskTrainingCamp1]',
                icon: 'icon.task.training.camp1.png',
            },
            {
                starCost: 2,
                label: '[taskTrainingCamp2]',
                icon: 'icon.task.training.camp2.png',
            },
            {
                starCost: 2,
                label: '[taskTrainingCamp3]',
                icon: 'icon.task.training.camp3.png',
            },
            {
                starCost: 2,
                label: '[taskTrainingCamp4]',
                icon: 'icon.task.training.camp4.png',
            },
            {
                starCost: 3,
                label: '[taskTrainingCamp5]',
                icon: 'icon.task.training.camp5.png',
            },
        ],
    },
    // --- cheerSquad ---
    {
        title: '[taskCheerSquadTitle]',
        completeTitle: '[taskCheerSquadCompleteTitle]',
        completeSubTitle: '[actionCheerSquadSubTitle]',
        actionButton1: '[buttonNotifyTeam]',
        actionButton2: '[buttonNotifyTeam]',

        actionComplete: '[actionCheerSquadCompleted]',
        actionProgress: '[actionCheerSquadAmount]',
        actionCurrentSteps: (progressLabel: string) => `[actionCheerSquadAmount|${progressLabel}]`,
        actionFinalSteps: (progressLabel: string) => `[actionCheerSquadAmount|${progressLabel}]`,

        icon: `icon.task.cheer.squad.main.png`,
        completeTime: 0,

        bg: 'bg.squares.png',
        iconType: 'silouetteBlue',
        steps: [
            {
                starCost: 1,
                label: '[taskCheerSquad0]',
                icon: 'icon.task.cheer.squad0.png',
            },
            {
                starCost: 2,
                label: '[taskCheerSquad1]',
                icon: 'icon.task.cheer.squad1.png',
            },
            {
                starCost: 2,
                label: '[taskCheerSquad2]',
                icon: 'icon.task.cheer.squad2.png',
            },
            {
                starCost: 3,
                label: '[taskCheerSquad3]',
                icon: 'icon.task.cheer.squad3.png',
            },
            {
                starCost: 3,
                label: '[taskCheerSquad4]',
                icon: 'icon.task.cheer.squad4.png',
            },
            {
                starCost: 3,
                label: '[taskCheerSquad5]',
                icon: 'icon.task.cheer.squad5.png',
            },
        ],
    },
    // --- exams ---
    {
        title: '[taskExamsTitle]',
        completeTitle: '[taskExamsCompleteTitle]',
        completeSubTitle: '[actionMenuSubTitle]',
        actionButton1: '[buttonPraise]',
        actionButton2: '[buttonPraise]',

        actionComplete: '[actionMenuCompleted]',
        actionProgress: '[actionMenuAmount]',
        actionCurrentSteps: (progressLabel: string) => `[actionMenuAmount|${progressLabel}]`,
        actionFinalSteps: (progressLabel: string) => `[actionMenuAmount|${progressLabel}]`,

        icon: `icon.task.exams.main.png`,
        completeTime: 0,

        bg: 'bg.squares.png',
        iconType: 'silouetteBlue',
        steps: [
            {
                starCost: 1,
                label: '[taskExams0]',
                icon: 'icon.task.exams0.png',
            },
            {
                starCost: 2,
                label: '[taskExams1]',
                icon: 'icon.task.exams1.png',
            },
            {
                starCost: 3,
                label: '[taskExams2]',
                icon: 'icon.task.exams2.png',
            },
            {
                starCost: 3,
                label: '[taskExams3]',
                icon: 'icon.task.exams3.png',
            },
            {
                starCost: 3,
                label: '[taskExams4]',
                icon: 'icon.task.exams4.png',
            },
            {
                starCost: 3,
                label: '[taskExams5]',
                icon: 'icon.task.exams5.png',
            },
        ],
    },
    // --- analyzeGame ---
    {
        title: '[taskAnalyzeGameTitle]',
        completeTitle: '[taskAnalyzeGameCompleteTitle]',
        completeSubTitle: '[actionAnalyzeGameSubTitle]',
        actionButton1: '[buttonNotifyResult]',
        actionButton2: '[buttonNotifyResult]',

        actionComplete: '[actionAnalyzeGameCompleted]',
        actionProgress: '[actionAnalyzeGameAmount]',
        actionCurrentSteps: (progressLabel: string) => `[actionAnalyzeGameAmount|${progressLabel}]`,
        actionFinalSteps: (progressLabel: string) => `[actionAnalyzeGameAmount|${progressLabel}]`,

        icon: `icon.task.analyze.game.main.png`,
        completeTime: 0,

        bg: 'bg.grass.png',
        iconType: 'silouetteGreen',
        steps: [
            {
                starCost: 1,
                label: '[taskAnalyzeGame0]',
                icon: 'icon.task.analyze.game0.png',
            },
            {
                starCost: 2,
                label: '[taskAnalyzeGame1]',
                icon: 'icon.task.analyze.game1.png',
            },
            {
                starCost: 3,
                label: '[taskAnalyzeGame2]',
                icon: 'icon.task.analyze.game2.png',
            },
            {
                starCost: 3,
                label: '[taskAnalyzeGame3]',
                icon: 'icon.task.analyze.game3.png',
            },
            {
                starCost: 3,
                label: '[taskAnalyzeGame4]',
                icon: 'icon.task.analyze.game4.png',
            },
            {
                starCost: 4,
                label: '[taskAnalyzeGame5]',
                icon: 'icon.task.analyze.game5.png',
            },
        ],
    },
    // --- bbqParty ---
    {
        title: '[taskBbqPartyGameTitle]',
        completeTitle: '[taskBbqPartyCompleteTitle]',
        completeSubTitle: '[actionBbqPartySubTitle]',
        actionButton1: '[buttonPraise]',
        actionButton2: '[buttonPraise]',

        actionComplete: '[actionBbqPartyCompleted]',
        actionProgress: '[actionBbqPartyAmount]',
        actionCurrentSteps: (progressLabel: string) => `[actionBbqPartyAmount|${progressLabel}]`,
        actionFinalSteps: (progressLabel: string) => `[actionBbqPartyAmount|${progressLabel}]`,

        icon: `icon.task.bbq.party.main.png`,
        completeTime: 0,

        bg: 'bg.grass.png',
        iconType: 'silouetteGreen',
        steps: [
            {
                starCost: 2,
                label: '[taskBbqParty0]',
                icon: 'icon.task.bbq.party0.png',
            },
            {
                starCost: 2,
                label: '[taskBbqParty1]',
                icon: 'icon.task.bbq.party1.png',
            },
            {
                starCost: 3,
                label: '[taskBbqParty2]',
                icon: 'icon.task.bbq.party2.png',
            },
            {
                starCost: 3,
                label: '[taskBbqParty3]',
                icon: 'icon.task.bbq.party3.png',
            },
            {
                starCost: 3,
                label: '[taskBbqParty4]',
                icon: 'icon.task.bbq.party4.png',
            },
            {
                starCost: 4,
                label: '[taskBbqParty5]',
                icon: 'icon.task.bbq.party5.png',
            },
        ],
    },
    // --- designUniform ---
    {
        title: '[taskDesignUniformTitle]',
        completeTitle: '[taskDesignUniformCompleteTitle]',
        completeSubTitle: '[actionDesignUniformSubTitle]',
        actionButton1: '[buttonNotifyTeam]',
        actionButton2: '[buttonNotifyTeam]',

        actionComplete: '[actionDesignUniformCompleted]',
        actionProgress: '[actionDesignUniformAmount]',
        actionCurrentSteps: (progressLabel: string) => `[actionDesignUniformAmount|${progressLabel}]`,
        actionFinalSteps: (progressLabel: string) => `[actionDesignUniformAmount|${progressLabel}]`,

        icon: `icon.task.design.uniform.main.png`,
        completeTime: 0,

        bg: 'bg.grass.png',
        iconType: 'silouetteGreen',
        steps: [
            {
                starCost: 3,
                label: '[taskDesignUniform0]',
                icon: 'icon.task.design.uniform0.png',
            },
            {
                starCost: 3,
                label: '[taskDesignUniform1]',
                icon: 'icon.task.design.uniform1.png',
            },
            {
                starCost: 3,
                label: '[taskDesignUniform2]',
                icon: 'icon.task.design.uniform2.png',
            },
            {
                starCost: 3,
                label: '[taskDesignUniform3]',
                icon: 'icon.task.design.uniform3.png',
            },
            {
                starCost: 3,
                label: '[taskDesignUniform4]',
                icon: 'icon.task.design.uniform4.png',
            },
            {
                starCost: 3,
                label: '[taskDesignUniform5]',
                icon: 'icon.task.design.uniform5.png',
            },
        ],
    },
    // --- workoutEquipment ---
    {
        title: '[taskWorkoutEquipmentTitle]',
        completeTitle: '[taskWorkoutEquipmentCompleteTitle]',
        completeSubTitle: '[actionWorkoutEquipmentSubTitle]',
        actionButton1: '[buttonNotifyTeam]',
        actionButton2: '[buttonNotifyTeam]',

        actionComplete: '[actionWorkoutEquipmentCompleted]',
        actionProgress: '[actionWorkoutEquipmentAmount]',
        actionCurrentSteps: (progressLabel: string) => `[actionWorkoutEquipmentAmount|${progressLabel}]`,
        actionFinalSteps: (progressLabel: string) => `[actionWorkoutEquipmentAmount|${progressLabel}]`,

        icon: `icon.task.workout.equipment.main.png`,
        completeTime: 0,

        bg: 'bg.grass.png',
        iconType: 'silouetteGreen',
        steps: [
            {
                starCost: 1,
                label: '[taskWorkoutEquipment0]',
                icon: 'icon.task.workout.equipment0.png',
            },
            {
                starCost: 2,
                label: '[taskWorkoutEquipment1]',
                icon: 'icon.task.workout.equipment1.png',
            },
            {
                starCost: 3,
                label: '[taskWorkoutEquipment2]',
                icon: 'icon.task.workout.equipment2.png',
            },
            {
                starCost: 3,
                label: '[taskWorkoutEquipment3]',
                icon: 'icon.task.workout.equipment3.png',
            },
            {
                starCost: 4,
                label: '[taskWorkoutEquipment4]',
                icon: 'icon.task.workout.equipment4.png',
            },
            {
                starCost: 4,
                label: '[taskWorkoutEquipment5]',
                icon: 'icon.task.workout.equipment5.png',
            },
        ],
    },
    // --- managersJob ---
    {
        title: '[taskManagersJobTitle]',
        completeTitle: '[taskManagersJobCompleteTitle]',
        completeSubTitle: '[actionMenuSubTitle]',
        actionButton1: '[buttonPraise]',
        actionButton2: '[buttonPraise]',

        actionComplete: '[actionMenuCompleted]',
        actionProgress: '[actionMenuAmount]',
        actionCurrentSteps: (progressLabel: string) => `[actionMenuAmount|${progressLabel}]`,
        actionFinalSteps: (progressLabel: string) => `[actionMenuAmount|${progressLabel}]`,

        icon: `icon.task.managers.job.main.png`,
        completeTime: 0,

        bg: 'bg.squares.png',
        iconType: 'silouetteBlue',
        steps: [
            {
                starCost: 3,
                label: '[taskManagersJob0]',
                icon: 'icon.task.managers.job0.png',
            },
            {
                starCost: 3,
                label: '[taskManagersJob1]',
                icon: 'icon.task.managers.job1.png',
            },
            {
                starCost: 3,
                label: '[taskManagersJob2]',
                icon: 'icon.task.managers.job2.png',
            },
            {
                starCost: 3,
                label: '[taskManagersJob3]',
                icon: 'icon.task.managers.job3.png',
            },
            {
                starCost: 3,
                label: '[taskManagersJob4]',
                icon: 'icon.task.managers.job4.png',
            },
            {
                starCost: 3,
                label: '[taskManagersJob5]',
                icon: 'icon.task.managers.job5.png',
            },
        ],
    },
    // --- schoolFest ---
    {
        title: '[taskSchoolFestJobTitle]',
        completeTitle: '[taskSchoolFestCompleteTitle]',
        completeSubTitle: '[actionSchoolFestSubTitle]',
        actionButton1: '[buttonPraise]',
        actionButton2: '[buttonPraise]',

        actionComplete: '[actionSchoolFestCompleted]',
        actionProgress: '[actionSchoolFestAmount]',
        actionCurrentSteps: (progressLabel: string) => `[actionSchoolFestAmount|${progressLabel}]`,
        actionFinalSteps: (progressLabel: string) => `[actionSchoolFestAmount|${progressLabel}]`,

        icon: `icon.task.school.fest.main.png`,
        completeTime: 0,

        bg: 'bg.squares.png',
        iconType: 'silouetteBlue',
        steps: [
            {
                starCost: 3,
                label: '[taskSchoolFest0]',
                icon: 'icon.task.school.fest0.png',
            },
            {
                starCost: 3,
                label: '[taskSchoolFest1]',
                icon: 'icon.task.school.fest1.png',
            },
            {
                starCost: 3,
                label: '[taskSchoolFest2]',
                icon: 'icon.task.school.fest2.png',
            },
            {
                starCost: 3,
                label: '[taskSchoolFest3]',
                icon: 'icon.task.school.fest3.png',
            },
            {
                starCost: 3,
                label: '[taskSchoolFest4]',
                icon: 'icon.task.school.fest4.png',
            },
            {
                starCost: 3,
                label: '[taskSchoolFest5]',
                icon: 'icon.task.school.fest5.png',
            },
        ],
    },
    // --- expedition ---
    {
        title: '[taskExpeditionTitle]',
        completeTitle: '[taskExpeditionCompleteTitle]',
        completeSubTitle: '[actionExpeditionSubTitle]',
        actionButton1: '[buttonNotifyTeam]',
        actionButton2: '[buttonNotifyTeam]',

        actionComplete: '[actionExpeditionCompleted]',
        actionProgress: '[actionExpeditionAmount]',
        actionCurrentSteps: (progressLabel: string) => `[actionExpeditionAmount|${progressLabel}]`,
        actionFinalSteps: (progressLabel: string) => `[actionExpeditionAmount|${progressLabel}]`,

        icon: `icon.task.expedition.main.png`,
        completeTime: 0,

        bg: 'bg.squares.png',
        iconType: 'silouetteBlue',
        steps: [
            {
                starCost: 1,
                label: '[taskExpedition0]',
                icon: 'icon.task.expedition0.png',
            },
            {
                starCost: 2,
                label: '[taskExpedition1]',
                icon: 'icon.task.expedition1.png',
            },
            {
                starCost: 3,
                label: '[taskExpedition2]',
                icon: 'icon.task.expedition2.png',
            },
            {
                starCost: 3,
                label: '[taskExpedition3]',
                icon: 'icon.task.expedition3.png',
            },
            {
                starCost: 4,
                label: '[taskExpedition4]',
                icon: 'icon.task.expedition4.png',
            },
            {
                starCost: 4,
                label: '[taskExpedition5]',
                icon: 'icon.task.expedition5.png',
            },
        ],
    },
];

export default {
    taskList,
};
