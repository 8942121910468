import { extendReplicantConfigForDebugPanel } from '@play-co/debug-panel/replicant';
import { ActiveFriendCounts, ClusterCounts } from '@play-co/gcinstant';
import { extendReplicantConfigForAnalytics } from '@play-co/gcinstant/replicantExtensions';
import { createConfig } from '@play-co/replicant';

import { asyncGetters } from './asyncGetters';
import chatbot from './chatbot/index';
import { abtestActions } from './components/abtests';
import { accountActions } from './components/account';
import { adminActions, adminTool } from './components/admin';
import { boosterActions } from './components/boosters';
import { cheatActions } from './components/cheats';
import { coreActions } from './components/core';
import { dailyBonusActions } from './components/dailyBonus';
import { gameDayActions } from './components/gameDay';
import { nativeActions } from './components/native';
import { playerActions } from './components/player';
import { productActions } from './components/product';
import { puzzleActions } from './components/puzzle';
import { settingsActions } from './components/settings';
import { shopActions } from './components/shop';
import { taskActions } from './components/task';
import { tutorialActions } from './components/tutorial';
import { computedProperties } from './computedProperties';
import { messages } from './messages';
import { migrator } from './migrations';
import { abTests } from './ruleset';
import { scheduledActions } from './scheduledActions';
import { stateSchema } from './state';
import { eventHandlers } from './eventHandlers';
import { teamMoraleActions } from './components/teamMorale';

// friend entry data
//-----------------------------------------------------------------------------
export type FriendsEntryData = {
    activeFriendCounts: ActiveFriendCounts;
    clusterCounts: ClusterCounts;
};

// combined actions
//-----------------------------------------------------------------------------
const actions = {
    ...abtestActions,
    ...accountActions,
    ...adminActions,
    ...boosterActions,
    ...cheatActions,
    ...coreActions,
    ...dailyBonusActions,
    ...gameDayActions,
    ...nativeActions,
    ...playerActions,
    ...productActions,
    ...puzzleActions,
    ...settingsActions,
    ...shopActions,
    ...tutorialActions,
    ...taskActions,
    ...teamMoraleActions,
};

/*
    replicant config
*/
const config = createConfig({
    adminTool,
    appName: process.env.APP_NAME,
    eventHandlers,
    actions,
    asyncGetters,
    chatbot,
    computedProperties,
    enableLineAccessTokenAuthentication: true,
    migrator,
    messages,
    scheduledActions,
    stateSchema,
    ruleset: { abTests },
    onLoginAction: 'coreInit',
    version: process.env.APP_VERSION,
});

export default extendReplicantConfigForDebugPanel(
    extendReplicantConfigForAnalytics(config, { amplitudeTimeZone: 'Asia/Tokyo' }),
);
