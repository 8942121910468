import { captureMessage } from '@sentry/browser';

import { IFlow } from '../../lib/pattern/IFlow';
import NakedPromise from '../../lib/pattern/NakedPromise';
import { guessInstalledNativeAppPlatform, isNativeRewardClaimed, isPlatformNative } from '../../lib/util/native';
import { InstantGame } from '../../plugins/instantGames/InstantGame';
import { getAndroidRewardState, getIosRewardState } from '../../replicant/components/native';
import { PowerBoosterId } from '../../replicant/defs/booster';
import native from '../../replicant/defs/native';
import { sleep } from '../../replicant/util/jsTools';
import { trackLiffLoginCrossplaySwitch, TriggerSource } from '../lib/analytics/native';
import { ShopPinchRestore } from '../lib/analytics/pinch';
import { getActivePuzzleController } from '../lib/tools/puzzleTools';
import app from '../getApp';
import { analytics } from '@play-co/gcinstant';

type NativePromoFlowOptions = {
    payload: {
        triggerSource: TriggerSource;
        feature: string;
        subFeature?: string;
        productID?: string;
    };
    skipPopup?: boolean;
    shopPinch?: ShopPinchRestore;
    crossplayPowerBoosters?: PowerBoosterId[];
};

export class NativePromoFlow implements IFlow {
    private readonly _complete = new NakedPromise<boolean>();
    private readonly _options: NativePromoFlowOptions;

    private _currentAppLaunchRequest: Promise<unknown> | undefined;

    // init
    //-------------------------------------------------------------------------
    constructor(options: NativePromoFlowOptions) {
        this._options = options;
    }

    // impl
    //-------------------------------------------------------------------------
    public async execute() {
        // save puzzle state if actively in a puzzle
        getActivePuzzleController()?.saveState();

        const isClaimed = isNativeRewardClaimed();
        if (!isClaimed) await this._updateRewardState();

        if (this._options.skipPopup) {
            await this._directFlow();
        } else {
            await this._popupFlow();
        }

        return this._complete;
    }

    private async _directFlow() {
        await this._onPromoConfirm();
        void this._actionComplete(true);
    }

    private async _popupFlow() {
        const isClaimed = isNativeRewardClaimed();
        const closePromise = new NakedPromise();
        if (!isClaimed) {
            await this._updateRewardState();
            const installId = 'appInstallPopup';
            void app().nav.open(installId, {
                ...native.rewards,
                onOk: () => {
                    void this._onPromoConfirm();
                },
                onClose: () => {
                    void app().nav.close(installId);
                    closePromise.resolve();
                },
                underlay: 0.6,
            });
        } else {
            const playId = 'appPlayPopup';
            void app().nav.open(playId, {
                onOk: () => {
                    void this._onPromoConfirm();
                },
                onClose: () => {
                    void app().nav.close(playId);
                    closePromise.resolve();
                },
                underlay: 0.6,
            });
        }
        await closePromise;
        void this._actionComplete(true);
    }

    private async _actionComplete(success: boolean) {
        this._complete.resolve(success);
    }

    private async _updateRewardState() {
        const platform = InstantGame.platform;
        if (platform.platformID === 'line-guest') return;

        const state = InstantGame.replicant.state;
        const os = InstantGame.platform.osType;
        if (os === 'IOS') {
            if (getIosRewardState(state) === 'unseen') {
                await InstantGame.replicant.invoke.setIosRewardToSeen();
            }
        } else if (os === 'ANDROID') {
            if (getAndroidRewardState(state) === 'unseen') {
                await InstantGame.replicant.invoke.setAndroidRewardToSeen();
            }
        }
    }

    private async _onPromoConfirm() {
        const appOpened = await this._openNativeApp();

        if (!appOpened) {
            analytics.game.crossplay.trackCrossplayStart({ ...this._options.payload, isInstall: true });
            const crossPlayTracking = {
                ...this._options.payload,
                wasInBackground: false,
                timestamp: app().server.now(),
            };
            const crossplayPayload = InstantGame.platform.crossplay.getCrossplayPayload(crossPlayTracking);
            const switchPayload = {
                ...InstantGame.platform.entryData,
                ...crossplayPayload,
            };

            await app().server.invoke.setPersistedPayload({ payload: JSON.stringify(switchPayload) });
            await app().server.flush();

            const osType = InstantGame.platform.osType;
            switch (osType) {
                case 'IOS':
                case 'IOS_APP':
                case 'WEB_IOS':
                    window.open(native.appStoreURL);
                    break;

                case 'ANDROID':
                case 'ANDROID_APP':
                case 'WEB_ANDROID':
                    window.open(native.googlePlayURL);
                    break;

                default:
                    captureMessage('Unsupported app store platform', {
                        level: 'warning',
                        extra: { platform: osType },
                    });
            }
        }
    }

    private async _openNativeApp() {
        const trigger = this._options.payload.triggerSource;

        // Can't open native app if we're already using it, or it's not installed
        if (isPlatformNative() || !guessInstalledNativeAppPlatform()) return false;

        // Don't allow multiple concurrent app launch attempts
        if (this._currentAppLaunchRequest) return false;

        let switchPayload = {};
        if (trigger === 'purchase_redirect' || trigger === 'purchase_install') {
            switchPayload = {
                openIAP: true,
                // indicate we came from pinch to skip moves/lives popup after purchase of coins, or just undefined
                shopPinch: this._options.shopPinch,
                crossplayPowerBoosters: this._options.crossplayPowerBoosters, // undefined or list
            };
        }

        const crossplayPayload = InstantGame.platform.crossplay.getCrossplayPayload({
            ...this._options.payload,
            wasInBackground: false,
            timestamp: app().server.now(),
        });

        switchPayload = {
            ...switchPayload,
            ...InstantGame.platform.entryData,
            ...crossplayPayload,
        };

        const switchPayloadText = encodeURIComponent(JSON.stringify(switchPayload));
        const deepLink = `${native.appLaunchUrl}?payload=${switchPayloadText}`;

        if (trigger === 'crossplay_popup') {
            // extra analytics for launch
            trackLiffLoginCrossplaySwitch({ deepLinkLength: deepLink.length });
        }

        analytics.game.crossplay.trackCrossplayStart({ ...this._options.payload, isInstall: false });
        // Make sure to flush replicant before launching the app.
        await app().server.flush();

        // Make sure no other request is in progress.
        if (this._currentAppLaunchRequest) return false;

        // Launch native app and wait for it to switch
        this._currentAppLaunchRequest = new Promise<void>((resolve) => {
            document.addEventListener('visibilitychange', () => {
                if (document.visibilityState !== 'visible') {
                    resolve();
                }
            });
            window.location.assign(deepLink);
        });

        // Close window after app switch
        void this._currentAppLaunchRequest.then(() => {
            if (window.liff?.isInClient()) {
                window.liff?.closeWindow();
            } else {
                window.close();
            }
        });

        return (
            Promise.race([
                // Return true if the app switch is detected
                this._currentAppLaunchRequest.then(() => true),

                // Return false if the app switch is not detected within 1 second
                sleep(1).then(() => false),
            ])
                // Allow subsequent app launch attempts after the current one resolves
                .finally(() => (this._currentAppLaunchRequest = null))
        );
    }
}
