import { Container, RenderTexture, Sprite } from 'pixi.js';
import app from '../../getApp';

// api
//-----------------------------------------------------------------------------
export function openURL(url: string) {
    if (app().platform.ios) window.location.href = url;
    else window.open(url);
}

export function formatResourceCount(count: number): string {
    return count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

export function objectToSprite(object: Container): Sprite {
    // get object bounds
    const bounds = object.getLocalBounds();

    // create texture target
    const renderTexture = RenderTexture.create({ width: bounds.width, height: bounds.height });

    // retranslate transform and render to this texture
    const transform = object.localTransform.clone();
    transform.translate(-bounds.x, -bounds.y);
    app().stage.renderer.render(object, { renderTexture, transform });

    // create sprite from rendered texture
    const sprite = new Sprite(renderTexture);

    // reposition sprite by bounds
    sprite.position.set(bounds.x, bounds.y);

    return sprite;
}
