import { IFlow } from '../../../lib/pattern/IFlow';
import { getGameDayReward } from '../../../replicant/components/gameDay';
import { getTeamMoraleScore } from '../../../replicant/components/teamMorale';
import features from '../../../replicant/defs/features';
import { sleep } from '../../../replicant/util/jsTools';
import app from '../../getApp';
import { GameDay0Flow } from './GameDay0Flow';
import { GameDay1Flow } from './GameDay1Flow';
import { GameDay2Flow } from './GameDay2Flow';
import { GameDay3Flow } from './GameDay3Flow';
import { GameDay4Flow } from './GameDay4Flow';
import { GameDay5Flow } from './GameDay5Flow';
import { GameDay6Flow } from './GameDay6Flow';
import { GameDay7Flow } from './GameDay7Flow';

export class GameDayMainFlow implements IFlow {
    // fields
    //-------------------------------------------------------------------------

    // default, 1 game per day
    private _gameDayFlows = {
        first: [
            async () => new GameDay0Flow({ gameId: 'first' }).execute(),
            async () => new GameDay1Flow({ gameId: 'first' }).execute(),
            async () => new GameDay2Flow({ gameId: 'first' }).execute(),
            async () => new GameDay3Flow({ gameId: 'first' }).execute(),
            async () => new GameDay4Flow({ gameId: 'first' }).execute(),
            async () => new GameDay5Flow({ gameId: 'first' }).execute(),
            async () => new GameDay6Flow({ gameId: 'first' }).execute(),
            async () => new GameDay7Flow({ gameId: 'first' }).execute(),
        ],
    };

    // 2 games per day
    private _gameDayFlowMultiple = {
        // first game of the day
        first: [
            async () => new GameDay0Flow({ gameId: 'first' }).execute(),
            async () => new GameDay2Flow({ gameId: 'first' }).execute(),
            async () => new GameDay4Flow({ gameId: 'first' }).execute(),
            async () => new GameDay6Flow({ gameId: 'first' }).execute(),
            async () => new GameDay0Flow({ gameId: 'first' }).execute(),
            async () => new GameDay2Flow({ gameId: 'first' }).execute(),
            async () => new GameDay4Flow({ gameId: 'first' }).execute(),
            async () => new GameDay6Flow({ gameId: 'first' }).execute(),
        ],
        // second game of the day
        second: [
            async () => new GameDay1Flow({ gameId: 'second' }).execute(),
            async () => new GameDay3Flow({ gameId: 'second' }).execute(),
            async () => new GameDay5Flow({ gameId: 'second' }).execute(),
            async () => new GameDay7Flow({ gameId: 'second' }).execute(),
            async () => new GameDay1Flow({ gameId: 'second' }).execute(),
            async () => new GameDay3Flow({ gameId: 'second' }).execute(),
            async () => new GameDay5Flow({ gameId: 'second' }).execute(),
            async () => new GameDay7Flow({ gameId: 'second' }).execute(),
        ],
    };

    // AB test
    private _gameDayFlowsMorale = {
        first: [
            {
                win: async () =>
                    new GameDay0Flow({ gameId: 'first', opponentOverride: '[gameDay0Opponent]' }).execute(),
                loss: async () =>
                    new GameDay1Flow({ gameId: 'first', opponentOverride: '[gameDay0Opponent]' }).execute(),
            },
            {
                win: async () =>
                    new GameDay2Flow({ gameId: 'first', opponentOverride: '[gameDay1Opponent]' }).execute(),
                loss: async () =>
                    new GameDay3Flow({ gameId: 'first', opponentOverride: '[gameDay1Opponent]' }).execute(),
            },
            {
                win: async () =>
                    new GameDay4Flow({ gameId: 'first', opponentOverride: '[gameDay2Opponent]' }).execute(),
                loss: async () =>
                    new GameDay6Flow({ gameId: 'first', opponentOverride: '[gameDay2Opponent]' }).execute(),
            },
            {
                win: async () =>
                    new GameDay5Flow({ gameId: 'first', opponentOverride: '[gameDay3Opponent]' }).execute(),
                loss: async () =>
                    new GameDay7Flow({ gameId: 'first', opponentOverride: '[gameDay3Opponent]' }).execute(),
            },

            // re-run same schedule up to 8 days
            {
                win: async () =>
                    new GameDay0Flow({ gameId: 'first', opponentOverride: '[gameDay4Opponent]' }).execute(),
                loss: async () =>
                    new GameDay1Flow({ gameId: 'first', opponentOverride: '[gameDay4Opponent]' }).execute(),
            },
            {
                win: async () =>
                    new GameDay2Flow({ gameId: 'first', opponentOverride: '[gameDay5Opponent]' }).execute(),
                loss: async () =>
                    new GameDay3Flow({ gameId: 'first', opponentOverride: '[gameDay5Opponent]' }).execute(),
            },
            {
                win: async () =>
                    new GameDay4Flow({ gameId: 'first', opponentOverride: '[gameDay6Opponent]' }).execute(),
                loss: async () =>
                    new GameDay6Flow({ gameId: 'first', opponentOverride: '[gameDay6Opponent]' }).execute(),
            },
            {
                win: async () =>
                    new GameDay5Flow({ gameId: 'first', opponentOverride: '[gameDay7Opponent]' }).execute(),
                loss: async () =>
                    new GameDay7Flow({ gameId: 'first', opponentOverride: '[gameDay7Opponent]' }).execute(),
            },
        ],
    };

    // impl
    //-------------------------------------------------------------------------
    async execute(): Promise<void> {
        const { game, index, isWin } = await app().server.invoke.gameDayStart();
        console.log(`- Starting game: ${game} ${index}, ${isWin}`);

        const state = app().server.state;
        const rewardMap = {
            first: () => {
                const gameId = state.gameDay.day;
                return getGameDayReward(state, {
                    gameId,
                    game: 0,
                    previousTimestamp: state.gameDay.timestamp,
                    teamMorale: getTeamMoraleScore(state, app().server.now()),
                });
            },
            second: () => {
                const gameId = state.gameDay.secondGame.day;
                return getGameDayReward(state, { gameId, game: 1 });
            },
        };

        // preload during game play dialogs
        void sleep(1.5).then(() => {
            const reward = rewardMap[game]();
            void app().nav.preload('gameDayRewardPopup', { results: reward.result, rewards: reward.items });
        });

        if (features.multipleGames) {
            // complete depending on what started
            await this._gameDayFlowMultiple[game][index]();
        } else {
            const result = isWin ? 'win' : 'loss';
            await this._gameDayFlowsMorale[game as 'first'][index][result]();
        }
        await app().server.invoke.gameDayComplete({ game });
    }
}
