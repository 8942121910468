import { Container, Graphics, ITextStyle, NineSlicePlane, Sprite, TEXT_GRADIENT, Texture } from 'pixi.js';

import { uiAlignCenter, uiAlignCenterY, uiCreateMask, uiCreateQuad, uiSizeToWidth } from '../../../lib/pixi/uiTools';
import { tween } from '../../../lib/util/tweens';
import { Scoreboard } from '../../../replicant/defs/gameDay';
import { arrayShuffle, sleep } from '../../../replicant/util/jsTools';
import { BaseballPlayer, PlayerClipId, PlayerSkin } from '../../concept/BaseballPlayer';
import { Confetti } from '../../concept/Confetti';
import { Speedlines } from '../../concept/Speedlines';
import { pixiConfig } from '../../defs/config';
import { BasicText } from '../../lib/ui/text/BasicText';
import { boardAssets, SpeechScreen } from './SpeechScreen';
import app from '../../getApp';

const PLAYER_Y_OFF = 440;

export type GameDayPlayer = {
    clip: PlayerClipId;
    skin: PlayerSkin;
    loop: boolean;
    offsetX?: number;
    offsetY?: number;
    scaleX?: number;
    scale?: number;
    animateRun?: boolean;
    overrideRunCallback?: (player: BaseballPlayer) => Promise<void>;
};

type GameDayResult = 'victory' | 'loss';

// types
//-----------------------------------------------------------------------------
export type GameDayScreenOptions = {
    bgId: BackgroundId;
    players?: GameDayPlayer[];
    fullTeam?: { type: GameDayResult };
    speedlines?: boolean; // blue animation lines in the middle of the screen
};

// not used for intial scene,
const lazyManifest = {
    lossPanel: 'panel.drop.down.png',
};

// manifest
//-----------------------------------------------------------------------------
const manifest = {
    topFrame: 'frame.top.blue.png',
};

// TODO change to baseball naming once we understand all the types
const bgMap: { [key in BackgroundId]: string | string[] } = {
    gameDay0: 'bg.game.day0.png',
    gameDay1: 'bg.game.day1.png',
    gameDay2: 'bg.game.day2.png',
    gameDay3: 'bg.game.day3.png',
    gameDay4: 'bg.intro2.png',
    gameDay5: 'bg.main.png',
    thirdBase: 'bg.game.third.base.png',
    victory: 'bg.main.png',
    loss: 'bg.main.png', // same as victory for now
    triplePan: ['bg.game.day0.png', 'bg.game.day1.png'],
};

type BackgroundId =
    | 'gameDay0'
    | 'gameDay1'
    | 'gameDay2'
    | 'gameDay3'
    | 'gameDay4'
    | 'gameDay5'
    | 'thirdBase'
    | 'victory'
    | 'loss'
    | 'triplePan';

export class GameDayScreen extends SpeechScreen {
    // events
    //-------------------------------------------------------------------------
    // scene
    private _headerFrame: NineSlicePlane;
    private _bgMask: Graphics;
    private _name: BasicText;
    private _bgScroll: NineSlicePlane;
    private _triplePanningDuration = 6;
    private _players: BaseballPlayer[];

    public get triplePanningDuration() {
        return this._triplePanningDuration;
    }

    public get nameView() {
        return this._name;
    }

    public get mainPlayer() {
        return this._players[0];
    }

    public getPlayers() {
        return this._players;
    }

    // https://pixijs.download/dev/docs/PIXI.Assets.html
    public async preloadLazyAsset(asset: keyof typeof lazyManifest) {
        await app().resource.loadAsset(lazyManifest[asset]);
    }

    // impl
    //-------------------------------------------------------------------------
    public override preload(options: GameDayScreenOptions) {
        const assets = [...Object.values(manifest), ...BaseballPlayer.assets()];
        if (options.bgId !== 'triplePan') {
            assets.push(bgMap[options.bgId] as string);
        } else {
            assets.push(...bgMap.triplePan);
        }
        if (options?.fullTeam?.type === 'victory') {
            assets.push(...Confetti.assets());
        } else if (options?.fullTeam?.type === 'loss') {
            assets.push(lazyManifest.lossPanel);
        }

        if (options.speedlines) {
            assets.push(...Speedlines.assets());
        }
        return [...super.preload(), ...app().resource.loadAssets(assets)];
    }

    public async spawning(options: GameDayScreenOptions) {
        void this.addOrientationListener();
        this._spines = [];
        this._players = [];

        // play music
        void app().music.play('bgm_championship.ogg');

        this._isInteracting = false;

        // spawn scene
        void this._spawn(options);

        this._bgMask = uiCreateMask(900, this._bg.height);
        this._bg.mask = this._bgMask;
        this._bg.addChild(this._bgMask);
        uiAlignCenter(this._bg, this._bgMask);
    }

    public async horizontalAnimation(opts?: { playerIndex?: number }) {
        const playerIndex = opts?.playerIndex ?? 0;
        const player = this._players[playerIndex];
        await player
            .animate()
            .add(player, { x: this._bg.x - 100 }, 1.85, tween.linear)
            .promise();
    }

    public async runStopAnimation(opts: { playerIndex?: number; xOffset: number }) {
        const { playerIndex, xOffset } = opts;
        const index = playerIndex ?? 0;
        const player = this._players[index];
        await player
            .animate()
            .add(player, { x: this._bg.x + xOffset }, 1.5, tween.linear)
            .promise();

        await this.playPlayerAnimation({ playerIndex, id: 'run_transition_onbase_flipped', loop: false });
        void this.playPlayerAnimation({ playerIndex, id: 'onbase1_flipped', loop: true });
    }

    public despawned() {
        this.empty();
    }

    public playPlayerAnimation(opts: {
        playerIndex?: number;
        id: PlayerClipId;
        loop?: boolean;
        mix?: number;
        scaleX?: number;
    }): Promise<void> {
        const { playerIndex, id, loop, mix, scaleX } = opts;
        const player = this._players[playerIndex ?? 0];
        player.scale.x *= scaleX ?? 1;
        return player.start({ id, loop, mix });
    }

    public async spawnPlayer(opts: { player: GameDayPlayer; playerIndex?: number; isHorizontalScroll?: boolean }) {
        const { player, playerIndex, isHorizontalScroll } = opts;
        const { clip, loop, skin, offsetX, offsetY, scaleX, scale, animateRun, overrideRunCallback } = player;

        const playerSpine = new BaseballPlayer(skin);
        playerSpine.scale.set(scale ?? 0.88);
        playerSpine.scale.x = playerSpine.scale.x * (scaleX ?? 1);

        this._spines.push(playerSpine);
        this._players.push(playerSpine);

        playerSpine.zIndex = 4;

        if (isHorizontalScroll) {
            this._bgScroll.addChild(playerSpine);
            playerSpine.position.set(
                this._bgScroll.width * 0.5 + (offsetX ?? 0),
                this._bgScroll.height - PLAYER_Y_OFF + (offsetY ?? 0),
            );
        } else {
            this._bg.addChild(playerSpine);
            playerSpine.position.set(
                this._bg.width * 0.5 + (offsetX ?? 0),
                this._bg.height - PLAYER_Y_OFF + (offsetY ?? 0),
            );
        }

        void this.playPlayerAnimation({ playerIndex, id: clip, loop });
        playerSpine.speed = 1;

        // run across the screen
        if (animateRun) {
            if (overrideRunCallback) {
                void overrideRunCallback(playerSpine);
            } else {
                void sleep(0.1).then(() => this.horizontalAnimation({ playerIndex }));
            }
        }
    }

    private _createTeam(type: GameDayResult) {
        const teamContainer = new Container();
        const team: { skin: PlayerSkin; scaleX: number; offsetX?: number; scale?: number }[] = [
            {
                skin: 'character2_girl',
                scale: 0.55,
                scaleX: 1,
            },
            {
                skin: 'character5_boy',
                scale: 0.55,
                scaleX: -1,
            },
            {
                skin: 'character1_boy',
                scale: 0.85,
                scaleX: 1,
                offsetX: -50,
            },
            {
                skin: 'character4_girl',
                scaleX: -1,
                scale: 0.85,
                offsetX: 50,
            },
        ];

        const defaultX = 5;
        let x = defaultX;
        let y = 0;

        const lossClips: PlayerClipId[] = arrayShuffle(['batter_sad', 'pitcher_sad', 'pitcher_sad', 'fielder_sad']);

        team.forEach((playerData, index) => {
            const { skin, scaleX, scale, offsetX } = playerData;
            const teamMate = new BaseballPlayer(skin);
            this._spines.push(teamMate);

            teamMate.scale.set(scale);
            teamMate.scale.x = teamMate.scale.x * scaleX;

            // teamMate.zIndex = 4;
            this._bg.addChild(teamMate);
            teamMate.position.set(x + (offsetX ?? 0), y);

            if (type === 'victory') {
                void teamMate.start({ id: 'celebrate' as PlayerClipId, loop: true });
            } else {
                // loss
                const clip = lossClips.shift();
                void teamMate.start({ id: clip, loop: true });
            }
            teamMate.speed = 1;

            x += 300;
            if (index === 1) {
                y += 400;
                x = defaultX;
            }

            teamContainer.addChild(teamMate);
        });

        return teamContainer;
    }

    public async spawnScoreDynamic(opts: {
        visitor: string;
        visitorData: string[];
        visitorR: number;
        home: string;
        homeData: string[];
        homeR: number;
        scoreboard: Scoreboard;
        balls: number;
        strikes: number;
        outs: number;
    }): Promise<void> {
        const { visitor, visitorData, visitorR, home, homeData, homeR, scoreboard, balls, strikes, outs } = opts;
        // this should been called before from the flow and will resolve instantly
        await this.preloadScoreboard(scoreboard);
        const scorePanel = Sprite.from(boardAssets[scoreboard].board);

        const scoreStyle = {
            fill: '#fff',
            fontSize: 70,
            fontWeight: 'bold',
            lineJoin: 'round',
            align: 'center',
        } as Partial<ITextStyle>;
        // ---- score
        const addScore = (data: string[], y: number) => {
            const scoreX = 660;
            const scoreOffsetX = 103;
            for (let i = 0; i < data.length; i++) {
                const number = new BasicText({
                    text: `${data[i]}`,
                    style: scoreStyle,
                });

                uiSizeToWidth(number, 68);
                number.pivot.set(number.width * 0.5, number.height * 0.5);
                number.position.set(scoreX + i * scoreOffsetX, y);
                scorePanel.addChild(number);
            }
        };
        addScore(visitorData, 208);
        addScore(homeData, 302 + 40);

        const addR = (amount: number, y: number) => {
            const r = new BasicText({
                text: `${amount}`,
                style: scoreStyle,
            });
            r.pivot.set(r.width * 0.5, r.height * 0.5);
            uiSizeToWidth(r, 140);
            r.position.set(1626, y);
            scorePanel.addChild(r);
        };
        addR(visitorR, 208);
        addR(homeR, 302 + 40);
        // -----------------

        // ----- lights
        const ballX = 772;
        const strikeX = ballX + 418;
        const outX = ballX + 708;
        const addLights = (amount: number, x: number) => {
            const y = 449;
            const offsetX = 46;
            for (let i = 0; i < amount; i++) {
                const light = Sprite.from(boardAssets[scoreboard].light);
                light.position.set(x + i * offsetX, y);
                scorePanel.addChild(light);
            }
        };

        addLights(balls, ballX);
        addLights(strikes, strikeX);
        addLights(outs, outX);
        // -----------------

        const style = {
            fill: '#fff',
            fontSize: 64,
            fontWeight: 'bold',
            lineJoin: 'round',
        } as Partial<ITextStyle>;

        const visitorName = new BasicText({
            text: visitor,
            style,
        });
        const homeName = new BasicText({
            text: home,
            style,
        });

        const x = 145;
        visitorName.x = x;
        visitorName.y = 172;

        homeName.x = x;
        homeName.y = 312;
        uiSizeToWidth(visitorName, 440);
        uiSizeToWidth(homeName, 440);
        scorePanel.addChild(visitorName, homeName);

        // uiAlignCenterY(this.base, scorePanel);
        // add as content to scale accordingly with the base parent
        this.base.addContent({
            score: {
                content: scorePanel,
                styles: {
                    position: 'center',
                    marginLeft: 480,
                    marginTop: -80,
                },
            },
        });

        scorePanel.alpha = 0;
        scorePanel.animate().add(scorePanel, { alpha: 1 }, 0.6, tween.pow2Out);
        await sleep(0.75);
        return scorePanel
            .animate()
            .add(scorePanel, { x: -scorePanel.width * 0.5 + 30 }, 3.1, tween.pow2InOut)
            .promise();
    }

    // private: scene
    //-------------------------------------------------------------------------
    private async _spawn(options: GameDayScreenOptions) {
        const { bgId, players, fullTeam } = options;
        this.root.sortableChildren = true;

        let confetti: Confetti;
        let lossHeader: Sprite;
        if (fullTeam?.type === 'victory') {
            confetti = new Confetti();
            void confetti.start({ loop: true });
        } else if (fullTeam?.type === 'loss') {
            lossHeader = Sprite.from(lazyManifest.lossPanel);
        }

        if (options.bgId === 'triplePan') {
            const width = 900;
            this._bgScroll = new NineSlicePlane(Texture.from(bgMap.triplePan[1]), 0, 0, 0, 1334);
            const containerHeight =
                app().stage.canvas.height < pixiConfig.size.height ? pixiConfig.size.height : app().stage.canvas.height;
            this._bgScroll.width = width;
            this._bgScroll.height = containerHeight;

            const left = new NineSlicePlane(Texture.from(bgMap.triplePan[0]), 0, 0, 0, 1334);
            const right = new NineSlicePlane(Texture.from(bgMap.triplePan[0]), 0, 0, 0, 1334);
            left.width = width;
            left.height = containerHeight;
            right.width = width;
            right.height = containerHeight;
            this._bgScroll.addChild(left);
            this._bgScroll.addChild(right);
            left.position.set(-width, 0);
            right.position.set(width, 0);
            this._bg = uiCreateQuad(0x0, 0.0001, width, pixiConfig.size.height);
            this._bg.addChild(this._bgScroll);
            uiAlignCenterY(this._bg, this._bgScroll);

            const duration = 6;
            this._bgScroll.x = 0;

            this._bgScroll
                .animate()
                .wait(0.3)
                .add(this._bgScroll.position, { x: this._bgScroll.x + 1800 }, duration, tween.pow2InOut);
        } else {
            // default bg
            this._bg = new NineSlicePlane(Texture.from(bgMap[bgId]), 0, 0, 0, 1334);
            this._bg.width = 900;
            this._bg.height = pixiConfig.size.height;
        }

        if (options.speedlines) {
            const container = uiCreateQuad(0x0, 0.5, 900, this._bg.height);
            const speedlines = new Speedlines();
            void speedlines.start();
            speedlines.scale.set(1.55);
            container.addChild(speedlines);
            this._bg.addChild(container);
            uiAlignCenter(container, speedlines, 200, speedlines.height * 0.5 - 100);
        }

        this.base.addContent({
            bg: {
                content: this._bg,
                styles: {
                    position: 'center',
                    minHeight: '100%',
                },
            },
        });

        if (fullTeam) {
            // spawn players before confetti
            this.base.addContent({
                team: {
                    content: this._createTeam(fullTeam.type),
                    styles: {
                        position: 'center',
                        maxWidth: 900,
                        marginTop: 500,
                        marginLeft: 200, // magic numbers
                    },
                },
            });
        } else {
            players?.forEach((player, index) =>
                this.spawnPlayer({ playerIndex: index, player, isHorizontalScroll: bgId === 'triplePan' }),
            );
        }

        if (confetti) {
            this.base.addContent({
                confetti: {
                    content: confetti,
                    styles: {
                        position: 'center',
                        marginLeft: confetti.width * 0.5,
                    },
                },
            });
        }

        if (lossHeader) {
            this.base.addContent({
                confetti: {
                    content: lossHeader,
                    styles: {
                        position: 'topCenter',
                        marginTop: -lossHeader.height,
                        width: 900,
                        height: 550,
                    },
                },
            });
            lossHeader.animate().add(lossHeader.position, { y: lossHeader.height }, 1.5, tween.pow2InOut);
        }

        if (fullTeam) {
            const titleMap = {
                loss: () =>
                    new BasicText({
                        text: '[gameDayLoss]',
                        style: {
                            align: 'center',
                            fontSize: 110,
                            fontWeight: 'bold',
                            fillGradientType: TEXT_GRADIENT.LINEAR_VERTICAL,
                            fill: [0xffffff, 0x0909090],
                            stroke: 'black',
                            strokeThickness: 16,
                            lineJoin: 'round',
                        },
                    }),
                victory: () =>
                    new BasicText({
                        text: '[gameDayWin]',
                        style: {
                            align: 'center',
                            fontSize: 110,
                            fontWeight: 'bold',
                            fillGradientType: TEXT_GRADIENT.LINEAR_VERTICAL,
                            fill: [0xac8700, 0xffd334, 0x8f6f00],
                            stroke: 'white',
                            strokeThickness: 16,
                            lineJoin: 'round',
                            dropShadow: true,
                            dropShadowDistance: 5,
                            dropShadowAngle: Math.PI / 2,
                            dropShadowAlpha: 0.3,
                        },
                    }),
            };
            const resultTitle = titleMap[fullTeam.type]();

            this.base.addContent({
                header: {
                    content: resultTitle,
                    styles: {
                        position: 'topCenter',
                        marginTop: 110,
                    },
                },
            });

            resultTitle.alpha = 0;
            const delay = fullTeam.type === 'victory' ? 0.2 : 1.4;
            resultTitle.animate().wait(delay).add(resultTitle, { alpha: 1 }, 0.3, tween.pow2In);
        }
    }
}
