import { analytics } from '@play-co/gcinstant';

import { InstantGame } from '../../../plugins/instantGames/InstantGame';
import { ProductId } from '../../../replicant/defs/product';
import app from '../../getApp';

const REVENUE_IAP_NET = 0.7; // Revenue IAP net multiplier

const purchaseActionsMap = {
    start: 'PurchaseFlowStart',
    failure: 'PurchaseFailure',
    success: 'PurchaseSuccess',
    end: 'PurchaseFlowEnd',
};

export type PurchaseFlow = 'apple' | 'google' | 'liff';

export type PurchaseAction = keyof typeof purchaseActionsMap;
export type RevenueAnalytics = {
    purchaseFlow: PurchaseFlow;
    productID: ProductId;
    feature: string;
    subFeature: string;
    revenueGross: number;
};

export function trackRevenue(
    opts: RevenueAnalytics & {
        revenueType: 'in_app_purchase';
        revenueGrossLocal: number;
        currencyCodeLocal: string;
        dollarToLocalRate: number;
    },
) {
    analytics.pushRevenue({
        ...opts,
    });
}

function trackPurchaseBase<T extends Pick<RevenueAnalytics, 'feature' | 'revenueGross' | 'purchaseFlow'>>(
    purchaseAction: PurchaseAction,
    opts: T,
) {
    let revenueType;
    const osType = InstantGame.platform.osType;
    if (osType === 'ANDROID_APP') {
        revenueType = 'androidIAP';
    } else if (osType === 'IOS_APP') {
        revenueType = 'iosIAP';
    }

    const eventOpts = {
        revenueNet: opts.revenueGross * REVENUE_IAP_NET,
        revenueType,
        purchaseFlow: opts.purchaseFlow,
        arePaymentsAvailable: app().product.supported,
    };

    analytics.pushEvent(purchaseActionsMap[purchaseAction], {
        ...opts,
        ...eventOpts,
    });
}

export function trackPurchaseFlowStart(opts: RevenueAnalytics) {
    const elapsedSeconds = (app().server.now() - app().analytics.purchaseShownTime) / 1000;

    trackPurchaseBase('start', {
        ...opts,
        elapsedSeconds,
    });
}

function trackPurchaseFlowEnd(opts: RevenueAnalytics) {
    const elapsedSeconds = (app().server.now() - app().analytics.purchaseShownTime) / 1000;

    trackPurchaseBase('end', {
        ...opts,
        elapsedSeconds,
    });
}

export function trackPurchaseFailure(
    opts: RevenueAnalytics & {
        elapsedSeconds: number;
        userCancelled: boolean;
        errorMessage: string;
        errorCode: string;
    },
) {
    trackPurchaseBase('failure', opts);
    trackPurchaseFlowEnd(opts);

    // Increment all applicable cancelled analytics
    if (opts.userCancelled) {
        app().analytics.sequenceCancelledSession++;
    }
}

export function trackPurchaseSuccess(
    opts: RevenueAnalytics & {
        elapsedSeconds: number;
    },
) {
    trackPurchaseBase('success', {
        isFirst: app().server.getPurchaseHistory().length <= 1,
        sequenceCancelledSession: app().analytics.sequenceCancelledSession,
        ...opts,
    });
    trackPurchaseFlowEnd(opts);

    // Reset all applicable sequenceCancelled counters
    app().analytics.sequenceCancelledSession = 0;
}

export function getPurchaseFlow(): PurchaseFlow {
    const osType = InstantGame.platform.osType;

    let flow: PurchaseFlow;
    switch (osType) {
        case 'ANDROID_APP':
            flow = 'google';
            break;
        case 'IOS_APP':
            flow = 'apple';
            break;
    }

    // guest does not support osType yet
    if (!app().platform.guest && !flow) {
        flow = 'liff';
    }

    return flow;
}
