import { Plugin, PluginOptions } from '@play-co/astro';
import { analytics } from '@play-co/gcinstant';

import UpdateObserver from '../../lib/pattern/UpdateObserver';
import { NavEvent, ScreenId } from '../../plugins/nav/NavPlugin';
import { livesGet } from '../../replicant/components/lives';
import { getTaskCompleteCount } from '../../replicant/components/task';
import { trackScreenClose, trackScreenOpen } from '../lib/analytics/core';
import app from '../getApp';
import { getTeamMoraleScore } from '../../replicant/components/teamMorale';

// types
//-----------------------------------------------------------------------------
export type AnalyticsServiceOptions = PluginOptions;

/*
    monitors various events and forwards to analytics
*/
export class AnalyticsService extends Plugin {
    // fields
    private _sessionPinchTriggered = false;
    public sessionTaps = 0;

    //  -- iap session fields
    public sequenceCancelledSession = 0;
    public purchaseShownTime = 0; // Time at purchase buttons shown

    //-------------------------------------------------------------------------
    private _stateObserver = new UpdateObserver();

    public get sessionPinchTriggered() {
        return this._sessionPinchTriggered;
    }

    public set sessionPinchTriggered(value: boolean) {
        this._sessionPinchTriggered = value;
    }

    // init
    //-------------------------------------------------------------------------
    public init(): void {
        // subscribe to pubsub
        app().nav.events.subscribe(this._onNavEvent.bind(this));
    }

    public async start() {
        // observe state to update properties
        this._startStateMonitoring();
    }

    // private: events
    //-------------------------------------------------------------------------
    // nav
    private _onNavEvent(event: NavEvent) {
        // handle screen open/close
        if (event.screenId) {
            if (event.id === 'spawned') this._onNavScreenOpen(event.screenId, event.options);
            else if (event.id === 'despawned') this._onNavScreenClose(event.screenId);
        }
    }

    private _onNavScreenOpen(id: ScreenId, options?: object) {
        trackScreenOpen({ id, ...this._interpretNavOptions(id, options) });
    }

    private _onNavScreenClose(id: ScreenId) {
        trackScreenClose({ id });
    }

    // private: monitoring
    //-------------------------------------------------------------------------
    private _startStateMonitoring() {
        // stars
        this._stateObserver.listen(
            () => app().game.player.stars,
            (realtimeStars) => analytics.setUserProperties({ realtimeStars }),
        );
        // highest puzzle level
        this._stateObserver.listen(
            () => app().server.state.puzzle.lastLevel,
            (realtimeHighestPuzzleLevel) => {
                analytics.setUserProperties({ realtimeHighestPuzzleLevel });
            },
        );
        // tasks
        this._stateObserver.listen(
            () => app().server.state.task.level,
            (realtimeTaskLevel) => {
                analytics.setUserProperties({ realtimeTaskLevel });
            },
        );
        this._stateObserver.listen(
            () => app().server.state.task.steps,
            (realtimeTaskStep) => {
                // realtime for "non timed" step (all current tasks)
                // if we add timed steps add another "stepper observer" similar to lives
                const stepCount = getTaskCompleteCount(app().server.state.task, app().server.now());
                analytics.setUserProperties({ realtimeTaskStep: stepCount });
            },
        );

        // boosters
        this._stateObserver.listen(
            () => app().server.state.boosters['dart'].count,
            (count) => {
                analytics.setUserProperties({ realtimeMetalBat: count });
            },
        );
        this._stateObserver.listen(
            () => app().server.state.boosters['bullet'].count,
            (count) => {
                analytics.setUserProperties({ realtimeRunner: count });
            },
        );
        this._stateObserver.listen(
            () => app().server.state.boosters['drill'].count,
            (count) => {
                analytics.setUserProperties({ realtimeFireball: count });
            },
        );
        this._stateObserver.listen(
            () => app().server.state.boosters['roulette'].count,
            (count) => {
                analytics.setUserProperties({ realtimeTornadoFan: count });
            },
        );

        // power boosters
        this._stateObserver.listen(
            () => app().server.state.powerBoosters.map['cube'].count,
            (count) => {
                analytics.setUserProperties({ realtimeBaseball: count });
            },
        );
        this._stateObserver.listen(
            () => app().server.state.powerBoosters.map['bomb'].count,
            (count) => {
                analytics.setUserProperties({ realtimeBomb: count });
            },
        );

        this._stateObserver.listen(
            () => app().server.state.powerBoosters.map['rocket'].count,
            (count) => {
                analytics.setUserProperties({ realtimeRocket: count });
            },
        );

        //  ------ LIVES & MORALE -----
        // lives (and morale), this one is a bit special because it relies on a timer to calculate exact value
        // combine it with state observer and time based polling
        this._startPolling();
        //  ------------------

        // start
        this._stateObserver.start();
    }

    // private: nav options
    //-------------------------------------------------------------------------
    private _interpretNavOptions(id: ScreenId, options?: object) {
        switch (id) {
            // example
            // case 'battleTip': {
            //     const character = (options as CharacterInfoPopupOptions).character;
            //     return {
            //         name: character.id,
            //         type: character.properties.type,
            //     };
            // }
            default:
                return {};
        }
    }

    private _startPolling() {
        let previousLifeCount = livesGet(app().server.state, app().server.now());
        analytics.setUserProperties({ realtimeLives: previousLifeCount });
        this._stateObserver.listen(
            () => app().game.player.lives,
            (realtimeLives) => {
                if (previousLifeCount !== realtimeLives) {
                    analytics.setUserProperties({ realtimeLives });
                }
            },
        );

        let previousMorale = app().game.player.morale;
        analytics.setUserProperties({ realtimeMorale: previousMorale });
        this._stateObserver.listen(
            () => app().game.player.morale,
            (realtimeMorale) => {
                if (previousMorale !== realtimeMorale) {
                    analytics.setUserProperties({ realtimeMorale });
                }
            },
        );

        // real time poller
        setInterval(() => {
            const realtimeLives = livesGet(app().server.state, app().server.now());
            if (previousLifeCount !== realtimeLives) {
                previousLifeCount = realtimeLives;
                analytics.setUserProperties({ realtimeLives });
            }

            const realtimeMorale = app().game.player.morale;
            if (previousMorale !== realtimeMorale) {
                previousMorale = realtimeMorale;
                analytics.setUserProperties({ realtimeMorale });
            }
        }, 15000);
    }
}
