import { waitAFrame } from '@play-co/astro';

import { DynamicXy, PositionType } from '../../../../../lib/defs/types';
import { IceDustAnimation } from '../../animations/IceDustAnimation';
import { despawnOverlayEntity, spawnOverlayEntity } from '../../entities/OverlayEntity';
import { GameScene } from '../../GameScene';
import { IEffect } from './IEffect';

// types
//-----------------------------------------------------------------------------
export type IceDustEffectOptions = {
    position: PositionType;
    amount?: number;
    tint?: number;
    scale?: DynamicXy;
    velocity?: DynamicXy;
};

/*
    ice dust effect
*/
export class IceDustEffect implements IEffect {
    // fields
    //-------------------------------------------------------------------------
    // input
    private readonly _scene: GameScene;
    private readonly _position: PositionType;
    private readonly _amount: number;
    private readonly _scale: DynamicXy;
    private readonly _velocity: DynamicXy;
    private readonly _tint?: number;

    // init
    //-------------------------------------------------------------------------
    constructor(scene: GameScene, options: IceDustEffectOptions) {
        this._scene = scene;
        this._position = options.position;
        this._amount = options.amount ?? 10;
        this._scale = options.scale ?? [
            { x: 1, y: 1 },
            { x: 1.5, y: 1.5 },
        ];
        this._velocity = options.velocity ?? { x: 0, y: -200 };
        this._tint = options.tint;
    }

    public static assets(): string[] {
        return [];
    }

    // impl
    //-------------------------------------------------------------------------
    public async execute() {
        // wait a frame since we async the animation
        await waitAFrame();

        // animate
        void this._animate();
    }

    // private: animation
    //-------------------------------------------------------------------------
    private async _animate() {
        // create  animation
        const animation = new IceDustAnimation({
            tint: this._tint,
            amount: this._amount,
            scale: this._scale,
            velocity: this._velocity,
        });
        // spawn animation as overlay
        const entity = spawnOverlayEntity(this._scene, this._position, {}, () => animation);
        // run animation
        await animation.start();
        // despawn entity
        despawnOverlayEntity(this._scene, entity);
    }
}
