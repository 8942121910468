import { asyncAction, ValidatePurchaseArgs } from '@play-co/replicant';

import { productDefs, ProductId, ProductState } from '../defs/product';
import { AsyncActionAPI, MutableState, State } from '../defs/replicant';
import { objectAccess } from '../util/jsTools';
import { timeFromComponents } from '../util/timeTools';
import { bulletAdd, dartAdd, drillAdd, powerBoosterAdd, rouletteAdd } from './boosters';
import { addInfiniteLives } from './lives';
import { grantCoins } from './shop';

// types
//-----------------------------------------------------------------------------
export type BuyError = 'unknown' | 'validation';

// actions
//-----------------------------------------------------------------------------
export const productActions = {
    // buys an IAP product
    productPurchaseComplete: asyncAction(
        async (state: MutableState, options: ValidatePurchaseArgs, api: AsyncActionAPI) => {
            try {
                // validate purchase
                const purchaseInfo = await api.purchases.validatePurchase(options);

                // get product id
                const productId = purchaseInfo.productId as ProductId;

                // update purchase count
                ++_accessState(state, productId).purchaseCount;

                // grant purchase
                if (!_grantPurchase(state, productId, api)) return new Error('unknown');
            } catch {
                return new Error('validation');
            }

            return true;
        },
    ),
};

// api
//-----------------------------------------------------------------------------
export function productIsPurchasable(state: State, productId: ProductId): boolean {
    const product = state.product[productId];
    const def = productDefs[productId];

    // if valid product and if one time product but not yet purchased
    return def && (!def.oneTimeOnly || !(product?.purchaseCount > 0));
}

// private: support
//-----------------------------------------------------------------------------
function _grantPurchase(state: MutableState, productId: ProductId, api: AsyncActionAPI) {
    const def = productDefs[productId];
    if (!def) return false;

    //TODO: refactor
    if (def.coins) {
        grantCoins(state, api, { type: 'coinsPremium', amount: def.coins, feature: 'shop', subFeature: productId });
    }
    if (def.metalBat) dartAdd(state, def.metalBat);
    if (def.runner) bulletAdd(state, def.runner);
    if (def.fireBall) drillAdd(state, def.fireBall);
    if (def.tornadoFan) rouletteAdd(state, def.tornadoFan);
    if (def.rocket) powerBoosterAdd(state, 'rocket', def.rocket);
    if (def.bomb) powerBoosterAdd(state, 'bomb', def.bomb);
    if (def.baseball) powerBoosterAdd(state, 'cube', def.baseball);
    if (def.infiniteLives) {
        addInfiniteLives(state, timeFromComponents({ hours: def.infiniteLives }), api.date.now());
    }

    return true;
}

function _accessState(state: MutableState, productId: ProductId): ProductState {
    return objectAccess(productId, state.product, () => ({
        purchaseCount: 0,
    }));
}
