import { SB, action } from '@play-co/replicant';
import { MutableState, ScheduledActionAPI, State, SyncActionAPI } from '../defs/replicant';
import teamMorale, { NightDecayClockJst } from '../defs/teamMorale';
import settings, { LanguageId } from '../defs/settings';
import { TeamMoralePinchAssetKey, chatbotMessageTemplates, generateChatbotPayload } from '../chatbot/messageTemplates';
import { ReplicantCreativeType, getCreativeText } from '../chatbot/chatbotTexts';
import { getAbTest } from '../util/replicantTools';
import { timeFromComponents, timeGetTimeOfDay } from '../util/timeTools';

const moralePinch0 = 'morale_pinch_0';
const moralePinch1 = 'morale_pinch_1';

//-----------------------------------------------------------------------------
export const teamMoraleActions = {
    updateTeamMorale: action((state: MutableState, options: { points: number }, api: SyncActionAPI) => {
        updateTeamMorale(state, options.points, api.date.now());
    }),
    consumeMoralePinch: action((state: MutableState, opts: { choiceIndex: number }, api: SyncActionAPI) => {
        if (state.teamMorale.pinchFlow.pinchId === -1) throw new Error('No pinch flow to consume');
        const moraleGain =
            teamMorale.teamMoraleConfig[state.teamMorale.pinchFlow.scenarioId][state.teamMorale.pinchFlow.pinchId]
                .moraleGain;
        updateTeamMorale(state, moraleGain[opts.choiceIndex], api.date.now());
        // reset pending pinch flow data, keep scenario if its the first pinch to re-use for the second pinch if needed
        if (state.teamMorale.pinchFlow.pinchId > 0) {
            state.teamMorale.pinchFlow.scenarioId = -1;
        }
        state.teamMorale.pinchFlow.pinchId = -1;
    }),
    completeTeamMoraleFTUE: action((state: MutableState, _, api: SyncActionAPI) => {
        state.teamMorale.ftue = true;
        // start morale decay
        state.teamMorale.timestamp = api.date.now();
    }),
};

// scheduledActions
//-----------------------------------------------------------------------------
export const teamMoraleScheduledActionsSchema = {
    teamMorale: SB.object({
        pinchId: SB.int(), // 0-indexed
    }),
};

export const teamMoraleScheduledActions = {
    teamMorale: async (state: MutableState, options: { pinchId: number }, api: ScheduledActionAPI) => {
        const { pinchId } = options;

        // update/override for pinch flow launch
        state.teamMorale.pinchFlow.pinchId = pinchId;
        if (pinchId === 0 || state.teamMorale.pinchFlow.scenarioId === -1) {
            const max = teamMorale.teamMoraleConfig.length;
            const newId = Math.floor(api.math.random() * max);
            // only override if its a new pinch sequence
            state.teamMorale.pinchFlow.scenarioId = newId;
        }

        const subFeature = teamMorale.teamMoraleConfig[state.teamMorale.pinchFlow.scenarioId][pinchId].id;

        void sendMoralePinchMessage(state, api, {
            pinchId,
            messageId: state.teamMorale.pinchFlow.scenarioId,
            subFeature,
        });
    },
};

// events
//-----------------------------------------------------------------------------
export function onTeamMoraleInit(api: SyncActionAPI, state: MutableState) {
    api.scheduledActions.unschedule(moralePinch0);
    api.scheduledActions.unschedule(moralePinch1);
}

// game day and game day practice
export function onTeamMoraleExit(api: ScheduledActionAPI, state: MutableState) {
    if (!state.teamMorale.ftue) return;

    const now = api.date.now();
    const firstPinchMS = getTimeToMoralePinch(state, 'first', now);
    const secondPinchMS = getTimeToMoralePinch(state, 'second', now);
    if (firstPinchMS > 0) {
        api.scheduledActions.schedule.teamMorale({
            args: { pinchId: 0 },
            notificationId: moralePinch0,
            delayInMS: firstPinchMS,
        });
    }
    if (secondPinchMS > 0) {
        api.scheduledActions.schedule.teamMorale({
            args: { pinchId: 1 },
            notificationId: moralePinch1,
            delayInMS: secondPinchMS,
        });
    }
}

export function updateTeamMorale(state: MutableState, points: number, now: number) {
    const newScore = getTeamMoraleScore(state, now, points);
    state.teamMorale.score = newScore;
    // only keep timestamp if ftue is done and decay is allowed
    if (state.teamMorale.ftue) {
        state.teamMorale.timestamp = now;
    }
}

export function getTeamMoraleScoreRaw(state: State, now: number, extraScore = 0) {
    let parsed = state.teamMorale.score;
    if (state.teamMorale.timestamp !== -1) {
        const lastAdded = state.teamMorale.timestamp;
        let timePassedMS = now - lastAdded;

        const nightTimeMS = getNightHoursJST(state.teamMorale.timestamp, now);

        const minutePoint = teamMorale.hourlyPointDecreaseAB / 60;
        // ignore night hours with no decay
        timePassedMS -= nightTimeMS;

        const timePassedMinutes = timePassedMS / 1000 / 60;
        const deductScore = timePassedMinutes * minutePoint;
        // handle min/max 0-100
        parsed = Math.max(0, state.teamMorale.score - deductScore);
    }

    return Math.min(100, parsed + extraScore);
}

export function getTeamMoraleScore(state: State, now: number, extraScore = 0) {
    return Math.round(getTeamMoraleScoreRaw(state, now, extraScore));
}

export function getTimeToMoralePinch(state: State, pinch: 'first' | 'second', now: number) {
    const currentMorale = getTeamMoraleScoreRaw(state, now);

    const threshold = {
        first: 50,
        second: 10,
    };

    const diff = currentMorale - threshold[pinch];
    if (diff <= 0) {
        return 0;
    }

    const currentHourJST = (new Date(now).getUTCHours() + 9) % 24;
    const timeToPinchHours = diff / teamMorale.hourlyPointDecreaseAB;
    let addNightTimeDelay = 0;
    let hours = Math.round(timeToPinchHours);

    const lastAddedHour = new Date(state.teamMorale.timestamp).getUTCHours();
    const lastAddedHourJST = (lastAddedHour + 9) % 24;

    // check if timestamp is during night hours and current time is during night then just add the remaining time until 6am JST
    if (
        (lastAddedHourJST >= teamMorale.decayPauseHourJST || lastAddedHourJST < teamMorale.decayResumeHourJST) &&
        (currentHourJST >= teamMorale.decayPauseHourJST || currentHourJST < teamMorale.decayResumeHourJST)
    ) {
        const customDelay = teamMorale.decayClockTimes['sixAM'] - timeFromComponents(timeGetTimeOfDay(now));
        addNightTimeDelay = customDelay;
    } else {
        // Look forward to the next hours to see if we will hit the night hours
        let currentTime = new Date(now);
        while (hours >= 0) {
            const currentHour = currentTime.getUTCHours();
            // JST is UTC+9
            const currentHourJST = (currentHour + 9) % 24;

            const nextTime = new Date(currentTime);

            // check if we will hit the night hoursm if so breakm out and add the delay
            if (currentHourJST >= teamMorale.decayPauseHourJST || currentHourJST < teamMorale.decayResumeHourJST) {
                const nightHours = getNightHoursJST(state.teamMorale.timestamp, now);
                addNightTimeDelay = timeFromComponents({ hours: 8 }) - nightHours;
                break;
            }

            nextTime.setHours(currentHourJST + 1, 0, 0, 0);
            currentTime = nextTime;
            hours--;
        }
    }

    return Math.round(timeToPinchHours * 60 * 60 * 1000 + addNightTimeDelay);
}

export function shouldTriggerMoralePinchFlow(state: State) {
    return state.teamMorale.pinchFlow.pinchId !== -1;
}

export function sendMoralePinchMessage(
    state: MutableState,
    api: ScheduledActionAPI | SyncActionAPI,
    message: {
        pinchId: number;
        messageId: number;
        subFeature?: string;
    },
) {
    const { pinchId, messageId, subFeature } = message;
    const lang = state.language as LanguageId;
    const prefixKey = `pinch_${messageId}_${pinchId}`;
    const assetId = `${prefixKey}_${lang}` as TeamMoralePinchAssetKey;
    const creativeText = getCreativeText(lang, prefixKey as ReplicantCreativeType, api.math.random);
    const isEn = lang === 'en';
    const preFilledName = isEn ? settings.defaultNameEN : settings.defaultNameJA;
    api.chatbot.sendMessage(
        state.id,
        chatbotMessageTemplates.flexImage({
            args: {
                imageKey: assetId,
                text: creativeText.text,
                senderName: state.name ? state.name : preFilledName,
            },
            payload: {
                ...generateChatbotPayload({ feature: 'morale_pinch', subFeature, api }),
                $creativeAssetID: assetId,
            },
        }),
    );
}

function getNightHoursJST(startTimestamp: number, endTimestamp: number) {
    const startTime = new Date(startTimestamp);
    const endTime = new Date(endTimestamp);

    if (endTime < startTime) {
        return 0;
    }

    let totalNightMS = 0;

    // Iterate over each hour between the start and end time
    let currentTime = new Date(startTime);
    while (currentTime < endTime) {
        const currentHour = currentTime.getUTCHours();
        // JST is UTC+9
        const currentHourJST = (currentHour + 9) % 24;
        const nextTime = new Date(currentTime);
        nextTime.setUTCHours(currentHour + 1, 0, 0, 0);

        // Calculate the actual period to be considered in this iteration
        const periodEnd = nextTime > endTime ? endTime : nextTime;
        const periodMS = periodEnd.getTime() - currentTime.getTime();

        // Count the hours between 10 PM (22:00) and 6 AM (06:00) JST
        if (currentHourJST >= teamMorale.decayPauseHourJST || currentHourJST < teamMorale.decayResumeHourJST) {
            totalNightMS += periodMS;
        }

        // Move to the next hour
        currentTime = nextTime;
    }

    return totalNightMS;
}
