/*
    js/language tools/helpers
*/
import { integerRandom, integerTea, RandomFunction } from './mathTools';

// properties
//-----------------------------------------------------------------------------
// props representation of the given class type
export type PropsOf<T> = Partial<{ [P in keyof T]: T[P] }>;

// applies given props to the given object
export function propsApply<T extends object>(object: T, props: PropsOf<T>) {
    for (const [key, value] of Object.entries(props)) {
        if (key in object) (object as any)[key] = value;
    }
}

// this is a strange one used to create an object with a getter/setter. for use by animators.
export function objectCreateWithProperty<T>(name: string, setter: (value: T) => void, initial: T = undefined): any {
    const object = {};
    let value: T = initial;
    Object.defineProperty(object, name, {
        get: () => value,
        set: (v: T) => {
            value = v;
            setter(value);
        },
    });
    return object;
}

// object operations
//-----------------------------------------------------------------------------
export function objectCreate<K extends string, V>(keys: readonly K[], initializer: (key: K) => V): Record<K, V> {
    const object: { [key in K]?: V } = {};
    for (const key of keys) {
        object[key] = initializer(key);
    }
    return object as Record<K, V>;
}

export function objectAccess<T>(key: string, object: any, init: () => T): any {
    return object[key] || (object[key] = init());
}

// array operations
//-----------------------------------------------------------------------------
/*
    create array
*/
export function arrayCreate<T>(count: number, builder: (index: number) => T): T[] {
    const array = new Array<T>(count);
    for (let i = 0; i < count; ++i) {
        array[i] = builder(i);
    }
    return array;
}

/*
    clone an array
*/
export function arrayClone<T>(array: T[]): T[] {
    return Array.from(array);
}

/*
    reverse iterate an array. this is also useful for safe mid iteration removal
    of the current item.
*/
export function arrayReverseIterate<T>(array: T[], handler: (value: T, index: number) => void) {
    for (let i = array.length; i--; ) {
        handler(array[i], i);
    }
}

/*
    add unique item to an array
*/
export function arrayUniqueElement<T>(array: T[], element: T, compare = (other: T) => other === element): T {
    // find existing object
    const found = array.find(compare);
    if (found) return found;

    // add given
    array.push(element);
    return element;
}

/*
    creates a new array where all elements are unique
*/
export function arrayCreateUniques<T>(array: T[]): T[] {
    return array.filter((element, index) => array.indexOf(element) === index);
}

/*
    remove item by value form array
*/
export function arrayRemove<T>(array: T[], value: T): boolean {
    const index = array.indexOf(value);
    if (index >= 0) {
        array.splice(index, 1);
        return true;
    }
    return false;
}

/*
    remove multiple indexes in an array
*/
export function arrayRemoveMany<T>(array: T[], indexes: number[]): T[] {
    arrayReverseIterate(array, (_, i) => {
        if (indexes.includes(i)) array.splice(i, 1);
    });
    return array;
}

/*
    swap 2 elements in an array
*/
export function arraySwap<T>(array: T[], i0: number, i1: number) {
    const v0 = array[i0];
    array[i0] = array[i1];
    array[i1] = v0;
}

/*
    get a random entry from an array
*/
export function arrayRandom<T>(array: readonly T[], fRandom: RandomFunction = Math.random): T | undefined {
    if (array.length === 0) return undefined;
    return array[integerRandom(0, array.length - 1, fRandom)];
}

/*
    shuffle an array
*/
export function arrayShuffle<T>(array: T[], fRandom: RandomFunction = Math.random): T[] {
    for (let i = 0; i < array.length; ++i) {
        arraySwap(array, i, integerRandom(0, array.length - 1, fRandom));
    }
    return array;
}

/*
    get a tea hashed entry from an array
*/
export function arrayTea<T>(array: T[], v0: string | number, v1?: number): T {
    return array[integerTea(0, array.length - 1, v0, v1)];
}

/*
    find "best" element
*/
export function arrayFindBest<T>(array: readonly T[], comparator: (a: T, b: T) => boolean): T | undefined {
    // if less than 2 elements return 1st or undefined
    if (array.length < 2) return array[0];

    // initial compare
    let best = comparator(array[0], array[1]) ? array[0] : array[1];

    // compare remaining
    for (let i = 2; i < array.length; ++i) {
        best = comparator(best, array[i]) ? best : array[i];
    }

    return best;
}

/*
    compare 2 (in order) arrays
    ex:
        array1 = [1,2,3]
        array2 = [1,2,3]
        result = true
*/
export function arrayCompare<T>(array1: T[], array2: T[]): boolean {
    if (array1.length !== array2.length) return false;
    for (let i = 0; i < array1.length; ++i) if (array1[i] !== array2[i]) return false;
    return true;
}

/*
    compare 2 (out of order) arrays
    ex:
        array1 = [1,2,3]
        array2 = [3,1,2]
        result = true
*/
export function arrayCompareUnordered<T>(array1: T[], array2: T[]): boolean {
    return arrayCompare(arrayClone(array1).sort(), arrayClone(array2).sort());
}

// 2d array operations
//-----------------------------------------------------------------------------
/*
    create 2d array
*/
export function arrayCreate2<T>(columns: number, rows: number, builder: (column: number, row: number) => T): T[][] {
    const array = new Array<T[]>(columns);

    for (let column = 0; column < columns; ++column) {
        const cells = (array[column] = new Array<T>(rows));

        for (let row = 0; row < rows; ++row) {
            cells[row] = builder(column, row);
        }
    }

    return array;
}

/*
    iterate 2d array
*/
export function arrayIterate2<T>(array: T[][], handler: (value: T, column: number, row: number) => void | boolean) {
    for (let column = 0; column < array.length; ++column) {
        const cells = array[column];
        for (let row = 0; row < cells.length; ++row) {
            if (handler(cells[row], column, row)) return;
        }
    }
}

// string operations
//-----------------------------------------------------------------------------
export function stringFormat(text: string, args: any[]): string {
    for (let i = 0; i < args.length; ++i) {
        text = text.replace(/{(\d+)}/g, (match, number) => args[number]);
    }

    return text;
}

// prototype hooks
//-----------------------------------------------------------------------------
export function prototypeHookAfter<T>(klass: new () => T, name: string, handler: Function) {
    const prototype = klass.prototype;
    const original = prototype[name] as Function;

    prototype[name] = function (...args: any[]) {
        const r = original.apply(this, args);
        handler.apply(this, args);
        return r;
    };
}

export function prototypeHookBefore<T>(klass: new () => T, name: string, handler: Function) {
    const prototype = klass.prototype;
    const original = prototype[name] as Function;

    prototype[name] = function (...args: any[]) {
        handler.apply(this, args);
        return original.apply(this, args);
    };
}

// other opeartions
//-----------------------------------------------------------------------------
export async function sleep(seconds: number) {
    return new Promise((resolve) => setTimeout(resolve, seconds * 1000));
}
