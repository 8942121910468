import { ITextStyle } from 'pixi.js';

const padding = 20;

export const layoutConfig = {
    headerHeight: 120,
    headerBgHeight: 155,
    padding,
    halfPadding: padding / 2,
    fontBlack: {
        fontWeight: 'bold',
        fill: 'black',
        lineJoin: 'round',
        stroke: 'white',
        strokeThickness: 8,
    } as Partial<ITextStyle>,
    fontWhite: {
        fontWeight: 'bold',
        fill: 'white',
        lineJoin: 'round',
        stroke: 'black',
        strokeThickness: 4,
        dropShadow: true,
        dropShadowDistance: 4,
        dropShadowAngle: Math.PI / 4,
        dropShadowAlpha: 0.2,
    } as Partial<ITextStyle>,
};
