import { gsap } from 'gsap';

import type { IScreen } from '../IScreen';
import { ITransition } from '../ITransition';

// constants
//-----------------------------------------------------------------------------
const duration = 0.3;

/*
    simple alpha fade transition
*/
export class SimpleAlphaTransition implements ITransition {
    // impl
    //-------------------------------------------------------------------------
    public async open(screen: IScreen) {
        screen.root.alpha = 0;
        await gsap.to(screen.root, {
            duration,
            alpha: 1,
        });
    }

    public async close(screen: IScreen) {
        await gsap.to(screen.root, {
            duration,
            alpha: 0,
        });
    }
}
