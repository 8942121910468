import { gsap } from 'gsap';
import { Container, Sprite } from 'pixi.js';

import { IAnimation } from '../../../../lib/pattern/IAnimation';
import { ParticleEmitter } from '../../../../lib/pixi/particles/ParticleEmitter';
import app from '../../../getApp';

// types
//-----------------------------------------------------------------------------
export type BlockBreakAnimationOptions = {
    color: number;
    alpha?: number;
};

/*
    block breaking animation
*/
export class BlockBreakAnimation extends Container implements IAnimation {
    // fields
    //-------------------------------------------------------------------------
    private _options: BlockBreakAnimationOptions;

    // init
    //-------------------------------------------------------------------------
    constructor(options: BlockBreakAnimationOptions) {
        super();
        this._options = options;
    }

    static assets(): string[] {
        return ['fx.block'];
    }

    // impl
    //-------------------------------------------------------------------------
    public async start(): Promise<void> {
        const color = this._options.color;

        // load assets
        await Promise.all(app().resource.loadAssets(BlockBreakAnimation.assets()));

        // create debris emitter
        const debrisEmitter = this._createDebrisEmitter(color);

        // create blast
        const blast = this._createBlast(color);

        // animate debris and blast
        await Promise.all([this._animateDebris(debrisEmitter), this._animateBlast(blast)]);
    }

    public stop() {}

    // private: animators
    //-------------------------------------------------------------------------
    private async _animateDebris(debris: ParticleEmitter) {
        // spawn debris
        this.addChild(debris.view);

        debris.view.alpha = this._options.alpha ?? 1;

        // start
        await debris.start();

        // despawn blast
        this.removeChild(debris.view);
    }

    private async _animateBlast(blast: Sprite) {
        // set initial values
        blast.scale.set(0.2);

        // spawn blast
        this.addChild(blast);

        // animate
        await gsap.to(blast.scale, { x: 1.6, y: 1.6, duration: 0.2, ease: 'power1.out' });

        // despawn blast
        this.removeChild(blast);
    }

    // private: factory
    //-------------------------------------------------------------------------
    private _createDebrisEmitter(color: number): ParticleEmitter {
        return app().particles.create({
            textures: ['fx.block.piece1.png', 'fx.block.piece2.png', 'fx.block.piece3.png'],
            rate: 0,
            limit: 7,
            emitterDuration: 0,
            duration: 2.5,
            velocity: { x: 0, y: -200 },
            tint: color,
            scale: [
                { x: 0.2, y: 0.2 },
                { x: 0.7, y: 0.7 },
            ],
            behaviors: [
                { type: 'explode', magnitude: [60, 180] },
                { type: 'gravity', gravity: { x: 0, y: 700 } },
                { type: 'scale', to: 0 },
                //{ type: 'tint', from: 0xff53d2, to: 0xffffff },
                { type: 'kinematic' },
            ],
        });
    }

    private _createBlast(color: number): Sprite {
        const blast = Sprite.from('fx.block.explode.png');
        blast.anchor.set(0.5);
        return blast;
    }
}
