import { Graphics, Sprite } from 'pixi.js';

import { uiAlignCenter, uiCreateMask, uiSizeToWidth } from '../../../lib/pixi/uiTools';
import { tween } from '../../../lib/util/tweens';
import { LayoutScreen2 } from '../../lib/screens/LayoutScreen2';
import { BasicText } from '../../lib/ui/text/BasicText';
import app from '../../getApp';

// types
//-----------------------------------------------------------------------------
export type VersusScreenOptions = {
    opponent: string;
    playerTeam: string;
};

// manifest
//-----------------------------------------------------------------------------
const manifest = {
    bg: 'bg.versus.png',
    glow: 'fx.glow2.png',
    versus: 'icon.versus.png',
    opponentRed: 'frame.banner.red.png',
    teamBlue: 'frame.banner.blue.png',
};

export class VersusScreen extends LayoutScreen2 {
    // events
    //-------------------------------------------------------------------------
    // scene
    private _bg: Sprite;
    private _bgMask: Graphics;

    // impl
    //-------------------------------------------------------------------------
    public preload(options: VersusScreenOptions) {
        return [...app().resource.loadAssets(Object.values(manifest))];
    }

    public async spawning(options: VersusScreenOptions) {
        void this.addOrientationListener();
        void app().music.play('bgm_championship.ogg');
        // spawn scene
        void this._spawn(options);

        this._bgMask = uiCreateMask(this._bg.width, this._bg.height);
        this._bg.mask = this._bgMask;
        this._bg.addChild(this._bgMask);
    }

    public despawned() {
        this.empty();
    }

    // private: scene
    //-------------------------------------------------------------------------
    private async _spawn(options: VersusScreenOptions) {
        this.root.sortableChildren = true;

        const iconGlow = Sprite.from(manifest.glow);
        iconGlow.pivot.set(iconGlow.width * 0.5, iconGlow.height * 0.5);
        iconGlow.scale.set(2);
        const versus = Sprite.from(manifest.versus);
        versus.pivot.set(versus.width * 0.5, versus.height * 0.5);

        const opponentRed = Sprite.from(manifest.opponentRed);
        const teamBlue = Sprite.from(manifest.teamBlue);

        const opponent = new BasicText({
            text: options.opponent,
            style: {
                fill: '#FFF',
                fontSize: 50,
                fontWeight: 'bold',
                lineJoin: 'round',
                align: 'center',
                stroke: '#F85353',
                strokeThickness: 4,
            },
        });

        const team = new BasicText({
            text: options.playerTeam,
            style: {
                fill: '#FFF',
                fontSize: 50,
                fontWeight: 'bold',
                lineJoin: 'round',
                align: 'center',
                stroke: '#0390E0',
                strokeThickness: 4,
            },
        });

        teamBlue.addChild(team);
        opponentRed.addChild(opponent);
        uiSizeToWidth(opponent, 400);
        uiAlignCenter(opponentRed, opponent, 0, -13);
        uiSizeToWidth(team, 390);
        uiAlignCenter(teamBlue, team, 0, -13);

        iconGlow.alpha = 0;
        versus.scale.set(1.2);
        versus
            .animate()
            .add(versus.scale, { x: 1.3, y: 1.3 }, 1, tween.pow2InOut)
            .add(versus.scale, { x: 1.2, y: 1.2 }, 1, tween.pow2InOut)
            .loop();
        iconGlow
            .animate()
            .add(iconGlow, { alpha: 0.3 }, 0.75, tween.pow2In)
            .add(iconGlow, { alpha: 0.4 }, 0.75, tween.pow2Out)
            .loop();
        iconGlow
            .animate()
            .set(iconGlow, { rotation: 0 })
            .add(iconGlow, { rotation: Math.PI * 2 }, 10, tween.linear)
            .loop();

        this._bg = Sprite.from(manifest.bg);

        this.base.addContent({
            bg: {
                content: this._bg,
                styles: {
                    position: 'center',
                    minHeight: '100%',
                },
            },
            opponent: {
                content: opponentRed,
                styles: {
                    position: 'center',
                    marginTop: -400,
                },
            },
            team: {
                content: teamBlue,
                styles: {
                    position: 'center',
                    marginTop: 360,
                },
            },
            glowAnimation: {
                content: iconGlow,
                styles: {
                    position: 'center',
                    marginTop: iconGlow.height * 0.5 - 100,
                    marginLeft: iconGlow.width * 0.5,
                },
            },
            versus: {
                content: versus,
                styles: {
                    position: 'center',
                    marginTop: 50,
                    marginLeft: versus.width * 0.5,
                },
            },
        });

        this._animateTeamBanners({
            opponentBanner: opponentRed,
            playerBanner: teamBlue,
        });
    }

    private _animateTeamBanners(opts: { opponentBanner: Sprite; playerBanner: Sprite }) {
        const { opponentBanner, playerBanner } = opts;
        opponentBanner.pivot.set(opponentBanner.width * 0.5, opponentBanner.height * 0.5);
        playerBanner.pivot.set(playerBanner.width * 0.5, playerBanner.height * 0.5);

        opponentBanner
            .animate()
            .set(opponentBanner.position, { x: -500 })
            .add(opponentBanner.position, { x: 240 }, 0.4, tween.backOut(0.6));
        opponentBanner
            .animate()
            .set(opponentBanner, { alpha: 0 })
            .add(opponentBanner, { alpha: 1 }, 0.3, tween.pow2Out);

        playerBanner
            .animate()
            .set(playerBanner.position, { x: 900 })
            .add(playerBanner.position, { x: 360 }, 0.4, tween.backOut(0.6));

        playerBanner.animate().set(playerBanner, { alpha: 0 }).add(playerBanner, { alpha: 1 }, 0.3, tween.pow2Out);
    }
}
