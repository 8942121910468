import { Vector2 } from '@play-co/odie';
import { gsap } from 'gsap';
import { Assets } from 'pixi.js';

import { pixiConfig } from '../../../../defs/config';
import { RocketComponent } from '../../components/RocketComponent';
import { AxisId, DirectionId } from '../../defs/block';
import { config } from '../../defs/config';
import { despawnRocketEntity, RocketEntity, spawnRocketEntity } from '../../entities/RocketEntity';
import { GameScene } from '../../GameScene';
import { CollisionTracker } from '../../util/CollisionTracker';
import { mapFromViewPosition } from '../../util/mapTools';
import { IEffect } from './IEffect';

// types
//-----------------------------------------------------------------------------
export type BombEffectOptions = {
    position: Vector2;
    axis: AxisId;
};

type AnimateProps = {
    getToPosition: (position: Vector2) => Vector2;
    iterateVector: Vector2;
};

// constants
//-----------------------------------------------------------------------------
const animationMap: Record<DirectionId, AnimateProps> = {
    // left
    left: {
        getToPosition: (position: Vector2) => new Vector2(-(config.tile.size + pixiConfig.size.width), position.y),
        iterateVector: new Vector2(-1, 0),
    },
    // right
    right: {
        getToPosition: (position: Vector2) => new Vector2(config.tile.size + pixiConfig.size.width, position.y),
        iterateVector: new Vector2(1, 0),
    },
    // up
    up: {
        getToPosition: (position: Vector2) => new Vector2(position.x, -(config.tile.size + pixiConfig.size.height)),
        iterateVector: new Vector2(0, -1),
    },
    // down
    down: {
        getToPosition: (position: Vector2) => new Vector2(position.x, config.tile.size + pixiConfig.size.height),
        iterateVector: new Vector2(0, 1),
    },
};

/*
    rocket launch effect
*/
export class RocketEffect implements IEffect {
    // fields
    //-------------------------------------------------------------------------
    // input
    private readonly _scene: GameScene;
    private readonly _position: Vector2;
    private readonly _axis: AxisId;
    // state
    private readonly _collisionTracker = new CollisionTracker();

    // init
    //-------------------------------------------------------------------------
    constructor(scene: GameScene, options: BombEffectOptions) {
        this._scene = scene;
        this._position = options.position;
        this._axis = options.axis;
    }

    static assets(): string[] {
        return RocketComponent.assets();
    }

    // impl
    //-------------------------------------------------------------------------
    public async execute() {
        // load assets
        await Assets.load(RocketEffect.assets());

        // launch rockets
        switch (this._axis) {
            case 'horizontal':
                await this._launchHorizontalRockets();
                break;
            case 'vertical':
                await this._launchVerticalRockets();
                break;
        }
    }

    // private
    //-------------------------------------------------------------------------
    private async _launchHorizontalRockets() {
        await Promise.all([this._launchRocketFromEntity('left'), this._launchRocketFromEntity('right')]);
    }

    private async _launchVerticalRockets() {
        await Promise.all([this._launchRocketFromEntity('up'), this._launchRocketFromEntity('down')]);
    }

    private async _launchRocketFromEntity(direction: DirectionId) {
        // spawn rocket
        const rocketEntity = spawnRocketEntity(this._scene, this._position, direction);

        // launch rocket
        await this._launchRocket(rocketEntity, direction);
    }

    private async _launchRocket(rocketEntity: RocketEntity, direction: DirectionId) {
        const animationProps = animationMap[direction];
        const view = rocketEntity.view;
        const from = new Vector2(view.x, view.y);
        const mapLast = mapFromViewPosition(from);

        // determine "to" position
        const to = animationProps.getToPosition(from);

        // solve duration from travel distance of linear ease
        const duration = from.sub(to).length() / config.sim.rocket.speed;

        // animate and despawn rocket
        return new Promise((resolve) => {
            // animate
            void gsap
                .to(view, {
                    x: to.x,
                    y: to.y,
                    duration,
                    ease: 'linear',
                    onUpdate: () => {
                        const current = new Vector2(view.x, view.y);
                        const mapCurrent = mapFromViewPosition(current);

                        // iterate from last to current. this is done to handle lost frames.
                        while (!mapLast.equals(mapCurrent)) {
                            // iterate
                            mapLast.add(animationProps.iterateVector);

                            // collide at this position
                            if (
                                !this._collisionTracker.collideAt(this._scene.sessionEntity.c.map, mapLast, 'attack', {
                                    type: 'rocket',
                                })
                            ) {
                                resolve(0);
                            }
                        }
                    },
                    // then despawn rocket
                })
                .then(() => despawnRocketEntity(this._scene, rocketEntity));
        });
    }
}
