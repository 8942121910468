import { Assets, Container, Graphics, ITextStyle, NineSlicePlane, Sprite, Texture } from 'pixi.js';

import {
    uiAlignBottom,
    uiAlignCenter,
    uiAlignCenterX,
    uiAlignCenterY,
    uiAlignTop,
    uiCreateMask,
    uiCreateQuad,
    uiSizeToWidth,
} from '../../../lib/pixi/uiTools';
import { Line } from '../../../lib/ui/widgets/Line';
import { tween } from '../../../lib/util/tweens';
import { Scoreboard } from '../../../replicant/defs/gameDay';
import { sleep } from '../../../replicant/util/jsTools';
import { BaseballPlayer, PlayerClipId, PlayerSkin } from '../../concept/BaseballPlayer';
import { Speedlines } from '../../concept/Speedlines';
import { pixiConfig } from '../../defs/config';
import { BasicText } from '../../lib/ui/text/BasicText';
import { boardAssets, SpeechScreen } from '../home/SpeechScreen';
import app from '../../getApp';

// types
//-----------------------------------------------------------------------------
export type IntroScreenOptions = { fadeTime?: number; dual?: boolean; isRegularGameDay?: boolean };

const BAT_SFX = 'wooden_bat.ogg';
const CHEER_SFX = 'crowd_cheer.ogg';

// manifest
//-----------------------------------------------------------------------------
const bgManfest = {
    introbg: 'bg.intro.png',
    schoolBg: 'bg.school.png',
};

// not used for intial scene,
const lazyManifest = {
    dualBg: 'bg.intro2.png',
    scorePanelIntro: 'panel.score.intro.png',
    board0: 'panel.score.game.0.png',
    board1: 'panel.score.game.1.png',
    board2: 'panel.score.game.2.png',
};

export class IntroScreen extends SpeechScreen {
    // events
    //-------------------------------------------------------------------------
    // scene
    private _bgMask: Graphics;
    private _topBlack: Graphics;
    private _bgScroll: NineSlicePlane;
    private _whiteSeparator: Line;

    // impl
    //-------------------------------------------------------------------------
    public override preload(options: IntroScreenOptions) {
        return [
            ...super.preload(),
            app().resource.loadAsset(options?.isRegularGameDay ? bgManfest.schoolBg : bgManfest.introbg),
        ];
    }

    // https://pixijs.download/dev/docs/PIXI.Assets.html
    public async preloadLazyAsset(asset: keyof typeof lazyManifest) {
        await app().resource.loadAsset(lazyManifest[asset]);
    }

    public async preloadSpeedLines() {
        await Promise.all(app().resource.loadAssets(Speedlines.assets()));
    }

    public async preloadPlayers() {
        await Promise.all(app().resource.loadAssets(BaseballPlayer.assets()));
    }

    public async spawning(options: IntroScreenOptions) {
        void this.addOrientationListener();
        if (options.dual) {
            // these should been called before from the flow and will resolve instantly
            const promise1 = this.preloadLazyAsset('dualBg');
            const promise2 = this.preloadPlayers();
            await Promise.all([promise1, promise2]);
        }

        this._spines = [];
        // play music
        void app().music.play('bgm_championship.ogg');

        // spawn scene
        void this._spawn(options);

        this._bgMask = uiCreateMask(this._bg.width, this._bg.height);
        this._bg.mask = this._bgMask;
        this._bg.addChild(this._bgMask);

        if (options.dual) {
            void this._spawnDual();
        }
    }

    public despawned() {
        this.empty();
    }

    // private: scene
    //-------------------------------------------------------------------------
    private async _spawn(options: IntroScreenOptions) {
        this.root.sortableChildren = true;

        const bgAsset = options?.isRegularGameDay
            ? bgManfest.schoolBg
            : options.dual
              ? lazyManifest.dualBg
              : bgManfest.introbg;
        // spawn scene
        this._bgScroll = new NineSlicePlane(Texture.from(bgAsset), 0, 0, 0, 1334);
        this._bgScroll.width = 900;
        this._bgScroll.height = 1334;

        // hack to work with the shared bubble logic that expects a static bg at bottomCenter
        const containerHeight =
            app().stage.canvas.height < pixiConfig.size.height ? pixiConfig.size.height : app().stage.canvas.height;
        this._bg = uiCreateQuad(0x0, 0.0001, this._bgScroll.width, containerHeight);

        if (options.dual) {
            this._whiteSeparator = new Line({
                from: { x: 0, y: 0 },
                to: { x: 0, y: 790 },
                size: 8,
                color: 0xffffff,
            });
            this._bg.addChild(this._whiteSeparator);
            uiAlignCenter(this._bg, this._whiteSeparator);
        }

        const boxHeight =
            app().stage.canvas.height < pixiConfig.size.height
                ? pixiConfig.size.height * 0.5 - 390
                : app().stage.canvas.height * 0.5 - 390;
        this._topBlack = uiCreateQuad(0x0, 1, this._bgScroll.width + 2, boxHeight);
        const bottomBlack = uiCreateQuad(0x0, 1, this._bgScroll.width + 2, boxHeight);
        this._bg.addChild(this._topBlack, bottomBlack);

        uiAlignTop(this._bg, this._topBlack);
        uiAlignBottom(this._bg, bottomBlack);

        this.base.addContent({
            bgScroll: {
                content: this._bgScroll,
                styles: {
                    position: 'center',
                },
            },
            bg: {
                content: this._bg,
                styles: {
                    position: 'bottomCenter',
                },
            },
        });

        if (options.fadeTime) {
            this.base.alpha = 0;
            this.base.animate().add(this.base, { alpha: 1 }, options.fadeTime, tween.pow2Out);
        }
    }

    // Old with static asset, only used in tutolrial for now
    public async spawnScore(opts: { visitor: string; home: string }): Promise<void> {
        // this should been called before from the flow and will resolve instantly
        const assetKey = 'scorePanelIntro';
        await this.preloadLazyAsset(assetKey);
        const scorePanel = Sprite.from(lazyManifest[assetKey]);

        const style = {
            fill: '#fff',
            fontSize: 64,
            fontWeight: 'bold',
            lineJoin: 'round',
        } as Partial<ITextStyle>;

        const visitorName = new BasicText({
            text: opts.visitor,
            style,
        });
        const homeName = new BasicText({
            text: opts.home,
            style,
        });

        const x = 130;
        visitorName.x = x;
        visitorName.y = 210;

        homeName.x = x;
        homeName.y = 350;
        scorePanel.addChild(visitorName, homeName);

        uiAlignCenterY(this._bg, scorePanel);
        this._bg.addChild(scorePanel);
        scorePanel.alpha = 0;
        scorePanel.animate().add(scorePanel, { alpha: 1 }, 0.6, tween.pow2Out);
        scorePanel.x = -35;
        scorePanel.y -= 30;
        await sleep(0.75);
        return scorePanel
            .animate()
            .add(scorePanel, { x: -scorePanel.width * 0.5 + 20 }, 3.1, tween.pow2InOut)
            .promise();
    }

    // this is same as spawnScoreDynamic in GameDayScreen but has different parent and pixel values because of different parent
    public async spawnScoreDynamic(opts: {
        visitor: string;
        visitorData: string[];
        visitorR: number;
        home: string;
        homeData: string[];
        homeR: number;
        scoreboard: Scoreboard;
        balls: number;
        strikes: number;
        outs: number;
    }): Promise<void> {
        const { visitor, visitorData, visitorR, home, homeData, homeR, scoreboard, balls, strikes, outs } = opts;
        // this should been called before from the flow and will resolve instantly
        await this.preloadScoreboard(scoreboard);
        const scorePanel = Sprite.from(boardAssets[scoreboard].board);

        const scoreStyle = {
            fill: '#fff',
            fontSize: 70,
            fontWeight: 'bold',
            lineJoin: 'round',
            align: 'center',
        } as Partial<ITextStyle>;
        // ---- score
        const addScore = (data: string[], y: number) => {
            const scoreX = 660;
            const scoreOffsetX = 103;
            for (let i = 0; i < data.length; i++) {
                const number = new BasicText({
                    text: `${data[i]}`,
                    style: scoreStyle,
                });

                uiSizeToWidth(number, 68);
                number.pivot.set(number.width * 0.5, number.height * 0.5);
                number.position.set(scoreX + i * scoreOffsetX, y);
                scorePanel.addChild(number);
            }
        };
        addScore(visitorData, 208);
        addScore(homeData, 302 + 40);

        const addR = (amount: number, y: number) => {
            const r = new BasicText({
                text: `${amount}`,
                style: scoreStyle,
            });
            r.pivot.set(r.width * 0.5, r.height * 0.5);
            uiSizeToWidth(r, 140);
            r.position.set(1626, y);
            scorePanel.addChild(r);
        };
        addR(visitorR, 208);
        addR(homeR, 302 + 40);
        // -----------------

        // ----- lights
        const ballX = 772;
        const strikeX = ballX + 418;
        const outX = ballX + 708;
        const addLights = (amount: number, x: number) => {
            const y = 449;
            const offsetX = 46;
            for (let i = 0; i < amount; i++) {
                const light = Sprite.from(boardAssets[scoreboard].light);
                light.position.set(x + i * offsetX, y);
                scorePanel.addChild(light);
            }
        };

        addLights(balls, ballX);
        addLights(strikes, strikeX);
        addLights(outs, outX);
        // -----------------

        const style = {
            fill: '#fff',
            fontSize: 64,
            fontWeight: 'bold',
            lineJoin: 'round',
        } as Partial<ITextStyle>;

        const visitorName = new BasicText({
            text: visitor,
            style,
        });
        const homeName = new BasicText({
            text: home,
            style,
        });

        const x = 145;
        visitorName.x = x;
        visitorName.y = 172;

        homeName.x = x;
        homeName.y = 312;
        uiSizeToWidth(visitorName, 440);
        uiSizeToWidth(homeName, 440);
        scorePanel.addChild(visitorName, homeName);

        uiAlignCenterY(this._bg, scorePanel);
        this._bg.addChild(scorePanel);
        scorePanel.alpha = 0;
        scorePanel.animate().add(scorePanel, { alpha: 1 }, 0.6, tween.pow2Out);
        scorePanel.x = -35;
        scorePanel.y -= 30;
        await sleep(0.75);
        return scorePanel
            .animate()
            .add(scorePanel, { x: -scorePanel.width * 0.5 + 20 }, 3.1, tween.pow2InOut)
            .promise();
    }

    public panDown() {
        return this._bgScroll.animate().add(this._bgScroll.position, { y: this._bg.y - 240 }, 4.3, tween.pow2InOut);
    }

    // messy code with magic constants to make everything look good and work with last minute changes
    public async swingAnimation(): Promise<void> {
        // preload should've been called before from the flow and will resolve instantly
        const promise1 = this.preloadSpeedLines();
        const promise2 = this.preloadPlayers();
        await Promise.all([promise1, promise2]);

        // custom fade using blackOverlay to avoid weird flicker
        const blackOverlay = uiCreateQuad(0x0, 1, this._bg.width + 2, this.base.height);
        this.base.addChild(blackOverlay);
        uiAlignCenter(this.base, blackOverlay);
        blackOverlay.alpha = 0;
        await blackOverlay.animate().add(blackOverlay, { alpha: 1 }, 0.3, tween.pow2Out).promise();

        // preload sfx so its ready to play once animation is finished
        void Assets.load(BAT_SFX);
        void Assets.load(CHEER_SFX);

        // --- clean up scene
        void this._despawnSpines();
        if (this._whiteSeparator) this._whiteSeparator.destroy();
        // --- clean up scene

        // top half
        // extra wide with smaller mask to allow for position animation without issues
        const topMaskHeight = 384;
        const topContainer = uiCreateQuad(0x0, 0.001, 1700, topMaskHeight);
        const topMask = uiCreateMask(900, topMaskHeight);
        topContainer.mask = topMask;
        topContainer.addChild(topMask);
        uiAlignCenter(topContainer, topMask, 0, -44);
        const topPlayer = new BaseballPlayer('character3_boy_red');
        this._spines.push(topPlayer);

        topPlayer.scale.y = 1.3;
        topPlayer.scale.x = -1.3;
        topPlayer.speed = 0.75;

        topContainer.addChild(topPlayer);
        this._bg.addChild(topContainer);

        const topFade = uiCreateQuad(0x0, 0.9, 902, topMaskHeight);
        this._bg.addChild(topFade);
        uiAlignCenter(this._bg, topFade, -198, -topFade.height * 0.5 - 30);

        topPlayer.position.set(1450, 420);
        uiAlignCenter(this._bg, topContainer, 0, -30);

        // bottom half
        const bottomMaskHeight = 400;
        const speedlines = new Speedlines();

        const bottomMask = uiCreateMask(speedlines.width, bottomMaskHeight);
        const bottomFade = uiCreateQuad(0x0, 0.9, speedlines.width, speedlines.height + 4);
        this._bg.addChild(speedlines, bottomFade);

        uiAlignCenter(this._bg, speedlines, 127, speedlines.height * 0.5 + 77);
        uiAlignCenter(this._bg, bottomFade, -100, bottomFade.height * 0.5 - 10);

        speedlines.mask = bottomMask;
        uiAlignCenter(speedlines, bottomMask, -bottomMask.width * 0.5, -bottomMask.height * 0.5 - 8);

        const bottomPlayer = new BaseballPlayer('character1_boy');
        this._spines.push(bottomPlayer);

        bottomPlayer.scale.set(1.3);
        bottomPlayer.speed = 0.6;
        // bottomPlayer.speed = 0.25;
        speedlines.addChild(bottomMask, bottomPlayer);
        bottomPlayer.position.set(-700, 170);

        topFade.alpha = 0;

        void speedlines.start();
        void bottomPlayer.start({ id: 'batter_idle', loop: true });
        void topPlayer.start({ id: 'pitcher_idle', loop: true });

        setTimeout(() => {
            void topPlayer.start({ id: 'pitcher_throw', mix: 0 });
        }, 550);
        setTimeout(async () => {
            await bottomPlayer.start({ id: 'batter_swing_slow', mix: 0 });
        }, 1160);

        setTimeout(async () => {
            topFade.animate().add(topFade, { alpha: 1 }, 0.25, tween.linear);
            await sleep(0.1);
            bottomFade.animate().add(bottomFade, { alpha: 0 }, 0.25, tween.linear);
        }, 1580);

        void blackOverlay.animate().add(blackOverlay, { alpha: 0 }, 0.3, tween.pow2In).promise();
        const topPromise = topPlayer.animate().add(topPlayer, { x: 870 }, 0.55, tween.backOut(1.5)).promise();
        const bottomPromise = bottomPlayer.animate().add(bottomPlayer, { x: -210 }, 0.65, tween.backOut(1.5)).promise();
        await Promise.all([topPromise, bottomPromise]);
    }

    public async exitFade() {
        const whiteOverlay = uiCreateQuad(0xffffff, 1, this._bg.width + 2, this.base.height);
        whiteOverlay.alpha = 0;
        this.base.addChild(whiteOverlay);
        uiAlignCenter(this.base, whiteOverlay);

        void app().music.fade(0.9);
        setTimeout(async () => {
            void app().sound.play(BAT_SFX);
            await sleep(0.25);
            void app().sound.play(CHEER_SFX);
        }, 1120);
        await whiteOverlay.animate().add(whiteOverlay, { alpha: 1 }, 1.1, tween.pow2Out).wait(0.5).promise();

        // go back to black
        const blackOverlay = uiCreateQuad(0x0, 1, this._bg.width + 2, this.base.height);
        this.base.addChild(blackOverlay);
        uiAlignCenter(this.base, blackOverlay);
        blackOverlay.alpha = 0;
        await blackOverlay.animate().add(blackOverlay, { alpha: 1 }, 0.8, tween.pow2Out).promise();
    }

    private async _spawnDual() {
        const dualMap: {
            [key in string]: {
                skin: PlayerSkin;
                animation: PlayerClipId;
                offsetX: number;
                scaleX: number;
                speed: number;
                namePlayer: string;
            };
        } = {
            player1: {
                skin: 'character1_boy',
                animation: 'batter_nervous',
                offsetX: -220,
                speed: 0.9,
                scaleX: 1,
                namePlayer: '[tutorialDual0]',
            },
            player2: {
                skin: 'character5_boy',
                animation: 'batter_confident',
                offsetX: 220,
                scaleX: -1,
                speed: 0.7,
                namePlayer: '[tutorialDual1]',
            },
        };

        const spawnPlayer = (id: 'player1' | 'player2') => {
            const playerData = dualMap[id];
            const player = new BaseballPlayer(playerData.skin);
            this._spines.push(player);

            player.scale.set(0.95);
            player.scale.x *= playerData.scaleX;
            this._bgScroll.addChild(player);
            player.position.set(
                this._bgScroll.width * 0.5 + playerData.offsetX,
                this._bgScroll.height * 0.5 + player.height * 0.5,
            );
            player.speed = playerData.speed;
            void player.start({ id: playerData.animation, loop: true });

            const title = new BasicText({
                text: playerData.namePlayer,
                style: {
                    fill: '#fff',
                    fontSize: 36,
                    fontWeight: 'bold',
                    lineJoin: 'round',
                    align: 'center',
                    dropShadow: true,
                    dropShadowAngle: Math.PI / 2,
                    dropShadowDistance: 3,
                    dropShadowAlpha: 0.7,
                    dropShadowBlur: 5,
                },
            });
            player.addChild(title);
            title.scale.x = player.scale.x;
            uiAlignCenterX(player, title, -player.width * 0.5);
            title.y = -555;
            return player;
        };

        spawnPlayer('player1');
        spawnPlayer('player2');
    }

    private async _despawnSpines() {
        const fadeNuke = async (spine: Container) => {
            // make sure skeleton is not too bright during fading
            (spine as BaseballPlayer).toSprite();
            // fast animation, faster than regular transision to make sure the skeleton is fully faded out
            await spine.animate().add(spine, { alpha: 0 }, 0.1, tween.pow4Out);
            spine.destroy();
        };

        const promises = this._spines.map((spine: Container) => fadeNuke(spine));
        await Promise.all(promises);

        this._spines = [];
    }
}
